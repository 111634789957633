import { useEffect, useState } from 'react';

import { useQuery } from 'react-admin';

import dataProvider from 'dataProvider';

import { CheckListElement } from 'types/check-list-elements';
import { DisassemblySkuModel } from 'types/disassembly-sku-models';
import { IrisCondition, IrisDefect, IrisRepair, IrisSection, IrisSymptom } from 'types/iris-codes';
import { ProductCheckListElement } from 'types/product-check-list-element';
import { ProductHistory } from 'types/product-history';
import { ProductType } from 'types/product-types';
import { Product } from 'types/products';
import { SkuModelReferential } from 'types/sku-model-referential';
import { SparePartFitting } from 'types/spare-part-fittings';

import { DONE } from './RepairCycleUtils';

export const useIrisDefects = (): { irisDefects: IrisDefect[] } => {
  const { data: irisDefects } = useQuery({
    type: 'getList',
    resource: 'iris-defects',
    payload: {
      sort: { field: 'code', order: 'DESC' },
    },
  }) as { data: IrisDefect[] };
  return { irisDefects };
};

export const useIrisSymptoms = (): { irisSymptoms: IrisSymptom[] } => {
  const { data: irisSymptoms } = useQuery({
    type: 'getList',
    resource: 'iris-symptoms',
    payload: {
      sort: { field: 'code', order: 'DESC' },
    },
  }) as { data: IrisSymptom[] };
  return { irisSymptoms };
};

export const useIrisSections = (): { irisSections: IrisSection[] } => {
  const { data: irisSections } = useQuery({
    type: 'getList',
    resource: 'iris-sections',
    payload: {
      sort: { field: 'code', order: 'DESC' },
    },
  }) as { data: IrisSection[] };
  return { irisSections };
};

export const useIrisConditions = (): { irisConditions: IrisCondition[] } => {
  const { data: irisConditions } = useQuery({
    type: 'getList',
    resource: 'iris-conditions',
    payload: {
      sort: { field: 'code', order: 'DESC' },
    },
  }) as { data: IrisSection[] };
  return { irisConditions };
};

export const useIrisRepairs = (): { irisRepairs: IrisRepair[] } => {
  const { data: irisRepairs } = useQuery({
    type: 'getList',
    resource: 'iris-repairs',
    payload: {
      sort: { field: 'code', order: 'DESC' },
    },
  }) as { data: IrisSection[] };
  return { irisRepairs };
};

export const useProductTypes = (): { productTypes: ProductType[] } => {
  const { data: productTypes } = useQuery({
    type: 'getList',
    resource: 'product-types',
    payload: {
      sort: { field: 'name', order: 'ASC' },
    },
  }) as { data: ProductType[] };
  return { productTypes };
};

export const getProductHistory = async (productId: string): Promise<ProductHistory> => {
  const { data } = await dataProvider.getOne(`murfy-erp/products/${productId}/history`, {});
  return data;
};

export const getProductTypes = async (): Promise<ProductType[]> => {
  const { data } = await dataProvider.getList('product-types', {
    sort: { field: 'name', order: 'DESC' },
    filter: {},
  });
  return data;
};

export const getProductCheckList = async (
  filter: Record<string, unknown>
): Promise<ProductCheckListElement[]> => {
  const { data } = await dataProvider.getList('product-check-list-elements', {
    filter: filter,
  });
  return data;
};

export const postRepairSection = async (
  productId: number,
  repairSection: { iris_section: number; iris_repair: number | null }
): Promise<void> => {
  await dataProvider.create('repair-sections', {
    data: {
      product: productId,
      state: DONE,
      iris_section: repairSection.iris_section,
      iris_repair: repairSection.iris_repair,
    },
  });
};

export const postSparePartFitting = async (
  productId: number,
  sparePartFitted: SparePartFitting
): Promise<void> => {
  await dataProvider.put('spare-part-fitting', {
    data: {
      product: productId,
      state: DONE,
      spare_part: sparePartFitted.spare_part.id,
      fitting_type: sparePartFitted?.fitting_type,
    },
  });
};

export const postCheckLists = async (
  productId: number,
  checklist: string,
  checks: { id: number; text: string; is_checked: boolean }[]
): Promise<void> => {
  await dataProvider.create('product-check-list-reports', {
    data: {
      state: DONE,
      product: productId,
      checklist: checklist,
      items: checks?.map((check: { id: number; text: string; is_checked: boolean }) => {
        return { is_checked: check.is_checked, text: check.text };
      }),
    },
  });
};

export const postRepairReport = async (productId: number, repairOutcome: string): Promise<void> => {
  await dataProvider.create('repair-reports', {
    data: {
      product: productId,
      repair_outcome: repairOutcome,
      state: DONE,
    },
  });
};

export const postCheckListsDeliveryItem = async (
  deliveryItemId: number,
  checklist: string,
  checks: { id: number; text: string; is_checked: boolean }[]
): Promise<void> => {
  await dataProvider.create('delivery-items-check-list-reports', {
    data: {
      delivery_item: deliveryItemId,
      state: DONE,
      checklist: checklist,
      items: checks?.map((check: { id: number; text: string; is_checked: boolean }) => {
        return { is_checked: check.is_checked, text: check.text };
      }),
    },
  });
};

export const getSkuModels = async (
  filter: Record<string, unknown>
): Promise<SkuModelReferential[]> => {
  const { data } = await dataProvider.getList('sku-models', {
    filter: filter,
    sort: { field: 'designation', order: 'ASC' },
  });
  return data;
};

export const getProduct = async (productId: number): Promise<Product> => {
  const { data } = await dataProvider.getOne('products', {
    id: productId,
  });
  return data;
};

export const getProductUsingPayload = async (
  payload: Record<string, unknown>
): Promise<Product[]> => {
  const { data } = await dataProvider.getOne('products', {
    ...payload,
  });
  return data;
};

export const postDisassemblyTask = async (
  productId: number,
  skuModelIds: number[]
): Promise<void> => {
  await dataProvider.create('disassemblies', {
    data: {
      state: DONE,
      product: productId,
      disassembled_parts: skuModelIds,
    },
  });
};

export const useDisassemblySkuModels = (productId: number): { data: DisassemblySkuModel[] } => {
  const { data } = useQuery({
    type: 'getList',
    resource: 'disassembly-sku-models',
    payload: {
      filter: { disassembly_product_id: productId, spare_part_registration_report_id: '' },
      sort: { field: 'created_at', order: 'DESC' },
    },
  }) as { data: DisassemblySkuModel[] };
  return { data };
};

export const postSparePartRegistrationReport = async (payload: {
  disassembly_sku_model: number;
  state: string;
  checklist: string;
  items: { is_checked: boolean; text: string }[];
}): Promise<number> => {
  const { data } = await dataProvider.create('spare-part-registration-report', {
    data: payload,
  });
  return data.sku;
};

export const useProductCheckList = (checklistType: string): ProductCheckListElement[] => {
  const { data } = useQuery({
    type: 'getList',
    resource: 'product-check-list-elements',
    payload: {
      filter: { checklist: checklistType },
      sort: { field: 'created_at', order: 'DESC' },
    },
  }) as { data: ProductCheckListElement[] };
  return data;
};

export const useSparePartCheckList = (checklistType: string): CheckListElement[] => {
  const { data } = useQuery({
    type: 'getList',
    resource: 'spare-part-check-list-elements',
    payload: {
      filter: { checklist: checklistType },
      sort: { field: 'created_at', order: 'DESC' },
    },
  }) as { data: CheckListElement[] };
  return data;
};

export const postSkuDemandWorkshop = async (payload: Record<string, unknown>): Promise<void> => {
  await dataProvider.create('sku-demand-workshop', {
    data: payload,
  });
};

type SkuModelsReturnValue = {
  isSuccess: boolean;
  skuModels: SkuModelReferential[];
  error: string | undefined;
};
export const useSkuModels = (
  reference: string,
  brand: string,
  category: string,
  aswoDeviceId: string
): SkuModelsReturnValue => {
  const [skuModels, setSkuModels] = useState<SkuModelReferential[]>([] as SkuModelReferential[]);
  const [error, setError] = useState<string>();

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    let abort = false;
    const fetchSkuModels = async () => {
      try {
        const data = await getSkuModels({
          product_reference: reference,
          brand: parseInt(brand),
          category: parseInt(category),
          aswo_device_id: aswoDeviceId,
        });
        if (!abort) {
          if (data.length > 0) {
            setSkuModels(data);
          } else {
            setError("Aucune pièce n'a été trouvé pour ce modèle");
          }
          setIsSuccess(true);
        }
      } catch (errors) {
        setError(`Modèle de machine inconnu: \n ${errors}`);
      }
    };
    fetchSkuModels();
    return () => {
      abort = true;
    };
  }, [reference, brand, category, aswoDeviceId]);

  return { isSuccess, skuModels, error };
};
