import { PaymentMethod } from '../form-values';

export const CREDIT_CARD_DEFERRED = 'credit_card_deferred';
export const CREDIT_CARD = 'credit_card';
export const CASH = 'cash';
export const CHECK = 'check';

export type paymentModeArray = { value: string; name: string };

export const digitalPaymentModeArray: paymentModeArray[] = [
  { value: CREDIT_CARD, name: 'TPE' },
  { value: CREDIT_CARD_DEFERRED, name: 'Empreinte CB' },
];

export const physicalPaymentModeArray: paymentModeArray[] = [
  { value: CASH, name: 'En espèces' },
  { value: CHECK, name: 'Par chèque' },
];

export const paymentModeArray: paymentModeArray[] = [
  ...digitalPaymentModeArray,
  ...physicalPaymentModeArray,
];

export const getPaymentModeArray = (hasStripePaymentMethod = false): paymentModeArray[] => {
  if (hasStripePaymentMethod) return digitalPaymentModeArray;
  return digitalPaymentModeArray.filter(
    (paymentMethod) => paymentMethod.value !== CREDIT_CARD_DEFERRED
  );
};

export const getMethodName = (method: PaymentMethod): string | undefined => {
  return paymentModeArray.find((x) => x.value === method)?.name;
};
