import React from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import ButtonIcon from 'components/atoms/ButtonIcon';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

type QuantitySelectorProps = {
  handleCustomInput: (value: number) => void;
  value: number;
};

const StyledAddIcon = styled(AddCircleIcon)((props) => ({
  color: props.theme.colors.turquoise,
  height: '-webkit-fill-available',
}));

const StyledRemoveIcon = styled(RemoveCircleIcon)((props) => ({
  color: props.theme.colors.turquoise,
  height: '-webkit-fill-available',
}));

const StyledInput = styled(Input)((props) => ({
  height: props.theme.sizes[5],
  paddingTop: 0,
}));

const QuantitySelector: React.FunctionComponent<QuantitySelectorProps> = ({
  handleCustomInput,
  value,
}: QuantitySelectorProps) => {
  return (
    <Box
      backgroundColor="#FFFFFF"
      display="flex"
      mt={2}
      borderBottom={'1px solid #E7E7E7'}
      px={2}
      justifyContent="space-between"
      height={7}
    >
      <Text my="auto" variant="small">
        Quantité
      </Text>
      <Box display="flex" justifyContent="space-evenly">
        {value > 0 && (
          <ButtonIcon icon={StyledRemoveIcon} handleClick={() => handleCustomInput(value - 1)} />
        )}
        <Box mx={2} marginY="auto">
          <StyledInput
            width={7}
            value={value}
            type="number"
            onInput={(event: { target: { value: number } }) => {
              event.target.value ? handleCustomInput(event.target.value) : handleCustomInput(0);
            }}
          />
        </Box>
        <ButtonIcon icon={StyledAddIcon} handleClick={() => handleCustomInput(value + 1)} />
      </Box>
    </Box>
  );
};

export default QuantitySelector;
