import React from 'react';

import Box from 'components/atoms/Box';
import ChoiceButton from 'components/atoms/ChoiceButton';

type Choice<T> = {
  name: string;
  value: T;
};

export type MultiChoicesButtonProps<T> = {
  choices: Choice<T>[];
  action: (value: T) => void;
  selectedValue?: Choice<T>['value'] | null;
};

// We use the function syntax here so typescript
// can recognize the generic type
function MultiChoicesButton<T extends string | boolean | number>({
  choices,
  action,
  selectedValue,
}: MultiChoicesButtonProps<T>): React.ReactElement {
  return (
    <Box display="flex" flexDirection="column">
      {choices.map((choice) => (
        <ChoiceButton
          mx={2}
          my={1}
          key={choice.name}
          onClick={() => action(choice.value)}
          active={choice.value === selectedValue}
        >
          <Box textAlign="initial"> {choice.name}</Box>
        </ChoiceButton>
      ))}
    </Box>
  );
}
export default MultiChoicesButton;
