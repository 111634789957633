import dataProvider from 'dataProvider';

import { ProductCategory } from 'types/product-categories';
import { ProductInstallation } from 'types/product-installations';
import { ProductType } from 'types/product-types';
import { Product } from 'types/products';

export const getProductTypes = async (): Promise<ProductType[]> => {
  const { data } = await dataProvider.getList('product-types', {
    filter: '',
    sort: { field: 'name', order: 'ASC' },
  });
  return data;
};

export const getProductCategories = async (productTypeId: number): Promise<ProductCategory[]> => {
  const { data } = await dataProvider.getList('product-categories', {
    filter: { product_type: productTypeId },
    sort: { field: 'name', order: 'ASC' },
  });
  return data;
};

export const getProductInstallations = async (
  productTypeId: number
): Promise<ProductInstallation[]> => {
  const { data } = await dataProvider.getList('installation-types', {
    filter: { product_type: productTypeId },
    sort: { field: 'name', order: 'ASC' },
  });
  return data;
};

export const upsertProduct = async (
  defaultProductId: number | null,
  productPayload: any
): Promise<Product> => {
  if (defaultProductId) {
    const { data } = await dataProvider.update(
      'products',
      {
        id: defaultProductId,
        data: productPayload,
      },
      'formData'
    );
    return data;
  } else {
    const { data } = await dataProvider.create('products', { data: productPayload }, 'formData');
    return data;
  }
};
