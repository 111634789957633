import React, { useState } from 'react';

import { Button, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import animationData from 'assets/check.json';
import dataProvider from 'dataProvider';
import Lottie from 'react-lottie';

import { SparePart } from 'types/spare-part';

import RepairmanStockStyles from '../RepairmanStockStyles';

const useStyles = makeStyles(RepairmanStockStyles);

const animationOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type IncomingSkuConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  TransitionComponent: React.FunctionComponent<TransitionProps>;
  incomingSku: SparePart;
};

const IncomingSkuConfirmationModal: React.FunctionComponent<IncomingSkuConfirmationModalProps> = ({
  open,
  onClose,
  TransitionComponent,
  incomingSku,
}: IncomingSkuConfirmationModalProps) => {
  const classes = useStyles();
  const [isLottieAnimationStopped, setIsLottieAnimationStopped] = useState(true);

  const handleValidateReception = () => {
    setIsLottieAnimationStopped(false);
    setTimeout(() => {
      handleCloseModal();
    }, 2000);
  };

  const handleSectionClick = (event: React.SyntheticEvent<unknown>) => {
    if (!isLottieAnimationStopped) {
      handleCloseModal();
    } else {
      event.stopPropagation();
    }
  };

  const handleCloseModal = () => {
    setIsLottieAnimationStopped(true);
    dataProvider
      .update('sku', {
        id: incomingSku.id,
        data: { status: 'received_tech', reception: 'out_of_package' },
      })
      .then((response) => {
        if (response) {
          onClose();
        }
      });
  };

  return (
    <Dialog fullScreen TransitionComponent={TransitionComponent} open={open} onClose={onClose}>
      <CloseIcon className={classes.closeButton} onClick={onClose} />

      <div className={classes.incomingSkuWrapper} onClick={handleSectionClick}>
        <div>
          <Typography variant="h6">
            {incomingSku.sku_model && incomingSku.sku_model.supplier_reference}
          </Typography>
          <Typography variant="h5">
            {incomingSku.sku_model && incomingSku.sku_model.designation}
          </Typography>
        </div>

        <Lottie
          options={animationOptions}
          height={150}
          width={150}
          isStopped={isLottieAnimationStopped}
          isClickToPauseDisabled={true}
        />
        <Button
          disabled={!isLottieAnimationStopped}
          onClick={handleValidateReception}
          className={classes.saveButton}
        >
          VALIDER LA RÉCEPTION
        </Button>
      </div>
    </Dialog>
  );
};

export default IncomingSkuConfirmationModal;
