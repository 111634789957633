import React from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import Text from 'components/atoms/Text';

const StyledNavLink = styled(NavLink)((props) => ({
  color: props.theme.colors.grey900,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [`&.${props.activeClassName}`]: {
    color: props.theme.colors.turquoise,
  },
}));

type NavButtonProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
} & NavLinkProps;

const NavButton: React.FunctionComponent<NavButtonProps> = ({
  children,
  icon,
  ...navLinkProps
}: NavButtonProps) => {
  return (
    <StyledNavLink activeClassName="active" {...navLinkProps}>
      {icon}
      <Text variant="caption" color="inherit">
        {children}
      </Text>
    </StyledNavLink>
  );
};

export default NavButton;
