// React imports
import React, { useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
// Component imports
import Button from '@material-ui/core/Button';
// Style imports
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import { Field, useField, useForm } from 'react-final-form';

import HermeticUnitPicture from 'pages/RepairReport/components/formComponents/HermeticUnitPicture';
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
import RepairReportSparePartStockAdditionStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportSparePartStockAdditionStyle';
import RepairReportTextAreaStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportTextAreaStyle';

const useCreationStyles = makeStyles(RepairReportSparePartStockAdditionStyle);
const useTextAreaStyles = makeStyles(RepairReportTextAreaStyle);

export default function RepairReportSparePartCommand({ validateFunction, formProductIdReference }) {
  var hermeticUnitOrder = [];
  useEffect(() => {}, [hermeticUnitOrder]);

  const textAreaStyles = useTextAreaStyles();
  const creationClasses = useCreationStyles();
  const {
    input: { onChange: hermeticUnitAddition },
  } = useField(formProductIdReference + '.hermetic_unit_order');
  const {
    input: { onChange: hermeticUnitPictureAddition },
  } = useField(formProductIdReference + '.hermetic_unit_order_picture');
  const form = useForm();
  var formdata = form.getState().values;
  const hermeticUnitObject =
    formdata[formProductIdReference] && formdata[formProductIdReference].hermetic_unit_order;
  const hermeticUnitPicture =
    formdata[formProductIdReference] &&
    formdata[formProductIdReference].hermetic_unit_order_picture;
  const [nbSparePartToOrder, setNbSparePartToOrder] = useState(1);
  const [nbHermeticUnitOrder, setNbHermeticUnitOrder] = useState(
    (formdata[formProductIdReference] &&
      formdata[formProductIdReference].hermeticUnitObject &&
      formdata[formProductIdReference].hermeticUnitObject.length) ||
      0
  );
  let shouldBeDisabled = false;

  var sparePartToOrder = [];

  if (nbHermeticUnitOrder >= 1) {
    shouldBeDisabled = true;
    if (hermeticUnitObject && hermeticUnitObject.length >= 1) {
      hermeticUnitObject.map((hermeticUnit) => {
        if (
          hermeticUnit.hp_diameter_compressor_outlet &&
          hermeticUnit.hp_diameter_inlet_dehydrator &&
          hermeticUnit.capillary_diameter &&
          hermeticUnit.diameter_bp_aspiration &&
          hermeticUnitPicture &&
          hermeticUnitPicture &&
          hermeticUnitPicture.length > 0
        ) {
          shouldBeDisabled = false;
        }
        return shouldBeDisabled;
      });
    }
  }
  const removeHermeticUnit = (id) => {
    hermeticUnitOrder = hermeticUnitOrder.filter((hermeticUnit) => hermeticUnit.id !== id);
    hermeticUnitObject && hermeticUnitObject.length > 0 && hermeticUnitObject.splice(id, 1);
    hermeticUnitPicture && hermeticUnitPicture.length > 0 && hermeticUnitPicture.splice(id, 1);
    setNbHermeticUnitOrder(nbHermeticUnitOrder - 1);
    hermeticUnitAddition(hermeticUnitObject);
    hermeticUnitPictureAddition(hermeticUnitPicture);
  };

  for (let i = 0; i < nbSparePartToOrder; i++) {
    sparePartToOrder.push(
      <>
        <Typography variant="h5" className={creationClasses.pieceNumber}>
          {`Pièce ${i + 1}`}
        </Typography>
        <Field
          name={formProductIdReference + `.spare_parts_to_order.[${i}].name`}
          key={`name_${i}`}
        >
          {(props) => (
            <div>
              <div className={creationClasses.newSparePartContainer}>
                {
                  <TextField
                    variant="outlined"
                    className={textAreaStyles.basicTextArea}
                    name="piece_name"
                    value={props.input.value}
                    onChange={props.input.onChange}
                    placeholder="Nom de la pièce"
                  />
                }
              </div>
            </div>
          )}
        </Field>
        <Field name={formProductIdReference + `.spare_parts_to_order.[${i}].ref`} key={`ref_${i}`}>
          {(props) => (
            <div>
              <div className={creationClasses.newSparePartContainer}>
                {
                  <TextField
                    variant="outlined"
                    name="reference"
                    className={textAreaStyles.basicTextArea}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    placeholder="Référence de la pièce"
                  />
                }
              </div>
            </div>
          )}
        </Field>
      </>
    );
  }

  for (let i = 0; i < nbHermeticUnitOrder; i++) {
    hermeticUnitOrder.push({
      id: i,
      component: (
        <div key={i} id={i}>
          <Typography variant="h5" className={creationClasses.pieceNumber}>
            {`Intervention sur Unité hermétique - Froid`}
            <RemoveCircleOutlineOutlinedIcon
              className={creationClasses.onCloseIcon}
              onClick={() => removeHermeticUnit(i)}
            />
          </Typography>
          <Field
            name={
              formProductIdReference + `.hermetic_unit_order.[${i}].hp_diameter_compressor_outlet`
            }
            key={`hp_diameter_compressor_outlet_${i}`}
          >
            {(props) => (
              <div>
                <div className={creationClasses.newSparePartContainer}>
                  {
                    <TextField
                      variant="outlined"
                      name="hp_diameter_compressor_outlet"
                      className={textAreaStyles.basicTextArea}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder="Diamètre HP sortie compresseur"
                    />
                  }
                </div>
              </div>
            )}
          </Field>
          <Field
            name={
              formProductIdReference + `.hermetic_unit_order.[${i}].hp_diameter_inlet_dehydrator`
            }
            key={`hp_diameter_inlet_dehydrator_${i}`}
          >
            {(props) => (
              <div>
                <div className={creationClasses.newSparePartContainer}>
                  {
                    <TextField
                      variant="outlined"
                      name="hp_diameter_inlet_dehydrator"
                      className={textAreaStyles.basicTextArea}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder="Diamètre HP entrée déshydrateur"
                    />
                  }
                </div>
              </div>
            )}
          </Field>
          <Field
            name={formProductIdReference + `.hermetic_unit_order.[${i}].capillary_diameter`}
            key={`capillary_diameter_${i}`}
          >
            {(props) => (
              <div>
                <div className={creationClasses.newSparePartContainer}>
                  {
                    <TextField
                      variant="outlined"
                      name="capillary_diameter"
                      className={textAreaStyles.basicTextArea}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder="Diamètre capillaire"
                    />
                  }
                </div>
              </div>
            )}
          </Field>
          <Field
            name={formProductIdReference + `.hermetic_unit_order.[${i}].diameter_bp_aspiration`}
            key={`diameter_bp_aspiration_${i}`}
          >
            {(props) => (
              <div>
                <div className={creationClasses.newSparePartContainer}>
                  {
                    <TextField
                      variant="outlined"
                      name="diameter_bp_aspiration"
                      className={textAreaStyles.basicTextArea}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder="Diamètre BP aspiration"
                    />
                  }
                </div>
              </div>
            )}
          </Field>
          <HermeticUnitPicture formProductIdReference={formProductIdReference} id={i} />
        </div>
      ),
    });
  }

  return (
    <>
      <div>
        {sparePartToOrder}
        {hermeticUnitOrder.length > 0 &&
          hermeticUnitOrder.map((hermeticUnit) => hermeticUnit.component)}
        <Button
          id="add-new-spare-part-button"
          className={creationClasses.addNewSparePartButton}
          onClick={() => setNbSparePartToOrder(nbSparePartToOrder + 1)}
        >
          <span className={creationClasses.underlinedLabel}>+ Demander une nouvelle pièce</span>
        </Button>
        {nbHermeticUnitOrder > 0 ? null : (
          <Button
            id="add-hermetic-unit-button"
            className={creationClasses.addNewSparePartButton}
            onClick={() => setNbHermeticUnitOrder(nbHermeticUnitOrder + 1)}
          >
            <span className={creationClasses.underlinedLabel}>
              + Intervention sur Unité hermétique
            </span>
          </Button>
        )}
      </div>
      <RepairReportValidateButton
        buttonLabel={`Continuer`}
        buttonValue="validate"
        validateFunction={() => validateFunction(nbHermeticUnitOrder >= 1)}
        shouldBeDisabled={shouldBeDisabled}
      />
    </>
  );
}
