import React from 'react';

import StatusField from 'components/StatusField/StatusField';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { SkuDemandHistory } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type SkuDemandBlockProps = {
  skuDemand: SkuDemandHistory;
};

const SkuDemand: React.FunctionComponent<SkuDemandBlockProps> = ({
  skuDemand,
}: SkuDemandBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={skuDemand.data.created_at}
          assignee={skuDemand.data.created_by}
          title={`Besoin pièce(s)`}
          tagVariant={'ternary'}
        />
      </Box>
      {isOpen && (
        <>
          <Box width="100%">
            {skuDemand.data?.items?.map((demandItem) => {
              return (
                <SimpleBlockContent containerStyle key={demandItem.id} noSeparator>
                  <Box justifyContent="space-between" display="flex" mt={1} mb={1}>
                    <Box maxWidth="75%" margin={0}>
                      <Text variant="smallBold" display="flex">
                        {` ${demandItem.designation} `}
                      </Text>
                      <Text variant="small" display="flex">
                        {` ${demandItem.supplier} `}
                      </Text>
                      <Text variant="small" color="grey500" display="flex">
                        {` ${demandItem.reference} `}
                      </Text>
                    </Box>
                    <Box margin={'auto 0'}>
                      <StatusField status={demandItem.status} />
                    </Box>
                  </Box>
                </SimpleBlockContent>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
export default SkuDemand;
