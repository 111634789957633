import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import IncentiveSystemStyles from 'pages/IncentiveSystem/styles/incentiveSystemStyles';

import IncentiveFilterModalList from './IncentiveFilterModalList';
import IncentiveSystemFilterButton from './IncentiveSystemFilterButton';
import IncentiveFilterByDate from './IncentiveSystemFilterByDate';

const useStyles = makeStyles({
  paper: {
    height: '-webkit-fill-available',
    width: '100%',
    overflow: 'auto',
    background: '#F1F0F5',
    '&:focus': {
      outline: 'none',
    },
  },
});
const styleList = makeStyles(IncentiveSystemStyles);

export default function IncentiveFilterModal(props) {
  const classes = useStyles();
  const modalStyle = styleList();
  const handleIncentiveFilter = () => {
    props.handleClose();
    props.handleSetFIlters();
  };

  const bodyModal = (
    <div className={classes.paper}>
      <div className={modalStyle.filterHeader}>
        <AppBar color="transparent" className={modalStyle.header}>
          <div className={modalStyle.elementHeader}>
            <IconButton
              edge="start"
              onClick={() => props.handleClose()}
              className={modalStyle.back}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>Filtrer</span>
          </div>
        </AppBar>
      </div>
      <IncentiveFilterByDate
        setStartedDate={props.setStartedDate}
        setEndedDate={props.setEndedDate}
        startedDate={props.startedDate}
        endedDate={props.endedDate}
      />
      <IncentiveFilterModalList
        setLabelValueToFilter={props.setLabelValueToFilter}
        listRepairReportLabel={props.listRepairReportLabel}
        onCheck={props.onCheck}
      />
      <IncentiveSystemFilterButton
        handleIncentiveFilter={handleIncentiveFilter}
        buttonLabel="Filtrer"
      />
    </div>
  );

  return (
    <div>
      <Modal open={props.open} onClose={props.handleClose}>
        {bodyModal}
      </Modal>
    </div>
  );
}
