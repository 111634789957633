// React imports
import React, { useState } from 'react';

// Icons
import { Button, Paper, Typography } from '@material-ui/core';
// Styles imports
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';

// Component imports
import RepairReportSnackbar from 'pages/RepairReport/components/formComponents/RepairReportSnackbar';
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
import RepairReportNameplateStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportNameplateStyle';

import { compressImage } from 'utils/images';

const useStyles = makeStyles(RepairReportNameplateStyle);
const StyledButton = withStyles({
  root: {
    backgroundColor: '#FCD660',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FCD660',
      },
    },
  },
})(Button);

export default function PaymentProofStepComponent(props) {
  const classes = useStyles();
  const [isPictureChosen, setIsPictureChosen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const {
    input: { onChange: onCheckProofPictureAddition },
  } = useField('payment.payment_proof');

  const onDrop = (uploadedPicture) => {
    const newMedia = [];
    uploadedPicture &&
      uploadedPicture.map((image) =>
        newMedia.push({
          tag: 'payment_proof',
          file: image,
        })
      );

    compressImage(newMedia[0].file, 'preuve_paiement.png')
      .then((compressedFile) => {
        newMedia[0].file = compressedFile;
      })
      .catch((error) => {
        console.error(error.message);
      });

    setPictures(newMedia);
    onCheckProofPictureAddition(newMedia);
    setIsPictureChosen(true);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const resetStep = () => {
    setPictures([]);
    setIsPictureChosen(false);
  };

  return (
    <div>
      {isPictureChosen ? (
        <div className={classes.nameplateBackground}>
          <div className={classes.nameplateContainerCamera}>
            {pictures && (
              <img
                className={classes.videoContainer}
                src={URL.createObjectURL(pictures[0].file)}
                alt="preview"
              />
            )}
            <Button id="re-add-media" className={classes.retakePictureButton} onClick={resetStep}>
              <p className={classes.secondaryButtonContent}> Recommencer </p>
            </Button>
            <div>
              <CheckCircleIcon className={classes.checkedIcon} />
              <span className={classes.infoPicture}>
                {`${pictures && pictures.length} `}
                {pictures && pictures.length > 0 ? 'photo' : ''}
              </span>
            </div>
          </div>
          <div className={classes.addNewMediaButtonContainer}>
            <RepairReportValidateButton
              buttonLabel={`Continuer`}
              //trigger the redirection on the dashboard
              validateFunction={() => props.validateFunction(true)}
              buttonValue="validate"
              step={props.step}
            />
            {((props.errorMessage && props.isErrorShown) || props.isDataMissing) && (
              <RepairReportSnackbar
                errorMessage={props.errorMessage}
                isDataMissing={props.isDataMissing}
                messageForMissingData={props.messageForMissingData}
                dataMissing={props.dataMissing}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <Paper className={classes.checkProofContainer}>
            <div className={classes.nameplateContent}>
              <CameraAltRoundedIcon className={classes.cameraIcon} />
              <Typography variant="h6" gutterBottom>
                Prenez en photo le paiement encaissé.
              </Typography>
            </div>
            <StyledButton className={classes.validateButton} {...getRootProps()}>
              <input id="test" {...getInputProps()} />
              Ajouter une photo
            </StyledButton>
          </Paper>
        </div>
      )}
    </div>
  );
}
