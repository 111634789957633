import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import { IncentiveLabel } from 'types/incentive-label';

type IncentiveFilterModalListProps = {
  setLabelValueToFilter: (labelList: IncentiveLabel) => void;
  listRepairReportLabel: [{ name: string; id: IncentiveLabel }];
  onCheck: (label: IncentiveLabel) => boolean;
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    color: '#2B3B5C',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    lineSeight: '140%',
    marginTop: 24,
    padding: 0,
  },
  checkbox: {
    color: '#8795B3',
  },
  labelName: {
    '& span': {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '135%',
    },
  },
  itemList: {
    padding: 16,
  },
});

const IncentiveFilterModalList: React.FunctionComponent<IncentiveFilterModalListProps> = (
  props: IncentiveFilterModalListProps
) => {
  const classes = useStyles();
  return (
    <List dense className={classes.root}>
      {props.listRepairReportLabel.map((label, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem key={index} className={classes.itemList}>
              <ListItemText className={classes.labelName} primary={label.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  edge="end"
                  value={label.id}
                  onClick={() => props.setLabelValueToFilter(label.id as IncentiveLabel)}
                  checked={props.onCheck(label.id)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default IncentiveFilterModalList;
