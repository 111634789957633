import React, { useState } from 'react';

import { AppBar, Box, Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';

import SecondHandIcon from 'components/atoms/SecondHandIcon';

import { generateQrCode } from 'utils/QrCodeUtils';
import { generateUniqueId } from 'utils/skuUniqueIdGenerator';

import StoreStyles from '../StoreStyles';
import PrintContent from './PrintContent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(StoreStyles);

export function ManualConfirmationModal(props) {
  const classes = useStyles();
  const [qrCode, setQrCode] = React.useState();
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [code, setCode] = useState();
  const updateQrCode = (code) => {
    generateQrCode(code, setQrCode);
  };

  const handleOpenResumeModal = () => {
    if (props.mode === 'packing') {
      generateUniqueId(props.sku).then(handlePrint);
    } else if (props.mode === 'transfer') {
      generateUniqueId(props.sku).then(props.handleValidatePacking(props.sku?.unique_id));
    }
  };

  const handlePrint = (code) => {
    setCode(code);
    setIsResumeModalOpen(true);
    updateQrCode(code);
  };

  const handleCloseResumeModal = () => {
    props.handleValidatePacking(code);
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={props.TransitionComponent}
      open={props.open}
      onClose={props.onClose}
    >
      <AppBar color="transparent" className={classes.header}>
        <div className={classes.elementHeader}>
          <IconButton edge="start" onClick={props.onClose} className={classes.back}>
            <ArrowBackIcon />
          </IconButton>
          <span>{props.employeeName}</span>
        </div>
      </AppBar>
      <div className={classes.lossDialog}>
        <div className={classes.lossCard}>
          <div className={classes.lossHeadingText}>Référence</div>
          <div className={classes.lossDetailText}>
            {props.sku?.sku_model?.sku_reference}
            <Box ml={1}>{props.sku?.is_second_hand && <SecondHandIcon />}</Box>
          </div>
        </div>

        <div className={classes.lossToolbar}>
          <div className={classes.wrapper} style={{ paddingLeft: 16, paddingTop: 10 }}>
            <Button
              className={`${classes.button} ${classes.buttonOrange}`}
              onClick={handleOpenResumeModal}
            >
              {props.mode === 'packing' ? 'Valider le colisage' : 'Valider la sélection'}
            </Button>
          </div>
        </div>
      </div>
      <PrintContent
        TransitionComponent={Transition}
        open={isResumeModalOpen}
        onClose={handleCloseResumeModal}
        firstText={code}
        secondText="Bien étiqueter cette pièce"
        codeSubtitle={code}
        qrCode={qrCode}
        handleNextStep={handleCloseResumeModal}
        headingText={props.sku?.is_second_hand && 'OCCASION'}
        LeftIcon={props.sku?.is_second_hand && CachedIcon}
        RightIcon={props.sku?.is_second_hand && CachedIcon}
      />
    </Dialog>
  );
}

export default ManualConfirmationModal;
