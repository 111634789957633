import React from 'react';

import RepairCycleCheckList from 'pages/RepairCycleTasks/RepairCycleCheckList/RepairCycleCheckList';
import RepairCycleCleaningTask from 'pages/RepairCycleTasks/RepairCycleCleaningTask/RepairCycleCleaningTask';
import RepairCycleRepairAction from 'pages/RepairCycleTasks/RepairCycleRepairAction/RepairCycleRepairAction';
import RepairCycleSkuDemand from 'pages/RepairCycleTasks/RepairCycleSkuDemand/RepairCycleSkuDemand';

import PortalComponent from 'components/Portal/PortalComponent';
import ScrapTypeform from 'components/TypeformComponents/ScrapTypeform';

import { IrisCondition, IrisDefect, IrisRepair, IrisSection, IrisSymptom } from 'types/iris-codes';
import { Product } from 'types/products';

import ProductDisassemblyTask from './ProductDisassemblyTask/ProductDisassemblyTask';
import RepairCycleComment from './RepairCycleComment/RepairCycleComment';
import RepairCycleDiagTask from './RepairCycleDiagTask/RepairCycleDiagTask';
import RepairCycleRepairReport from './RepairCycleRepairReport/RepairCycleRepairReport';
import {
  CLEANING,
  COMMENT,
  DIAG,
  DISASSEMBLY,
  PHOTO,
  REPAIR_ACTION,
  REPAIR_REPORT,
  SCRAP,
  SECTION_WEAR,
  SKU_DEMAND,
  SPARE_PART_REGISTRATION_REPORT,
} from './RepairCycleUtils';
import SellingPicturesUpdateContainer from './SellingPicturesUpdate/SellingPicturesUpdateContainer';
import SparePartRegistrationTask from './SparePartRegistrationTask/SparePartRegistrationTask';

type RepairCycleTaskScreenProps = {
  open: boolean;
  handleClose: () => void;
  selectedTask: string;
  product: Product;
  irisConditions: IrisCondition[];
  irisDefects: IrisDefect[];
  irisSections: IrisSection[];
  irisSymptoms: IrisSymptom[];
  irisRepairs: IrisRepair[];
};

const RepairCycleTaskScreen: React.FunctionComponent<RepairCycleTaskScreenProps> = ({
  open,
  handleClose,
  selectedTask,
  product,
  irisConditions,
  irisDefects,
  irisSections,
  irisSymptoms,
  irisRepairs,
}: RepairCycleTaskScreenProps) => {
  const renderTask = () => {
    switch (selectedTask) {
      case DIAG:
        return (
          <RepairCycleDiagTask
            productId={product.id}
            handleClose={handleClose}
            irisConditions={irisConditions}
            irisDefects={irisDefects}
            irisSections={irisSections}
            irisSymptoms={irisSymptoms}
          />
        );
      case SCRAP:
        return <ScrapTypeform handleClose={handleClose} product={product} />;
      case REPAIR_REPORT:
        return <RepairCycleRepairReport handleClose={handleClose} product={product} />;

      case SECTION_WEAR:
        return (
          <RepairCycleCheckList
            title="Contrôle des pièces"
            checklist={SECTION_WEAR}
            handleClose={handleClose}
            product={product}
          />
        );

      case SKU_DEMAND:
        return (
          <RepairCycleSkuDemand
            handleClose={handleClose}
            product={product}
            irisSections={irisSections}
          />
        );

      case REPAIR_ACTION:
        return (
          <RepairCycleRepairAction
            handleClose={handleClose}
            product={product}
            irisRepairs={irisRepairs}
            irisSections={irisSections}
          />
        );

      case COMMENT:
        return <RepairCycleComment handleClose={handleClose} product={product} />;

      case CLEANING:
        return <RepairCycleCleaningTask handleClose={handleClose} product={product} />;

      case PHOTO:
        return <SellingPicturesUpdateContainer handleClose={handleClose} productId={product.id} />;

      case DISASSEMBLY:
        return <ProductDisassemblyTask handleClose={handleClose} product={product} />;

      case SPARE_PART_REGISTRATION_REPORT:
        return <SparePartRegistrationTask handleClose={handleClose} product={product} />;

      default:
        return null;
    }
  };
  return (
    <PortalComponent open={open} onClose={handleClose}>
      {renderTask()}
    </PortalComponent>
  );
};

export default RepairCycleTaskScreen;
