import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  basicButton: {
    background: '#FCD660',
    margin: '16px',
    height: 56,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#2B3B5C',
    width: '90%',
  },
  container: {
    background: '#fff',
    position: 'relative',
    width: '100%',
    bottom: 0,
    marginTop: 100,
  },
});

export default function IncentiveSystemFilterButton(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        className={classes.basicButton}
        variant="contained"
        onClick={() => props.handleIncentiveFilter()}
      >
        {props.buttonLabel}
      </Button>
    </div>
  );
}
