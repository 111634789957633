import { useEffect, useState } from 'react';

import { getProductTypes } from 'pages/ProductReception/utils/api';
import {
  OVERALL_APPAREANCE,
  SPARE_PART_CLEANLINESS,
  STARTING_UP,
  WIRES_AND_PIPES,
} from 'pages/RepairCycleTasks/RepairCycleUtils';
import {
  getProductCheckList,
  getProductHistory,
  useIrisConditions,
  useIrisDefects,
  useIrisRepairs,
  useIrisSections,
  useIrisSymptoms,
  useProductTypes,
} from 'pages/RepairCycleTasks/api';

import { DemandItem } from 'types/demand-items';
import { IrisCondition, IrisDefect, IrisRepair, IrisSection, IrisSymptom } from 'types/iris-codes';
import { ProductCheckListElement } from 'types/product-check-list-element';
import { DiagnosticHistory, ProductHistory } from 'types/product-history';
import { ProductType } from 'types/productTypes';
import { Product } from 'types/products';
import { SkuDemand } from 'types/sku-demand';

import { getSkuDemands } from 'utils/api/api';

import { DIAG, PACKED, normalizeIrisCodes } from './RepairCycleUtils';
import { formatProductHistory } from './utils/historyFormatter';

export const useIrisCodes = (): {
  irisConditions: IrisCondition[];
  irisDefects: IrisDefect[];
  irisSymptoms: IrisSymptom[];
  irisSections: IrisSection[];
  irisRepairs: IrisRepair[];
} => {
  const { irisSymptoms } = useIrisSymptoms();
  const { irisSections } = useIrisSections();
  const { irisDefects } = useIrisDefects();
  const { irisConditions } = useIrisConditions();
  const { irisRepairs } = useIrisRepairs();

  const [formattedIrisSections, setFormattedIrisSections] = useState<IrisSection[]>([]);
  const [formattedIrisSymptoms, setFormattedIrisSymptoms] = useState<IrisSymptom[]>([]);
  const [formattedIrisDefects, setFormattedIrisDefects] = useState<IrisDefect[]>([]);
  const [formattedIrisConditions, setFormattedIrisConditions] = useState<IrisCondition[]>([]);
  const [formattedIrisRepairs, setFormattedIrisRepairs] = useState<IrisRepair[]>([]);

  useEffect(() => {
    if (irisSymptoms) {
      setFormattedIrisSymptoms(normalizeIrisCodes(irisSymptoms));
    }
    if (irisSections) {
      setFormattedIrisSections(normalizeIrisCodes(irisSections));
    }
    if (irisDefects) {
      setFormattedIrisDefects(normalizeIrisCodes(irisDefects));
    }
    if (irisConditions) {
      setFormattedIrisConditions(normalizeIrisCodes(irisConditions));
    }
    if (irisRepairs) {
      setFormattedIrisRepairs(normalizeIrisCodes(irisRepairs));
    }
  }, [irisSymptoms, irisSections, irisDefects, irisConditions, irisRepairs]);

  return {
    irisConditions: formattedIrisConditions,
    irisDefects: formattedIrisDefects,
    irisSymptoms: formattedIrisSymptoms,
    irisSections: formattedIrisSections,
    irisRepairs: formattedIrisRepairs,
  };
};

export const useProductChecklist = (
  productTypeName: string,
  checklist: string
): { productCheckList: ProductCheckListElement[]; isLoading: boolean } => {
  const { productTypes } = useProductTypes();
  const [productCheckList, setProductChecklist] = useState<ProductCheckListElement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchProductChecklist = async (productType: ProductType) => {
      const data = await getProductCheckList({
        product_type: productType.id,
        checklist: checklist,
      });
      if (data.length > 0) {
        setProductChecklist(data);
      } else {
        setProductChecklist([]);
      }
      setIsLoading(false);
    };
    if (productTypes) {
      const actualProductType = productTypes?.find(
        (productType) => productType.name === productTypeName
      );
      if (actualProductType) {
        fetchProductChecklist(actualProductType);
      }
    }
  }, [productTypes, productTypeName, checklist]);
  return { productCheckList, isLoading };
};

type DiagnosticsSectionsReturnValue = {
  diagnosticDefectiveSectionIds: string[];
  isLoading?: boolean;
};

export const useDiagnosticSections = (productId: number): DiagnosticsSectionsReturnValue => {
  const [diagnosticDefectiveSectionIds, setDiagnosticDefectiveSectionIds] = useState<string[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const productHistoryData = await getProductHistory(productId.toString());
      const lastDiagnosticEvent = productHistoryData.filter(
        (historyEvent) => historyEvent.type === DIAG
      )[0] as DiagnosticHistory;
      setDiagnosticDefectiveSectionIds(
        lastDiagnosticEvent?.data.section_defects?.map(function (sectionDefect) {
          return sectionDefect.iris_section.toString();
        }) || []
      );
    };
    fetchData();
  }, [productId]);
  return {
    diagnosticDefectiveSectionIds: diagnosticDefectiveSectionIds,
  };
};

export const useProductHistory = (
  productId: number,
  shouldFetch: boolean
): ProductHistory | null => {
  const [productHistoryData, setProductHistoryData] = useState<ProductHistory | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const productHistoryData = await getProductHistory(productId.toString());

      const formattedProductHistory = formatProductHistory(productHistoryData);

      setProductHistoryData(formattedProductHistory);
    };

    if (shouldFetch) {
      fetchData();
    }
  }, [productId, shouldFetch]);
  return productHistoryData || null;
};

export const useDemandItemsReadyToBeFitted = (product: Product): DemandItem[] => {
  const [demandItems, setDemandItems] = useState<DemandItem[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const skuDemands = await getSkuDemands({
        workshop_file: product.workshop_file,
      });
      const demandItems = skuDemands
        .map((skuDemand: SkuDemand) => {
          return skuDemand.items || [];
        })
        .flat();
      setDemandItems(
        demandItems.filter((item) => {
          return item.status === PACKED;
        })
      );
    };
    fetchData();
  }, [product]);
  return demandItems;
};

export const useCleaningChecklist = (product: Product) => {
  const [initialValues, setInitialValues] = useState<any>({
    overall_appearance: [],
    spare_part_cleanliness: [],
    wires_and_pipes: [],
    starting_up: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const productTypes = await getProductTypes();
      if (productTypes) {
        const actualProductType = productTypes?.find(
          (productType) => productType.name === product.product_type
        );
        if (actualProductType) {
          const overallAppearanceChecks = await getProductCheckList({
            product_type: actualProductType.id,
            checklist: OVERALL_APPAREANCE,
          });

          const sparePartCleanlinessChecks = await getProductCheckList({
            product_type: actualProductType.id,
            checklist: SPARE_PART_CLEANLINESS,
          });

          const wireAndPipesChecks = await getProductCheckList({
            product_type: actualProductType.id,
            checklist: WIRES_AND_PIPES,
          });

          const startingUpChecks = await getProductCheckList({
            product_type: actualProductType.id,
            checklist: STARTING_UP,
          });

          setInitialValues({
            overall_appearance: overallAppearanceChecks.map((check: ProductCheckListElement) => {
              return {
                id: check.id,
                text: check.display_text,
                is_checked: false,
              };
            }),
            spare_part_cleanliness: sparePartCleanlinessChecks.map(
              (check: ProductCheckListElement) => {
                return {
                  id: check.id,
                  text: check.display_text,
                  is_checked: false,
                };
              }
            ),
            wires_and_pipes: wireAndPipesChecks.map((check: ProductCheckListElement) => {
              return {
                id: check.id,
                text: check.display_text,
                is_checked: false,
              };
            }),
            starting_up: startingUpChecks.map((check: ProductCheckListElement) => {
              return {
                id: check.id,
                text: check.display_text,
                is_checked: false,
              };
            }),
          });

          setLoading(false);
        }
      }
    };
    fetchData();
  }, [product]);

  return { initialValues, loading };
};
