import React from 'react';

import { useGetOne } from 'react-admin';

import { RouteComponentProps } from 'react-router-dom';

import PageLayout from 'components/templates/PageLayout';

import PrequalificationReport from './PrequalificationReport';

interface PrequalificationReportMatchParams {
  prequalificationId: string;
}
type PrequalificationReportProps = RouteComponentProps<PrequalificationReportMatchParams>;

const PrequalificationReportContainer: React.FunctionComponent<PrequalificationReportProps> = ({
  match: {
    params: { prequalificationId },
  },
}: PrequalificationReportProps) => {
  const { data: prequalification } = useGetOne('fault-forecast-detail', prequalificationId);

  if (!prequalification) {
    return <PageLayout />;
  } else {
    return (
      <PrequalificationReport
        productId={prequalification.product.id.toString()}
        intervention={prequalificationId}
      />
    );
  }
};

export default PrequalificationReportContainer;
