import React, { useState } from 'react';

import { TextField } from '@material-ui/core';

import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ChoiceTabs from 'components/molecules/ChoiceTabs';

import QrScanner from 'utils/QrScanner';
import Scanner from 'utils/Scanner';

const QR_CODE = 'qrcode';
const BARCODE = 'barcode';

const tabs = [
  { id: QR_CODE, name: 'QR code' },
  { id: BARCODE, name: 'Code barre ' },
];

const StyledTextField = styled(TextField)((props) => ({
  background: props.theme.colors.white,
  width: 312,
  height: props.theme.sizes[7],
  borderRadius: props.theme.sizes[1],
}));

const ScanContainer = styled.div({
  display: 'inline-grid',
  width: '100%',
});

const FieldContainer = styled.div((props) => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: `${props.theme.space[2]}px ${props.theme.space[3]}px`,
}));

export type ProductSelectionPortalProps = {
  handleConfirmBarcode: (code: string) => void;
  error: string | undefined;
  buttonText?: string;
  disableManualIdentification?: boolean;
};

const ProductSelectionPortal: React.FunctionComponent<ProductSelectionPortalProps> = ({
  handleConfirmBarcode,
  error,
  buttonText = 'Confirmer',
  disableManualIdentification = false,
}: ProductSelectionPortalProps) => {
  const [isModalOpen] = useState<boolean>(false);
  const [isManualInput, setManualInput] = useState(false);
  const [productBarcode, setProductBarcode] = useState<string>();
  const [selectedScan, setSelectedScan] = useState<string>(QR_CODE);

  return (
    <>
      {!isManualInput && (
        <>
          <div>
            {selectedScan === QR_CODE ? (
              <ScanContainer>
                <QrScanner handleConfirmBarcode={handleConfirmBarcode} />
                <Text variant="body" color="red">
                  {error}
                </Text>
              </ScanContainer>
            ) : (
              <Scanner
                height={480}
                handleConfirmBarcode={handleConfirmBarcode}
                isModalOpen={isModalOpen}
              />
            )}
          </div>
          <ChoiceTabs<string>
            tabs={tabs.map((x) => {
              return { value: x.id, name: x.name };
            })}
            action={setSelectedScan}
            selectedValue={selectedScan}
          />
          {!disableManualIdentification && (
            <Button variant="fullTransparent" onClick={() => setManualInput(true)}>
              Identifier manuellement
            </Button>
          )}
        </>
      )}
      {isManualInput && (
        <PortalComponent
          open={isManualInput}
          onClose={() => setManualInput(false)}
          title="Identification manuelle"
        >
          <FieldContainer>
            <StyledTextField
              variant="outlined"
              margin="none"
              label="Code barres de la machine"
              name="Code barres de la machine"
              value={productBarcode}
              onChange={(event) => setProductBarcode(event.target.value)}
            />
            {error && (
              <Text variant="body" color="red" mt={1}>
                {error}
              </Text>
            )}
            {productBarcode && (
              <BottomButton
                variant="primary"
                onClick={() => {
                  handleConfirmBarcode(productBarcode);
                  setManualInput(false);
                }}
                data-testid="bottom-button"
              >
                {buttonText}
              </BottomButton>
            )}
          </FieldContainer>
        </PortalComponent>
      )}
    </>
  );
};

export default ProductSelectionPortal;
