import { ElementType } from 'types/utils';

export const STEPS_LIST = [
  {
    id: 'dashboard',
    stepQuestion: 'dashboard',
    stepName: `Dashboard`,
  },
  {
    id: 'product_list',
    stepQuestion: '',
    stepName: `Compte-rendu`,
  },
  {
    id: 'new_product',
    stepQuestion: 'Choisissez le produit',
    stepName: `Type d'appareil`,
  },
  {
    id: 'appliance_nameplate',
    stepQuestion: 'Identification',
    stepName: `Identification`,
  },
  {
    id: 'is_appliance_repaired',
    stepQuestion: `La réparation est-elle terminée?`,
    stepName: 'État de la réparation',
  },
  {
    id: 'is_spare_part_used_reparation_done',
    stepQuestion: 'Avez-vous posé des pièces?',
    stepName: `Constat`,
  },
  {
    id: 'is_spare_part_used_reparation_not_done',
    stepQuestion: 'Avez-vous posé des pièces?',
    stepName: `Constat`,
  },
  {
    id: 'intervention_not_done_conclusion_with_sp',
    stepQuestion: `Issue de l'intervention:`,
    stepName: `Constat`,
  },
  {
    id: 'intervention_not_done_conclusion_without_sp',
    stepQuestion: `Issue de l'intervention:`,
    stepName: `Constat`,
  },
  {
    id: 'additional_information',
    stepQuestion: `Infos complémentaires:`,
    stepName: `Constat`,
  },
  {
    id: 'appliance_test',
    stepQuestion: `Test de la machine:`,
    stepName: `Constat`,
  },
  {
    id: 'symptoms_diagnosis_repaired',
    stepQuestion: `Symptômes:`,
    stepName: `Détails`,
  },
  {
    id: 'symptoms_diagnosis_not_repaired',
    stepQuestion: `Symptômes:`,
    stepName: `Détails`,
  },
  {
    id: 'symptoms_diagnosis_not_reparable',
    stepQuestion: `Symptômes:`,
    stepName: `Détails`,
  },
  {
    id: 'which_spare_parts_reparation_done',
    stepQuestion: `Indiquez les pièces posées: `,
    stepName: `Détails`,
  },
  {
    id: 'which_spare_parts_reparation_not_done',
    stepQuestion: `Indiquez les pièces posées: `,
    stepName: `Détails`,
  },
  {
    id: 'payment',
    stepQuestion: `Montant encaissé`,
    stepName: `Paiement`,
  },
  {
    id: 'not_paid',
    stepQuestion: `Pourquoi ?`,
    stepName: `Paiement`,
  },
  {
    id: 'report_done',
    stepQuestion: `Rapport enregistré`,
    stepName: `Merci !`,
  },
  {
    id: 'need_spare_part',
    stepQuestion: `Besoin de pièce(s) détachée(s) ?`,
    stepName: `Détails`,
  },
  {
    id: 'payment_proof',
    stepQuestion: `Prenez en photo le chèque encaissé`,
    stepName: `Encaissement`,
  },
  {
    id: 'time-evaluation',
    stepQuestion: `Temps passé (en minutes) ?`,
    stepName: `Détails`,
  },
  {
    id: 'intermediate_validation_screen',
  },
  {
    id: 'intervention_impossible_reason',
    stepQuestion: `Pourquoi ?`,
    stepName: `Détails`,
  },
  {
    id: 'not_repaired_reason',
    stepQuestion: `Pourquoi ?`,
    stepName: `Détails`,
  },
] as const;

export type Step = ElementType<typeof STEPS_LIST>;
export type StepId = Step['id'];

export const INITIAL_STEP: StepId = 'dashboard';

export const paymentModeArray = [
  { id: 'cash', name: 'En espèces' },
  { id: 'credit_card', name: 'TPE' },
  { id: 'check', name: 'Par chèque' },
];

export const notPayedReasonsArray = [
  { id: 'already_payed', name: 'Déjà réglé en ligne' },
  { id: 'customer_warranty', name: 'Client sous garantie' },
  { id: 'customer_service_reco', name: 'SAV Reco' },
  { id: 'reopening', name: 'Réouverture' },
  { id: 'cant_pay', name: `Je n'ai pas pu encaisser` },
];

export const longInterventionReasonsArray = [
  { id: 'multiple_products', name: 'Double appareil' },
  { id: 'recessed_appliance', name: 'Appareil encastré' },
  { id: 'contrained_environment', name: 'Environnement contraint' },
  { id: 'hard_spare_part_allocation', name: 'Pose de piece fastidieuse' },
  { id: 'other', name: `Autre` },
];
export const interventionImpossibleReasonArray = [
  { id: 'customers-absence', name: 'Absence du client' },
  { id: 'product-column', name: 'Colonne de produit' },
  { id: 'lack-of-space-risk-of-damage', name: 'Manque de place - risque de dégâts' },
  { id: 'health-risk', name: 'Risque sanitaire' },
  { id: 'dispute-with-the-customer', name: 'Litige avec le client' },
  { id: 'spare-part-not-found-on-board', name: 'Pièce introuvable à bord' },
];

export const notRepairedReasonArray = [
  { id: 'bearing-porthole-washing-machine', name: 'Roulement Lave-linge hublot' },
  { id: 'clothes-dryer-heat-pump', name: 'Sèche-linge Pompe à chaleur' },
  { id: 'drilled-dishwasher-tub', name: 'Cuve lave-vaisselle percée' },
  { id: 'cold-gas-other-thanR600', name: 'Froid - gaz autre que R600' },
  { id: 'cold-leak-not-identified', name: 'Froid - fuite non-identifiée' },
];

export type ReasonListType = typeof interventionImpossibleReasonArray;

export const numericKeypad = {
  pattern: '[0-9]*', // ios support
  type: 'number', // Field control + android support
};
