import React from 'react';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import CallButton from 'components/CallButton';
import PaperStripLink from 'components/PaperStripLink';
import PartsToInstallPanel from 'components/PartsToInstallPanel';
import ProductDetailPanel from 'components/ProductDetailPanel';
import Box from 'components/atoms/Box';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';
import AddressPanel from 'components/organisms/AddressPanel';

import { Comment } from 'types/comment';
import { CustomerFileDetailed } from 'types/customer-files';
import { Product } from 'types/products';

type VisitSummaryProps = {
  customerFile: CustomerFileDetailed;
  productList: Product[];
  visitType?: string;
  showVisitComments: boolean;
  customerDetailText?: string;
  showCallButton?: boolean;
  visitComments?: Comment;
  noProductDetails?: boolean;
};

const VisitSummary: React.FunctionComponent<VisitSummaryProps> = ({
  customerFile,
  productList,
  visitType,
  showVisitComments,
  customerDetailText = 'Infos Client',
  showCallButton = false,
  visitComments,
  noProductDetails = false,
}: VisitSummaryProps) => {
  return (
    <>
      <AddressPanel customerFile={customerFile} />
      <PaperStripLink
        text={customerDetailText}
        link={`/customer-details/${customerFile.id}`}
        Icon={KeyboardArrowRightIcon}
        linkMode="route"
      />
      <PaperStripLink
        link={`/customer-file/${customerFile.id}/history`}
        linkMode="route"
        Icon={KeyboardArrowRightIcon}
        text="Historique"
      />
      {showCallButton && <CallButton text="Appeler le client" phone={customerFile.phone} mb={3} />}

      {showVisitComments && visitComments && (
        <Box backgroundColor="#FFFFFF">
          <PaperStrip>
            <Text variant="bodyBold">Commentaire</Text>
            <Text ml={1} variant="body">
              {visitComments.text}
            </Text>
            <Text mt={1} variant="bodyBold">
              Pièces jointes
            </Text>
            <Box mx={1} display="grid">
              {visitComments?.attachments.map(
                (attachment: { id: number; file: string }, index: number) => {
                  return (
                    <a
                      key={attachment.id}
                      href={attachment.file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`Document ${index + 1}`}
                    </a>
                  );
                }
              )}
            </Box>
          </PaperStrip>
        </Box>
      )}
      {!noProductDetails && (
        <Box mb={4}>
          {/* The visitType can be undefined if we are looking at a workshop repair because the visit itself is undefined */}
          {(visitType === 'sp_installation' || visitType === 'reopening_sp_installation') &&
            customerFile.last_quote_elements && (
              <PartsToInstallPanel spareParts={customerFile.last_quote_elements} />
            )}
          {!noProductDetails &&
            productList.map((product) => <ProductDetailPanel product={product} key={product.id} />)}
        </Box>
      )}
      {noProductDetails &&
        (visitType === 'sp_installation' || visitType === 'reopening_sp_installation') &&
        customerFile.last_quote_elements && (
          <PartsToInstallPanel spareParts={customerFile.last_quote_elements} />
        )}
    </>
  );
};

export default VisitSummary;
