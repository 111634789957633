import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

import IncentiveFilterModal from './IncentiveFilterModal';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
  },
  input: {
    flex: 1,
    color: '#8795B3',
    fontSize: 15,
    lineHeight: '140%',
    padding: '0 16px',
  },
  iconButton: {
    padding: 10,
    color: '#8795B3',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filterButton: {
    background: '#F4F3F8',
    borderRadius: 10,
    color: '#8795B3',
    margin: '0 20px 0 15px',
  },
});

export default function IncentiveSystemFilter(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [labelList, setLabelList] = React.useState([]);
  const [startedDate, setStartedDate] = React.useState('');
  const [endedDate, setEndedDate] = React.useState('');
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateFilters = (newValues) => {
    props.setFilters({
      ...props.filterValues,
      ...newValues,
    });
  };
  const onCheck = (label) => labelList.includes(label);

  const setLabelValueToFilter = (value) => {
    if (onCheck(value)) {
      setLabelList([...labelList.filter((label) => label !== value)]);
    } else {
      setLabelList([...labelList, value]);
    }
  };

  const handleSetFIlters = () => {
    updateFilters({
      label_name: labelList,
      ended_date: endedDate,
      started_date: startedDate,
      repairman_id: props.repairmanId,
    });
  };
  const handleSetSearchValue = (value) => {
    updateFilters({ repairman_id: props.repairmanId, q: value });
  };

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Recherche"
        onChange={(e) => handleSetSearchValue(e.target.value)}
      />
      <IconButton className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
      <IconButton className={classes.filterButton} aria-label="search" onClick={() => handleOpen()}>
        <FilterListIcon />
      </IconButton>
      <IncentiveFilterModal
        open={open}
        handleClose={handleClose}
        setLabelValueToFilter={setLabelValueToFilter}
        handleSetFIlters={handleSetFIlters}
        setStartedDate={setStartedDate}
        setEndedDate={setEndedDate}
        startedDate={startedDate}
        endedDate={endedDate}
        listRepairReportLabel={props.listRepairReportLabel}
        onCheck={onCheck}
      />
    </Paper>
  );
}
