import React from 'react';

import styled from 'styled-components';

import {
  ADDITIONAL_PACKAGE,
  BASIC_PACKAGE,
  COMMERCIAL_GESTURE,
  CONSUMABLE,
  DISCOUNTED_PACKAGE,
  HERMETIC_UNIT,
  LEGACY_QUOTE,
  QuoteElementType,
  REDUCTION_CODE,
  REPAIR_BONUS,
  SPARE_PART,
  getLabel,
} from 'components/QuoteElementField/utils';
import Box from 'components/atoms/Box';
import ListItemContainer from 'components/atoms/ListItemContainer';
import Text from 'components/atoms/Text';

import { QuoteElement } from 'types/quote-elements';

import { formatPrice } from 'utils/formatting';

type QuoteElementFieldProps = {
  element: QuoteElement;
};

const TurquoiseListItemContainer = styled(ListItemContainer)({
  backgroundColor: 'rgba(164, 231, 219, 0.2)',
});

const QuoteElementField: React.FunctionComponent<QuoteElementFieldProps> = ({
  element,
}: QuoteElementFieldProps) => {
  const renderPrice = (amount: number | undefined) => {
    return amount ? formatPrice(amount) : '?';
  };
  const renderQuoteElement = (elementType: QuoteElementType): React.ReactNode => {
    // overkill for now but will be usefull to personalize some update actions later
    switch (elementType) {
      case ADDITIONAL_PACKAGE:
      case DISCOUNTED_PACKAGE:
        return (
          <ListItemContainer>
            <Box ml={'4px'}>
              <Text variant="small">{getLabel[elementType]}</Text>
              <Text variant="small" color="grey">
                {element.product}
              </Text>
            </Box>
            <Text mr={'48px'} variant="small" textAlign="center">
              {renderPrice(element.amount_withtax)}
            </Text>
          </ListItemContainer>
        );
      case REPAIR_BONUS:
        return (
          <TurquoiseListItemContainer>
            <Box ml={'4px'}>
              <Text variant="smallBold" color="turquoise">
                {getLabel[elementType]}
              </Text>
            </Box>
            <Text mr={'48px'} variant="smallBold" color="turquoise" textAlign="center">
              {renderPrice(element.amount_withtax)}
            </Text>
          </TurquoiseListItemContainer>
        );
      case BASIC_PACKAGE:
      case COMMERCIAL_GESTURE:
      case CONSUMABLE:
      case HERMETIC_UNIT:
      case LEGACY_QUOTE:
      case REDUCTION_CODE:
        return (
          <ListItemContainer>
            <Text ml={'4px'} variant="small">
              {getLabel[elementType]} {element.product}
            </Text>
            <Text mr={'48px'} variant="small" textAlign="center">
              {renderPrice(element.amount_withtax)}
            </Text>
          </ListItemContainer>
        );
      case SPARE_PART:
        return (
          <ListItemContainer>
            <Box ml={'4px'}>
              <Text variant="small">{element?.spare_part?.piece_name}</Text>
              <Text variant="small">{element?.spare_part?.aswo_ref}</Text>
            </Box>
            <Text mr={'48px'} variant="small" textAlign="center">
              {renderPrice(element.amount_withtax)}
            </Text>
          </ListItemContainer>
        );
      default:
        return <></>;
    }
  };
  return <>{renderQuoteElement(element.element_type)}</>;
};

export default QuoteElementField;
