import { AuthProvider } from 'react-admin';

import { Employee } from 'types/employees';

type LoginInformation = {
  username: string;
  password: string;
};

export const createAuthProvider = (
  storeEmployee: (e: Employee) => void,
  clearEmployee: () => void
): AuthProvider => {
  const fetchAuthToken = async ({ username, password }: LoginInformation) => {
    const request = new Request(`${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/authentication/`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  };

  const fetchAccountData = async (token: string) => {
    const request = new Request(`${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/common/account/`, {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      }),
    });
    return fetch(request).then((accountResponse) => {
      return accountResponse.json();
    });
  };

  return {
    login: ({ username, password }: LoginInformation) => {
      return fetchAuthToken({ username, password }).then(({ token, groups }) => {
        localStorage.setItem('token', token);
        localStorage.setItem('groups', JSON.stringify(groups));
        fetchAccountData(token).then((accountData) => {
          storeEmployee(accountData);
        });
      });
    },
    logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('groups');
      clearEmployee();
      return Promise.resolve();
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('groups');
        clearEmployee();
        return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
      const groups = localStorage.getItem('groups');
      return groups ? Promise.resolve(JSON.parse(groups)) : Promise.reject();
    },
  };
};
