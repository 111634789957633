import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { MarginProps, margin, variant } from 'styled-system';

const BadgeVariants = {
  green: {
    color: '#81D89F',
  },
  orange: {
    color: '#F7B349',
  },
};

type ListBadgeProps = {
  variant?: keyof typeof BadgeVariants;
} & MarginProps;

const ListBadge = styled('div')<ListBadgeProps>(
  margin,
  (props: ListBadgeProps) => ({
    borderRadius: 20,
    width: themeGet('space.4')(props),
    height: themeGet('space.4')(props),
    paddingTop: 44,
  }),
  variant({
    variants: BadgeVariants,
  })
);

ListBadge.defaultProps = {
  variant: 'green',
};
export default ListBadge;
