import React, { useState } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { useEmployee } from 'employeeContext';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import { useModals } from 'modalsContext';
import styled from 'styled-components';
import * as yup from 'yup';

import { ASWO_SUPPLIER_ID, renderIrisCodeFromId } from 'pages/RepairCycleTasks/RepairCycleUtils';
import { useDiagnosticSections } from 'pages/RepairCycleTasks/hooks';

import PaperStripAction from 'components/PaperStripAction';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import InputBase from 'components/atoms/InputBase';
import PageLayout from 'components/templates/PageLayout';

import { IrisSection } from 'types/iris-codes';
import { Product } from 'types/products';

import { getSparePartCount, useSkuSuppliers } from 'utils/api/api';

import GenericCodeList from '../GenericIrisCodeList/GenericIrisCodeList';
import { postSkuDemandWorkshop } from '../api';
import SkuSupplierSelector from './SkuSupplierSelector';

type RepairCycleSkuDemandProps = {
  product: Product;
  handleClose: () => void;
  irisSections: IrisSection[];
};

type RepairCycleSparePartFormValues = {
  spare_parts: {
    designation: string;
    reference: string;
    isOpen: boolean;
    sparePartInStockCount: number;
    supplier_id: string;
  }[];
};

const GreenCheckIcon = styled(AddCircleIcon)((props) => ({
  color: props.theme.colors.green,
}));
const GreyRemoveIcon = styled(RemoveCircleOutlineIcon)((props) => ({
  color: props.theme.colors.grey300,
}));

const formatSparePartNeedPayload = (
  values: { designation: string; reference: string; isOpen: boolean; supplier_id: string }[],
  workshopFileId: number
) => {
  return {
    workshop_file: workshopFileId,
    sku_models: values.map(
      (sku: { designation: string; reference: string; isOpen: boolean; supplier_id: string }) => {
        return {
          supplier: sku.supplier_id,
          reference: sku.reference,
          designation: sku.designation,
        };
      }
    ),
  };
};

const StyledInputBase = styled(InputBase)((props) => ({
  outline: 'none',
  border: 'none',
  height: props.theme.sizes[6],
  borderRadius: props.theme.radii[2],
  background: props.theme.colors.grey200,
  margin: 16,
  width: '-webkit-fill-available',
}));

const RepairCycleSkuDemand: React.FunctionComponent<RepairCycleSkuDemandProps> = ({
  product,
  handleClose,
  irisSections,
}: RepairCycleSkuDemandProps) => {
  const { diagnosticDefectiveSectionIds } = useDiagnosticSections(product.id);
  const { skuSuppliers } = useSkuSuppliers();
  const { employee } = useEmployee();
  const { showModal, closeModal } = useModals();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    spare_parts: diagnosticDefectiveSectionIds.map((section: string) => {
      return {
        reference: '',
        designation: renderIrisCodeFromId(irisSections, parseInt(section)),
        isOpen: false,
        sparePartInStockCount: 0,
        supplier_id: ASWO_SUPPLIER_ID,
      };
    }),
  };
  const [isSectionAdditionOpen, setIsSectionAdditionOpen] = useState<boolean>(false);

  const handleConfirmSkuDemandSubmission = async (
    sparePartsWithReference: {
      designation: string;
      reference: string;
      isOpen: boolean;
      supplier_id: string;
    }[]
  ) => {
    setIsLoading(true);
    if (product.workshop_file && skuSuppliers) {
      await postSkuDemandWorkshop(
        formatSparePartNeedPayload(
          sparePartsWithReference as {
            designation: string;
            reference: string;
            isOpen: boolean;
            supplier_id: string;
          }[],
          product.workshop_file
        )
      );
    }
    setIsLoading(false);
    handleClose();
  };

  const handleSparePartsSubmit = async (values: RepairCycleSparePartFormValues) => {
    const sparePartsWithReference = values.spare_parts.filter(
      (sparePart: {
        designation: string;
        reference: string;
        isOpen: boolean;
        sparePartInStockCount: number;
        supplier_id: string;
      }) => sparePart.reference && sparePart.reference.length > 0
    );

    const inStockSpareParts = sparePartsWithReference.filter((sparePartWithReference) => {
      return sparePartWithReference.sparePartInStockCount > 0;
    });

    if (sparePartsWithReference.length > 0) {
      if (inStockSpareParts.length > 0) {
        showModal({
          modalToShow: 'CONFIRM_MODAL',
          modalProps: {
            title: "Des pièces sont disponibles dans le stock de l'atelier:",
            content: `${inStockSpareParts.map((sparePartReference) => {
              return `\n - ${sparePartReference.reference}`;
            })}`,
            cancelLabel: 'Annuler',
            confirmLabel: 'Commander la/les pièces',
            onCancel: closeModal,
            onConfirm: () => {
              handleConfirmSkuDemandSubmission(sparePartsWithReference);
              closeModal();
            },
          },
        });
      } else {
        handleConfirmSkuDemandSubmission(sparePartsWithReference);
      }
    }
  };

  const validationSchema = yup.object().shape({
    spare_part: yup.array().min(1, 'Minimum une piece'),
  });

  return (
    <PageLayout>
      <Box overflow="auto">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSparePartsSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, isValid, dirty, isSubmitting }) => (
            <Form>
              <FieldArray name="spare_parts">
                {({ push, replace }) => (
                  <Box textAlign="start" mb={56}>
                    {!isSectionAdditionOpen && (
                      <>
                        <Box position="absolute">
                          <HeaderWithArrow title="Besoin de pièce(s)" action={handleClose} />
                        </Box>
                        <Box pt={56}>
                          {values.spare_parts.map(
                            (
                              sparePart: {
                                designation: string;
                                reference: string;
                                isOpen: boolean;
                              },
                              index: number
                            ) => {
                              return (
                                <Box mt={2} key={index}>
                                  <PaperStripAction
                                    key={index}
                                    text={sparePart.designation.toUpperCase()}
                                    Icon={sparePart.isOpen ? GreyRemoveIcon : GreenCheckIcon}
                                    callback={() =>
                                      sparePart.isOpen
                                        ? replace(index, {
                                            designation: sparePart.designation,
                                            reference: '',
                                            isOpen: false,
                                            sparePartInStockCount: 0,
                                            supplier_id: ASWO_SUPPLIER_ID,
                                          })
                                        : replace(index, {
                                            designation: sparePart.designation,
                                            reference: sparePart.reference,
                                            isOpen: true,
                                            sparePartInStockCount: 0,
                                            supplier_id: ASWO_SUPPLIER_ID,
                                          })
                                    }
                                    textMode="task"
                                  />
                                  {sparePart.isOpen && (
                                    <Field key={`spare_parts.${index}.reference`}>
                                      {({ form }: FieldProps) => {
                                        return (
                                          <Box backgroundColor="white">
                                            {skuSuppliers && (
                                              <SkuSupplierSelector
                                                onChange={(value) => {
                                                  form.setFieldValue(
                                                    `spare_parts.${index}.supplier_id`,
                                                    value
                                                  );
                                                }}
                                                value={form.values.spare_parts[index].supplier_id}
                                                skuSuppliers={skuSuppliers}
                                              />
                                            )}
                                            <StyledInputBase
                                              placeholder="Référence"
                                              width="100%"
                                              name={`spare_parts.${index}.reference`}
                                              value={form.values.spare_parts[index].reference}
                                              onChange={async (e) => {
                                                form.setFieldValue(
                                                  `spare_parts.${index}.reference`,
                                                  e.target.value
                                                );
                                                if (
                                                  e.target.value.length > 3 &&
                                                  employee.workshop
                                                ) {
                                                  form.setFieldValue(
                                                    `spare_parts.${index}.sparePartInStockCount`,
                                                    await getSparePartCount({
                                                      sku_model_reference: e.target.value,
                                                      workshop: employee.workshop,
                                                      supplier:
                                                        form.values.spare_parts[index].supplier_id,
                                                      available_spare_parts: true,
                                                    })
                                                  );
                                                } else {
                                                  form.setFieldValue(
                                                    `spare_parts.${index}.sparePartInStockCount`,
                                                    0
                                                  );
                                                }
                                              }}
                                            />
                                          </Box>
                                        );
                                      }}
                                    </Field>
                                  )}
                                </Box>
                              );
                            }
                          )}
                        </Box>
                        <Button
                          onClick={() => setIsSectionAdditionOpen(true)}
                          mt={1}
                          variant="fullTransparent"
                          width="fit-content"
                        >
                          Demander une autre pièce
                        </Button>
                        <BottomButton disabled={!isValid || !dirty || isSubmitting || isLoading}>
                          Confirmer
                        </BottomButton>
                      </>
                    )}

                    {isSectionAdditionOpen && (
                      <GenericCodeList
                        handleCodeAddition={(value: { code: string }) => {
                          push({
                            designation: renderIrisCodeFromId(irisSections, parseInt(value.code)),
                            reference: '',
                            isOpen: false,
                          });
                          setIsSectionAdditionOpen(false);
                        }}
                        handleClose={() => {
                          setIsSectionAdditionOpen(false);
                        }}
                        irisCodes={irisSections}
                        title="Pièce à réparer"
                        buttonText="Valider la pièce"
                      />
                    )}
                  </Box>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Box>
    </PageLayout>
  );
};

export default RepairCycleSkuDemand;
