import React, { useState } from 'react';

import { useGetOne } from 'react-admin';

import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import dayjs from 'dayjs';

import ClaimButton from 'pages/IncentiveSystem/Claims/ClaimButton';
import { LabelIncentiveSystemField } from 'pages/IncentiveSystem/Components/LabelIncentiveSystemField';
import ClaimResponse from 'pages/IncentiveSystem/Team/Components/ClaimResponse';

const useStyles = makeStyles({
  header: {
    borderRadius: 0,
    paddingLeft: '20px',
  },
  headWrapper: {
    backgroundColor: '#fff',
    overflow: 'auto',
    height: 'auto',
    '& header': {
      position: 'unset',
    },
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
  },
  back: {
    color: '#2B3B5C',
  },
  paper: {
    height: '-webkit-fill-available',
    overflow: 'auto',
    width: '100%',
    left: '0',
    bottom: '0',
    background: '#F1F0F5',
    '&:focus': {
      outline: 'none',
    },
  },
  button: {
    width: '100%',
    height: 52,
    padding: 24,
    borderRadius: 10,
    border: '1px solid #FFFFFF',
    margin: '20px 0',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    '& svg': {
      color: '#F7B349',
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  linkHistory: {
    textDecoration: 'none',
  },
  wrapper: {
    margin: '32px 20px 0',
  },
  container: {
    marginTop: 24,
    borderRadius: 10,
    boxShadow: 'none',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  titleItem: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    color: '#2B3B5C',
  },
  item: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    marginTop: 8,
    color: '#2B3B5C',
    '& span': {
      fontWeight: 'normal',
    },
  },
  hsitoryText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    color: '#2B3B5C',
  },
  divider: {
    borderTop: '1px solid #DBDFE8',
    margin: '20px 0px',
  },
  classButton: {
    background: '#FCD660',
    color: '#2B3B5C',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FCD660',
      },
    },
  },
  requalificationLabel: {
    width: '100%',
    height: 52,
    padding: 24,
    borderRadius: 10,
    border: '1px solid #FFFFFF',
    margin: '20px 0',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'start',
    fontWeight: 'bold',
    '& svg': {
      color: '#2B3B5C',
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  oldLabel: {
    color: '#8795B3',
    '& span': {
      fontWeight: 'normal',
    },
  },
  arrowStyle: {
    width: 24,
    margin: '0 16px',
  },
});

type ClaimProduct = {
  id: string;
};

type ClaimRequest = {
  old_label: string;
  text: string;
};

type ClaimDetail = {
  customer_file_id: string;
  product: string;
  lastname_customer: string;
  lastname_technician: string;
  date_visit_beginning: string;
  date_visit_end: string;
  claim_request: ClaimRequest;
  initial_label: string;
};
type ClaimDetailsProps = {
  claimDetails: ClaimDetail;
  handleCloseModalClaimDetails: boolean;
  openModalClaimDetails: boolean;
  setIsSubmit: boolean;
};

const ClaimDetailsComponent: React.FunctionComponent<ClaimDetailsProps> = ({
  claimDetails,
  handleCloseModalClaimDetails,
  openModalClaimDetails,
  setIsSubmit,
}: ClaimDetailsProps) => {
  const classes = useStyles();
  const { data: customerFile } = useGetOne('customer-file', claimDetails.customer_file_id);
  const product =
    customerFile &&
    customerFile.products &&
    customerFile.products.find(
      (product: ClaimProduct) => parseInt(product.id) === parseInt(claimDetails.product)
    );
  const [openModalClaimResponse, setOpenModalClaimResponse] = useState(false);
  const handleOpenClaimResponse = () => {
    setOpenModalClaimResponse(true);
  };

  const handleCloseClaimResponse = () => {
    setOpenModalClaimResponse(false);
  };

  const renderDateAndHourVisit = (beginning: string, end: string) => {
    const dateVisit = dayjs(beginning).locale('fr').format('D/MM/YY');
    const hourVisitBeginning = dayjs(beginning).locale('fr').format('HH');
    const hourVisitEnd = dayjs(end).locale('fr').format('HH');
    return `${dateVisit} - ${hourVisitBeginning}h à ${hourVisitEnd}h`;
  };

  const userDiagnostic = (diagnostic: string) =>
    diagnostic &&
    diagnostic.split('%-%').map((diagStep, index) => {
      return (
        <>
          <span key={index}>{diagStep}</span>
          <br />
        </>
      );
    });

  const body = (
    <div className={classes.paper}>
      <div className={classes.headWrapper}>
        <AppBar className={classes.header}>
          <div className={classes.elementHeader}>
            <IconButton
              edge="start"
              onClick={() => handleCloseModalClaimDetails}
              className={classes.back}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>{` ${claimDetails.lastname_customer} / ${claimDetails.lastname_technician}`}</span>
          </div>
        </AppBar>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <span className={classes.titleItem}>Type de machine</span>
          <span className={classes.item}>{product && product.product_type}</span>
        </div>
        <hr className={classes.divider} />

        <div className={classes.container}>
          <span className={classes.titleItem}>Diagnostic en ligne</span>
          <span className={classes.item}>{product && userDiagnostic(product.user_diagnostic)}</span>
        </div>
        <hr className={classes.divider} />
        <div className={classes.container}>
          <span className={classes.titleItem}>Commentaire Client</span>
          <span className={classes.item}>{product && product.symptom}</span>
        </div>
        <hr className={classes.divider} />
        <div className={classes.container}>
          <span className={classes.titleItem}>Date du passage</span>
          <span className={classes.item}>
            {renderDateAndHourVisit(claimDetails.date_visit_beginning, claimDetails.date_visit_end)}
          </span>
        </div>
        <hr className={classes.divider} />
        <div className={classes.container}>
          <span className={classes.titleItem}>Labélisations</span>
          <div>
            <IconButton type="button" className={classes.requalificationLabel}>
              <span className={classes.oldLabel}>
                {claimDetails.claim_request &&
                  claimDetails.claim_request.old_label &&
                  LabelIncentiveSystemField(claimDetails.claim_request.old_label)}
              </span>
              <ArrowRightAltIcon className={classes.arrowStyle} />
              <Typography className={classes.hsitoryText}>
                {LabelIncentiveSystemField(claimDetails.initial_label)}
              </Typography>
            </IconButton>
          </div>
        </div>
        <div className={classes.container}>
          <span className={classes.titleItem}>Commentaire</span>
          <span className={classes.item}>
            {claimDetails.claim_request && claimDetails.claim_request.text}
          </span>
        </div>

        <div>
          <a
            href={`customer-file/${claimDetails.customer_file_id}/history`}
            className={classes.linkHistory}
            rel="noopener noreferrer"
          >
            <IconButton type="button" className={classes.button}>
              <Typography className={classes.hsitoryText}>Voir l’historique</Typography>
              <ArrowForwardIcon />
            </IconButton>
          </a>
        </div>
      </div>

      <ClaimButton
        handleSubmit={handleOpenClaimResponse}
        buttonLabel="TRAITER LA RECLAMATION"
        classButton={classes.classButton}
      />
    </div>
  );
  return (
    <div>
      <Modal
        open={openModalClaimDetails}
        onClose={() => {
          handleCloseModalClaimDetails;
        }}
      >
        {body}
      </Modal>
      <ClaimResponse
        openModalClaimResponse={openModalClaimResponse}
        handleCloseClaimResponse={handleCloseClaimResponse}
        handleCloseModalClaimDetails={handleCloseModalClaimDetails}
        claimDetails={claimDetails}
        setIsSubmit={setIsSubmit}
      />
    </div>
  );
};
export default ClaimDetailsComponent;
