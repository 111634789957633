import React from 'react';

import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';

import styled from 'styled-components';

const StyledDiv = styled.div<FloatingButtonProps>((props) => ({
  position: 'fixed',
  bottom: props.withoutBottomBar ? 16 : 88,
  right: 16,
  zIndex: 999,
}));

type FloatingButtonProps = {
  withoutBottomBar?: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | undefined;
  style?: Record<string, unknown>;
};

const FloatingActionButtonZoom: React.FunctionComponent<FloatingButtonProps> = ({
  onClick,
  icon,
  color,
  ...props
}: FloatingButtonProps) => {
  return (
    <StyledDiv onClick={onClick} {...props}>
      <Zoom in unmountOnExit>
        <Fab aria-label="FAB" color={color}>
          {icon}
        </Fab>
      </Zoom>
    </StyledDiv>
  );
};

export default FloatingActionButtonZoom;
