import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import AddAlertIcon from '@material-ui/icons/AddAlert';

import pluralize from 'pluralize';
import styled from 'styled-components';

const StyledAlertIcon = styled(AddAlertIcon)((props) => ({
  color: props.theme.colors.turquoise,
  marginRight: props.theme.space[1],
}));

const useStyles = makeStyles({
  packageItem: {
    height: 56,
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  recipentName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#2B3B5C',
  },
  skuNumber: {
    fontSize: 15,
    fontWeight: 'normal',
    color: '#8795B3',
    alignItems: 'center',
    display: 'flex',
  },
});

type PackageItemProps = {
  recipientName: string;
  key: number;
  onClick: () => void;
  itemCount: number;
  packageAlreadyExists: boolean;
};

const PackageItem: React.FunctionComponent<PackageItemProps> = ({
  recipientName,
  key,
  onClick,
  itemCount,
  packageAlreadyExists,
}: PackageItemProps) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.packageItem} key={key} onClick={onClick}>
      <div className={classes.receipientName}>{recipientName}</div>
      <div className={classes.skuNumber}>
        {packageAlreadyExists && <StyledAlertIcon />}
        {pluralize(`${itemCount} pièces`, itemCount)}
      </div>
    </ListItem>
  );
};

export default PackageItem;
