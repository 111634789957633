import React from 'react';

import InfoIcon from '@material-ui/icons/Info';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ModalContainer from 'components/organisms/modals/ModalContainer';
import ContentModal from 'components/organisms/modals/ModalContent';

export type InformationModalProps = {
  title: string;
  content: string | string[];
  onConfirm: () => void;
  confirmLabel?: string;
};

type IconProps = {
  className?: string;
};

const StyledIcon = styled(InfoIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.pink700')(props),
}));

const InformationModal: React.FunctionComponent<InformationModalProps> = ({
  content,
  title,
  onConfirm,
  confirmLabel = 'Ok',
}: InformationModalProps) => {
  return (
    <ModalContainer>
      <StyledIcon />
      <Text variant="bodyBold" mb={3} color={'green'}>
        {title}
      </Text>
      <ContentModal content={content} />
      <Button variant="primary" onClick={onConfirm} minWidth={192}>
        {confirmLabel}
      </Button>
    </ModalContainer>
  );
};

export default InformationModal;
