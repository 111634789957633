import { StylesConfig } from 'react-select';

type OptionType = { label: string; value: string };

export const customStyles: StylesConfig<OptionType, false> = {
  menu: () => ({
    color: '#073331',
    backgroundColor: '#F6F6F6',
    textAlign: 'left',
  }),
  control: () => ({
    height: 56,
    backgroundColor: 'white',
    display: 'flex',
  }),
  option: () => ({
    padding: 16,
    marginBottom: 8,
    backgroundColor: 'white',
    borderRadius: 8,
  }),
};
