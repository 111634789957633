import { useQuery } from 'react-admin';

import { Quote } from 'types/quotes';

export const hasAcquisitionSource = async (
  customerFileId: string
): Promise<{ has_source: boolean }> => {
  const token = localStorage.getItem('token');
  const request = new Request(
    `${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/murfy-erp/acquisition-source/?customer_file=${customerFileId}`,
    {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      }),
    }
  );
  return fetch(request).then((response) => {
    return response.json();
  });
};

export const useLastBill = (
  customerFileId: string
): { bill: Quote; isBillLoading: boolean; isErroredBill: boolean } => {
  const {
    data: bill,
    loading: isBillLoading,
    error: isErroredBill,
  } = useQuery({
    type: 'getOne',
    resource: 'quotes',
    payload: {
      last_quote_from_file_id: customerFileId,
    },
  }) as { data: Quote; loading: boolean; error: boolean };
  return { bill, isBillLoading, isErroredBill };
};
