import React from 'react';

import { useQuery } from 'react-admin';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
// material-ui imports
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import dayjs from 'dayjs';
// Types and utils
import { useEmployee } from 'employeeContext';
// Libs
import { RouteComponentProps, useHistory } from 'react-router-dom';

// Components
import BottomBar from 'components/BottomBar/BottomBar';
import PageHeader from 'components/PageHeader';
import PageLayout from 'components/templates/PageLayout';

import { Incentive } from 'types/incentives';

import { useRepairman } from 'utils/api/api';

import IncentiveSystemContent from './IncentiveSystemContent';
// Style
import IncentiveSystemStyles from './styles/incentiveSystemStyles';

const StyledTab = withStyles({
  root: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '140%',
    padding: '0 16px',
    width: '33%',
  },
})(Tab);
const StyledTabs = withStyles({
  flexContainer: {
    justifyContent: 'space-between',
  },
  indicator: {
    backgroundColor: '#FCD660',
    height: 4,
  },
})(Tabs);
const useStyles = makeStyles(IncentiveSystemStyles);

type IncentiveSystemMatchParams = {
  repairmanId: string;
};

type IncentiveSystemProps = RouteComponentProps<IncentiveSystemMatchParams>;

const IncentiveSystem: React.FunctionComponent<IncentiveSystemProps> = (
  props: IncentiveSystemProps
) => {
  const repairmanId = Number(props.match.params.repairmanId);
  const { employee } = useEmployee();

  const isLookingMyOwnIncentives = repairmanId === employee.id;

  const { repairman, loadingRepairman } = useRepairman(repairmanId);

  const history = useHistory();

  const goBackRepairmanList = () => {
    history.push('/team', { indexTab: 1 });
  };

  const [indexTab, setIndexTab] = React.useState(0);

  const dateForFilter =
    dayjs().get('date') > 25
      ? dayjs().set('date', 26).format('YYYY-MM-DD')
      : dayjs().add(-1, 'M').set('date', 26).format('YYYY-MM-DD');

  const classes = useStyles();

  const { data: rawIncentives, loading: loadingIncentives } = useQuery({
    type: 'getList',
    resource: 'incentives',
    payload: {
      pagination: { page: 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: { date_slot: dateForFilter, repairman_id: repairmanId },
    },
  }) as { data: Incentive[]; loading: boolean };

  if (loadingRepairman) {
    // We do not display the page while we are loading repairman  data
    return null;
  }

  const lastName = repairman.last_name;

  const handleChange = (_event: React.ChangeEvent<unknown>, newIndexTab: number) => {
    setIndexTab(newIndexTab);
  };

  const handleChangeIndex = (index: number) => {
    setIndexTab(index);
  };

  return (
    <PageLayout>
      <AppBar position="sticky" className={classes.IncentiveSystemAppBar}>
        {!isLookingMyOwnIncentives ? (
          <IconButton
            edge="start"
            onClick={() => goBackRepairmanList()}
            className={classes.goBackRepairmListStyles}
          >
            <ArrowBackIcon /> {lastName}
          </IconButton>
        ) : (
          <PageHeader title="Performance" />
        )}
        <StyledTabs value={indexTab} onChange={handleChange}>
          <StyledTab label="RÉSUMÉ" />
          <StyledTab label="DETAILS" />
          <StyledTab label="MISE À JOUR" />
        </StyledTabs>
      </AppBar>
      <IncentiveSystemContent
        repairman={repairman}
        indexTab={indexTab}
        onSwipe={handleChangeIndex}
        rawIncentives={loadingIncentives ? [] : rawIncentives}
      />
      <BottomBar />
    </PageLayout>
  );
};

export default IncentiveSystem;
