import React from 'react';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

const HelpWantedDescription: React.FunctionComponent = () => {
  return (
    <Box>
      <Text variant="body">Identique à la catégorie dans les CR</Text>
      <Text variant="body">
        Pour plus de détails, vous pouvez vous référer à la page Notion suivante :
      </Text>
      <a
        href="https://www.notion.so/Assistance-technique-1d773875d4364f38b36f18fc4367f95f"
        target="_blank"
        rel="noopener noreferrer"
      >
        Assistance technique
      </a>
    </Box>
  );
};

export default HelpWantedDescription;
