import React, { useState } from 'react';

import ProductIdentification from 'components/ProductIdentification';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

import { Product } from 'types/products';

import { updateProduct } from 'utils/api/api';

type RetrieveProductModelSkuModelProps = {
  goBack: () => void;
  product: Product;
  onSubmit: (productReference: string, brand: string, category: string) => void;
};

const RetrieveProductModelSkuModel: React.FunctionComponent<RetrieveProductModelSkuModelProps> = ({
  goBack,
  product,
  onSubmit,
}: RetrieveProductModelSkuModelProps) => {
  const [error, setError] = useState<string>();
  const handleSubmit = async (reference: string, brand: string, category: string) => {
    setError(undefined);
    if (reference !== product?.model_reference) {
      try {
        await updateProduct(product.id, { model_reference: reference });
        onSubmit(reference, brand, category);
      } catch (errors) {
        setError(
          `Une erreur est survenue lors de la mise à jour de la référence produit: \n ${errors}`
        );
      }
    }
    onSubmit(reference, brand, category);
  };
  return (
    <Box paddingBottom={150}>
      <HeaderWithArrow title="Vérification du modèle" action={goBack} />
      <ProductIdentification handleSubmit={handleSubmit} product={product} error={error} />
    </Box>
  );
};

export default RetrieveProductModelSkuModel;
