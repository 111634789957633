import React from 'react';

import { BackEvent, IsReparaibleEvent } from '../../types';
import { BACK, IS_NOT_REPAIRABLE, IS_REPAIRABLE } from '../../types';
import IsRepairableChoice from './IsRepairableChoice';

interface IsRepairableContainerProps {
  send: (event: IsReparaibleEvent | BackEvent) => void;
  selectedValue: boolean | null;
}

const IsRepairableContainer: React.FunctionComponent<IsRepairableContainerProps> = ({
  send,
  selectedValue,
}: IsRepairableContainerProps) => {
  return (
    <IsRepairableChoice
      back={() => {
        send({
          type: BACK,
          data: {},
        });
      }}
      submit={(is_repairable: boolean) => {
        is_repairable
          ? send({
              type: IS_REPAIRABLE,
              data: {
                is_repairable,
              },
            })
          : send({
              type: IS_NOT_REPAIRABLE,
              data: {
                is_repairable,
              },
            });
      }}
      selectedValue={selectedValue}
    />
  );
};

export default IsRepairableContainer;
