import React from 'react';

import { parseEventDate } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Tag from 'components/atoms/Tag';
import Text from 'components/atoms/Text';

import { Repairman } from 'types/employees';

import { useRepairman } from 'utils/api/api';

import SimpleBlockTitle from './SimpleBlockTitle';

type HistoryEventHeaderProps = {
  date: Date;
  assignee: number;
  title: string;
  tagVariant:
    | 'primary'
    | 'secondary'
    | 'ternary'
    | 'info'
    | 'alert'
    | 'warning'
    | 'success'
    | 'problem';
};

const renderEmployeeName = (repairman: Repairman) => {
  return `${repairman?.first_name} ${repairman?.last_name ? repairman.last_name[0] : ''}`;
};

const HistoryEventHeader: React.FunctionComponent<HistoryEventHeaderProps> = ({
  date,
  assignee,
  title,
  tagVariant,
}: HistoryEventHeaderProps) => {
  const { repairman } = useRepairman(assignee);
  return (
    <SimpleBlockTitle>
      <Tag variant={tagVariant} text={title} />
      <Text variant="caption" color="grey900">
        {`${repairman && renderEmployeeName(repairman)}`}
        {`- ${parseEventDate(date)}`}
      </Text>
    </SimpleBlockTitle>
  );
};
export default HistoryEventHeader;
