import React, { forwardRef, useState } from 'react';

import { Datagrid, List } from 'react-admin';

import { AppBar, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import styled from 'styled-components';

import RepairmanScanModal from 'pages/RepairmanStock/components/RepairmanScanModal';
import StoreStyles from 'pages/Store/StoreStyles';
import FloatingActionButton from 'pages/Store/components/FloatingActionButton';
import PrintContent from 'pages/Store/components/PrintContent';
import SparePartLossModal from 'pages/Store/components/SparePartLossModal';
import SparePartReceptionField from 'pages/Store/components/SparePartReceptionField';

import PortalComponent from 'components/Portal/PortalComponent';
import ValidateScanningTask from 'components/ValidateScanningTask/ValidateScanningTask';

import { SparePart } from 'types/spare-part';

type SparePartReceptionManagementProps = {
  open: boolean;
  onClose: () => void;
  handleCloseModal: () => void;
  selectedIds: number[];
  lostSkuList: number[];
  setSkuNumber: (value: number) => void;
  skuNumber: number;
  employeeName: string;
  selectedId: number;
  baseSkuList: SparePart[];
  printSku: (sparePart: SparePart) => void;
  handleRowClick: (record: SparePart) => void;
  surplusSkuList: SparePart[];
  isListLoading: boolean;
  sparePartFilter: Record<string, unknown>;
  disabledPackageButton: boolean;
  handleValidateTask: () => void;
  mode: 'packing' | 'transfer';
  isLossModalOpen: boolean;
  setIsListLoading: (value: boolean) => void;
  sku?: SparePart;
  handleCloseSparePartLossModal: () => void;
  handleConfirmBarcode: (value: string) => void;
  handleCloseScanModal: () => void;
  handleSparePartLoss: () => void;
  isScanModalOpen: boolean;
  isPrintModalOpened: boolean;
  handleClosePackageDetails: () => void;
  qrCode?: string;
  packageCode?: string;
  handleOpenScanModal: () => void;
  error: string;
};

const TransitionScan = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as any)} />;
});

const styleStore = makeStyles(StoreStyles);

// OCCASION
const OCCASION = 'OCCASION';

const StyledCachedIcon = styled(CachedIcon)((props) => ({
  color: props.theme.colors.grey900,
}));

const SparePartReceptionManagement: React.FunctionComponent<SparePartReceptionManagementProps> = ({
  open,
  onClose,
  handleCloseModal,
  selectedIds,
  lostSkuList,
  setSkuNumber,
  skuNumber,
  employeeName,
  selectedId,
  baseSkuList,
  printSku,
  handleRowClick,
  surplusSkuList,
  isListLoading,
  sparePartFilter,
  disabledPackageButton,
  handleValidateTask,
  mode,
  isLossModalOpen,
  setIsListLoading,
  sku,
  handleCloseSparePartLossModal,
  handleConfirmBarcode,
  handleCloseScanModal,
  handleSparePartLoss,
  isScanModalOpen,
  isPrintModalOpened,
  handleClosePackageDetails,
  qrCode,
  packageCode,
  handleOpenScanModal,
  error,
  ...props // Needed unless we get rid of RA <List> component
}: SparePartPackagingReceptionManagementProps) => {
  const classes = styleStore();

  const [prevTouchInfo, setPrevTouchInfo] = useState(0);

  // Update the sku style if it is lost

  // Used to check if we can close the modal with only one tap
  const handleDoubleClickCheck = () => {
    const dt = Date.now() - prevTouchInfo;
    if ((selectedIds.length === 0 && lostSkuList.length === 0) || dt < 500) {
      handleCloseModal();
    }
    setPrevTouchInfo(Date.now());
  };

  const postRowStyle = (record: SparePart) => ({
    background: lostSkuList.includes(record?.id) ? '#DBDFE8' : 'white',
  });

  const ListController = ({ ids, setSort, currentSort, loading }: any) => {
    // Use this component to manage props related to the list
    // Count the number of skus in the list and set the sku list
    if (ids.filter((el: number) => !lostSkuList.includes(el)).length !== skuNumber) {
      setSkuNumber(ids.filter((el: number) => !lostSkuList.includes(el)).length);
    }

    if (isListLoading !== loading) {
      setIsListLoading(loading);
    }

    // Prevent default sorting from list that is occuring when rendering this screen
    if (currentSort.field === 'id') {
      setSort('');
    }
    return <></>;
  };

  return (
    <PortalComponent open={open} onClose={onClose}>
      <AppBar color="transparent" className={classes.header}>
        <div className={classes.elementHeader}>
          <IconButton edge="start" onClick={handleDoubleClickCheck} className={classes.back}>
            <ArrowBackIcon />
          </IconButton>
          <span>{employeeName}</span>
          <span className={classes.skuHeaderNumber}>{skuNumber}</span>
        </div>
      </AppBar>
      <div className={classes.detailContainer}>
        <div className={classes.listSkuPackageContainer}>
          {selectedId &&
            (baseSkuList ? (
              <Box mt={8}>
                {baseSkuList.map((item: SparePart, index: number) => (
                  <Box
                    key={index}
                    mb={1}
                    paddingX={2}
                    style={{
                      background: lostSkuList.includes(item?.id) ? '#DBDFE8' : 'white',
                      borderRadius: 8,
                    }}
                  >
                    <SparePartReceptionField
                      key={index}
                      record={item}
                      selectedIds={selectedIds}
                      lostSkuList={lostSkuList}
                      lossClick={handleRowClick}
                      printSku={printSku}
                      surplusSkuList={surplusSkuList}
                      isLoading={isListLoading}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <List
                basePath=""
                resource="sku"
                exporter={false}
                bulkActionButtons={false}
                filter={sparePartFilter}
                sort={{ field: 'location.code', order: 'ASC' }}
                pagination={false}
                perPage={1000}
                aside={<ListController />}
                {...props}
              >
                <Datagrid
                  rowStyle={postRowStyle}
                  classes={{ table: classes.packageTable, row: classes.packageRow }}
                >
                  <SparePartReceptionField
                    selectedIds={selectedIds}
                    lostSkuList={lostSkuList}
                    lossClick={handleRowClick}
                    printSku={printSku}
                    surplusSkuList={surplusSkuList}
                    isLoading={isListLoading}
                  />
                </Datagrid>
              </List>
            ))}
          {!isListLoading && selectedIds.length > 0 && selectedIds.length !== skuNumber && (
            <div className={classes.fullPackageText} onClick={handleValidateTask}>
              Le colis est plein
            </div>
          )}
        </div>
      </div>
      <ValidateScanningTask
        disabled={skuNumber !== selectedIds.length || disabledPackageButton}
        onClick={handleValidateTask}
        buttonText="Confirmer"
      />
      {isLossModalOpen && sku && (
        <SparePartLossModal
          open={isLossModalOpen}
          onClose={handleCloseSparePartLossModal}
          employeeName={employeeName}
          defaultSparePart={sku}
          handleConfirmBarcode={handleConfirmBarcode}
          handleSparePartLoss={handleSparePartLoss}
          mode={mode}
        />
      )}

      <RepairmanScanModal
        error={error}
        hideManualInput
        bodyText="Prenez l’ensemble de l’étiquette, veillez à la netteté."
        headerText="Scannez une pièce"
        TransitionComponent={TransitionScan}
        handleConfirmBarcode={handleConfirmBarcode}
        onClose={handleCloseScanModal}
        open={isScanModalOpen}
      />
      <PrintContent
        open={isPrintModalOpened}
        onClose={handleClosePackageDetails}
        firstText={'Impression de code'}
        secondText={packageCode}
        codeSubtitle={packageCode}
        qrCode={qrCode}
        handleNextStep={handleClosePackageDetails}
        headingText={sku?.is_second_hand ? OCCASION : ''}
        LeftIcon={sku?.is_second_hand ? StyledCachedIcon : undefined}
        RightIcon={sku?.is_second_hand ? StyledCachedIcon : undefined}
        barCode={undefined}
      />
      <div>
        <FloatingActionButton
          onClick={handleOpenScanModal}
          withoutBottomBar={selectedIds.length <= 0}
          color="secondary"
          icon={<CenterFocusStrongIcon />}
        />
      </div>
    </PortalComponent>
  );
};

export default SparePartReceptionManagement;
