import React from 'react';

import Box from 'components/atoms/Box';

import { Location } from 'types/location';
import { ShippingDelivery, ShippingDeliveryItem } from 'types/shipping-deliveries';

import ShippingPreparation from '../ShippingPreparation/ShippingPreparation';
import { DELIVERY } from '../utils';
import ProductPreparationScan from './ProductPreparationScan';

type ShippingProductPreparationProps = {
  isChecklistOpen: boolean;
  setIsChecklistOpen: (bool: boolean) => void;
  setSelectedProductId: (id: number | null) => void;
  selectedProductId: number | null;
  deliveries: ShippingDelivery[];
  locations: Location[];
};
const ShippingProductPreparation: React.FunctionComponent<ShippingProductPreparationProps> = ({
  isChecklistOpen,
  setIsChecklistOpen,
  setSelectedProductId,
  selectedProductId,
  deliveries,
  locations,
}: ShippingProductPreparationProps) => {
  return (
    <Box height="calc(100vh - 172px)" overflow="scroll">
      {deliveries?.map((delivery: ShippingDelivery) => (
        <Box key={delivery.id}>
          {delivery?.items?.map((item: ShippingDeliveryItem) => (
            <>
              {item.delivery_type === DELIVERY && (
                <ShippingPreparation
                  isChecklistOpen={isChecklistOpen}
                  setIsChecklistOpen={setIsChecklistOpen}
                  setSelectedProductId={setSelectedProductId}
                  selectedProductId={selectedProductId}
                  key={item.id}
                  productId={item.product}
                  locations={locations}
                  delivery={delivery}
                  deliveryItem={item}
                />
              )}
            </>
          ))}
        </Box>
      ))}

      <ProductPreparationScan callback={setIsChecklistOpen} />
    </Box>
  );
};

export default ShippingProductPreparation;
