import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import dataProvider from 'dataProvider';

import ClaimButton from './ClaimButton';
import ClaimTextArea from './ClaimTextArea';

const useStyles = makeStyles({
  header: {
    borderRadius: 0,
    paddingLeft: '20px',
  },
  headWrapper: {
    backgroundColor: '#fff',
    '& header': {
      position: 'unset',
    },
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
  },
  back: {
    color: '#2B3B5C',
  },
  paper: {
    height: '-webkit-fill-available',
    width: '100%',
    left: '0',
    bottom: '0',
    overflow: 'auto',
    background: '#F1F0F5',
    '&:focus': {
      outline: 'none',
    },
  },
  wrapper: {
    margin: '22px 0 0',
  },
  classButton: {
    background: '#FCD660',
    color: '#2B3B5C',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FCD660',
      },
    },
  },
  classContainerButton: {
    position: 'absolute',
  },
});
export default function ClaimForm(props) {
  const classes = useStyles();
  const [claimRequest, setClaimRequest] = useState('');

  const handleSubmitClaim = () => {
    if (claimRequest) {
      dataProvider
        .create('claim', {
          data: {
            claim_request: {
              text: claimRequest,
              old_label:
                props.incentiveData.previous_incentive &&
                props.incentiveData.previous_incentive.prev_label,
            },
            incentive: props.incentiveData.id,
            initial_label: props.incentiveData.label,
            manager: props.incentiveData.manager,
            technician: props.incentiveData.repairman,
          },
        })
        .then(() => {
          props.handleCloseClaimForm();
          props.handleCloseClaimModal();
          props.setIsSubmit(true);
          setClaimRequest('');
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const body = (
    <div className={classes.paper}>
      <div className={classes.headWrapper}>
        <AppBar color="white" className={classes.header}>
          <div className={classes.elementHeader}>
            <IconButton
              edge="start"
              onClick={() => props.handleCloseClaimForm()}
              className={classes.back}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>Réclamation</span>
          </div>
        </AppBar>
      </div>
      <div className={classes.wrapper}>
        <ClaimTextArea
          textAreaLabel="claim_request"
          textPlaceHolder="Explications"
          claimRequest={claimRequest}
          setClaimRequest={setClaimRequest}
        />
      </div>
      <ClaimButton
        handleSubmit={handleSubmitClaim}
        isDisable={!claimRequest.trim()}
        buttonLabel="VALIDER"
        classButton={classes.classButton}
        classContainerButton={classes.classContainerButton}
      />
    </div>
  );
  return (
    <div>
      <Modal open={props.openModalClaimForm} onClose={props.handleCloseClaimForm}>
        {body}
      </Modal>
    </div>
  );
}
