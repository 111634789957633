import React from 'react';

import { RouteComponentProps, useParams } from 'react-router-dom';

import GoBackHeader from 'components/molecules/GoBackHeader';
import PageLayout from 'components/templates/PageLayout';

import CustomerFileHistoryContent from './CustomerFileHistoryContent';

type CustomerFileHistoryMatchParams = {
  customerFileId: string;
};

type CustomerFileHistoryProps = RouteComponentProps<CustomerFileHistoryMatchParams>;

const CustomerFileHistory: React.FunctionComponent<CustomerFileHistoryProps> = () => {
  const { customerFileId } = useParams<CustomerFileHistoryMatchParams>();

  return (
    <PageLayout height="max-content">
      <GoBackHeader title={`Historique des interventions`} />
      <CustomerFileHistoryContent customerFileId={customerFileId} />{' '}
    </PageLayout>
  );
};

export default CustomerFileHistory;
