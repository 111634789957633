// States
import { assign, createMachine } from 'xstate';

export const PRODUCT_VERIFICATION = 'product_verification';
export const PRODUCT_SELECTION = 'product_selection';
export const SPARE_PARTS = 'spare_parts';
export const FINAL = 'final';
export const BACK = 'back';

// Events

export const CONFIRMATION = 'confirmation';
export const SELECTION = 'selection';

type EventType = typeof FINAL | typeof BACK;

interface BaseEvent {
  type: EventType;
}

interface SetDeviceId {
  type: typeof SELECTION;
  data: {
    device_id: string;
  };
}

interface SetProductReference {
  type: typeof CONFIRMATION;
  data: {
    product_reference: string;
    brand: string;
    category: string;
  };
}

type Event = BaseEvent | SetDeviceId | SetProductReference;

interface ProductContext {
  device_id: string;
  product_reference: string;
  brand: string;
  category: string;
}

const updateContext = (context: ProductContext, event: Event) => {
  switch (event.type) {
    case CONFIRMATION: {
      return {
        ...context,
        product_reference: event.data.product_reference,
        brand: event.data.brand,
        category: event.data.category,
      };
    }
    case SELECTION: {
      return {
        ...context,
        device_id: event.data.device_id,
      };
    }
    default:
      return context;
  }
};

export const productDisassemblyMachine = createMachine<ProductContext, Event>(
  {
    id: 'product-machine',
    initial: PRODUCT_VERIFICATION,
    context: {
      device_id: '',
      product_reference: '',
      brand: '',
      category: '',
    },
    states: {
      [PRODUCT_VERIFICATION]: {
        on: {
          [CONFIRMATION]: PRODUCT_SELECTION,
        },
        exit: ['updateContext'],
      },
      [PRODUCT_SELECTION]: {
        on: {
          [SELECTION]: SPARE_PARTS,
          [BACK]: PRODUCT_VERIFICATION,
        },
        exit: ['updateContext'],
      },
      [SPARE_PARTS]: {
        on: {
          [BACK]: PRODUCT_SELECTION,
        },
      },
    },
  },
  {
    actions: {
      updateContext: assign(updateContext),
    },
  }
);
