import React from 'react';

import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';

import { useFaultForecastList } from 'pages/Prequalification/utils';

import Box from 'components/atoms/Box';

import { FaultForecastListItem } from 'types/intervention';

import InboxItem from './InboxItem';

const PrequalificationList: React.FunctionComponent = () => {
  const history = useHistory();

  const goToPrequalification = (prequalificationId: number) => {
    history.push(`prequalification/${prequalificationId}`);
  };
  const { crPrequalCustomLimitHour } = useFlags();

  const { prequalifications, loading } = useFaultForecastList(crPrequalCustomLimitHour);

  return (
    <Box display="flex" flexDirection="column">
      {!loading &&
        prequalifications.map((prequal: FaultForecastListItem) => (
          <Box onClick={() => goToPrequalification(prequal.id)} my={1} key={prequal.id}>
            <InboxItem
              title={prequal.client_name}
              body={[prequal.product_type, prequal.symptom]}
              created_at={prequal.created_at}
              date={prequal.first_visit_date}
              Icon={prequal.has_identification_plate ? CameraAltRoundedIcon : undefined}
            />
          </Box>
        ))}
    </Box>
  );
};

export default PrequalificationList;
