// React imports
import React from 'react';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { useCustomerFile } from 'utils/api/api';

type ProductAssociatedFilesProps = {
  customerFileId: string;
};
const ProductAssociatedFiles: React.FunctionComponent<ProductAssociatedFilesProps> = ({
  customerFileId,
}: ProductAssociatedFilesProps) => {
  const { customerFile, isCustomerFileLoading } = useCustomerFile(customerFileId);
  return (
    <Box alignItems="left" borderRadius={2} mt={3} ml={2} mr={2} p={3} bg="#1A8E96" color="#FFFFFF">
      <Text textAlign="left" variant="bodyBold" color="white">
        Dossier RD identifié:
      </Text>
      <Box mt={3}>
        <Text textAlign="left" variant="title2" color="white">
          {customerFile && !isCustomerFileLoading && <> {customerFile.file_number} </>}
        </Text>
      </Box>
    </Box>
  );
};

export default ProductAssociatedFiles;
