import React from 'react';

import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ContentContainer from 'components/templates/ContentContainer';

type OpenScanButtonProps = {
  handleOpen: () => void;
  text: string;
};

type IconProps = {
  className?: string;
};

const StyledIcon = styled(CenterFocusStrongIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.dark')(props),
}));

const OpenScanButton: React.FunctionComponent<OpenScanButtonProps> = ({
  handleOpen,
  text,
}: OpenScanButtonProps) => {
  return (
    <ContentContainer>
      <Box
        border={'1px dashed'}
        borderColor={'grey900'}
        borderRadius={10}
        height={188}
        mt={132}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          backgroundColor={'yellow'}
          borderRadius={'50%'}
          display={'flex'}
          marginBottom={1}
          height={72}
          width={72}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <StyledIcon data-testid="scan-icon" onClick={handleOpen} />
        </Box>
        <Text variant="bodyBold">{text}</Text>
      </Box>
    </ContentContainer>
  );
};

export default OpenScanButton;
