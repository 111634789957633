// React imports
import React from 'react';

import { RadioButtonGroupInput } from 'react-admin';

// Style imports
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formControlComponent: {
    '& .MuiFormControlLabel-root': {
      boxSizing: 'border-box',
      borderRadius: 10,
      border: '1px solid #DBDFE8',
      backgroundColor: '#ffffff',
      margin: '0 24px 16px 24px',
      height: 56,
      color: '#2B3B5C',
    },
    '& .MuiFormControlLabel-label': {
      marginLeft: '13px',
      fontSize: 18,
    },
    '& .MuiSvgIcon-root': {
      marginLeft: '13px',
    },
    '& .MuiFormControl-marginDense': {
      margin: 0,
    },
    '& .MuiFormHelperText-marginDense': {
      margin: 0,
      display: 'none',
    },
  },
});
const useRadioButtonGroupStyles = makeStyles({
  root: {
    display: 'grid',
  },
});

type RepairReportRadioProps = {
  radioLabel: string;
  selectionableValues: Array<{ id: string; name: string }>;
};

const RepairReportRadio: React.FunctionComponent<RepairReportRadioProps> = ({
  radioLabel,
  selectionableValues,
}: RepairReportRadioProps) => {
  const radioButtonGroupClasses = useRadioButtonGroupStyles();
  const classes = useStyles();

  return (
    <div className={classes.formControlComponent}>
      <RadioButtonGroupInput
        fullWidth
        options={{ classes: radioButtonGroupClasses }}
        label=""
        source={`${radioLabel}`}
        choices={selectionableValues}
      />
    </div>
  );
};

export default RepairReportRadio;
