import React, { useCallback, useContext } from 'react';

import { Box } from '@material-ui/core';

type RadioFieldListProps = {
  activeItem: string;
  handleRadioClick: (value: string) => void;
};
type RadioFieldProviderProps = {
  activeItem: string;
  onChange: (item: string) => void;
  children: React.ReactNode;
};

const RadioFieldContext = React.createContext<RadioFieldListProps>({} as RadioFieldListProps);

export const useRadioFieldContext: () => RadioFieldListProps = () => useContext(RadioFieldContext);

const RadioFieldList: React.FunctionComponent<RadioFieldProviderProps> = ({
  activeItem,
  onChange,
  children,
}: RadioFieldProviderProps) => {
  // useCallback is used in handleRadioClick to replicate a useState interface
  const handleRadioClick = useCallback(
    (value) => {
      // this if clause allows to uncheck active value
      if (activeItem !== value) {
        onChange(value);
      } else {
        onChange('');
      }
    },
    [activeItem, onChange]
  );

  const value = {
    activeItem,
    handleRadioClick,
  };

  return (
    <RadioFieldContext.Provider value={value}>
      <Box bgcolor="white" borderRadius="10px" padding={3}>
        {children}
      </Box>
    </RadioFieldContext.Provider>
  );
};

export default RadioFieldList;
