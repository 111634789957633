import React from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import { ButtonProps } from 'components/atoms/Button/Button';

type ButtonBottomProps = ButtonProps;

const StyledBottomButton = styled(Button)((props) => ({
  bottom: props.theme.space[2],
  position: 'absolute',
  width: '90%',
  left: '5%',
}));
export const ButtonBottom: React.FunctionComponent<ButtonBottomProps> = ({
  children,
  disabled,
  variant,
  type = 'submit',
  ...rest
}: ButtonBottomProps) => (
  <StyledBottomButton
    {...rest}
    variant={disabled ? 'disabled' : variant}
    disabled={disabled}
    type={type}
  >
    {children}
  </StyledBottomButton>
);

export default ButtonBottom;
