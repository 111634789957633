import React from 'react';

import { useMachine } from '@xstate/react';
import { Redirect } from 'react-router-dom';

import AdditionalInformation from '../components/AdditionalInformation';
import ApplianceNameplate from '../components/ApplianceNameplate';
import IsRepairableChoice from '../components/IsRepairableChoice';
import NotRepairableReason from '../components/NotRepairableReason';
import SparePartsNeed from '../components/SparePartsNeed';
import SparePartsNeededChoice from '../components/SparePartsNeededChoice';
import SymptomsAndDiagnostic from '../components/SymptomsAndDiagnostic';
import { getValidationSchema } from '../validators';
import PrequalReportMachine from './PrequalificationReportStateMachine';

interface PrequalificationReportProps {
  productId: string;
  intervention: string;
}

const PrequalificationReport: React.FunctionComponent<PrequalificationReportProps> = ({
  productId,
  intervention,
}: PrequalificationReportProps) => {
  const [state, send] = useMachine(PrequalReportMachine, {
    context: {
      symptoms: '',
      diagnostic: '',
      need_skus: null,
      is_repairable: null,
      intervention: intervention,
      additional_information: '',
      irreparable_reason: '',
    },
  });
  switch (state.value) {
    case 'applianceNameplate':
      return <ApplianceNameplate productId={productId} send={send} />;
    case 'symptomsAndDiagnostic':
      return (
        <SymptomsAndDiagnostic
          symptoms={state.context.symptoms}
          diagnostic={state.context.diagnostic}
          validationSchema={getValidationSchema('symptomsAndDiagnostic')}
          send={send}
        />
      );
    case 'additionalInformation':
      return (
        <AdditionalInformation
          additional_information={state.context.additional_information}
          validationSchema={getValidationSchema('additionalInformation')}
          send={send}
        />
      );
    case 'sparePartsNeededChoice':
      return <SparePartsNeededChoice send={send} selectedValue={state.context.need_skus} />;
    case 'sparePartsNeed':
      return <SparePartsNeed intervention={intervention} send={send} />;
    case 'isRepairableChoice':
      return <IsRepairableChoice send={send} selectedValue={state.context.is_repairable} />;
    case 'notRepairableReason':
      return <NotRepairableReason send={send} selectedValue={state.context.irreparable_reason} />;
    case 'success':
      return <Redirect to={`/inbox`} />;

    case 'sumbitting':
      return <>Envoie de la préqualification </>;
    default:
      return <>{state.value}</>;
  }

  return <div>Hors du switch</div>;
};

export default PrequalificationReport;
