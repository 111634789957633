import React from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { MarginBottomProps } from 'styled-system';

import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

export type HeaderWithArrowProps = {
  action: () => void;
  title: string;
} & MarginBottomProps;

export const HeaderWithArrow: React.FunctionComponent<HeaderWithArrowProps> = ({
  action,
  title,
  ...rest
}: HeaderWithArrowProps) => {
  return (
    <PaperStrip onClick={action} {...rest}>
      <Text variant="bodyBold" display="flex">
        <ArrowBack style={{ marginRight: 16 }} />
        {title}
      </Text>
    </PaperStrip>
  );
};

export default HeaderWithArrow;
