import React from 'react';

import MenuIcon from '@material-ui/icons/Menu';

import Box from 'components/atoms/Box';
import ButtonIcon from 'components/atoms/ButtonIcon';
import Text from 'components/atoms/Text';

type CanvasBodyProps = {
  title: string;
  noPadding?: boolean;
  mention?: React.ReactNode;
  onClick: () => void;
};

const CanvasBody: React.FunctionComponent<CanvasBodyProps> = ({
  title,
  noPadding,
  mention,
  onClick,
}: CanvasBodyProps) => {
  return (
    <Box
      px={noPadding ? 0 : 2}
      py={noPadding ? 0 : 1}
      display="flex"
      justifyContent="space-between"
      backgroundColor="white"
    >
      <Box display="flex">
        <ButtonIcon icon={MenuIcon} handleClick={onClick} />
        <Text pt="4px" pl={2} variant="subtitle">
          {title}
        </Text>
      </Box>
      {mention}
    </Box>
  );
};

export default CanvasBody;
