import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { useTourStatus } from 'tourStatusContext';

import Button from 'components/atoms/Button';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

const StartTour: React.FunctionComponent = () => {
  const { tourStatus, setRepairmanTourStatus, isLoading } = useTourStatus();
  const startTour = () => setRepairmanTourStatus('ongoing');
  return tourStatus == 'not_started' ? (
    <PaperStrip>
      <Text paddingX={4} textAlign="center" variant="body">
        Vous êtes arrivé chez votre premier client ?
      </Text>
      <Button type="button" mt={3} width="100%" onClick={startTour} disabled={isLoading}>
        {isLoading ? <CircularProgress size={20} /> : 'Commencer ma journée'}
      </Button>
    </PaperStrip>
  ) : (
    <></>
  );
};

export default StartTour;
