import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import dataProvider from 'dataProvider';
import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import RoundTab from 'components/molecules/RoundTab';

import { Picture, Product } from 'types/products';

import { compressImage } from 'utils/images';

import IndividualPictureDisplayUpdate from './IndividualPictureDisplayUpdate';

type SellingPicturesUpdateProps = {
  product: Product;
  handleClose: () => void;
};

const TurquoiseAddIcon = styled(AddIcon)((props) => ({
  color: props.theme.colors.turquoise,
}));
const SellingPicturesUpdate: React.FunctionComponent<SellingPicturesUpdateProps> = ({
  product,
  handleClose,
}: SellingPicturesUpdateProps) => {
  const [key, setKey] = useState<number>(0);

  const [pictures, setPictures] = useState<Picture[]>(
    product?.pictures.map((picture, index) => ({ ...picture, index: index })) || []
  );
  const createdPictures = pictures
    .filter((picture) => picture.state === 'created')
    .filter((picture) => picture.newPicture !== undefined);
  const updatedPictures = pictures.filter((picture) => picture.state === 'updated');
  const [selectedPhoto, setSelectedPhoto] = useState<Picture | undefined>(
    pictures ? pictures[0] : undefined
  );
  const [error, setError] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
  const [createIndex, setCreateIndex] = useState<number | null>(null);
  const [updateIndex, setUpdateIndex] = useState<number | null>(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

  const createPicture = (index: number) => {
    const picture = createdPictures[index];
    dataProvider
      .create('pictures', {
        data: { product: product.id, url: picture.newPicture },
      })
      .then(() => {
        createIndex === createdPictures.length - 1
          ? setIsCreateLoading(false)
          : createIndex != null && setCreateIndex(createIndex + 1);
      })
      .catch((e) => setError(e.message));
  };

  const updatePicture = (index: number) => {
    const picture = updatedPictures[index];
    dataProvider
      .update('pictures', {
        id: picture.id,
        data: { url: picture.newPicture, rank: picture.index },
      })
      .then(() => {
        updateIndex === updatedPictures.length - 1
          ? setIsUpdateLoading(false)
          : updateIndex != null && setUpdateIndex(updateIndex + 1);
      })
      .catch((e) => setError(e.message));
  };

  useEffect(() => {
    setSelectedPhoto(pictures[pictures.length - 1]);
    if (!isUpdateLoading && !isCreateLoading && isSubmitted && error === null) {
      handleClose();
    }
    if (error !== null) {
      setIsCreateLoading(false);
      setIsUpdateLoading(false);
    }
    if (createIndex != null && isCreateLoading) {
      createPicture(createIndex);
    }
    if (updateIndex != null && isUpdateLoading) {
      updatePicture(updateIndex);
    }
    // eslint-disable-next-line
  }, [pictures, isUpdateLoading, isCreateLoading, isSubmitted, error, createIndex, updateIndex]);

  const handleSellingPicturesSubmit = () => {
    setIsCreateLoading(createdPictures.length > 0);
    setIsUpdateLoading(updatedPictures.length > 0);
    setError(null);
    setIsSubmitted(true);
    setCreateIndex(createdPictures.length > 0 ? 0 : null);
    setUpdateIndex(updatedPictures.length > 0 ? 0 : null);
  };

  const updatePictureState = async (fileList: FileList | null, index: number) => {
    if (fileList) {
      const statePictures = pictures;
      for (let i = 0; i < fileList.length; i++) {
        const compressedImage = await compressImage(
          fileList[i] as File,
          fileList[i]?.name as string
        );
        if (index + i > pictures.length - 1) {
          statePictures.push({
            index: index + i,
            id: undefined,
            newPicture: compressedImage,
            state: 'created',
            url: undefined,
            rank: undefined,
          });
        } else {
          statePictures[index + i].newPicture = compressedImage;
          if (statePictures[index + i].id) {
            statePictures[index + i].state = 'updated';
          }
        }
        setKey(key + 1);
      }
    }
    setPictures(pictures);
    setError(null);
  };
  return (
    <>
      <Box mt={2}>
        {selectedPhoto && (
          <IndividualPictureDisplayUpdate
            key={key}
            photo={selectedPhoto}
            updatePicture={updatePictureState}
          />
        )}
      </Box>
      <Box width="100%" position="absolute" bottom={0} py={2} backgroundColor="white">
        <Box
          mb={2}
          mx={2}
          display="grid"
          style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gridGap: 16 }}
        >
          {pictures &&
            pictures.map((picture) => (
              <RoundTab
                key={picture.id}
                handleClick={() => {
                  setSelectedPhoto(picture);
                }}
              >
                <>
                  {picture.newPicture && (
                    <Image
                      width={48}
                      height={48}
                      src={URL.createObjectURL(picture.newPicture)}
                      alt="preview"
                    />
                  )}
                  {!picture.newPicture && picture.url && (
                    <Image width={48} height={48} src={picture.url} alt="preview" />
                  )}
                </>
              </RoundTab>
            ))}
          <label htmlFor="photo-upload">
            <RoundTab
              handleClick={() => {
                setPictures((previousState) => [
                  ...previousState,
                  {
                    id: undefined,
                    index: previousState.length,
                    url: '',
                    state: 'created',
                    newPicture: undefined,
                    rank: undefined,
                  },
                ]);
              }}
            >
              <TurquoiseAddIcon />
            </RoundTab>
          </label>
        </Box>
        {error !== null && (
          <Text my={1} variant="smallBold" color="pink700" px={1}>
            {`Une erreur est survenue à l'enregistrement des photos:  ${error}`}
          </Text>
        )}
        <Button
          disabled={isCreateLoading || isUpdateLoading}
          width="90%"
          onClick={handleSellingPicturesSubmit}
        >
          {isCreateLoading || isUpdateLoading ? 'Chargement en cours' : 'Confirmer'}
        </Button>
      </Box>
    </>
  );
};

export default SellingPicturesUpdate;
