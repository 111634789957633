import React from 'react';

import dataProvider from 'dataProvider';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useHasSource } from 'pages/Payment/utils/hook';

import Box from 'components/atoms/Box';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import ButtonBlock from 'components/molecules/ButtonBlock';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { useSearchParams } from 'utils/api/hooks';
import { formatPrice } from 'utils/formatting';

import success from '../svg/success.svg';
import CustomerSurvey from './CustomerSurvey';

export type CustomerSurveyValues = {
  subscribeNewsletter: string;
  acquisitionSource: string;
};

const validationSchema = yup.object().shape({
  subscribeNewsletter: yup.mixed(),
  acquisitionSource: yup.mixed(),
});

type PaymentProps = {
  customerFileId: string;
  visitId: string;
  amount: number;
};

const DebitSuccess: React.FunctionComponent<PaymentProps> = ({
  customerFileId,
  visitId,
  amount,
}: PaymentProps) => {
  const history = useHistory();
  const query = useSearchParams();
  const embeded = query.get('embeded');

  const { isSuccess, hasSource } = useHasSource(customerFileId);

  const handleSurveySubmit = async (values: CustomerSurveyValues) => {
    if (values.acquisitionSource !== '' || values.subscribeNewsletter !== '') {
      try {
        await dataProvider.create('customer-survey', {
          data: {
            customer_file: customerFileId,
            acquisition_source: values.acquisitionSource,
            subscribe_to_newsletter: values.subscribeNewsletter,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
    history.push(`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`);
  };

  return (
    <PageLayout>
      <GoBackHeader
        title="Fin d'intervention"
        backLink={`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`}
      />
      <ContentContainer>
        <Box display="flex" justifyContent="center">
          <Image src={success} alt="preview" width={128} height={128} />
        </Box>
        <Text variant="title1" color="#005646" textAlign="center">
          Débit effectué !
        </Text>
        <Text variant="small" textAlign="center" padding={1}>
          Vous avez bien été débité d’un montant de :
        </Text>
        <Text variant="bodyBold" textAlign="center">
          {formatPrice(amount)}
        </Text>
      </ContentContainer>
      <Formik
        initialValues={{ subscribeNewsletter: '', acquisitionSource: '' }}
        onSubmit={handleSurveySubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            {isSuccess && !hasSource && <CustomerSurvey />}

            {embeded !== 'true' && (
              <ButtonBlock
                type="button"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
                disabled={isSubmitting}
              >
                Faire mon compte rendu
              </ButtonBlock>
            )}
          </>
        )}
      </Formik>
    </PageLayout>
  );
};

export default DebitSuccess;
