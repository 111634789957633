import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

const Header = styled.div<SpaceProps>`
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  position: sticky;
  width: 100vw;
  ${space}
`;

export default Header;
