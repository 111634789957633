import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { useTourStatus } from 'tourStatusContext';

import BottomBar from 'components/BottomBar/BottomBar';
import PageHeader from 'components/PageHeader';
import Box from 'components/atoms/Box';
import Header from 'components/atoms/Header';
import Tag from 'components/atoms/Tag';
import InfiniteDateSelector, { useDateSelector } from 'components/organisms/InfiniteDateSelector';
import StartTour from 'components/organisms/StartTour';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import SevenOpteamCalendar from './SevenOpteamCalendar';

const Calendar: React.FunctionComponent = () => {
  const { state } = useLocation() as { state?: { dateVisit: string } };
  const history = useHistory();
  const { selectedDate, selectedWeekIndex, handleDateChange, onWeekChange } = useDateSelector(
    state?.dateVisit || dayjs().format('YYYY-MM-DD'),
    0
  );
  const { tourStatus } = useTourStatus();

  useEffect(() => {
    history.replace({
      ...history.location,
      state: undefined,
    });
  }, [history]);

  return (
    <PageLayout height="100vh">
      <Header pt={1}>
        <PageHeader
          noPadding
          title="Réparations"
          mention={
            <Box mt={1}>
              <Tag variant="info" text="Tournées 7opteam" />
            </Box>
          }
        />
        <InfiniteDateSelector
          selectedDate={selectedDate}
          selectedWeekIndex={selectedWeekIndex}
          onWeekChange={onWeekChange}
          onDateChange={handleDateChange}
        />
      </Header>
      <ContentContainer overflow="scroll" minHeight="0px">
        <SevenOpteamCalendar selectedDate={selectedDate} />
      </ContentContainer>
      {tourStatus == 'not_started' && <StartTour />}
      <BottomBar />
    </PageLayout>
  );
};

export default Calendar;
