import React from 'react';

import { useEmployee } from 'employeeContext';
import { useHistory } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import ReconditionBottomBar from 'components/ReconditionBottomBar';
import Box from 'components/atoms/Box';
import WorkshopFilesList from 'components/molecules/WorkshopFilesList';
import PageLayout from 'components/templates/PageLayout';

import { Product } from 'types/products';

const AWAITING_SPARE_PART = 'awaiting_spare_part';
const PRODUCTION = 'production';

const ReconditionSparePartAvailabilityMonitoring: React.FunctionComponent = () => {
  const history = useHistory();
  const { employee } = useEmployee();

  const handleItemSelection = (product: Product) => {
    history.push(`/recondition/repair-cycle/${product.id}/`);
  };

  return (
    <PageLayout>
      <Box bg="white">
        <PageHeader title="Stock" />
      </Box>
      <WorkshopFilesList
        handleItemSelection={handleItemSelection}
        displayMissingSparePartsCount
        filter={{
          workshop: employee.workshop,
          location_usage: [AWAITING_SPARE_PART, PRODUCTION],
          existing_sku_demand: true,
        }}
      />
      <ReconditionBottomBar />
    </PageLayout>
  );
};

export default ReconditionSparePartAvailabilityMonitoring;
