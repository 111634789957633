import { useEffect, useState } from 'react';

import { useGetOne, useQuery } from 'react-admin';

import axios from 'axios';
import dataProvider, { APIResponse } from 'dataProvider';

import { AswoDevice } from 'types/aswo-devices';
import { CollectionFile } from 'types/collection-files';
import { Comment } from 'types/comment';
import { CustomerFile } from 'types/customer-files';
import { DeliveryItem } from 'types/delivery-items';
import { Repairman } from 'types/employees';
import { Location } from 'types/location';
import { Package } from 'types/package';
import { PackagePriceObject } from 'types/package-price-object';
import { ProductBrand } from 'types/product-brands';
import { ProductModel } from 'types/product-models';
import { ProductSupplier } from 'types/product-suppliers';
import { ProductType } from 'types/productTypes';
import { Product } from 'types/products';
import { QuoteElement } from 'types/quote-elements';
import { ShippingDelivery } from 'types/shipping-deliveries';
import { SkuDemand } from 'types/sku-demand';
import { SkuLocation } from 'types/sku-location';
import { SkuTransfer } from 'types/sku-transfer';
import { SparePart } from 'types/spare-part';
import { Supplier } from 'types/supplier';
import { Visit } from 'types/visits';
import { WorkshopFile } from 'types/workshop-files';
import { Workshop } from 'types/workshops';

import { compareVisit } from 'utils/visits';

import { post } from './http';

export const useHomeRepairVisitList = (
  repairmanId: number,
  visitDate: string
): { visitList: Visit[]; loadingVisits: boolean } => {
  const EXCLUDED_VISIT_TYPES = ['administrative'];
  const { data: visitList, loading: loadingVisits } = useQuery({
    type: 'getList',
    resource: 'visit',
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
      filter: {
        repairman_id: repairmanId,
        visit_date: visitDate,
        visit_status: ['done', 'intervention'],
        category: 'home_repair',
      },
    },
  }) as { data: Visit[]; loading: boolean };

  return {
    visitList:
      visitList &&
      visitList
        .filter((visit) => !EXCLUDED_VISIT_TYPES.includes(visit.visit_type))
        .sort(compareVisit),
    loadingVisits,
  };
};

export const useRepairman = (
  repairmanId: number
): { repairman: Repairman; loadingRepairman: boolean } => {
  const { data: repairman, loading: loadingRepairman } = useQuery({
    type: 'getOne',
    resource: 'common/employee',
    payload: {
      id: repairmanId,
    },
  });
  return { repairman, loadingRepairman };
};

export const useProducts = (
  customerFileId: number
):
  | { products: undefined; loadingProducts: true }
  | { products: Product[]; loadingProducts: false } => {
  const { data: customerFile, loading } = useGetOne<CustomerFile>('customer-file', customerFileId);
  return loading
    ? { products: undefined, loadingProducts: loading }
    : { products: (customerFile as CustomerFile).products, loadingProducts: loading };
};

export const postProduct = async (payload: {
  customer_file: string;
  product_type: string | undefined;
  product_type_link: number | undefined;
}): Promise<Product> => {
  const { data } = await dataProvider.create('products', {
    data: payload,
  });
  return data;
};

export const useSkuList = (
  filter: Record<string, unknown>
): { skuList: SparePart[]; loadingSku: boolean } => {
  const { data: skuList, loading } = useQuery({
    type: 'getList',
    resource: 'sku',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      filter: { ...filter },
    },
  }) as { data: SparePart[]; loading: boolean };
  return loading ? { skuList: [], loadingSku: loading } : { skuList: skuList, loadingSku: loading };
};

export const useSkuSuppliers = (): { skuSuppliers: Supplier[] } => {
  const { data: skuSuppliers } = useQuery({
    type: 'getList',
    resource: 'sku-suppliers',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'DESC' },
    },
  }) as { data: Supplier[] };
  return { skuSuppliers };
};

export const useProduct = (
  productId: number
): { product: Product; loadingProduct: boolean; error?: string } => {
  const {
    data: product,
    loading: loadingProduct,
    error,
  } = useQuery({
    type: 'getOne',
    resource: 'products',
    payload: {
      id: productId,
    },
  }) as { data: Product; loading: boolean; error: string };

  return { product, loadingProduct, error };
};

export const useWorkshopFile = (
  workshopFileId: number
): { workshopFile: WorkshopFile; loadingWorkshopFile: boolean } => {
  const { data: workshopFile, loading: loadingWorkshopFile } = useQuery({
    type: 'getOne',
    resource: 'workshop-files',
    payload: {
      id: workshopFileId,
    },
  }) as { data: WorkshopFile; loading: boolean };

  return { workshopFile, loadingWorkshopFile };
};

export const useWorkshopFiles = (
  filter?: Record<string, unknown>,
  page?: number,
  pageSize?: number
): { workshopFiles: WorkshopFile[]; total: number; loading: boolean; error: boolean } => {
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'workshop-files',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      pagination: { page: page || 1, perPage: pageSize || 50 },
      filter: { ...filter },
    },
  }) as { data: WorkshopFile[]; total: number; loading: boolean; error: boolean };
  return { workshopFiles: data, total, loading, error };
};

export const useEmployeeAssociatedLocations = (
  workshopId?: number,
  usageName?: string
): { locations: Location[] } => {
  const filter = {
    workshop: workshopId,
    usage: usageName,
  };
  const { data: locations } = useQuery({
    type: 'getList',
    resource: 'location',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'DESC' },
      filter: { ...filter },
    },
  }) as { data: Location[] };
  return { locations };
};

export const useLocation = (id: number): Location => {
  const { data: location } = useQuery({
    type: 'getOne',
    resource: 'location',
    payload: {
      id: id,
    },
  }) as { data: Location };
  return location;
};

export const useWorkshops = (): { workshops: Workshop[] } => {
  const { data: workshops } = useQuery({
    type: 'getList',
    resource: 'workshops',
    payload: {
      sort: { field: 'name', order: 'DESC' },
      filter: '',
    },
  }) as { data: Workshop[] };
  return { workshops };
};

export const useCollectionFiles = (
  filter?: Record<string, unknown>
): { collectionFiles: CollectionFile[] } => {
  const { data: collectionFiles } = useQuery({
    type: 'getList',
    resource: 'collection-files',
    payload: {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'DESC' },
      filter: { ...filter },
    },
  }) as { data: CollectionFile[] };
  return { collectionFiles };
};

export const useCustomerFile = (
  customerFileId?: string
): { customerFile: CustomerFile; isCustomerFileLoading: boolean } => {
  const { data: customerFile, loading: isCustomerFileLoading } = useQuery({
    type: 'getOne',
    resource: 'customer-file',
    payload: {
      id: customerFileId,
    },
  }) as { data: CustomerFile; loading: boolean };

  return { customerFile, isCustomerFileLoading };
};

export const useDeliveryItems = (
  filter?: Record<string, unknown>
): { deliveryItems: DeliveryItem[] } => {
  const { data: deliveryItems } = useQuery({
    type: 'getList',
    resource: 'delivery-items',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      filter: { ...filter },
    },
  }) as { data: DeliveryItem[] };
  return { deliveryItems };
};

export const getSkuDemands = async (filter?: Record<string, unknown>): Promise<SkuDemand[]> => {
  const { data } = (await dataProvider.getList('sku-demands', {
    sort: { field: 'id', order: 'DESC' },
    filter: { ...filter },
  })) as { data: SkuDemand[] };
  return data;
};

export const useProductSupplierList = (): { productSuppliers: ProductSupplier[] } => {
  const { data: productSuppliers } = useQuery({
    type: 'getList',
    resource: 'product-suppliers',
    payload: {
      sort: { field: 'name', order: 'DESC' },
      filter: '',
    },
  }) as { data: ProductSupplier[] };
  return { productSuppliers };
};

export const useDeliveries = (
  filter?: Record<string, unknown>
): { deliveries: ShippingDelivery[] } => {
  const { data: deliveries } = useQuery({
    type: 'getList',
    resource: 'deliveries',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      filter: { ...filter },
    },
  }) as { data: ShippingDelivery[] };
  return { deliveries };
};

export const patchAccount = async (payload: Record<string, unknown>): Promise<void> => {
  await dataProvider.update('account', {
    user_modification: true,
    data: payload,
  });
};

export const useManyProducts = (productIds: number[]): { products: Product[] } => {
  const { data: products } = useQuery({
    type: 'getMany',
    resource: 'products',
    payload: { ids: productIds },
  }) as { data: Product[] };
  return { products };
};

export const updateProduct = async (
  productId: number,
  payload: Record<string, unknown>
): Promise<void> => {
  await dataProvider.update(
    'products',
    {
      id: productId,
      data: payload,
    },
    'formData'
  );
};

export const useProductBrands = (): { productBrands: ProductBrand[] } => {
  const { data: productBrands } = useQuery({
    type: 'getList',
    resource: 'product-brands',
    payload: {
      sort: { field: 'name', order: 'ASC' },
    },
  }) as { data: ProductBrand[] };
  return { productBrands };
};

export const createProductModel = async (payload: {
  productBrand: number | null;
  reference: string;
  productType: number;
}): Promise<ProductModel> => {
  const { data } = await dataProvider.create('product-models', {
    data: {
      brand: payload.productBrand,
      reference: payload.reference,
      product_type: payload.productType,
    },
  });
  return data;
};

export const getProductModels = async (
  filter: Record<string, unknown>
): Promise<ProductModel[]> => {
  const { data } = await dataProvider.getList('product-models', {
    sort: { field: 'reference', order: 'ASC' },
    filter: filter,
  });
  return data;
};

export const getProductModel = async (productModelId: number): Promise<ProductModel> => {
  const { data } = await dataProvider.getOne(`sku/product-models/${productModelId}`, {});
  return data;
};

export const useProductModel = (productModelId: number | null): ProductModel | undefined => {
  const [productModel, setProductModel] = useState<ProductModel | undefined>();

  useEffect(() => {
    const fetchProductModel = async () => {
      if (productModelId) {
        const data = await getProductModel(productModelId);
        setProductModel(data);
      }
    };
    if (productModelId) {
      fetchProductModel();
    }
  }, [productModelId]);

  return productModel;
};

export const postSparePartDefect = async (payload: {
  spare_part: number;
  defect: string;
  has_been_tested_fitted: boolean;
  comment: string;
  pictures: File[];
}): Promise<void> => {
  const url = '/spare-part/defects/';

  try {
    // Ensure all values are of the correct type
    const dataToSend = {
      spare_part: payload.spare_part.toString(),
      defect: payload.defect,
      has_been_tested_fitted: payload.has_been_tested_fitted.toString(),
      comment: payload.comment,
      pictures: payload.pictures,
    };
    return await post<any>(url, dataToSend, true);
  } catch (error) {
    console.error('Error posting spare part defect:', error);
    throw error;
  }
};

export const getSparePartsList = async (filter: Record<string, unknown>): Promise<SparePart[]> => {
  const { data } = await dataProvider.getList('spare-parts', {
    sort: { field: 'id', order: 'DESC' },
    filter: filter,
  });
  return data;
};

export const useSpareParts = (
  filter?: Record<string, unknown>,
  page?: number,
  pageSize?: number
): { spareParts: SparePart[]; total: number; loading: boolean; error: boolean } => {
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'spare-parts',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      pagination: page && pageSize ? { page: page, perPage: pageSize } : {}, // warining: can lead to timeout
      filter: { ...filter },
    },
  }) as { data: SparePart[]; total: number; loading: boolean; error: boolean };
  return { spareParts: data, total, loading, error };
};

export const useSparePart = (sparePartId: number): { sparePart: SparePart } => {
  const { data: sparePart } = useQuery({
    type: 'getOne',
    resource: 'spare-parts',
    payload: {
      id: sparePartId,
    },
  });
  return { sparePart };
};

export const getSparePart = async (filter: Record<string, unknown>): Promise<SparePart> => {
  const { data } = await dataProvider.getOne('spare-parts', filter);
  return data;
};

export const getSparePartCount = async (filter: Record<string, unknown>): Promise<number> => {
  const { data } = await dataProvider.getList('spare-parts-count', { filter: filter });
  return data;
};

export const getSparePartUsingId = async (codeOrId: string): Promise<SparePart> => {
  const { data } = await dataProvider.getOne(`murfy-erp/spare-parts/${codeOrId}`, {});
  return data;
};

export const patchSparePart = async (
  sparePartId: number,
  payload: Record<string, unknown>
): Promise<void> => {
  await dataProvider.update('spare-parts', {
    id: sparePartId,
    data: payload,
  });
};

export const packSparePart = async (
  uniqueId: string,
  demandItemId: number
): Promise<{ id: number }> => {
  const { data: idSparePart } = await dataProvider.create('spare-part-packing', {
    data: { unique_id: uniqueId, demand_item_id: demandItemId },
  });
  return idSparePart;
};

export const packSparePartWithSystemId = async (
  sparePartId: number,
  demandItemId: number
): Promise<{ id: number }> => {
  const { data: idSparePart } = await dataProvider.create('spare-part-packing', {
    data: { spare_part_id: sparePartId, demand_item_id: demandItemId },
  });
  return idSparePart;
};
export const patchManySpareParts = async (
  sparePartIds: number[],
  payload: Record<string, unknown>
): Promise<void> => {
  await dataProvider.updateMany('spare-parts', { ids: sparePartIds, data: payload });
};

export const usePackages = (
  filter?: Record<string, unknown>
): { packages: Package[]; total: number; loading: boolean; error: boolean } => {
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'package',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      filter: { ...filter },
    },
  }) as { data: Package[]; total: number; loading: boolean; error: boolean };
  return { packages: data, total, loading, error };
};

export const getPackages = async (filter?: Record<string, unknown>): Promise<Package[]> => {
  const { data } = await dataProvider.getList('package', {
    sort: { field: 'id', order: 'DESC' },
    filter: filter,
  });
  return data;
};

export const getPackage = async (codeOrId: string): Promise<Package> => {
  const { data } = await dataProvider.getOne(`murfy-erp/package/${codeOrId}`, {});
  return data;
};

export const getPackageFromCode = async (code: string): Promise<Package> => {
  const { data } = await dataProvider.getOne('package', { code: code });
  return data[0];
};

export const patchPackage = async (
  packageId: number,
  payload: Record<string, unknown>
): Promise<void> => {
  await dataProvider.update('package', {
    id: packageId,
    data: payload,
  });
};

export const postPackage = async (payload: {
  code: string;
  status: string;
  employee: number;
  sent_at: Date;
  employee_name: string;
}): Promise<Package> => {
  const { data } = await dataProvider.create('package', {
    data: payload,
  });
  return data;
};

export const postSparePartIdentification = async (sparePartIdentification: {
  unique_id: string;
}): Promise<{ system_id: number }> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/spare-part/spare-part-identification/`,
    sparePartIdentification,
    { headers: { Authorization: `Token ${localStorage.getItem('token')}` } }
  );
  return data;
};

export const getCustomerFile = async (filter: Record<string, unknown>): Promise<CustomerFile> => {
  const { data } = await dataProvider.getOne('customer-file', filter);
  return data;
};

export const getWorkshopFile = async (filter: Record<string, unknown>): Promise<WorkshopFile> => {
  const { data } = await dataProvider.getOne('workshop-files', filter);
  return data;
};

export const useComments = (filter: Record<string, unknown>): { comments: Comment[] } => {
  const { data: comments } = useQuery({
    type: 'getList',
    resource: 'comments',
    payload: {
      filter: filter,
      sort: { field: 'id', order: 'DESC' },
    },
  });
  return { comments };
};

export const postQuote = async (payload: {
  customer_file: string;
  quote_elements: QuoteElement[];
  quote_accepted?: boolean;
  quote_refused?: boolean;
  version?: number;
}): Promise<void> => {
  await dataProvider.create('quotes', {
    data: payload,
  });
};

export const useProductTypesList = (): {
  productTypes: ProductType[];
  loadingProductTypes: boolean;
} => {
  const { data: productTypes, loading: loadingProductTypes } = useQuery({
    type: 'getList',
    resource: 'product-types',
    payload: {
      filter: '',
      sort: { field: 'id', order: 'DESC' },
    },
  });
  return { productTypes, loadingProductTypes };
};

export const useSparePartLocations = (
  filter: Record<string, unknown>
): { sparePartLocations: SkuLocation[]; sparePartsLoading: boolean } => {
  const { data: sparePartLocations, loading: sparePartsLoading } = useQuery({
    type: 'getList',
    resource: 'sku-location',
    payload: {
      filter: filter,
      sort: { field: 'id', order: 'DESC' },
    },
  });
  return { sparePartLocations, sparePartsLoading };
};

export const postSparePartTransfer = async (payload: {
  transfer_type: 'repairman_to_repairman' | 'repairman_to_warehouse' | 'package';
  status: 'planned' | 'in_progress' | 'done';
  final_location: number;
}): Promise<SkuTransfer> => {
  const { data } = await dataProvider.create('sku-transfer', {
    data: payload,
  });
  return data;
};

export const usePackagePrices = (postalCode: string, productType?: string): PackagePriceObject => {
  const { data } = useQuery({
    type: 'getOne',
    resource: 'repair-package-prices',
    payload: {
      postal_code: postalCode,
      product_type: productType && encodeURIComponent(productType),
      sort: { field: 'name', order: 'ASC' },
    },
  }) as { data: PackagePriceObject };
  return data;
};

export const postSparePartRegularisation = async (sparePartData: {
  unique_id: string;
  location: number;
  type?: string;
}): Promise<{ id: number; location: number; unique_id: string }> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/spare-part/spare-part-regularisation/`,
    sparePartData,
    { headers: { Authorization: `Token ${localStorage.getItem('token')}` } }
  );
  return data;
};
export const getAswoDevices = async (reference_model: string): Promise<AswoDevice[]> => {
  const { data: devices } = await dataProvider.getList('aswo-devices', {
    sort: { field: 'id', order: 'DESC' },
    filter: { search: reference_model },
  });
  return devices;
};

type DevicesReturnValue = {
  isSuccess: boolean;
  devices: AswoDevice[];
};
export const useAswoDevices = (referenceModel: string): DevicesReturnValue => {
  const [devices, setDevices] = useState<AswoDevice[]>([] as AswoDevice[]);

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    let abort = false;
    const fetchAswoDevices = async () => {
      const data = await getAswoDevices(referenceModel);
      if (!abort) {
        setDevices(data);
        setIsSuccess(true);
      }
    };
    fetchAswoDevices();
    return () => {
      abort = true;
    };
  }, [referenceModel]);

  return { isSuccess, devices };
};

export const updateProductsFunctionalStatus = (
  products: Product[],
  values: string[]
): Promise<APIResponse> => {
  const data: { functional_status: string }[] = values.map((value) => ({
    functional_status: value,
  }));

  return dataProvider.updateMany('products', {
    ids: products.map((product) => product.id),
    data: data,
  });
};
