import React, { useState } from 'react';

import { MarginProps } from 'styled-system';

import CustomerFileHistoryContent from 'pages/CustomerFileHistory/CustomerFileHistoryContent';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { useCustomerFile } from 'utils/api/api';

type AssociatedCustomerFileProps = {
  fileNumber: string;
} & MarginProps;

const AssociatedCustomerFile: React.FunctionComponent<AssociatedCustomerFileProps> = ({
  fileNumber,
}: AssociatedCustomerFileProps) => {
  const { customerFile } = useCustomerFile(fileNumber);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  return (
    <>
      <Box
        onClick={() => setIsHistoryOpen(true)}
        backgroundColor={'green700'}
        p={1}
        borderRadius={1}
      >
        <Text textAlign="start" color="white" variant="smallBold">
          {`Voir l'historique de réparation à domicile ➔`}
        </Text>
      </Box>
      {isHistoryOpen && (
        <PortalComponent
          open={isHistoryOpen}
          onClose={() => setIsHistoryOpen(false)}
          title="Historique des interventions RD"
        >
          <CustomerFileHistoryContent customerFileId={customerFile.id.toString()} />
        </PortalComponent>
      )}
    </>
  );
};
export default AssociatedCustomerFile;
