import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { Field, FieldProps, Form, Formik } from 'formik';
import { useModals } from 'modalsContext';
import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import AutocompleteInput from 'components/atoms/AutocompleteInput';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

import { patchManySpareParts, postSparePartTransfer, useSparePartLocations } from 'utils/api/api';

const REPAIRMAN_TO_WAREHOUSE = 'repairman_to_warehouse';
const REPAIRMAN_TO_REPAIRMAN = 'repairman_to_repairman';
const IN_PROGRESS = 'in_progress';
const TRANSIT_TECH_TRANSFER = 'transit_tech_transfer';

const REPAIRMAN_LOCATION_TYPE = 'repairman';
const WORKSHOP_LOCATION_TYPE = 'workshop';

type SparePartTransferToNewLocationProps = {
  skus: SparePart[];
  open: boolean;
  onClose: () => void;
  resetSkus: () => void;
  setNextStep: (step: number) => void;
  addLocation: (location: SkuLocation) => void;
};

const StyledForm = styled(Form)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
});

const StyledCloseIcon = styled(CloseIcon)({
  color: '#8795B3',
  position: 'absolute',
  right: 16,
  top: 16,
});

const SparePartTransferToNewLocation: React.FunctionComponent<
  SparePartTransferToNewLocationProps
> = ({ skus, open, onClose, resetSkus }) => {
  const { showModal, closeModal } = useModals();

  const { sparePartLocations } = useSparePartLocations({
    location_type: [WORKSHOP_LOCATION_TYPE, REPAIRMAN_LOCATION_TYPE],
  });

  const isReturnToWarehouse = (id: number | null): boolean => {
    if (!id) return false;
    return !!sparePartLocations
      .filter((location: SkuLocation) => !location.employee)
      ?.find((location: SkuLocation) => location.id === id);
  };

  const showTransferConfirmation = (title: string, content: string) => {
    showModal({
      modalToShow: 'SUCCESS_MODAL',
      modalProps: {
        title: title,
        onConfirm: () => {
          closeModal();
          handleCloseModal();
        },
        content: content,
        fullScreen: true,
        animation: true,
      },
    });
  };

  const saveAndClose = async (values: { location: number | null }) => {
    if (values.location) {
      try {
        const transfer = await postSparePartTransfer({
          transfer_type: isReturnToWarehouse(values.location)
            ? REPAIRMAN_TO_WAREHOUSE
            : REPAIRMAN_TO_REPAIRMAN,
          status: IN_PROGRESS,
          final_location: values.location,
        });

        await patchManySpareParts(
          skus?.map((sparePart: SparePart) => sparePart.id),
          {
            transfer: [transfer.id],
            expedition: TRANSIT_TECH_TRANSFER,
          }
        );
        showTransferConfirmation(
          'Transfert confirmé',
          sparePartLocations.find(
            (location: SkuLocation) =>
              location.id === parseInt(values.location as unknown as string)
          )?.name || 'Erreur'
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCloseModal = () => {
    resetSkus();
    onClose();
  };

  const optionRenderer = (choice: SkuLocation | undefined) => {
    if (!choice) return;
    return `${isReturnToWarehouse(choice.id) ? `${choice.name}` : choice.employee_name}`;
  };
  const initialValues: { location: number | null } = {
    location: null,
  };

  return (
    <PortalComponent open={open} onClose={onClose}>
      <StyledCloseIcon onClick={onClose} />
      <Text variant="title2" textAlign="center" paddingTop="10%" color="dark">
        Choisir le destinataire
      </Text>
      <Formik onSubmit={saveAndClose} initialValues={initialValues}>
        {({ isSubmitting, isValid, dirty }) => (
          <StyledForm>
            <Field key="location" name="location">
              {({ field: { name, value }, form }: FieldProps) => {
                return (
                  <Box margin="auto" width="90%">
                    <AutocompleteInput
                      isCreatableEnabled={false}
                      name={name}
                      isClearable
                      options={sparePartLocations.map((location: SkuLocation) => ({
                        label: optionRenderer(location) || '',
                        value: location.id.toString(),
                      }))}
                      placeholder="Emplacement"
                      value={
                        {
                          label:
                            optionRenderer(
                              sparePartLocations.find(
                                (item: SkuLocation) => item.id.toString() === value?.toString()
                              )
                            ) || '',
                          value:
                            sparePartLocations.find(
                              (item: SkuLocation) => item.id.toString() === value?.toString()
                            )?.id || '',
                        } as { label: string; value: string }
                      }
                      onChange={(event) => {
                        form.setFieldValue('location', event?.value);
                      }}
                    />
                  </Box>
                );
              }}
            </Field>
            <Box display="grid" width="90%" position="absolute" bottom={2}>
              <Button
                mt={2}
                data-testid="bottom-button"
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
              >
                Confirmer
              </Button>
            </Box>
          </StyledForm>
        )}
      </Formik>
    </PortalComponent>
  );
};

export default SparePartTransferToNewLocation;
