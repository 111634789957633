import React, { InputHTMLAttributes } from 'react';

import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import InputBase from 'components/atoms/InputBase';

interface InputWithIconProps extends InputHTMLAttributes<HTMLInputElement>, MarginProps {
  name: string;
  Icon: React.FC;
  noStyling?: boolean;
}

const InputWithIcon: React.FunctionComponent<InputWithIconProps> = ({
  name,
  Icon,
  noStyling,
  ...rest
}: InputWithIconProps) => {
  const StyledIcon = styled(Icon)`
    position: absolute;
    right: ${(props) => props.theme.space[2]}px;
    color: ${(props) => props.theme.colors.grey900};
  `;
  return (
    <Box
      bg="white"
      borderRadius="16px"
      maxHeight={168}
      position="relative"
      display="flex"
      alignItems="center"
      {...rest}
    >
      <InputBase name={name} {...rest} />
      {noStyling ? <Icon /> : <StyledIcon />}
    </Box>
  );
};

export default InputWithIcon;
