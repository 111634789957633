import React from 'react';

import dayjs from 'dayjs';

import ItemDetailField from 'components/atoms/ItemDetailField';

import { Location } from 'types/location';
import { Product } from 'types/products';
import { ShippingDelivery } from 'types/shipping-deliveries';

type ProductDetailFieldProps = {
  product: Product;
  onclick?: () => void;
  onRemove?: () => void;
  checked?: boolean;
  showBooking?: boolean;
  removeable: boolean;
  location?: Location;
  associatedDelivery?: ShippingDelivery;
  errored?: boolean;
  customText?: React.ReactNode;
};

const ProductDetailField: React.FunctionComponent<ProductDetailFieldProps> = ({
  product,
  onclick,
  checked,
  onRemove,
  removeable,
  location,
  associatedDelivery,
  errored,
  customText,
}: ProductDetailFieldProps) => {
  return (
    product && (
      <ItemDetailField
        topText={product.barcode}
        customText={customText}
        checked={checked}
        errored={errored}
        bottomText={`
        ${product.product_type} ${product.brand} ${
          associatedDelivery &&
          `\n Transporteur: ${
            associatedDelivery?.interregional_shipping_provider
              ? `${associatedDelivery?.interregional_shipping_provider.name} puis`
              : ''
          } ${
            associatedDelivery?.last_mile_shipping_provider
              ? ` ${associatedDelivery?.last_mile_shipping_provider.name}`
              : ''
          } \n Expédition: ${dayjs(associatedDelivery.expedition_date).format('DD/MM/YYYY')}`
        }
        `}
        onclick={onclick}
        location={product?.location_name || location?.code}
        removeable={removeable}
        onRemove={onRemove}
      />
    )
  );
};

export default ProductDetailField;
