import React from 'react';

export const PackageIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0L0 3.75V16.25L10 20L20 16.25V3.75L10 0ZM12.5 3.4825L16.25 5.1625V9.5375L13.75 10.475V6.1L10 4.55L12.5 3.4875V3.4825ZM2.5 14.02L5 14.9575V16.2925L2.5 15.355V14.02Z"
      fill="#005646"
    />
  </svg>
);
