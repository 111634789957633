import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PrintIcon from '@material-ui/icons/Print';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Image from 'components/atoms/Image';
import SecondHandIcon from 'components/atoms/SecondHandIcon';
import Text from 'components/atoms/Text/Text';
import placeholder from 'components/organisms/SkuModelInfos/assets/preview.png';

import { SkuModelReference } from 'types/sku-model-reference';

import StoreStyles from '../StoreStyles';

const StyledImage = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 0px;
`;

type SparePartPackingFieldProps = {
  designation: string;
  references: SkuModelReference[];
  isSecondHand: boolean;
  isSelected: boolean;
  isLost: boolean;
  isSurplus: boolean;
  locationCode: string;
  printSku: () => void;
  lossClick: () => void;
  thumbnail?: string;
};

export const SparePartPackingField: React.FunctionComponent<SparePartPackingFieldProps> = ({
  designation,
  references,
  isSecondHand,
  isSelected,
  isLost,
  isSurplus,
  locationCode,
  printSku,
  lossClick,
  thumbnail,
}: SparePartPackingFieldProps) => {
  return (
    <Box display="grid" gridTemplateColumns="100px auto 20px" justifyContent="initial">
      <Box margin="auto">
        <StyledImage src={thumbnail || placeholder} alt="preview" />
      </Box>
      <Box>
        <Box textAlign="start" display="grid" pl={2}>
          <Text variant="small">
            <Box display="inline-flex">
              <Text variant="smallBold" mr={1}>
                {references[0].supplier_name}_{references[0].reference}
                {isSecondHand && (
                  <Box ml={1}>
                    <SecondHandIcon />
                  </Box>
                )}
              </Text>
              {(isSurplus || isSelected) && (
                <Box mx={2} color={isSurplus ? '#F7B349' : '#81D89F'}>
                  <CheckCircleIcon />
                </Box>
              )}
            </Box>
          </Text>
          <Text mt={1} variant="small" color="grey900">
            {designation}
          </Text>
          <Text mt={1} variant="smallBold" display="flex">
            <>{locationCode}</>
            <Box color="honey">
              <LocationOnIcon color="inherit" />
            </Box>
            {isLost && <Text variant="small">Perdu</Text>}
          </Text>
        </Box>
      </Box>
      {!isLost && (
        <Box display="grid" alignItems="center">
          <ReportOutlinedIcon onClick={lossClick} />
          <PrintIcon onClick={printSku} />
        </Box>
      )}
    </Box>
  );
};

export default withStyles(StoreStyles)(SparePartPackingField);
