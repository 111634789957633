import React, { useState } from 'react';

import CloudDoneIcon from '@material-ui/icons/CloudDone';

import dataProvider from 'dataProvider';
import dayjs from 'dayjs';
import { useModals } from 'modalsContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ConfirmationPortal from 'components/ConfirmationPortal';
import SkuListSelectionPortal from 'components/SkuListSelectionPortal';
import SparePartSelectionPortal from 'components/SparePartSelectionPortal';

import { SkuInventory } from 'types/sku-inventory';
import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

import { isItemInList } from 'utils/list';
import { useInventory } from 'utils/useInventory';

export type SkuInventoryProps = {
  skuList: SparePart[];
  open: boolean;
  onClose: () => void;
  location: SkuLocation;
  surplusStatus: string;
  skuFilter?: Record<string, unknown>;
  lossReason: string;
  redirectUrl: string;
  ConfirmationButtonText: string;
  isCreationAllowed?: boolean;
  SkuCreationStatus?: string;
};

const TurquoiseIcon = styled(CloudDoneIcon)((props) => ({
  color: props.theme.colors.turquoise,
}));

const SkuInventoryPortal: React.FunctionComponent<SkuInventoryProps> = ({
  skuList,
  open,
  onClose,
  location,
  surplusStatus,
  skuFilter,
  lossReason,
  redirectUrl,
  ConfirmationButtonText,
  isCreationAllowed,
  SkuCreationStatus,
}: SkuInventoryProps) => {
  const history = useHistory();
  const { showModal, closeModal } = useModals();
  const [baseSkuList, setBaseSkuList] = useState<SparePart[]>(skuList);
  const [isSkuSelectionOpen, setIsSkuSelectionOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isDeclaringLosses, setIsDeclaringLosses] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [sku, setSku] = useState<SparePart>();

  React.useEffect(() => {
    if (skuList) {
      setBaseSkuList(skuList);
      handleGetInventoryFromStorage();
    }
  }, [skuList]);

  const handleGetInventoryFromStorage = () => {
    const stringifiedSkuInventory = localStorage.getItem('skuInventory');
    if (stringifiedSkuInventory !== null) {
      const skuInventory = JSON.parse(stringifiedSkuInventory);
      if (location.id === skuInventory.location.id && skuInventory.skus.length > 0) {
        toggleAvailableInventoryModal(skuInventory);
      }
    }
  };

  const toggleSkuSelection = () => {
    setIsSkuSelectionOpen(true);
  };

  const handleCloseSparePartSelectionPortal = () => {
    setIsSkuSelectionOpen(false);
  };

  const toggleAlreadySelectedModal = () => {
    showModal({
      modalToShow: 'INFORMATION_MODAL',
      modalProps: {
        title: 'La pièce est déjà sélectionnée',
        content: '',
        onConfirm: () => {
          closeModal();
        },
      },
    });
  };

  const toggleErrorModal = () => {
    showModal({
      modalToShow: 'INFORMATION_MODAL',
      modalProps: {
        title: 'Oops',
        content: 'une erreur est survenue',
        onConfirm: () => {
          closeModal();
        },
      },
    });
  };

  const toggleReturnButtonModal = () => {
    showModal({
      modalToShow: 'CONFIRM_MODAL',
      modalProps: {
        title: "Quitter l'inventaire ?",
        content: '',
        onCancel: closeModal,
        onConfirm: () => {
          closeModal();
          onClose();
        },
      },
    });
  };

  const toggleAvailableInventoryModal = (skuInventory: SkuInventory) => {
    showModal({
      modalToShow: 'CONFIRM_MODAL',
      modalProps: {
        title: "Continuer l'inventaire ?",
        content: [
          dayjs(skuInventory.created_at).locale('fr').format('HH:mm dddd DD MMMM'),
          `${skuInventory.skus.length} pièce(s) sélectionnée(s)`,
        ],
        onCancel: () => {
          localStorage.removeItem('skuInventory');
          closeModal();
        },
        onConfirm: () => {
          restoreStorageInventory(skuInventory);
          closeModal();
        },
      },
    });
  };

  const restoreStorageInventory = (skuInventory: SkuInventory) => {
    forceItemsSelection(skuInventory.skus);
  };

  const handleSelectAndStore = (sku: SparePart) => {
    handleItemSelection(sku);
    if (!isItemInList(sku, selectedSkuList)) {
      localStorage.setItem(
        'skuInventory',
        JSON.stringify({
          created_at: new Date(),
          skus: [...selectedSkuList, sku],
          location: location,
        })
      );
    }
  };

  const handleLossSubmission = () => {
    showModal({
      modalToShow: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Confirmer les pertes',
        content: 'Nous allons sortir ces pièces de votre stock',
        onCancel: closeModal,
        onConfirm: () => {
          declareLossForSelectedSkus(
            () => setIsDeclaringLosses(true),
            () => setIsDeclaringLosses(false),
            () => {
              setIsConfirmationOpen(true);
            },
            (e) => console.error(e)
          );
          closeModal();
        },
      },
    });
  };

  const declareLossForSelectedSkus = (
    onStart: () => void,
    onEnd: () => void,
    onSuccess: () => void,
    onError: (e: string) => void
  ) => {
    onStart();
    dataProvider
      .updateMany('sku', {
        ids: (getItemsToDeclareLost() as SparePart[]).map((sku: SparePart) => sku.id),
        data: {
          status: 'lost',
          loss_reason: lossReason,
          expected_location: location.id,
        },
      })
      .then(() => {
        handleSubmissionSuccess();
      })
      .catch((e) => onError(e))
      .finally(() => onEnd());
  };

  const handleSurplusManagement = () => {
    const surplusSkuList = getSurplusItemList();
    if (surplusSkuList.length > 0) {
      setIsSubmitting(true);
      dataProvider
        .updateMany('sku', {
          ids: surplusSkuList.map((sku) => sku.id),
          data: {
            location_id: location.id,
            transfer_type: 'inventory_regulation',
            status: surplusStatus,
          },
        })
        .catch((e) => console.error(e))
        .finally(() => {
          setIsSubmitting(false);
          // add surplus to base list
          setBaseSkuList([...baseSkuList, ...getSurplusItemList()] as SparePart[]);
        });
    }
  };

  const handleSubmissionSuccess = () => {
    setIsSubmitting(true);
    dataProvider
      .create('sku-inventory-task', {
        data: {
          state: 'done',
          location: location.id,
          skus: selectedSkuList.map((x) => x.id),
        },
      })
      .then(() => {
        setIsConfirmationOpen(true);
      })
      .catch(() => {
        toggleErrorModal();
      })
      .finally(() => {
        localStorage.removeItem('skuInventory');
        setIsSubmitting(false);
      });
  };

  const {
    shouldDisplayBaseList,
    submitInventory,
    getItemsToDeclareLost,
    getSurplusItemList,
    selectedItemList: selectedSkuList,
    handleItemSelection,
    forceItemsSelection,
  } = useInventory({
    itemList: baseSkuList,
    declareSurplus: handleSurplusManagement,
    onSuccess: handleSubmissionSuccess,
    duplicateWarning: toggleAlreadySelectedModal,
  });

  const closeAndRedirect = () => {
    setIsConfirmationOpen(false);
    onClose();
    history.push(redirectUrl);
  };

  return (
    <>
      <SkuListSelectionPortal
        skuList={shouldDisplayBaseList ? baseSkuList : (selectedSkuList as SparePart[])}
        selectedList={selectedSkuList as SparePart[]}
        open={open}
        onClose={toggleReturnButtonModal}
        isSaving={isSubmitting}
        title={`Inventaire - ${location?.name}`}
        buttonText="Inventaire terminé"
        onClickOnButton={() => submitInventory(baseSkuList, selectedSkuList)}
        openSkuSelection={toggleSkuSelection}
        surplusSkuList={
          shouldDisplayBaseList ? (getSurplusItemList() as SparePart[]) : ([] as SparePart[])
        }
      />
      <SkuListSelectionPortal
        open={getItemsToDeclareLost().length > 0}
        skuList={getItemsToDeclareLost() as SparePart[]}
        selectedList={selectedSkuList as SparePart[]}
        onClose={toggleReturnButtonModal}
        isSaving={isDeclaringLosses}
        title={`Pièces manquantes - ${location?.name}`}
        buttonText="Déclarer les pertes"
        onClickOnButton={handleLossSubmission}
        openSkuSelection={toggleSkuSelection}
        redButton
      />
      {isSkuSelectionOpen && (
        <SparePartSelectionPortal
          onConfirm={handleSelectAndStore}
          onClose={handleCloseSparePartSelectionPortal}
          open={isSkuSelectionOpen}
          filter={{ ...skuFilter, location_id: location.id }}
          selectedIds={selectedSkuList.map((x) => x.id)}
          isCreationAllowed={isCreationAllowed}
          skuCreationStatus={SkuCreationStatus}
          location={location}
          sku={sku}
          setSku={setSku}
        />
      )}
      <ConfirmationPortal
        title="Inventaire enregistré"
        body="Ton stock est maintenant à jours !"
        buttonText={ConfirmationButtonText}
        icon={<TurquoiseIcon />}
        onConfirm={closeAndRedirect}
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </>
  );
};

export default SkuInventoryPortal;
