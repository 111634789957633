import React from 'react';

import { Formik, FormikProps } from 'formik';

import { BackEvent, SubmitEvent } from '../../types';
import { BACK, SUBMIT } from '../../types';
import { ValidationSchema } from '../../validators';
import AdditionalInformation from './AdditionalInformation';

type AdditionalInformationForm = {
  additional_information: string;
};

type AdditionalInformationProps = {
  send: (event: SubmitEvent | BackEvent) => void;
  validationSchema: ValidationSchema;
} & AdditionalInformationForm;

const AdditionalInformationContainer: React.FunctionComponent<AdditionalInformationProps> = ({
  additional_information,
  validationSchema,
  send,
}: AdditionalInformationProps) => {
  const back = () => {
    send({
      type: BACK,
      data: {},
    });
  };
  return (
    <Formik
      initialValues={{
        additional_information: additional_information,
      }}
      validationSchema={validationSchema}
      onSubmit={(values: { additional_information: string }) => {
        send({
          type: SUBMIT,
          data: {
            additional_information: values.additional_information,
          },
        });
      }}
    >
      {(props: FormikProps<AdditionalInformationForm>) => {
        return <AdditionalInformation back={back} {...props} />;
      }}
    </Formik>
  );
};

export default AdditionalInformationContainer;
