import * as React from 'react';

import dataProvider from 'dataProvider';
import { Field, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

import { SkuModel } from 'types/sku-model';
import { Supplier } from 'types/supplier';

export type CreateSkuModelProps = {
  initialSupplier: Supplier;
  initialSupplierReference: string;
  initialDesignation: string;
  onClose: () => void;
  handleSkuModelCreation: (skuModel: SkuModel) => void;
  open: boolean;
  skuSuppliers: Array<Supplier>;
  skuType?: string;
};

const BottomButton = styled(Button)((props) => ({
  bottom: props.theme.space[2],
  position: 'absolute',
  width: '90%',
}));

const StyledForm = styled(Form)((props) => ({
  background: props.theme.colors.grey200,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 150,
}));

export const CreateSkuModel: React.FunctionComponent<CreateSkuModelProps> = ({
  initialSupplier,
  initialSupplierReference,
  initialDesignation,
  onClose,
  handleSkuModelCreation,
  open,
  skuType,
}: CreateSkuModelProps) => {
  type FormValues = {
    designation: string | null;
    supplierReference: string | null;
    supplier: Supplier;
    manufacturerReference: string | null;
  };

  const initialValues: FormValues = {
    designation: initialDesignation,
    supplierReference: initialSupplierReference,
    supplier: initialSupplier,
    manufacturerReference: null,
  };

  const handleCreateSkuModel = (values: FormValues) => {
    return dataProvider
      .create('sku-model', {
        data: {
          designation: values.designation,
          supplier_reference: values.supplierReference,
          sku_reference: values.supplier.name + '_' + values.supplierReference,
          supplier: values.supplier.name,
          sku_type: skuType,
        },
      })
      .then((response) => {
        handleSkuModelCreation(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const validationSchema = yup.object().shape({
    supplierReference: yup.string().required('Référence requise'),
    designation: yup.string().required('Désignation requise'),
    supplier: yup.mixed().required('Fournisseur requis'),
    manufacturerReference: yup.mixed().notRequired(),
  });

  return (
    <PortalComponent open={open} onClose={onClose} title="Créer un modèle">
      <Formik
        onSubmit={handleCreateSkuModel}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ errors, values, isSubmitting }) => (
          <StyledForm>
            <Box mb={2} textAlign="left" width="80%">
              <Field name="manufacturerReference">
                {({ field: { name, onChange, value } }: FieldProps) => {
                  return (
                    <Input
                      width="100%"
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Référence constructeur"
                      data-testid="manufacturer-reference"
                    />
                  );
                }}
              </Field>
              <Text variant="small" color="red">
                {errors?.manufacturerReference}
              </Text>
            </Box>
            <Box mb={2} textAlign="left" width="80%">
              <Field name="supplier.name">
                {({ field: { name, onChange, value } }: FieldProps) => {
                  return (
                    <Input
                      width="100%"
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Fournisseur"
                      disabled
                    />
                  );
                }}
              </Field>
            </Box>
            <Box mb={2} textAlign="left" width="80%">
              <Field name="supplierReference">
                {({ field: { name, onChange, value } }: FieldProps) => {
                  return (
                    <Input
                      width="100%"
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder={`Référence ${values.supplier.name}`}
                    />
                  );
                }}
              </Field>
              <Text variant="small" color="red">
                {errors?.supplierReference}
              </Text>
            </Box>
            <Box mb={2} textAlign="left" width="80%">
              <Field name="designation">
                {({ field: { name, onChange, value } }: FieldProps) => {
                  return (
                    <Input
                      width="100%"
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Désignation"
                    />
                  );
                }}
              </Field>
              <Text variant="small" color="red">
                {errors?.designation}
              </Text>
            </Box>
            <BottomButton
              variant={'transparent'}
              data-testid="bottom-button"
              disabled={isSubmitting}
              type="submit"
            >
              Confirmer
            </BottomButton>
          </StyledForm>
        )}
      </Formik>
    </PortalComponent>
  );
};

export default CreateSkuModel;
