import { createStyles } from '@material-ui/core/styles';

const RepairReportIntermediateValidationScreenStyle = createStyles({
  intermediateValidationScreenContainer: {
    textAlign: 'center',
    height: '100vh',
  },
  confirmationScreenContent: {
    paddingTop: 124,
  },
  progressInfoContainer: {
    background: '#F4F3F8',
    borderRadius: 10,
    margin: 24,
    padding: 16,
  },
  biggerCheckedIcon: {
    color: '#4A55FE',
    height: 70,
    width: 70,
  },
  icons: {
    color: '#F7B349',
    verticalAlign: 'bottom',
  },
});

export default RepairReportIntermediateValidationScreenStyle;
