import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import SparePartDetailField from 'pages/Store/components/SparePartDetailField';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import { SparePart } from 'types/spare-part';

import { useSpareParts } from 'utils/api/api';

type SparePartListProps = {
  filter?: Record<string, unknown>;
  showBooking?: boolean;
  showOnboardedStock?: boolean;
  showBroken?: boolean;
  showLocation?: boolean;
  handleSparePartSelection: (sparePart: SparePart) => void;
};

const SparePartList: React.FunctionComponent<SparePartListProps> = ({
  filter,
  showBooking = false,
  showBroken = false,
  showOnboardedStock = false,
  showLocation = false,
  handleSparePartSelection,
}: SparePartListProps) => {
  const pageSize = 50;
  const [page, setPage] = useState<number>(1);
  const { spareParts, total, loading, error } = useSpareParts(filter, page, pageSize);

  return (
    <>
      {loading && (
        <Box mt={4} mx="auto">
          <Text textAlign="center" variant="subtitle">
            Récupération de la liste de pièces
          </Text>
          <Box mx="auto" width="fit-content" py={2}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {error && (
        <Text textAlign="center" variant="body" color="red">
          Une erreur est survenue lors du chargement des pièces
        </Text>
      )}
      {!loading && !error && (
        <>
          {spareParts.length > 0 && (
            <>
              <Text py={1} mt={1} textAlign="center" backgroundColor="white" variant="small">
                Page {page}/{Math.ceil(total / pageSize)} ({total})
              </Text>
              <Box mt={1} overflow="scroll">
                {spareParts?.map((sparePart: SparePart) => (
                  <SparePartDetailField
                    key={sparePart.id}
                    sparePart={sparePart}
                    showBooking={showBooking}
                    showBroken={showBroken}
                    showOnboardedStock={showOnboardedStock}
                    showLocation={showLocation}
                    rowClick={handleSparePartSelection}
                  />
                ))}
              </Box>
              <Box width="80%" display="flex" justifyContent="space-between">
                <Box>
                  {page > 1 && (
                    <Button variant="fullTransparent" onClick={() => setPage(page - 1)}>
                      Précédent
                    </Button>
                  )}
                </Box>
                <Box>
                  {page < Math.ceil(total / pageSize) && (
                    <Button variant="fullTransparent" onClick={() => setPage(page + 1)}>
                      Suivant
                    </Button>
                  )}
                </Box>
              </Box>
            </>
          )}
          {spareParts.length < 1 && (
            <Text textAlign="center" variant="body">
              Aucune pièce dans votre atelier
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default SparePartList;
