import { createStyles } from '@material-ui/core/styles';

const ReportStepStyle = createStyles({
  elementContainer: {
    display: 'grid',
    backgroundColor: '#F1F0F5',
  },
  sparePartsContainer: {
    marginBottom: 80,
  },
  stepQuestion: {
    height: 28,
    margin: '24px',
    fontWeight: 'bold',
  },
  euroIcon: {
    color: '#8795B3',
  },
  noPaymentButton: {
    display: 'flex',
    textDecoration: 'underline',
    textTransform: 'none',
    color: '#8795B3',
    padding: 0,
  },
  alternateButton: {
    margin: 0,
  },
});

export default ReportStepStyle;
