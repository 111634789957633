import React, { useEffect, useState } from 'react';

import { AppBar, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import SparePartDefectsDeclaration from 'pages/SparePartDefectsDeclaration';

import DefectsField from 'components/DefectsField';
import PortalComponent from 'components/Portal/PortalComponent';
import SecondHandIcon from 'components/atoms/SecondHandIcon';

import { SparePart } from 'types/spare-part';

import { getSparePart } from 'utils/api/api';

import StoreStyles from '../../StoreStyles';
import ManualConfirmationModal from '../ManualConfirmationModal';

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="left" ref={ref} {...props} />; // eslint-disable-line @typescript-eslint/no-explicit-any
});

const useStyles = makeStyles(StoreStyles);

type SparePartLossModalProps = {
  open: boolean;
  onClose: () => void;
  employeeName: string;
  defaultSparePart: SparePart;
  handleConfirmBarcode: (code: string) => void;
  handleSparePartLoss: () => void;
  mode: string;
};

const SparePartLossModal: React.FunctionComponent<SparePartLossModalProps> = ({
  open,
  onClose,
  employeeName,
  defaultSparePart,
  handleConfirmBarcode,
  handleSparePartLoss,
  mode,
}: SparePartLossModalProps) => {
  const classes = useStyles();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSparePartDefectOpen, setIsSparePartDefectOpen] = useState(false);
  const [sparePart, setSparePart] = useState<SparePart>(defaultSparePart);

  useEffect(() => {
    const fetchData = async () => {
      const part = await getSparePart({ id: defaultSparePart.id });
      setSparePart(part);
    };
    if (!isSparePartDefectOpen) {
      fetchData();
    }
  }, [isSparePartDefectOpen, defaultSparePart.id]);

  function handleLoss() {
    onClose();
    handleSparePartLoss();
  }

  function handleValidatePacking(code: string) {
    onClose();
    handleCloseManualAddModal();
    handleConfirmBarcode(code);
  }

  function handleOpenManualAddModal() {
    setIsModalOpened(true);
    setIsSparePartDefectOpen(false);
  }

  function handleCloseManualAddModal() {
    setIsModalOpened(false);
    setIsSparePartDefectOpen(false);
  }

  return (
    <>
      <PortalComponent open={open} onClose={onClose}>
        <AppBar color="transparent" className={classes.header}>
          <div className={classes.elementHeader}>
            <IconButton edge="start" onClick={onClose} className={classes.back}>
              <ArrowBackIcon />
            </IconButton>
            <span>{employeeName}</span>
          </div>
        </AppBar>
        <div className={classes.lossDialog}>
          <div className={classes.lossCard}>
            <div className={classes.lossHeadingText}>Référence</div>
            <div className={classes.lossDetailText}>
              {sparePart?.sku_model?.sku_reference}
              <Box ml={1}>{sparePart?.is_second_hand && <SecondHandIcon />}</Box>
            </div>
          </div>
          <div className={classes.lossCard}>
            <div className={classes.lossHeadingText}>Emplacement</div>
            <div className={classes.lossDetailText}>
              <span>{sparePart?.location?.code}</span>
              <LocationOnIcon className={classes.skuFieldIcon} />
            </div>
          </div>
          <Box mx={2}>
            {sparePart?.is_defective && sparePart?.defects && (
              <Box mt={1}>
                <DefectsField defects={sparePart.defects} />
              </Box>
            )}
          </Box>
          <Button
            className={`${classes.underlinedRed}`}
            onClick={() => setIsSparePartDefectOpen(true)}
          >
            Déclarer un défaut sur la pièce
          </Button>

          <div className={classes.lossManualAddText} onClick={handleOpenManualAddModal}>
            Impossible de scanner
          </div>

          <div className={classes.lossToolbar}>
            <div className={classes.wrapper} style={{ paddingLeft: 16, paddingTop: 10 }}>
              <Button className={`${classes.button} ${classes.buttonRed}`} onClick={handleLoss}>
                Déclarer une perte
              </Button>
            </div>
          </div>
        </div>
        <ManualConfirmationModal
          TransitionComponent={Transition}
          open={isModalOpened}
          onClose={handleCloseManualAddModal}
          handleValidatePacking={handleValidatePacking}
          sku={sparePart}
          mode={mode}
        />
      </PortalComponent>

      {isSparePartDefectOpen && (
        <SparePartDefectsDeclaration
          defaultSparePart={sparePart}
          handleClose={() => setIsSparePartDefectOpen(false)}
        />
      )}
    </>
  );
};

export default SparePartLossModal;
