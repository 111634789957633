import dataProvider from 'dataProvider';

import { SkuModel } from 'types/sku-model';
import { SparePart } from 'types/spare-part';

export const generateUniqueId = async (sparePart: SparePart): Promise<string> => {
  const sku_model: SkuModel = sparePart.sku_model;
  return dataProvider
    .getList('sku', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
      filter: { q: sku_model.supplier_reference },
    })
    .then(({ data: skuList }: { data: SparePart[] }) => {
      const existingSkuId =
        skuList.find(
          (element) => element.unique_id === sku_model.sku_reference + '/' + sparePart.id
        )?.id ?? -1;
      if (existingSkuId === -1) {
        return sku_model.sku_reference + '/' + sparePart.id;
      }
      if (existingSkuId === sparePart.id) return sku_model.sku_reference + '/' + sparePart.id;
      return sku_model.sku_reference + '/' + sparePart.id + 'x';
    });
};
