import React from 'react';

import Box from 'components/atoms/Box';

type SimpleBlockContentProps = {
  children?: React.ReactNode;
  noSeparator?: boolean;
  containerStyle?: boolean;
};

const SimpleBlockContent: React.FunctionComponent<SimpleBlockContentProps> = ({
  children,
  noSeparator,
  containerStyle,
}: SimpleBlockContentProps) => (
  <Box
    bg="white"
    display={containerStyle ? 'inherit' : 'flex'}
    flexWrap={containerStyle ? 'inherit' : 'wrap'}
    borderColor="grey500_2"
    borderBottomWidth="1px"
    borderBottomStyle={!noSeparator ? 'solid' : 'none'}
    borderTopWidth="1px"
    borderTopStyle={!noSeparator ? 'solid' : 'none'}
    pt={1}
    pl={2}
    pb={1}
    pr={2}
  >
    {children}
  </Box>
);

export default SimpleBlockContent;
