import React, { useState } from 'react';

import EuroIcon from '@material-ui/icons/Euro';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import InputWithIcon from 'components/molecules/InputWithIcon';
import ModalContainer from 'components/organisms/modals/ModalContainer';

export type InputModalProps = {
  title: string;
  onConfirm: (selectedAmount: number) => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  initialValue: number;
  fieldName: string;
};

const InputModal: React.FunctionComponent<InputModalProps> = ({
  title,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirmer',
  cancelLabel = 'Annuler',
  initialValue,
  fieldName,
}: InputModalProps) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  return (
    <ModalContainer>
      <Text variant="bodyBold" mb={3}>
        {title}
      </Text>
      <InputWithIcon
        placeholder="Indiquez le montant"
        mt={2}
        name={fieldName}
        value={currentValue}
        onChange={(event) => {
          setCurrentValue(parseFloat(event.target.value));
        }}
        type="number"
        Icon={EuroIcon}
      />
      <Box textAlign="center" mt={1}>
        <Button variant="fullTransparent" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onConfirm(currentValue);
          }}
          padding="8px 48px"
        >
          {confirmLabel}
        </Button>
      </Box>
    </ModalContainer>
  );
};

export default InputModal;
