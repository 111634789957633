import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  FlexboxProps,
  LayoutProps,
  MarginProps,
  PaddingProps,
  WidthProps,
  flexbox,
  layout,
  margin,
  padding,
  variant,
  width,
} from 'styled-system';
import theme from 'theme';

const FlatCardVariants = {
  primary: {
    backgroundColor: 'white',
  },
  ternary: {
    backgroundColor: transparentize(0.5, theme.colors.white),
  },
  secondary: {
    backgroundColor: 'grey300',
  },
  success: {
    backgroundColor: 'green',
  },
};

export type FlatCardProps = {
  className?: string;
  variant?: keyof typeof FlatCardVariants;
} & PaddingProps &
  MarginProps &
  WidthProps &
  LayoutProps &
  FlexboxProps;

const FlatCard = styled('div')<FlatCardProps>(
  padding,
  margin,
  width,
  layout,
  flexbox,
  () => ({
    boxSizing: 'border-box',
    borderRadius: 10,
  }),
  variant({
    variants: FlatCardVariants,
  })
);

FlatCard.defaultProps = {
  variant: 'primary',
  p: 1,
};
export default FlatCard;
