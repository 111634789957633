import React, { useEffect, useState } from 'react';

import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import dataProvider from 'dataProvider';
import styled from 'styled-components';

import FloatingActionButton from 'pages/Store/components/FloatingActionButton';
import SparePartDetailField from 'pages/Store/components/SparePartDetailField';

import OpenScanButton from 'components/OpenScanButton';
import SkuLocationSelectionPortal from 'components/SkuLocationSelectionPortal';
import SparePartSelectionPortal from 'components/SparePartSelectionPortal';
import Box from 'components/atoms/Box';

import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

import { isItemInList } from 'utils/list';

const TransferContainer = styled.div({
  height: 'calc(100vh - 108px)',
  paddingTop: 24,
  boxSizing: 'border-box',
});
type LocationFilter = string[] | string;

type StoreTransferType = {
  sparePartFilter?: Record<string, LocationFilter>;
  locationFilter?: Record<string, LocationFilter>;
};

const StoreTransfer: React.FunctionComponent<StoreTransferType> = ({
  sparePartFilter,
  locationFilter,
}: StoreTransferType) => {
  const [skuList, setSkuList] = useState<SparePart[]>([]);
  const [location, setLocation] = useState<SkuLocation>();
  const [isSkuLocationSelectionOpen, setIsSkuLocationSelectionOpen] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [sku, setSku] = useState<SparePart>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    if (location) {
      dataProvider
        .updateMany('sku', {
          ids: skuList.map((sku) => sku.id),
          data: skuList.map((sku) =>
            getStatus(sku.status)
              ? { location_id: location.id, status: getStatus(sku.status) }
              : { location_id: location.id }
          ),
        })
        .then(() => {
          setSkuList([]);
          setLocation(undefined);
        })
        .catch((e) => {
          setErrorMessage(e?.message);
          console.error(e);
        });
    }
  }, [location, skuList]);

  const getStatus = (status: string) => {
    if (['ordered', 'instock', 'reserved', 'waiting_for_packing'].indexOf(status) >= 0) {
      return null;
    } else {
      return 'instock';
    }
  };

  const addSkuInList = (sku: SparePart) => {
    if (!isItemInList(sku, skuList)) {
      setSkuList([...skuList, sku]);
    }
  };

  const handleOpenLocationPortal = () => {
    setIsSkuLocationSelectionOpen(true);
  };
  const handleCloseLocationPortal = () => {
    setIsSkuLocationSelectionOpen(false);
  };

  return (
    <TransferContainer>
      {skuList.length === 0 ? (
        <OpenScanButton text={'Scanner une pièce'} handleOpen={() => setOpen(true)} />
      ) : (
        <FloatingActionButton
          onClick={() => setOpen(true)}
          style={{ right: 16 }}
          color="secondary"
          icon={<CenterFocusStrongIcon />}
        />
      )}
      <SparePartSelectionPortal
        onConfirm={(sku: SparePart) => addSkuInList(sku)}
        onClose={() => setOpen(false)}
        open={open}
        locationToDisplay={'turning'}
        sku={sku}
        setSku={setSku}
        filter={sparePartFilter}
      />
      {skuList.length > 0 && (
        <SkuLocationSelectionPortal
          isOpen={isSkuLocationSelectionOpen}
          handleClosePortal={handleCloseLocationPortal}
          handleOpenPortal={handleOpenLocationPortal}
          isDrawer
          onConfirm={(location: SkuLocation) => setLocation(location)}
          filter={locationFilter}
          errorMessage={errorMessage}
        />
      )}
      <Box overflowY="auto" pb={7}>
        {skuList.length > 0 &&
          skuList.map((sku) => <SparePartDetailField key={sku.id} sparePart={sku} checked />)}
      </Box>
    </TransferContainer>
  );
};

export default StoreTransfer;
