// roles
export const REPAIRMAN = 'repairman';
export const ADMIN = 'admin';
export const SUPPORT = 'support';
export const PLANNER = 'planner';
export const OPERATOR = 'operator';
export const STOREKEEPER = 'storekeeper';
export const ALUMNI = 'alumni';

export const EMPLOYEE_ROLES = [
  { id: REPAIRMAN, name: 'Technicien' },
  { id: ADMIN, name: 'Admin' },
  { id: SUPPORT, name: 'Support' },
  { id: PLANNER, name: 'Planneur' },
  { id: OPERATOR, name: 'Opérateur' },
  { id: STOREKEEPER, name: 'Magasinier' },
  { id: ALUMNI, name: 'Alumni' },
];

// tasks
export const PERFORMANCE = 'performance';
export const REPAIR = 'repair';
export const TRANSFER = 'transfer';
export const CLEANING = 'cleaning';
export const STORE = 'store';
