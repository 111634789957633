import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core';

import { RouteComponentProps, useHistory } from 'react-router-dom';

import Loader from '../../components/Loader';
import Button from '../../components/atoms/Button';
import PageLayout from '../../components/templates/PageLayout';
import { useProduct } from '../../utils/api/api';
import { applyBonusToQuote, removeBonusFromQuote } from '../../utils/api/repair-bonus';
import { useLastBill } from '../Payment/utils/api';
import HeaderWithProductTitle from '../RepairReport/components/HeaderWithProductTitle';

const BackgroundBox = styled(Box)(() => ({
  backgroundColor: '#DDEEEF',
  color: '#1A8E96',
  border: `solid 1px`,
  borderRadius: '12px',
  padding: 8,
  fontSize: 18,
  maxHeight: '50vh',
  overflow: 'scroll',
  '@media (min-height: 650px)': {
    maxHeight: '60vh',
  },
}));

const StyledUl = styled('ul')(() => ({
  padding: 'inherit',
  marginBottom: 0,
  marginLeft: 12,
}));

const FullWidthButton = styled(Button)(() => ({
  width: '80%',
  display: 'block',
  alignSelf: 'center',
  margin: 8,
}));

type ApplyBonusScreenMatchParams = {
  customerFileId: string;
  visitId: string;
  productId: string;
};
type ApplyBonusScreenProps = { onApplyBonus: (productId: number) => void };
type ApplyBonusScreenRouteProps = RouteComponentProps<ApplyBonusScreenMatchParams> &
  ApplyBonusScreenProps;

const ApplyBonus: React.FunctionComponent<ApplyBonusScreenRouteProps> = ({
  onApplyBonus,
  match: {
    params: { customerFileId, visitId, productId },
  },
}: ApplyBonusScreenRouteProps) => {
  const history = useHistory();
  const { product, loadingProduct } = useProduct(parseInt(productId));
  const { bill } = useLastBill(customerFileId);
  const [bonusError, setBonusError] = useState<string>();

  const continueToDashboard = () => {
    onApplyBonus(parseInt(productId));
    history.push(`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`);
  };

  const continueAndApplyBonus = async () => {
    try {
      const apiResponse = await applyBonusToQuote(bill, product);
      apiResponse.status === 200
        ? continueToDashboard()
        : setBonusError("Erreur à l'application du bonus");
    } catch (error) {
      setBonusError('Erreur réseau ou bonus déjà attribué');
    }
  };

  const continueWithoutBonus = async () => {
    try {
      await removeBonusFromQuote(bill, product);
      continueToDashboard();
    } catch (error) {
      if (error.status === 404) {
        continueToDashboard();
      } else {
        setBonusError('Erreur imprévue');
      }
    }
  };

  return loadingProduct ? (
    <Loader />
  ) : (
    <PageLayout>
      <HeaderWithProductTitle product={product} />
      <Box mb={2} textAlign="left" mx={2} mt={2}>
        <Typography variant="h6" role="h2">
          Vérifier l&apos;application du bonus
        </Typography>
        <BackgroundBox mt={2}>
          L&apos;appareil est fonctionnel, mais un certain nombre d&apos;interventions{' '}
          <b>ne sont pas éligibles au Bonus Réparation</b> selon les conditions du label. <br />
          Cela inclut:
          <StyledUl>
            <li>L&apos;appareil fonctionnait déjà</li>
            <li>
              C&apos;était un geste d&apos;entretien (dégivrage, ouverture de robinet, nettoyage de
              filtre, ...)
            </li>
            <li>L&apos;appareil fonctionnait mais était mal réglé ou mal utilisé</li>
            <li>
              Un élément de l&apos;appareil était cassé à cause d&apos;un incident (chute sur
              l&apos;appareil, choc d&apos;un plat sur la vitre, ...)
            </li>
            <li>C&apos;est une intervention auprès d&apos;un professionnel</li>
            <li>Le client a apporté sa pièce lui-même</li>
          </StyledUl>
        </BackgroundBox>
      </Box>
      <Box position="absolute" mb={2} width="100%" display="flex" flexDirection="column" bottom={0}>
        {bonusError && (
          <Box mt={2} textAlign="center" color="red">
            {bonusError}
          </Box>
        )}
        <FullWidthButton variant="transparent" onClick={continueWithoutBonus}>
          Ne pas attribuer le bonus
        </FullWidthButton>
        <FullWidthButton onClick={continueAndApplyBonus}>Attribuer le bonus</FullWidthButton>
      </Box>
    </PageLayout>
  );
};

export default ApplyBonus;
