//React imports
import React from 'react';

// MUI imports
import { CircularProgress, Typography } from '@material-ui/core';
//Styles imports
import { makeStyles } from '@material-ui/core/styles';

import { useParams } from 'react-router-dom';

import RepairReportEndScreen from 'pages/RepairReport/components/formComponents/RepairReportEndScreen';
import TransferNewLocation from 'pages/Transfer/components/TransferNewLocation';
import TransferProductAddition from 'pages/Transfer/components/TransferProductAddition';
import TransferStyle from 'pages/Transfer/styles/TransferStepStyle';

const useStyles = makeStyles(TransferStyle);

export default function TransferStep({ setNextStep }) {
  const classes = useStyles();
  let { stepSlug } = useParams();
  let slugComponent = '';

  switch (stepSlug) {
    case 'product_selection':
      slugComponent = (
        <div className={classes.elementContainer}>
          <TransferProductAddition />
          <TransferNewLocation setNextStep={setNextStep} />
        </div>
      );
      break;
    case 'transfer-successful':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportEndScreen task="transfer" setNextStep={setNextStep} />
        </div>
      );
      break;
    default:
      slugComponent = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      );
      break;
  }
  return (
    <div className={classes.stepContainer}>
      {stepSlug !== 'transfer-successful' && (
        <Typography variant="h1" component="div">
          Transfert
        </Typography>
      )}
      <div>{slugComponent}</div>
    </div>
  );
}
