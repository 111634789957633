import React from 'react';

import PrequalificationList from 'pages/Inbox/components/PrequalificationList';

import BottomBar from 'components/BottomBar/BottomBar';
import PageHeader from 'components/PageHeader';
import Box from 'components/atoms/Box';
import HeaderWithText from 'components/atoms/HeaderWithText';
import Text from 'components/atoms/Text';
import Tab from 'components/molecules/Tab';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

const Inbox: React.FunctionComponent = () => {
  return (
    <PageLayout>
      <HeaderWithText pb={0} marginBottom={0}>
        <Box display="flex">
          <PageHeader noPadding title="" />
          <Text mt="2px" variant="subtitle">
            Boîte de Réception
          </Text>
        </Box>
      </HeaderWithText>
      <Box paddingX={2} pb={0} pt={1} backgroundColor="white" display="flex">
        <Tab title="Préqual." active={true} cardinality={0} handleClick={() => {}} />
      </Box>
      <ContentContainer paddingX={0} paddingY={0}>
        <PrequalificationList />
      </ContentContainer>
      <BottomBar />
    </PageLayout>
  );
};

export default Inbox;
