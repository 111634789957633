import dataProvider from 'dataProvider';

import { Statistics } from 'types/employee-statistics';
import { IncentiveStatistics } from 'types/incentive-statistics';

import { get } from 'utils/api/http';

interface HomeRepairPerformance {
  nb_days_category_home_repair: number;
  nb_interventions: number;
  points_updates: number;
}
export const getDataWarehouseEmployeeStatistics = async (
  date: string,
  repairmanId: number
): Promise<Statistics> => {
  const etl_statistics_array = await get<HomeRepairPerformance[]>(
    `/operations-data-provider/reverse-etl-proxy/v_homerepair_performance_month?employee_id=eq.${repairmanId}&year_month=eq.${date}`
  );
  if (etl_statistics_array.length > 0) {
    const etl_statistics = etl_statistics_array[0];
    return {
      number_of_visit_per_day:
        etl_statistics.nb_days_category_home_repair &&
        etl_statistics.nb_interventions / etl_statistics.nb_days_category_home_repair,
      number_of_points_per_day:
        etl_statistics.nb_days_category_home_repair &&
        etl_statistics.points_updates / etl_statistics.nb_days_category_home_repair,
      number_of_points_per_visit:
        etl_statistics.nb_interventions &&
        etl_statistics.points_updates / etl_statistics.nb_interventions,
    };
  } else {
    return {
      number_of_visit_per_day: 0,
      number_of_points_per_day: 0,
      number_of_points_per_visit: 0,
    };
  }
};

export const getIncentiveStatistics = async (
  startDate: string,
  endDate: string,
  repairmanId: number
): Promise<IncentiveStatistics> => {
  const { data } = await dataProvider.getList('incentive-statistics', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'DESC' },
    filter: {
      start_date: startDate,
      end_date: endDate,
      repairman_id: repairmanId,
    },
  });
  return data;
};
