import React from 'react';

import BuildIcon from '@material-ui/icons/Build';
import CachedIcon from '@material-ui/icons/Cached';
import ExtensionIcon from '@material-ui/icons/Extension';
import StoreIcon from '@material-ui/icons/Store';

import { useEmployee } from 'employeeContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ADMIN, OPERATOR, REPAIRMAN, STOREKEEPER } from 'pages/Home/constants';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { Employee } from 'types/employees';

type NavigationLinksProps = {
  employee: Employee;
};

const StyledStoreIcon = styled(StoreIcon)((props) => ({
  color: props.theme.colors.white,
  height: 20,
  width: 20,
}));

const StyledBuildIcon = styled(BuildIcon)((props) => ({
  color: props.theme.colors.white,
  height: 20,
  width: 20,
}));

const StyledExtensionIcon = styled(ExtensionIcon)((props) => ({
  color: props.theme.colors.white,
  height: 20,
  width: 20,
}));

const StyledCacheIcon = styled(CachedIcon)((props) => ({
  color: props.theme.colors.white,
  height: 20,
  width: 20,
}));

const FRANCHISEES = 'Franchisés';

const NavigationLinks: React.FunctionComponent<NavigationLinksProps> = ({
  employee,
}: NavigationLinksProps) => {
  const history = useHistory();
  const { permissions } = useEmployee();
  const isFranchisee = () => {
    return permissions?.includes(FRANCHISEES);
  };

  return (
    <Box backgroundColor="#073331">
      <Box py={2} borderBottom="1px solid #CBF5AB26">
        {((employee.role && [ADMIN, OPERATOR, REPAIRMAN].includes(employee.role)) ||
          isFranchisee()) && (
          <>
            <Box
              onClick={() => history.push(`/incentives/${employee.id}`)}
              py={1}
              px={2}
              display="flex"
            >
              <StyledBuildIcon />
              <Text ml={1} variant="body" color="#FFFFFF">
                Réparation à domicile
              </Text>
            </Box>

            <Box onClick={() => history.push(`/recondition/`)} py={1} px={2} display="flex">
              <StyledCacheIcon />
              <Text ml={1} variant="body" color="#FFFFFF">
                Reconditionné
              </Text>
            </Box>
          </>
        )}
        {((employee.role && [ADMIN, OPERATOR, STOREKEEPER].includes(employee.role)) ||
          isFranchisee()) && (
          <Box onClick={() => history.push(`/workshop/repair-cycle/`)} py={1} px={2} display="flex">
            <StyledStoreIcon />
            <Text ml={1} variant="body" color="#FFFFFF">
              Logistique
            </Text>
          </Box>
        )}
        {employee.role &&
          [ADMIN, OPERATOR, STOREKEEPER].includes(employee.role) &&
          !isFranchisee() && (
            <Box onClick={() => history.push(`/store`)} py={1} px={2} display="flex">
              <StyledExtensionIcon />
              <Text ml={1} variant="body" color="#FFFFFF">
                Magasin
              </Text>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default NavigationLinks;
