import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import BulletList from 'components/atoms/BulletList';
import Text from 'components/atoms/Text';
import BulletPoint from 'components/molecules/BulletPoint';

import { CustomerFile } from 'types/customer-files';
import { SparePart } from 'types/spare-part';
import { WorkshopFile } from 'types/workshop-files';

import { getCustomerFile, getWorkshopFile } from 'utils/api/api';

type SparePartDetailsProps = {
  open: boolean;
  selectedSparePart: SparePart;
  onClose: () => void;
};

const SparePartDetails: React.FunctionComponent<SparePartDetailsProps> = ({
  open,
  selectedSparePart,
  onClose,
}: SparePartDetailsProps) => {
  const [customerFileDetails, setCustomerFileDetails] = useState<CustomerFile>();
  const [workshopFileDetails, setWorkshopFileDetails] = useState<WorkshopFile>();
  const [isReappro, setIsRappro] = useState<boolean>(false);

  useEffect(() => {
    const fetchCustomerFile = async () => {
      const customerFile = await getCustomerFile({
        id: selectedSparePart?.demand?.customer_file_number,
      });
      setCustomerFileDetails(customerFile);
      setWorkshopFileDetails(undefined);
      setIsRappro(false);
    };
    const fetchWorkshopFile = async () => {
      const WorkshopFile = await getWorkshopFile({
        id: selectedSparePart?.demand?.customer_file_number,
      });
      setWorkshopFileDetails(WorkshopFile);
      setCustomerFileDetails(undefined);
      setIsRappro(false);
    };

    if (selectedSparePart && selectedSparePart.demand) {
      if (selectedSparePart?.demand?.workshop_name) {
        fetchWorkshopFile();
      } else if (selectedSparePart?.demand.customer_file_number !== '') {
        fetchCustomerFile();
      } else {
        setIsRappro(true);
      }
    }
  }, [selectedSparePart]);

  const parseInstallationDate = (date: SparePart['installation_date']) => {
    dayjs.extend(isTomorrow);
    dayjs.extend(isYesterday);
    dayjs.extend(isToday);
    if (!date) {
      return null;
    }
    if (dayjs(date).isToday()) {
      return "À poser aujourd'hui à " + dayjs(date).format('HH:mm');
    } else if (dayjs(date).isTomorrow()) {
      return 'À poser demain à ' + dayjs(date).format('HH:mm');
    } else if (dayjs(date).isAfter(dayjs())) {
      return 'À poser le ' + dayjs(date).format('DD/MM/YY à HH:mm');
    } else {
      return null;
    }
  };

  const installationDate = parseInstallationDate(selectedSparePart?.installation_date);
  return (
    <PortalComponent
      open={open}
      onClose={onClose}
      title={selectedSparePart.sku_model.sku_reference}
    >
      <Box borderRadius={1} mx={1} mt={1} backgroundColor="#FFFFFF" textAlign="initial">
        <Box p={1}>
          <Text variant="bodyBold">
            {workshopFileDetails && 'Dossier RECO'}
            {customerFileDetails && 'Dossier CLIENT'}
            {isReappro && 'Réappro'}
          </Text>
          <Box p={1}>
            {!workshopFileDetails && !customerFileDetails && !isReappro && (
              <Text margin="auto" variant="body">
                Pas de demande associée à cette pièce
              </Text>
            )}
            {isReappro && <Text variant="small">{selectedSparePart.demand?.customer_name}</Text>}
            {workshopFileDetails && (
              <>
                <Text variant="small">
                  {workshopFileDetails.product.product_type} {workshopFileDetails.product.brand}{' '}
                  {workshopFileDetails.product.barcode}
                </Text>
                <Text variant="small">
                  Emplacement: {workshopFileDetails.product.location_name}
                </Text>
              </>
            )}
            {customerFileDetails && (
              <>
                <Text variant="small">
                  {customerFileDetails.firstname} {customerFileDetails.lastname}
                </Text>
                <Text variant="small">{customerFileDetails.address}</Text>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {installationDate && (
        <BulletList pl={3} mt={1}>
          <BulletPoint>
            <Text variant="smallBold" color="honey">
              {installationDate}
            </Text>
          </BulletPoint>
        </BulletList>
      )}
    </PortalComponent>
  );
};

export default SparePartDetails;
