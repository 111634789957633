//React imports
import React from 'react';

// MUI imports
import RepairReportButton from 'pages/RepairReport/components/formComponents/RepairReportButton';

export default function ApplianceRepairedStep({
  slug,
  validateFunction,
  customerFile,
  formProductIdReference,
}) {
  return (
    <>
      <RepairReportButton
        stepQuestionId={slug}
        buttonLabel={`Oui`}
        buttonValue={'finished'}
        validateFunction={validateFunction}
        formProductIdReference={formProductIdReference}
      />
      <RepairReportButton
        stepQuestionId={slug}
        buttonLabel={`Non`}
        buttonValue={false}
        validateFunction={validateFunction}
        formProductIdReference={formProductIdReference}
      />
      <RepairReportButton
        stepQuestionId={slug}
        buttonLabel={`Non réparable`}
        buttonValue={'not-repaired'}
        validateFunction={validateFunction}
        formProductIdReference={formProductIdReference}
      />
      {customerFile && customerFile.category && customerFile.category === 'workshop_repair' && (
        <RepairReportButton
          stepQuestionId={slug}
          buttonLabel={`Non traitée`}
          buttonValue="not-processed"
          validateFunction={validateFunction}
          formProductIdReference={formProductIdReference}
        />
      )}
      {customerFile && customerFile.category && customerFile.category !== 'workshop_repair' && (
        <RepairReportButton
          stepQuestionId={slug}
          buttonLabel={`Impossible d'intervenir`}
          buttonValue="intervention-impossible"
          validateFunction={validateFunction}
          formProductIdReference={formProductIdReference}
        />
      )}
    </>
  );
}
