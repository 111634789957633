//React imports
import React from 'react';

import { Field } from 'react-final-form';

/**
 * Determines if a condition is respected by looking for a value inside the form
 * Returns a component or null
 * @param {String} when value identifier
 * @param {Object} is value
 * @param {Object} children component to be displayed
 */

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export default Condition;
