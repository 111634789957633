import React from 'react';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';

const ImageUpload: React.FC<{
  images?: File[];
  onChange: (files: File[]) => void;
}> = ({ images = [], onChange }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      onChange([...(images || []), ...newFiles]);
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    document.getElementById('image-upload')?.click();
  };

  const handleDeleteImage = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    const newImages = (images || []).filter((_, i) => i !== index);
    onChange(newImages);
  };

  return (
    <Box mt={4}>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="image-upload"
      />
      <Button variant="fullTransparent" onClick={handleButtonClick}>
        Ajouter des photos
      </Button>
      <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
        {(images || []).map((file, index) => (
          <Box key={index} width={100} height={100} position="relative">
            <img
              src={URL.createObjectURL(file)}
              alt={`Preview ${index + 1}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '4px',
              }}
              onLoad={(e) => URL.revokeObjectURL((e.target as HTMLImageElement).src)}
            />
            <Button
              variant="fullTransparent"
              onClick={(e) => handleDeleteImage(e, index)}
              style={{
                position: 'absolute',
                top: -8,
                right: -8,
                width: '24px',
                height: '24px',
                minWidth: 'unset',
                padding: 0,
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="white"
                stroke="red"
                strokeWidth="2"
              >
                <circle cx="12" cy="12" r="11" />
                <path d="M15 9l-6 6M9 9l6 6" />
              </svg>
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageUpload;
