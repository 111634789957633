import React from 'react';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

interface Tab<T> {
  name: string;
  value: T;
}

export interface ChoiceTabsProps<T> {
  tabs: Tab<T>[];
  action: (value: T) => void;
  selectedValue?: Tab<T>['value'] | null;
}

const StyledBox = styled(Box)((props) => ({
  border: '1px solid #DBDFE8',
  boxSizing: 'border-box',
  borderRadius: 12,
  height: props.theme.space[5],
  alignSelf: 'center',
  justifyContent: 'space-around',
}));

function ChoiceTabs<T extends string | boolean | number>({
  tabs,
  action,
  selectedValue,
}: ChoiceTabsProps<T>): React.ReactElement {
  return (
    <StyledBox display="flex" margin="auto" justifyContent="center" width="90%">
      {tabs.map((tab) => (
        <Box
          key={tab.name}
          alignItems="center"
          backgroundColor={tab.value === selectedValue ? 'turquoise15' : 'transparent'}
          width="inherit"
          m="2px"
          borderRadius={12}
        >
          <Button
            type="button"
            padding="0px"
            height="32px"
            width="100%"
            variant="fullTransparent"
            onClick={() => action(tab.value)}
          >
            <Text color={tab.value === selectedValue ? 'turquoise' : 'grey900'} variant="smallBold">
              {tab.name}
            </Text>
          </Button>
        </Box>
      ))}
    </StyledBox>
  );
}

export default ChoiceTabs;
