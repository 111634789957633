import React from 'react';

import ItemListSelectionPortal from 'components/ItemListSelectionPortal';

import { SparePart } from 'types/spare-part';

export type SkuListSelectionPortalProps = {
  skuList: SparePart[];
  selectedList: SparePart[];
  surplusSkuList?: SparePart[];
  title?: string;
  buttonText?: string;
  onClose: () => void;
  openSkuSelection: () => void;
  onClickOnButton: () => void;
  open: boolean;
  isSaving: boolean;
  redButton?: boolean;
};

const SkuListSelectionPortal: React.FunctionComponent<SkuListSelectionPortalProps> = ({
  skuList,
  selectedList,
  surplusSkuList,
  title,
  buttonText,
  onClose,
  openSkuSelection,
  onClickOnButton,
  isSaving,
  open,
  redButton,
}: SkuListSelectionPortalProps) => {
  return (
    <ItemListSelectionPortal
      itemList={skuList}
      selectedList={selectedList}
      surplusItemList={surplusSkuList}
      title={title}
      buttonText={buttonText}
      onClose={onClose}
      openItemSelection={openSkuSelection}
      onClickOnButton={onClickOnButton}
      isSaving={isSaving}
      open={open}
      redButton={redButton}
      field="sku"
      openScanText="Scanner une première pièce"
    />
  );
};

export default SkuListSelectionPortal;
