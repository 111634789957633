import React from 'react';

import { BackEvent, NotRepairableReasonEvent } from '../../types';
import { BACK, NOT_REPAIRABLE_REASON } from '../../types';
import NotRepairedReason from './NotRepairableReason';

interface NotRepairableReasonContainerProps {
  send: (event: NotRepairableReasonEvent | BackEvent) => void;
  selectedValue: string | null;
}

const NotRepairableReasonContainer: React.FunctionComponent<NotRepairableReasonContainerProps> = ({
  send,
  selectedValue,
}: NotRepairableReasonContainerProps) => {
  return (
    <NotRepairedReason
      back={() => {
        send({
          type: BACK,
          data: {},
        });
      }}
      submit={(irreparable_reason: string) => {
        send({
          type: NOT_REPAIRABLE_REASON,
          data: {
            irreparable_reason,
          },
        });
      }}
      selectedValue={selectedValue}
    />
  );
};

export default NotRepairableReasonContainer;
