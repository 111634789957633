import React from 'react';

import { Filter, SearchInput } from 'react-admin';

import { ThemeProvider } from '@material-ui/core/styles';

import customerThemeFilter from './customThemeFilter.js';

class StorePackingFilter extends React.Component {
  componentWillUnmount() {
    this.setFilterProps();
  }

  setFilterProps() {
    if (this.props.setFilters) {
      this.props.setFilters({});
    }
  }

  render() {
    return (
      <ThemeProvider theme={customerThemeFilter}>
        <Filter {...this.props}>
          <SearchInput
            source="q"
            alwaysOn
            placeholder="Rechercher"
            onChange={(event) => this.props.onType(event)}
          />
        </Filter>
      </ThemeProvider>
    );
  }
}

export default StorePackingFilter;
