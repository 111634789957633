import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import dayjs from 'dayjs';
import { useEmployee } from 'employeeContext';

import UpdateWorkshopInterface from 'pages/Recondition/ReconditionStockList/components/UpdateWorkshopInterface';

import PageHeader from 'components/PageHeader';
import WorkshopBottomBar from 'components/WorkshopBottomBar/WorkshopBottomBar';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Tab from 'components/molecules/Tab';
import PageLayout from 'components/templates/PageLayout';

import { ShippingDelivery } from 'types/shipping-deliveries';

import { useEmployeeAssociatedLocations } from 'utils/api/api';

import ShippingProductPreparation from './ShippingProductPreparation/ShippingProductPreparation';
import ShippingProductTransfers from './ShippingProductTransfers/ShippingProductTransfers';
import { getDeliveries } from './api';
import {
  CANCELLED_ANOMALY,
  CANCELLED_FREE_WITHDRAWAL,
  CANCELLED_PAYING_WITHDRAWAL,
  ORDER,
  PRE_SHIPPING_ISSUE,
} from './utils';

const WorkshopShipping: React.FunctionComponent = () => {
  const { locations } = useEmployeeAssociatedLocations();
  const { employee } = useEmployee();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isProductSelectionOpen, setIsProductSelectionOpen] = useState<boolean>(false);
  const [isLocationSelectionOpen, setIsLocationSelectionOpen] = useState<boolean>(false);
  const [isChecklistOpen, setIsChecklistOpen] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [deliveries, setDeliveries] = useState<ShippingDelivery[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const deliveries = await getDeliveries({
        preparation_date: dayjs().format('YYYY-MM-DD'),
        file_type: ORDER,
        order_state_exclude: [
          CANCELLED_ANOMALY,
          CANCELLED_PAYING_WITHDRAWAL,
          CANCELLED_FREE_WITHDRAWAL,
          PRE_SHIPPING_ISSUE,
        ],
        starting_point: employee.workshop,
      });
      setDeliveries(deliveries);
      setIsLoading(false);
    };

    if (!isLocationSelectionOpen && !isChecklistOpen) {
      fetchData();
    }
  }, [isLocationSelectionOpen, isChecklistOpen, employee.workshop]);

  if (!employee.workshop) {
    return (
      <PageLayout>
        <PageHeader title="Expéditions" />
        <UpdateWorkshopInterface />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageHeader title="Expéditions" />

      <Box backgroundColor="white" display="flex" justifyContent="space-between">
        <Box width="100%" textAlign="center">
          <Tab
            title="TRANSFÉRER"
            active={activeTabIndex === 0}
            cardinality={0}
            handleClick={() => setActiveTabIndex(0)}
            width="100%"
            variant={'smallBold'}
          />
        </Box>
        <Box width="100%" textAlign="center">
          <Tab
            title="PRÉPARER"
            active={activeTabIndex === 1}
            cardinality={0}
            handleClick={() => setActiveTabIndex(1)}
            width="100%"
            variant={'smallBold'}
          />
        </Box>
      </Box>

      {isLoading && (
        <Box mt={4} mx="auto">
          <Text variant="subtitle">Mise à jour des expéditions</Text>
          <Box mx="auto" width="fit-content" py={2}>
            <CircularProgress />
          </Box>
        </Box>
      )}

      {!isLoading && activeTabIndex === 0 && (
        <ShippingProductTransfers
          isProductSelectionOpen={isProductSelectionOpen}
          setIsProductSelectionOpen={setIsProductSelectionOpen}
          isLocationSelectionOpen={isLocationSelectionOpen}
          setIsLocationSelectionOpen={setIsLocationSelectionOpen}
          deliveries={deliveries}
          locations={locations}
        />
      )}

      {!isLoading && activeTabIndex === 1 && (
        <ShippingProductPreparation
          isChecklistOpen={isChecklistOpen}
          setIsChecklistOpen={setIsChecklistOpen}
          deliveries={deliveries}
          locations={locations}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
        />
      )}

      <WorkshopBottomBar />
    </PageLayout>
  );
};

export default WorkshopShipping;
