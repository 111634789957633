import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const InputBase = styled.input((props) => ({
  width: '100%',
  height: themeGet('sizes.7')(props),
  border: `1px solid ${props.theme.colors.grey500}`,
  borderRadius: 5,
  textIndent: 20,
  fontSize: props.theme.fontSizes[1],
  fontWeight: props.theme.fontWeights.medium,
  cursor: 'pointer',
}));

export default InputBase;
