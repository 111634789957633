import React, { useContext } from 'react';

import { useMachine } from '@xstate/react';
import { EventObject, State, StateMachine } from 'xstate';

interface StateMachineProviderProps<TContext, TEvent extends EventObject> {
  machine: StateMachine<TContext, any, TEvent>;
  children: React.ReactNode;
}

interface StateMachineContextValue {
  state: State<any>;
  send: (eventType: string) => void;
}

const StateMachineContext = React.createContext<StateMachineContextValue>(
  {} as StateMachineContextValue
);

function StateMachineProvider<TContext, TEvent extends EventObject>({
  machine,
  children,
}: StateMachineProviderProps<TContext, TEvent>): React.ReactElement {
  const [state, send] = useMachine<TContext, TEvent>(machine);
  return (
    <StateMachineContext.Provider value={{ state, send }}>{children}</StateMachineContext.Provider>
  );
}

export const useStateMachine = (): StateMachineContextValue => useContext(StateMachineContext);
export default StateMachineProvider;
