import React from 'react';

import PaperStripUnfoldsWithArrow from 'components/PaperStripUnfoldsWithArrow';
import Box from 'components/atoms/Box';
import SecondHandIcon from 'components/atoms/SecondHandIcon';
import Text from 'components/atoms/Text';

import { DeprecatedSparePart } from 'types/deprecated-spare-part';

import { formatPrice } from 'utils/formatting';

type PartsToInstallPanelProps = {
  spareParts: DeprecatedSparePart[];
};
const PartsToInstallPanel: React.FunctionComponent<PartsToInstallPanelProps> = ({
  spareParts,
}: PartsToInstallPanelProps) => {
  const isSecondHand = (type: string | null): boolean => type === 'second_hand';
  const renderSparePart = (sparePart: DeprecatedSparePart): React.ReactNode => (
    <Text variant="small" display="flex" data-testid="spare-part">
      {sparePart.piece_name}
      {isSecondHand(sparePart.type) && (
        <Box ml={1} mr={1}>
          <SecondHandIcon />
        </Box>
      )}
      - {sparePart.aswo_ref} - {formatPrice(sparePart.amount_withtax)}
    </Text>
  );
  return (
    <PaperStripUnfoldsWithArrow
      title="Pièce(s) à poser"
      content={spareParts.map((sparePart) => {
        return renderSparePart(sparePart);
      })}
      contentStyle="small"
    />
  );
};

export default PartsToInstallPanel;
