import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import dataProvider from 'dataProvider';

import ClaimButton from 'pages/IncentiveSystem/Claims/ClaimButton';
import ClaimTextArea from 'pages/IncentiveSystem/Claims/ClaimTextArea';
import { listRepairReportLabel } from 'pages/IncentiveSystem/Services/LabelList';

const useStyles = makeStyles(() => ({
  header: {
    borderRadius: 0,
    paddingLeft: '20px',
  },
  headWrapper: {
    backgroundColor: '#fff',
    '& header': {
      position: 'unset',
    },
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
  },
  back: {
    color: '#2B3B5C',
  },
  paper: {
    height: '-webkit-fill-available',
    width: '100%',
    left: '0',
    bottom: '0',
    overflow: 'auto',
    background: '#F1F0F5',
    '&:focus': {
      outline: 'none',
    },
  },
  wrapper: {
    margin: '22px 0 0',
  },
  classButton: {
    background: '#4A55FE',
    color: '#FFFFFF',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#4A55FE',
      },
    },
    position: 'relative',
  },
  textAreaContainer: {
    display: 'flex',
  },
  selectLabel: {
    padding: 12,
    backgroundColor: '#FFFFFF',
    fontSize: 15,
    margin: '0px 16px',
    justifyContent: 'flex-start',
    width: '100%',
    color: '#2B3B5C',
    marginBottom: 32,
    border: '1px solid rgba(219, 223, 232, 0.4)',
    boxSizing: 'border-box',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FFFFFF',
      },
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#FFFFFF',
    },
    borderRadius: 12,
    '& .MuiSelect-icon': {
      color: '#8795B3',
    },
  },
  classContainerButton: {
    position: 'absolute',
  },
}));
export default function ClaimResponse(props) {
  const classes = useStyles();
  const [claimResponse, setClaimResponse] = useState('');
  const [initLabel, setLabel] = useState(props.claimDetails.initial_label);

  const handleSubmitClaim = () => {
    if (claimResponse) {
      dataProvider
        .update('claim', {
          id: props.claimDetails.id,
          data: {
            claim_response: { text: claimResponse },
            incentive: props.claimDetails.incentive,
            initial_label: initLabel,
          },
        })
        .then(() => {
          props.handleCloseClaimResponse();
          props.handleCloseModalClaimDetails();
          setClaimResponse('');
          props.setIsSubmit(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const body = (
    <div className={classes.paper}>
      <div className={classes.headWrapper}>
        <AppBar color="white" className={classes.header}>
          <div className={classes.elementHeader}>
            <IconButton
              edge="start"
              onClick={() => props.handleCloseClaimResponse()}
              className={classes.back}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>Traitement de la réclamation</span>
          </div>
        </AppBar>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.textAreaContainer}>
          <Select
            label="Labélisation"
            className={classes.selectLabel}
            defaultValue={props.claimDetails.initial_label}
            value={initLabel}
            onChange={(e) => setLabel(e.target.value)}
          >
            {listRepairReportLabel.map((label, index) => (
              <MenuItem key={index} value={label.id}>
                {label.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <ClaimTextArea
          textAreaLabel="claim_request"
          textPlaceHolder="Explications"
          claimRequest={claimResponse}
          setClaimRequest={setClaimResponse}
        />
      </div>
      <ClaimButton
        handleSubmit={handleSubmitClaim}
        buttonLabel="VALIDER"
        classButton={classes.classButton}
        isDisable={!claimResponse.trim()}
        classContainerButton={classes.classContainerButton}
      />
    </div>
  );
  return (
    <div>
      <Modal open={props.openModalClaimResponse} onClose={props.handleCloseClaimResponse}>
        {body}
      </Modal>
    </div>
  );
}
