import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Button from 'components/atoms/Button';

type DrawerButtonProps = {
  bottom?: boolean;
  top?: boolean;
};

const DrawerButton = styled(Button)<DrawerButtonProps>((props) => ({
  background: themeGet('colors.white')(props),
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 1px 40px rgba(0, 0, 0, 0.12)',
  borderRadius: '12px 12px 0px 0px',
  height: 54,
  width: '100%',
  position: 'absolute',
  bottom: props.bottom ? 0 : '',
  left: 0,
  top: props.bottom ? '' : 0,
  padding: props.theme.space[2],
  display: 'inline-flex',
  justifyContent: 'center',
}));

export default DrawerButton;
