import React from 'react';

import { Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import DeviceCard from 'components/DeviceCard';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import ContentContainer from 'components/templates/ContentContainer';

import { AswoDevice } from 'types/aswo-devices';

type FormValues = {
  device: string;
};

type DeviceSelectionProps = {
  potentialDevices: AswoDevice[];
  handleSubmit: (deviceId: string) => void;
  submitNoSufficientDeviceFound?: () => void;
  selectedDeviceId?: string;
};
const StyledButton = styled(Button)((props) => ({
  color: props.theme.colors.grey700,
  textDecoration: 'underline',
}));

const StyledForm = styled(Form)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
});
const StyledContentContainer = styled(ContentContainer)({
  padding: 0,
  width: '90%',
  paddingBottom: 120,
});
const DeviceSelection: React.FunctionComponent<DeviceSelectionProps> = ({
  potentialDevices,
  handleSubmit,
  submitNoSufficientDeviceFound,
  selectedDeviceId,
}: DeviceSelectionProps) => {
  const initialValues: FormValues = {
    device: selectedDeviceId || '',
  };
  const validationSchema = yup.object().shape({
    device: yup.mixed().required(),
  });
  const handleSubmitNameplateStep = (values: FormValues) => {
    handleSubmit(values.device);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitNameplateStep}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <StyledForm>
          <StyledContentContainer>
            {potentialDevices.map((device) => (
              <DeviceCard
                name="device"
                key={device.aswo_device_id}
                device={device}
                value={device.aswo_device_id}
                mt={1}
              />
            ))}
            {submitNoSufficientDeviceFound && (
              <StyledButton
                variant="fullTransparent"
                type="button"
                onClick={submitNoSufficientDeviceFound}
              >
                {`Le modèle n'est pas dans la liste`}
              </StyledButton>
            )}
          </StyledContentContainer>
          <Box display="grid" width="90%" position="absolute" bottom={2}>
            <Button
              mt={2}
              data-testid="bottom-button"
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
            >
              Confirmer
            </Button>
          </Box>
        </StyledForm>
      )}
    </Formik>
  );
};

export default DeviceSelection;
