import React from 'react';

import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import styled from 'styled-components';

import LoaderPlaceHolder from 'components/LoaderPlaceHolder';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { DefectDetails, SymptomDetails } from 'types/diagnostic-details';
import { DiagnosticHistory } from 'types/product-history';

import { useDiagnosticDetails } from 'utils/api/cobble-flow-read-api-hooks';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type DiagnosticBlockProps = {
  diagnostic: DiagnosticHistory;
};

const GreyBolt = styled(OfflineBoltIcon)((props) => ({
  color: props.theme.colors.grey900,
  height: props.theme.sizes[2],
  width: props.theme.sizes[2],
  paddingRight: props.theme.space[1],
}));

const DiagnosticBlock: React.FunctionComponent<DiagnosticBlockProps> = ({
  diagnostic,
}: DiagnosticBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const { diagnosticDetails, isLoading, isError } = useDiagnosticDetails(diagnostic.data.id);

  const renderDefectivePart = (defect: DefectDetails) => {
    return `${defect.iris_code_irissection.text} ⟶ ${defect.iris_code_irisdefect.text}`;
  };

  const renderSymptom = (symptom: SymptomDetails) => {
    return `${symptom.iris_code_irissymptom.text} ⟶ ${symptom.iris_code_iriscondition.text}`;
  };

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={diagnostic.data.created_at}
          assignee={diagnostic.data.assignee}
          title={`Diagnostic`}
          tagVariant={'info'}
        />
      </Box>
      {isLoading && <LoaderPlaceHolder size="small" />}
      {isOpen && diagnosticDetails && (
        <>
          <SimpleBlockContent noSeparator>
            <Box mt={3}>
              <Text variant="caption" color="grey900" display="flex">
                <GreyBolt />
                SYMPTÔMES
              </Text>
              <Box width="100%" mt={1}>
                {diagnosticDetails.symptoms.map((symptom) => {
                  return (
                    <Text
                      key={`${diagnostic.data.id}${symptom.iris_code_irissymptom.text}`}
                      variant="smallBold"
                      color="dark"
                      mt={2}
                    >
                      {renderSymptom(symptom)}
                    </Text>
                  );
                })}
              </Box>
            </Box>
          </SimpleBlockContent>
          <SimpleBlockContent noSeparator>
            <Box mt={3}>
              <Text variant="caption" color="grey900" display="flex">
                <GreyBolt /> PIÈCE(S) DÉFECTUEUSE(S)
              </Text>
              <Box width="100%" mt={1}>
                {diagnosticDetails.defects.map((defect) => {
                  return (
                    <Text
                      key={`${diagnostic.data.id}${defect.iris_code_irisdefect.text}`}
                      variant="smallBold"
                      color="dark"
                      mt={2}
                    >
                      {renderDefectivePart(defect)}
                    </Text>
                  );
                })}
              </Box>
            </Box>
          </SimpleBlockContent>
          {diagnosticDetails.comment.task.comment && (
            <SimpleBlockContent noSeparator>
              <Box mt={3} mb={3}>
                <Text variant="caption" color="grey900" display="flex">
                  <GreyBolt /> COMMENTAIRE
                </Text>
                <Box width="100%" mt={1}>
                  <Text
                    key={`${diagnosticDetails.diagnostic_id}`}
                    variant="small"
                    color="dark"
                    mt={2}
                  >
                    {diagnosticDetails.comment.task.comment}
                  </Text>
                </Box>
              </Box>
            </SimpleBlockContent>
          )}
        </>
      )}
      {isError && (
        <Text variant="small" m={2} color="red">
          Echec de récupération des informations du diagnostic.
        </Text>
      )}
    </Box>
  );
};
export default DiagnosticBlock;
