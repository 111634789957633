import React from 'react';

import Box from 'components/atoms/Box';
import ItemDetailField from 'components/atoms/ItemDetailField';
import SecondHandIcon from 'components/atoms/SecondHandIcon';
import Text from 'components/atoms/Text';

import { SparePart } from 'types/spare-part';

type SparePartDetailFieldProps = {
  sparePart: SparePart;
  rowClick?: (sparePart: SparePart) => void;
  checked?: boolean;
  showLocation?: boolean;
  showBooking?: boolean;
  showOnboardedStock?: boolean;
  showBroken?: boolean;
};

const TopText: React.FunctionComponent<{ sparePart: SparePart; isSecondHand: boolean }> = ({
  sparePart,
  isSecondHand,
}) => (
  <Box display="block">
    <Box display="inline-flex">
      {sparePart.sku_model?.sku_reference}
      {isSecondHand && (
        <Box ml={1}>
          <SecondHandIcon />
        </Box>
      )}
    </Box>
    <Text color="grey900" mt={1} variant="caption">
      {sparePart.unique_id}
    </Text>
  </Box>
);

const SparePartDetailField: React.FunctionComponent<SparePartDetailFieldProps> = ({
  sparePart,
  rowClick,
  checked = false,
  showLocation = false,
  showBooking = false,
  showOnboardedStock = false,
  showBroken = false,
}: SparePartDetailFieldProps) => {
  const isSecondHand = sparePart.is_second_hand || false;
  const hasDemand = showBooking && !!sparePart.demand?.customer_file;
  const isBoardedStock =
    showOnboardedStock &&
    !sparePart.is_defective &&
    sparePart.is_boarded_stock &&
    !sparePart.demand?.customer_file;

  return (
    sparePart && (
      <ItemDetailField
        topText={<TopText sparePart={sparePart} isSecondHand={isSecondHand} />}
        checked={checked}
        bottomText={sparePart.sku_model?.designation}
        onclick={() => rowClick && rowClick(sparePart)}
        location={showLocation ? sparePart.location?.code : null}
        removeable={false}
        booked={hasDemand}
        boarded={isBoardedStock}
        broken={showBroken && sparePart.is_defective}
      />
    )
  );
};

export default SparePartDetailField;
