import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const resetCache = () => {
  if ('serviceWorker' in navigator) {
    serviceWorkerRegistration.unregister();
    caches
      .keys()
      .then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      })
      .then(() => {
        serviceWorkerRegistration.register();
      });
  }
  location.reload();
};
