import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { QueryObserverResult, useQuery } from 'react-query';

import { Statistics } from 'types/employee-statistics';
import { IncentiveStatistics } from 'types/incentive-statistics';

import { getDataWarehouseEmployeeStatistics, getIncentiveStatistics } from './api';

export const useEmployeeStatistics = (
  selectedDate: string,
  startDate: string,
  endDate: string,
  repairmanId: number
): QueryObserverResult<Statistics> => {
  const statQuery = useQuery([startDate, endDate, repairmanId], async () => {
    return await getDataWarehouseEmployeeStatistics(
      dayjs(selectedDate).format('YYYY-MM'),
      repairmanId
    );
  });

  return statQuery;
};

type IncentiveStatisticsReturnValue = {
  isSuccess: boolean;
  incentiveStatistics: IncentiveStatistics;
};
export const useIncentiveStatistics = (
  startDate: string,
  endDate: string,
  repairmanId: number
): IncentiveStatisticsReturnValue => {
  const [incentiveStatistics, setIncentiveStatistics] = useState<IncentiveStatistics>({
    statistics: [],
    statistics_to_be_improve: [],
    total_incentives: 0,
  });

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const fetchIncentiveStatistics = async () => {
      const data = await getIncentiveStatistics(startDate, endDate, repairmanId);
      setIncentiveStatistics(data);
      setIsSuccess(true);
    };
    fetchIncentiveStatistics();
  }, [startDate, endDate, repairmanId]);

  return { isSuccess, incentiveStatistics };
};
