// React imports
import React, { useState } from 'react';

// Icons
import { Button } from '@material-ui/core';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';

import RepairReportAdditionalInformationPictureStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportAdditionalInformationPictureStyle';

import { compressImage } from 'utils/images';

const useStyles = makeStyles(RepairReportAdditionalInformationPictureStyle);

export default function HermeticUnitPicture(props) {
  const classes = useStyles();
  const [pictures, setPictures] = useState([]);
  const {
    input: { onChange: onNameplatePictureAddition },
  } = useField(props.formProductIdReference + `.hermetic_unit_order_picture`);
  const productReference = props.formProductIdReference && props.formProductIdReference.split('_');
  let productFileNameIdentifier = productReference && productReference[1];
  if (productReference && productReference[1] === 'new') {
    productFileNameIdentifier = productReference[1] + productReference[2];
  }

  const onDrop = (uploadedPicture) => {
    const newMedia = [];
    uploadedPicture &&
      uploadedPicture.map((image) =>
        newMedia.push({
          tag: 'hermetic_unit',
          file: image,
        })
      );
    const imagesToCompress = [...pictures, ...newMedia];
    imagesToCompress.forEach((image, index) =>
      compressImage(image.file, 'hermetic_unit_' + productFileNameIdentifier + '_' + index + '.png')
        .then((compressedFile) => {
          image.file = compressedFile;
        })
        .catch((error) => {
          console.error(error.message);
        })
    );
    setPictures(imagesToCompress);
    onNameplatePictureAddition(imagesToCompress);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removePicture = (index) => {
    const newPictures = [...pictures];
    newPictures.splice(index, 1);
    setPictures([...newPictures]);
    onNameplatePictureAddition(newPictures);
  };
  return (
    <div>
      {
        <div className={classes.addNewMediaButtonContainer}>
          <div>
            {pictures &&
              pictures.map((picture, index) => (
                <div className={classes.preview} key={index}>
                  <RemoveCircleOutlineOutlinedIcon
                    className={classes.onCloseIcon}
                    onClick={() => removePicture(index)}
                  />
                  {picture.file && (
                    <img
                      src={URL.createObjectURL(picture.file)}
                      className={classes.previewPicture}
                      alt="dummy"
                      width="148"
                      height="144"
                    />
                  )}
                </div>
              ))}
          </div>

          <Button id="add-new-media" className={classes.addNewMediaButton}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {<p> Ajouter une photo de la plaque du compresseur</p>}
            </div>
          </Button>
        </div>
      }
    </div>
  );
}
