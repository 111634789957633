// spare part defects
export const BAD_REFERENCE = 'bad_reference';
const INCOMPLETE = 'incomplete';
const BROKEN_DURING_INTERVENTION = 'broken_during_intervention';
const INCORRECTLY_PROGRAMMED = 'incorrectly_programmed';
const MALFUNCTIONING = 'malfunctioning';
const RECEIVED_BROKEN = 'received_broken';
const DIRTY = 'dirty';
const BAD_QUANTITY = 'bad_quantity';
export const MACHINE_TESTED = 'machine_tested';
const MACHINE_NON_TESTED = 'machine_non_tested';

export const WRONG_SPARE_PART = 'wrong-spare-part';

export const PROBLEMS = [
  {
    value: WRONG_SPARE_PART,
    name: 'Ce n’est pas la pièce demandée / étiquette incorrecte',
  },
  { value: 'defective', name: 'La pièce à un défaut' },
];

export const SPARE_PART_DEFECTS = [
  { value: INCOMPLETE, name: 'Incomplète' },
  { value: BROKEN_DURING_INTERVENTION, name: 'Cassé en intervention' },
  { value: INCORRECTLY_PROGRAMMED, name: 'Mal programmée' },
  { value: MALFUNCTIONING, name: 'Ne fonctionne pas' },
  { value: RECEIVED_BROKEN, name: 'Pièce reçue cassée' },
  { value: DIRTY, name: 'Sale' },
  { value: BAD_QUANTITY, name: 'Quantité incorrecte' },
];

export const HAS_BEEN_TEST_FITTED = [
  { value: MACHINE_TESTED, name: 'Oui' },
  { value: MACHINE_NON_TESTED, name: 'Non' },
];

export const PROBLEM = 'problem';
export const DEFECT = 'defect';
export const TEST = 'test';
export const COMMENT = 'comment';

export const STEPS = [
  { text: 'Quel est le problème ?', type: 'choice', name: PROBLEM, choices: PROBLEMS },
  { text: 'Quel est le défaut ?', type: 'choice', name: DEFECT, choices: SPARE_PART_DEFECTS },
  {
    text: 'Avez-vous testé la pièce sur la machine ?',
    type: 'choice',
    name: TEST,
    choices: HAS_BEEN_TEST_FITTED,
  },
  { text: 'Commentaire', type: 'text', name: COMMENT },
];
