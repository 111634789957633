import React from 'react';

import { Admin, AuthProvider, DataProvider, Resource } from 'react-admin';

import { ThemeProvider } from '@material-ui/core/styles';

import { History } from 'history';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import './App.css';
import customRoutes from './CustomRoutes';
import theme from './customTheme';

type ReactAdminAppProps = {
  authProvider: AuthProvider;
  dataProvider: DataProvider;
  history: History;
  store: Store;
};
const ReactAdminApp: React.FunctionComponent<ReactAdminAppProps> = ({
  authProvider,
  dataProvider,
  store,
  history,
}: ReactAdminAppProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          history={history}
          customRoutes={customRoutes}
        >
          <Resource name="customer-file" />
          <Resource name="fault-forecast-detail" />
          <Resource name="spare-parts" />
          <Resource name="products" />
          <Resource name="location" />
          <Resource name="account" />
          <Resource name="task-list" />
          <Resource name="incentives" />
          <Resource name="prev-incentives" />
          <Resource name="sku" />
          <Resource name="sku-transfer" />
          <Resource name="claim" />
          <Resource name="package" />
          <Resource name="employee-status" />
        </Admin>
      </ThemeProvider>
    </Provider>
  );
};

export default ReactAdminApp;
