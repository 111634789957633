import { useState } from 'react';

import dayjs from 'dayjs';

type MonthSelectorHandler = {
  selectedDate: string;
  onMonthChange: (index: number) => void;
  startDate: string;
  endDate: string;
};
export const useMonthSelector = (
  initialDate: string,
  hasWorkshop: boolean
): MonthSelectorHandler => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const onMonthChange = (index: number) => {
    setSelectedDate(dayjs(selectedDate).add(index, 'M').format('YYYY-MM-DD'));
  };

  let startDate = null;
  let endDate = null;

  if (!hasWorkshop) {
    startDate =
      dayjs(selectedDate).date() > 25
        ? dayjs(selectedDate).set('date', 26).format('YYYY-MM-DD')
        : dayjs(selectedDate)
            .set('date', 26)
            .set('month', dayjs(selectedDate).get('month') - 1)
            .format('YYYY-MM-DD');

    endDate =
      dayjs(selectedDate).date() > 25
        ? dayjs(selectedDate)
            .set('date', 25)
            .set('month', dayjs(selectedDate).get('month') + 1)
            .format('YYYY-MM-DD')
        : dayjs(selectedDate).set('date', 25).format('YYYY-MM-DD');
  } else {
    startDate = dayjs(selectedDate).set('date', 1).format('YYYY-MM-DD');
    endDate = dayjs(selectedDate)
      .set('date', dayjs(selectedDate).daysInMonth())
      .format('YYYY-MM-DD');
  }

  return {
    selectedDate: selectedDate,
    onMonthChange: onMonthChange,
    startDate: startDate,
    endDate: endDate,
  };
};
