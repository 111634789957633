import React, { useEffect, useState } from 'react';

import { useRefresh } from 'react-admin';

import invariant from 'tiny-invariant';

import SparePartPackingManagement from 'components/organisms/SparePartPackingManagement';

import { SparePart } from 'types/spare-part';

import { generateQrCode } from 'utils/QrCodeUtils';
import { postSparePartIdentification } from 'utils/api/api';
import { packSparePartWithSystemId } from 'utils/api/api';
import { generateUniqueId } from 'utils/skuUniqueIdGenerator';

type SparePartPackagingProps = {
  selectedId: number;
  spareParts: SparePart[];
  onClose: () => void;
  mode: 'packing' | 'transfer';
  open: boolean;
  recipientName: string;
  handleCreate: (
    employeeId: number,
    sparePartIds: number[],
    recipientName: string,
    lostSparePartIds: number[]
  ) => void;
  isPackageButtonDisabled: boolean;
  generatedPackageCode: string;
};

const SparePartPackaging: React.FunctionComponent<SparePartPackagingProps> = ({
  selectedId,
  spareParts,
  onClose,
  mode,
  open,
  recipientName,
  handleCreate,
  isPackageButtonDisabled,
  generatedPackageCode,
}: SparePartPackagingProps) => {
  const refresh = useRefresh();
  const [isLossModalOpen, setIsLossModalOpen] = useState(false);
  const [selectedSparePart, setSelectedSparePart] = useState<SparePart>();
  const [isScanModalOpen, setIsScanModalOpen] = useState<boolean>(false);
  // Id list of the spare parts to pack (linked to the demand items)
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  // Id list of the spare parts matched in the database from the scanned QR code
  const [selectedSparePartIds, setSelectedSparePartIds] = useState<number[]>([]);
  const [selectedUniqueIds, setSelectedUniqueIds] = useState<string[]>([]);

  // Barcord that don't match any spare_part in our system and need to
  // be regularized
  const [barcodesToRegularize, setBarcodesToRegularize] = useState<string[]>([]);
  const [barcodeBeingRegularized, setBarcodeBeingRegularized] = useState<string>();

  const [lostSkuList, setLostSkuList] = useState<number[]>([]);
  const [error, setError] = useState<string>('');
  const [isPrintModalOpened, setIsPrintModalOpened] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>();
  const [packageCode, setPackageCode] = useState<string>();
  const [surplusSkuList, setSurplusSkuList] = useState<SparePart[]>([]);
  const [scannedElement, setScannedElement] = useState<string | undefined>();

  // temporary spare part creator display state management

  const handleOpenScanModal = () => {
    setIsScanModalOpen(true);
  };

  const resetLists = () => {
    setSelectedIds([]);
    setSelectedSparePartIds([]);
    setSelectedUniqueIds([]);
    setLostSkuList([]);
    setSurplusSkuList([]);
  };

  const handleCloseModal = () => {
    resetLists();
    onClose();
  };
  const handleCloseSparePartLossModal = () => {
    setIsLossModalOpen(false);
    refresh();
  };

  const handleCloseScanModal = () => {
    setIsScanModalOpen(false);
    setError('');
  };

  const handleClosePackageDetails = () => {
    setIsPrintModalOpened(false);
    if (packageCode && scannedElement === 'package') {
      handleCloseModal();
    }
    setScannedElement(undefined);
  };

  // Handle icon button click on sku
  const onLossClick = (sparePart: SparePart) => {
    // Set the selected spare part
    setSelectedSparePart(sparePart);
    setIsLossModalOpen(true);
  };

  // The identifed system id might not match
  // the sparepart id. This is because the
  // list of unique_id to identify is actually
  // a list of spare parts that act as place holders
  // before matching the actual spare_part from
  // the scanned unique id
  const pack = async (
    sparePartIdentifiedSystemId: number,
    sparePart: SparePart,
    sparePartBarcode: string
  ) => {
    invariant(
      typeof sparePart.demand_item !== 'undefined',
      'Cannot pack a spare part without a demand item'
    );
    try {
      const { id: sparePartId } = await packSparePartWithSystemId(
        sparePartIdentifiedSystemId,
        sparePart.demand_item
      );

      setSelectedUniqueIds((oldArray) => [...oldArray, sparePartBarcode]);
      setIsScanModalOpen(false);
      setSelectedIds((oldArray) => [...oldArray, sparePart.id]);
      setSelectedSparePartIds((oldArray) => [...oldArray, sparePartId]);
    } catch (e) {
      setError(
        'Cette pièce ne peut pas être colisée - le qr code référence une pièce indisponible' +
          e.message
      );
    }
  };
  const handleConfirmBarcode = async (sparePartBarcode: string) => {
    const skuReference = sparePartBarcode.substring(0, sparePartBarcode.lastIndexOf('/'));
    // Find a spare part in the list that has not been packed yet with the right reference
    const sparePart = spareParts.find(
      (sparePart: SparePart) =>
        !selectedIds.includes(sparePart.id) && sparePart.sku_model.sku_reference === skuReference
    );

    if (sparePart && !selectedUniqueIds.includes(sparePartBarcode)) {
      setError('');

      // sparePartId is the id of the SparePart matched with the barcode in the database
      // It might be different than sparePart.id (the SparePart linked to the DemandItem)
      if (sparePart.demand_item) {
        // Identify the spare part in the system

        let systemId;

        try {
          const { system_id } = await postSparePartIdentification({
            unique_id: sparePartBarcode,
          });
          systemId = system_id;
        } catch {
          if (!barcodesToRegularize.includes(sparePartBarcode)) {
            setBarcodesToRegularize((barcodesToRegularize) => [
              ...barcodesToRegularize,
              sparePartBarcode,
            ]);
            setError(`La pièce ${sparePartBarcode} doit être régularisée`);
            setIsScanModalOpen(false);
          } else {
            setError(`La pièce ${sparePartBarcode} est déjà à régulariser`);
          }
          return;
        }

        // Pack the identified spare part
        await pack(systemId, sparePart, sparePartBarcode);
      }
    } else {
      setError('Erreur SKU : ' + sparePartBarcode);
    }
  };

  const handleRegularize = (unique_id: string) => {
    setBarcodesToRegularize((currentBarcodesToRegularize) => {
      const results = currentBarcodesToRegularize.filter((barcode) => barcode !== unique_id);
      return results;
    });
    setBarcodeBeingRegularized(undefined);
  };

  // Add a sku to the lost sku list
  const handleSparePartLoss = () => {
    if (selectedSparePart) {
      setLostSkuList([...lostSkuList, selectedSparePart.id]);
    }
  };

  // It open the print screen modal
  const handlePrintScreen = (code: string, scanSource?: string) => {
    // unclear
    setScannedElement(scanSource);
    setPackageCode(code);
    setIsPrintModalOpened(true);
    if (scanSource === 'package') {
      setSelectedSparePart(undefined);
    }
    generateQrCode(code, setQrCode);
  };

  // Used to generate an unique id for the spare part  and to trigger
  // the print screen display
  const printSku = (sparePart: SparePart) => {
    generateUniqueId(sparePart).then(handlePrintScreen);
  };

  useEffect(() => {
    if (generatedPackageCode) handlePrintScreen(generatedPackageCode, 'package');
  }, [generatedPackageCode]);

  // Used when the complete task is finished
  // It tests if we have to manage a transfer or a package
  async function handleValidateTask() {
    await handleCreate(selectedId, selectedSparePartIds, recipientName, lostSkuList);
    resetLists();
    refresh();
  }

  return (
    <SparePartPackingManagement
      open={open}
      onClose={onClose}
      handleCloseModal={handleCloseModal}
      selectedSparePartIds={selectedIds}
      lostSkuList={lostSkuList}
      skuNumber={spareParts.length - lostSkuList.length}
      recipientName={recipientName}
      selectedId={selectedId}
      spareParts={spareParts}
      printSku={printSku}
      onLossClick={onLossClick}
      surplusSkuList={surplusSkuList}
      isPackageButtonDisabled={isPackageButtonDisabled}
      handleValidateTask={handleValidateTask}
      mode={mode}
      isLossModalOpen={isLossModalOpen}
      barcodesToRegularized={barcodesToRegularize}
      onSelectBarcode={(barcode) => setBarcodeBeingRegularized(barcode)}
      clearSelectedBarcode={() => setBarcodeBeingRegularized(undefined)}
      barcodeBeingRegularized={barcodeBeingRegularized}
      selectedSparePart={selectedSparePart}
      handleCloseSparePartLossModal={handleCloseSparePartLossModal}
      handleConfirmBarcode={handleConfirmBarcode}
      handleCloseScanModal={handleCloseScanModal}
      handleSparePartLoss={handleSparePartLoss}
      isScanModalOpen={isScanModalOpen}
      isPrintModalOpened={isPrintModalOpened}
      handleClosePackageDetails={handleClosePackageDetails}
      qrCode={qrCode}
      packageCode={packageCode}
      handleOpenScanModal={handleOpenScanModal}
      error={error}
      onRegularize={handleRegularize}
    />
  );
};

export default SparePartPackaging;
