import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { ProductCheckListReportItem } from 'types/product-check-list-report-item';
import { CleaningTaskChecklistHistory, ProductCheckListReport } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type CleaningTaskBlockProps = {
  cleaningTask: CleaningTaskChecklistHistory;
};

const GreenCheck = styled(CheckCircleTwoToneIcon)((props) => ({
  color: props.theme.colors.green,
  height: 48,
  width: 48,
}));

const TinyRedCross = styled(CancelIcon)((props) => ({
  color: props.theme.colors.red,
  height: 16,
  width: 16,
}));

const OrangeWarning = styled(WarningTwoToneIcon)((props) => ({
  color: props.theme.colors.orange,
  height: 48,
  width: 48,
}));

const CleaningTaskBlock: React.FunctionComponent<CleaningTaskBlockProps> = ({
  cleaningTask,
}: CleaningTaskBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const problematicItems = cleaningTask.data.checklists
    ?.map((checklist: ProductCheckListReport) => {
      return [...checklist.items.filter((item: ProductCheckListReportItem) => !item.is_checked)];
    })
    .flat(1);

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={cleaningTask.data.created_at}
          assignee={cleaningTask.data.assignee}
          title={`Nettoyage`}
          tagVariant={'info'}
        />
      </Box>
      {isOpen && (
        <SimpleBlockContent noSeparator>
          <Box
            height={6}
            mt={2}
            mb={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {problematicItems && problematicItems?.length < 1 ? (
                <Text variant="smallBold" color="dark">
                  Appareil Nettoyé
                </Text>
              ) : (
                <Text variant="smallBold" color="dark">
                  {'Problème pendant le nettoyage'}
                </Text>
              )}
            </Box>

            {problematicItems && problematicItems.length < 1 && <GreenCheck />}
            {problematicItems && problematicItems.length > 0 && <OrangeWarning />}
          </Box>

          {problematicItems && problematicItems.length > 0 && (
            <Box width="100%" mb={1} mr={2}>
              {problematicItems?.map(
                (item: ProductCheckListReportItem | undefined, index: number) => {
                  return (
                    <Box key={`${index}`} display="flex" justifyContent="space-between" mt={2}>
                      <Text key={`${index}`} variant="small" color="dark">
                        {item?.text}
                      </Text>
                      <TinyRedCross />
                    </Box>
                  );
                }
              )}
            </Box>
          )}
        </SimpleBlockContent>
      )}
    </Box>
  );
};
export default CleaningTaskBlock;
