import React, { forwardRef, useState } from 'react';

import { AppBar, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import styled from 'styled-components';

import RepairmanScanModal from 'pages/RepairmanStock/components/RepairmanScanModal';
import StoreStyles from 'pages/Store/StoreStyles';
import FloatingActionButton from 'pages/Store/components/FloatingActionButton';
import PrintContent from 'pages/Store/components/PrintContent';
import SparePartLossModal from 'pages/Store/components/SparePartLossModal';
import SparePartPackingField from 'pages/Store/components/SparePartPackingField';

import PortalComponent from 'components/Portal/PortalComponent';
import ValidateScanningTask from 'components/ValidateScanningTask/ValidateScanningTask';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import BarcodesToRegularize from 'components/organisms/BarcodesToRegularize';

import { SparePart } from 'types/spare-part';

import SparePartCreator from './SparePartCreator';

type SparePartPackingManagementProps = {
  open: boolean;
  onClose: () => void;
  handleCloseModal: () => void;
  selectedSparePartIds: number[];
  lostSkuList: number[];
  skuNumber: number;
  recipientName: string;
  selectedId: number;
  spareParts: SparePart[];
  printSku: (sparePart: SparePart) => void;
  onLossClick: (record: SparePart) => void;
  surplusSkuList: SparePart[];
  isPackageButtonDisabled: boolean;
  handleValidateTask: () => void;
  mode: 'packing' | 'transfer';
  isLossModalOpen: boolean;
  selectedSparePart?: SparePart;
  handleCloseSparePartLossModal: () => void;
  handleConfirmBarcode: (value: string) => void;
  handleCloseScanModal: () => void;
  onSelectBarcode: (barcode: string) => void;
  clearSelectedBarcode: () => void;
  handleSparePartLoss: () => void;
  barcodeBeingRegularized: string | undefined;
  onRegularize: (uniqued_id: string) => void;
  barcodesToRegularized: string[];
  isScanModalOpen: boolean;
  isPrintModalOpened: boolean;
  handleClosePackageDetails: () => void;
  qrCode?: string;
  packageCode?: string;
  handleOpenScanModal: () => void;
  error: string;
};

const TransitionScan = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styleStore = makeStyles(StoreStyles);

// OCCASION
const OCCASION = 'OCCASION';

const StyledCachedIcon = styled(CachedIcon)((props) => ({
  color: props.theme.colors.grey900,
}));

const SparePartPackingManagement: React.FunctionComponent<SparePartPackingManagementProps> = ({
  open,
  onClose,
  handleCloseModal,
  selectedSparePartIds,
  lostSkuList,
  skuNumber,
  recipientName,
  selectedId,
  spareParts,
  printSku,
  onLossClick,
  surplusSkuList,
  onRegularize,
  isPackageButtonDisabled,
  barcodesToRegularized,
  barcodeBeingRegularized,
  handleValidateTask,
  mode,
  isLossModalOpen,
  selectedSparePart,
  handleCloseSparePartLossModal,
  handleConfirmBarcode,
  handleCloseScanModal,
  handleSparePartLoss,
  isScanModalOpen,
  isPrintModalOpened,
  handleClosePackageDetails,
  qrCode,
  packageCode,
  handleOpenScanModal,
  onSelectBarcode,
  clearSelectedBarcode,
  error,
}: SparePartPackingManagementProps) => {
  const classes = styleStore();

  const [prevTouchInfo, setPrevTouchInfo] = useState(0);

  // Used to check if we can close the modal with only one tap
  const handleDoubleClickCheck = () => {
    const dt = Date.now() - prevTouchInfo;
    if ((selectedSparePartIds.length === 0 && lostSkuList.length === 0) || dt < 500) {
      handleCloseModal();
    }
    setPrevTouchInfo(Date.now());
  };

  return (
    <PortalComponent open={open} onClose={onClose}>
      <AppBar color="transparent" className={classes.header}>
        <div className={classes.elementHeader}>
          <IconButton edge="start" onClick={handleDoubleClickCheck} className={classes.back}>
            <ArrowBackIcon />
          </IconButton>
          <span>{recipientName}</span>
          <span className={classes.skuHeaderNumber}>{skuNumber}</span>
        </div>
      </AppBar>

      {barcodeBeingRegularized && (
        <SparePartCreator
          sparePartBarcode={barcodeBeingRegularized}
          onSuccess={onRegularize}
          onError={() => undefined}
          handleConfirmSparePart={handleConfirmBarcode}
          onClose={clearSelectedBarcode}
        />
      )}

      <Box pt={6} bgcolor="#F1F0F5" height="100vh">
        <Box p={2} position="relative" height="calc(100vh - 156px)" overflow="auto">
          <BarcodesToRegularize
            barcodesToRegularize={barcodesToRegularized}
            onSelectBarcode={onSelectBarcode}
          />
          <Box mt={6}>
            {selectedId && (
              <Box>
                <Text mb={2} variant="body">
                  Pièces à coliser
                </Text>
                {spareParts.map((item: SparePart, index: number) => (
                  <Box
                    key={index}
                    mb={1}
                    padding={1}
                    style={{
                      background: lostSkuList.includes(item?.id) ? '#DBDFE8' : 'white',
                      borderRadius: 8,
                    }}
                  >
                    <SparePartPackingField
                      designation={item.sku_model && item.sku_model.designation}
                      references={[
                        {
                          supplier_name: item.sku_model && item.sku_model.supplier,
                          reference: item.sku_model && item.sku_model.supplier_reference,
                        },
                      ]}
                      thumbnail={item.thumbnail}
                      isSecondHand={item.is_second_hand}
                      isSelected={selectedSparePartIds.includes(item.id)}
                      isLost={lostSkuList.includes(item.id)}
                      isSurplus={surplusSkuList.includes(item)}
                      locationCode={item.location && item.location.code}
                      lossClick={() => onLossClick(item)}
                      printSku={() => printSku(item)}
                    />
                  </Box>
                ))}
              </Box>
            )}
            {selectedSparePartIds.length > 0 && selectedSparePartIds.length !== skuNumber && (
              <Button variant="fullTransparent" onClick={handleValidateTask}>
                <Text variant="small" color="grey900">
                  Le colis est plein
                </Text>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <ValidateScanningTask
        disabled={skuNumber !== selectedSparePartIds.length || isPackageButtonDisabled}
        onClick={handleValidateTask}
        buttonText="Coliser"
      />
      {isLossModalOpen && selectedSparePart && (
        <SparePartLossModal
          open={isLossModalOpen && !!selectedSparePart}
          onClose={handleCloseSparePartLossModal}
          employeeName={recipientName}
          defaultSparePart={selectedSparePart}
          handleConfirmBarcode={handleConfirmBarcode}
          handleSparePartLoss={handleSparePartLoss}
          mode={mode}
        />
      )}
      <RepairmanScanModal
        error={error}
        hideManualInput
        bodyText="Prenez l’ensemble de l’étiquette, veillez à la netteté."
        headerText="Scannez une pièce"
        TransitionComponent={TransitionScan}
        handleConfirmBarcode={handleConfirmBarcode}
        onClose={handleCloseScanModal}
        open={isScanModalOpen}
      />

      <PrintContent
        open={isPrintModalOpened}
        onClose={handleClosePackageDetails}
        firstText={'Impression de code'}
        secondText={packageCode}
        codeSubtitle={packageCode}
        qrCode={qrCode}
        handleNextStep={handleClosePackageDetails}
        headingText={selectedSparePart?.is_second_hand ? OCCASION : ''}
        LeftIcon={selectedSparePart?.is_second_hand ? StyledCachedIcon : undefined}
        RightIcon={selectedSparePart?.is_second_hand ? StyledCachedIcon : undefined}
        barCode={undefined}
      />
      <div>
        <FloatingActionButton
          onClick={handleOpenScanModal}
          withoutBottomBar={selectedSparePartIds.length <= 0}
          color="secondary"
          icon={<CenterFocusStrongIcon />}
        />
      </div>
    </PortalComponent>
  );
};

export default SparePartPackingManagement;
