import React, { ReactNode } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import WorkIcon from '@material-ui/icons/Work';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import IconText from 'components/IconText';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

import Box from '../Box';

type ItemDetailFieldProps = {
  topText: string | ReactNode;
  bottomText: string;
  location?: string | null;
  onclick?: () => void;
  checked?: boolean;
  removeable: boolean;
  onRemove?: () => void;
  booked?: boolean;
  boarded?: boolean;
  errored?: boolean;
  broken?: boolean;
  customText?: React.ReactNode;
};

type IconProps = {
  className?: string;
};

const StyledLocationIcon = styled(LocationOnIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.yellow')(props),
  marginRight: themeGet('space.1')(props),
}));

const StyledCheckedIcon = styled(CheckCircleIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.greenChecked')(props),
  width: themeGet('space.3')(props),
  height: themeGet('space.3')(props),
  float: 'right',
}));

const StyledHowToRegIcon = styled(HowToRegIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.honey')(props),
  width: themeGet('space.3')(props),
  height: themeGet('space.3')(props),
  float: 'right',
}));

const StyledRemoveCircleOutlineIcon = styled(RemoveCircleOutlineIcon)<IconProps>(
  (props: IconProps) => ({
    color: themeGet('colors.grey300')(props),
    width: themeGet('space.3')(props),
    height: themeGet('space.3')(props),
    float: 'right',
  })
);

const StyledCancelIcon = styled(CancelIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.red')(props),
  width: themeGet('space.3')(props),
  height: themeGet('space.3')(props),
  float: 'right',
}));
const StyledWorkIcon = styled(WorkIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.honey')(props),
  width: themeGet('space.3')(props),
  height: themeGet('space.3')(props),
  float: 'right',
}));

const StyledErrorIcon = styled(ErrorIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.red')(props),
  width: themeGet('space.3')(props),
  height: themeGet('space.3')(props),
  float: 'right',
}));

const ItemDetailField: React.FunctionComponent<ItemDetailFieldProps> = ({
  topText,
  bottomText,
  location,
  onclick,
  checked,
  booked,
  boarded,
  broken,
  onRemove,
  removeable,
  errored,
  customText,
}: ItemDetailFieldProps) => {
  return (
    <div onClick={() => onclick && onclick()}>
      <PaperStrip mb={1}>
        <Text variant="small" color="dark">
          <Box display="flex" justifyContent="space-between">
            {topText}
            {customText}
          </Box>
          {checked && <StyledCheckedIcon />}
          {booked && <StyledHowToRegIcon />}
          {boarded && <StyledWorkIcon />}
          {broken && <StyledErrorIcon />}
          {!checked && errored && <StyledCancelIcon />}
          {removeable && onRemove && (
            <StyledRemoveCircleOutlineIcon id="remove-icon" onClick={() => onRemove()} />
          )}
        </Text>
        <Text variant="small" color="grey900">
          {bottomText}
        </Text>
        {location && (
          <IconText
            mt={1}
            icon={<StyledLocationIcon fontSize="default" />}
            alignItems="center"
            text={location}
            color="dark"
            variant="smallBold"
          />
        )}
      </PaperStrip>
    </div>
  );
};

export default ItemDetailField;
