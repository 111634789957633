import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import styled from 'styled-components';

import ProductDetailField from 'pages/Store/components/ProductDetailField';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Tag from 'components/atoms/Tag';
import Text from 'components/atoms/Text';
import InputWithIcon from 'components/molecules/InputWithIcon';

import { Product } from 'types/products';
import { WorkshopFile } from 'types/workshop-files';

import { useWorkshopFiles } from 'utils/api/api';

type WorkshopFilesListProps = {
  filter?: Record<string, unknown>;
  handleItemSelection: (item: Product) => void;
  displayMissingSparePartsCount?: boolean;
};

const StyledInputWithIcon = styled(InputWithIcon)((props) => ({
  outline: 'none',
  border: 'none',
  height: props.theme.sizes[6],
  borderRadius: props.theme.radii[0],
}));

const WorkshopFilesList: React.FunctionComponent<WorkshopFilesListProps> = ({
  filter,
  handleItemSelection,
  displayMissingSparePartsCount,
}: WorkshopFilesListProps) => {
  const pageSize = 50;
  const [page, setPage] = useState<number>(1);
  const [productCode, setProductCode] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const { workshopFiles, total, loading, error } = useWorkshopFiles(
    { ...filter, q: searchValue },
    page,
    pageSize
  );

  useEffect(() => {
    // to avoid triggering a getList everytime the search field changes
    const timer = setTimeout(() => {
      setSearchValue(productCode);
    }, 500);
    return () => clearTimeout(timer);
  }, [productCode]);

  return (
    <>
      <Box bg="white" borderTop={'2px solid #DBDFE8'}>
        <StyledInputWithIcon
          placeholder="Rechercher"
          name={'search'}
          value={productCode}
          onChange={(event) => {
            setProductCode(event.target.value);
          }}
          type="string"
          Icon={SearchIcon}
        />
      </Box>
      {loading && (
        <Box mt={4} mx="auto">
          <Text textAlign="center" variant="subtitle">
            Récupération de la liste des machines
          </Text>
          <Box mx="auto" width="fit-content" py={2}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {error && (
        <Text textAlign="center" variant="body" color="red">
          Une erreur est survenue lors du chargement des machines
        </Text>
      )}
      {!loading && !error && (
        <>
          {workshopFiles.length > 0 && (
            <>
              <Text py={1} mt={1} textAlign="center" backgroundColor="white" variant="small">
                Page {page}/{Math.ceil(total / pageSize)} ({total})
              </Text>
              <Box mt={1} overflow="scroll" height="calc(100vh - 210px)">
                {workshopFiles.map((item: WorkshopFile, index) => (
                  <ProductDetailField
                    data-testid={index}
                    key={index}
                    product={item.product as Product}
                    checked={false}
                    removeable={false}
                    onclick={() => handleItemSelection(item.product)}
                    customText={
                      displayMissingSparePartsCount && (
                        <Box>
                          {item.missing_spare_parts_count === 0 && (
                            <Tag variant="success" text="Pièces disponibles" />
                          )}
                          {item.missing_spare_parts_count > 0 && (
                            <Tag
                              variant="problem"
                              text={`${item.missing_spare_parts_count} pièce(s) manquantes`}
                            />
                          )}
                        </Box>
                      )
                    }
                  />
                ))}
                <Box width="80%" display="flex" justifyContent="space-between">
                  <Box>
                    {page > 1 && (
                      <Button variant="fullTransparent" onClick={() => setPage(page - 1)}>
                        Précédent
                      </Button>
                    )}
                  </Box>
                  <Box>
                    {page < Math.ceil(total / pageSize) && (
                      <Button variant="fullTransparent" onClick={() => setPage(page + 1)}>
                        Suivant
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {workshopFiles.length < 1 && (
            <Text textAlign="center" variant="body">
              Aucune machine dans votre atelier
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default WorkshopFilesList;
