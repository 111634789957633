import React from 'react';

import { Field } from 'formik';
import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';

type RadioFieldProps = {
  name: string;
  label: string;
  value: string;
} & MarginProps;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.dark};
  font-size: ${(props) => `${props.theme.fontSizes[3]}px`};
  display: flex;
  width: 100%;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-family: ${(props) => props.theme.fonts[0]};
  margin-left: ${(props) => `${props.theme.space[2]}px`};
`;

const StyledField = styled(Field)`
  width: 10%;
  height: ${(props) => `${props.theme.sizes[3]}px`};
  margin-right: ${(props) => `${props.theme.space[2]}px`};
`;

const RadioField: React.FunctionComponent<RadioFieldProps> = ({
  name,
  label,
  value,
  ...rest
}: RadioFieldProps) => {
  return (
    <Box bg="white" borderRadius={2} display="flex" padding={2} width="100%" {...rest}>
      <StyledLabel>
        <StyledField type="radio" name={name} value={value} />
        {label}
      </StyledLabel>
    </Box>
  );
};

export default RadioField;
