import React from 'react';

import FlatCard from 'components/FlatCard';
import Button from 'components/atoms/Button';
import Tag from 'components/atoms/Tag';
import Text from 'components/atoms/Text';

interface BarcodesToRegularizeProps {
  barcodesToRegularize: string[];
  onSelectBarcode: (barcode: string) => void;
}

export const BarcodesToRegularize: React.FunctionComponent<BarcodesToRegularizeProps> = ({
  barcodesToRegularize,
  onSelectBarcode,
}: BarcodesToRegularizeProps) => {
  return (
    <>
      <Text mb={2} variant="body">
        Pièces à régulariser
      </Text>
      {barcodesToRegularize.length < 1 && (
        <Text variant="small" color="grey700">
          Pas de pièce à régulariser
        </Text>
      )}
      {barcodesToRegularize.map((barcode) => {
        return (
          <FlatCard display="flex" justifyContent="space-between" alignItems="center" key={barcode}>
            <Tag variant="problem" text={barcode} />
            <Button variant="fullTransparent" onClick={(_) => onSelectBarcode(barcode)}>
              Régulariser
            </Button>
          </FlatCard>
        );
      })}
    </>
  );
};

export default BarcodesToRegularize;
