import styled from 'styled-components';
import { HeightProps, height, variant } from 'styled-system';

const PageLayoutVariants = {
  transparent: {
    backgroundColor: 'transparent',
  },
  white: {
    backgroundColor: 'white',
  },
};

type PageLayoutProps = {
  variant?: keyof typeof PageLayoutVariants;
} & HeightProps;

const PageLayout = styled('div')<PageLayoutProps>(
  height,
  () => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  variant({
    variants: PageLayoutVariants,
  })
);

PageLayout.defaultProps = {
  variant: 'transparent',
};

export default PageLayout;
