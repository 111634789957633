import React from 'react';

import { useEmployee } from 'employeeContext';
import { FormikProps } from 'formik';

import Button from 'components/atoms/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/TextArea';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { sendGAEvent } from 'utils/google-analytics';

type AdditionalInformationValues = {
  additional_information: string;
};

type AdditionalInformationProps = {
  back: () => void;
} & FormikProps<AdditionalInformationValues>;

export const AdditionalInformation: React.FunctionComponent<AdditionalInformationProps> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  back,
}: AdditionalInformationProps) => {
  const { employee } = useEmployee();
  return (
    <PageLayout>
      <HeaderWithArrow title="Informations supplémentaires" action={back} />
      <ContentContainer>
        <Text mb={3} variant="subtitle">
          Informations supplémentaires
        </Text>
        <form onSubmit={handleSubmit}>
          <TextArea
            onChange={handleChange}
            onBlur={handleBlur}
            width="100%"
            rows="7"
            data-testid="additional_information"
            value={values.additional_information}
            placeholder="Informations supplémentaires"
            name="additional_information"
          />
          <Button
            width="100%"
            mt={2}
            type="submit"
            onClick={() =>
              sendGAEvent(
                'end-prequal',
                'prequal',
                new Date().toDateString(),
                `${employee.user?.username}`
              )
            }
          >
            Suivant
          </Button>
        </form>
      </ContentContainer>
    </PageLayout>
  );
};

export default AdditionalInformation;
