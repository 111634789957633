import React, { useState } from 'react';

import { useEmployee } from 'employeeContext';
import { useHistory } from 'react-router-dom';
import { resetCache } from 'service-worker.utils';
import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button/Button';
import Text from 'components/atoms/Text';

import NavigationLinks from '../NavigationLinks';
import PasswordChange from '../PasswordChange';
import Profile from '../Profile';

type CanvasMenuProps = { onClose: () => void };

const Dimmed = styled.div({
  height: '100vh',
  width: '20%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  transition: 'opacity 2s',
  opacity: 1,
});

const CanvasMenu: React.FunctionComponent<CanvasMenuProps> = ({ onClose }: CanvasMenuProps) => {
  const history = useHistory();
  const { clearEmployee, employee } = useEmployee();
  const [passwordChangeOpen, setPasswordChangeOpen] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

  const deconnection = () => {
    clearEmployee();
    history.push('/');
  };

  const appRefresh = () => {
    resetCache();
  };

  return (
    <Box display="flex">
      <Box width="100%" backgroundColor="#FFFFFF">
        <Profile />
        <NavigationLinks employee={employee} />
        <Box display="block">
          <Box>
            <Button
              variant="fullTransparent"
              onClick={() => {
                setPasswordChangeOpen(true);
                setPasswordChanged(false);
              }}
            >
              Changer mon mot de passe
            </Button>
            <Button variant="fullTransparent" onClick={deconnection}>
              Se déconnecter
            </Button>
          </Box>
          <Box>
            <Button variant="fullTransparent" onClick={appRefresh}>
              Mettre à jour l&apos;application
            </Button>
          </Box>
        </Box>

        {passwordChangeOpen && (
          <PasswordChange
            open={passwordChangeOpen}
            onClose={() => setPasswordChangeOpen(false)}
            onSuccessCallback={() => setPasswordChanged(true)}
          />
        )}
        {passwordChanged && (
          <Box mt={2} width="100%">
            <Text textAlign="center" color="green" variant="smallBold">
              Mot de passe modifié !
            </Text>
          </Box>
        )}
      </Box>

      <Dimmed
        onClick={() => {
          onClose();
          setPasswordChanged(false);
        }}
      />
    </Box>
  );
};

export default CanvasMenu;
