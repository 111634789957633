const theme = {
  fonts: ['GT-Walsheim'],
  breakpoints: ['600px', '900px', '1200px'],
  fontSizes: [12, 14, 15, 18, 20, 28, 30],
  fontWeights: { regular: 400, medium: 500, bold: 700 },
  colors: {
    black500: '#3E3E3E',
    black700: '#1B1E1C',
    blue: '#4A55FE',
    lightBlue: '#47BDFF',
    orange: '#FF6B17',
    purple: '#C317FF',
    dark: '#2B3B5C',
    green200: '#A4E7DB',
    green700: '#073331',
    green: '#005646',
    greenChecked: '#81D89F',
    grey200: '#F6F6F6',
    grey300: '#D7D7D7',
    grey500: '#ABABAB',
    grey500_2: '#DBDFE8',
    grey600: '#F1F0F5',
    grey700: '#787878',
    grey900: '#8795B3',
    honey: '#F7B349',
    pink700: '#FF757B',
    pink: '#F7BDBD',
    red: '#F85B5B',
    turquoise: '#1A8E96',
    turquoise15: '#DDEEEF',
    white: '#FFFFFF',
    yellow: '#FFD747',
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72],
  sizes: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72],
  radii: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72],
};

export default theme;
