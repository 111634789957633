// React imports
import React, { useState } from 'react';

// Icons
import { Box, Button } from '@material-ui/core';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/Filter';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';

import RepairReportAdditionalInformationPictureStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportAdditionalInformationPictureStyle';

import Text from 'components/atoms/Text';

import { compressImage } from 'utils/images';

const useStyles = makeStyles(RepairReportAdditionalInformationPictureStyle);

export default function RepairReportAdditionalInformationPicture({
  formProductIdReference,
  fileName,
  fileTag,
}) {
  const classes = useStyles();
  const [pictures, setPictures] = useState([]);
  const pictureName = fileName || 'information_additionnelle_';
  const pictureTag = fileTag || 'attachment';
  const productReference = formProductIdReference && formProductIdReference.split('_');
  let productFileNameIdentifier = productReference && productReference[1];
  if (productReference && productReference[1] === 'new') {
    productFileNameIdentifier = productReference[1] + productReference[2];
  }
  const {
    input: { onChange: onNameplatePictureAddition },
  } = useField(`${formProductIdReference}.additional_info_pictures`);
  const {
    input: { onChange: onJustificationPictureAddition },
  } = useField(`${formProductIdReference}.justification_long_intervention_pictures`);

  const onDrop = (uploadedPicture) => {
    const newMedia = [];
    uploadedPicture &&
      uploadedPicture.map((image) =>
        newMedia.push({
          tag: pictureTag,
          file: image,
        })
      );
    const imagesToCompress = [...pictures, ...newMedia];
    imagesToCompress.forEach((image, index) =>
      compressImage(image.file, pictureName + productFileNameIdentifier + '_' + index + '.png')
        .then((compressedImage) => {
          image.file = compressedImage;
        })
        .catch((error) => {
          console.error(error.message);
        })
    );
    setPictures(imagesToCompress);
    pictureTag === 'long_intervention_justification'
      ? onJustificationPictureAddition(imagesToCompress)
      : onNameplatePictureAddition(imagesToCompress);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const removePicture = (index) => {
    const newPictures = [...pictures];
    newPictures.splice(index, 1);
    setPictures([...newPictures]);
    pictureTag === 'long_intervention_justification'
      ? onJustificationPictureAddition(newPictures)
      : onNameplatePictureAddition(newPictures);
  };
  return (
    <div>
      {
        <div className={classes.addNewMediaButtonContainer}>
          <div>
            {pictures &&
              pictures.map((picture, index) => (
                <div className={classes.preview} key={index}>
                  <RemoveCircleOutlineOutlinedIcon
                    className={classes.onCloseIcon}
                    onClick={() => removePicture(index)}
                  />
                  {picture.file && (
                    <img
                      src={URL.createObjectURL(picture.file)}
                      className={classes.previewPicture}
                      alt="dummy"
                      width="148"
                      height="144"
                    />
                  )}
                </div>
              ))}
          </div>
          <Button id="add-new-media" className={classes.addNewMediaButton}>
            <FilterIcon />
            <div className={classes.contentMoreMedias} {...getRootProps()}>
              <input {...getInputProps()} />
              {<p> Ajouter des photos</p>}
            </div>
          </Button>
          {pictures.length === 0 && (
            <Box p={3}>
              <Text
                text-align="center"
                borderRadius={1}
                padding={1}
                backgroundColor={'turquoise'}
                color="white"
              >
                Pensez à ajouter une photo de l&apos;appareil pour le bonus réparation !
              </Text>
            </Box>
          )}
        </div>
      }
    </div>
  );
}
