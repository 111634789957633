import { useEffect, useState } from 'react';

import { CustomerFileHistory as CustomerFileHistoryType } from 'types/customer-file-history';

import { getCustomerFileHistory } from './api';

type CustomerFileHistoryReturnSuccess = {
  isSuccess: true;
  customerFileHistory: CustomerFileHistoryType[];
};

type CustomerFileHistoryReturnLoading = { isSuccess: false };

type CustomerFileHistoryReturn =
  | CustomerFileHistoryReturnSuccess
  | CustomerFileHistoryReturnLoading;

export const useCustomerFileHistory = (customerFileId: string): CustomerFileHistoryReturn => {
  const [customerFileHistoryQuery, setCustomerFileHistoryQuery] =
    useState<CustomerFileHistoryReturn>({ isSuccess: false });
  useEffect(() => {
    const fetchData = async () => {
      const customerHistoryData = await getCustomerFileHistory(customerFileId);
      // customerFileHistory sorted by visit_number ASC
      setCustomerFileHistoryQuery({
        customerFileHistory: customerHistoryData.sort((a, b) =>
          a.visit_number > b.visit_number ? 1 : -1
        ),
        isSuccess: true,
      });
    };

    fetchData();
  }, [customerFileId]);

  return customerFileHistoryQuery;
};
