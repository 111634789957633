import React from 'react';

import * as Sentry from '@sentry/react';
import dataProvider from 'dataProvider';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';

import App from './App';
import { createAuthProvider } from './authProvider';
import { EmployeeProvider } from './employeeContext';
import './index.css';
import configureStore from './modules/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const initialState = {};

const history = createBrowserHistory();

const store = configureStore(initialState, dataProvider, history, createAuthProvider);

const environment = process.env.REACT_APP_ENVIRONMENT || 'dev';

if (['prod', 'staging', 'integration'].includes(environment)) {
  Sentry.init({
    dsn: 'https://4332658eb7664930b9512b89c5f44194@o464319.ingest.sentry.io/5472792',
    environment: environment,
  });
}

ReactDOM.render(
  <EmployeeProvider>
    <App
      store={store}
      history={history}
      dataProvider={dataProvider}
      authProviderFactory={createAuthProvider}
    />
  </EmployeeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
