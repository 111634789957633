import { assign } from 'xstate';

import { PrequalReportContext, PrequalReportEvent } from './types';

// Side effect (Mealy machine)
const is_repairable = (context: PrequalReportContext, event: PrequalReportEvent) => {
  switch (event.type) {
    case 'IS_REPAIRABLE':
      return event.data.is_repairable;
    case 'IS_NOT_REPAIRABLE':
      return event.data.is_repairable;
    default:
      return context.is_repairable;
  }
};

const spare_parts_needed = (context: PrequalReportContext, event: PrequalReportEvent) => {
  switch (event.type) {
    case 'SPARE_PARTS_ARE_NEEDED':
      return event.data.spare_parts_needed;
    case 'SPARE_PARTS_ARE_NOT_NEEDED':
      return event.data.spare_parts_needed;
    // reset spare parts needed it the machine isn't repairable
    case 'IS_NOT_REPAIRABLE':
      return null;
    default:
      return context.need_skus;
  }
};

const irreparable_reason = (context: PrequalReportContext, event: PrequalReportEvent) => {
  switch (event.type) {
    case 'NOT_REPAIRABLE_REASON':
      return event.data.irreparable_reason;
    default:
      return context.irreparable_reason;
  }
};

const symptoms = (context: PrequalReportContext, event: PrequalReportEvent) => {
  switch (event.type) {
    case 'VALIDATE_SYMPTOMS_AND_DIAGNOSTIC':
      return event.data.symptoms;
    default:
      return context.symptoms;
  }
};

const diagnostic = (context: PrequalReportContext, event: PrequalReportEvent) => {
  switch (event.type) {
    case 'VALIDATE_SYMPTOMS_AND_DIAGNOSTIC':
      return event.data.diagnostic;
    default:
      return context.diagnostic;
  }
};

const additional_information = (context: PrequalReportContext, event: PrequalReportEvent) => {
  switch (event.type) {
    case 'SUBMIT':
      return event.data.additional_information;
    default:
      return context.additional_information;
  }
};

export const contextReducer = assign<PrequalReportContext, PrequalReportEvent>({
  additional_information: (context, event) => additional_information(context, event),
  diagnostic: (context: PrequalReportContext, event: PrequalReportEvent) =>
    diagnostic(context, event),
  symptoms: (context: PrequalReportContext, event: PrequalReportEvent) => symptoms(context, event),
  is_repairable: (context: PrequalReportContext, event: PrequalReportEvent) =>
    is_repairable(context, event),
  need_skus: (context: PrequalReportContext, event: PrequalReportEvent) =>
    spare_parts_needed(context, event),
  irreparable_reason: (context: PrequalReportContext, event: PrequalReportEvent) =>
    irreparable_reason(context, event),
});
