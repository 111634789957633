import React from 'react';

import ImageWithRetry from 'components/ImageWithRetry';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { SkuSupplierReference } from 'types/sku-supplier-references';

import placeholder from './assets/preview.png';

type SkuModelInfosProps = {
  skuModel: {
    id: number;
    designation: string;
    thumbnail: string;
    supplierReference: SkuSupplierReference;
    quantity: number;
    checks: { id: number; text: string; is_checked: boolean }[];
  };
  handleOnClick: () => void;
  detailedView?: boolean;
};

const SkuModelInfos: React.FunctionComponent<SkuModelInfosProps> = ({
  skuModel,
  handleOnClick,
  detailedView,
}: SkuModelInfosProps) => {
  return (
    <Box
      backgroundColor="white"
      onClick={handleOnClick}
      my="auto"
      width={detailedView ? '100%' : '176px'}
      height="100%"
      data-testid={`sku-model-info-${skuModel.id}`}
    >
      <Box
        textAlign="start"
        height="inherit"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <ImageWithRetry
            height={detailedView ? 360 : 176}
            width={detailedView ? '100%' : 168}
            src={skuModel.thumbnail.length > 0 ? skuModel.thumbnail : placeholder}
          />
          <Box mx={2} my={1}>
            <Text variant="caption" color="#ABABAB">
              {skuModel.supplierReference.reference}
            </Text>
            <Text variant="small">{skuModel.designation}</Text>
          </Box>
        </Box>
        {!detailedView && (
          <Box mx={2} mb={1}>
            {skuModel.quantity > 0 && (
              <Text color="turquoise" variant="smallBold">
                {skuModel.quantity}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SkuModelInfos;
