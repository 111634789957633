import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'FaroWeb-RegularLucky',
    color: '#2B3B5C',
    h1: {
      height: 28,
      fontSize: 28,
      paddingLeft: 24,
      fontWeight: 'bold',
      paddingBottom: 8,
      color: '#2B3B5C',
      lineHeight: '150%',
    },
    h5: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: '#8795B3',
      lineHeight: '140%',
    },
    h6: {
      color: '#2B3B5C',
      lineHeight: '150%',
    },
    body1: {
      fontSize: 18,
      lineHeight: '135%',
      color: '#2B3B5C',
    },
    body2: {
      fontWeight: 'bold',
      fontSize: 15,
      lineHeight: '140%',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'FaroWeb-RegularLucky',
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          display: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiFilledInput: {
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          display: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          color: '#2B3B5C',
          '&::placeholder': {
            color: '#8795B3',
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #F7B349',
          },
          '& fieldset': {
            border: '1px solid #DBDFE8',
          },
        },
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '12px 12px 0px 0px',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiTableHead: {
      root: {
        display: 'none',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
      },
    },
    MuiExpansionPanel: {
      root: {
        '&$rounded': {
          'border-bottom': '1px solid #DBDFE8',
          '&:first-child': {
            borderRadius: 0,
            'border-bottom': '1px solid #DBDFE8',
          },
          '&:last-child': {
            borderRadius: 0,
            boxShadow:
              '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
          },
        },
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.13), 0px 0px 3px rgba(0, 0, 0, 0.08);',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        borderBottom: '1px solid #DBDFE8',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 56,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    RaTopToolbar: {
      root: {
        '@media (max-width: 599.95px)': {
          backgroundColor: 'transparent',
          padding: 0,
        },
        '@media(max-width: 959.95px)': {
          paddingRight: 0,
          paddingTop: 24,
        },
      },
    },
    RaListToolbar: {
      toolbar: {
        '@media (max-width: 599.95px)': {
          paddingLeft: 0,
          backgroundColor: 'transparent',
        },
      },
      actions: {
        '@media (max-width: 599.95px)': {
          padding: 0,
          backgroundColor: 'transparent',
        },
      },
    },
    RaFormInput: {
      input: {
        width: 'auto',
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 10000,
        boxShadow:
          '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        boxSizing: 'border-box',
        justifyContent: 'center',
      },
      title: {
        display: 'none',
      },
    },
    RaList: {
      bulkActionsDisplayed: {
        marginTop: 0,
      },
    },
    MuiSelect: {
      filled: {
        textAlign: 'left',
      },
    },
  },
  palette: {
    primary: {
      main: '#F7B349',
    },
    secondary: {
      main: '#FCD660',
    },
  },
});

export default theme;
