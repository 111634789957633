import React from 'react';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { RefurbishingDecisionHistory } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type RefurbishingDecisionBlockProps = {
  decision: RefurbishingDecisionHistory;
};

const RefurbishingDecisionBlock: React.FunctionComponent<RefurbishingDecisionBlockProps> = ({
  decision,
}: RefurbishingDecisionBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const getDecisionDisplayName = (decisionSlug: string): string => {
    switch (decisionSlug) {
      case 'ACCEPT_COLLECTION':
        return 'Collecte acceptée';
      case 'REFUSE_COLLECTION':
        return 'Collecte refusée';
      case 'SCRAP_PRODUCT':
        return 'Mise au rebut';
      default:
        return decisionSlug;
    }
  };

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={decision.data.created_at}
          assignee={decision.data.created_by_id}
          title={getDecisionDisplayName(decision.data.decision)}
          tagVariant={'info'}
        />
      </Box>
      {isOpen && (
        <SimpleBlockContent noSeparator>
          <Box mt={1} mb={1} width="100%" justifyContent="space-between" alignItems="center">
            <Text variant="smallBold" color="dark" textAlign="start">
              {decision.data.payload.reason}
            </Text>
            <Text variant="small" color="dark" textAlign="start">
              {decision.data.payload.comment}
            </Text>
          </Box>
        </SimpleBlockContent>
      )}
    </Box>
  );
};
export default RefurbishingDecisionBlock;
