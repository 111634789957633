import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import dataProvider from 'dataProvider';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import Tag from 'components/atoms/Tag';
import Text from 'components/atoms/Text';

import { Product } from 'types/products';

import { useCustomerFile } from 'utils/api/api';

import PhotoBlock from './AppliancePhotoBlock';
import AssociatedFileBloc from './AssociatedFileBlock';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';
import SimpleBlockField from './SimpleBlocks/SimpleBlockField';
import SimpleBlockTitle from './SimpleBlocks/SimpleBlockTitle';

type ProductInfoBlockProps = {
  product: Product;
} & MarginProps;

const ProductInfoBlock: React.FunctionComponent<ProductInfoBlockProps> = ({
  product,
}: ProductInfoBlockProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { customerFile } = useCustomerFile(product?.customer_file);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateNameplate = (fileList: FileList | null) => {
    setError(null);
    setIsLoading(true);
    const image = fileList?.item(0);
    dataProvider
      .update(
        'products',
        {
          id: product.id,
          data: { id: product.id, identification_plate: image },
        },
        'formData'
      )
      .catch((e) => setError(e.message))
      .finally(() => setIsLoading(false));
  };
  return (
    <Box mt={2}>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <SimpleBlockTitle>
          <Tag variant="primary" text="Information machine" />
          <Text variant="caption" color="grey900">
            {product.delivery_date}
          </Text>
        </SimpleBlockTitle>
      </Box>
      {isOpen && (
        <>
          <SimpleBlockContent noSeparator>
            {product.model_reference && (
              <SimpleBlockField name="Modèle" value={product.model_reference} />
            )}
            {product.product_type && <SimpleBlockField name="Type" value={product.product_type} />}
            {product.subcategory1 && (
              <SimpleBlockField name="Catégorie" value={product.subcategory1} />
            )}

            {product.subcategory2 && (
              <SimpleBlockField name="Sous-catégorie" value={product.subcategory2} />
            )}

            {customerFile?.file_number && (
              <AssociatedFileBloc fileNumber={customerFile?.file_number} />
            )}
            {product.location_name && (
              <SimpleBlockField name="Emplacement" value={product.location_name} />
            )}
          </SimpleBlockContent>
          {!isLoading && (
            <PhotoBlock productId={product.id} updatePicture={updateNameplate} error={error} />
          )}
          {isLoading && (
            <Box bg="white">
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default ProductInfoBlock;
