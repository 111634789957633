import React from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '#fff',
    marginTop: 32,
    justifyContent: 'space-evenly',
    padding: 16,
  },
  textField: {
    width: 150,
    '& label': {
      color: '#2B3B5C',
      lineHeight: '140%',
      letterSpacing: '0.5px',
    },
    '& label + .MuiInput-formControl': {
      color: '#2B3B5C',
      fontSize: 14,
    },
  },
});

export default function IncentiveFilterByDate(props) {
  const classes = useStyles();
  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label="Date de début"
        type="date"
        defaultValue={props.startedDate}
        className={classes.textField}
        onChange={(e) => props.setStartedDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="date"
        label="Date de fin"
        type="date"
        defaultValue={props.endedDate}
        onChange={(e) => props.setEndedDate(e.target.value)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
