import React from 'react';

import { Field, FieldArray, FieldProps } from 'formik';

import Box from 'components/atoms/Box';
import CheckBox from 'components/atoms/CheckBox';
import Text from 'components/atoms/Text';

type FormattedChecklistElement = {
  id: number;
  text: string;
  is_checked: boolean;
};

type RepairProductChecklistProps = {
  checklistType: string;
  values: any;
  title?: string;
  subtitle?: string;
};

interface RestProps {
  index: number;
}

const RepairProductChecklist: React.FunctionComponent<RepairProductChecklistProps> = ({
  checklistType,
  values,
  title,
  subtitle,
}: RepairProductChecklistProps) => {
  const CheckBoxButtonComponent = ({
    field,
    form,
    label,
    ...rest
  }: { label: string } & FieldProps & RestProps) => {
    const { name, value: formikValue } = field;
    const { setFieldValue } = form;
    const values = formikValue || [];
    const handleChange = () => {
      values[rest.index].is_checked = !values[rest.index].is_checked;
      setFieldValue(name, values);
    };
    return (
      <Box>
        <CheckBox
          key={values[rest.index]?.id}
          onClick={handleChange}
          active={values[rest.index]?.is_checked}
          displayNonSelectedIcon
          iconSize="default"
          {...rest}
        >
          <Text margin="auto 8px" variant="small" textAlign="initial">
            {label}
          </Text>
        </CheckBox>
      </Box>
    );
  };
  return (
    <Box mt={2}>
      {title && (
        <Text backgroundColor="white" pt={2} pb={subtitle ? 0 : 2} variant="bodyBold" margin="auto">
          {title}
        </Text>
      )}
      {subtitle && (
        <Text backgroundColor="white" pt={title ? 0 : 2} pb={2} variant="small" margin="auto">
          {subtitle}
        </Text>
      )}
      {
        <FieldArray
          name={checklistType}
          render={() => (
            <>
              {values[checklistType].map((item: FormattedChecklistElement, index: number) => {
                return (
                  <Box key={item.id}>
                    <Field
                      component={CheckBoxButtonComponent}
                      name={checklistType}
                      index={index}
                      value={`${item.id}`}
                      label={`${item.text}`}
                    />
                  </Box>
                );
              })}
            </>
          )}
        />
      }
    </Box>
  );
};

export default RepairProductChecklist;
