import axios from 'axios';
import { END, eventChannel } from 'redux-saga';

function parseJSON(response) {
  return response.data;
}

export function request(config) {
  return axios
    .request(config)
    .then(parseJSON)
    .catch((error) => {
      throw error.response;
    });
}

export function createAuthenticatedUploader(config, token) {
  let emit;
  const channel = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });

  config = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Token ${token}`,
    },
  };

  config.onUploadProgress = (event) => {
    var percentage = Math.round((event.loaded * 100) / event.total);
    if (percentage === 100) {
      emit(END);
    }
    emit(percentage);
  };

  const uploadPromise = axios.request(config);
  return [uploadPromise, channel];
}

export default request;
