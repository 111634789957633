import React from 'react';

import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';
import PageLayout from 'components/templates/PageLayout';

type IsRepairableChoiceProps = {
  submit: (is_repairable: boolean) => void;
  back: () => void;
  selectedValue: boolean | null;
};

export const IsRepairableChoice: React.FunctionComponent<IsRepairableChoiceProps> = ({
  submit,
  back,
  selectedValue,
}: IsRepairableChoiceProps) => {
  return (
    <PageLayout>
      <HeaderWithArrow action={back} title="Réparabilité" />

      <Text m={3} variant="subtitle">
        La machine est-elle réparable ?
      </Text>
      <MultiChoicesButton<boolean>
        choices={[
          { name: 'Oui', value: true },
          { name: 'Non', value: false },
        ]}
        action={submit}
        selectedValue={selectedValue}
      />
    </PageLayout>
  );
};

export default IsRepairableChoice;
