import React from 'react';

import { FlatCardList } from 'components/FlatCard';

import { Visit } from 'types/visits';

import VisitCard from './VisitCard';

type VisitListProps = {
  visitList: Visit[];
};

const VisitList: React.FunctionComponent<VisitListProps> = ({ visitList }: VisitListProps) => {
  return (
    <FlatCardList>
      {visitList.map((visit: Visit) => {
        return <VisitCard visit={visit} key={visit.id} />;
      })}
    </FlatCardList>
  );
};

export default VisitList;
