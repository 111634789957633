import React from 'react';

import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';

export type RadioFieldProps = {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChoiceClick: (event: any) => void;
} & MarginProps;

const StyledLabel = styled.label`
  color: #2b3b5c;
  font-size: 18px;
  display: flex;
  width: 100%;
  font-weight: 400;
  line-height: 135%;
  margin-left: 16px;
`;

const StyledField = styled.input`
  width: 10%;
  height: 24px;
  margin-right: 16px;
  margin-top: 0px;
`;

const RadioField: React.FunctionComponent<RadioFieldProps> = ({
  label,
  value,
  onChoiceClick,
  name,
  checked,
  ...rest
}: RadioFieldProps) => {
  return (
    <Box bg="#F4F3F8" borderRadius="16px" display="flex" padding={16} width="100%" {...rest}>
      <StyledLabel>
        <StyledField
          type="radio"
          checked={checked}
          value={value}
          name={name}
          onClick={(event) => onChoiceClick(event.target)}
        />
        {label}
      </StyledLabel>
    </Box>
  );
};

export default RadioField;
