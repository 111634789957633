import React from 'react';

import FlatCard from 'components/FlatCard';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { Package } from 'types/package';

import { dateToDay } from 'utils/dates';

type PackageFieldProps = {
  sparePartPackage: Package;
};

const RECEIVED = 'received';

const PackageField: React.FunctionComponent<PackageFieldProps> = ({
  sparePartPackage,
}: PackageFieldProps) => {
  const isReceived = (packageStatus: 'to_prepare' | 'prepared' | 'sent' | 'received') => {
    return packageStatus === RECEIVED;
  };

  return (
    <FlatCard mb={1} variant={isReceived(sparePartPackage.status) ? 'secondary' : 'primary'}>
      <Box padding={1}>
        <Text variant="bodyBold">{dateToDay(sparePartPackage.sent_at)}</Text>
        {isReceived(sparePartPackage.status) && (
          <Text variant="small">{`${sparePartPackage.number_skus} pièce(s) - ${sparePartPackage.number_installed} posée(s)`}</Text>
        )}
      </Box>
    </FlatCard>
  );
};

export default PackageField;
