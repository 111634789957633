import React from 'react';

import { Filter, SearchInput } from 'react-admin';

import { ThemeProvider } from '@material-ui/core/styles';

import customerThemeFilter from './customThemeFilter.js';

export function StoreReceptionFilter(props) {
  const handleTextChange = (e) => {
    if (props.onTextInput) {
      props.onTextInput(e);
    }
  };

  return (
    <ThemeProvider theme={customerThemeFilter}>
      <Filter {...props}>
        <SearchInput
          source="q"
          id={`search-input`}
          alwaysOn
          placeholder="Rechercher"
          onChange={(e) => handleTextChange(e)}
          inputProps={{
            style: {
              paddingTop: 20,
            },
          }}
        />
      </Filter>
    </ThemeProvider>
  );
}

export default StoreReceptionFilter;
