import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import dataProvider from 'dataProvider';
import { Field, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import Image from 'components/atoms/Image';
import ButtonBlock from 'components/molecules/ButtonBlock';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ImageSelector from 'components/molecules/ImageSelector';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

type FormValues = {
  nameplate: File[] | null;
};

type ApplianceNameplateProps = {
  onSuccess: () => void;
  productId: string;
};

const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ApplianceNameplate: React.FunctionComponent<ApplianceNameplateProps> = ({
  productId,
  onSuccess,
}: ApplianceNameplateProps) => {
  const classes = useStyles();
  const initialValues: FormValues = {
    nameplate: null,
  };
  const validationSchema = yup.object().shape({
    nameplate: yup.mixed().required(),
  });
  const renderImage = (image: File[]) => {
    return <Image className={classes.img} src={URL.createObjectURL(image[0])} alt="preview" />;
  };

  const updateProduct = (values: FormValues) => {
    dataProvider
      .update(
        'products',
        {
          id: parseInt(productId),
          data: { id: productId, identification_plate: values.nameplate?.[0] },
        },
        'formData'
      )
      .then(() => {
        onSuccess();
      });
  };
  return (
    <PageLayout>
      <GoBackHeader title="Identification" />
      <Formik
        initialValues={initialValues}
        onSubmit={updateProduct}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isValid, dirty }) => (
          <StyledForm>
            <ContentContainer>
              <Field data-testid="nameplate-input" name="nameplate">
                {({ field: { name, value }, form }: FieldProps) => {
                  return value ? (
                    renderImage(value)
                  ) : (
                    <ImageSelector
                      name={name}
                      onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
                        form.setFieldValue('nameplate', event.currentTarget.files)
                      }
                    />
                  );
                }}
              </Field>
            </ContentContainer>
            <ButtonBlock disabled={!isValid || !dirty}>Soumettre</ButtonBlock>
          </StyledForm>
        )}
      </Formik>
    </PageLayout>
  );
};

const useStyles = makeStyles({
  img: {
    width: '100%',
  },
});

export default ApplianceNameplate;
