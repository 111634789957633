// React imports
import React from 'react';

// Icons
import { Paper, Typography } from '@material-ui/core';
// Component imports
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import RepairReportNameplateStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportNameplateStyle';

// Types
import { TaskName } from 'types/tasks';

type RepairReportEndScreenProps = {
  task?: TaskName;
  setNextStep: (step: string) => void;
};
const useStyles = makeStyles(RepairReportNameplateStyle);

const getSentences = (task: TaskName | undefined) => {
  switch (task) {
    case 'cleaning':
      return ['Le nettoyage a bien été enregistré !', "Cliquer pour revenir à l'écran d'accueil"];
    case 'transfer':
      return ['Le transfert a bien été enregistré !', "Cliquer pour revenir à l'écran d'accueil"];
    case 'workshop_repair':
      return ["C'est dans la boîte !", "Vous allez être redirigé sur l'écran d'accueil"];
    default:
      return ["C'est dans la boîte !", 'Bonne fin de tournée !'];
  }
};

const RepairReportEndScreen: React.FunctionComponent<RepairReportEndScreenProps> = ({
  task,
  setNextStep,
}: RepairReportEndScreenProps) => {
  const classes = useStyles();

  // enable to come back to first step on click on the paper only for transfer task
  const handleClick = () => {
    if (task === 'cleaning' || task === 'transfer') {
      setNextStep('');
    }
  };

  return (
    <div>
      <Paper className={classes.nameplateContainer} onClick={handleClick}>
        {
          <div className={classes.confirmationScreenContent}>
            <CheckCircleIcon className={classes.biggerCheckedIcon} />
            <div>
              <Typography variant="h6" gutterBottom>
                {getSentences(task)[0]}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {getSentences(task)[1]}
              </Typography>
            </div>
          </div>
        }
      </Paper>
    </div>
  );
};

export default RepairReportEndScreen;
