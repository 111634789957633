import React from 'react';

import { CheckCircle } from '@material-ui/icons';

import styled from 'styled-components';

import { ASWO } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { DisassemblyHistory } from 'types/product-history';
import { SkuModelReferential } from 'types/sku-model-referential';
import { SkuSupplierReference } from 'types/sku-supplier-references';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type DisassemblyBlockProps = {
  disassembly: DisassemblyHistory;
};

const TurquoiseCheck = styled(CheckCircle)((props) => ({
  color: props.theme.colors.turquoise,
  height: 24,
  width: 24,
}));

const DisassemblyBlock: React.FunctionComponent<DisassemblyBlockProps> = ({
  disassembly,
}: DisassemblyBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const nbParts = disassembly.data.disassembled_parts.length;
  const totalPrice = disassembly.data.disassembled_parts.reduce((acc, item) => {
    const skuModelPrice =
      item.supplier_references.find(
        (skuModelReference: SkuSupplierReference) => skuModelReference.supplier_name == ASWO
      )?.price || 0;
    return acc + skuModelPrice;
  }, 0);

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={disassembly.data.created_at}
          assignee={disassembly.data.assignee}
          title={'Désassemblage'}
          tagVariant={'info'}
        />
      </Box>
      {isOpen && (
        <>
          {nbParts > 0 && (
            <SimpleBlockContent noSeparator>
              {disassembly.data.disassembled_parts.map((skuModel: SkuModelReferential) => (
                <Box width="100%" mt={1} key={skuModel.id}>
                  <Box justifyContent="space-between" width="100%" display="flex">
                    <Box>
                      <Text variant="smallBold">{skuModel.designation}</Text>
                    </Box>
                    <TurquoiseCheck />
                  </Box>
                  <Text variant="small" color="grey500">
                    {skuModel.supplier_references.find(
                      (skuModelReference: SkuSupplierReference) =>
                        skuModelReference.supplier_name == ASWO
                    )?.reference || 'Pas de référence'}
                  </Text>
                </Box>
              ))}
              <Box
                pt={2}
                width="100%"
                pb={1}
                margin="auto"
                display="flex"
                justifyContent="space-between"
              >
                <Text color="turquoise" variant="body">
                  {nbParts} pièce{nbParts > 1 && 's'}
                </Text>
                <Text variant="subtitleBold">{(totalPrice / 3 / 100).toFixed(2)} €</Text>
              </Box>
            </SimpleBlockContent>
          )}
          {nbParts < 1 && (
            <SimpleBlockContent noSeparator>
              <Text variant="body">Aucune pièce récupérée</Text>
            </SimpleBlockContent>
          )}
        </>
      )}
    </Box>
  );
};
export default DisassemblyBlock;
