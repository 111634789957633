import React from 'react';

import DeviceSelection from 'components/DeviceSelection';
import Loader from 'components/Loader';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import PageLayout from 'components/templates/PageLayout';

import { useAswoDevices } from 'utils/api/api';

type DeviceListProps = {
  productReference: string;
  onSubmit: (deviceId: string) => void;
  goBack: () => void;
  selectedDeviceId: string;
};

const DeviceList: React.FC<DeviceListProps> = ({
  productReference,
  onSubmit,
  goBack,
  selectedDeviceId,
}: DeviceListProps) => {
  const { devices, isSuccess } = useAswoDevices(productReference);

  return (
    <PageLayout>
      <HeaderWithArrow title="Identification" action={goBack} />
      {isSuccess ? (
        devices.length > 0 ? (
          <DeviceSelection
            potentialDevices={devices}
            handleSubmit={(device_id: string) => {
              onSubmit(device_id);
            }}
            selectedDeviceId={selectedDeviceId}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={8}
            flexDirection="column"
          >
            <Text variant="smallBold">référence: {productReference}</Text>
            <Text color="red" variant="smallBold">
              Modèle de machine inconnu chez aswo
            </Text>
          </Box>
        )
      ) : (
        <Loader />
      )}
    </PageLayout>
  );
};

export default DeviceList;
