import React from 'react';

// Libs
import { useHistory } from 'react-router-dom';

// Components
import { FlatCardList } from 'components/FlatCard';
import ContentContainer from 'components/templates/ContentContainer';

// Types and utils
import { EmployeeWithPoints } from 'types/employees';

import RepairmanIncentiveCard from './RepairmanIncentiveCard';

type RepairmanPerformanceTabProps = {
  repairmanListWithPoints: EmployeeWithPoints[];
};

const RepairmanPerformanceTab: React.FunctionComponent<RepairmanPerformanceTabProps> = ({
  repairmanListWithPoints,
}: RepairmanPerformanceTabProps) => {
  const history = useHistory();

  const onCardClick = (repairmanId: number) => {
    history.push(`/team/incentives/${repairmanId}`);
  };

  return (
    <ContentContainer>
      <FlatCardList>
        {repairmanListWithPoints.map((repairman) => (
          <RepairmanIncentiveCard
            onClick={() => onCardClick(repairman.id)}
            name={`${repairman.first_name} ${repairman.last_name}`}
            points={repairman.points}
            key={repairman.id}
          />
        ))}
      </FlatCardList>
    </ContentContainer>
  );
};

export default RepairmanPerformanceTab;
