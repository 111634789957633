import React from 'react';

import Box from 'components/atoms/Box';

import OffCanvasBody from './OffCanvasBody';
import OffCanvasMenu from './OffCanvasMenu';

('use strict');

export type BaseOffCanvasProps = {
  width: number | undefined;
  transitionDuration: number;
  isMenuOpened: boolean;
  position: 'left' | 'right';
  effect: 'overlay' | 'push' | 'parallax';
};

export type OffCanvasProps = {
  body?: React.ReactNode;
  menu?: React.ReactNode;
} & BaseOffCanvasProps;

export type OffCanvasContentProps = {
  children: React.ReactNode;
} & BaseOffCanvasProps;

const OffCanvas: React.FunctionComponent<OffCanvasProps> = ({
  width = 250,
  transitionDuration = 250,
  isMenuOpened = false,
  position = 'left',
  effect = 'push',
  body,
  menu,
}: OffCanvasProps) => {
  return (
    <Box position="relative" zIndex={1}>
      {body && (
        <OffCanvasBody
          width={width}
          transitionDuration={transitionDuration}
          isMenuOpened={isMenuOpened}
          position={position}
          effect={effect}
        >
          {body}
        </OffCanvasBody>
      )}
      {menu && (
        <OffCanvasMenu
          width={width}
          transitionDuration={transitionDuration}
          isMenuOpened={isMenuOpened}
          position={position}
          effect={effect}
        >
          {menu}
        </OffCanvasMenu>
      )}
    </Box>
  );
};

export default OffCanvas;
