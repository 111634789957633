import React from 'react';

import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

import Text from 'components/atoms/Text';

const Bullet = styled.li<SpaceProps>(
  (props) => ({ color: props.theme.colors.honey, fontSize: props.theme.fonts[3] }),
  space
);

type BulletPointProps = {
  children?: React.ReactNode;
};

const BulletPoint: React.FunctionComponent<BulletPointProps> = ({ children }: BulletPointProps) => (
  <Bullet mr={1}>
    <Text variant="small">{children}</Text>
  </Bullet>
);

export default BulletPoint;
