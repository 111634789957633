import React from 'react';

/* eslint-disable react/jsx-key */
import { Route } from 'react-router-dom';

import CalendarSwitch from 'pages/Calendar/routes';
import CustomerDetails from 'pages/CustomerDetails';
import CustomerFileHistory from 'pages/CustomerFileHistory/CustomerFileHistory';
import HomeScreen from 'pages/Home/HomeScreen';
import NoContextScreen from 'pages/Home/NoContextScreen';
import Inbox from 'pages/Inbox';
import IncentiveSystem from 'pages/IncentiveSystem/IncentiveSystem';
import Payment from 'pages/Payment/Payment';
import { prequalificationReportRoutes } from 'pages/Prequalification';
import ReconditionRouter from 'pages/Recondition/ReconditionRouter';
import RepairReport from 'pages/RepairReport/RepairReport';
import RepairmanStock from 'pages/RepairmanStock/RepairmanStock';
import Store from 'pages/Store/Store';
import { teamRoutes } from 'pages/Team';
import WorkshopRouter from 'pages/Workshop/WorkshopRouter';
import WorkshopRepairCycleDashboard from 'pages/WorkshopRepairCycle/WorkshopRepairCycleDashboard';
import WorkshopRepairCycleScan from 'pages/WorkshopRepairCycle/WorkshopRepairCycleScan';

export default [
  <Route path="/compte-rendu/:customerFileId/:stepSlug?" component={RepairReport} noLayout />,
  <Route exact path="/customer-details/:customerFileId" component={CustomerDetails} noLayout />,
  <Route path="/workshop" component={WorkshopRouter} noLayout />,
  <Route path="/recondition" component={ReconditionRouter} noLayout />,
  <Route path="/no-context" component={NoContextScreen} noLayout />,
  <Route path="/incentives/:repairmanId" component={IncentiveSystem} noLayout />,
  <Route path="/customer-file/:customerFileId/history" component={CustomerFileHistory} noLayout />,
  <Route path="/store" component={Store} noLayout />,
  <Route path="/home-repair-payment/:customerFileId/:visitId" component={Payment} noLayout />,
  <Route path="/inbox" component={Inbox} noLayout />,
  <Route path="/repairman-stock" component={RepairmanStock} noLayout />,
  <Route exact path="/repair-cycle" component={WorkshopRepairCycleScan} noLayout />,
  <Route exact path="/repair-cycle/:productId" component={WorkshopRepairCycleDashboard} noLayout />,
  <Route path="/calendar" component={CalendarSwitch} noLayout />,
  ...prequalificationReportRoutes,
  ...teamRoutes,
  <Route path="/" component={HomeScreen} noLayout />,
];
