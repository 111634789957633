/* eslint-disable @typescript-eslint/ban-types */
import dataProvider from 'dataProvider';
import { Machine } from 'xstate';

import { contextReducer } from '../ContextReducer';
import { PrequalReportContext, PrequalReportEvent, PrequalReportStateSchema } from '../types';
import {
  additionalInformationIsValid,
  applianceNamePlateIsValid,
  isRepairableIsValid,
  sparePartsNeededIsValid,
  symptomsAndDiagnosticAreValid,
} from '../validators';

const PrequalReportMachine = Machine<
  PrequalReportContext,
  PrequalReportStateSchema,
  PrequalReportEvent
>(
  {
    initial: 'applianceNameplate',
    states: {
      applianceNameplate: {
        on: {
          VALIDATE_NAMEPLATE: {
            target: 'symptomsAndDiagnostic',
            cond: {
              type: 'applianceNamePlateIsValid',
            },
            actions: ['updateContext'],
          },
        },
      },
      isRepairableChoice: {
        on: {
          IS_REPAIRABLE: {
            target: 'sparePartsNeededChoice',
            cond: {
              type: 'isRepairableIsValid',
            },
            actions: ['updateContext'],
          },
          BACK: {
            target: 'symptomsAndDiagnostic',
          },
          IS_NOT_REPAIRABLE: {
            target: 'notRepairableReason',
            cond: {
              type: 'isRepairableIsValid',
            },
            actions: ['updateContext'],
          },
        },
      },
      notRepairableReason: {
        on: {
          NOT_REPAIRABLE_REASON: {
            target: 'additionalInformation',
            actions: ['updateContext'],
          },
          BACK: {
            target: 'isRepairableChoice',
          },
        },
      },
      sparePartsNeededChoice: {
        on: {
          SPARE_PARTS_ARE_NOT_NEEDED: {
            target: 'additionalInformation',
            cond: {
              type: 'sparePartsNeededIsValid',
            },
            actions: ['updateContext'],
          },
          BACK: {
            target: 'isRepairableChoice',
          },
          SPARE_PARTS_ARE_NEEDED: {
            target: 'sparePartsNeed',
            cond: {
              type: 'sparePartsNeededIsValid',
            },
            actions: ['updateContext'],
          },
        },
      },
      sparePartsNeed: {
        on: {
          VALIDATE_SPARE_PART_NEED: {
            target: 'additionalInformation',
            actions: ['updateContext'],
          },
          BACK: {
            target: 'isRepairableChoice',
          },
        },
      },
      symptomsAndDiagnostic: {
        on: {
          VALIDATE_SYMPTOMS_AND_DIAGNOSTIC: {
            target: 'isRepairableChoice',
            cond: {
              type: 'symptomsAndDiagnosticAreValid',
            },
            actions: ['updateContext'],
          },
          BACK: {
            target: 'applianceNameplate',
          },
        },
      },
      additionalInformation: {
        on: {
          SUBMIT: {
            target: 'sumbitting',
            actions: ['updateContext'],
          },
          BACK: [
            {
              target: 'sparePartsNeededChoice',
              cond: {
                type: 'sparePartsInContextNoNeedSparePart',
              },
            },
            {
              target: 'sparePartsNeed',
              cond: {
                type: 'sparePartsInContextNeedSparePart',
              },
            },
            { target: 'notRepairableReason' },
          ],
        },
      },
      sumbitting: {
        invoke: {
          id: 'createReport',
          src: 'createReport',
          onDone: {
            target: 'success',
          },
        },
      },
      success: {},
    },
  },
  {
    actions: {
      updateContext: contextReducer,
    },
    services: {
      createReport: (context) => {
        return dataProvider.create(
          'fault-forecast-report',
          {
            data: {
              ...context,
              need_skus: context.need_skus ?? false,
              intervention: context.intervention,
            },
          },
          'formData'
        );
      },
    },
    guards: {
      applianceNamePlateIsValid: applianceNamePlateIsValid,
      symptomsAndDiagnosticAreValid: symptomsAndDiagnosticAreValid,
      isRepairableIsValid: isRepairableIsValid,
      sparePartsNeededIsValid: sparePartsNeededIsValid,
      additionalInformationIsValid: additionalInformationIsValid,
      sparePartsInContextNoNeedSparePart: (context) =>
        context.need_skus !== null && !context.need_skus,
      sparePartsInContextNeedSparePart: (context) =>
        context.need_skus !== null && context.need_skus,
    },
  }
);

export default PrequalReportMachine;
