import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

const getToken = (): string | null => localStorage.getItem('token');

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKOFFICE_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => response.data);

const fetchData = async <T>(
  method: Method,
  url: string,
  body?: Record<string, unknown>,
  isMultipart = false
): Promise<T> => {
  console.log('fetchData called with:', { method, url, body, isMultipart });

  let data = body;
  let headers = {};

  if (isMultipart && body) {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value);
      }
    });
    data = formData;
    headers = { 'Content-Type': 'multipart/form-data' };
  }

  const response = await axiosInstance.request<T>({
    method,
    url,
    data,
    headers,
  });

  return response.data;
};

export const get = <T>(url: string): Promise<T> => fetchData<T>('GET', url);

export const post = <T>(
  url: string,
  body: Record<string, unknown>,
  isMultipart = false
): Promise<T> => fetchData<T>('POST', url, body, isMultipart);

export const put = <T>(
  url: string,
  body: Record<string, unknown>,
  isMultipart = false
): Promise<T> => fetchData<T>('PUT', url, body, isMultipart);

export const destroy = <T>(url: string): Promise<T> => fetchData<T>('DELETE', url);
