import React from 'react';

import Text from 'components/atoms/Text';

type FormErrorMessageProps = {
  children?: React.ReactNode;
};

const FormErrorMessage: React.FunctionComponent<FormErrorMessageProps> = ({
  children,
}: FormErrorMessageProps) => {
  return (
    <Text variant="buttonSmall" color="pink700" pl={2}>
      {children}
    </Text>
  );
};

export default FormErrorMessage;
