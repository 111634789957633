import dayjs from 'dayjs';

import {
  CleaningTaskHistory,
  ProductCheckListReportHistory,
  ProductHistory,
  RepairReportHistory,
  SectionRepairHistory,
  SparePartFittingHistory,
  SparePartRegistration,
  SparePartRegistrationHistory,
} from 'types/product-history';

import {
  CLEANING,
  OVERALL_APPAREANCE,
  OVERALL_CONDITION,
  PRODUCT_CHECK_LIST_REPORT,
  REPAIR_ACTION,
  REPAIR_REPORT,
  SECTION_PRESENCE,
  SECTION_REPAIR,
  SPARE_PART_CLEANLINESS,
  SPARE_PART_FITTING,
  SPARE_PART_REGISTRATION_REPORT,
  STARTING_UP,
  WIRES_AND_PIPES,
} from '../RepairCycleUtils';

interface GroupedRepairActionByKey {
  [key: string]: (
    | SectionRepairHistory
    | SparePartFittingHistory
    | RepairReportHistory
    | ProductCheckListReportHistory
    | SparePartRegistration
  )[];
}

/**
 * regroup events by their done_at value
 * @param  {[ SectionRepairHistory | SparePartFittingHistory | RepairReportHistory | ProductCheckListReportHistory | SparePartRegistration]} theList  a product history list of events
 * @return {GroupedRepairActionByKey} regrouped events under their done at as object keys
 */
function groupTimesBy(
  theList: [
    | SectionRepairHistory
    | SparePartFittingHistory
    | RepairReportHistory
    | ProductCheckListReportHistory
    | SparePartRegistration
  ],
  timeUnit: 'minute' | 'hour' | 'day' | 'week'
) {
  const toReturn = {} as GroupedRepairActionByKey;
  for (const listItem of theList) {
    const date = dayjs(listItem.data.done_at).valueOf();
    const paramName = dayjs(date).startOf(timeUnit).valueOf();
    if (toReturn[paramName] == null) {
      toReturn[paramName] = [];
    }
    toReturn[paramName].push(listItem);
  }
  return toReturn;
}

/**
 * Filter, organize, reassemble the section repairs and spare part fittings
 * when done in the same range of time by the same employee.
 * @param  {ProductHistory} productHistoryData  a product history list of events
 * @return {Array(SectionRepairHistory | SparePartFittingHistory)[]}Properly formatted RepairActionhistory events
 */
export const formatActionRepairs = (
  repairActions: [SectionRepairHistory | SparePartFittingHistory]
): (SectionRepairHistory | SparePartFittingHistory)[] => {
  const eventMinuteGrouped = groupTimesBy(
    repairActions as [SectionRepairHistory | SparePartFittingHistory],
    'minute'
  );
  const repairActionsGrouped: (SectionRepairHistory | SparePartFittingHistory | any)[] = [];

  Object.keys(eventMinuteGrouped).forEach((key) => {
    const groupedSameTimeEvents = [];
    eventMinuteGrouped[key].map((event: SectionRepairHistory | SparePartFittingHistory | any) => {
      const indexOfExistingEvent = groupedSameTimeEvents.findIndex(
        (element: SectionRepairHistory | SparePartFittingHistory) =>
          element.data.assignee === event.data.assignee
      );
      if (indexOfExistingEvent !== -1) {
        if (event.type === SECTION_REPAIR) {
          groupedSameTimeEvents[indexOfExistingEvent].data.section_repairs.push(event.data);
        } else if (event.type === SPARE_PART_FITTING) {
          groupedSameTimeEvents[indexOfExistingEvent].data.spare_part_fittings.push(event.data);
        }
      } else {
        const section_repairs = [];
        const spare_part_fittings = [];
        if (event.type === SECTION_REPAIR) {
          section_repairs.push(event.data);
        } else if (event.type === SPARE_PART_FITTING) {
          spare_part_fittings.push(event.data);
        }
        groupedSameTimeEvents.push({
          type: REPAIR_ACTION,
          data: {
            id: event.data.id,
            section_repairs: section_repairs,
            spare_part_fittings: spare_part_fittings,
            created_at: dayjs(parseInt(key)).toISOString(),
            done_at: dayjs(parseInt(key)).toISOString(),
            assignee: event.data.assignee,
          },
        });
      }
    });
    repairActionsGrouped.push([...groupedSameTimeEvents]);
  });
  return repairActionsGrouped.flat();
};

/**
 * Filter, organize, reassemble the repair reports and checklists
 * when done in the same range of time by the same employee.
 * @param  {ProductHistory} productHistoryData  a product history list of events
 * @return {Array(RepairReportHistory | ProductCheckListReportHistory)[]}Properly formatted RepairActionhistory events
 */

export const formatRepairReports = (
  historyEvents: [RepairReportHistory | ProductCheckListReportHistory]
): (RepairReportHistory | ProductCheckListReportHistory)[] => {
  const eventMinuteGrouped = groupTimesBy(
    historyEvents as [RepairReportHistory | ProductCheckListReportHistory],
    'minute'
  );
  const eventsGroupped: (SectionRepairHistory | SparePartFittingHistory | any)[] = [];

  Object.keys(eventMinuteGrouped).forEach((key) => {
    const groupedSameTimeEvents = [];
    eventMinuteGrouped[key].map(
      (event: RepairReportHistory | ProductCheckListReportHistory | any) => {
        const indexOfExistingEvent = groupedSameTimeEvents.findIndex(
          (element: RepairReportHistory | ProductCheckListReportHistory) =>
            element.data.assignee === event.data.assignee
        );
        if (indexOfExistingEvent !== -1) {
          if (event.type === PRODUCT_CHECK_LIST_REPORT) {
            groupedSameTimeEvents[indexOfExistingEvent].data.checklists.push(event.data);
          }
          if (event.type === REPAIR_REPORT) {
            groupedSameTimeEvents[indexOfExistingEvent].data.repair_outcome = event.data;
          }
        } else {
          let repair_report = {};
          const checklists = [];
          if (event.type === REPAIR_REPORT) {
            repair_report = event.data;
          } else if (event.type === PRODUCT_CHECK_LIST_REPORT) {
            checklists.push(event.data);
          }
          groupedSameTimeEvents.push({
            type: REPAIR_REPORT,
            data: {
              id: event.data.id,
              repair_outcome: repair_report,
              checklists: checklists,
              created_at: event.data.created_at,
              done_at: dayjs(parseInt(key)).toISOString(),
              assignee: event.data.assignee,
            },
          });
        }
      }
    );
    eventsGroupped.push([...groupedSameTimeEvents]);
  });

  return eventsGroupped.flat();
};

/**
 * Filter, organize, reassemble the spare part registration events
 * when done in the same range of time by the same employee.
 * @param  {ProductHistory} productHistoryData  a product history list of events
 * @return {Array(SparePartRegistrationHistory)[]} Properly formatted SparePartRegistrationHistory events
 */

const formatSparePartRegistrations = (
  sparePartRegistrations: SparePartRegistration[]
): SparePartRegistrationHistory[] => {
  const eventMinuteGrouped = groupTimesBy(
    sparePartRegistrations as [SparePartRegistration],
    'minute'
  );
  const repairActionsGrouped: any[] = [];

  Object.keys(eventMinuteGrouped).forEach(function (key) {
    const groupedSameTimeEvents: any[] = [];
    eventMinuteGrouped[key].map((event: any) => {
      const indexOfExistingEvent = groupedSameTimeEvents.findIndex(
        (element: SparePartRegistration) => element.data.assignee === event.data.assignee
      );
      if (indexOfExistingEvent !== -1) {
        groupedSameTimeEvents[indexOfExistingEvent].data.spare_part_registrations.push(
          event.data.disassembly_sku_model
        );
      } else {
        groupedSameTimeEvents.push({
          type: SPARE_PART_REGISTRATION_REPORT,
          data: {
            spare_part_registrations: [event.data.disassembly_sku_model],
            created_at: event.data.created_at,
            done_at: dayjs(parseInt(key)).toISOString(),
            assignee: event.data.assignee,
            state: event.data.state,
          },
        });
      }
    });
    repairActionsGrouped.push([...groupedSameTimeEvents]);
  });

  return repairActionsGrouped.flat();
};

export const formatCleaningTasks = (
  historyEvents: [CleaningTaskHistory | ProductCheckListReportHistory]
): (CleaningTaskHistory | ProductCheckListReportHistory)[] => {
  const eventMinuteGrouped = groupTimesBy(
    historyEvents as [CleaningTaskHistory | ProductCheckListReportHistory],
    'minute'
  );
  const eventsGroupped: any[] = [];

  Object.keys(eventMinuteGrouped).forEach((key) => {
    const groupedSameTimeEvents: any[] = [];
    eventMinuteGrouped[key].map(
      (event: RepairReportHistory | ProductCheckListReportHistory | any) => {
        const indexOfExistingEvent = groupedSameTimeEvents.findIndex(
          (element: RepairReportHistory | ProductCheckListReportHistory) =>
            element.data.assignee === event.data.assignee
        );
        if (indexOfExistingEvent !== -1) {
          if (event.type === PRODUCT_CHECK_LIST_REPORT) {
            groupedSameTimeEvents[indexOfExistingEvent].data.checklists.push(event.data);
          }
          if (event.type === CLEANING) {
            groupedSameTimeEvents[indexOfExistingEvent].data.repair_outcome = event.data;
          }
        } else {
          let cleaning_task = {};
          const checklists = [];
          if (event.type === CLEANING) {
            cleaning_task = event.data;
          } else if (event.type === PRODUCT_CHECK_LIST_REPORT) {
            checklists.push(event.data);
          }
          groupedSameTimeEvents.push({
            type: CLEANING,
            data: {
              id: event.data.id,
              cleaning_task: cleaning_task,
              checklists: checklists,
              created_at: event.data.created_at,
              done_at: dayjs(parseInt(key)).toISOString(),
              assignee: event.data.assignee,
            },
          });
        }
      }
    );
    eventsGroupped.push([...groupedSameTimeEvents]);
  });

  return eventsGroupped.flat();
};

export const reorderProductHistory = (productData: ProductHistory): ProductHistory => {
  return productData.sort(function (a, b) {
    return a.data.created_at > b.data.created_at
      ? -1
      : a.data.created_at < b.data.created_at
      ? 1
      : 0;
  });
};

export const formatProductHistory = (productHistoryData: ProductHistory): ProductHistory | null => {
  // formatting action repairs to regroup them under the same object
  const formattedActionRepairs = formatActionRepairs(
    (productHistoryData.filter(
      (event) => event.type === SECTION_REPAIR || event.type === SPARE_PART_FITTING
    ) as [SectionRepairHistory | SparePartFittingHistory]) || []
  );

  // formatting repair reports and product checklists reports under the same object
  const checklists = productHistoryData.filter(
    (event) => event.type === PRODUCT_CHECK_LIST_REPORT
  ) as ProductCheckListReportHistory[];

  // repair report checklists
  const repairReportChecklists = checklists.filter((checklist: ProductCheckListReportHistory) =>
    [OVERALL_CONDITION, SECTION_PRESENCE].includes(checklist.data.checklist)
  ) as ProductCheckListReportHistory[];
  const repairReports = productHistoryData.filter(
    (event) => event.type === REPAIR_REPORT
  ) as RepairReportHistory[];

  const formattedRepairReports = formatRepairReports([
    ...repairReportChecklists,
    ...repairReports,
  ] as [ProductCheckListReportHistory | RepairReportHistory]);

  // cleaning task checklists
  const cleaningTaskChecklists = checklists.filter(
    (checklist: ProductCheckListReportHistory) =>
      [OVERALL_APPAREANCE, SPARE_PART_CLEANLINESS, WIRES_AND_PIPES, STARTING_UP].includes(
        checklist.data.checklist
      ) as unknown as ProductCheckListReportHistory[]
  );
  const cleaningTasks = productHistoryData.filter(
    (event) => event.type === CLEANING
  ) as RepairReportHistory[];

  const formatttedCleaningTasks = formatCleaningTasks([
    ...cleaningTaskChecklists,
    ...cleaningTasks,
  ] as [ProductCheckListReportHistory | CleaningTaskHistory]);

  // format spare part registration checklists
  const sparePartRegistrations = formatSparePartRegistrations(
    productHistoryData.filter(
      (event) => event.type === SPARE_PART_REGISTRATION_REPORT
    ) as SparePartRegistration[]
  );

  // replacing values by their formatted counterpart
  let parsedData = productHistoryData.filter(
    (event) =>
      ![
        SPARE_PART_FITTING,
        SECTION_REPAIR,
        REPAIR_REPORT,
        CLEANING,
        SPARE_PART_REGISTRATION_REPORT,
      ].includes(event.type)
  );
  parsedData = parsedData.filter(
    (val: any) => !repairReportChecklists.includes(val) && !cleaningTaskChecklists.includes(val)
  );

  productHistoryData = [
    ...formattedActionRepairs,
    ...formattedRepairReports,
    ...formatttedCleaningTasks,
    ...sparePartRegistrations,
    ...parsedData,
  ] as ProductHistory;

  const reorderedProductHistory = reorderProductHistory(productHistoryData);
  return reorderedProductHistory || [];
};
