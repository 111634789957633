import React, { useState } from 'react';

import {
  PACKAGE_STATUS_RECEIVED,
  SPARE_PART_LOSS_REASON_REPAIRMAN_RECEPTION,
  SPARE_PART_STATUS_LOST,
} from 'components/PackageReception/constants';

import { Package } from 'types/package';
import { SparePart } from 'types/spare-part';

import {
  getSparePartUsingId,
  getSparePartsList,
  patchManySpareParts,
  patchPackage,
  patchSparePart,
} from 'utils/api/api';

import LostSparePartDeclarationList from './components/LostSparePartDeclarationList';
import PackageSelection from './components/PackageSelection/PackageSelection';
import SparePartSelection from './components/SparePartSelection';

type PackageReceptionScanProps = {
  handleClosePackageReception: () => void;
  lossReason?: string;
  lossStatus?: string;
  receivedStatus?: string;
  packageFilter?: Record<string, unknown>;
  packageUpdatePayload?: Record<string, unknown>;
};

const PackageReceptionScan: React.FunctionComponent<PackageReceptionScanProps> = ({
  handleClosePackageReception,
  lossStatus = SPARE_PART_STATUS_LOST,
  lossReason = SPARE_PART_LOSS_REASON_REPAIRMAN_RECEPTION,
  receivedStatus = PACKAGE_STATUS_RECEIVED,
  packageFilter,
  packageUpdatePayload,
}: PackageReceptionScanProps) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package>();
  const [isLostSparePartDeclarationOpen, setIsLostSparePartDeclarationOpen] = useState(false);
  const [potentiallyLostSpareParts, setPotentiallyLostSpareParts] = useState<SparePart[]>([]);
  const [isSparePartSelectionOpen, setIsSparePartSelectionOpen] = useState<boolean>(false);

  const [lostSparePartList, setlostSparePartList] = useState<SparePart[]>([]);
  const [surplusSparePartList, setSurplusSparePartList] = useState<SparePart[]>([]);
  const [sparePartList, setSparePartList] = useState<SparePart[]>([]);
  const [prevTouchInfo, setPrevTouchInfo] = useState(0);

  const [isPackageSelectionOpen, setIsPackageSelectionOpen] = useState(true);

  const handleCloseModal = () => {
    handleClosePackageReception();
  };

  const handleDoubleClickCheck = () => {
    const dt = Date.now() - prevTouchInfo;
    if ((selectedIds.length === 0 && lostSparePartList.length === 0) || dt < 500) {
      handleCloseModal();
    }
    setPrevTouchInfo(Date.now());
  };

  const getSparePartSurplus = async (uniqueId: string) => {
    try {
      const sparePart = await getSparePartUsingId(uniqueId);
      if (sparePart && !selectedIds.includes(sparePart.id)) {
        await patchSparePart(sparePart.id, { package: selectedPackage?.id });
        if (!surplusSparePartList.includes(sparePart)) {
          setSurplusSparePartList((oldArray) => [...oldArray, sparePart]);
          addSparePart(sparePart.id);
        }
      } else {
        console.error('Erreur pièce : ' + uniqueId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addSparePart = (sparePartId: number) => {
    const filteredArray = sparePartList.filter((el) => el.id !== sparePartId);
    setSparePartList(filteredArray);
    setSelectedIds((oldArray) => [...oldArray, sparePartId]);
  };

  const handleValidatePackage = async (selectedPackage: Package) => {
    setSelectedPackage(selectedPackage);
    const spareParts = await getSparePartsList({
      package_id: selectedPackage?.id,
    });
    setSparePartList(spareParts);
    setIsPackageSelectionOpen(false);
    setIsSparePartSelectionOpen(true);
  };

  const handleSavePackage = async (
    selectedSparePartsIds: number[],
    packageSparePartsList: SparePart[]
  ) => {
    if (packageSparePartsList.length === selectedSparePartsIds.length + lostSparePartList.length) {
      try {
        await patchManySpareParts(
          lostSparePartList.map((spareParts: SparePart) => {
            return spareParts.id;
          }),
          {
            status: lossStatus,
            loss_reason: lossReason,
          }
        );
      } catch (e) {
        console.error(e);
      }
      if (selectedPackage) {
        try {
          await patchPackage(selectedPackage.id, {
            status: receivedStatus,
            ...packageUpdatePayload,
          });
        } catch (e) {
          console.error(e);
        }
        handleClosePackageReception();
      }
    } else {
      setPotentiallyLostSpareParts(
        packageSparePartsList.filter((sparePart: SparePart) => !selectedIds.includes(sparePart.id))
      );
      setIsLostSparePartDeclarationOpen(true);
      setIsSparePartSelectionOpen(false);
    }
  };

  const handleCloseLossSparePartDeclaration = () => {
    setIsLostSparePartDeclarationOpen(true);
    setIsSparePartSelectionOpen(true);
  };

  return (
    <>
      {isPackageSelectionOpen && (
        <PackageSelection
          selectedIds={selectedIds}
          handleValidatePackage={handleValidatePackage}
          handleClose={() => handleClosePackageReception()}
          packageFilter={packageFilter}
        />
      )}

      {selectedPackage && isSparePartSelectionOpen && (
        <SparePartSelection
          sparePartList={sparePartList}
          selectedPackage={selectedPackage}
          selectedIds={selectedIds}
          lostSparePartList={lostSparePartList}
          surplusSparePartList={surplusSparePartList}
          addSparePart={addSparePart}
          handleDoubleClickCheck={handleDoubleClickCheck}
          onClose={handleCloseModal}
          handleSavePackage={handleSavePackage}
          getSparePartSurplus={getSparePartSurplus}
        />
      )}

      {isLostSparePartDeclarationOpen && (
        <LostSparePartDeclarationList
          open={isLostSparePartDeclarationOpen}
          setLostSpareParts={(spareParts: SparePart[]) => {
            setlostSparePartList(spareParts);
            handleCloseLossSparePartDeclaration();
          }}
          onClose={handleCloseLossSparePartDeclaration}
          potentiallyLostSpareParts={potentiallyLostSpareParts}
        />
      )}
    </>
  );
};

export default PackageReceptionScan;
