import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    background: '#fff',
    width: '100%',
    bottom: 0,
    marginTop: 100,
  },
  basicButton: {
    margin: '16px',
    height: 42,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    width: '90%',
  },
});

export default function ClaimButton(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${props.classContainerButton}`}>
      <Button
        className={`${classes.basicButton} ${props.classButton}`}
        variant="contained"
        onClick={() => props.handleSubmit()}
        disabled={props.isDisable}
      >
        {props.buttonLabel}
      </Button>
    </div>
  );
}
