import React from 'react';

import dayjs from 'dayjs';

import Text from 'components/atoms/Text';

type DailyInterventionsCounterProps = {
  visitCount: number;
  date: string;
};

const DailyInterventionsCounter: React.FunctionComponent<DailyInterventionsCounterProps> = ({
  date,
  visitCount,
}: DailyInterventionsCounterProps) => {
  const weekDay = dayjs(date).locale('fr').format('dddd');

  return (
    <Text variant="small" mb={2}>
      {`${weekDay.toUpperCase()} - ${visitCount} Interventions`}
    </Text>
  );
};

export default DailyInterventionsCounter;
