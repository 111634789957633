import dayjs from 'dayjs';

import { Visit } from 'types/visits';
import { BaseVisit } from 'types/visits';

import { compareDate } from 'utils/dates';

export const compareVisit = (visit1: Visit, visit2: Visit): number => {
  const pattern = /^\d+$/;
  if (
    dayjs(visit1.timeslot.beginning).format('YYYY-MM-DD-HH') !==
    dayjs(visit2.timeslot.beginning).format('YYYY-MM-DD-HH')
  )
    return compareDate(new Date(visit2.timeslot.beginning), new Date(visit1.timeslot.beginning));

  if (pattern.test(visit1.order) && pattern.test(visit2.order)) {
    return parseInt(visit1.order) - parseInt(visit2.order);
  }
  return pattern.test(visit1.order) ? -1 : 1;
};

export const getMostRecentVisit = (visits: Visit[]): Visit | undefined => {
  const orderedVisits = [...visits].sort(compareVisit);
  return orderedVisits[orderedVisits.length - 1];
};

export const getVisitAddress = (visit: BaseVisit): string => {
  return `${visit.street_number}, ${visit.street} ${visit.postal_code} ${visit.city}`;
};

export const compareSevenOpteamActivity = (visit1: Visit, visit2: Visit): number => {
  return parseInt(visit1.order) - parseInt(visit2.order);
};
