import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import StoreStyles from 'pages/Store/StoreStyles';

import Box from 'components/atoms/Box';

import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

import SparePartTransferToNewLocation from './SparePartTransferToNewLocation';
import TransferSkuAddition from './TransferSkuAddition';

const storeStyles = makeStyles(StoreStyles);

type SparePartTransferStepProps = {
  resetSkus: () => void;
  onCloseLocationModal: () => void;
  isLocationModalOpen: boolean;
  setNextStep: (step: number) => void;
  addSkuInForm: (sparePart: SparePart) => void;
  removeSku: (skuId: number) => void;
  addLocation: (location: SkuLocation) => void;
  skus: SparePart[];
  nbSkus: number;
  openLocationModal: () => void;
};

const SparePartTransferStep: React.FunctionComponent<SparePartTransferStepProps> = ({
  resetSkus,
  onCloseLocationModal,
  isLocationModalOpen,
  setNextStep,
  addSkuInForm,
  removeSku,
  addLocation,
  skus,
  nbSkus,
  openLocationModal,
}: SparePartTransferStepProps) => {
  const storeClasses = storeStyles();

  return (
    <Box display="grid">
      <TransferSkuAddition
        addSkuInForm={addSkuInForm}
        removeSku={removeSku}
        skus={skus}
        nbSkus={nbSkus}
        openLocationModal={openLocationModal}
        storeClasses={storeClasses}
      />
      <SparePartTransferToNewLocation
        setNextStep={setNextStep}
        addLocation={addLocation}
        skus={skus}
        open={isLocationModalOpen}
        onClose={onCloseLocationModal}
        resetSkus={resetSkus}
      />
    </Box>
  );
};

export default SparePartTransferStep;
