import React, { useState } from 'react';

import OffCanvas from 'components/OffCanvas/OffCanvas';

import CanvasBody from './components/CanvasBody';
import CanvasMenu from './components/CanvasMenu';

type PageHeaderProps = {
  title: string;
  noPadding?: boolean;
  mention?: React.ReactNode;
};

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  noPadding,
  mention,
}: PageHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <OffCanvas
      width={document?.getElementById('root')?.offsetWidth}
      transitionDuration={0}
      effect={'overlay'}
      isMenuOpened={menuOpen}
      position={'left'}
      body={
        <CanvasBody
          title={title}
          noPadding={noPadding}
          mention={mention}
          onClick={() => setMenuOpen(true)}
        />
      }
      menu={menuOpen && <CanvasMenu onClose={() => setMenuOpen(false)} />}
    />
  );
};

export default PageHeader;
