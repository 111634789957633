import { UseQueryResult, useQuery } from 'react-query';

import { SparePart } from 'types/spare-part';

import { get } from 'utils/api/http';
import { sortObjByKey } from 'utils/objects';

import { groupBy } from './utils';

export const useSparePartToPackFor = (filterSpec: string): UseQueryResult<SparePart[]> => {
  const url = `/operations-data-provider/cobble-flow-read-api-proxy/${filterSpec}`;

  return useQuery<SparePart[], Error>(url, async () => await get<SparePart[]>(url));
};

export const useSparePartToPackForWorkshop = (): UseQueryResult<SparePart[]> => {
  const filterSpec =
    'murfy_erp_sparepart?status=eq.instock&murfy_erp_demanditem.status=neq.packed&murfy_erp_demanditem.demand.status=in.(waiting_for_packing,waiting_for_sku)&murfy_erp_demanditem.demand.murfy_erp_workshopskudemand.common_workshop(workshop_name=not.is.null)&select=*,...murfy_erp_demanditem!inner(demand_item:id,demand:murfy_erp_skudemand!inner(id, ...murfy_erp_workshopskudemand!inner(...common_workshop(workshop_name:name)))),location:murfy_erp_skulocation(code),sku_model:murfy_erp_skumodel(designation, supplier, supplier_reference, sku_reference),...sku_sku(type,...sku_skumodel(thumbnail))';

  return useSparePartToPackFor(filterSpec);
};

export const useSparePartToPackForEmployee = (): UseQueryResult<SparePart[]> => {
  const filterSpec =
    'murfy_erp_sparepart?status=eq.instock&murfy_erp_demanditem.status=neq.packed&murfy_erp_demanditem.demand.status=in.(waiting_for_packing,waiting_for_sku)&murfy_erp_demanditem.demand.murfy_erp_homerepairskudemand.common_employee(recipient_id=not.is.null)&select=*,...murfy_erp_demanditem!inner(demand_item:id, demand:murfy_erp_skudemand!inner(id,...murfy_erp_homerepairskudemand!inner(...common_employee(recipient_id:id,... auth_user(id,first_name, last_name))))),location:murfy_erp_skulocation(code),sku_model:murfy_erp_skumodel(designation, supplier, supplier_reference, sku_reference),...sku_sku(...sku_skumodel(thumbnail))';

  return useSparePartToPackFor(filterSpec);
};

const isRecipientMatch = (recipientName: string, filterName: string) => {
  if (!filterName) {
    return true;
  }
  return recipientName?.toLowerCase().includes(filterName.toLowerCase());
};

export const useSparePartToPack = (
  filterRecipientName: string
): {
  loading: boolean;
  sparePartsByEmployee: Record<string, SparePart[]>;
  sparePartsByWorkshop: Record<string, SparePart[]>;
} => {
  const { data: sparePartsForWorkshops, isLoading: loadingWorkshop } =
    useSparePartToPackForWorkshop();
  const { data: sparePartsForEmployees, isLoading: loading } = useSparePartToPackForEmployee();

  const sparePartsMappingWithFullName: SparePart[] =
    sparePartsForEmployees?.map((sparePart) => ({
      ...sparePart,
      demand: {
        ...sparePart.demand,
        full_name: `${sparePart.demand.first_name} ${sparePart.demand.last_name}`,
      },
    })) || [];

  const filteredSparePartsForWorkshops = sparePartsForWorkshops?.filter((sparePart: SparePart) =>
    isRecipientMatch(sparePart.demand.workshop_name, filterRecipientName)
  );
  const filteredSparePartsForEmployees = sparePartsMappingWithFullName?.filter(
    (sparePart: SparePart) => isRecipientMatch(sparePart.demand.full_name, filterRecipientName)
  );

  const sparePartsByWorkshop =
    (filteredSparePartsForWorkshops &&
      sortObjByKey(groupBy(filteredSparePartsForWorkshops, 'workshop_name'))) ||
    {};

  const sparePartsByEmployee =
    filteredSparePartsForEmployees &&
    sortObjByKey(groupBy(filteredSparePartsForEmployees, 'full_name'));

  return {
    loading: loading && loadingWorkshop,
    sparePartsByEmployee,
    sparePartsByWorkshop,
  };
};
