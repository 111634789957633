// React imports
import React, { useEffect, useState } from 'react';

import PrintIcon from '@material-ui/icons/Print';

import styled from 'styled-components';

import PrintContent from 'pages/Store/components/PrintContent';

import PortalComponent from 'components/Portal/PortalComponent';
import ProductGeneralInfoPanel from 'components/ProductGeneralInfoPanel/';
import { TypeformDefaultSideTab } from 'components/TypeformComponents';
import WorkshopFileGeneralInfoPanel from 'components/WorkshopFileGeneralInfoPanel/';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ContentContainer from 'components/templates/ContentContainer';

import { generateQrCode } from 'utils/QrCodeUtils';
import { useWorkshopFile } from 'utils/api/api';
import { useEmployeeAssociatedLocations } from 'utils/api/api';

type WorkshopFileDetailSreenProps = {
  workshopFileId: number;
  handleClosePortal: () => void;
  enableDefaultDeclaration?: boolean;
};

const Container = styled(ContentContainer)({
  padding: 0,
});

const WorkshopFileDetailScreen: React.FunctionComponent<WorkshopFileDetailSreenProps> = ({
  workshopFileId,
  handleClosePortal,
  enableDefaultDeclaration = false,
}: WorkshopFileDetailSreenProps) => {
  const { workshopFile, loadingWorkshopFile } = useWorkshopFile(workshopFileId);
  const { locations } = useEmployeeAssociatedLocations(undefined, undefined);
  const [qrCode, setQrCode] = useState();

  const [isBarCodeModalOpen, setIsBarCodeModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (workshopFile?.product?.barcode) {
      generateQrCode(workshopFile?.product?.barcode, setQrCode);
    }
  }, [workshopFile]);
  if (loadingWorkshopFile) {
    return null;
  }
  return (
    <PortalComponent
      open={true}
      onClose={() => handleClosePortal()}
      title={workshopFile.product.barcode}
    >
      <Container>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          paddingY={0}
          paddingX={0}
          minHeight={0}
          overflow="scroll"
        >
          <Box mt={2}>
            <ProductGeneralInfoPanel product={workshopFile.product} />
          </Box>
          <Box mt={2}>
            <WorkshopFileGeneralInfoPanel
              workshopFile={workshopFile}
              locations={locations as any[]}
            />
          </Box>
          <Box mt={2}>
            <Button height={56} variant="paperStrip" onClick={() => setIsBarCodeModalOpen(true)}>
              <Box display="flex" textAlign="left">
                <PrintIcon />
                <Text ml={2} variant="bodyBold">
                  Imprimer le QR code
                </Text>
              </Box>
            </Button>
            {isBarCodeModalOpen && workshopFile.product.barcode && (
              <PrintContent
                open={isBarCodeModalOpen}
                onClose={() => setIsBarCodeModalOpen(false)}
                qrCode={qrCode}
                handleNextStep={() => setIsBarCodeModalOpen(false)}
                codeSubtitle={workshopFile?.product?.barcode}
              />
            )}
          </Box>
        </Box>
        {enableDefaultDeclaration && (
          <TypeformDefaultSideTab barcode={workshopFile?.product?.barcode} />
        )}
        <BottomButton variant="primary" onClick={() => handleClosePortal()}>
          Retourner à la liste de dossiers
        </BottomButton>
      </Container>
    </PortalComponent>
  );
};

export default WorkshopFileDetailScreen;
