import React from 'react';

import PaperStripUnfoldsWithArrow from 'components/PaperStripUnfoldsWithArrow';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { Location } from 'types/location';
import { WorkshopFile } from 'types/workshop-files';

type WorkshopFileGeneralInfoPanelProps = {
  workshopFile: WorkshopFile;
  locations: Location[];
};

const WorkshopFileGeneralInfoPanel: React.FunctionComponent<WorkshopFileGeneralInfoPanelProps> = ({
  workshopFile,
  locations,
}: WorkshopFileGeneralInfoPanelProps) => {
  const getLocationNameFromId = (id: number | unknown): any => {
    const location = locations?.find((el: any) => el.id === id);
    return location?.name || '';
  };
  const getProductDescription = () => {
    return (
      <>
        {workshopFile.state_name && (
          <Box mb={2}>
            <Text textAlign="left" variant="small" color="grey500">
              Statut
            </Text>
            <Text textAlign="left" variant="small">
              {workshopFile.state_name}
            </Text>
          </Box>
        )}
        {workshopFile.product?.location && (
          <Box mb={2}>
            <Text textAlign="left" variant="small" color="grey500">
              Emplacement
            </Text>
            <Text textAlign="left" variant="small">
              {getLocationNameFromId(workshopFile.product.location)}
            </Text>
          </Box>
        )}
        {workshopFile.product?.delivery_date && (
          <Box>
            <Text textAlign="left" variant="small" color="grey500">
              Date de réception
            </Text>
            <Text textAlign="left" variant="small">
              {workshopFile.product.delivery_date}
            </Text>
          </Box>
        )}
      </>
    );
  };

  return (
    <PaperStripUnfoldsWithArrow
      title="Informations"
      content={getProductDescription()}
      key={workshopFile.id}
    />
  );
};

export default WorkshopFileGeneralInfoPanel;
