export const formatPrice = (priceInCents: number): string => {
  const price = priceInCents / 100;
  return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
};

export const formatDecimal = (value: number): string =>
  Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value);

export const formatAsInt = (value: number): string =>
  Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value);

export const uppercaseFirstLetter = (word: string): string => {
  if (word === '') return '';
  return `${word[0].toUpperCase()}${word.slice(1)}`;
};

const capitalizeName = (name: string): string => {
  if (name.includes('-')) {
    return name
      .split('-')
      .filter((nameChunk) => nameChunk.length > 0)
      .map((nameChunk) => uppercaseFirstLetter(nameChunk))
      .join('-');
  }

  return name
    .split(' ')
    .filter((nameChunk) => nameChunk.length > 0)
    .map((nameChunk) => uppercaseFirstLetter(nameChunk))
    .join(' ');
};

export const formatName = (firstName: string, lastname: string): string =>
  `${capitalizeName(firstName)} ${capitalizeName(lastname)}`;
