import * as yup from 'yup';

export const disassemblyValidationSchema = yup.object({
  quantity: yup.number(),
  sku_model_test: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          is_checked: yup.boolean(),
        })
        .required()
    )
    .when('quantity', {
      is: (quantity: number) => quantity > 0,
      then: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              is_checked: yup.boolean(),
            })
            .required()
        )
        .test(
          'at-least-one-checked',
          'Au moins une vérification doit être faite',
          (skuModelTest: any) => {
            return skuModelTest?.some((test: any) => test.is_checked);
          }
        ),
    }),
});

export const sparePartRegisteringValidationSchema = yup.object({
  quantity: yup.number(),
  sku_model_test: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          is_checked: yup.boolean(),
        })
        .required()
    )
    .when('quantity', {
      is: (quantity: number) => quantity > 0,
      then: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              is_checked: yup.boolean(),
            })
            .required()
        )
        .test(
          'at-least-one-checked',
          'Au moins une vérification doit être faite',
          (skuModelTest: any) => {
            return !skuModelTest?.some((test: any) => !test.is_checked);
          }
        ),
    }),
});
