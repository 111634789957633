import React from 'react';

import { makeStyles } from '@material-ui/core';

import { Widget } from '@typeform/embed-react';
import { useEmployee } from 'employeeContext';

import { Product } from 'types/products';

import { useLocation } from 'utils/api/api';

const FORM_ID = 'hDewVsNC';
const WORKSHOP_RECEPTION = 'workshop_reception';
const REPAIR_PROD = 'repair_prod';

type ScrapTypeformProps = {
  product: Product;
  handleClose: () => void;
};
const useStyles = makeStyles({
  widget: {
    '& > a': {
      fontSize: '40px !important',
      right: '28px !important',
    },
  },
});

const ScrapTypeform: React.FunctionComponent<ScrapTypeformProps> = ({
  product,
  handleClose,
}: ScrapTypeformProps) => {
  const classes = useStyles();
  const { employee } = useEmployee();
  const location = useLocation(product.location as number);
  const getContext = () => {
    if (location?.usage === 'sourcing') return WORKSHOP_RECEPTION;
    if (location?.usage === 'production') return REPAIR_PROD;
    return '';
  };

  return (
    <Widget
      style={{ height: '100vh' }}
      id={FORM_ID}
      className={classes.widget}
      onClose={handleClose}
      onSubmit={handleClose}
      hidden={{
        context_name: getContext(),
        user_email: employee.user.email,
        workshop_file_id: (product.workshop_file || '').toString(),
        product_id: product.id.toString(),
      }}
    />
  );
};

export default ScrapTypeform;
