import React from 'react';

import Box from 'components/atoms/Box';
import BulletList from 'components/atoms/BulletList';
import Text from 'components/atoms/Text';
import BulletPoint from 'components/molecules/BulletPoint';

const NotRepairedDescription: React.FunctionComponent = () => {
  return (
    <Box>
      <Text variant="body" mt={1} mb={1}>
        Identique à la catégorie dans les CR, pour rappel un appareil est considéré non réparable
        dans les 5 cas suivants :
      </Text>
      <BulletList>
        <BulletPoint>Lorsque les roulements d'un lave-linge à hublot sont usés</BulletPoint>
        <BulletPoint>Lorsqu'un sèche-linge à pompe à chaleur ne sèche plus</BulletPoint>
        <BulletPoint>Lorsque la cuve d'un lave vaisselle est percée</BulletPoint>
        <BulletPoint>
          Lorsqu'un réfrigérateur ou congélateur qui fonctionne avec un autre gaz que le R600 a une
          panne liée au circuit hermétique
        </BulletPoint>
        <BulletPoint>
          Lorsqu'un réfrigérateur ou congélateur a une fuite dont le technicien ne peut pas
          identifier l'origine
        </BulletPoint>
      </BulletList>
      <Text variant="body">
        Pour plus de détails, vous pouvez vous référer à la page Notion suivante :
      </Text>
      <a
        href="https://www.notion.so/murfy/Cas-particulier-appareil-non-r-parable-domicile-a86f64f1707f4f1dbdb876ee252278ee"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cas particulier : appareil non réparable à domicile
      </a>
    </Box>
  );
};

export default NotRepairedDescription;
