import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

const StyledRadioLabel = styled(FormControlLabel)({
  margin: 0,
});

const CheckedRadioIcon = styled('span')(({ theme }) => ({
  height: 12,
  width: 12,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 50,
}));

const UncheckedRadioIcon = styled('span')({
  height: 8,
  width: 8,
  backgroundColor: '#C5C5C5',
  borderRadius: 50,
  margin: 2,
});

const StyledRadioGroup = styled(RadioGroup)({
  justifyContent: 'space-between',
});

type LabelProps = { checked: boolean };

const StyledLabel = styled('div')<Theme, LabelProps>(({ theme, checked }) => ({
  borderBottom: checked ? `4px ${theme.palette.secondary.main} solid` : '',
  fontSize: 15,
  paddingBottom: 16,
  width: 40,
  textAlign: 'center',
}));

type DateSelectorProps = {
  selectedDate: string;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DateSelector: React.FunctionComponent<DateSelectorProps> = ({
  selectedDate,
  onSelect,
}: DateSelectorProps) => {
  const getShortWeekday = (date: string) => {
    return dayjs(date).locale('fr').format('dddd').slice(0, 3).toUpperCase();
  };

  /**
   * Function to return the day and month from a date
   * @param {string} date - The date in ISO format (YYYY-MM-DD)
   */
  const getOnlyDayAndMonth = (date: string) => {
    return dayjs(date).format('DD/MM');
  };

  dayjs.extend(weekday);
  const displayedWeek = [1, 2, 3, 4, 5, 6].map((idx) => {
    return dayjs(selectedDate).weekday(idx).format('YYYY-MM-DD');
  });

  return (
    <StyledRadioGroup row onChange={onSelect} value={selectedDate}>
      {displayedWeek.map((dateAsString) => {
        const checked = selectedDate === dateAsString;
        return (
          <StyledRadioLabel
            label={
              <StyledLabel checked={checked}>
                {checked ? getOnlyDayAndMonth(dateAsString) : getShortWeekday(dateAsString)}
              </StyledLabel>
            }
            key={dateAsString}
            value={dateAsString}
            control={<Radio checkedIcon={<CheckedRadioIcon />} icon={<UncheckedRadioIcon />} />}
            labelPlacement="bottom"
          />
        );
      })}
    </StyledRadioGroup>
  );
};

export default DateSelector;
