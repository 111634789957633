import * as yup from 'yup';

import { PrequalReportContext, PrequalReportEvent, PrequalReportState } from './types';

export const validationSchema = yup.object({
  symptoms: yup.string().required(),
  diagnostic: yup.string().required(),
  spare_parts_needed: yup.boolean().required(),
  is_repairable: yup.boolean().required(),
  additional_information: yup.string(),
  identification_plate: yup.mixed().required(),
});

export type ValidationSchema = yup.TypeOf<yup.BaseSchema>;

export const getValidationSchema = (state: PrequalReportState): ValidationSchema => {
  switch (state) {
    case 'symptomsAndDiagnostic':
      return validationSchema.pick(['symptoms', 'diagnostic']);
    case 'additionalInformation':
      return validationSchema.pick(['additional_information']);
    default:
      return validationSchema;
  }
};
// Validators
export const applianceNamePlateIsValid = (
  context: PrequalReportContext,
  event: PrequalReportEvent
): boolean => {
  validationSchema.pick(['identification_plate']).isValidSync(event.data);
  return true;
};

export const isRepairableIsValid = (
  context: PrequalReportContext,
  event: PrequalReportEvent
): boolean => {
  return validationSchema.pick(['is_repairable']).isValidSync(event.data);
};
export const additionalInformationIsValid = (
  context: PrequalReportContext,
  event: PrequalReportEvent
): boolean => {
  return getValidationSchema('additionalInformation').isValidSync(event.data);
};
export const sparePartsNeededIsValid = (
  context: PrequalReportContext,
  event: PrequalReportEvent
): boolean => {
  return validationSchema.pick(['spare_parts_needed']).isValidSync(event.data);
};
export const symptomsAndDiagnosticAreValid = (
  context: PrequalReportContext,
  event: PrequalReportEvent
): boolean => {
  return getValidationSchema('symptomsAndDiagnostic').isValidSync(event.data);
};
