import React, { useState } from 'react';

import dayjs from 'dayjs';

import IncentiveHelpModal from 'pages/EmployeeStatistics/components/IncentiveHelpModal';
import IncentiveStatisticsContainer from 'pages/EmployeeStatistics/components/IncentiveStatisticsContainer';
import StatBlockContainer from 'pages/EmployeeStatistics/components/StatBlockContainer';

import MonthSelector from 'components/organisms/InfiniteMonthSelector';
import PageLayout from 'components/templates/PageLayout';

import { Repairman } from 'types/employees';
import { IncentiveLabel } from 'types/incentive-label';
import { StatisticLabel } from 'types/incentive-statistics';

import { formatAsInt, formatDecimal } from 'utils/formatting';
import { useMonthSelector } from 'utils/useMonthSelector';

import { useEmployeeStatistics, useIncentiveStatistics } from './utils/hooks';

type EmployeeStatisticsProps = {
  repairman: Repairman;
};

const EmployeeStatistics: React.FunctionComponent<EmployeeStatisticsProps> = ({
  repairman,
}: EmployeeStatisticsProps) => {
  const { selectedDate, onMonthChange, startDate, endDate } = useMonthSelector(
    dayjs().format('YYYY-MM-DD'),
    !!repairman?.workshop || !!repairman?.workshop_id
  );
  const { isSuccess: successEmployeeStatistics, data: employeeStatistics } = useEmployeeStatistics(
    selectedDate,
    startDate,
    endDate,
    repairman.id
  );

  const { isSuccess: successIncentiveStatistics, incentiveStatistics } = useIncentiveStatistics(
    startDate,
    endDate,
    repairman.id
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDescription, setIsOpenDescription] = useState(false);

  const getNumberOfLabel = (label: IncentiveLabel, statsList: StatisticLabel[]): number => {
    return statsList?.find((incentive) => incentive.label === label)?.number_of_label || 0;
  };

  if (!successIncentiveStatistics || !successEmployeeStatistics) {
    return null;
  }
  const { statistics } = incentiveStatistics;

  const getPercentageLabel = (label: IncentiveLabel): string => {
    const total =
      incentiveStatistics.total_incentives && incentiveStatistics.total_incentives > 0
        ? incentiveStatistics.total_incentives
        : 1;
    return `${Math.round((getNumberOfLabel(label, statistics) / total) * 100)}%`;
  };

  return (
    <PageLayout height="max-content">
      <MonthSelector onMonthChange={onMonthChange} selectedDate={selectedDate} mt={2} mb={2} />
      <StatBlockContainer
        mb={3}
        percentageOfFinished={getPercentageLabel('finished')}
        numberOfPointsPerDay={formatAsInt(employeeStatistics.number_of_points_per_day)}
        numberOfVisitPerDay={formatDecimal(employeeStatistics.number_of_visit_per_day)}
        numberOfPointsPerVisit={formatAsInt(employeeStatistics.number_of_points_per_visit)}
      />
      <IncentiveStatisticsContainer
        incentiveStatistics={incentiveStatistics}
        mb={2}
        getNumberOfLabel={getNumberOfLabel}
        getPercentageLabel={getPercentageLabel}
      />
      <IncentiveHelpModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isOpenDescription={isOpenDescription}
        setIsOpenDescription={setIsOpenDescription}
        mb={2}
      />
    </PageLayout>
  );
};

export default EmployeeStatistics;
