import React from 'react';

import { Box } from '@material-ui/core';

import ChoiceButton from 'components/atoms/ChoiceButton';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';

import { Supplier } from 'types/supplier';

type SkuSupplierSelectorProps = {
  onChange: (value: string) => void;
  value: string;
  skuSuppliers: Supplier[];
};

const SkuSupplierSelector: React.FunctionComponent<SkuSupplierSelectorProps> = ({
  onChange,
  skuSuppliers,
  value,
}: SkuSupplierSelectorProps) => {
  const chosenSupplier = skuSuppliers.find((item) => item.id.toString() === value);

  return chosenSupplier ? (
    <>
      <MultiChoicesButton<string>
        choices={[{ value: chosenSupplier.id.toString(), name: chosenSupplier.name }]}
        action={onChange}
        selectedValue={value}
      />
      <ChoiceButton mx={2} my={1} onClick={() => onChange('')}>
        <Box textAlign="initial">Modifier</Box>
      </ChoiceButton>
    </>
  ) : (
    skuSuppliers && (
      <MultiChoicesButton<string>
        choices={skuSuppliers.map((x) => {
          return { value: x.id.toString(), name: x.name };
        })}
        action={onChange}
        selectedValue={value}
      />
    )
  );
};

export default SkuSupplierSelector;
