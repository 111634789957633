import { useEffect, useState } from 'react';

import { hasAcquisitionSource } from './api';

type hasAcquisitionSourceReturnValue = {
  isSuccess: boolean;
  hasSource: boolean;
};

export const useHasSource = (customerFileId: string): hasAcquisitionSourceReturnValue => {
  const [hasSource, setHasSource] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const fetchAcquisitionSource = async () => {
      const data = await hasAcquisitionSource(customerFileId);
      if (data) {
        setHasSource(data['has_source']);
      } else {
        setHasSource(false);
      }
      setIsSuccess(true);
    };
    fetchAcquisitionSource();
  }, [customerFileId]);

  return { isSuccess, hasSource };
};
