import React, { useState } from 'react';

import Loader from 'components/Loader';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import PageLayout from 'components/templates/PageLayout';

import { FormSkuModel } from 'types/form-sku-models';
import { Product } from 'types/products';

import { getInitialValues, handleValidateSparePart } from '../ProductDisassemblyTask/utils';
import { postDisassemblyTask, useSkuModels } from '../api';
import SparePartThumbnailsList from './SparePartsThumbnailsList';

type SparePartsThumbnailsProps = {
  product: Product;
  handleClose: () => void;
  requestParams: {
    reference: string;
    brand: string;
    category: string;
    aswoDeviceId: string;
  };
  goBack: () => void;
};
const SparePartsThumbnails: React.FC<SparePartsThumbnailsProps> = ({
  product,
  handleClose,
  requestParams,
  goBack,
}: SparePartsThumbnailsProps) => {
  const [error, setError] = useState<string>();
  const { reference, brand, category, aswoDeviceId } = requestParams;
  const {
    skuModels,
    isSuccess,
    error: onError,
  } = useSkuModels(reference, brand, category, aswoDeviceId);
  const initialValues = getInitialValues(skuModels);

  const handleSubmit = async (values: { sku_models: FormSkuModel[] }) => {
    try {
      setError(undefined);
      const skuModelsIds: number[] = [];
      values.sku_models
        .filter((skuModel) => skuModel.quantity > 0)
        .forEach((skuModel) => {
          for (let i = 0; i < skuModel.quantity; i++) {
            skuModelsIds.push(skuModel.id);
          }
        });

      if (product) {
        await postDisassemblyTask(product.id, skuModelsIds);
      }
      handleClose();
    } catch (error) {
      setError(`Une erreur est survenue lors de sauvegarde de la tâche: \n ${error}`);
      return;
    }
  };

  return (
    <>
      {isSuccess ? (
        <SparePartThumbnailsList
          showQuantitySelector
          product={product}
          handleClose={handleClose}
          goBack={goBack}
          handleSubmit={handleSubmit}
          title="Valorisation"
          error={error || onError}
          handleValidateSparePart={handleValidateSparePart}
          initialValues={initialValues}
        />
      ) : (
        <PageLayout>
          <HeaderWithArrow title="Valorisation" action={goBack} />
          <Loader />
        </PageLayout>
      )}
    </>
  );
};

export default SparePartsThumbnails;
