import React from 'react';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import DailyInterventionsCounter from 'components/molecules/DailyInterventionsCounter';

import { TourStatus } from 'types/tour-status';
import { Visit } from 'types/visits';

import NotStartedTourSummary from './NotStartedTourSummary';
import OverTourSummary from './OverTourSummary';
import StartedTourSummary from './StartedTourSummary';

type TourSummaryProps = {
  visitCount: number;
  remainingVisitCount: number;
  firstVisit: Visit;
  className?: string;
  setRepairmanTourStatus: (tourStatus: TourStatus) => void;
  tourStatus: TourStatus;
  date: string;
  areAllReportsComplete: boolean;
  isLoading: boolean;
};

const TourSummary: React.FunctionComponent<TourSummaryProps> = ({
  visitCount,
  remainingVisitCount,
  firstVisit,
  className,
  setRepairmanTourStatus,
  tourStatus,
  date,
  areAllReportsComplete,
  isLoading,
}: TourSummaryProps) => {
  const isTodaySelected = () => {
    dayjs.extend(isToday);
    const asDate = dayjs(date);
    return asDate.isToday();
  };

  if (!isTodaySelected()) {
    return <DailyInterventionsCounter date={date} visitCount={visitCount} />;
  }

  const renderOnGoingTour = () => {
    return remainingVisitCount > 0 ? (
      <StartedTourSummary
        className={className}
        visitCount={remainingVisitCount}
        buttonAction={() => setRepairmanTourStatus('on_break')}
        buttonLabel="Prendre une pause"
        isLoading={isLoading}
      />
    ) : (
      <StartedTourSummary
        visitCount={remainingVisitCount}
        className={className}
        buttonAction={() => setRepairmanTourStatus('over')}
        buttonLabel="Terminer ma journée"
        disabled={!areAllReportsComplete}
        isLoading={isLoading}
      />
    );
  };

  switch (tourStatus) {
    case 'not_started':
      return (
        <NotStartedTourSummary
          className={className}
          firstVisit={firstVisit}
          visitCount={visitCount}
        />
      );
    case 'ongoing':
      return renderOnGoingTour();
    case 'on_break':
      return (
        <StartedTourSummary
          className={className}
          visitCount={remainingVisitCount}
          buttonAction={() => setRepairmanTourStatus('ongoing')}
          buttonLabel="Reprendre ma tournée"
          isLoading={isLoading}
        />
      );
    case 'over':
      return <OverTourSummary className={className} visitCount={visitCount} />;
    default: // This ensures we do not forget a tourStatus
      ((_: never): void => {})(tourStatus);
      return null;
  }
};

export default TourSummary;
