import { connect } from 'react-redux';

import ReportStepsPlayer from './ReportStepsPlayer';
import { postFormRequest, resetIsPosted } from './module';

function mapStateToProps(state) {
  return {
    isPosted: state.repairReportPlayer.isPosted,
    error: state.repairReportPlayer.error,
    isErrorShown: state.repairReportPlayer.isErrorShown,
    isLoading: state.repairReportPlayer.isLoading,
    formUploadProgress: state.repairReportPlayer.uploadProgress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postForm(data) {
      dispatch(postFormRequest(data));
    },
    resetIsPosted() {
      dispatch(resetIsPosted());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportStepsPlayer);
