import React, { useState } from 'react';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ReplayIcon from '@material-ui/icons/Replay';

import dataProvider from 'dataProvider';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SparePartDetailField from 'pages/Store/components/SparePartDetailField';

import ConfirmationPortal from 'components/ConfirmationPortal';
import Loader from 'components/Loader';
import SkuListSelectionPortal from 'components/SkuListSelectionPortal';
import SparePartSelectionPortal from 'components/SparePartSelectionPortal';
import FloatingActionButton from 'components/atoms/FloatingActionButton';
import HeaderWithText from 'components/atoms/HeaderWithText';

import { SparePart } from 'types/spare-part';

import { isItemInList } from 'utils/list';

const PageContent = styled.div({
  /**
   * This is a hack, we need this height because the SpeedDial button is positionned
   * inside the component. It should be instanciated directly at the root of the application.
   */
  height: 'calc(100vh - 165px)',
});

const TurquoiseIcon = styled(LocalShippingIcon)((props) => ({
  color: props.theme.colors.turquoise,
}));

const skuFilter = {
  enable_employee_filter: 'on',
  status: 'return_to_warehouse',
};

export type RepairmanStockReturnProps = {
  skuList: SparePart[];
  loading: boolean;
};

const RepairmanStockReturn: React.FunctionComponent<RepairmanStockReturnProps> = ({
  loading,
  skuList,
}: RepairmanStockReturnProps) => {
  const history = useHistory();

  const [isSkuSelectionOpen, setIsSkuSelectionOpen] = useState(false);
  const [isListSelectionOpen, setIsListSelectionOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // baseSkuList is the list of skus to return given as props from parent component
  const [baseSkuList, setBaseSkuList] = useState<SparePart[]>(skuList);

  // selectedSkuList is the list selected skus that evolves
  // each time a sku is selected by the user
  const [selectedSkuList, setSelectedSkuList] = useState<SparePart[]>([]);

  // skusToDeclareLost is the list of skus that are not selected
  // by the user when the selection is submitted
  const [skusToDeclareLost, setSkusToDeclareLost] = useState<SparePart[]>([]);
  const [sku, setSku] = useState<SparePart>();

  const openSkuSelection = () => {
    setIsSkuSelectionOpen(true);
    !isListSelectionOpen && setIsListSelectionOpen(true);
  };

  const handleClose = () => {
    setIsSkuSelectionOpen(false);
  };

  const addSkuInList = (sku: SparePart) => {
    if (!isItemInList(sku, selectedSkuList)) {
      setSelectedSkuList([...selectedSkuList, sku]);
    }
  };

  const declareReturnForSelectedkus = () => {
    if (selectedSkuList.length === 0) {
      // if no skus are selected fill the lost list
      // with all skus from the base list
      setSkusToDeclareLost(baseSkuList);
    } else {
      setIsSaving(true);
      dataProvider
        .getOne('sku-location', { code: 'WHBOB' })
        .then(({ data: SkuLocation }) => {
          if (SkuLocation && SkuLocation.length > 0) {
            dataProvider
              .create('sku-transfer', {
                data: {
                  transfer_type: 'repairman_to_warehouse',
                  status: 'in_progress',
                  final_location: SkuLocation[0].id,
                },
              })
              .then((response) => {
                return dataProvider.updateMany('sku', {
                  ids: selectedSkuList.map((sku) => sku.id),
                  data: {
                    transfer: [response.data.id],
                    expedition: 'transit_tech_transfer',
                  },
                });
              })
              .then(() => {
                // all the skus that are not updated should be declared lost
                setSkusToDeclareLost(
                  baseSkuList.filter((sku) => !selectedSkuList.map((x) => x.id).includes(sku.id))
                );
                // the base list is now filled with the remaining skus
                setBaseSkuList(
                  baseSkuList.filter((sku) => !selectedSkuList.map((x) => x.id).includes(sku.id))
                );
                // the selected skus list is thus emptied
                setSelectedSkuList([]);
                setIsSaving(false);
              })
              .catch((e) => {
                console.error(e);
                setIsSaving(false);
              });
          } else {
            setIsSaving(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setIsSaving(false);
        });
    }
  };

  const declareLossForSelectedkus = () => {
    setIsSaving(true);
    dataProvider
      .updateMany('sku', {
        ids: skusToDeclareLost.map((sku) => sku.id),
        data: {
          status: 'lost',
          loss_reason: 'repairman_return',
        },
      })
      .then(() => {
        // withdrawing skus declared lost from based sku list
        setBaseSkuList(
          baseSkuList.filter((sku) => !skusToDeclareLost.map((x) => x.id).includes(sku.id))
        );
        setSkusToDeclareLost([]);
        setIsSaving(false);
      })
      .catch((e) => {
        console.error(e);
        setIsSaving(false);
      });
  };

  const handleCloseListPortal = () => {
    setSelectedSkuList([]);
    setIsListSelectionOpen(false);
  };

  const goToCalendar = () => {
    history.push('/calendar');
  };

  return (
    <>
      <PageContent>
        {loading || isSaving ? (
          <Loader />
        ) : (
          <>
            <HeaderWithText>{baseSkuList?.length} pièce(s) à retourner</HeaderWithText>
            {baseSkuList &&
              baseSkuList.map((sku: SparePart, index) => (
                <SparePartDetailField
                  key={index}
                  sparePart={sku}
                  checked={isItemInList(sku, selectedSkuList)}
                />
              ))}
          </>
        )}
        <FloatingActionButton page={3} variant="primary" onClick={openSkuSelection}>
          <ReplayIcon />
        </FloatingActionButton>
      </PageContent>
      <SkuListSelectionPortal
        open={isListSelectionOpen || selectedSkuList.length > 0}
        skuList={baseSkuList}
        selectedList={selectedSkuList}
        onClose={handleCloseListPortal}
        isSaving={isSaving}
        title="Retour"
        buttonText="Valider le retour"
        onClickOnButton={declareReturnForSelectedkus}
        openSkuSelection={openSkuSelection}
      />
      <SkuListSelectionPortal
        open={selectedSkuList.length === 0 && skusToDeclareLost.length > 0}
        skuList={skusToDeclareLost}
        selectedList={selectedSkuList}
        onClose={handleCloseListPortal}
        isSaving={isSaving}
        title="Pièces manquantes"
        buttonText="Déclarer une perte"
        onClickOnButton={declareLossForSelectedkus}
        openSkuSelection={openSkuSelection}
        redButton
      />
      {isSkuSelectionOpen && (
        <SparePartSelectionPortal
          onConfirm={(sku: SparePart) => addSkuInList(sku)}
          onClose={handleClose}
          open={isSkuSelectionOpen}
          filter={skuFilter}
          sku={sku}
          setSku={setSku}
        />
      )}
      <ConfirmationPortal
        title="Retour enregistré"
        body="Pensez à envoyer votre colis dès que possible"
        buttonText="Retourner à l’agenda"
        icon={<TurquoiseIcon />}
        onConfirm={goToCalendar}
        open={skuList.length !== 0 && baseSkuList.length === 0}
        onClose={handleClose}
      />
    </>
  );
};

export default RepairmanStockReturn;
