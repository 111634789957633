import React from 'react';

import { Box } from '@material-ui/core';

import { useFormikContext } from 'formik';
import styled from 'styled-components';

import Text from 'components/atoms/Text';
import RadioFieldItem from 'components/molecules/RadioFieldItem';
import RadioFieldList from 'components/molecules/RadioFieldList';

import { CustomerSurveyValues } from './DebitSuccess';

const StyledForm = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CustomerSurvey: React.FunctionComponent = () => {
  const {
    values: { subscribeNewsletter, acquisitionSource },
    setFieldValue,
  } = useFormikContext<CustomerSurveyValues>();
  return (
    <StyledForm>
      <Box padding={3} display="flex" flexDirection="column" paddingBottom={0}>
        <RadioFieldList
          activeItem={subscribeNewsletter}
          onChange={(value) => setFieldValue('subscribeNewsletter', value)}
        >
          <Text variant="body" color="#005646" paddingBottom={2}>
            Souhaitez-vous recevoir gratuitement le guide d&apos;entretien de votre appareil et vous
            inscrire aux actualités Murfy ?
          </Text>
          <RadioFieldItem name={'subscribeNewsletter'} value={'yes'} label={'Oui'} />
          <RadioFieldItem name={'subscribeNewsletter'} value={'no'} label={'Non'} />
        </RadioFieldList>
      </Box>
      <Box padding={3} display="flex" flexDirection="column" paddingBottom={1} paddingTop={1}>
        <RadioFieldList
          activeItem={acquisitionSource}
          onChange={(value) => setFieldValue('acquisitionSource', value)}
        >
          <Text variant="body" color="#005646" paddingBottom={2}>
            Comment nous avez-vous connu ?
          </Text>
          <RadioFieldItem
            name={'acquisitionSource'}
            label={'Bouche à oreille'}
            value={'peer_recommandation'}
          />
          <RadioFieldItem name={'acquisitionSource'} label={'A la télévision'} value={'tv'} />
          <RadioFieldItem
            name={'acquisitionSource'}
            label={'Autre publicité (radio, affiche…)'}
            value={'other_ads'}
          />
          <RadioFieldItem
            name={'acquisitionSource'}
            label={'Réseaux sociaux'}
            value={'social_media'}
          />
          <RadioFieldItem
            name={'acquisitionSource'}
            label={'Recherche Internet'}
            value={'organic_search'}
          />
          <RadioFieldItem name={'acquisitionSource'} label={'Autre'} value={'other'} />
        </RadioFieldList>
      </Box>
    </StyledForm>
  );
};

export default CustomerSurvey;
