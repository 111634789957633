import React, { useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';

import RepairProductChecklist from 'pages/RepairCycleTasks/RepairCycleRepairReport/RepairProductChecklist/RepairProductChecklist';
import { postCheckListsDeliveryItem } from 'pages/RepairCycleTasks/api';
import { SHIPPING } from 'pages/WorkshopShipping/utils';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

import { Product } from 'types/products';
import { ShippingDeliveryItem } from 'types/shipping-deliveries';

type ProductPreparationChecksFormProps = {
  deliveryItem: ShippingDeliveryItem;
  product: Product;
  handleClose: () => void;
  initialValues: ProductPreparationChecksFormValue;
};

type FormattedChecklistElement = {
  id: number;
  text: string;
  is_checked: boolean;
};

type ProductPreparationChecksFormValue = {
  shipping: FormattedChecklistElement[];
};

const steps = [SHIPPING];
type DataType = {
  shipping: FormattedChecklistElement[];
};
function _renderStepContent(
  step: number,
  formProps: FormikProps<ProductPreparationChecksFormValue>,
  _handleSubmit: (values: ProductPreparationChecksFormValue) => void
) {
  //   checking if the current step has data to display
  //   if not, going straight to the next step
  const actualStep = steps[step];
  (Object.keys(formProps.values) as (keyof DataType)[]).forEach((key) => {
    if (
      key === actualStep &&
      formProps.values[actualStep] &&
      formProps.values[actualStep]?.length < 1
    ) {
      _handleSubmit(formProps.values);
    }
  });

  switch (step) {
    case 0:
      return <RepairProductChecklist values={formProps.values} checklistType={SHIPPING} />;
    default:
      return <div>Not Found</div>;
  }
}

const ProductPreparationChecksForm: React.FunctionComponent<ProductPreparationChecksFormProps> = ({
  deliveryItem,
  product,
  handleClose,
  initialValues,
}: ProductPreparationChecksFormProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isLastStep = activeStep === steps.length - 1;

  async function _submitForm(values: ProductPreparationChecksFormValue) {
    if (values?.shipping && values?.shipping.length > 0) {
      setIsLoading(true);
      await postCheckListsDeliveryItem(deliveryItem.id, SHIPPING, values.shipping);
      setIsLoading(false);
    }
    handleClose();
  }

  function _handleSubmit(values: ProductPreparationChecksFormValue) {
    if (isLastStep) {
      _submitForm(values);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      <HeaderWithArrow
        title={`Préparation ${product.barcode}`}
        action={activeStep !== 0 ? _handleBack : handleClose}
      />
      <Box overflow="auto" mb={64}>
        <Formik initialValues={initialValues} onSubmit={_handleSubmit} enableReinitialize>
          {({ ...props }: FormikProps<ProductPreparationChecksFormValue>) => {
            return (
              <Form>
                {_renderStepContent(activeStep, props, _handleSubmit)}
                <BottomButton disabled={!props.isValid || isLoading}>Confirmer</BottomButton>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default ProductPreparationChecksForm;
