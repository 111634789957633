import React, { useState } from 'react';

import { useGetOne } from 'react-admin';

import { Formik } from 'formik';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useLastBill } from 'pages/Payment/utils/api';

import { ADDITIONAL_PACKAGE } from 'components/QuoteElementField/utils';
import Text from 'components/atoms/Text';

import { CustomerFile } from 'types/customer-files';
import { Product } from 'types/products';

import { useProductTypesList } from 'utils/api/api';
import { postProduct, postQuote } from 'utils/api/api';

import ProductAddition from './ProductAddition';
import ProductAdditionConfirmation from './ProductAdditionConfirmation';

type ProductAdditionContainerMatchParams = {
  customerFileId: string;
  visitId: string;
};

type ProductAdditionContainerProps = RouteComponentProps<ProductAdditionContainerMatchParams>;
const ProductAdditionContainer: React.FunctionComponent<ProductAdditionContainerProps> = ({
  match: {
    params: { customerFileId, visitId },
  },
}: ProductAdditionContainerProps) => {
  // API DATA FETCHING
  const { productTypes, loadingProductTypes } = useProductTypesList();
  const { bill } = useLastBill(customerFileId);
  const { data: customerFile } = useGetOne<CustomerFile>('customer-file', customerFileId);

  const history = useHistory();

  const [error, setError] = useState<string | undefined>(undefined);
  const [step, setStep] = useState<number>(1);

  // PAGE NAVIGATION FUNCTIONS
  const goToNextScreen = () => setStep(2);
  const goToPreviousScreen = () => setStep(1);

  // FORM CONFIGURATION
  type FormValues = {
    productType: string | null;
  };
  const initialValues: FormValues = {
    productType: null,
  };
  const validationSchema = yup.object().shape({
    productType: yup.string().required(),
  });

  const getProductTypeNameFromId = (id: number): string => {
    return productTypes.find((product) => product.id === id)?.name || '';
  };

  const createAdditonnalPackage = async (product: Product) =>
    postQuote({
      customer_file: customerFileId,
      quote_elements: [
        ...bill.quote_elements,
        {
          element_type: ADDITIONAL_PACKAGE,
          product: product.product_type, // still is a charfield: only carrying the product type
          home_repair_product: product.id,
        },
      ],
      quote_accepted: true,
    });

  // GLOBAL FORM SUBMIT FUNCTION
  const handleProductAddition = async (values: FormValues) => {
    setError(undefined);
    const productType = productTypes.find(
      (product) => values.productType && product.id === parseInt(values.productType)
    );

    try {
      const createdProduct = await postProduct({
        customer_file: customerFileId,
        product_type: productType?.name,
        product_type_link: productType?.id,
      });
      if (!bill) {
        history.push(`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`);
      } else {
        try {
          await createAdditonnalPackage(createdProduct);
          history.push(`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`);
        } catch (e) {
          setError("Erreur lors de l'ajout de facture");
        }
      }
    } catch (e) {
      setError("Erreur lors de l'ajout de facture");
    }
  };

  // EARLY RETURN ON LOADING
  if (loadingProductTypes || !customerFile) return null;

  // EARLY RETURN ON ERROR
  if (error)
    return (
      <Text textAlign="center" variant="smallBold" color="red">
        {error}
      </Text>
    );

  // GLOBAL RETURN
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleProductAddition}
    >
      {({ isSubmitting, values, isValid, dirty }) =>
        step === 1 || !values.productType ? (
          <ProductAddition
            validateType={goToNextScreen}
            productTypes={productTypes}
            disabled={isSubmitting || !isValid || !dirty}
          />
        ) : (
          <ProductAdditionConfirmation
            customerFile={customerFile}
            productType={getProductTypeNameFromId(parseInt(values.productType))}
            isSubmitting={isSubmitting}
            goBack={goToPreviousScreen}
          />
        )
      }
    </Formik>
  );
};

export default ProductAdditionContainer;
