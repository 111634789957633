import React from 'react';

import BuildIcon from '@material-ui/icons/Build';

import FlatCard from 'components/FlatCard';
import Text from 'components/atoms/Text';

type OverTourSummaryProps = {
  visitCount: number;
  className?: string;
};

const OverTourSummary: React.FunctionComponent<OverTourSummaryProps> = ({
  visitCount,
  className,
}: OverTourSummaryProps) => {
  return (
    <FlatCard className={className} variant="success">
      <Text variant="bodyBold" mb={1} color="white">
        Journée terminée
      </Text>
      <Text variant="small" display="flex" alignItems="center" color="white">
        <BuildIcon fontSize="small" style={{ marginRight: 16, color: '#8795B3' }} />
        {`${visitCount} interventions`}
      </Text>
    </FlatCard>
  );
};

export default OverTourSummary;
