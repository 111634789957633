import React from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Select from 'components/molecules/Select';

import { ProductSupplier } from 'types/product-suppliers';

import { useProductSupplierList } from 'utils/api/api';

type FormValues = {
  supplier: string | null;
  price: number | null;
};
type ProductSupplierInfoProps = {
  handleSubmit: (values: FormValues) => void;
  goBack: () => void;
};

const StyledForm = styled(Form)((props) => ({
  background: props.theme.colors.grey200,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 150,
}));

const formatSelectFieldCompliantList = (list: Array<ProductSupplier>) => {
  return list.map(function (row: ProductSupplier) {
    return { value: row.name, name: row.name, id: row.id };
  });
};

const ProductSupplierInfo: React.FunctionComponent<ProductSupplierInfoProps> = ({
  handleSubmit,
  goBack,
}) => {
  const { productSuppliers } = useProductSupplierList();
  const formattedProductSuppliers = formatSelectFieldCompliantList(productSuppliers || []);
  const validationSchema = yup.object().shape({
    supplier: yup.string().required('Fournisseur requis'),
    price: yup.number().nullable().notRequired(),
  });

  const initialValues: FormValues = {
    supplier: null,
    price: null,
  };

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, isValid, dirty }) => (
          <StyledForm>
            <HeaderWithArrow title="Réception B2B" action={goBack} />
            <Box mb={2} textAlign="left" width="90%">
              <Field name="supplier">
                {({ field: { name, value }, form, meta }: FieldProps) => {
                  return (
                    <Select
                      items={formattedProductSuppliers}
                      value={value}
                      name={name}
                      valid={!(meta.touched && meta.error)}
                      onChange={(value) => form.setFieldValue(name, value)}
                      width="100%"
                      innerWidth="90%"
                      maxHeight={380}
                      placeholder={'Fournisseur'}
                    />
                  );
                }}
              </Field>
              <Text variant="small" color="red">
                {errors?.supplier}
              </Text>
              <Field name="price">
                {({ field: { name, onChange, value } }: FieldProps) => {
                  return (
                    <Input
                      width="100%"
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Prix d'achat"
                    />
                  );
                }}
              </Field>
              <Text variant="small" color="red">
                {errors?.price}
              </Text>
            </Box>
            <BottomButton
              data-testid="bottom-button"
              disabled={!isValid || isSubmitting || !dirty}
              type="submit"
            >
              Valider
            </BottomButton>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default ProductSupplierInfo;
