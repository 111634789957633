import React from 'react';

import { MarginProps } from 'styled-system';

import PaperStripText from 'components/PaperStripText';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ApplianceNameplate from 'components/organisms/ApplianceNameplate';

import {
  CustomerFileTicket,
  TicketAttachment,
  TicketDescription,
} from 'types/customer-file-history';
import { CustomerFileHistoryProduct } from 'types/products';
import { VisitPayment } from 'types/visits';

type HistoryTicketsProps = {
  ticket: CustomerFileTicket;
  payment?: VisitPayment;
} & MarginProps;

const HistoryTicket: React.FunctionComponent<HistoryTicketsProps> = ({
  ticket,
  payment,
  ...rest
}: HistoryTicketsProps) => {
  const visitOutcomeValues = {
    finished: 'Terminé',
    'intervention-impossible': `Impossible d'intervenir`,
    'wrong-command': 'Problème de commande',
    'need-spare-part': `Besoin d'une pièce`,
    'help-wanted': 'Aide technique',
    'not-repaired': 'Non réparé',
  };

  const renderDescription = (ticket_desc: string) =>
    JSON.parse(ticket_desc).map((description: TicketDescription) => description.description);

  const lastSparePart = (a: string | []) => {
    if (a && a.length > 0) {
      return a[a.length - 1];
    }
  };

  return (
    <Box {...rest}>
      <PaperStripText
        title={`Date du passage`}
        content={`${new Date(ticket.created_at).toLocaleDateString()} ${new Date(
          ticket.created_at
        ).toLocaleTimeString()}`}
      />
      <PaperStripText
        title={'Règlement'}
        content={
          payment
            ? `${Math.round(payment.amount / 100)}€ - reglé le ${new Date(
                payment.created_at
              ).toLocaleDateString()}`
            : 'Non reglé'
        }
      />
      <Box>
        <PaperStripText title={'Technicien'} content={ticket.employee} />
        <PaperStripText
          title={'Description CR'}
          content={renderDescription(ticket.ticket_description)}
        />
        {ticket?.ticket_payload?.payload?.products &&
          ticket.ticket_payload.payload.products.map(
            (product: CustomerFileHistoryProduct, index) => (
              <Box key={product.product_id} mb={2}>
                {product.product_type && (
                  <PaperStripText title={`Machine n°${index + 1}`} content={product.product_type} />
                )}
                {product.symptoms && (
                  <PaperStripText title={'Symptomes'} content={product.symptoms} />
                )}
                {product.diagnosis && (
                  <PaperStripText title={'Diagnostic'} content={product.diagnosis} />
                )}
                {product.additional_information && (
                  <PaperStripText
                    title={'Informations supplémentaires'}
                    content={product.additional_information}
                  />
                )}
                <PaperStripText
                  title={'Issue du passage'}
                  content={
                    !product.visit_outcome ? 'Inconnu' : visitOutcomeValues[product.visit_outcome]
                  }
                />
                <PaperStripText
                  title={'Utilisation du stock embarqué'}
                  content={product.own_stock_spare_part_used ? 'Oui' : 'Non'}
                />
                <PaperStripText
                  title={'Pièces à commander'}
                  content={
                    product.spare_parts_orders ||
                    (product.hermetic_unit_order && product.hermetic_unit_order.length > 0)
                      ? 'Oui'
                      : 'Non'
                  }
                />
                {product.spare_parts_orders && (
                  <Box key={index} ml={2}>
                    <Text variant="bodyBold" mb={1}>
                      Détail des pièces à commander
                    </Text>
                  </Box>
                )}
                {product.spare_parts_orders?.length &&
                  product.spare_parts_orders.map((sparePart, index) => (
                    <Box key={index} ml={2}>
                      <Text variant={'body'}>
                        - Pièce {index + 1}{' '}
                        {Array.isArray(sparePart.name)
                          ? lastSparePart(sparePart.name)
                          : sparePart.name}{' '}
                        (ref:{' '}
                        {Array.isArray(sparePart.ref)
                          ? lastSparePart(sparePart.ref)
                          : sparePart.ref}
                        )
                      </Text>
                    </Box>
                  ))}
                <PaperStripText
                  title={'Pose de pièce(s) du devis'}
                  content={product.spare_part_used ? 'Oui' : 'Non'}
                />
              </Box>
            )
          )}
        {ticket.ticket_attachments?.length && (
          <Box ml={2}>
            <Text variant="bodyBold" mb={1}>
              Pièces jointes
            </Text>
            {ticket.ticket_attachments.map((document: TicketAttachment) => (
              <ApplianceNameplate
                applianceNameplateImageUrl={document.attachment}
                key={document.id}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HistoryTicket;
