import React, { useEffect } from 'react';

import { useQuery } from 'react-admin';

import CalendarToday from '@material-ui/icons/CalendarToday';
import ExtensionIcon from '@material-ui/icons/Extension';
import GroupIcon from '@material-ui/icons/Group';
import ListIcon from '@material-ui/icons/List';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useEmployee } from 'employeeContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Box from 'components/atoms/Box';

import { Task } from 'types/tasks';

import NavButton from './NavButton';

const BottomBar: React.FunctionComponent = () => {
  const { employee } = useEmployee();
  const { crPrequal } = useFlags();

  const [isManager, setIsManager] = React.useState(false);

  const { data: taskList }: { data?: Task[] } = useQuery({
    type: 'getList',
    resource: 'task-list',
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
      filter: '',
    },
  });

  useEffect(() => {
    if (taskList) {
      if ((employee && taskList && taskList.length >= 1) || (employee && employee.id)) {
        const hasManagerTask = taskList
          .filter((task) => employee.associated_tasks.includes(task.id))
          .some((task) => task.name === 'management');
        setIsManager(hasManagerTask);
      }
    }
  }, [employee, taskList]);

  return (
    <Box
      bg="white"
      py={1}
      display="flex"
      justifyContent="space-around"
      borderColor="grey500_2"
      borderTopWidth="1px"
      borderTopStyle="solid"
      position="sticky"
      bottom={0}
    >
      <NavButton to={`/incentives/${employee.id}`} icon={<ListIcon />}>
        Performance
      </NavButton>
      <NavButton to="/repairman-stock" icon={<ExtensionIcon />}>
        Pièces
      </NavButton>
      {isManager && (
        <NavButton to="/team" icon={<GroupIcon />}>
          Team
        </NavButton>
      )}
      <NavButton to="/calendar" icon={<CalendarToday />}>
        Calendrier
      </NavButton>
      {crPrequal && (
        <NavButton to="/inbox" icon={<NotificationsIcon />}>
          Inbox
        </NavButton>
      )}
    </Box>
  );
};

export default BottomBar;
