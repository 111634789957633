import React from 'react';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BuildIcon from '@material-ui/icons/Build';
import PlaceIcon from '@material-ui/icons/Place';

import Divider from 'components/atoms/Divider';
import Text from 'components/atoms/Text';

import { Visit } from 'types/visits';

import { dateToLongHour } from 'utils/dates';
import { getVisitAddress } from 'utils/visits';

import BaseTourSummary from './BaseTourSummary';

type NotStartedTourSummaryProps = {
  className?: string;
  visitCount: number;
  firstVisit?: Visit;
};

const NotStartedTourSummary: React.FunctionComponent<NotStartedTourSummaryProps> = ({
  className,
  visitCount,
  firstVisit,
}: NotStartedTourSummaryProps) => {
  return (
    <BaseTourSummary className={className}>
      <Text variant="bodyBold" mb={1}>
        Tournée planifiée
      </Text>
      <Text variant="small" display="flex" alignItems="center">
        <BuildIcon fontSize="small" style={{ marginRight: 16, color: '#8795B3' }} />
        {`${visitCount} interventions`}
      </Text>
      {firstVisit && (
        <>
          <Divider my={3} />
          <Text variant="bodyBold" mb={1}>
            {`Premier rendez-vous à ${dateToLongHour(firstVisit.timeslot.beginning)}`}
          </Text>
          <Text variant="small" display="flex" alignItems="center" mb={1}>
            <AccountCircleIcon fontSize="small" style={{ marginRight: 16, color: '#8795B3' }} />
            {`${firstVisit.firstname} ${firstVisit.lastname}`}
          </Text>
          <Text variant="small" display="flex" alignItems="center" mb={1}>
            <PlaceIcon fontSize="small" style={{ marginRight: 16, color: '#8795B3' }} />
            {getVisitAddress(firstVisit)}
          </Text>
        </>
      )}
    </BaseTourSummary>
  );
};

export default NotStartedTourSummary;
