import React, { useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ProductIdentification from 'components/ProductIdentification';
import GoBackHeader from 'components/molecules/GoBackHeader';
import PageLayout from 'components/templates/PageLayout';

import { createProductModel, useProduct, useProductModel } from 'utils/api/api';
import { updateProduct } from 'utils/api/api';
import { compressImage } from 'utils/images';

type ProductModelIdentificationMatchParams = {
  productId: string;
  customerFileId: string;
  visitId: string;
};

type ProductAdditionContainerProps = RouteComponentProps<ProductModelIdentificationMatchParams>;

interface ProductModelIdentificationProps extends ProductAdditionContainerProps {
  onByPassProductIdentification: (productId: number) => void;
  onProductIdentification: (productId: number) => void;
}

const ProductModelIdentification: React.FunctionComponent<ProductModelIdentificationProps> = ({
  match: {
    params: { productId, customerFileId, visitId },
  },
  onByPassProductIdentification,
  onProductIdentification,
}: ProductModelIdentificationProps) => {
  const history = useHistory();

  const { product } = useProduct(parseInt(productId));
  const productModel = useProductModel(product?.product_model);
  const [error, setError] = useState<string | undefined>(undefined);

  localStorage.setItem('productId', productId);

  const pushToDashboard = () => {
    history.push(`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`);
  };

  const updateProductHandleNavigate = async (payload: {
    product_model?: number;
    identification_plate?: File | null;
  }) => {
    try {
      setError(undefined);
      await updateProduct(parseInt(productId), {
        ...payload,
      });

      pushToDashboard();
    } catch (errors) {
      setError(
        `Une erreur est survenue lors de la mise à jour de la référence produit: \n ${errors}`
      );
    }
  };

  const handleSubmitProductModelIdentification = async (
    reference?: string,
    productBrand?: string,
    productType?: string,
    identificationPlate?: File | null
  ) => {
    let payload = {};
    try {
      if (reference && productType) {
        const model = await createProductModel({
          reference: reference,
          productType: parseInt(productType),
          productBrand: productBrand ? parseInt(productBrand) : null,
        });

        payload = {
          product_model: model.id,
        };
      }
    } catch (errors) {
      setError(`Une erreur est survenue lors de l'enregistrement du modèle: \n ${errors}`);
    }
    if (identificationPlate) {
      payload = Object.assign({}, payload, {
        identification_plate: await compressImage(
          identificationPlate,
          `plaque_signalétique_${productId}.png`
        ),
      });
    }
    onProductIdentification(product.id);
    updateProductHandleNavigate(payload);
  };

  const bypassIdentification = (identificationPlate?: File | null) => {
    if (!identificationPlate) {
      if (!product.identification_plate) {
        setError('Veuillez prendre en photo la plaque signalétique');
        return;
      } else {
        pushToDashboard();
      }
    }
    updateProductHandleNavigate({ identification_plate: identificationPlate });
    onByPassProductIdentification(product.id);
  };

  return (
    <PageLayout>
      <GoBackHeader title={`Identification du produit`} />
      <ProductIdentification
        handleSubmit={handleSubmitProductModelIdentification}
        product={product}
        error={error}
        validationLabel="Confirmer le modèle"
        waitingLabel="Mise à jour du modèle en cours"
        defaultProductModel={productModel}
        identificationPlateUpdateEnabled
        bypassCallback={bypassIdentification}
        enableBypass
      />
    </PageLayout>
  );
};

export default ProductModelIdentification;
