import { createStyles } from '@material-ui/core/styles';

const RepairReportTextAreaStyle = createStyles({
  textAreaContainer: {
    display: 'flex',
  },
  basicTextArea: {
    backgroundColor: '#FFFFFF',
    margin: '0px 24px',
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    justifyContent: 'flex-start',
    width: '100%',
    color: '#2B3B5C',
  },
  autocompleteStyle: {
    display: 'flex',
    margin: '0px 24px',
    '& .MuiFilledInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      border: '2px solid #DBDFE8',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense ': {
      padding: '0 16px',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    '& .MuiFilledInput-inputMarginDense': {
      padding: '27px 24px 10px',
      color: '#2B3B5C',
    },
    '& .MuiFormHelperText-marginDense': {
      display: 'none',
    },
  },
});

export default RepairReportTextAreaStyle;
