import React from 'react';

import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ButtonBlock from 'components/molecules/ButtonBlock';
import ContentContainer from 'components/templates/ContentContainer';

type ImageSelectorProps = {
  title?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  content?: string;
  name: string;
};

type IconProps = {
  className?: string;
};

const StyledIcon = styled(CameraAltRoundedIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.pink')(props),
}));

const ImageSelector: React.FunctionComponent<ImageSelectorProps> = ({
  title = 'Ajoutez une photo de la plaque signalétique de l’appareil.',
  content = 'Prenez l’ensemble de l’étiquette, veillez à la netteté.',
  name,
  onChange,
}: ImageSelectorProps) => {
  return (
    <ContentContainer>
      <Box
        bg="white"
        borderRadius={10}
        p={36}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <StyledIcon />
        <Text variant="body" mt={2} mb={2} textAlign="center">
          {title}
        </Text>
        <Text variant="small" textAlign="center">
          {content}
        </Text>
        <input
          id="file-upload"
          data-testid="nameplate-input"
          type="file"
          name={name}
          style={{ display: 'none' }}
          onChange={onChange}
        />

        <ButtonBlock mt={3} type="button">
          <label htmlFor="file-upload">Ajouter une photo</label>
        </ButtonBlock>
      </Box>
    </ContentContainer>
  );
};

export default ImageSelector;
