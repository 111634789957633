import React from 'react';

import { MarginProps } from 'styled-system';

import Tag from 'components/atoms/Tag';

import { VisitLabelLitteral } from 'types/visits';

type VisitLabelProps = {
  label: VisitLabelLitteral;
} & MarginProps;

const VisitLabel: React.FunctionComponent<VisitLabelProps> = ({
  label,
  ...rest
}: VisitLabelProps) => {
  const renderTag = () => {
    switch (label) {
      case 'finished':
        return <Tag {...rest} text="Terminé" variant="success" />;
      case 'diag':
        return <Tag {...rest} text="Diagnostic" variant="info" />;
      case 'intervention-impossible':
        return <Tag {...rest} variant="problem" text="Inter. impossible" />;
      case 'help-wanted':
        return <Tag {...rest} variant="ternary" text="Aide demandée" />;
      case 'wrong-command':
        return <Tag variant="problem" {...rest} text="Mauvaise commande" />;
      case 'superfluous-visit':
        return <Tag variant="problem" {...rest} text="Visite superflue" />;
      case 'quote-refused':
        return <Tag variant="problem" {...rest} text="Devis refusé" />;
      case 'not-repaired':
        return <Tag variant="problem" {...rest} text="Non réparable" />;
      default:
        return null;
    }
  };

  return <>{renderTag()}</>;
};

export default VisitLabel;
