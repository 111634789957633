import React from 'react';

import PaperStrip from 'components/atoms/PaperStrip';
import { PaperStripProps } from 'components/atoms/PaperStrip/PaperStrip';
import Text, { TextVariants } from 'components/atoms/Text';

export type PaperStripTextProps = {
  title: string;
  content: string | string[];
  contentStyle?: keyof typeof TextVariants;
} & PaperStripProps;

const PaperStripText: React.FunctionComponent<PaperStripTextProps> = ({
  title,
  content,
  contentStyle = 'body',
  ...rest
}: PaperStripTextProps) => {
  const renderContent = () => {
    return typeof content === 'string' ? (
      <Text variant={contentStyle}> {content} </Text>
    ) : (
      content.map((text) => (
        <Text key={text} variant={contentStyle}>
          {text}
        </Text>
      ))
    );
  };
  return (
    <PaperStrip {...rest}>
      <Text variant="bodyBold" mb={1}>
        {title}
      </Text>
      {renderContent()}
    </PaperStrip>
  );
};

export default PaperStripText;
