import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import styled from 'styled-components';

import { renderIrisCodeFromId } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { IrisRepair, IrisSection } from 'types/iris-codes';
import { RepairActionHistory } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type RepairActionBlockProps = {
  repairAction: RepairActionHistory;
  irisRepairs: IrisRepair[];
  irisSections: IrisSection[];
};

const GreenCheck = styled(CheckCircleIcon)((props) => ({
  color: props.theme.colors.turquoise,
}));

const SectionRepairBlock: React.FunctionComponent<RepairActionBlockProps> = ({
  repairAction,
  irisSections,
  irisRepairs,
}: RepairActionBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const renderSectionRepair = (iris_section: number, iris_repair: number) => {
    return `${renderIrisCodeFromId(irisSections, iris_section)} ⟶ ${renderIrisCodeFromId(
      irisRepairs,
      iris_repair
    )}`;
  };
  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={repairAction.data.created_at}
          assignee={repairAction.data.assignee}
          title={`Réparation`}
          tagVariant={'secondary'}
        />
      </Box>
      {isOpen && (
        <>
          {repairAction.data?.spare_part_fittings.length > 0 && (
            <SimpleBlockContent noSeparator>
              <Box mt={2} width="100%">
                <Text variant="caption" color="grey900" display="flex">
                  PIÈCE(S) POSÉES
                </Text>
                <Box width="100%" mt={1}>
                  {repairAction.data?.spare_part_fittings?.map((sparePart, index) => {
                    return (
                      <Box display="flex" justifyContent="space-between" key={sparePart.id} mt={2}>
                        <Box>
                          <Text
                            key={`${sparePart.sku_demand_item}`}
                            variant="smallBold"
                            color="dark"
                          >
                            {sparePart.designation}
                          </Text>
                          <Text key={`${index}`} variant="caption">
                            {sparePart.reference}
                          </Text>
                        </Box>
                        <GreenCheck />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </SimpleBlockContent>
          )}
          {repairAction.data?.section_repairs.length > 0 && (
            <SimpleBlockContent noSeparator>
              <Box width="100%" mt={2}>
                <Text variant="caption" color="grey900" display="flex">
                  PIÈCE(S) RÉPARÉE(S)
                </Text>
                <Box width="100%" mt={1}>
                  {repairAction.data?.section_repairs?.map((sectionRepair, index) => {
                    return (
                      <Box key={`${index}`} display="flex" justifyContent="space-between" mt={2}>
                        <Text key={`${index}`} variant="smallBold" color="dark">
                          {renderSectionRepair(
                            sectionRepair.iris_section,
                            sectionRepair.iris_repair
                          )}
                        </Text>
                        <GreenCheck />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </SimpleBlockContent>
          )}
        </>
      )}
    </Box>
  );
};
export default SectionRepairBlock;
