// React imports
import React, { useEffect, useState } from 'react';

import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PrintIcon from '@material-ui/icons/Print';
import WarningIcon from '@material-ui/icons/Warning';

import styled from 'styled-components';

import ProductAssociatedFiles from 'pages/ProductReception/components/ProductAssociatedFiles';
import PrintContent from 'pages/Store/components/PrintContent';

import PaperStripAction from 'components/PaperStripAction';
import ProductGeneralInfoPanel from 'components/ProductGeneralInfoPanel/';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import ContentContainer from 'components/templates/ContentContainer';

import { generateQrCode } from 'utils/QrCodeUtils';
import { useProduct } from 'utils/api/api';

type ProductDetailSreenProps = {
  productId: number;
  handleRedoIdentification: () => void;
  handleClosePortal: () => void;
  errorMessage: string | null;
  isProductionReceptionPending: boolean;
  goBack: () => void;
};

const Container = styled(ContentContainer)({
  padding: 0,
});

const GreenCheckIcon = styled(CheckCircleIcon)((props) => ({
  color: props.theme.colors.turquoise,
}));
const GreyCameraIcon = styled(CameraAltRoundedIcon)((props) => ({
  color: props.theme.colors.grey500,
}));
const GreyPrintIcon = styled(PrintIcon)((props) => ({
  color: props.theme.colors.grey500,
}));

const RedWarning = styled(WarningIcon)((props) => ({
  color: props.theme.colors.red,
}));

const ProductDetailScreen: React.FunctionComponent<ProductDetailSreenProps> = ({
  productId,
  handleRedoIdentification,
  handleClosePortal,
  goBack,
}: ProductDetailSreenProps) => {
  const { product, loadingProduct } = useProduct(productId);
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState();
  useEffect(() => {
    if (product?.barcode) {
      generateQrCode(product.barcode, setQrCode);
    }
  }, [product]);
  if (loadingProduct) {
    return null;
  }
  return (
    <Container>
      <HeaderWithArrow title={product?.barcode} action={goBack} />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        paddingY={0}
        paddingX={0}
        minHeight={0}
        overflow="scroll"
      >
        <ProductGeneralInfoPanel product={product} />

        <PaperStripAction
          text="Identification"
          Icon={GreyCameraIcon}
          SecondaryIcon={product.id ? GreenCheckIcon : undefined}
          callback={() => handleRedoIdentification()}
          textMode="action"
        />
        <Button height={56} variant="paperStrip" onClick={() => setIsQrCodeModalOpen(true)}>
          <Box display="flex" textAlign="left">
            <GreyPrintIcon />
            <Text ml={2} variant="bodyBold">
              Imprimer le QR code
            </Text>
          </Box>
        </Button>
        {isQrCodeModalOpen && product.barcode && (
          <PrintContent
            open={isQrCodeModalOpen}
            onClose={() => setIsQrCodeModalOpen(false)}
            handleNextStep={() => setIsQrCodeModalOpen(false)}
            qrCode={qrCode}
            codeSubtitle={product?.barcode}
          />
        )}
        {product?.customer_file && (
          <ProductAssociatedFiles customerFileId={product.customer_file} />
        )}
      </Box>
      <Box bg="white" borderRadius={2} m={2} p={2}>
        <Box textAlign="start">
          <RedWarning />
        </Box>
        <Text textAlign="start" variant="bodyBold">
          {`Le produit à été créé.`}
        </Text>
        <Text textAlign="start" variant="body">
          {`Si l'impression n'a pas fonctionné, note le numéro sur la machine afin d'imprimer le QR
          plus tard.`}
        </Text>
      </Box>
      <BottomButton variant="primary" onClick={() => handleClosePortal()}>
        {'Retourner à la liste de dossiers'}
      </BottomButton>
    </Container>
  );
};

export default ProductDetailScreen;
