import styled from 'styled-components';
import { MarginProps, WidthProps, margin } from 'styled-system';

export type TextWithBackgroundProps = {
  color?: string;
  background?: string;
  fontSize?: number;
  borderRadius: string;
} & MarginProps &
  WidthProps;

const TextWithBackground = styled('span')<TextWithBackgroundProps>(margin, (props) => ({
  padding: '6px 8px',
  boxSizing: 'border-box',
  borderRadius: props.borderRadius || props.theme.radii[1],
  fontSize: props.fontSize || props.theme.fontSizes[1],
  width: 'fit-content',
  height: 28,
  color: props.color || props.theme.colors.white,
  background: props.background || props.theme.colors.turquoise,
}));

export default TextWithBackground;
