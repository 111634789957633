import React from 'react';

import {
  CHECKLIST,
  CLEANING,
  COLLECTION_ITEM,
  COMMENT,
  DIAG,
  DISASSEMBLY,
  REFURBISHING_DECISION,
  REPAIR_ACTION,
  REPAIR_REPORT,
  SKU_DEMAND,
  SPARE_PART_REGISTRATION_REPORT,
} from 'pages/RepairCycleTasks/RepairCycleUtils';

import CheckListBlock from 'components/RepairCycleBlocks/CheckListBlock';
import CleaningTaskBlock from 'components/RepairCycleBlocks/CleaningTaskBlock';
import CollectionItemBlock from 'components/RepairCycleBlocks/CollectionItemBlock';
import CommentBlock from 'components/RepairCycleBlocks/CommentBlock';
import DiagnosticBlock from 'components/RepairCycleBlocks/DiagnosticBlock';
import DisassemblyBlock from 'components/RepairCycleBlocks/DisassemblyBlock';
import RefurbishingDecisionBlock from 'components/RepairCycleBlocks/RefurbishingDecisionBlock';
import SectionRepairBlock from 'components/RepairCycleBlocks/RepairActionBlock';
import RepairReportBlock from 'components/RepairCycleBlocks/RepairReportBlock';
import SkuDemandBlock from 'components/RepairCycleBlocks/SkuDemandBlock';
import SparePartRegistrationBlock from 'components/RepairCycleBlocks/SparePartRegistrationBlock';
import Box from 'components/atoms/Box';
import VerticalConnector from 'components/atoms/VerticalConnector';

import { IrisRepair, IrisSection } from 'types/iris-codes';
import {
  CleaningTaskChecklistHistory,
  CollectionItem,
  CollectionItemHistory,
  CommentHistory,
  DiagnosticHistory,
  DisassemblyHistory,
  ProductCheckListReport,
  ProductCheckListReportHistory,
  ProductHistory,
  RefurbishingDecisionHistory,
  RepairActionHistory,
  RepairReportHistory,
  SectionRepairHistory,
  SkuDemandHistory,
  SparePartRegistrationHistory,
} from 'types/product-history';

type RepairCycleHistoryProps = {
  productHistoryData: ProductHistory | null;
  irisSections: IrisSection[];
  irisRepairs: IrisRepair[];
};
const RepairCycleHistory: React.FunctionComponent<RepairCycleHistoryProps> = ({
  productHistoryData,
  irisSections,
  irisRepairs,
}: RepairCycleHistoryProps) => {
  const renderEvent = (
    event:
      | DiagnosticHistory
      | RepairReportHistory
      | SectionRepairHistory
      | SkuDemandHistory
      | RepairActionHistory
      | CommentHistory
      | CollectionItemHistory
      | ProductCheckListReportHistory
      | DisassemblyHistory
      | SparePartRegistrationHistory
      | RefurbishingDecisionHistory
  ) => {
    switch (event.type) {
      case DIAG:
        return <DiagnosticBlock diagnostic={event as DiagnosticHistory} />;
      case REPAIR_REPORT:
        return <RepairReportBlock repairReport={event as RepairReportHistory} />;
      case CHECKLIST:
        return <CheckListBlock productCheckListReport={event.data as ProductCheckListReport} />;
      case SKU_DEMAND:
        return <SkuDemandBlock skuDemand={event as SkuDemandHistory} />;
      case REPAIR_ACTION:
        return (
          <SectionRepairBlock
            irisSections={irisSections}
            irisRepairs={irisRepairs}
            repairAction={event as RepairActionHistory}
          />
        );
      case COMMENT:
        return <CommentBlock comment={event as CommentHistory} />;
      case COLLECTION_ITEM:
        return <CollectionItemBlock collectionItem={event.data as CollectionItem} />;

      case CLEANING:
        return <CleaningTaskBlock cleaningTask={event as CleaningTaskChecklistHistory} />;

      case DISASSEMBLY:
        return <DisassemblyBlock disassembly={event as DisassemblyHistory} />;

      case SPARE_PART_REGISTRATION_REPORT:
        return (
          <SparePartRegistrationBlock
            sparePartRegistration={event as SparePartRegistrationHistory}
          />
        );
      case REFURBISHING_DECISION:
        return <RefurbishingDecisionBlock decision={event as RefurbishingDecisionHistory} />;
    }
  };
  return (
    <>
      {productHistoryData?.map(
        (
          event:
            | DiagnosticHistory
            | RepairReportHistory
            | SectionRepairHistory
            | SkuDemandHistory
            | RepairActionHistory
            | CommentHistory
            | ProductCheckListReportHistory
            | CleaningTaskChecklistHistory
            | DisassemblyHistory
        ) => {
          return (
            <>
              <VerticalConnector height={3} />
              <Box key={event.data.id}>{renderEvent(event)}</Box>
            </>
          );
        }
      )}
    </>
  );
};

export default RepairCycleHistory;
