import React from 'react';

import { Field } from 'formik';
import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';

type RadioFieldProps = {
  name: string;
  label: string;
  value: string;
} & MarginProps;

const StyledLabel = styled.label`
  font-size: ${(props) => `${props.theme.fontSizes[2]}px`};
  display: flex;
  width: 100%;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-family: ${(props) => props.theme.fonts[0]};
  justify-content: space-between;
`;

const StyledField = styled(Field)`
  height: ${(props) => `${props.theme.sizes[3]}px`};
  width: 5%;
`;

const RadioField: React.FunctionComponent<RadioFieldProps> = ({
  name,
  label,
  value,
  ...rest
}: RadioFieldProps) => {
  return (
    <Box mt="1px" mb="1px" bg="white" padding={2} width="100%" {...rest}>
      <Box display="flex" textAlign="start">
        <StyledLabel>
          {label}
          <StyledField type="radio" name={name} value={value} />
        </StyledLabel>
      </Box>
    </Box>
  );
};

export default RadioField;
