import React, { useState } from 'react';

import ErrorIcon from '@material-ui/icons/Error';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import { useEmployee } from 'employeeContext';
import styled from 'styled-components';

import WorkshopSparePartInventory from 'pages/Recondition/ReconditionStockList/components/WorkshopSparePartInventory';
import SparePartDefectsDeclaration from 'pages/SparePartDefectsDeclaration';
import StoreTransfer from 'pages/Store/components/StoreTransfer';

import PortalComponent from 'components/Portal/PortalComponent';
import SparePartDetails from 'components/SparePartDetails';
import SpeedDial from 'components/SpeedDial/SpeedDial';
import SpeedDialAction from 'components/SpeedDial/SpeedDialAction';
import SpeedDialIcon from 'components/SpeedDial/SpeedDialIcon';
import Box from 'components/atoms/Box';
import SparePartList from 'components/molecules/SparePartList';

import { SparePart } from 'types/spare-part';

const StyledSpeedDial = styled(SpeedDial)((props) => ({
  position: 'fixed',
  bottom: props.theme.space[9],
  right: props.theme.space[2],
  color: 'green',
}));

const SPARE_PART_LOCATION_TYPE = ['workshop', 'repairman'];

const SparePartStock: React.FunctionComponent = () => {
  const { employee } = useEmployee();

  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);

  const [isTransferSparePartModalOpen, setIsTransferSparePartModalOpen] = useState<boolean>(false);
  const [isSparePartDetailsOpen, setIsSparePartDetailsOpen] = useState<boolean>(false);
  const [isSparePartDefectOpen, setIsSparePartDefectOpen] = useState<boolean>(false);
  const [isInventoryOpen, setIsInventoryOpen] = useState<boolean>(false);

  const [selectedSparePart, setSelectedSparePart] = useState<SparePart>();
  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleOpenSkuDetails = (record: SparePart) => {
    setIsSparePartDetailsOpen(true);
    setSelectedSparePart(record);
  };

  const handleCloseSkuDetails = () => {
    setIsSparePartDetailsOpen(false);
    setSelectedSparePart(undefined);
  };

  const actions = [
    {
      icon: <ImportExportIcon />,
      tooltipTitle: 'Transfert',
      onClick: () => setIsTransferSparePartModalOpen(true),
      tooltipOpen: true,
    },
    {
      icon: <YoutubeSearchedForIcon />,
      tooltipTitle: 'Inventaire',
      onClick: () => setIsInventoryOpen(true),
      tooltipOpen: true,
    },

    {
      icon: <ErrorIcon />,
      tooltipTitle: 'Déclarer une pièce défectueuse',
      onClick: () => setIsSparePartDefectOpen(true),
      tooltipOpen: true,
    },
  ];

  const speedDialProps = {
    ariaLabel: 'sparePart',
    icon: <SpeedDialIcon />,
    onClose: handleCloseSpeedDial,
    onOpen: handleOpenSpeedDial,
    open: openSpeedDial,
  };

  return (
    <>
      <Box overflow="auto" height="calc(100vh - 165px)">
        {!isSparePartDefectOpen && (
          <SparePartList
            filter={{ workshop: employee.workshop }}
            handleSparePartSelection={handleOpenSkuDetails}
            showBooking
            showOnboardedStock
            showBroken
          />
        )}
      </Box>

      {isTransferSparePartModalOpen && (
        <PortalComponent
          open={isTransferSparePartModalOpen}
          onClose={() => setIsTransferSparePartModalOpen(false)}
          title="Transfert de pièces"
        >
          <StoreTransfer
            sparePartFilter={{ spare_part_location_type: SPARE_PART_LOCATION_TYPE }}
            locationFilter={{ location_type: SPARE_PART_LOCATION_TYPE }}
          />
        </PortalComponent>
      )}
      {selectedSparePart && (
        <SparePartDetails
          open={isSparePartDetailsOpen}
          onClose={handleCloseSkuDetails}
          selectedSparePart={selectedSparePart}
        />
      )}

      {isSparePartDefectOpen && (
        <SparePartDefectsDeclaration
          filter={{ workshop: employee.workshop }}
          handleClose={() => setIsSparePartDefectOpen(false)}
        />
      )}

      {isInventoryOpen && employee.workshop && (
        <WorkshopSparePartInventory handleCloseInventory={() => setIsInventoryOpen(false)} />
      )}

      <StyledSpeedDial {...speedDialProps}>
        {actions.map((action) => (
          <SpeedDialAction key={action.tooltipTitle} {...action} />
        ))}
      </StyledSpeedDial>
    </>
  );
};

export default SparePartStock;
