import React from 'react';

import Box from '../Box';

type ListItemContainerProps = {
  children: React.ReactNode;
};

const ListItemContainer: React.FunctionComponent<ListItemContainerProps> = ({
  children,
  ...props
}: ListItemContainerProps) => (
  <Box
    display="flex"
    my={2}
    width="100%"
    alignItems="center"
    height="30px"
    justifyContent="space-between"
    borderRadius="4px"
    {...props}
  >
    {children}
  </Box>
);

export default ListItemContainer;
