import React from 'react';

import { MarginProps } from 'styled-system';

import FlatCard from 'components/FlatCard';
import Text from 'components/atoms/Text';

type StatBlockProps = {
  title: string;
  subTitle: string;
} & MarginProps;

const StatBlock: React.FunctionComponent<StatBlockProps> = ({ title, subTitle, ...rest }) => {
  return (
    <FlatCard variant="primary" p={3} flexGrow={1} {...rest}>
      <Text variant="title1" color="green">
        {title}
      </Text>
      <Text variant="small" color="grey">
        {subTitle}
      </Text>
    </FlatCard>
  );
};
export default StatBlock;
