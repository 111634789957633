import React from 'react';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BuildIcon from '@material-ui/icons/Build';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ReorderIcon from '@material-ui/icons/Reorder';

import NavButton from 'components/BottomBar/NavButton';
import Box from 'components/atoms/Box';

const ReconditionBottomBar: React.FunctionComponent = () => {
  return (
    <Box
      bg="white"
      py={1}
      display="flex"
      justifyContent="space-around"
      borderColor="grey500_2"
      borderTopWidth="1px"
      borderTopStyle="solid"
      position="absolute"
      bottom={0}
      width="100%"
    >
      <NavButton to="/recondition/transfer/" icon={<ImportExportIcon />}>
        Transfert
      </NavButton>{' '}
      <NavButton to={'/recondition/spare-part-availability-monitoring'} icon={<AccessTimeIcon />}>
        Attente pièce
      </NavButton>
      <NavButton to={'/recondition/repair-cycle'} icon={<BuildIcon />}>
        Reconditionner
      </NavButton>
      <NavButton to="/recondition/stock/" icon={<ReorderIcon />}>
        Stock
      </NavButton>
    </Box>
  );
};

export default ReconditionBottomBar;
