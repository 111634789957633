// React imports
import React, { useEffect } from 'react';

// Icons
import { Paper, Typography } from '@material-ui/core';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import RepairReportNameplateStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportNameplateStyle';

const useStyles = makeStyles(RepairReportNameplateStyle);

export default function ClaimConfirmationScreen(props) {
  const classes = useStyles();
  useEffect(() => {
    const timer = setTimeout(() => {
      props.setIsSubmit(false);
    }, 2000);
    return () => clearTimeout(timer);
  });

  return (
    <div>
      <Paper className={classes.nameplateContainer}>
        {
          <div className={classes.confirmationScreenContent}>
            <CheckCircleIcon className={classes.biggerCheckedIcon} />
            <div>
              <Typography variant="h6" gutterBottom>
                {props.isManager
                  ? 'La réclamation a bien été traitée !'
                  : 'La réclamation a bien été enregistrée !'}
              </Typography>
            </div>
          </div>
        }
      </Paper>
    </div>
  );
}
