import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Field, useField, useForm } from 'react-final-form';

import RepairReportSnackbar from 'pages/RepairReport/components/formComponents/RepairReportSnackbar';
import RepairReportSubmitButton from 'pages/RepairReport/components/formComponents/RepairReportSubmitButton';
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
import { numericKeypad } from 'pages/RepairReport/services/stepHelper';

import TimeEvaluationStyle from 'utils/styles/TimeEvaluationStyle';

const TimeEvaluationSlider = withStyles({
  root: {
    color: '#F7B349',
    height: 5,
    marginTop: 15,
    marginBottom: 30,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles(TimeEvaluationStyle);
export default function TimeEvaluation(props) {
  const classes = useStyles();

  const {
    input: { onChange: setTaskDuration },
  } = useField(
    props.formProductIdReference ? props.formProductIdReference + '.task_duration' : 'task_duration'
  );
  const [isDisplay, setIsDisplay] = useState(false);

  const handleSetDuration = (value) => {
    setTaskDuration(value);
  };

  const form = useForm();
  const formdata = form.getState().values; // accesses directly to the form values
  const taskDuration =
    formdata.task_duration ||
    (formdata &&
      formdata[props.formProductIdReference] &&
      formdata[props.formProductIdReference].task_duration);

  return (
    <>
      <div className={classes.TimeEvaluationContainer}>
        <TimeEvaluationSlider
          aria-label="Time Evaluation"
          step={5}
          max={90}
          min={10}
          defaultValue={10}
          valueLabelDisplay="on"
          onChange={(e, val) => handleSetDuration(val)}
        />
        {!isDisplay && (
          <Typography
            variant="h5"
            className={classes.pieceNumber}
            onClick={() => setIsDisplay(true)}
          >
            + de 90 minutes
          </Typography>
        )}
        {isDisplay && (
          <>
            <Typography variant="h5" className={classes.textDescription}>
              L'intervention m'a demandé plus de 90 min
            </Typography>
            <div>
              <div className={classes.timeContainer}>
                <Field name={`${props.formProductIdReference}.task_duration`} key={`taskSlider`}>
                  {(props) => (
                    <TextField
                      variant="outlined"
                      className={classes.basicTextArea}
                      name={props.name}
                      value={props.input.value}
                      onChange={(value) => handleSetDuration(value)}
                      placeholder="Temps passé"
                      inputProps={numericKeypad}
                    />
                  )}
                </Field>
              </div>
            </div>
          </>
        )}
      </div>
      {props.formProductIdReference ? (
        <RepairReportValidateButton
          buttonLabel={`Continuer`}
          buttonValue="validate"
          validateFunction={() => props.validateFunction(true)}
          formProductIdReference={props.formProductIdReference}
          step={props.step}
        />
      ) : (
        <div className={classes.button}>
          <RepairReportSubmitButton
            buttonLabel={props.buttonLabel}
            handleSubmit={props.handleSubmit}
            isLoading={props.isLoading}
            formUploadProgress={props.formUploadProgress}
            disabled={!taskDuration}
          />
        </div>
      )}

      {((props.errorMessage && props.isErrorShown) || props.isDataMissing) && (
        <RepairReportSnackbar
          errorMessage={props.errorMessage}
          isDataMissing={props.isDataMissing}
          messageForMissingData={props.messageForMissingData}
          dataMissing={props.dataMissing}
        />
      )}
    </>
  );
}
