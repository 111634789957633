import React from 'react';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { FormSkuModel } from 'types/form-sku-models';

type SummaryProps = {
  skuModels: FormSkuModel[];
};

const getQuantityAndTotalPrice = (
  array: FormSkuModel[]
): { nbParts: number; totalPrice: number } => {
  const { nbParts, totalPrice } = array.reduce(
    (acc, item) => {
      acc.nbParts += item.quantity;
      acc.totalPrice += item.quantity * item.supplierReference.price;
      return acc;
    },
    { nbParts: 0, totalPrice: 0 }
  );
  return { nbParts: nbParts, totalPrice: totalPrice };
};

const Summary: React.FunctionComponent<SummaryProps> = ({ skuModels }: SummaryProps) => {
  const retrievedSpareParts = skuModels.filter((skuModel) => skuModel.quantity > 0);
  const { nbParts, totalPrice } = getQuantityAndTotalPrice(retrievedSpareParts);

  return (
    <>
      {retrievedSpareParts.length > 0 && (
        <Box px={1} pb={1} margin="auto" display="flex" justifyContent="space-between">
          <Text color="turquoise" variant="body">
            {nbParts} pièce{nbParts > 1 && 's'}
          </Text>
          <Text variant="subtitleBold">{(totalPrice / 3 / 100).toFixed(2)} €</Text>
        </Box>
      )}
    </>
  );
};

export default Summary;
