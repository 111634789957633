import React from 'react';

import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import RadioField from 'components/molecules/RadioField';
import ModalContainer from 'components/organisms/modals/ModalContainer';

type listItem = {
  id: string;
  name: string;
};

export type FormSelectModalProps = {
  list: listItem[];
  fieldName: string;
  title: string;
  isChecked: (value: string) => boolean;
  onValueSelection: (value: string) => void;
} & MarginProps;

const FormSelectModal: React.FunctionComponent<FormSelectModalProps> = ({
  list,
  title,
  fieldName,
  onValueSelection,
  isChecked,
}: FormSelectModalProps) => {
  return (
    <ModalContainer>
      <Box textAlign="center">
        <Text variant="subtitle" mb={3}>
          {title}
        </Text>
        {list.map((item) => (
          <RadioField
            onChoiceClick={(htmlItem) => onValueSelection(htmlItem.value)}
            key={item.id}
            value={item.id}
            label={item.name}
            name={fieldName}
            checked={isChecked(item.id)}
            mt={2}
          />
        ))}
      </Box>
    </ModalContainer>
  );
};

export default FormSelectModal;
