import React from 'react';

import { SvgIconProps } from '@material-ui/core/SvgIcon';

import styled from 'styled-components';
import { LayoutProps, MarginProps, layout, margin } from 'styled-system';

export type ButtonIconProps = {
  handleClick: () => void;
  icon: React.FunctionComponent<SvgIconProps>;
} & MarginProps &
  LayoutProps;

const Button = styled.button`
  ${margin}
  ${layout}
  border: none;
  /* Reset button font */
  font-size: ${(props) => props.theme.fontSizes[6]}px;
  padding: 0;
  width: fit-content;
  overflow: visible;

  background: transparent;

  color: inherit;

  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`;

const ButtonIcon: React.FunctionComponent<ButtonIconProps> = ({
  handleClick,
  icon,
  ...rest
}: ButtonIconProps) => {
  const Icon = icon;
  return (
    <Button {...rest} onClick={handleClick}>
      <Icon fontSize="inherit" />
    </Button>
  );
};

export default ButtonIcon;
