import React from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { ProductCheckListReportItem } from 'types/product-check-list-report-item';

type RepairCycleCheckListItemsProps = {
  productCheckListReportItems: ProductCheckListReportItem[];
  handleUpdateItem: (index: number) => void;
};

const GreenCheck = styled(CheckBoxIcon)((props) => ({
  color: props.theme.colors.turquoise,
  height: 18,
  width: 18,
}));

const EmptyCheck = styled(CheckBoxOutlineBlankIcon)((props) => ({
  color: props.theme.colors.turquoise,
  height: 18,
  width: 18,
}));

const RepairCycleCheckListItems: React.FunctionComponent<RepairCycleCheckListItemsProps> = ({
  productCheckListReportItems,
  handleUpdateItem,
}: RepairCycleCheckListItemsProps) => {
  return (
    <Box ml={2} mr={2}>
      {productCheckListReportItems.map(
        (productCheckListReportItem: ProductCheckListReportItem, index: number) => {
          return (
            <Box
              minHeight={7}
              display="flex"
              alignItems="center"
              key={productCheckListReportItem.text}
              onClick={() => handleUpdateItem(index)}
              borderBottom={
                index < productCheckListReportItems.length - 1 ? '1px solid #DBDFE8' : 'none'
              }
            >
              {productCheckListReportItem.is_checked ? <GreenCheck /> : <EmptyCheck />}
              <Text ml={1} variant="body" textAlign="start">
                {productCheckListReportItem.text}
              </Text>
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default RepairCycleCheckListItems;
