import { createStyles } from '@material-ui/core/styles';

const RepairReportSnackbarStyle = createStyles({
  root: {
    '& > * + *': {
      marginTop: 24,
    },
    textAlign: 'center',
  },
  snackbarContent: {
    backgroundColor: '#f44336',
    margin: 24,
  },
  snackBarTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#fff',
  },
  errorMessageStyle: {
    fontSize: 11,
  },
});

export default RepairReportSnackbarStyle;
