import React, { useEffect, useState } from 'react';

import dataProvider from 'dataProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';

import RepairCycleCheckListItems from 'pages/RepairCycleTasks/RepairCycleCheckList/RepairCycleCheckListItems';
import { DONE, WEAR_CHECK_PICTURE_FORM_ID } from 'pages/RepairCycleTasks/RepairCycleUtils';

import { TypeformQualityPictureWidget } from 'components/TypeformComponents';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import PageLayout from 'components/templates/PageLayout';

import { ProductCheckListElement } from 'types/product-check-list-element';
import { ProductCheckListReportItem } from 'types/product-check-list-report-item';
import { Product } from 'types/products';

import { useProductChecklist } from '../hooks';

type RepairCycleCheckListProps = {
  title: string;
  checklist: string;
  product: Product;
  handleClose: () => void;
};

export const generateReportItemsFromElements = (
  productCheckListElements: ProductCheckListElement[]
): ProductCheckListReportItem[] =>
  productCheckListElements?.map((element: ProductCheckListElement) => ({
    text: element.display_text,
    is_checked: false,
  }));

const formatProductCheckListReport = (
  productCheckListReportItems: ProductCheckListReportItem[],
  productId: number,
  checklist: string
) => ({
  state: DONE,
  product: productId,
  checklist,
  items: productCheckListReportItems?.map((item) => ({
    is_checked: item.is_checked,
    text: item.text,
  })),
});

const RepairCycleCheckList: React.FunctionComponent<RepairCycleCheckListProps> = ({
  title,
  checklist,
  product,
  handleClose,
}: RepairCycleCheckListProps) => {
  const { productCheckList } = useProductChecklist(product.product_type, checklist);
  const { pictureProof } = useFlags();

  const [productCheckListReportItems, setProductCheckListReportItems] = useState<
    ProductCheckListReportItem[]
  >([]);
  const [qualityPictureOpen, setQualityPictureOpen] = useState(pictureProof);
  useEffect(() => {
    if (productCheckList) {
      setProductCheckListReportItems(generateReportItemsFromElements(productCheckList));
    }
  }, [productCheckList]);

  const handleUpdateItem = (index: number) => {
    const items = [...productCheckListReportItems];
    items[index].is_checked = !items[index].is_checked;
    setProductCheckListReportItems(items);
  };

  const handleSubmitProductCheckListReport = () => {
    dataProvider
      .create('product-check-list-reports', {
        data: formatProductCheckListReport(productCheckListReportItems, product.id, checklist),
      })
      .then(() => {
        handleClose();
      });
  };

  return (
    <PageLayout>
      <Box overflow="auto">
        <HeaderWithArrow title={title} action={handleClose} />
      </Box>
      {qualityPictureOpen && (
        <TypeformQualityPictureWidget
          barcode={product.barcode}
          onClose={() => setQualityPictureOpen(false)}
          formId={WEAR_CHECK_PICTURE_FORM_ID}
        />
      )}
      {!qualityPictureOpen && (
        <>
          <Box backgroundColor={'white'} mt={2}>
            <RepairCycleCheckListItems
              productCheckListReportItems={productCheckListReportItems}
              handleUpdateItem={handleUpdateItem}
            />
          </Box>
          <BottomButton onClick={handleSubmitProductCheckListReport}>Confirmer</BottomButton>
        </>
      )}
    </PageLayout>
  );
};

export default RepairCycleCheckList;
