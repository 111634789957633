import { FittingType } from 'types/sku';

interface Choice {
  name:
    | "C'est la pièce d'origine"
    | "C'est une pièce adaptable, elle convient parfaitement à ce modèle de machine"
    | "La pièce n'est pas vraiment adaptable, je me suis débrouillé(e) pour la poser";
  value: FittingType;
}
export const sparePartFittingChoices: Choice[] = [
  { name: "C'est la pièce d'origine", value: 'original' },
  {
    name: "C'est une pièce adaptable, elle convient parfaitement à ce modèle de machine",
    value: 'substitution',
  },
  {
    name: "La pièce n'est pas vraiment adaptable, je me suis débrouillé(e) pour la poser",
    value: 'makeshift',
  },
];
