import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { Form, Formik } from 'formik';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import BottomButton from 'components/atoms/BottomButton';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import CompactRadioField from 'components/molecules/CompactRadioField';

import { updateProductsFunctionalStatus, useProduct } from 'utils/api/api';
import {
  PRODUCT_FUNCTIONAL_STATUS,
  PRODUCT_FUNCTIONAL_STATUS_NOT_WORKING,
  PRODUCT_FUNCTIONAL_STATUS_WORKING,
  ProductFunctionnalStatusScreenFormValue,
} from 'utils/products';

import { Product } from '../../../types/products';
import { removeBonusFromQuote } from '../../../utils/api/repair-bonus';
import { useLastBill } from '../../Payment/utils/api';
import DataFetchingStatusScreen from './DataFetchingStatusScreen';
import HeaderWithProductTitle from './HeaderWithProductTitle';

type ProductFunctionnalStatusScreenMatchParams = {
  customerFileId: string;
  visitId: string;
  productId: string;
};
type ProductFunctionnalStatusScreenProps =
  RouteComponentProps<ProductFunctionnalStatusScreenMatchParams> & {
    handleNotWorkingChoice: (productId: number) => void;
  };
const ProductFunctionnalStatusScreen: React.FunctionComponent<
  ProductFunctionnalStatusScreenProps
> = ({
  handleNotWorkingChoice,
  match: {
    params: { customerFileId, visitId, productId },
  },
}: ProductFunctionnalStatusScreenProps) => {
  const history = useHistory();
  const [error, setError] = React.useState<string>();
  const { bill } = useLastBill(customerFileId);
  const { product, loadingProduct, error: getProductError } = useProduct(parseInt(productId));

  const initialValues: ProductFunctionnalStatusScreenFormValue = {
    isRepaired: '',
  };
  const validationSchema = yup.object().shape({
    isRepaired: yup.string().oneOf(PRODUCT_FUNCTIONAL_STATUS).required(),
  });

  localStorage.setItem('productId', productId);

  const pushToApplyBonus = () =>
    history.push(
      `/compte-rendu/${customerFileId}/${productId}/bonus-reparation/appliquer/${visitId}`
    );

  const pushToDashboard = () =>
    history.push(`/compte-rendu/${customerFileId}/dashboard/?visit=${visitId}`);

  const continueToDashboard = (product: Product) => {
    handleNotWorkingChoice(product.id);
    pushToDashboard();
  };

  const handleSelectNotWorkingChoice = async () => {
    try {
      await removeBonusFromQuote(bill, product);
      continueToDashboard(product);
    } catch (error) {
      if (error.status === 404) {
        continueToDashboard(product);
      } else {
        setError('Erreur imprévue');
      }
    }
  };

  const handleSubmit = (values: ProductFunctionnalStatusScreenFormValue) => {
    updateProductsFunctionalStatus([product], [values.isRepaired])
      .then(
        values.isRepaired === PRODUCT_FUNCTIONAL_STATUS_WORKING
          ? pushToApplyBonus
          : handleSelectNotWorkingChoice
      )
      .catch(() => setError('Erreur lors de la mise à jour des informations produit'));
  };

  if (product && !error) {
    return (
      <>
        <HeaderWithProductTitle product={product} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <Box position="absolute" width="100%">
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant="h6">L&apos;appareil est-il fonctionnel ?</Typography>
                  </Box>
                  <CompactRadioField
                    name={'isRepaired'}
                    key={'yes'}
                    value={PRODUCT_FUNCTIONAL_STATUS_WORKING}
                    label={'Oui'}
                  />
                  <CompactRadioField
                    name={'isRepaired'}
                    key={'no'}
                    value={PRODUCT_FUNCTIONAL_STATUS_NOT_WORKING}
                    label={'Non'}
                  />
                </Box>
              </Box>
              <BottomButton variant="transparent" disabled={!isValid || !dirty || isSubmitting}>
                Valider
              </BottomButton>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  return (
    <Box position="absolute">
      <HeaderWithArrow
        title={loadingProduct ? 'Chargement...' : 'Erreur'}
        action={pushToDashboard}
      />
      <DataFetchingStatusScreen
        loading={loadingProduct}
        error={
          (!!getProductError && 'Erreur lors de la récupération des informations produit') || error
        }
        loadingText="Récupération des données produit en cours..."
      />
    </Box>
  );
};

export default ProductFunctionnalStatusScreen;
