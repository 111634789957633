import React, { useEffect, useState } from 'react';

import animationData from 'assets/check.json';
import dataProvider from 'dataProvider';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import SkuDetailCarousel from 'components/SkuDetailCarousel';
import StatusField from 'components/StatusField/StatusField';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import SecondHandIcon from 'components/atoms/SecondHandIcon';
import Text from 'components/atoms/Text';

import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

const TextButton = styled.div((props) => ({
  textDecoration: 'underline',
  color: props.theme.colors.grey900,
  fontSize: props.theme.fontSizes[2],
  marginTop: props.theme.space[6],
}));

const BottomButton = styled(Button)((props) => ({
  marginBottom: props.theme.space[3],
  width: '100%',
  alignSelf: 'self-end',
}));

const ConfirmationContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  height: 'calc(100% - 57px)',
  padding: `0 ${props.theme.space[3]}px`,
  boxSizing: 'border-box',
}));

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type ReceptionConfirmationProps = {
  sku: SparePart | undefined;
  setSku: (sku: SparePart | undefined) => void;
  onConfirm: () => void;
  receptionConfirmationOpen: boolean;
  handleClose: () => void;
  locationToDisplay: string;
};

const ReceptionConfirmation: React.FunctionComponent<ReceptionConfirmationProps> = ({
  sku: sparePart,
  onConfirm,
  receptionConfirmationOpen,
  handleClose,
  setSku,
  locationToDisplay,
}: ReceptionConfirmationProps) => {
  const [locationCodes, setLocationCodes] = useState<string[]>([] as string[]);

  useEffect(() => {
    if (locationToDisplay && sparePart?.sku) {
      dataProvider
        .getList('sku-location', {
          filter: { sku: sparePart?.sku, location_type: locationToDisplay },
        })
        .then(({ data }) => setLocationCodes(data.map((location: SkuLocation) => location?.code)));
    }
  }, [locationToDisplay, sparePart]);

  return (
    <PortalComponent
      open={receptionConfirmationOpen}
      onClose={handleClose}
      title="Identifier une pièce"
    >
      <ConfirmationContainer>
        <Box mt={2}>
          <Lottie options={animationOptions} height={130} width={130} />
        </Box>
        <div>
          <Text variant="title1" mt={2} display="inline-flex">
            {sparePart!.sku_model?.sku_reference}
            {sparePart!.is_second_hand && (
              <Box ml={1}>
                <SecondHandIcon />
              </Box>
            )}
          </Text>
          <Text variant="body" mt={1}>
            {sparePart?.sku_model?.designation}
          </Text>
        </div>
        <div>
          <StatusField status={sparePart!.status} />
        </div>
        <div>
          {(sparePart?.demand || sparePart?.location || locationCodes.length > 0) && (
            <SkuDetailCarousel sku={sparePart as SparePart} locationCodes={locationCodes} />
          )}
        </div>
        <TextButton onClick={() => setSku(undefined)}>Modifier la sélection</TextButton>
        <BottomButton variant="primary" onClick={() => onConfirm()} minWidth={192}>
          Confirmer
        </BottomButton>
      </ConfirmationContainer>
    </PortalComponent>
  );
};

export default ReceptionConfirmation;
