import React from 'react';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { ButtonProps } from 'components/atoms/Button/Button';

const ButtonBlock: React.FunctionComponent<ButtonProps> = ({
  children,
  width = '100%',
  ...rest
}: ButtonProps) => {
  return (
    <Box
      height={8}
      bg="white"
      px={2}
      display="flex"
      width={width}
      flexDirection="column"
      justifyContent="center"
    >
      <Button {...rest}>{children}</Button>
    </Box>
  );
};

export default ButtonBlock;
