import React, { useState } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import IconText from 'components/IconText';
import PortalComponent from 'components/Portal/PortalComponent';
import PaperStrip from 'components/atoms/PaperStrip';

import { IncentiveLabel } from 'types/incentive-label';

import { labelListWithPoints } from 'utils/label-list-with-points';

import IncentiveDescriptionLabelModal from './IncentiveDescriptionLabelModal';
import IncentiveHelpContent from './IncentiveHelpContent';

type IncentiveHelpModalProps = {
  isOpen: boolean;
  isOpenDescription: boolean;
  setIsOpen: (value: boolean) => void;
  setIsOpenDescription: (value: boolean) => void;
} & MarginProps;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)((props) => ({
  marginRight: themeGet('space.1')(props),
  fontSize: themeGet('sizes.3')(props),
}));
const IncentiveHelpModal: React.FunctionComponent<IncentiveHelpModalProps> = ({
  isOpen,
  setIsOpen,
  setIsOpenDescription,
  isOpenDescription,
  ...rest
}: IncentiveHelpModalProps) => {
  const [selectedValue, setSelectedValue] = useState<{ label: IncentiveLabel; title: string }>(
    {} as { label: IncentiveLabel; title: string }
  );
  const handleSelectLabel = (label: { label: IncentiveLabel; title: string }) => {
    setSelectedValue(label);
    setIsOpenDescription(true);
  };

  return (
    <>
      <PaperStrip display="flex" flexDirection="column" {...rest} onClick={() => setIsOpen(true)}>
        <IconText
          text="Comprendre le système"
          icon={<StyledInfoOutlinedIcon />}
          color="grey700"
          variant="body"
        />
      </PaperStrip>
      <PortalComponent title="Comprendre le système" open={isOpen} onClose={() => setIsOpen(false)}>
        <IncentiveHelpContent
          labelListWithPoints={labelListWithPoints}
          handleSelectLabel={handleSelectLabel}
        />
      </PortalComponent>
      <IncentiveDescriptionLabelModal
        isOpenDescription={isOpenDescription}
        setIsOpenDescription={setIsOpenDescription}
        selectedValue={selectedValue}
      />
    </>
  );
};

export default IncentiveHelpModal;
