import React, { useEffect, useState } from 'react';

import GoBackHeader from '../../../components/molecules/GoBackHeader';
import { Product } from '../../../types/products';
import { useProductModel } from '../../../utils/api/api';
import { formatProductTitle } from '../../../utils/products';

const HeaderWithProductTitle = ({ product }: { product: Product }): JSX.Element => {
  const productModel = useProductModel(product.product_model);
  const [title, setTitle] = useState('');

  useEffect(() => {
    let isMounted = true;

    if (productModel && isMounted && productModel.brand && productModel.product_type) {
      setTitle(
        formatProductTitle({
          ...product,
          product_type: productModel.product_type.name,
          brand: productModel.brand.name,
        })
      );
    } else if (isMounted) {
      setTitle(formatProductTitle(product));
    }

    return () => {
      isMounted = false;
    };
  }, [product, productModel]);

  return <GoBackHeader title={title} />;
};

export default HeaderWithProductTitle;
