import React from 'react';

import dataProvider from 'dataProvider';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import ButtonBlock from 'components/molecules/ButtonBlock';
import FormErrorMessage from 'components/molecules/FormErrorMessage';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ImageSelector from 'components/molecules/ImageSelector';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { validationSchemaPaymentProof } from '../validationSchema';

const StyledForm = styled(Form)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
});
const StyledContentContainer = styled(ContentContainer)({
  padding: 0,
  width: '90%',
  paddingBottom: 120,
});

type PaymentProofProps = {
  amount: number;
  customerFileId: string;
  visitId: string;
  employee_id: number;
  paymentMethod: string;
  onSubmit: () => void;
  onFailure: () => void;
};
type VisitPayment = {
  amount: number;
  payment_method: string;
  visit: number;
  customer_file: number;
  employee: number;
  image?: File;
};

const PaymentProof: React.FunctionComponent<PaymentProofProps> = ({
  customerFileId,
  visitId,
  paymentMethod,
  amount,
  onSubmit,
  onFailure,
  employee_id,
}) => {
  const renderImage = (image: File[]) => {
    return <Image src={URL.createObjectURL(image[0])} alt="preview" />;
  };

  type FormValues = {
    image: File[] | null;
  };

  const initialValues: FormValues = {
    image: null,
  };

  const createPayment = async (values: FormValues) => {
    const formValues: VisitPayment = {
      amount: amount,
      payment_method: paymentMethod,
      visit: parseInt(visitId),
      customer_file: parseInt(customerFileId),
      employee: employee_id,
      image: values?.image?.[0],
    };

    try {
      await dataProvider.create(
        'visit-payment',
        {
          data: formValues,
        },
        'formData'
      );
      onSubmit();
    } catch (errors) {
      onFailure();
    }
  };
  return (
    <PageLayout>
      <GoBackHeader title="Preuve de paiement" />
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={createPayment}
        validationSchema={validationSchemaPaymentProof}
      >
        {({ values, isSubmitting, isValid }) => {
          return (
            <StyledForm>
              <StyledContentContainer>
                {values.image ? (
                  <>
                    {renderImage(values.image as File[])}
                    <Field>
                      {({ form }: FieldProps) => {
                        return (
                          <Button
                            width="100%"
                            type="button"
                            variant="transparent"
                            onClick={() => form.setFieldValue('image', null)}
                          >
                            Recommencer
                          </Button>
                        );
                      }}
                    </Field>
                  </>
                ) : (
                  <Field name="image">
                    {({ field: { name, value }, form }: FieldProps) => {
                      return value ? (
                        renderImage(value)
                      ) : (
                        <ImageSelector
                          name={name}
                          onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
                            form.setFieldValue('image', event.currentTarget.files)
                          }
                        />
                      );
                    }}
                  </Field>
                )}
              </StyledContentContainer>
              <ErrorMessage name="image" component={FormErrorMessage} />
              <ErrorMessage name="amount" component={FormErrorMessage} />
              <ErrorMessage name="paymentMethod" component={FormErrorMessage} />
              <ButtonBlock disabled={!isValid || isSubmitting}>Soumettre</ButtonBlock>
            </StyledForm>
          );
        }}
      </Formik>
    </PageLayout>
  );
};

export default PaymentProof;
