import React from 'react';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

type TaskItemProps = {
  text: string;
  removeable: boolean;
  onRemove?: () => void;
};

type IconProps = {
  className?: string;
};

const StyledRemoveCircleOutlineIcon = styled(RemoveCircleOutlineIcon)<IconProps>(
  (props: IconProps) => ({
    color: themeGet('colors.grey300')(props),
    width: themeGet('space.3')(props),
    height: themeGet('space.3')(props),
  })
);

const ItemDetailField: React.FunctionComponent<TaskItemProps> = ({
  text,
  onRemove,
  removeable,
}: TaskItemProps) => {
  return (
    <div>
      <PaperStrip display="flex" justifyContent="space-between">
        <Text variant="smallBold" color="dark" textAlign="start">
          {text}
        </Text>
        {removeable && onRemove && (
          <StyledRemoveCircleOutlineIcon id="remove-icon" onClick={() => onRemove()} />
        )}
      </PaperStrip>
    </div>
  );
};

export default ItemDetailField;
