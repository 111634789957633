import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

const BulletList = styled.ul<SpaceProps>(
  {
    margin: 0,
    '> :not(:last-child)': {
      marginBottom: 8,
    },
  },
  space
);

export default BulletList;
