import React from 'react';

import { Product } from 'types/products';
import { SparePart } from 'types/spare-part';

import { isItemInList } from 'utils/list';

export type Item = SparePart | Product;

export type ItemInventoryProps = {
  itemList: Item[];
  declareSurplus: () => void;
  onSuccess: () => void;
  duplicateWarning: () => void;
};

export function useInventory({
  itemList,
  declareSurplus,
  onSuccess,
  duplicateWarning,
}: ItemInventoryProps): {
  shouldDisplayBaseList: boolean;
  submitInventory: (baseItemList: Item[], selectedItemList: Item[]) => void;
  getItemsToDeclareLost: () => Item[];
  getSurplusItemList: () => Item[];
  selectedItemList: Item[];
  handleItemSelection: (item: Item) => void;
  forceItemsSelection: (items: Item[]) => void;
} {
  const [selectedItemList, setSelectedItemList] = React.useState<Item[]>([] as Item[]);
  const [currentContext, setCurrentContext] = React.useState<string>('selection');

  const [shouldDisplayBaseList, setShouldDisplayBaseList] = React.useState<boolean>(false);

  const handleItemSelection = (item: Item) => {
    if (isItemInList(item, selectedItemList)) {
      duplicateWarning();
    } else if (!isItemInList(item, selectedItemList)) {
      setSelectedItemList([...selectedItemList, item]);
    }
  };
  const forceItemsSelection = (items: Item[]) => {
    setSelectedItemList(items);
  };

  const submitInventory = (baseItemList: Item[], selectedItemList: Item[]) => {
    setCurrentContext('submission');
    declareSurplus();
    baseItemList = [...baseItemList, ...getSurplusItemList()];
    if (selectedItemList.length !== baseItemList.length) {
      // display base list after first inventory submit
      setShouldDisplayBaseList(true);
    } else {
      // case when all the Items to select are selected => open confirmation screen and empty lists
      onSuccess();
    }
  };

  const getSurplusItemList = () => {
    return selectedItemList.filter((item) => !itemList.map((x) => x.id).includes(item.id));
  };

  const getItemsToDeclareLost = () => {
    if (currentContext === 'submission') {
      return itemList.filter((item) => !selectedItemList.map((x) => x.id).includes(item.id));
    } else {
      return [];
    }
  };

  return {
    shouldDisplayBaseList,
    submitInventory,
    getItemsToDeclareLost,
    getSurplusItemList,
    selectedItemList,
    handleItemSelection,
    forceItemsSelection,
  };
}
