import React from 'react';

import HistoryTicket from 'pages/CustomerFileHistory/componnents/HistoryTicket';

import PaperStripText from 'components/PaperStripText';
import PaperStripUnfoldsWithArrow from 'components/PaperStripUnfoldsWithArrow';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { CustomerFileHistory as customerFileHistoryType } from 'types/customer-file-history';
import { CustomerFileTicket } from 'types/customer-file-history';

import { useCustomerFileHistory } from './utils/hooks';

type CustomerFileHistoryContentProps = {
  customerFileId: string;
};

const CustomerFileHistoryContent: React.FunctionComponent<CustomerFileHistoryContentProps> = ({
  customerFileId,
}: CustomerFileHistoryContentProps) => {
  const historyQuery = useCustomerFileHistory(customerFileId);

  if (!historyQuery.isSuccess) return null;
  if (!historyQuery.customerFileHistory.length) {
    return (
      <Text mx={2} mt={2} textAlign="start" variant={'body'}>
        Aucun historique pour le moment
      </Text>
    );
  }
  return (
    <Box textAlign="start" overflow="auto">
      <PaperStripText
        title={'Client'}
        content={`${historyQuery.customerFileHistory[0].firstname} ${historyQuery.customerFileHistory[0].lastname}`}
      />
      {historyQuery.customerFileHistory &&
        historyQuery.customerFileHistory.map((history: customerFileHistoryType) => (
          <div key={history.id}>
            {history.tickets.length > 0 &&
              history.tickets.map((ticket: CustomerFileTicket) => (
                <React.Fragment key={ticket.id}>
                  <PaperStripUnfoldsWithArrow
                    title={`Passage ${history.visit_number}`}
                    content={<HistoryTicket ticket={ticket} payment={history.visit_payment} />}
                  />
                  <Text variant={'body'}>
                    {ticket.user_diagnostic &&
                      ticket.user_diagnostic
                        .split('%-%')
                        .map((step, index) => <li key={index}>{step}</li>)}
                  </Text>
                </React.Fragment>
              ))}
            {history.tickets.length === 0 && (
              <PaperStripUnfoldsWithArrow
                title={`Passage ${history.visit_number}`}
                content={
                  <PaperStripText
                    title={`Date du passage`}
                    content={`${new Date(
                      history.timeslot.beginning
                    ).toLocaleDateString()} ${new Date(
                      history.timeslot.beginning
                    ).toLocaleTimeString()}`}
                  />
                }
              />
            )}
          </div>
        ))}
    </Box>
  );
};

export default CustomerFileHistoryContent;
