import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import { Incentive } from 'types/incentives';

type GroupedIncentives = {
  [key: string]: Incentive[];
};

export const groupBy = (
  incentiveList: Incentive[],
  key: Exclude<keyof Incentive, 'previous_incentive' | 'manager' | 'customer_file_id'>
): GroupedIncentives => {
  return incentiveList.reduce((result: GroupedIncentives, currentValue: Incentive) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

export const getMostRecentIncentive = (incentiveList: Incentive[]): Incentive => {
  return incentiveList.reduce((prev, current) =>
    prev.created_at > current.created_at ? prev : current
  );
};

export const getTotalPoints = (incentiveList: Incentive[]): number => {
  return incentiveList.reduce((total, current) => total + current.points, 0);
};

export const getInterventionsCount = (groupedByIncentives: GroupedIncentives): number => {
  return Object.keys(groupedByIncentives).reduce(
    (total, label) => total + groupedByIncentives[label].length,
    0
  );
};

export const groupByProduct = (
  groupedByIncentives: GroupedIncentives
): Record<string, GroupedIncentives> => {
  const groupByVisitAndProduct: Record<string, GroupedIncentives> = {};
  Object.keys(groupedByIncentives).forEach(
    (product) =>
      (groupByVisitAndProduct[product] = groupBy(groupedByIncentives[product], 'product'))
  );
  return groupByVisitAndProduct;
};

export const getLastIncentive = (incentiveList: Record<string, GroupedIncentives>): Incentive[] => {
  return Object.keys(incentiveList).flatMap((visit) =>
    Object.values(incentiveList[visit]).map((incentives) => getMostRecentIncentive(incentives))
  );
};

export const incentiveListSliceCreatedAt = (incentiveList: Incentive[]): Incentive[] => {
  return incentiveList
    .map((incentive) => ({
      ...incentive,
      created_at: incentive.created_at.slice(0, 10),
    }))
    .flat();
};

type FormattedIncentives = {
  count: number;
  points: number;
  withDateFormatted: Incentive[];
  groupedByLabel: GroupedIncentives;
};

export const formatRawIncentives = (rawIncentives: Incentive[]): FormattedIncentives => {
  const incentivesWithoutRequalifications = rawIncentives.filter(
    (incentive) => incentive.point_type !== 'label_requalification'
  );
  const incentivesWithoutUselessReports = incentivesWithoutRequalifications.filter(
    (incentive) => incentive.label !== 'CR-useless'
  );
  const incentiveGroupedByLabel = groupBy(
    getLastIncentive(groupByProduct(groupBy(incentivesWithoutUselessReports, 'visit'))),
    'label'
  );
  const incentivesWithFormattedDate = incentiveListSliceCreatedAt(
    incentivesWithoutRequalifications
  );
  return {
    count: getInterventionsCount(incentiveGroupedByLabel),
    points: getTotalPoints(rawIncentives),
    withDateFormatted: incentivesWithFormattedDate,
    groupedByLabel: incentiveGroupedByLabel,
  };
};

export const renderDate = (dateValue: Date | string): string => {
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const date = dayjs(dateValue);
  return `${days[date.day()]} ${date.locale('fr').format('D/MM/YY')}`;
};

export const renderDateSlot = (date: Date | string): string => {
  const monthsList = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const dateSlotEnd = dayjs(date).add(1, 'month');
  const dateSlotStart = dayjs(date);
  return `26 ${monthsList[dateSlotStart.month()]} au 25 ${monthsList[dateSlotEnd.month()]}`;
};
