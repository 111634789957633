import { createStyles } from '@material-ui/core/styles';

const RepairmanStockStyles = createStyles({
  RepairmanStockAppBar: {
    background: '#fff',
    color: '#2B3B5C',
    boxShadow: 'none',
    borderRadius: 'unset',
    width: '100vw',
  },
  title: {
    marginLeft: 16,
  },
  headerReceptionList: {
    color: '#2B3B5C',
    fontSize: 18,
    fontWeight: 'normal',
    padding: '30px 0 10px 19px',
  },
  list: {
    width: '100vw',
  },
  packageFieldRow: {
    backgroundColor: '#F1F0F5',
  },
  packageFieldContainer: {
    height: 56,
    color: '#2B3B5C',
    display: 'grid',
    backgroundColor: 'white',
    width: 'calc(100vw - 32px)',
    borderRadius: 10,
  },
  packageFieldContainerReceived: {
    height: 77,
    color: '#2B3B5C',
    display: 'grid',
    backgroundColor: '#DBDFE8',
    width: 'calc(100vw - 32px)',
    borderRadius: 10,
  },
  packageFieldDate: {
    padding: '16px 0 0 16px',
    fontSize: 18,
    position: 'relative',
  },
  packageFieldSku: {
    fontSize: 15,
    paddingLeft: 16,
  },
  detailContainer: {
    background: '#F1F0F5',
    height: '100vh',
    paddingBottom: 82,
  },
  listSkuPackageContainer: {
    paddingLeft: '12px',
    paddingRight: '12px',
    position: 'relative',
    paddingBottom: 132,
    height: 'calc(100vh - 120px)',
    overflow: 'auto',
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    borderRadius: 0,
    paddingLeft: '20px',
    backgroundColor: '#fff',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100vw - 32px)',
    height: 64,
  },
  button: {
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B3B5C',
    height: 42,
    width: '100%',
    borderRadius: 8,
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#8795B3',
    },
  },
  buttonOrange: {
    backgroundColor: '#FCD660',
  },
  buttonRed: {
    backgroundColor: '#F85B5B',
    color: 'white',
  },
  skuFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  skuFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    paddingLeft: 16,
  },
  invisible: {
    display: 'None',
  },
  skuFieldRef: {
    fontSize: 15,
    color: '#2B3B5C',
    fontWeight: 'normal',
    width: '15%',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: 'auto',
    top: 3,
    position: 'relative',
  },
  skuFieldDesignation: {
    fontSize: 15,
    color: '#8795B3',
    position: 'relative',
    fontWeight: 'bold',
    maxWidth: '45vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    top: 3,
  },
  receiveSkuRow: {
    backgroundColor: 'white',
    height: 52,
    '& .MuiTableCell-sizeSmall:last-child': {
      borderRadius: 8,
      width: 'calc(100vw - 32px)',
    },
  },
  receiveSkuLossModalRow: {
    background: 'white',
    width: 'calc(100vw - 32px)',
    marginLeft: 16,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    height: 52,
    marginBottom: 8,
  },
  receiveSkuLossModalRowWrapper: {
    width: '100%',
  },
  packageTable: {
    backgroundColor: '#F1F0F5',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
  },
  lossIcon: {
    position: 'absolute',
    right: 8,
    color: '#8795B3',
  },
  skuFieldLostText: {
    fontSize: 15,
    fontWeight: 'normal',
    position: 'absolute',
    right: 32,
  },
  stockRowStyle: {
    height: 56,
    backgroundColor: '#F1F0F5',
  },
  stockRow: {
    display: 'flex',
    fontWeight: 'normal',
  },
  stockDescriptionRow: {
    fontSize: 18,
    color: '#2B3B5C',
  },
  stockHasDemandIcon: {
    marginLeft: 8,
    verticalAlign: 'middle',
    color: '#F7B349',
    fontSize: 10,
  },
  stockRefRow: {
    color: '#8795B3',
    marginLeft: 'auto',
  },
  divider: {
    position: 'relative',
    top: 16,
    borderBottom: '1px solid #DBDFE8',
  },
  labelList: {
    paddingTop: 64,
  },
  labelRow: {
    height: 56,
    background: '#fff',
  },
  labelText: {
    color: '#2B3B5C',
    fontSize: 18,
    fontWeight: 'normal',
  },
  filterButtonWrapper: {
    display: 'flex',
    width: 'calc(100vw - 32px)',
    height: 64,
    alignSelf: 'center',
    paddingTop: 10,
  },
  skuHeaderNumber: {
    position: 'absolute',
    color: '#8795B3',
    right: 19,
    fontSize: 15,
  },
  RepairmanStokReceptionContainer: {
    /**
     * This is a hack, we need this height because the SpeedDial button is positionned
     * inside the component. It should be instanciated directly at the root of the application.
     */
    height: 'calc(100vh - 165px)',
  },
  closeButton: {
    color: '#8795B3',
    position: 'absolute',
    right: 16,
    top: 16,
  },
  repairmanScanHeader: {
    paddingTop: '5vh',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#2B3B5C',
    textAlign: 'center',
  },
  qrContainer: {
    width: '100vw',
  },
  qrReader: {
    maxWidth: '400px',
    margin: 'auto',
  },
  repairmanScanBody: {
    width: '100vw',
  },
  repairmanScanBodyContainer: {
    margin: 'auto',
    width: 'fit-content',
  },
  repairmanScanBodyText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
    padding: '0 16px',
  },
  packageResumeContainer: {
    width: '100vw',
    paddingTop: '35vh',
  },
  packageResumeHeaderContainer: {
    width: 'fit-content',
    margin: 'auto',
    paddingLeft: 50,
  },
  packageResumeIcon: {
    position: 'relative',
    top: 25,
    left: -25,
    color: '#81D89F',
    width: 50,
    height: 50,
  },
  packageResumeCode: {
    fontSize: 28,
    color: '#2B3B5C',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: 48,
  },
  packageResumeActions: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: '#2B3B5C',
    position: 'absolute',
    bottom: 35,
    width: '100vw',
    display: 'grid',
  },
  packageResumeFab: {
    left: '50%',
    paddingBottom: 14,
  },
  errorText: {
    color: 'red',
    padding: '0 24px',
  },
  checkedIcon: {
    width: 24,
    height: 24,
    color: '#81D89F',
    paddingLeft: 16,
    paddingRight: 8,
  },
  cancelIcon: {
    width: 24,
    height: 24,
    color: '#F85B5B',
    paddingLeft: 16,
    paddingRight: 8,
  },
  surplusIcon: {
    width: 24,
    height: 24,
    color: '#F7B349',
    paddingLeft: 16,
    paddingRight: 8,
  },
  customToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100vw',
    height: 72,
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
  },
  buttonToolbar: {
    width: 'calc(calc(100vw - 32px))',
    left: 16,
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B3B5C',
    height: 42,
    borderRadius: 8,
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#8795B3',
    },
    top: 11,
  },
  manualInputText: {
    color: '#8795B3',
    textDecoration: 'underline',
    paddingBottom: 26,
    paddingTop: '8vh',
    width: '100vw',
    textAlign: 'center',
  },
  manualAddText: {
    color: '#8795B3',
    textDecoration: 'underline',
    paddingBottom: 26,
    paddingTop: 32,
    width: '100%',
    textAlign: 'center',
  },
  manualInputWrapper: {
    width: 'fit-content',
    margin: '30vh auto',
  },
  surplusTitle: {
    paddingTop: 35,
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      display: 'none',
    },
  },
  incomingSkuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '32px 16px 16px 16px',
    overflow: 'hidden',
  },
  saveButton: {
    color: '#2B3B5C',
    backgroundColor: '#FCD660',
    borderRadius: 8,
    height: 50,
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-paperFullScreen': {
      backgroundColor: '#F1F0F5',
    },
  },
  spinnerContainer: {
    paddingTop: '4vh',
    height: 100,
  },
  speedDial: {
    position: 'fixed',
    bottom: 16,
  },
  speedDialPage0: {
    right: 16,
  },
  speedDialPage1: {
    right: 'calc(-100vw + 16px)',
  },
  skuTransferListHeader: {
    height: '5%',
  },
  skuTransferList: {
    margin: '0 auto',
    height: '90%',
    width: '100%',
    overflow: ' auto',
  },
  manualAddTextTransfer: {
    color: '#8795B3',
    textDecoration: 'underline',
    paddingBottom: 16,
    paddingTop: 16,
    width: '100%',
    textAlign: 'center',
  },
});

export default RepairmanStockStyles;
