import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const LoadingPage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  alignItems: 'center',
});
const Loader: React.FunctionComponent = () => (
  <LoadingPage>
    <CircularProgress />
  </LoadingPage>
);

export default Loader;
