import React, { useEffect, useState } from 'react';

import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import styled from 'styled-components';

import ProductReception from 'pages/ProductReception/ProductReception';
import { B2B, B2C } from 'pages/ProductReception/utils/helpers';
import DeliveryItemList from 'pages/WorkshopProductReception/component/DeliveryItemList';

import PageHeader from 'components/PageHeader';
import SpeedDial from 'components/SpeedDial/SpeedDial';
import SpeedDialAction from 'components/SpeedDial/SpeedDialAction';
import SpeedDialIcon from 'components/SpeedDial/SpeedDialIcon';
import WorkshopBottomBar from 'components/WorkshopBottomBar/WorkshopBottomBar';
import Box from 'components/atoms/Box';
import InputWithIcon from 'components/molecules/InputWithIcon';
import PageLayout from 'components/templates/PageLayout';

import { DeliveryItem } from 'types/delivery-items';
import { Product } from 'types/products';

export type ProductReceptionProps = {
  productList: Product[];
};
const StyledSpeedDial = styled(SpeedDial)((props) => ({
  position: 'fixed',
  bottom: props.theme.space[9],
  right: props.theme.space[2],
  color: 'green',
}));
const StyledInputWithIcon = styled(InputWithIcon)((props) => ({
  outline: 'none',
  border: 'none',
  height: props.theme.sizes[6],
  borderRadius: props.theme.radii[0],
}));

const WorkshopProductReception: React.FunctionComponent<ProductReceptionProps> = () => {
  const [isProductCreationOpen, setIsProductCreationOpen] = useState<boolean>(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [productSupplier, setProductSupplier] = useState<string | null>(null);
  const [selectedDeliveryItem, setSelectedDeliveryItem] = useState<DeliveryItem | null>(null);
  const [collectionCode, setCollectionCode] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const resetInitialState = () => {
    setSelectedDeliveryItem(null);
    setIsProductCreationOpen(false);
    handleCloseSpeedDial();
    setProductSupplier(null);
  };

  const openProductCreation = (supplier: string) => {
    setProductSupplier(supplier);
    setIsProductCreationOpen(true);
  };
  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const actions = [
    {
      icon: <PeopleIcon />,
      tooltipTitle: 'Collecte',
      tooltipOpen: true,
      onClick: () => openProductCreation(B2C),
    },
    {
      icon: <ShoppingBasketIcon />,
      tooltipTitle: 'B2B',
      tooltipOpen: true,
      onClick: () => openProductCreation(B2B),
    },
  ];

  const speedDialProps = {
    ariaLabel: 'productCreation',
    icon: <SpeedDialIcon />,
    onClose: handleCloseSpeedDial,
    onOpen: handleOpenSpeedDial,
    open: openSpeedDial,
  };

  const handleSelectDeliveryItem = (deliveryItem: DeliveryItem) => {
    setSelectedDeliveryItem(deliveryItem);
    openProductCreation(B2C);
  };

  useEffect(() => {
    // to avoid triggering a getList everytime the search field changes
    const timer = setTimeout(() => {
      setSearchValue(collectionCode);
    }, 500);
    return () => clearTimeout(timer);
  }, [collectionCode]);

  return (
    <PageLayout>
      <PageHeader title="Réception d'appareils" />
      <Box bg="white" borderTop={'2px solid #DBDFE8'}>
        <StyledInputWithIcon
          placeholder="Rechercher"
          name={'search'}
          value={collectionCode}
          onChange={(event) => {
            setCollectionCode(event.target.value);
          }}
          type="string"
          Icon={SearchIcon}
        />
      </Box>
      {!isProductCreationOpen && (
        <DeliveryItemList onClick={handleSelectDeliveryItem} searchValue={searchValue} />
      )}
      {isProductCreationOpen && (
        <ProductReception
          open={isProductCreationOpen}
          handleClose={() => {
            resetInitialState();
          }}
          productSupplier={productSupplier}
          deliveryItem={selectedDeliveryItem}
        />
      )}
      <StyledSpeedDial {...speedDialProps}>
        {actions.map((action) => (
          <SpeedDialAction key={action.tooltipTitle} {...action} />
        ))}
      </StyledSpeedDial>
      <WorkshopBottomBar />
    </PageLayout>
  );
};

export default WorkshopProductReception;
