import React from 'react';

import { MarginProps } from 'styled-system';

import PaperStripItem from 'components/PaperStripItem';
import Box from 'components/atoms/Box';

import { IncentiveLabel } from 'types/incentive-label';
import { IncentiveStatistics, StatisticLabel } from 'types/incentive-statistics';

type IncentiveStatisticsContainerProps = {
  getNumberOfLabel: (label: IncentiveLabel, statsList: StatisticLabel[]) => number;
  getPercentageLabel: (label: IncentiveLabel) => string;
  incentiveStatistics: IncentiveStatistics;
} & MarginProps;

const IncentiveStatisticsContainer: React.FunctionComponent<IncentiveStatisticsContainerProps> = ({
  incentiveStatistics,
  getPercentageLabel,
  getNumberOfLabel,
  ...rest
}: IncentiveStatisticsContainerProps) => {
  const { statistics } = incentiveStatistics;
  const { statistics_to_be_improve } = incentiveStatistics;

  return (
    <Box {...rest}>
      <Box mb={2}>
        <PaperStripItem
          labelName="Terminé"
          percentage={getPercentageLabel('finished')}
          numberOfLabel={`(${getNumberOfLabel('finished', statistics)})`}
        />
        <PaperStripItem
          labelName="Diag"
          percentage={getPercentageLabel('diag')}
          numberOfLabel={`(${getNumberOfLabel('diag', statistics)})`}
        />
      </Box>
      <Box mb={2}>
        <PaperStripItem
          labelName="Mauvaise commande"
          percentage={getPercentageLabel('wrong-command')}
          numberOfLabel={`(${getNumberOfLabel('wrong-command', statistics)})`}
        />
        <PaperStripItem
          labelName="Non réparable"
          percentage={getPercentageLabel('not-repaired')}
          numberOfLabel={`(${getNumberOfLabel('not-repaired', statistics)})`}
        />
        <PaperStripItem
          labelName="Intervention impossible"
          percentage={getPercentageLabel('intervention-impossible')}
          numberOfLabel={`(${getNumberOfLabel('intervention-impossible', statistics)})`}
        />
        <PaperStripItem
          labelName="Aide demandée"
          percentage={getPercentageLabel('help-wanted')}
          numberOfLabel={`(${getNumberOfLabel('help-wanted', statistics)})`}
        />
      </Box>
      <Box>
        <PaperStripItem
          labelName="Passage superflu"
          percentage={`${getNumberOfLabel('superfluous-visit', statistics_to_be_improve)}`}
        />
        <PaperStripItem
          labelName="Refus de devis"
          percentage={`${getNumberOfLabel('quote-refused', statistics_to_be_improve)}`}
        />
      </Box>
    </Box>
  );
};

export default IncentiveStatisticsContainer;
