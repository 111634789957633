import { createStyles } from '@material-ui/core/styles';

const TransferSkuAdditionStyle = createStyles({
  validateButton: {
    color: '#2B3B5C',
    width: 'calc(100vw - 32px)',
    border: '1px solid #DBDFE8',
    height: 56,
    fontSize: 14,
    background: '#FCD660',
    fontWeight: 'bold',
    lineHeight: '140%',
    borderRadius: 8,
    textTransform: 'uppercase',
    position: 'fixed',
    bottom: 16,
    left: 16,
  },
  dashedSquare: {
    border: '1px dashed #8795B3',
    borderRadius: 10,
    height: 188,
    marginTop: 156,
  },
  clickableDiv: {
    width: '100%',
    textAlign: 'center',
    marginTop: 50,
  },
  SmallClickableDiv: {
    position: 'relative',
    textAlign: 'end',
    bottom: 180,
  },
  scanNewSkuButton: {
    width: 72,
    height: 72,
    padding: 22,
    boxSizing: 'border-box',
    background: '#FCD660',
    borderRadius: '50%',
  },
  SkuListContainer: {
    width: '100%',
    height: 'calc(100vh - 250px)',
    marginTop: 24,
    overflow: 'scroll',
  },
  SkuItem: {
    padding: '20px 16px',
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',
  },
  skuItemText: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  removeIcon: {
    color: '#DADADA',
    float: 'right',
  },
  paddingContainer: {
    padding: '0px 24px',
    maxHeight: '100%',
    width: 'calc(100vw - 48px)',
  },
  paddingDiv: {
    padding: '0 24px',
  },
  paddingTop: {
    paddingTop: 48,
  },
  fabAddedList: {
    position: 'fixed',
    bottom: 16,
    left: 16,
  },
});

export default TransferSkuAdditionStyle;
