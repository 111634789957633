import React from 'react';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/TextArea';
import PageLayout from 'components/templates/PageLayout';

import { Product } from 'types/products';

type RepairCycleCommentProps = {
  handleClose: () => void;
  product: Product;
};
type FormValues = {
  comment: string;
};

const RepairCycleComment: React.FunctionComponent<RepairCycleCommentProps> = ({
  handleClose,
  product,
}: RepairCycleCommentProps) => {
  const { employee } = useEmployee();

  const handleSubmitComment = (values: { comment: string }) => {
    dataProvider
      .create('comment', {
        data: {
          author: employee.id,
          text: values.comment,
          workshop_file: product.workshop_file,
        },
      })
      .catch((e) => {
        console.error(e);
      })
      .then(() => {
        handleClose();
      });
  };

  const initialValues: FormValues = {
    comment: '',
  };

  const validationSchema = yup.object().shape({
    comment: yup.string().required(),
  });

  return (
    <PageLayout>
      <HeaderWithArrow title="Commentaire" action={handleClose} />
      <Formik
        onSubmit={handleSubmitComment}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <Field name="comment">
              {({ field: { name, onChange, value } }: FieldProps) => {
                return (
                  <Box mt={4} ml={2} mr={2}>
                    <Text variant="subtitle" textAlign="start" mb={2}>
                      Commentaire
                    </Text>
                    <TextArea
                      width="100%"
                      rows="12"
                      name={name}
                      value={value}
                      onChange={onChange}
                    />
                  </Box>
                );
              }}
            </Field>
            <BottomButton
              variant={'primary'}
              data-testid="bottom-button"
              disabled={isSubmitting || !dirty}
              type="submit"
            >
              Confirmer
            </BottomButton>{' '}
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};

export default RepairCycleComment;
