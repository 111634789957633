import React from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlatCard, { FlatCardList } from 'components/FlatCard';
import Text from 'components/atoms/Text';
import ContentContainer from 'components/templates/ContentContainer';

import { EmployeeWithPoints } from 'types/employees';

type RepairmanCalendarTab = {
  repairmanList: EmployeeWithPoints[];
};

const ArrowIcon = styled(ArrowForwardIcon)`
  color: ${(props) => props.theme.colors.dark};
`;

const RepairmanCalendarTab: React.FunctionComponent<RepairmanCalendarTab> = ({
  repairmanList,
}: RepairmanCalendarTab) => {
  const history = useHistory();

  const goToCalendar = (repairmanId: number) => {
    history.push(`/team/calendar/${repairmanId}`);
  };

  return (
    <ContentContainer>
      <FlatCardList>
        {repairmanList.map((repairman) => (
          <FlatCard
            key={repairman.id}
            display="flex"
            justifyContent="space-between"
            onClick={() => {
              goToCalendar(repairman.id);
            }}
          >
            <Text variant="bodyBold">
              {repairman.first_name} {repairman.last_name}
            </Text>
            <ArrowIcon />
          </FlatCard>
        ))}
      </FlatCardList>
    </ContentContainer>
  );
};

export default RepairmanCalendarTab;
