import React from 'react';

import ContentLoader from 'react-content-loader';

type LoaderPlaceHolderProps = {
  size: 'small' | 'big';
};

const LoaderPlaceHolder: React.FunctionComponent<LoaderPlaceHolderProps> = ({
  size,
}: LoaderPlaceHolderProps) => {
  return (
    <>
      {size === 'big' ? (
        <ContentLoader
          speed={2}
          width={300}
          height={109}
          viewBox="0 0 300 109"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ width: '100%' }}
        >
          <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
          <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
          <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
          <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          <circle cx="20" cy="20" r="20" />
        </ContentLoader>
      ) : (
        <ContentLoader
          speed={2}
          width={400}
          height={100}
          viewBox="0 0 400 100"
          backgroundColor="#d9d9d9"
          foregroundColor="#ecebeb"
          style={{ width: '100%' }}
        >
          <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
          <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
          <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
          <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
          <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
          <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
          <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
          <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
          <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
          <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
          <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
        </ContentLoader>
      )}
    </>
  );
};

export default LoaderPlaceHolder;
