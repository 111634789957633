import React from 'react';

import CleaningTaskForm from 'pages/RepairCycleTasks/RepairCycleCleaningTask/CleaningTaskForm/CleaningTaskForm';

import { TypeformDefaultSideTab } from 'components/TypeformComponents';
import PageLayout from 'components/templates/PageLayout';

import { Product } from 'types/products';

import { useCleaningChecklist } from '../hooks';

type RepairCycleCleaningTaskProps = {
  product: Product;
  handleClose: () => void;
};

const RepairCycleCleaningTask: React.FunctionComponent<RepairCycleCleaningTaskProps> = ({
  product,
  handleClose,
}: RepairCycleCleaningTaskProps) => {
  const { initialValues, loading } = useCleaningChecklist(product);
  return (
    <PageLayout>
      {!loading && (
        <>
          <CleaningTaskForm
            handleClose={handleClose}
            product={product}
            initialValues={initialValues}
          />
          <TypeformDefaultSideTab barcode={product.barcode} />
        </>
      )}
    </PageLayout>
  );
};

export default RepairCycleCleaningTask;
