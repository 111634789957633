import React, { useState } from 'react';

import { CenterFocusStrong } from '@material-ui/icons';

import dataProvider from 'dataProvider';

import SelectProductLocation from 'pages/Store/components/SelectProductLocation';
import WorkshopFileDetailScreen from 'pages/WorkshopFileDetailScreen/WorkshopFileDetailScreen';
import { DELIVERY, SHIPPING } from 'pages/WorkshopShipping/utils';

import ProductSelectionPortal from 'components/ProductSelectionPortal/ProductSelectionPortal';
import Box from 'components/atoms/Box';
import FloatingActionButton from 'components/atoms/FloatingActionButton';

import { Location } from 'types/location';
import { Product } from 'types/products';
import { ShippingDelivery } from 'types/shipping-deliveries';

import { useManyProducts } from 'utils/api/api';

import ShippingProduct from '../ShippingProduct/ShippingProduct';

type ShippingProductTransfersProps = {
  deliveries: ShippingDelivery[];
  locations: Location[];
  isProductSelectionOpen: boolean;
  setIsProductSelectionOpen: (bool: boolean) => void;
  isLocationSelectionOpen: boolean;
  setIsLocationSelectionOpen: (bool: boolean) => void;
};

const getProductIds = (deliveries: ShippingDelivery[]) => {
  return [
    ...Array.from(
      new Set(
        deliveries
          .map((delivery) =>
            delivery.items
              .filter((item) => item.delivery_type === DELIVERY)
              .map((item) => item.product)
          )
          .flat()
      )
    ),
  ];
};
const ShippingProductTransfers: React.FunctionComponent<ShippingProductTransfersProps> = ({
  deliveries,
  locations,
  isProductSelectionOpen,
  isLocationSelectionOpen,
  setIsProductSelectionOpen,
  setIsLocationSelectionOpen,
}: ShippingProductTransfersProps) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedProductDetail, setSelectedProductDetail] = useState<Product | undefined>();
  const { products } = useManyProducts(getProductIds(deliveries));
  const getLocationFromId = (id: number | unknown): Location | undefined => {
    return locations.find((location: Location) => location.id === id);
  };
  const handleChangeLocation = (id: number | unknown) => {
    const location = getLocationFromId(id);

    dataProvider
      .update('products', {
        id: selectedProduct?.id,
        data: {
          location: location?.id,
        },
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setSelectedProduct(null);
        setIsLocationSelectionOpen(false);
      });
  };

  return (
    <Box height="calc(100vh - 172px)" overflow="scroll">
      {!isLocationSelectionOpen && !selectedProductDetail && (
        <>
          {products &&
            products
              .sort((a, b) =>
                a.location_name && b.location_name && a.location_name > b.location_name ? 1 : -1
              )
              .map((product: Product) => {
                const delivery = deliveries.find((delivery) =>
                  delivery.items.some((item) => item.product === product.id)
                );
                return (
                  delivery && (
                    <ShippingProduct
                      locations={locations}
                      key={product.id}
                      product={product}
                      delivery={delivery}
                      onClick={() => setSelectedProductDetail(product)}
                    />
                  )
                );
              })}
        </>
      )}

      {selectedProductDetail?.workshop_file && (
        <WorkshopFileDetailScreen
          workshopFileId={selectedProductDetail.workshop_file}
          handleClosePortal={() => setSelectedProductDetail(undefined)}
          enableDefaultDeclaration
        />
      )}
      {isProductSelectionOpen && (
        <ProductSelectionPortal
          buttonText="Confirmer"
          onClose={() => setIsProductSelectionOpen(false)}
          onProductSelection={(product: Product) => {
            setSelectedProduct(product);
            setIsLocationSelectionOpen(true);
          }}
          open={isProductSelectionOpen}
        />
      )}
      {isLocationSelectionOpen && (
        <SelectProductLocation
          locations={locations.filter((location) => location.usage === SHIPPING) as Location[]}
          open={isLocationSelectionOpen}
          handleClose={() => {
            setIsLocationSelectionOpen(false);
          }}
          handleChangeLocation={handleChangeLocation}
        />
      )}
      <FloatingActionButton
        page={1}
        variant="primary"
        onClick={() => setIsProductSelectionOpen(true)}
      >
        <CenterFocusStrong data-testid="scan-icon" />
      </FloatingActionButton>
    </Box>
  );
};

export default ShippingProductTransfers;
