import React from 'react';

import { Field } from 'formik';
import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { AswoDevice } from 'types/aswo-devices';

type DeviceCardProps = {
  name: string;
  value: string | null;
  device: AswoDevice;
} & MarginProps;

const StyledLabel = styled.label`
  display: flex;
  width: 100%;
`;

const StyledField = styled(Field)`
  width: 10%;
  height: ${(props) => `${props.theme.sizes[3]}px`};
  margin: ${(props) => `${props.theme.space[1]}px`};
`;

const StyledDiv = styled.div`
  display: block;
  width: 100%;
  padding: ${(props) => `${props.theme.space[1]}px`};
`;

const StyledBox = styled(Box)`
  line-break: anywhere;
`;

const DeviceCard: React.FunctionComponent<DeviceCardProps> = ({
  name,
  value,
  device,
  ...rest
}: DeviceCardProps) => {
  const deviceProductType = device.product_type?.name || '';
  const deviceProductCategory = device.product_category?.name || device.aswo_device_category || '';

  return (
    <Box bg="white" borderRadius={2} display="flex" padding={1} width="100%" {...rest}>
      <StyledLabel>
        <StyledField type="radio" name={name} value={value} />
        <StyledDiv>
          <Box display="flex" mb={1}>
            <Text textAlign="left" variant="smallBold">
              {device.device_type}
            </Text>
            <Text textAlign="left" variant="small" ml={4} color="#8795B3">
              {device.name || 'Nom inconnu'}
            </Text>
          </Box>
          <Text textAlign="left" variant="small" mb={1}>
            {device.device_id || 'Numéro inconnu'}
          </Text>
          {device.ean && (
            <Text textAlign="left" variant="extraSmall" color="#8795B3" mb={1}>
              EAN: {device.ean}
            </Text>
          )}
          <StyledBox mb={1}>
            <Text textAlign="left" variant="extraSmall" color="#8795B3">
              {deviceProductType} {deviceProductCategory} - {device.brand}
            </Text>
          </StyledBox>
        </StyledDiv>
      </StyledLabel>
    </Box>
  );
};

export default DeviceCard;
