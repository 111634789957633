import React from 'react';

import { useQuery } from 'react-admin';

import { Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import { useEmployee } from 'employeeContext';

import Text from 'components/atoms/Text';

import { SkuTransfer } from 'types/sku-transfer';

import RepairmanStockStyles from '../RepairmanStockStyles';
import TransferCard from './TransferCard';

const useStyles = makeStyles(RepairmanStockStyles);

type IncomingTransferSelectionProps = {
  open: boolean;
  onClose: () => void;
  TransitionComponent: React.FunctionComponent<TransitionProps>;
  scanIncomingSku: () => void;
  handleTransferScreen: (transfer_id: number) => void;
};

const IncomingTransferSelection: React.FunctionComponent<IncomingTransferSelectionProps> = ({
  open,
  onClose,
  TransitionComponent,
  scanIncomingSku,
  handleTransferScreen,
}: IncomingTransferSelectionProps) => {
  const classes = useStyles();
  const { employee } = useEmployee();
  /*   const [isTransferListLoading, setIsTransferListLoading] = useState(true); */

  const { data: SkuTransferList, loading } = useQuery({
    type: 'getList',
    resource: 'sku-transfer',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      filter: {
        repairman_id: employee.id,
        status: 'in_progress',
      },
    },
  }) as { data: SkuTransfer[]; loading: boolean };

  return (
    <Dialog
      fullScreen
      TransitionComponent={TransitionComponent}
      open={open}
      onClose={onClose}
      className={classes.dialog}
    >
      <CloseIcon className={classes.closeButton} onClick={onClose} />

      <div className={classes.incomingSkuWrapper}>
        <div className={classes.skuTransferListHeader}>
          <Text mb={2} variant="subtitle" textAlign="left" color="dark">
            Liste des transferts :
          </Text>
        </div>
        <div className={classes.skuTransferList}>
          {SkuTransferList?.map((transfer: SkuTransfer) => {
            return (
              <TransferCard
                onClick={handleTransferScreen}
                isLoading={loading}
                transfer={transfer}
                key={transfer.id}
              />
            );
          })}
        </div>
        <Typography className={classes.manualAddTextTransfer} onClick={scanIncomingSku}>
          Provenance inconnue
        </Typography>
      </div>
    </Dialog>
  );
};

export default IncomingTransferSelection;
