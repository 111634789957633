import React from 'react';

import { ErrorMessage, Field, FieldProps } from 'formik';

import Box from 'components/atoms/Box';
import FormErrorMessage from 'components/molecules/FormErrorMessage';
import Select from 'components/molecules/Select';

type BoxType = React.ComponentProps<typeof Box>;
type SelectFieldProps = {
  name: string;
  placeholder: string;
  timeslotsList: {
    name: string;
    value: string;
  }[];
} & BoxType;

const SelectField: React.FunctionComponent<SelectFieldProps> = ({
  name,
  placeholder,
  timeslotsList,
  width = 152,
  ...boxProps
}: SelectFieldProps) => {
  return (
    <Box width={width} {...boxProps}>
      <Field name={name}>
        {({ field: { name, value }, form, meta }: FieldProps) => {
          return (
            <Select
              items={timeslotsList}
              value={value}
              name={name}
              valid={!(meta.touched && meta.error)}
              // Necessary because our Select component does not behave like a standard input
              onChange={(value) => form.setFieldValue(name, value)}
              width="100%"
              placeholder={placeholder}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={FormErrorMessage} />
    </Box>
  );
};

export default SelectField;
