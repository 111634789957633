import React from 'react';

import { Dialog, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';

import RepairConfirmationModalStyle from 'pages/RepairReport/components/formComponents/styles/RepairConfirmationModalStyle';

import { SparePart } from 'types/spare-part';

const useStyles = makeStyles(RepairConfirmationModalStyle);

type RepairReportConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  remainingSkus: SparePart[];
  onValidate: () => void;
};

const RepairReportConfirmModal: React.FunctionComponent<RepairReportConfirmModalProps> = ({
  open,
  onClose,
  remainingSkus,
  onValidate,
}: RepairReportConfirmModalProps) => {
  const classes = useStyles();
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <div className={classes.modal}>
        <ArrowBackIcon className={classes.arrowBackButton} onClick={onClose} />
        <div className={classes.modalHeader}>
          <Typography variant="h6">Confirmation</Typography>
          <Typography variant="h5">Confirmez que ces pièces ne sont pas posées</Typography>
        </div>
        {remainingSkus &&
          remainingSkus.map((element) => (
            <div className={classes.skuField} key={element.id}>
              <div className={classes.skuFieldDesignation}>
                {element.sku_model && element.sku_model.designation}
              </div>
              <div className={classes.skuFieldReference}>
                {element.sku_model && element.sku_model.supplier_reference}
              </div>
              <div className={classes.cancelIcon}>
                <CancelIcon />
              </div>
            </div>
          ))}
        <Button
          id="validate-step-button"
          className={classes.validateButton}
          variant="contained"
          onClick={() => onValidate()}
        >
          <span id="validation-button-label">Confirmer</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default RepairReportConfirmModal;
