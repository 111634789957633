import React from 'react';

import { OffCanvasContentProps } from './OffCanvas';

const OffCanvasMenu: React.FunctionComponent<OffCanvasContentProps> = ({
  width = 250,
  transitionDuration = 250,
  isMenuOpened = false,
  position = 'left',
  children,
}: OffCanvasContentProps) => {
  // closed state style
  const left = position === 'left' ? -1 * width + 'px' : 'auto';
  const right = position === 'left' ? 'auto' : -1 * width + 'px';
  const translateX = position === 'left' ? -1 * width : 0;
  const closedStyle = {
    width: width + 'px',
    position: 'fixed',
    top: '0px',
    left: left,
    right: right,
    transform: 'translate(' + translateX + 'px, 0px)',
    transitionDuration: transitionDuration + 'ms',
  } as React.CSSProperties;

  // open state style
  const translateOpenX = position === 'left' ? width : -1 * width;
  const openStyle = {
    transform: 'translate(' + translateOpenX + 'px, 0px)',
  };

  // create current state styles
  let currStyle = Object.assign({}, closedStyle);
  if (isMenuOpened) {
    currStyle = Object.assign({}, currStyle, openStyle);
  }

  return <div style={{ ...currStyle }}>{children}</div>;
};

export default OffCanvasMenu;
