/**
 * Function used to return a list grouped by a certain key
 * @param {list} list - The list of groupby
 * @param {key} key - To what field the list need to be grouped
 */
export const groupBy = <T>(list: T[], key: string): Record<string, T[]> => {
  return list.reduce((result, currentValue) => {
    (result[currentValue.demand[key]] = result[currentValue.demand[key]] || []).push(currentValue);
    return result;
  }, {});
};
