import React from 'react';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { useEmployee } from 'employeeContext';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import CallButton from 'components/CallButton';
import PaperStripLink from 'components/PaperStripLink';
import PaperStripUnfolds from 'components/PaperStripUnfolds';
import ProductDetailPanel from 'components/ProductDetailPanel';
import GoBackHeader from 'components/molecules/GoBackHeader';
import AddressPanel from 'components/organisms/AddressPanel';
import PageLayout from 'components/templates/PageLayout';

import { sendGAEvent } from 'utils/google-analytics';

import InterventionNeeded from './components/InterventionNeeded';
import { useFaultForecastDetail } from './utils';

interface PrequalificationMatchParams {
  prequalificationId: string;
}

type PrequalificationProps = RouteComponentProps<PrequalificationMatchParams>;

const Prequalification: React.FunctionComponent<PrequalificationProps> = ({
  match: {
    params: { prequalificationId },
  },
}: PrequalificationProps) => {
  const history = useHistory();
  const goToInbox = () => {
    history.push('/inbox');
  };
  const { employee } = useEmployee();

  const { prequalification, customerFile } = useFaultForecastDetail(prequalificationId);

  if (prequalification && customerFile) {
    return (
      <PageLayout>
        <GoBackHeader title={customerFile.name} />
        <AddressPanel customerFile={customerFile} />
        <PaperStripLink
          text="Infos Client"
          link={`/customer-details/${customerFile.id}`}
          Icon={KeyboardArrowRightIcon}
          linkMode="route"
          mb={3}
        />
        <PaperStripUnfolds content={customerFile.message_to_repairman} title="Commentaire" mb={3} />
        <ProductDetailPanel product={prequalification.product} />
        <PaperStripLink
          text="Préqualifier"
          link={`/prequal-report/${prequalification.id}/do`}
          handleClick={() =>
            sendGAEvent(
              'start-prequal',
              'prequal',
              new Date().toDateString(),
              `${employee.user?.username}`
            )
          }
          Icon={KeyboardArrowRightIcon}
          linkMode="route"
          mb={3}
          mt={3}
        />
        <CallButton text="Appeler" phone={customerFile.phone} mb={3} />
        <InterventionNeeded
          onSuccess={goToInbox}
          prequalificationId={parseInt(prequalificationId)}
        />
      </PageLayout>
    );
  } else {
    return <PageLayout />;
  }
};
export default Prequalification;
