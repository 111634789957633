import React from 'react';

import styled from 'styled-components';

import Box from '../Box';
import Button from '../Button';
import { ButtonProps } from '../Button/Button';

export type FloatingActionButtonProps = {
  size?: number;
  page: number;
} & ButtonProps;

const FixedButton = styled(Button)<FloatingActionButtonProps>((props) => ({
  position: 'fixed',
  top: '80%',
  right: `calc(-${(props.page - 1) * 100}vw + 16px)`,
}));

const FloatingActionButton: React.FunctionComponent<FloatingActionButtonProps> = ({
  children,
  disabled,
  variant,
  type = 'submit',
  size = 56,
  ...rest
}: FloatingActionButtonProps) => (
  <Box>
    <FixedButton
      variant={disabled ? 'disabled' : variant}
      disabled={disabled}
      {...rest}
      type={type}
      width={size}
      height={size}
    >
      {children}
    </FixedButton>
  </Box>
);

export default FloatingActionButton;
