import React, { useState } from 'react';

import SimpleScanner from 'components/molecules/SimpleScanner';

import { SparePart } from 'types/spare-part';

import { getSparePartsList } from 'utils/api/api';

type SparePartScannerProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmBarcode: (scannedCode: string) => void;
  packageId: number;
  title?: string;
  handleManualInput: (sparePartId: number) => void;
  excludedIdsList: number[];
};

const SparePartScanner: React.FunctionComponent<SparePartScannerProps> = ({
  open,
  onClose,
  handleConfirmBarcode,
  handleManualInput,
  packageId,
  excludedIdsList,
}: SparePartScannerProps) => {
  const [choicesList, setChoicesList] = useState<SparePart[]>([]);

  const handleScan = (code: string) => {
    handleConfirmBarcode(code);
  };

  const loadOptionList = async () => {
    const spareParts = await getSparePartsList({ package_id: packageId });
    setChoicesList(
      spareParts.filter((sparePart: SparePart) => !excludedIdsList.includes(sparePart.id))
    );
  };

  return (
    <SimpleScanner
      open={open}
      onClose={onClose}
      handleConfirmCallback={handleScan}
      scannerTitle="Scannez une pièce"
      scannerExplanations="Prenez l'ensemble de l'étiquette, veillez à la netteté."
      options={
        choicesList.map((choice) => {
          return { value: choice.id, label: choice.sku_model.sku_reference };
        }) as unknown as Array<{ value: string; label: string }>
      }
      selectedValue={undefined}
      loadOptionList={loadOptionList}
      handleManualInput={(scannedCode: { value: number; label: string }) =>
        handleManualInput(scannedCode.value)
      }
    />
  );
};

export default SparePartScanner;
