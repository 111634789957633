import React from 'react';

import HowToRegIcon from '@material-ui/icons/HowToReg';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import FlatCard from 'components/FlatCard';
import IconText from 'components/IconText';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { SparePart } from 'types/spare-part';

type IconProps = {
  className?: string;
};

type SkuDetailCardProps = {
  sku: SparePart;
};

const StyledLocationIcon = styled(LocationOnIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.honey')(props),
  marginRight: '8px',
  verticalAlign: 'sub',
}));
const StyledHowToRegIcon = styled(HowToRegIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.honey')(props),
  marginRight: '8px',
  verticalAlign: 'sub',
}));

const SkuDetailCard: React.FunctionComponent<SkuDetailCardProps> = ({
  sku,
}: SkuDetailCardProps) => {
  return (
    <Box>
      {sku.location && (
        <FlatCard mb={1} variant="primary">
          <Box>
            <IconText
              icon={<StyledLocationIcon fontSize="default" />}
              text="Emplacement"
              color="dark"
              variant="small"
            />
            <Text variant="smallBold" ml={4} textAlign="left">
              {sku.location?.code}
            </Text>
          </Box>
        </FlatCard>
      )}
      {sku.demand && sku.demand?.customer_name && (
        <FlatCard variant="primary">
          <IconText
            icon={<StyledHowToRegIcon fontSize="default" />}
            text="Client / Appareil dédiée"
            color="dark"
            variant="small"
          />

          <Text variant="smallBold" ml={4} textAlign="left">
            {sku.demand?.customer_name}
          </Text>
        </FlatCard>
      )}
    </Box>
  );
};

export default SkuDetailCard;
