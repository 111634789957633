import React from 'react';

import { FormikProps } from 'formik';

import Button from 'components/atoms/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/TextArea';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

type SymptomAndDiagnosticForm = {
  symptoms: string;
  diagnostic: string;
};

type SymptomsAndDiagnosticProps = {
  back: () => void;
} & FormikProps<SymptomAndDiagnosticForm>;

export const SymptomsAndDiagnostic: React.FunctionComponent<SymptomsAndDiagnosticProps> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  isValid,
  values,
  back,
}: SymptomsAndDiagnosticProps) => {
  return (
    <PageLayout>
      <HeaderWithArrow title="Symptômes et Diagnostic" action={back} />
      <ContentContainer>
        <Text mb={3} variant="subtitle">
          Quel est le diagnostic ?
        </Text>
        <form onSubmit={handleSubmit}>
          <TextArea
            placeholder="symptômes"
            type="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="symptoms"
            width="100%"
            rows={7}
            value={values.symptoms}
            name="symptoms"
          />
          <TextArea
            type="textarea"
            data-testid="diagnostic"
            onChange={handleChange}
            placeholder="diagnostic"
            onBlur={handleBlur}
            width="100%"
            rows="7"
            value={values.diagnostic}
            name="diagnostic"
          />
          <Button disabled={!isValid} mt={2} width="100%" type="submit">
            Suivant
          </Button>
        </form>
      </ContentContainer>
    </PageLayout>
  );
};

export default SymptomsAndDiagnostic;
