import React, { useEffect } from 'react';

import { ListToolbar, useListController } from 'react-admin';

import IncentiveSystemFilter from 'pages/IncentiveSystem/Components/IncentiveSystemFilter';
import { listRepairReportLabelRequalification } from 'pages/IncentiveSystem/Services/LabelList';

import IncentiveSystemUpdate from './IncentiveSystemUpdate';

const IncentiveSystemUpdateComponentList = (props) => {
  const controllerProps = useListController(props);

  const { data, ids, setFilters, filterValues } = controllerProps;

  useEffect(() => {
    setFilters({});
    return () => {
      setFilters({});
    };
  }, []);
  return (
    <div>
      <ListToolbar
        filters={
          <IncentiveSystemFilter
            setFilters={setFilters}
            repairmanId={props.repairmanId}
            filterValues={filterValues}
            listRepairReportLabel={listRepairReportLabelRequalification}
          />
        }
        {...controllerProps}
        actions={props.actions}
        permanentFilter={props.filter}
      />
      <IncentiveSystemUpdate
        dataIncentiveListSliceCreatedAt={props.dataIncentiveListSliceCreatedAt}
        ids={ids}
        data={data}
        {...props}
      />
    </div>
  );
};

export const IncentiveSystemUpdateList = (props) => (
  <IncentiveSystemUpdateComponentList
    {...props}
    repairmanId={props.repairmanId}
    basePath=""
    filter={{ repairman_id: props.repairmanId }}
    resource="incentives"
    perPage={300}
  />
);
