import React, { useEffect, useState } from 'react';

import { MarginProps } from 'styled-system';

import DefectsDeclarationForm from 'components/DefectsDeclarationForm';
import PortalComponent from 'components/Portal/PortalComponent';
import SparePartSelectionPortal from 'components/SparePartSelectionPortal';

import { SparePart } from 'types/spare-part';

import { postSparePartDefect } from 'utils/api/api';

import {
  BAD_REFERENCE,
  COMMENT,
  HAS_BEEN_TEST_FITTED,
  MACHINE_TESTED,
  PROBLEMS,
  SPARE_PART_DEFECTS,
  STEPS,
  WRONG_SPARE_PART,
} from './constants';

type SparePartDefectsDeclarationProps = {
  handleClose: () => void;
  defaultSparePart?: SparePart;
  filter?: Record<string, unknown>;
} & MarginProps;

type DefaultDecalarationFormValue = {
  problem: FormattedChecklistElement[];
  defect: FormattedChecklistElement[];
  test: FormattedChecklistElement[];
  comment: { id: string; text: string; value: string; images: File[] };
};

type FormattedChecklistElement = {
  id: string | boolean;
  text: string | undefined;
  is_checked: boolean;
};

const SparePartDefectsDeclaration: React.FunctionComponent<SparePartDefectsDeclarationProps> = ({
  handleClose,
  defaultSparePart,
  filter = { enable_employee_filter: 'on' },
}: SparePartDefectsDeclarationProps) => {
  const [isSparePartScanOpen, setIsSparePartScanOpen] = useState(true);
  const [sparePart, setSparePart] = useState<SparePart>();
  const [isDeclarationFormOpen, setIsDeclarationFormOpen] = useState(!!defaultSparePart);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  // needed to counter the closing interaction of SparePartSelectionPortal
  const [dummySparePart, setDummySparePart] = useState<SparePart>();
  const [stepHistory, setStepHistory] = useState<number[]>([0]);
  const isLastStep = stepHistory[stepHistory.length - 1] === STEPS.length - 1;

  const isSparePartWrong = (problem: string): boolean => {
    return problem === WRONG_SPARE_PART;
  };

  const isSparePartMachineTested = (test: string): boolean => {
    return test === MACHINE_TESTED;
  };

  useEffect(() => {
    if (!isSparePartScanOpen && !!dummySparePart) {
      handleClose();
    }
    if (!isSparePartScanOpen && !isDeclarationFormOpen) {
      handleClose();
    }
  }, [isSparePartScanOpen, handleClose, dummySparePart, isDeclarationFormOpen]);

  const initialValues: DefaultDecalarationFormValue = {
    problem: PROBLEMS.map((value: { value: string; name: string }) => {
      return {
        id: value.value,
        text: value.name,
        is_checked: false,
      };
    }),
    defect: SPARE_PART_DEFECTS.map((value: { value: string; name: string }) => {
      return {
        id: value.value,
        text: value.name,
        is_checked: false,
      };
    }),
    test: HAS_BEEN_TEST_FITTED.map((value: { value: string; name: string }) => {
      return {
        id: value.value,
        text: value.name,
        is_checked: false,
      };
    }),
    comment: { id: COMMENT, text: 'Commentaire', value: '', images: [] },
  };

  const handleValidateForm = async (values: any) => {
    setIsLoading(true);
    const actualSparePart = sparePart || defaultSparePart;
    try {
      if (actualSparePart) {
        await postSparePartDefect({
          spare_part: actualSparePart.id,
          defect: isSparePartWrong(values.problem) ? BAD_REFERENCE : values.defect,
          has_been_tested_fitted: isSparePartMachineTested(values.test),
          comment: values.comment.value || '',
          pictures: values.comment.images,
        });
      }
      handleClose();
    } catch (e) {
      setError('Erreur: ' + e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateStep = (values: any) => {
    const actualStep = STEPS[stepHistory[stepHistory.length - 1]].name;
    let isErrored = false;
    (Object.keys(values) as (keyof DefaultDecalarationFormValue)[]).forEach((key) => {
      if (key === actualStep && values[actualStep]) {
        if (typeof values[actualStep] === 'object' && actualStep !== COMMENT) {
          setError('Veuillez choisir une possibilité');
          isErrored = true;
        }
      }
    });
    if (isErrored) {
      return;
    }
    setError(undefined);
    if (isLastStep) {
      handleValidateForm(values);
    } else if (isSparePartWrong(values.problem)) {
      setStepHistory([...stepHistory, STEPS.length - 1]);
    } else {
      setStepHistory([...stepHistory, (stepHistory.pop() as number) + 1]);
    }
  };

  const confirmScan = (sparePart: SparePart) => {
    setSparePart(sparePart);
    setIsDeclarationFormOpen(true);
  };

  const handleBack = () => {
    setError(undefined);
    setStepHistory(stepHistory.slice(0, -1));
  };

  return (
    <>
      <SparePartSelectionPortal
        onConfirm={confirmScan}
        onClose={() => setIsSparePartScanOpen(false)}
        open={isSparePartScanOpen}
        sku={dummySparePart}
        setSku={setDummySparePart}
        filter={filter}
      />

      <PortalComponent open={isDeclarationFormOpen} onClose={handleClose}>
        <DefectsDeclarationForm
          handleClose={handleClose}
          handleValidateStep={handleValidateStep}
          initialValues={initialValues}
          activeStep={stepHistory[stepHistory.length - 1]}
          steps={STEPS}
          isLoading={isLoading}
          handleBack={handleBack}
          error={error}
          headerText={sparePart?.sku_model.sku_reference}
        />
      </PortalComponent>
    </>
  );
};
export default SparePartDefectsDeclaration;
