// React imports
import React, { useState } from 'react';

import { useQuery } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import dayjs from 'dayjs';
import { useModals } from 'modalsContext';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';

import { useLastBill } from 'pages/Payment/utils/api';
import RepairReportSnackbar from 'pages/RepairReport/components/formComponents/RepairReportSnackbar';
import DashboardStyle from 'pages/RepairReport/components/formComponents/styles/DashboardStyle';

import PaperStripAction from 'components/PaperStripAction';
// Component imports
import PaperStripLink from 'components/PaperStripLink';
import { REPAIR_BONUS } from 'components/QuoteElementField/utils';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import GoBackHeader from 'components/molecules/GoBackHeader';
import Tab from 'components/molecules/Tab';
import StartTour from 'components/organisms/StartTour';
import VisitSummary from 'components/organisms/VisitSummary';

import { CustomerFileDetailed } from 'types/customer-files';
import { Product } from 'types/products';
import { Quote } from 'types/quotes';

import { useComments } from 'utils/api/api';
import { formatName, formatPrice } from 'utils/formatting';

import { getRepairBonusEligibility } from '../../../../utils/api/repair-bonus';
import ProductDetailForDashboard from './ProductDetailForDashboard';

const useStyles = makeStyles(DashboardStyle);

type HomeRepairDashboardProps = {
  customerFile: CustomerFileDetailed;
  isErrorShown: boolean;
  errorMessage: string;
  handleSubmit: () => void;
  isLoading: boolean;
  visitId: string;
  productIdentificationByPassed: number[];
  productsWithBonusChecked: number[];
  productId: string;
};
interface CalendarState {
  dateVisit: string;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const HomeRepairDashboard: React.FunctionComponent<HomeRepairDashboardProps> = ({
  customerFile,
  visitId,
  handleSubmit,
  errorMessage,
  isErrorShown,
  isLoading,
  productIdentificationByPassed,
  productsWithBonusChecked,
  productId,
}: HomeRepairDashboardProps) => {
  const { showModal, closeModal } = useModals();
  const classes = useStyles();
  const dataKeys = Object.keys(useFormState().values); // accesses directly to the form values

  const [isPendingSubmit, setIsPendingSubmit] = useState(false);
  const [productsEligibleToRepairBonus, setProductsEligibleToRepairBonus] = useState<Product[]>([]);

  const { comments } = useComments({ recipient_role: 'repairman', customer_file: customerFile.id });
  const productIndex = customerFile.products.findIndex(
    (product) => product?.id.toString() === productId
  );
  const activeIndex = productIndex !== -1 ? productIndex : 0;
  const [activeProductIndex, setActiveProductIndex] = useState(activeIndex);

  const { bill } = useLastBill(customerFile.id.toString());

  const isIdentificationByPassedOnProduct = (index: number): boolean => {
    return productIdentificationByPassed?.includes(customerFile.products[index].id);
  };

  React.useEffect(() => {
    customerFile.products.forEach((product, index) => {
      if (!isIdentificationByPassedOnProduct(index)) {
        getRepairBonusEligibility(product)
          .then((isEligible) => {
            isEligible &&
              setProductsEligibleToRepairBonus((productsEligibleToRepairBonus) => [
                ...productsEligibleToRepairBonus,
                product,
              ]);
          })
          .catch((e) => console.log(e));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFile.products]);

  const { data: visit, loading } = useQuery({
    type: 'getOne',
    resource: 'visit',
    payload: {
      id: visitId,
      include: ['visit_payment'],
    },
  });
  if (loading) {
    return (
      <div>
        <Text variant="body">Récupération des données du dossier client en cours</Text>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  const currentVisit = customerFile.visits.find((visit) => visit.id === parseInt(visitId));

  const checkAndSubmit = () => {
    setIsPendingSubmit(true);
    if (numberOfProductDone.length === productList.length) {
      handleSubmit();
    } else {
      showModal({
        modalToShow: 'CONFIRM_MODAL',
        modalProps: {
          title: 'Compte-rendu partiel détecté',
          content: [
            `Il semblerait que ce compte-rendu soit partiel, sur ${productList.length} appareils, seuls ${numberOfProductDone.length} possèdent un compte-rendu.`,
            '', // Blank line
            'Si cela est anormal, merci de continuer le compte-rendu',
          ],
          onCancel: () => {
            closeModal();
            setIsPendingSubmit(false);
          },
          onConfirm: () => {
            closeModal();
            handleSubmit();
          },
          confirmLabel: 'Confirmer et envoyer',
          cancelLabel: 'Continuer le compte-rendu',
        },
      });
    }
  };

  const productList = customerFile.products;

  const numberOfProductDone = dataKeys.filter((element) => element.includes('product')); // to Differenciate the payment object and the products
  const numberOfPaymentDone = dataKeys.filter((element) => element.includes('payment'));
  // payment condition to enable the submission. visit_payment is true if there is a payment associated to the visit,
  // numberOfPaymentDone is longer than 0 if there is a reason for not registrating a payment.
  // payment is considered done if the balance equals 0
  const isPaymentDone =
    visit.visit_payment || numberOfPaymentDone.length > 0 || customerFile.balance == 0;

  const GreenCheckIcon = () => <CheckCircleIcon className={classes.checkedIcon} />;

  const visitBeginningTime = dayjs(currentVisit?.timeslot.beginning);

  const toggleVisitPaymentAlreadyExist = () => {
    showModal({
      modalToShow: 'INFORMATION_MODAL',
      modalProps: {
        title: 'Le paiement est déjà déclaré',
        content:
          'Une erreur ? Une fausse manipulation ? Contactez directement le support pour le signaler.',
        onConfirm: () => {
          closeModal();
        },
      },
    });
  };

  const isProductDone = (productId: string) => {
    return dataKeys.some((productKey: string) => productKey.includes(productId));
  };

  const activeProductId = customerFile.products[activeProductIndex].id;

  const isActiveProductEligibleToRepairBonus = productsEligibleToRepairBonus
    .map((item) => item.id)
    .includes(activeProductId);

  const isProductIdentified = (index: number): boolean => {
    return (
      isIdentificationByPassedOnProduct(index) ||
      (!!customerFile.products[index].product_model &&
        !!customerFile.products[index].identification_plate)
    );
  };

  const isBonusAppliedOnProduct = (id: number, bill: Quote): boolean => {
    if (!bill) return false;
    const alreadyAppliedBonuses = bill.quote_elements
      .filter((element) => element.element_type === REPAIR_BONUS)
      .map((element) => element.product_foreignkey);
    return productsWithBonusChecked?.includes(id) || alreadyAppliedBonuses.includes(id);
  };

  const isBonusAppliedOnAllProducts = (bill: Quote): boolean => {
    let response = true;
    productsEligibleToRepairBonus.forEach((product) => {
      if (!isBonusAppliedOnProduct(product.id, bill)) response = false;
    });
    return response;
  };

  const areAllProductsIdentified = (): boolean => {
    let response = true;
    customerFile.products.forEach((_, index) => {
      if (!isProductIdentified(index)) response = false;
    });
    return response;
  };

  return (
    <Container>
      <GoBackHeader<CalendarState>
        title={formatName(customerFile.firstname, customerFile.lastname)}
        backLink={`/calendar`}
        state={{ dateVisit: visitBeginningTime.format('YYYY-MM-DD') }}
      />
      <Box paddingY={0} paddingX={0} minHeight={0} overflowY="scroll" mb={8}>
        <VisitSummary
          customerFile={customerFile}
          productList={productList}
          visitType={currentVisit?.visit_type}
          showVisitComments={true}
          visitComments={(comments && comments[0]) || undefined}
          noProductDetails
        />

        <Box
          overflowX="auto"
          display="grid"
          gridAutoFlow="column"
          textAlign="center"
          gridAutoColumns="max-content"
        >
          {productList?.map((product, index) => {
            return (
              <Tab
                width="72px"
                key={product.id}
                title={`Produit ${index + 1}`}
                active={index === activeProductIndex}
                cardinality={0}
                handleClick={() => setActiveProductIndex(index)}
                variant={index === activeProductIndex ? 'smallBold' : 'small'}
                backgroundColor="transparent"
              />
            );
          })}
        </Box>
        {productList?.map((product, index) => (
          <ProductDetailForDashboard
            key={index}
            active={index === activeProductIndex}
            product={product}
          />
        ))}
        <PaperStripLink
          textMode="title"
          text={`Identifier l'appareil`}
          link={`/compte-rendu/${customerFile.id}/product-identification/${activeProductId}/${visitId}`}
          Icon={isProductIdentified(activeProductIndex) ? GreenCheckIcon : KeyboardArrowRightIcon}
          linkMode="route"
          data-testid={`product-selection-${activeProductId}`}
          importantIcon={!isProductIdentified(activeProductIndex)}
        />
        <PaperStripLink
          textMode="title"
          text={`Faire mon compte-rendu `}
          link={`/compte-rendu/${customerFile.id}/is_appliance_repaired?visit=${visitId}&productId=${activeProductId}`}
          Icon={isProductDone(activeProductId.toString()) ? GreenCheckIcon : KeyboardArrowRightIcon}
          linkMode="route"
          data-testid={`appliance-repaired-${activeProductId}`}
        />
        {isActiveProductEligibleToRepairBonus && (
          <PaperStripLink
            textMode="title"
            text="Vérifier le bonus"
            link={`/compte-rendu/${customerFile.id}/${activeProductId}/bonus-reparation/machine-fonctionnelle/${visitId}`}
            Icon={
              isBonusAppliedOnProduct(activeProductId, bill)
                ? GreenCheckIcon
                : KeyboardArrowRightIcon
            }
            linkMode="route"
            importantIcon={!isBonusAppliedOnProduct(activeProductId, bill)}
            disabled={isPaymentDone}
          />
        )}
        <Box mx="auto" my={2} textAlign="center">
          <Link
            to={`/compte-rendu/${customerFile.id}/product-addition/${visitId}`}
            color={'grey900'}
          >
            + Ajouter un produit
          </Link>
        </Box>
        {visit.visit_payment ? (
          <PaperStripAction
            textMode="title"
            text={`Montant encaissé ${formatPrice(visit.visit_payment.amount)}`}
            Icon={GreenCheckIcon}
            callback={toggleVisitPaymentAlreadyExist}
          />
        ) : (
          <PaperStripLink
            textMode="title"
            text={`Encaisser ${formatPrice(customerFile.balance)}`}
            link={`/compte-rendu/${customerFile.id}/payment/${visitId}`}
            Icon={isPaymentDone ? GreenCheckIcon : KeyboardArrowRightIcon}
            linkMode="route"
            disabled={!areAllProductsIdentified() || !isBonusAppliedOnAllProducts(bill)}
          />
        )}
      </Box>
      {errorMessage && isErrorShown && <RepairReportSnackbar errorMessage={errorMessage} />}{' '}
      <Box position="absolute" bottom={2} textAlign="center" width="100%">
        <StartTour />
        <Box px={2}>
          <Button
            width="100%"
            type="button"
            onClick={checkAndSubmit}
            disabled={
              isPendingSubmit || isLoading || numberOfProductDone.length === 0 || !isPaymentDone
            }
          >
            Terminer et envoyer
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default HomeRepairDashboard;
