import React, { useState } from 'react';

import CloudDoneIcon from '@material-ui/icons/CloudDone';

import dataProvider from 'dataProvider';
import { useModals } from 'modalsContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ConfirmationPortal from 'components/ConfirmationPortal';
import ItemListSelectionPortal from 'components/ItemListSelectionPortal';
import PortalComponent from 'components/Portal/PortalComponent';
import ProductListSelectionPortal from 'components/ProductListSelectionPortal/ProductListSelectionPortal';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';

import { Location } from 'types/location';
import { Product } from 'types/products';

import { useInventory } from 'utils/useInventory';

export type ProductInventoryProps = {
  productList: Product[];
  open: boolean;
  onClose: () => void;
  location?: Location;
  redirectUrl: string;
  ConfirmationButtonText: string;
  isCreationAllowed?: boolean;
};

const TurquoiseIcon = styled(CloudDoneIcon)((props) => ({
  color: props.theme.colors.turquoise,
}));

const ProductInventory: React.FunctionComponent<ProductInventoryProps> = ({
  productList,
  onClose,
  location,
  redirectUrl,
  ConfirmationButtonText,
}: ProductInventoryProps) => {
  const history = useHistory();
  const { showModal, closeModal } = useModals();
  const [baseProductList, setBaseProductList] = useState<Product[]>(productList);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isDeclaringLosses, setIsDeclaringLosses] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isScanOpen, setIsScanOpen] = useState<boolean>(true);

  React.useEffect(() => {
    if (productList) {
      setBaseProductList(productList);
    }
  }, [productList]);

  const toggleAlreadySelectedModal = () => {
    console.warn('Machine déjà scannée');
  };

  const handleLossSubmission = () => {
    showModal({
      modalToShow: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Confirmer les pertes',
        content: 'Les machines vont être sorties du stock',
        onCancel: closeModal,
        onConfirm: () => {
          declareLossForSelectedProducts(
            () => setIsDeclaringLosses(true),
            () => setIsDeclaringLosses(false),
            () => {
              setIsConfirmationOpen(true);
            },
            (e) => console.error(e)
          );
          closeModal();
        },
      },
    });
  };

  const declareLossForSelectedProducts = (
    onStart: () => void,
    onEnd: () => void,
    onSuccess: () => void,
    onError: (e: string) => void
  ) => {
    onStart();
    dataProvider
      .updateMany('product-loss', {
        ids: (getItemsToDeclareLost() as Product[]).map((product: Product) => product.id),
        data: {
          expected_location: location?.id,
        },
      })
      .then(() => {
        onSuccess();
      })
      .catch((e) => onError(e))
      .finally(() => onEnd());
  };

  const handleSurplusManagement = () => {
    const surplusProductList = getSurplusItemList();
    if (surplusProductList.length > 0) {
      setIsSubmitting(true);
      dataProvider
        .updateMany('products', {
          ids: surplusProductList.map((product) => product.id),
          data: {
            location: location?.id,
            transfer_type: 'inventory',
          },
        })
        .catch((e) => console.error(e))
        .finally(() => {
          setIsSubmitting(false);
          // add surplus to base list
          setBaseProductList([...baseProductList, ...getSurplusItemList()] as Product[]);
        });
    }
  };
  const {
    shouldDisplayBaseList,
    submitInventory,
    getItemsToDeclareLost,
    getSurplusItemList,
    selectedItemList: selectedProductList,
    handleItemSelection,
  } = useInventory({
    itemList: baseProductList,
    declareSurplus: handleSurplusManagement,
    onSuccess: () => setIsConfirmationOpen(true),
    duplicateWarning: toggleAlreadySelectedModal,
  });

  const closeAndRedirect = () => {
    setIsConfirmationOpen(false);
    onClose();
    history.push(redirectUrl);
  };

  return (
    <>
      <PortalComponent open={isScanOpen} onClose={onClose} title={`Inventaire - ${location?.name}`}>
        <Box overflow="auto" mb={7}>
          <ProductListSelectionPortal
            productList={selectedProductList as Product[]}
            selectedList={selectedProductList as Product[]}
            surplusProductList={
              shouldDisplayBaseList ? (getSurplusItemList() as Product[]) : ([] as Product[])
            }
            onProductSelection={(product: Product) => handleItemSelection(product)}
          />
          <BottomButton
            disabled={isSubmitting}
            variant="primary"
            onClick={() => {
              submitInventory(baseProductList, selectedProductList);
              setIsScanOpen(false);
            }}
            data-testid="bottom-button"
          >
            {`Terminer l'inventaire`}
          </BottomButton>
        </Box>
      </PortalComponent>
      <ItemListSelectionPortal
        open={getItemsToDeclareLost().length > 0}
        itemList={getItemsToDeclareLost() as Product[]}
        selectedList={selectedProductList as Product[]}
        onClose={() => setIsScanOpen(true)}
        isSaving={isDeclaringLosses}
        title={`Machines manquantes - ${location?.name}`}
        buttonText="Déclarer les pertes"
        onClickOnButton={handleLossSubmission}
        openItemSelection={() => setIsScanOpen(true)}
        field="product"
        openScanText="Scanner un premier appareil"
        redButton
      />
      <ConfirmationPortal
        title="Inventaire enregistré"
        body="Ton stock est maintenant à jours !"
        buttonText={ConfirmationButtonText}
        icon={<TurquoiseIcon />}
        onConfirm={closeAndRedirect}
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </>
  );
};

export default ProductInventory;
