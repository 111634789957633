import * as yup from 'yup';

import { CREDIT_CARD_DEFERRED } from './utils/utils';

export const validationSchemaPaymentInput = yup
  .object()
  .shape({
    amount: yup.number().moreThan(0).required('Montant de paiement requis'),
    paymentMethod: yup.string().nullable().required('Méthode de paiement requis'),
    signature: yup.string().notRequired(),
  })
  .test('signature', 'Signature du client requise', (values, context) => {
    if (values.paymentMethod === CREDIT_CARD_DEFERRED && !values.signature) {
      return context.createError({ path: 'signature', message: 'Signature du client requise' });
    } else {
      return true;
    }
  });

export const validationSchemaPaymentProof = yup.object().shape({
  image: yup.mixed().nullable().required('Preuve de paiement requise'),
});
