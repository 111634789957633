import React from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import FlatCard from 'components/FlatCard';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { IncentiveLabel } from 'types/incentive-label';
import { LabelWithPoints } from 'types/label-with-points';

type IncentiveHelpContentProps = {
  labelListWithPoints: LabelWithPoints[];
  handleSelectLabel: (value: { label: IncentiveLabel; title: string }) => void;
};
const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)((props) => ({
  color: themeGet('colors.grey900')(props),
  marginLeft: themeGet('space.1')(props),
  fontSize: themeGet('sizes.2')(props),
}));

const IncentiveHelpContent: React.FunctionComponent<IncentiveHelpContentProps> = ({
  labelListWithPoints,
  handleSelectLabel,
}: IncentiveHelpContentProps) => {
  return (
    <FlatCard pr={1} pl={1} m={2}>
      <Text
        variant="bodyBold"
        color="honey"
        display="flex"
        justifyContent="flex-start"
        mt={2}
        mb={2}
      >
        Technique
      </Text>
      {labelListWithPoints.map((labelWithPoints) => (
        <Box
          key={labelWithPoints.id}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
        >
          <Text
            variant="small"
            color="dark"
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            onClick={() =>
              handleSelectLabel({ label: labelWithPoints.id, title: labelWithPoints.label })
            }
          >
            {labelWithPoints.label}
            <StyledInfoOutlinedIcon />
          </Text>
          <Text variant="smallBold" color="dark">
            {labelWithPoints.points} points
          </Text>
        </Box>
      ))}
    </FlatCard>
  );
};

export default IncentiveHelpContent;
