import styled from 'styled-components';
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  TypographyProps,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

const Box = styled.div<
  SpaceProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    BorderProps &
    TypographyProps &
    PositionProps &
    GridProps
>(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  border,
  typography,
  position,
  grid
);

export default Box;
