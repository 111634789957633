import { LabelWithPoints } from 'types/label-with-points';

export const labelListWithPoints: LabelWithPoints[] = [
  { label: 'Terminé', id: 'finished', points: 120 },
  { label: 'Diag', id: 'diag', points: 50 },
  { label: 'Refus devis', id: 'quote-refused', points: 25 },
  { label: 'Mauvaise commande', id: 'wrong-command', points: 25 },
  { label: 'Non réparable', id: 'not-repaired', points: 25 },
  { label: 'Intervention impossible', id: 'intervention-impossible', points: 25 },
  { label: 'Aide demandée', id: 'help-wanted', points: 25 },
  { label: 'Passage superflu', id: 'superfluous-visit', points: 0 },
];
