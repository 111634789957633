import { createStyles } from '@material-ui/core/styles';

const StoreStyles = createStyles({
  container: {
    background: '#F1F0F5',
    height: '100vh',
  },
  StoreAppBar: {
    background: '#fff',
    color: '#2B3B5C',
    boxShadow: 'none',
    borderRadius: 'unset',
    width: '100vw',
    position: 'fixed',
  },
  title: {
    marginLeft: 16,
  },
  StoreContainer: {
    background: '#F1F0F5',
    position: 'relative',
    paddingTop: '108px',
    width: '100vw',
    height: 'calc(100vh - 108px)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  receptionResumeContainer: {
    margin: 'auto',
    textAlign: 'center',
    paddingTop: 24,
  },
  barcodeStyle: {
    margin: 'auto',
  },
  receptionResumeContainerConfirmation: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 'auto',
    textAlign: 'center',
  },
  containerResume: {
    padding: '0 16px 16px',
  },
  headerCell: {
    display: 'none',
  },
  list: {
    paddingBottom: 108,
  },
  rowStyle: {
    height: 56,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  refRow: {
    color: '#2B3B5C',
    paddingRight: 8,
  },
  desRow: {
    color: '#8795B3',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  locRow: {
    color: '#2B3B5C',
    textAlign: 'end',
    flex: 'auto',
  },
  row: {
    display: 'flex',
    width: 'calc(100vw - 32px)',
  },
  closeButton: {
    color: '#8795B3',
    position: 'absolute',
    right: 16,
    top: 16,
    zIndex: 3,
  },
  arrowBackButton: {
    color: '#8795B3',
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 3,
  },
  skuLocationHeader: {
    textAlign: 'center',
    paddingTop: 47,
  },
  toolbar: {
    backgroundColor: 'transparent',
    position: 'fixed',
    bottom: 16,
    width: '-webkit-fill-available',
  },
  skuLocation: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#2B3B5C',
  },
  skuModifyLocation: {
    position: 'fixed',
    bottom: 104,
    left: '50%',
    transform: 'translate(-50%, -10%)',
    color: '#8795B3',
    textDecoration: 'underline',
  },
  locationInput: {
    position: 'fixed',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  skuLocationValidation: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#2B3B5C',
  },
  saveButton: {
    color: '#2B3B5C',
    backgroundColor: '#FCD660',
    borderRadius: 8,
    height: 50,
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    justifyContent: 'center',
  },
  header: {
    borderRadius: 0,
    paddingLeft: '20px',
    backgroundColor: '#fff',
  },
  headWrapper: {
    backgroundColor: '#fff',
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    display: 'flex',
    alignItems: 'center',
  },
  back: {
    color: '#2B3B5C',
  },
  skuHeaderNumber: {
    position: 'absolute',
    color: '#8795B3',
    right: 19,
    fontSize: 15,
  },
  skuFieldContainer: {
    height: 109,
    display: 'flex',
  },
  skuFieldRef: {
    fontSize: 15,
    color: '#2B3B5C',
    position: 'relative',
    top: 12,
    fontWeight: 'normal',
  },
  skuFieldDesignation: {
    fontSize: 15,
    color: '#8795B3',
  },
  skuFieldLocation: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#2B3B5C',
    display: 'flex',
    alignItems: 'center',
  },
  skuFieldIcon: {
    color: '#F7B349',
  },
  packageTable: {
    backgroundColor: '#F1F0F5',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    paddingBottom: 16,
  },
  listSkuPackageContainer: {
    paddingLeft: '12px',
    paddingRight: '12px',
    position: 'relative',
    paddingBottom: 108,
    height: 'calc(100vh - 120px)',
    overflow: 'auto',
  },
  packageRow: {
    backgroundColor: 'white',
    '& .MuiTableCell-sizeSmall:last-child': {
      borderRadius: 8,
    },
    '& .MuiTableCell-paddingCheckbox': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  packageLossRow: {
    background: '#DBDFE8',
    '& .MuiTableCell-sizeSmall:last-child': {
      borderRadius: 8,
    },
  },
  skuFieldLostText: {
    fontSize: 15,
    position: 'absolute',
    fontWeight: 'normal',
    right: 16,
  },
  lossToolbar: {
    background: 'white',
    width: '100vw',
    height: 64,
    position: 'fixed',
    bottom: 0,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100vw - 32px)',
    height: 64,
  },
  wrapperPrintContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100vw - 32px)',
  },
  button: {
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B3B5C',
    height: 42,
    width: '100%',
    borderRadius: 8,
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#8795B3',
    },
  },
  buttonOrange: {
    backgroundColor: '#FCD660',
  },
  buttonRed: {
    backgroundColor: '#F85B5B',
    color: 'white',
  },
  buttonBorder: {
    border: '1px solid #2B3B5C',
  },
  buttonDisabled: {},
  lossDialog: {
    backgroundColor: '#F1F0F5',
    width: '100vw',
    height: 'calc(100vh - 48px)',
    paddingTop: 48,
    overflow: 'auto',
  },
  underlinedRed: {
    color: '#F85B5B',
    textDecorationLine: 'underline',
  },
  lossCard: {
    width: 'calc(100vw - 32px)',
    height: 96,
    background: 'white',
    marginTop: 16,
    fontSize: 18,
    color: '#2B3B5C',
    marginLeft: 16,
    borderRadius: 8,
  },
  lossManualAddText: {
    color: '#8795B3',
    left: '50%',
    transform: 'translate(-50%, 0)',
    textDecoration: 'underline',
    position: 'absolute',
    top: '85vh',
    zIndex: 3,
  },
  lossHeadingText: {
    fontWeight: 'bold',
    padding: 16,
  },
  lossDetailText: {
    fontWeight: 'normal',
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',
  },
  detailContainer: {
    background: '#F1F0F5',
    height: '100vh',
    paddingBottom: 82,
  },
  lossIcon: {
    position: 'absolute',
    right: 16,
    paddingTop: 8,
    color: '#8795B3',
  },
  printIcon: {
    position: 'absolute',
    right: 16,
    marginTop: 80,
    color: '#8795B3',
  },
  StoreReceptionContainer: {
    height: 'calc(100vh - 108px)',
  },
  StoreReceptionScrollableView: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  newSkuDialog: {
    background: '#F1F0F5',
    height: '100vh',
  },
  newSkuHeader: {
    width: '100vw',
    textAlign: 'center',
    top: 'calc(50% - 147px)',
    position: 'absolute',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#2B3B5C',
  },
  newSkuSupplierInput: {
    background: '#fff',
    left: '50%',
    transform: 'translate(-50%, 0)',
    top: 'calc(50% - 101px)',
    width: 312,
    height: 56,
    borderRadius: 8,
  },
  newSkuRefInput: {
    background: '#fff',
    left: '50%',
    transform: 'translate(-50%, 0)',
    top: 'calc(50% - 85px)',
    width: 312,
    height: 56,
    borderRadius: 8,
  },
  newSkuNoRefInput: {
    background: '#fff',
    left: '50%',
    transform: 'translate(-50%, 0)',
    top: 'calc(50%  - 69px)',
    width: 312,
    height: 56,
    borderRadius: 8,
  },
  newSkuNoRefText: {
    textDecoration: 'underline',
    bottom: 108,
    left: '50%',
    transform: 'translate(-50%, 0)',
    fontSize: 15,
    fontWeight: 'normal',
    color: '#8795B3',
    position: 'fixed',
  },
  newSkuButton: {
    bottom: 24,
    left: '50%',
    transform: 'translate(-50%, 0)',
    position: 'fixed',
    width: 'calc(100vw - 48px)',
    height: 54,
    background: '#FCD660',
    borderRadius: 8,
    fontWeight: 'bold',
    fontSize: 14,
    color: '#2B3B5C',
  },
  newSkuInvalidRef: {
    left: '50%',
    transform: 'translate(-105%, 0)',
    top: 'calc(calc(50%  - 20px))',
    color: '#ff0000',
    position: 'fixed',
  },
  skuLocationManualIdentifier: {
    color: '#8795B3',
    left: '50%',
    transform: 'translate(-50%, 0)',
    textDecoration: 'underline',
    position: 'fixed',
    bottom: 26,
    zIndex: 3,
  },
  errorText: {
    color: 'red',
    padding: '0 24px',
  },
  customToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100vw',
    height: 72,
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
  },
  buttonToolbar: {
    width: 'calc(calc(100vw - 32px))',
    left: 16,
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B3B5C',
    height: 42,
    borderRadius: 8,
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#8795B3',
    },
    top: 11,
  },
  SkuFieldSubContainer: {
    height: 109,
    textAlign: 'start',
    display: 'grid',
    paddingLeft: 16,
  },
  qrImageContainer: {
    width: 100,
  },
  icon: {
    background: 'black',
    color: 'white',
    borderRadius: '50%',
    margin: 'auto',
  },
  lossList: {
    paddingTop: 48,
  },

  fullPackageText: {
    width: '100%',
    textAlign: 'center',
    color: '#8795B3',
    textDecoration: 'underline',
  },
  locationSelectionWrapper: {
    display: 'flex',
    flexFlow: 'column',
    height: '80vh',
  },
});

export default StoreStyles;
