import React from 'react';

import { IconProps } from '@material-ui/core';

import FlatCard from 'components/FlatCard';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { SparePart } from 'types/spare-part';

type MinimalSparePartDetailProps = {
  icon: IconProps;
  sparePart: SparePart;
};

const MinimalSparePartDetail: React.FunctionComponent<MinimalSparePartDetailProps> = ({
  icon,
  sparePart,
}: MinimalSparePartDetailProps) => {
  return (
    <FlatCard justifyContent="space-between" display={'flex'} key={sparePart.id} mt={1}>
      <Box>
        <Text textAlign="start" variant="small" color="#8795B3">
          {sparePart.sku_model.designation}
        </Text>
        <Text textAlign="start" variant="small" color="#2B3B5C">
          {sparePart.sku_model.supplier_reference}
        </Text>
      </Box>
      {icon}
    </FlatCard>
  );
};

export default MinimalSparePartDetail;
