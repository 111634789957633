import React, { forwardRef, useRef, useState } from 'react';

import { useRefresh } from 'react-admin';

import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import ExtensionIcon from '@material-ui/icons/Extension';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';

import RepairmanStockStyles from 'pages/RepairmanStock/RepairmanStockStyles';
import IncomingSkuTransferSelection from 'pages/RepairmanStock/components/IncomingTransferSelection';

import CreateNewSku from 'components/CreateNewSku/CreateNewSku';
import PackageReceptionScan from 'components/PackageReception/components/PackageReceptionScan';
import { PACKAGE_STATUS_SENT } from 'components/PackageReception/constants';
import SparePartReception from 'components/SparePartReception';
import { PackageIcon } from 'components/SpeedDial/PackageIcon.js';
import SpeedDial from 'components/SpeedDial/SpeedDial';
import SpeedDialAction from 'components/SpeedDial/SpeedDialAction';
import SpeedDialIcon from 'components/SpeedDial/SpeedDialIcon';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import PackageList from 'components/molecules/PackageList';

import IncomingSkuConfirmationModal from './IncomingSkuConfirmationModal';
import RepairmanScanModal from './RepairmanScanModal';

const styleList = makeStyles(RepairmanStockStyles);

const DONE = 'done';
const LOST = 'lost';
const RECEIVED_TECH = 'received_tech';

// LOSS REASONS
const REPAIRMAN_RECEPTION = 'repairman_reception';
const TRANSFER = 'transfer';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TransitionScan = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RepairmanStockReception() {
  const classes = styleList();
  const refresh = useRefresh();
  const { employee } = useEmployee();

  const [packageId] = useState();
  const [packageCode] = useState();
  const [isScanModalOpen, setIsScanModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [error, setError] = useState('');
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [isIncomingSkuModalOpen, setIsInconmingSkuModalOpen] = useState(false);
  const [incomingSku, setIncomingSku] = useState({});
  const [isCreateNewSkuModalOpened, setIsCreateNewSkuModalOpened] = useState(false);
  const [isTransferSelectionModalOpen, setIsTransferSelectionModalOpen] = useState(false);

  const [isScanningTransferTask, setIsScanningTransferTask] = useState(false);
  const [transferId, setTransferId] = useState();

  const [isScanLoading, setIsScanLoading] = useState(false);

  const [isOpenPackageReceptionOpen, setIsOpenPackageReceptionOpen] = useState(false);

  const childRef = useRef();

  const handleCloseScanModal = () => {
    setError('');
    setIsScanModalOpen(false);
  };

  const handleSelectTransfer = () => {
    setIsTransferSelectionModalOpen(true);
  };

  const handleCloseTransferSelection = () => {
    setIsTransferSelectionModalOpen(false);
  };

  const handleCloseScanningTransferTask = () => {
    setIsScanningTransferTask(false);
  };

  const handleOpenCreateNewSkuModal = () => {
    setIsCreateNewSkuModalOpened(true);
  };

  const handleCloseCreateNewSkuModal = () => {
    setIsCreateNewSkuModalOpened(false);
  };

  //Handling QrScanning for out of package sku
  const handleIncomingSku = (unique_id) => {
    dataProvider
      .getOne('sku', { unique_id: unique_id })
      .then((response) => {
        if (response.data) {
          if (response.data.length > 0) {
            handleOpenIncomingSkuConfirmationModal();
            setIncomingSku(response.data[0]);
          } else {
            setError('Erreur sku : ' + unique_id);
          }
          setIsScanLoading(false);
        }
      })
      .catch((e) => {
        setIsScanLoading(false);
        console.error(e);
      });
  };

  const handleCreateSku = (model) => {
    dataProvider
      .create('sku', {
        data: {
          sku_model: model.id,
          status: 'received_tech',
          tech_location: 'on',
        },
      })
      .then(() => {
        refresh();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleConfirmBarcode = (data) => {
    if (!isScanLoading) {
      setIsScanLoading(true);
      handleIncomingSku(data);
    }
  };

  const handleOpenIncomingSkuConfirmationModal = () => {
    setIsInconmingSkuModalOpen(true);
    setIsScanModalOpen(false);
  };

  const handleCloseIncomingSkuConfirmationModal = () => {
    setIsInconmingSkuModalOpen(false);
  };

  const addSkuToList = (id) => {
    handleCloseScanModal();
    const filteredArray = skuList.filter((el) => el.id !== id);
    setSkuList(filteredArray);
    setSelectedIds((oldArray) => [...oldArray, id]);
    childRef.current.refreshList();
  };

  const handleTransferScreen = (transfer_id) => {
    setTransferId(transfer_id);
    setIsScanningTransferTask(true);
    setIsTransferSelectionModalOpen(false);
  };

  const handleScanIncomingSku = () => {
    setIsTransferSelectionModalOpen(false);
    setIsScanModalOpen(true);
    setError('');
  };

  const actions = [
    {
      icon: <PackageIcon />,
      name: 'Recevoir un colis',
      action: () => setIsOpenPackageReceptionOpen(true),
    },

    {
      icon: <ExtensionIcon />,
      name: 'AJOUT DE PIÈCE(S)',
      action: handleSelectTransfer,
    },
  ];

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleUpdateSkuAfterValidation = (selectedIds, lostSkuList) => {
    const dataToSku = { status: RECEIVED_TECH, reception: TRANSFER };

    dataProvider
      .updateMany('sku', {
        ids: selectedIds,
        data: dataToSku,
      })
      .then(() => {
        dataProvider.updateMany('sku', {
          ids: lostSkuList,
          data: {
            status: LOST,
            loss_reason: REPAIRMAN_RECEPTION,
          },
        });
      })
      .then(() => {
        handleCloseScanModal();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleUpdateSkuTransfer = (selectedId, selectedIds, _, lostSkuList) => {
    dataProvider
      .update('sku-transfer', {
        id: selectedId,
        data: {
          status: DONE,
        },
      })
      .then(() => {
        handleUpdateSkuAfterValidation(selectedIds, lostSkuList);
        handleCloseScanModal();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <div className={classes.RepairmanStokReceptionContainer}>
        <Box height="calc(100vh - 172px)" overflow={'scroll'}>
          <Text mx={2} mt={2} variant="body">
            Expédié par l’atelier
          </Text>
          <PackageList filter={{ enable_employee_filter: 'on', status: 'sent' }} />

          <Text mx={2} mt={2} variant="body">
            Reçu
          </Text>
          <PackageList
            filter={{
              enable_employee_filter: 'on',
              status: 'received',
            }}
          />
        </Box>

        {isOpenPackageReceptionOpen && (
          <PackageReceptionScan
            packageFilter={{ status: PACKAGE_STATUS_SENT, enable_employee_filter: 'on' }}
            handleClosePackageReception={() => setIsOpenPackageReceptionOpen(false)}
            packageUpdatePayload={{}}
          />
        )}

        <RepairmanScanModal
          excludeList={selectedIds}
          mode={'incomingSku'}
          packageId={packageId}
          error={error}
          bodyText={"Prenez l'ensemble de l'étiquette, veillez à la netteté."}
          headerText={'Scanner une pièce entrante'}
          TransitionComponent={TransitionScan}
          handleConfirmBarcode={handleConfirmBarcode}
          onClose={handleCloseScanModal}
          open={isScanModalOpen}
          addManualSku={addSkuToList}
          isScanLoading={isScanLoading}
          manualInputCallback={handleOpenCreateNewSkuModal}
        />
        <IncomingSkuConfirmationModal
          TransitionComponent={Transition}
          open={isIncomingSkuModalOpen}
          onClose={handleCloseIncomingSkuConfirmationModal}
          incomingSku={incomingSku}
        />
        <IncomingSkuTransferSelection
          TransitionComponent={TransitionScan}
          open={isTransferSelectionModalOpen}
          onClose={handleCloseTransferSelection}
          scanIncomingSku={handleScanIncomingSku}
          handleTransferScreen={handleTransferScreen}
        />
        <CreateNewSku
          TransitionComponent={TransitionScan}
          open={isCreateNewSkuModalOpened}
          onClose={handleCloseCreateNewSkuModal}
          handleCreateSku={handleCreateSku}
        />
        <SparePartReception
          selectedId={transferId}
          TransitionComponent={Transition}
          open={isScanningTransferTask}
          onClose={handleCloseScanningTransferTask}
          employeeName={employee.user?.first_name}
          mode={TRANSFER}
          sparePartFilter={{ transfer_id: transferId }}
          handleCreate={handleUpdateSkuTransfer}
          packageCode={packageCode}
        />
      </div>
      <SpeedDial
        ariaLabel="RepairMan Menu"
        icon={<SpeedDialIcon />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        className={`${classes.speedDial} ${classes.speedDialPage0}`}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.action}
          />
        ))}
      </SpeedDial>
    </>
  );
}
