import styled from 'styled-components';

import Box from 'components/atoms/Box';

type ModalContainerProps = {
  fullScreen?: boolean;
};

const ModalContainer = styled(Box)<ModalContainerProps>`
  padding: ${(props) =>
    props.fullScreen ? `${props.theme.space[4]}px 0` : `${props.theme.space[4]}px`};
  background-color: ${(props) => props.theme.colors.white};
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.fullScreen ? '100vw' : 280)};
  height: ${(props) => (props.fullScreen ? '100vh' : '')};
  text-align: center;
`;

export default ModalContainer;
