import React, { FC, useState } from 'react';

import { resetCache } from 'service-worker.utils';
import { register } from 'serviceWorkerRegistration';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

type ServiceWorkerControllerProps = {
  children: React.ReactNode;
};

const ServiceWorkerController: FC<ServiceWorkerControllerProps> = ({
  children,
}: ServiceWorkerControllerProps) => {
  const [showReload, setShowReload] = useState(false);
  const [installingWorker, setInstallingWorker] = useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setInstallingWorker(registration.installing);
  };

  React.useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    installingWorker?.postMessage({ type: 'SKIP_WAITING' });
    resetCache();
    setShowReload(false);
  };

  return (
    <>
      {showReload && (
        <Box py={2} backgroundColor="#3E3E3E" onClick={reloadPage}>
          <Text paddingX={4} textAlign="center" variant="small" color="#ffffff">
            Une nouvelle version de l&apos;app est disponible, cliquez ici pour la mettre à jour.
          </Text>
        </Box>
      )}
      {children}
    </>
  );
};

export default ServiceWorkerController;
