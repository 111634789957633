import React from 'react';

import { parseEventDate } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Box from 'components/atoms/Box';
import Tag from 'components/atoms/Tag';
import Text from 'components/atoms/Text';

import { CollectionItem } from 'types/product-history';

import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';
import SimpleBlockField from './SimpleBlocks/SimpleBlockField';
import SimpleBlockTitle from './SimpleBlocks/SimpleBlockTitle';

type CollectionItemBlockProps = {
  collectionItem: CollectionItem;
};

const CollectionItemBlock: React.FunctionComponent<CollectionItemBlockProps> = ({
  collectionItem,
}: CollectionItemBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <SimpleBlockTitle>
          <Tag variant="primary" text="Information client" />
          <Text variant="caption" color="grey900">
            {parseEventDate(collectionItem.created_at)}
          </Text>
        </SimpleBlockTitle>
      </Box>
      {isOpen && (
        <SimpleBlockContent noSeparator>
          <Box mt={1} mb={1} width="100%" justifyContent="space-between" alignItems="center">
            <SimpleBlockField
              name="Appareil fonctionnel"
              value={collectionItem.is_broken ? 'Non' : 'Oui'}
            />
            <SimpleBlockField
              name="Dernière utilisation de l'appareil"
              value={collectionItem.last_time_used}
            />
            {collectionItem.problem && (
              <SimpleBlockField name="Panne" value={collectionItem.problem} />
            )}
          </Box>
        </SimpleBlockContent>
      )}
    </Box>
  );
};
export default CollectionItemBlock;
