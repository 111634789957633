import React, { useState } from 'react';

import { useEmployee } from 'employeeContext';
import styled from 'styled-components';

import SkuLocationSelectionPortal from 'components/SkuLocationSelectionPortal';
import { PackageIcon } from 'components/SpeedDial/PackageIcon.js';
import SpeedDial from 'components/SpeedDial/SpeedDial';
import SpeedDialAction from 'components/SpeedDial/SpeedDialAction';
import SpeedDialIcon from 'components/SpeedDial/SpeedDialIcon';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import PackageList from 'components/molecules/PackageList';

import { SkuLocation } from 'types/sku-location';

import PackageReceptionScan from './components/PackageReceptionScan';
import { PACKAGE_STATUS_RECEIVED, PACKAGE_STATUS_SENT } from './constants';

const StyledSpeedDial = styled(SpeedDial)((props) => ({
  position: 'fixed',
  bottom: props.theme.space[9],
  right: props.theme.space[2],
  color: 'green',
}));

const SparePartReception: React.FunctionComponent = () => {
  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);
  const [isOpenPackageReceptionOpen, setIsOpenPackageReceptionOpen] = useState<boolean>(false);
  const [isLocationSelectionOpen, setIsLocationSelectionOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<SkuLocation>();

  const { employee } = useEmployee();
  const actions = [
    {
      icon: <PackageIcon />,
      tooltipTitle: 'Recevoir un colis',
      onClick: () => setIsLocationSelectionOpen(true),
      tooltipOpen: true,
    },
  ];

  const speedDialProps = {
    ariaLabel: 'spare part reception',
    icon: <SpeedDialIcon />,
    onClose: () => setOpenSpeedDial(false),
    onOpen: () => setOpenSpeedDial(true),
    open: openSpeedDial,
  };

  return (
    <Box>
      <Box height="calc(100vh - 172px)" overflow={'scroll'}>
        <Text mx={2} mt={2} variant="body">
          Expédié
        </Text>
        <PackageList filter={{ workshop: employee.workshop, status: PACKAGE_STATUS_SENT }} />
        <Text mx={2} mt={2} variant="body">
          Reçu
        </Text>
        <PackageList
          filter={{
            workshop: employee.workshop,
            status: PACKAGE_STATUS_RECEIVED,
          }}
        />
      </Box>
      {isLocationSelectionOpen && (
        <SkuLocationSelectionPortal
          filter={{ workshop: employee.workshop }}
          isOpen={isLocationSelectionOpen}
          handleClosePortal={() => {
            setIsLocationSelectionOpen(false);
            setLocation(undefined);
          }}
          title="Sélectionner un emplacement"
          onConfirm={(location: SkuLocation) => {
            setLocation(location);
            setIsLocationSelectionOpen(false);
            setIsOpenPackageReceptionOpen(true);
          }}
          handleOpenPortal={() => setIsLocationSelectionOpen(true)}
        />
      )}
      {isOpenPackageReceptionOpen && (
        <PackageReceptionScan
          packageFilter={{ status: PACKAGE_STATUS_SENT, workshop: employee.workshop }}
          handleClosePackageReception={() => setIsOpenPackageReceptionOpen(false)}
          packageUpdatePayload={{ location: location?.id, workshop: employee?.workshop }}
        />
      )}

      <StyledSpeedDial {...speedDialProps}>
        {actions.map((action) => (
          <SpeedDialAction key={action.tooltipTitle} {...action} />
        ))}
      </StyledSpeedDial>
    </Box>
  );
};
export default SparePartReception;
