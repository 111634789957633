import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ModalContainer from 'components/organisms/modals/ModalContainer';
import ContentModal from 'components/organisms/modals/ModalContent';

export type ConfirmModalProps = {
  title: string;
  content: string | string[];
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  icon?: React.ReactNode;
};

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = ({
  content,
  title,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirmer',
  cancelLabel = 'Annuler',
  icon,
}: ConfirmModalProps) => {
  return (
    <ModalContainer>
      {icon}
      <Text variant="bodyBold" mb={3}>
        {title}
      </Text>
      <ContentModal content={content} />
      <Button variant="fullTransparent" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button variant="primary" onClick={onConfirm} minWidth={192}>
        {confirmLabel}
      </Button>
    </ModalContainer>
  );
};

export default ConfirmModal;
