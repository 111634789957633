import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import Text from 'components/atoms/Text';

type DataFetchingStatusScreenProps = {
  loadingText: string;
  error?: string;
  loading: boolean;
};

const DataFetchingStatusScreen: React.FunctionComponent<DataFetchingStatusScreenProps> = ({
  loadingText,
  error,
  loading,
}: DataFetchingStatusScreenProps) => {
  if (loading) {
    return (
      <Box p={4}>
        <Text variant="body">{loadingText}</Text>
        <CircularProgress color="inherit" />
      </Box>
    );
  }
  if (error) {
    return (
      <Box p={4}>
        <Text variant="body">{error}</Text>
        <ErrorIcon />
      </Box>
    );
  }
  return null;
};

export default DataFetchingStatusScreen;
