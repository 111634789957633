import { createStyles } from '@material-ui/core/styles';

const RepairReportButtonStyle = createStyles({
  basicButton: {
    backgroundColor: '#FFFFFF',
    margin: '8px 24px',
    height: 56,
    boxSizing: 'border-box',
    borderRadius: 10,
    justifyContent: 'flex-start',
    boxShadow: 'none',
    border: '1px solid #DBDFE8',
    color: '#2B3B5C',
    fontSize: 18,
  },
  validateButton: {
    background: '#FCD660',
    margin: '20px 24px',
    height: 42,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#2B3B5C',
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 48px)',
  },
  paymentButton: {
    background: '#4A55FE',
    margin: '20px 24px',
    height: 42,
    borderRadius: 8,
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#ffffff',
    boxShadow: 'none',
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 48px)',
  },
});

export default RepairReportButtonStyle;
