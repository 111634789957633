import React from 'react';

import { Box } from '@material-ui/core';

import animationData from 'assets/check.json';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import { SkuModel } from 'types/sku-model';

const StyledContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  height: 'calc(100% - 57px)',
  padding: `0 ${props.theme.space[3]}px`,
  boxSizing: 'border-box',
}));

const BottomButton = styled(Button)((props) => ({
  bottom: props.theme.space[2],
  position: 'absolute',
  width: '90%',
}));

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const TextButton = styled.div((props) => ({
  textDecoration: 'underline',
  color: props.theme.colors.grey900,
  fontSize: props.theme.fontSizes[2],
  marginTop: props.theme.space[6],
}));

type ConfirmationContainerProps = {
  skuModel: SkuModel;
  setSkuModel: (skuModel: SkuModel | undefined) => void;
  onSkuModelValidation: (skuModel: SkuModel) => void;
};

const ConfirmationContainer: React.FunctionComponent<ConfirmationContainerProps> = ({
  skuModel,
  setSkuModel,
  onSkuModelValidation,
}: ConfirmationContainerProps) => {
  return (
    <StyledContainer>
      <Box mt={20}>
        <Lottie options={animationOptions} height={130} width={130} />
      </Box>
      <div>
        <Text variant="title1" mt={3}>
          {skuModel?.supplier} {skuModel?.supplier_reference}
        </Text>
        <Text variant="body" mt={1}>
          {skuModel?.designation}
        </Text>
      </div>
      <TextButton onClick={() => setSkuModel(undefined)}>Modifier la sélection</TextButton>
      <BottomButton variant="primary" onClick={() => onSkuModelValidation(skuModel)} minWidth={192}>
        Confirmer
      </BottomButton>
    </StyledContainer>
  );
};

export default ConfirmationContainer;
