import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { MarginProps } from 'styled-system';

import { useProductChecklist } from 'pages/RepairCycleTasks/hooks';

import PortalComponent from 'components/Portal/PortalComponent';
import { TypeformDefaultSideTab } from 'components/TypeformComponents';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

import { ProductCheckListElement } from 'types/product-check-list-element';
import { Product } from 'types/products';
import { ShippingDeliveryItem } from 'types/shipping-deliveries';

import { SHIPPING } from '../../utils';
import ProductPreparationChecksForm from './ProductPreparationChecksForm';

type ProductPreparationChecklistProps = {
  product: Product;
  isOpen: boolean;
  handleClose: () => void;
  deliveryItem: ShippingDeliveryItem;
} & MarginProps;

const ProductPreparationChecklist: React.FunctionComponent<ProductPreparationChecklistProps> = ({
  product,
  isOpen,
  handleClose,
  deliveryItem,
}: ProductPreparationChecklistProps) => {
  const { productCheckList: preparationChecks, isLoading } = useProductChecklist(
    product.product_type,
    SHIPPING
  );

  const initialValues = {
    shipping: preparationChecks.map((check: ProductCheckListElement) => {
      return {
        id: check.id,
        text: check.display_text,
        is_checked: false,
      };
    }),
  };
  return (
    <>
      <PortalComponent open={isOpen} onClose={handleClose}>
        {isLoading && (
          <>
            <HeaderWithArrow title={`Chargement des vérifications`} action={handleClose} />
            <Box margin="auto">
              <CircularProgress />
            </Box>
          </>
        )}
        {initialValues?.shipping?.length > 0 && (
          <>
            <ProductPreparationChecksForm
              handleClose={handleClose}
              product={product}
              initialValues={initialValues}
              deliveryItem={deliveryItem}
            />
            <TypeformDefaultSideTab barcode={product.barcode} />
          </>
        )}
      </PortalComponent>
    </>
  );
};
export default ProductPreparationChecklist;
