import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Transfer from 'pages/Transfer/Transfer';
import WorkshopRepairCycleDashboard from 'pages/WorkshopRepairCycle/WorkshopRepairCycleDashboard';
import WorkshopRepairCycleScan from 'pages/WorkshopRepairCycle/WorkshopRepairCycleScan';

import ReconditionSparePartAvailabilityMonitoring from './ReconditionSparePartAvailabilityMonitoring';
import ReconditionSpareParts from './ReconditionStockList';

const ReconditionRouter: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path="/recondition/spare-part-availability-monitoring"
        component={ReconditionSparePartAvailabilityMonitoring}
        noLayout
      />
      <Route path="/recondition/transfer/:stepSlug?" component={Transfer} />
      <Route exact path="/recondition/repair-cycle" component={WorkshopRepairCycleScan} noLayout />,
      <Route
        exact
        path="/recondition/repair-cycle/:productId"
        component={WorkshopRepairCycleDashboard}
        noLayout
      />
      <Route path="/recondition/stock" component={ReconditionSpareParts} noLayout />
      <Redirect strict from="/recondition*" to="/recondition/repair-cycle" />
    </Switch>
  );
};

export default ReconditionRouter;
