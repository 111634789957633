import { createStyles } from '@material-ui/core/styles';

const DashboardStyle = createStyles({
  clientInfoContainer: {
    borderTop: '1px solid #DBDFE8',
    backgroundColor: '#FFFFFF',
  },
  clientInfoContent: {
    padding: 16,
    color: '#2B3B5C',
    display: 'flex',
  },
  callClientText: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    textDecoration: 'none',
    color: '#2B3B5C',
  },
  iconAlignedRight: {
    position: 'absolute',
    right: 24,
  },
  sectionProductList: {
    borderBottom: '1px solid #DBDFE8',
    marginTop: '24px',
  },
  buttonContainer: {
    display: 'grid',
  },
  checkedIcon: {
    color: '#81D89F',
  },
  productAdditionCheckedIcon: {
    color: '#4A55FE',
  },
  addNewProductButton: {
    fontSize: '15px',
    lineHeight: '140%',
    textTransform: 'none',
    width: 'fit-content',
    margin: '8px',
    color: '#8795B3',
  },
});

export default DashboardStyle;
