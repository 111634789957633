import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MarginProps, margin } from 'styled-system';

import PaperStripWithIcon from '../PaperStripWithIcon';

type PaperStripLinkBaseProps = {
  link: string;
  Icon: React.FunctionComponent;
  // Decide if we redirect using href or reactrouter
  linkMode: 'href' | 'route';
  handleClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  importantIcon?: boolean;
} & MarginProps;

type PaperStripLinkWithTitleProps = PaperStripLinkBaseProps & {
  textMode?: 'title';
  text: string;
};

type PaperStripLinkWithContentProps = PaperStripLinkBaseProps & {
  textMode?: 'content';
  text: React.ReactNode;
};

export type PaperStripLinkProps = PaperStripLinkWithTitleProps | PaperStripLinkWithContentProps;

const LinkContainer = styled('a')<MarginProps>(margin, () => ({
  textDecoration: 'none',
}));

const PaperStripLink: React.FunctionComponent<PaperStripLinkProps> = ({
  link,
  linkMode,
  handleClick,
  disabled,
  importantIcon,
  ...rest
}: PaperStripLinkProps) => {
  const renderLink = () => {
    return <PaperStripWithIcon disabled={disabled} importantIcon={importantIcon} {...rest} />;
  };

  if (disabled) {
    return <LinkContainer>{renderLink()}</LinkContainer>;
  }

  return linkMode === 'href' ? (
    <LinkContainer href={link} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      {renderLink()}
    </LinkContainer>
  ) : (
    <Link style={{ textDecoration: 'none' }} to={link} onClick={handleClick}>
      {renderLink()}
    </Link>
  );
};

export default PaperStripLink;
