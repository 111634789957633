import React from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Image from 'components/atoms/Image';
import ProductReferenceAutocomplete from 'components/atoms/ProductReferenceAutocomplete';
import Text from 'components/atoms/Text';
import ButtonBlock from 'components/molecules/ButtonBlock';
import ImageSelector from 'components/molecules/ImageSelector';
import ContentContainer from 'components/templates/ContentContainer';

type FormValues = {
  nameplate: File[] | null;
  model: string | null;
  productBrand: string | null;
  productType: number | null;
};

type ProductReceptionNameplateProps = {
  handleSubmit: (values: FormValues) => void;
  goBack: () => void;
  selectedReference: string | null;
};

const StyledContentContainer = styled(ContentContainer)`
  padding: 0px;
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledImage = styled(Image)`
  width: 100%;
  border-radius: 0px; ;
`;

const ProductReceptionNameplate: React.FunctionComponent<ProductReceptionNameplateProps> = ({
  selectedReference,
  handleSubmit,
  goBack,
}) => {
  const initialValues: FormValues = {
    nameplate: null,
    model: selectedReference || '',
    productBrand: '',
    productType: null,
  };
  const validationSchema = yup.object().shape({
    nameplate: yup.mixed().required(),
    model: yup.string().required(),
  });

  const handleSumbitNameplateStep = (values: FormValues) => {
    handleSubmit(values);
  };

  const renderImage = (image: File[]) => {
    return (
      <Box overflow="auto" height={200} position="relative">
        <StyledImage src={URL.createObjectURL(image[0])} alt="preview" />
      </Box>
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSumbitNameplateStep}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => (
        <StyledForm>
          <HeaderWithArrow title={values.nameplate ? 'Modèle' : 'Identification'} action={goBack} />
          <StyledContentContainer>
            {values.nameplate ? (
              renderImage(values.nameplate)
            ) : (
              <Field data-testid="nameplate-input" name="nameplate">
                {({ field: { name }, form }: FieldProps) => {
                  return (
                    <ImageSelector
                      name={name}
                      onChange={(event: React.SyntheticEvent<HTMLInputElement>) =>
                        form.setFieldValue('nameplate', event.currentTarget.files)
                      }
                    />
                  );
                }}
              </Field>
            )}
            {values.nameplate && (
              <Box padding={16} width="100%" alignItems="center" justifyContent="space-between">
                <Text variant="body" justifyContent="space-between" display="flex" m={1}>
                  Saisissez le modèle de l&apos;appareil
                </Text>
                <ProductReferenceAutocomplete fieldName="model" brandField="name" />
                <Button width="100%" mt={5} type="reset" variant="transparent">
                  Reprendre la plaque signalétique en photo
                </Button>
              </Box>
            )}
          </StyledContentContainer>

          <ButtonBlock disabled={!isValid || !dirty}>Valider</ButtonBlock>
        </StyledForm>
      )}
    </Formik>
  );
};

export default ProductReceptionNameplate;
