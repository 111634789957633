import { createMuiTheme } from '@material-ui/core/styles';

const customerThemeFilter = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        border: '1px solid #DBDFE8',
        backgroundColor: '#ffffff',
        color: '#8795B3',
        width: '100vw',
        height: 48,
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          display: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
      inputMarginDense: {
        paddingTop: 20,
      },
    },
    RaSearchInput: {
      input: {
        marginTop: 0,
      },
    },
    RaFilterForm: {
      form: {
        marginTop: 0,
        minHeight: 0,
      },
    },
    MuiInputLabel: {
      filled: {
        fontSize: 15,
        color: '#8795B3',
      },
    },
    RaFilterFormInput: {
      body: {
        display: 'block',
      },
    },
  },
});

export default customerThemeFilter;
