import React from 'react';

import { Button, Dialog } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RepairmanStockStyles from '../RepairmanStockStyles';

const styleList = makeStyles(RepairmanStockStyles);

export default function StockListFilterModal(props) {
  const classes = styleList();

  const handleStockFilter = () => {
    props.handleCloseModal();
    props.handleSetFilters();
  };

  const setNewValues = (value) => {
    const newfilteredLabelList = [...props.filteredLabelList];
    if (props.filteredLabelList.includes(value)) {
      props.setFilteredLabelList([...newfilteredLabelList.filter((label) => label !== value)]);
    } else {
      props.setFilteredLabelList([...newfilteredLabelList, value]);
    }
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={props.TransitionComponent}
      open={props.open}
      onClose={props.onClose}
    >
      <AppBar color="transparent" className={classes.header}>
        <div className={classes.elementHeader}>
          <IconButton
            edge="start"
            onClick={() => props.handleCloseModal()}
            className={classes.back}
          >
            <ArrowBackIcon />
          </IconButton>
          <span>Filtrer</span>
        </div>
      </AppBar>
      <div className={classes.detailContainer}>
        <List dense className={classes.labelList}>
          {props.labelList.map((label, index) => {
            return (
              <>
                <ListItem key={index} className={classes.labelRow}>
                  <ListItemText
                    id={index}
                    classes={{
                      primary: classes.labelText,
                    }}
                    primary={label.name}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      edge="end"
                      value={label.id}
                      onClick={(e) => setNewValues(e.target.value)}
                      checked={props.filteredLabelList.includes(label.id)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </div>
      <div className={classes.filterButtonWrapper}>
        <Button
          className={`${classes.button} ${classes.buttonOrange}`}
          onClick={() => handleStockFilter()}
        >
          Filtrer
        </Button>
      </div>
    </Dialog>
  );
}
