import React from 'react';

import styled from 'styled-components';

import FlatCard from 'components/FlatCard';
import LoaderPlaceHolder from 'components/LoaderPlaceHolder';
import Text from 'components/atoms/Text';

import { SkuTransfer } from 'types/sku-transfer';

type TransferCardProps = {
  isLoading: boolean;
  transfer: SkuTransfer;
  onClick: (transfer_id: number) => void;
};

const FirstLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TransferCard: React.FunctionComponent<TransferCardProps> = ({
  isLoading,
  transfer,
  onClick,
}: TransferCardProps) => {
  return (
    <FlatCard mt={2} variant={'primary'} onClick={() => onClick(transfer.id)}>
      <FirstLineContainer>
        {isLoading ? (
          <LoaderPlaceHolder size="big" />
        ) : (
          <>
            <Text variant="bodyBold">{transfer?.final_location?.employee_name}</Text>
            <Text variant="body">{transfer?.number_skus} pièce(s)</Text>
          </>
        )}
      </FirstLineContainer>
    </FlatCard>
  );
};

export default TransferCard;
