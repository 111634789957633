import React from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { MarginProps, MinWidthProps, WidthProps, margin, minWidth, width } from 'styled-system';

import Box from '../Box';

type BaseChoiceButtonProps = {
  padding?: string;
} & MarginProps &
  WidthProps &
  MinWidthProps;

const BaseChoiceButton = styled('div')<BaseChoiceButtonProps>(
  margin,
  width,
  minWidth,

  (props: BaseChoiceButtonProps) => ({
    border: 0,
    cursor: 'pointer',
    fontFamily: 'GT-Walsheim',
    fontSize: themeGet('fontSizes.1')(props),
    fontWeight: themeGet('fontWeights.medium')(props),
    padding: props.padding ? props.padding : `${themeGet('space.2')(props)}px`,
    display: 'flex',
    backgroundColor: 'white',
    color: 'dark',
  })
);

export type CheckBoxProps = {
  children: React.ReactNode;
  active?: boolean;
  color?: string;
  displayNonSelectedIcon?: boolean;
  iconSize?: 'small' | 'default' | 'large';
} & BaseChoiceButtonProps &
  React.HTMLAttributes<HTMLDivElement>;

const CheckBox: React.FunctionComponent<CheckBoxProps> = ({
  children,
  active,
  color = '#1A8E96',
  displayNonSelectedIcon,
  iconSize = 'small',
  ...rest
}: CheckBoxProps) => (
  <>
    <BaseChoiceButton {...rest}>
      {active ? (
        <CheckBoxIcon style={{ color: color }} fontSize={iconSize} />
      ) : (
        displayNonSelectedIcon && (
          <CheckBoxOutlineBlankIcon style={{ color: 'black' }} fontSize={iconSize} />
        )
      )}

      {children}
    </BaseChoiceButton>
    <Box borderBottom={'1px solid #E7E7E7'} width={'60%'} margin={'auto'} />
  </>
);

export default CheckBox;
