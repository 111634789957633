import React from 'react';

import animationData from 'assets/check.json';
// import lottie animations
import Lottie from 'react-lottie';
import styled from 'styled-components';

import SelectLocation from 'pages/Store/components/SelectLocation';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import { Location } from 'types/location';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ConfirmationContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  height: 'calc(100% - 57px)',
  padding: `0 ${props.theme.space[3]}px`,
  boxSizing: 'border-box',
}));

const BottomButton = styled(Button)((props) => ({
  alignSelf: 'self-end',
  margin: `${props.theme.space[2]}px 0`,
}));

const TextButton = styled.div((props) => ({
  textDecoration: 'underline',
  color: props.theme.colors.grey900,
  fontSize: props.theme.fontSizes[2],
}));

type SelectProductLocationProps = {
  locations: Location[];
  open: boolean;
  handleClose: () => void;
  handleChangeLocation: (locationId: number | unknown) => void;
};

const SelectProductLocation: React.FunctionComponent<SelectProductLocationProps> = ({
  locations,
  open,
  handleClose,
  handleChangeLocation,
}: SelectProductLocationProps) => {
  const [location, setLocation] = React.useState<Location>();
  const [error, setError] = React.useState<string>('');

  const getLocationFromPath = (stringPath: string) => {
    return locations.find((el: Location) => el.path === stringPath);
  };
  const getLocationFromId = (id: number | unknown) => {
    return locations.find((el: Location) => el.id === id);
  };

  const processPath = (barcode: string) => {
    let path = barcode;
    path = path.split('/').join('.');
    path = path.split('-').join('_');
    return path;
  };

  const handleConfirmBarcode = (barcode: string) => {
    const path = processPath(barcode);
    const location = getLocationFromPath(path);
    if (location) {
      setLocation(location);
    } else {
      setError(`Emplacement inconnu: ${path}`);
    }
  };
  const handleSelectLocation = (id: number) => {
    const location = getLocationFromId(id);
    setLocation(location);
  };

  return (
    <PortalComponent open={open} onClose={handleClose} title="Identifier un emplacement">
      {location ? (
        <ConfirmationContainer>
          <Box mt={150}>
            <Lottie options={animationOptions} height={130} width={130} />
          </Box>
          <div>
            <Text variant="title1" mt={3}>
              {location.code}
            </Text>
            <Text variant="body" mt={1}>
              {location.name}
            </Text>
          </div>
          <TextButton onClick={() => setLocation(undefined)}>Modifier la sélection</TextButton>
          <BottomButton
            variant="primary"
            onClick={() => handleChangeLocation(location.id)}
            minWidth={192}
          >
            Confirmer
          </BottomButton>
        </ConfirmationContainer>
      ) : (
        <SelectLocation
          locations={locations}
          handleConfirmBarcode={handleConfirmBarcode}
          error={error}
          handleChangeLocation={handleSelectLocation}
        />
      )}
    </PortalComponent>
  );
};

export default SelectProductLocation;
