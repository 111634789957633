import React from 'react';

import { useHistory } from 'react-router-dom';
import { MarginBottomProps } from 'styled-system';

import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

import useQuerySearch from 'utils/useQuerySearch';

export type GoBackHeaderProps<T> = {
  title: string;
  backLink?: string;
  state?: T;
} & MarginBottomProps;

function GoBackHeader<T>({ backLink, state, ...rest }: GoBackHeaderProps<T>): React.ReactElement {
  const history = useHistory();
  const query = useQuerySearch();
  const embeded = query.get('embeded');
  if (embeded === 'true') {
    return <> </>;
  }

  const handleClick = () => {
    backLink ? history.push(backLink, state) : history.goBack();
  };
  return <HeaderWithArrow {...rest} action={handleClick} />;
}

export default GoBackHeader;
