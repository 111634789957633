import { createStyles } from '@material-ui/core/styles';

const TimeEvaluationStyle = createStyles({
  button: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    '& button': {
      width: 'calc(100% - 48px)',
    },
  },
  TimeEvaluationContainer: {
    boxSizing: 'border-box',
    margin: '24px',
    display: 'grid',
  },
  textDescription: {
    margin: '12px 0px',
    fontSize: 14,
  },
  timeContainer: {
    marginBottom: 16,
  },
  basicTextArea: {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    justifyContent: 'flex-start',
    width: '100%',
    color: '#2B3B5C',
  },
});

export default TimeEvaluationStyle;
