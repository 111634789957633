import React from 'react';

import { AutocompleteInput, SimpleForm } from 'react-admin';

import { Dialog } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import animationData from 'assets/scan.json';
import dataProvider from 'dataProvider';
import Lottie from 'react-lottie';

import QrScanner from 'utils/QrScanner';

import RepairmanStockStyles from '../RepairmanStockStyles';

const useStyles = makeStyles(RepairmanStockStyles);

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function RepairmanScanModal(props) {
  const classes = useStyles();
  const [manualInput, setManualInput] = React.useState(false);
  const [choicesList, setChoicesList] = React.useState([]);
  const handleCloseModal = () => {
    setManualInput(false);
    props.onClose();
  };
  const handleManualInput = () => {
    if (props.mode === 'incomingSku') {
      handleCloseModal();
      props.manualInputCallback();
    } else {
      // If mode === sku or package
      fetchChoicesList();
      setManualInput(true);
    }
  };

  const fetchData = (ressource, filter, exclude) => {
    dataProvider
      .getList(ressource, {
        sort: { field: 'id', order: 'DESC' },
        filter: filter,
      })
      .then(({ data }) => {
        setChoicesList(
          exclude
            ? data.filter((el) => !props.excludeList.includes(el.id) && el.status !== 'lost')
            : data
        );
      });
  };

  const fetchChoicesList = () => {
    switch (props.mode) {
      case 'package':
        fetchData('package', { status: 'sent' }, false);
        break;
      case 'sku':
        fetchData('sku', { package_id: props.packageId }, true);
        break;
      case 'skuTransfer':
        fetchData('sku', { enable_employee_filter: 'on' }, true);
        break;
      default:
        break;
    }
  };

  const handleSelect = (data) => {
    if (props.mode === 'package') {
      props.handleConfirmBarcode(data);
    } else {
      props.addManualSku(data);
    }

    setChoicesList([]);
    setManualInput(false);
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={props.TransitionComponent}
      open={props.open}
      onClose={props.onClose}
    >
      <CloseIcon className={classes.closeButton} onClick={handleCloseModal} />
      <div className={classes.repairmanScanHeader}>
        <div>
          {manualInput && props.manualHeaderText ? props.manualHeaderText : props.headerText}
        </div>
      </div>

      {manualInput ? (
        <>
          <div className={classes.manualInputWrapper}>
            <SimpleForm toolbar={false}>
              <AutocompleteInput
                label={props.mode === 'package' ? 'Colis' : 'Pièce'}
                source={props.mode}
                optionValue={props.mode === 'package' ? 'code' : 'id'}
                optionText={props.mode === 'package' ? 'code' : 'sku_model.sku_reference'}
                choices={choicesList}
                onChange={(event) => handleSelect(event)}
              />
            </SimpleForm>
          </div>
          {props.addManualSkuCreation && (
            <div className={classes.manualAddText}>
              <span onClick={props.addManualSkuCreation}>Référence absente du stock</span>
            </div>
          )}
        </>
      ) : (
        <div>
          <div className={classes.qrContainer}>
            <div className={classes.qrReader}>
              <QrScanner handleConfirmBarcode={props.handleConfirmBarcode} />
            </div>
          </div>

          {props.error && <div className={classes.errorText}>{props.error}</div>}
          <div className={classes.repairmanScanBody}>
            <div className={classes.repairmanScanBodyContainer}>
              <div className={classes.repairmanScanBodyContainer}>
                {props.isScanLoading ? (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Lottie
                    options={animationOptions}
                    height={150}
                    width={150}
                    isClickToPauseDisabled={true}
                  />
                )}
              </div>
              <div className={classes.repairmanScanBodyText}>
                <span>{props.bodyText}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {!manualInput && !props.hideManualInput && (
        <div className={classes.manualInputText}>
          <span onClick={handleManualInput}>Identifier manuellement</span>
        </div>
      )}
    </Dialog>
  );
}
