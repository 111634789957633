import React from 'react';

// Icons
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Redux
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Types
import { Step, StepId } from 'pages/RepairReport/services/stepHelper';
import ReportStepPlayerStyle from 'pages/RepairReport/styles/ReportStepsPlayerStyle';

import { CustomerFile } from 'types/customer-files';

import { resetIsPosted } from './module';

const useStyles = makeStyles(ReportStepPlayerStyle);

export type ReportStepsHeaderProps = {
  customerFile: CustomerFile;
  stepSlug: StepId;
  setPreviousStep: () => void;
  currentStep: Exclude<Step, { id: 'intermediate_validation_screen' }>;
};

const ReportStepsHeader: React.FunctionComponent<ReportStepsHeaderProps> = ({
  stepSlug,
  customerFile,
  setPreviousStep,
  currentStep,
}: ReportStepsHeaderProps) => {
  const classes = useStyles(ReportStepPlayerStyle);
  const history = useHistory();
  const dispatch = useDispatch();

  const resetAndGoBackToCalendar = () => {
    dispatch(resetIsPosted());
    history.push('/calendar');
  };

  const renderBackButton = () => {
    if (stepSlug === 'report_done') {
      return (
        <IconButton onClick={resetAndGoBackToCalendar} className={classes.arrowBack} edge="start">
          <ArrowBackIosIcon />
        </IconButton>
      );
    } else if (stepSlug === 'appliance_nameplate' && customerFile.category === 'workshop_repair') {
      return (
        <IconButton onClick={() => history.goBack()} className={classes.arrowBack} edge="start">
          <ArrowBackIosIcon />
        </IconButton>
      );
    } else {
      return (
        <IconButton onClick={setPreviousStep} className={classes.arrowBack} edge="start">
          <ArrowBackIosIcon />
        </IconButton>
      );
    }
  };

  return (
    <div className={classes.playerHeader}>
      <AppBar className={classes.header} color="transparent">
        <div className={classes.headerContent}>
          {renderBackButton()}
          {currentStep && <span className={classes.stepName}>{currentStep.stepName}</span>}
        </div>
      </AppBar>
    </div>
  );
};

export default ReportStepsHeader;
