/* eslint-disable @typescript-eslint/no-explicit-any*/
declare global {
  interface Window {
    gtag: any;
  }
}

export const sendGAEvent = (
  action: string,
  category: string,
  label: string,
  value: string
): void => {
  return window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
