import React from 'react';

import LoaderPlaceHolder from 'components/LoaderPlaceHolder';
import QuoteElementField from 'components/QuoteElementField';
import ListItemContainer from 'components/atoms/ListItemContainer';
import Text from 'components/atoms/Text';

import { QuoteElement } from 'types/quote-elements';

import { formatPrice } from 'utils/formatting';

import { useLastBill } from '../utils/api';

type BillDetailsProps = {
  customerFileId: string;
  totalPaid: number;
};

const BillDetails: React.FunctionComponent<BillDetailsProps> = ({
  customerFileId,
  totalPaid,
}: BillDetailsProps) => {
  const { bill, isBillLoading, isErroredBill } = useLastBill(customerFileId);
  return (
    <>
      {isBillLoading && <LoaderPlaceHolder size="big" />}
      {isErroredBill && (
        <Text variant="small" m={2} color="red">
          Le chargement du détail de la facture à échoué
        </Text>
      )}
      {bill?.quote_elements?.map((quoteElement: QuoteElement) => (
        <QuoteElementField key={quoteElement.id} element={quoteElement} />
      ))}

      <ListItemContainer>
        <Text ml={'4px'} variant="small" color="turquoise">
          Déjà payé
        </Text>
        <Text mr={'48px'} variant="small" color="turquoise" textAlign="center">
          {formatPrice(totalPaid)}
        </Text>
      </ListItemContainer>
    </>
  );
};

export default BillDetails;
