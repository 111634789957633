import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { CenterFocusStrong } from '@material-ui/icons';

import PortalComponent from 'components/Portal/PortalComponent';
import ProductSelectionPortal from 'components/ProductSelectionPortal/ProductSelectionPortal';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import FloatingActionButton from 'components/atoms/FloatingActionButton';
import Text from 'components/atoms/Text';

import { Product } from 'types/products';
import { ShippingDelivery, ShippingDeliveryItem } from 'types/shipping-deliveries';

import ProductPreparationChecklist from '../ShippingPreparation/ProductPreparationChecks/ProductPreparationChecks';
import { getDeliveries } from '../api';

type ProductPreparationScanProps = {
  callback: (bool: boolean) => void;
};

const ProductPreparationScan: React.FunctionComponent<ProductPreparationScanProps> = ({
  callback,
}: ProductPreparationScanProps) => {
  const [isProductSelectionOpen, setIsProductSelectionOpen] = useState<boolean>(false);
  const [scannedDelivery, setScannedDelivery] = useState<ShippingDelivery | undefined>();
  const [scannedDeliveryItem, setScannedDeliveryItem] = useState<ShippingDeliveryItem | null>(null);
  const [scannedProduct, setScannedProduct] = useState<Product | null>(null);
  const [scanned, setScanned] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDelivery = async (productId: number) => {
    try {
      setIsLoading(true);
      const deliveries = await getDeliveries({
        product_id: productId,
      });

      if (deliveries.length > 0) {
        const lastDelivery = deliveries.pop();
        setScannedDelivery(lastDelivery);

        const scannedDeliveryItem = lastDelivery?.items.find(
          (item) => item.product === productId && item.delivery_type === 'delivery'
        );
        setScannedDeliveryItem(scannedDeliveryItem ?? null);
        setScanned(true);
      } else {
        setError('Pas de livraison associée');
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la récupération des informations de livraison');
    } finally {
      setIsLoading(false);
    }
  };

  const resetScan = () => {
    setError(undefined);
    setScannedDelivery(null);
    setScannedDeliveryItem(null);
    setScannedProduct(null);
    setScanned(false);
    callback(false);
  };

  const isPrePortalOpen = () => {
    return error || isLoading;
  };

  return (
    <Box>
      <FloatingActionButton
        page={1}
        variant="primary"
        onClick={() => {
          callback(true);
          setIsProductSelectionOpen(true);
        }}
      >
        <CenterFocusStrong data-testid="scan-icon" />
      </FloatingActionButton>
      {isProductSelectionOpen && (
        <ProductSelectionPortal
          buttonText="Confirmer"
          onClose={() => setIsProductSelectionOpen(false)}
          onProductSelection={(product: Product) => {
            setScannedProduct(product);
            getDelivery(product.id);
          }}
          open={isProductSelectionOpen}
        />
      )}
      {scannedDelivery && scannedDeliveryItem && scannedProduct && scanned && !isLoading && (
        <ProductPreparationChecklist
          isOpen={scanned}
          handleClose={() => resetScan()}
          product={scannedProduct}
          deliveryItem={scannedDeliveryItem}
        />
      )}
      {isPrePortalOpen() && (
        <PortalComponent
          open={!scannedDelivery}
          onClose={() => resetScan()}
          title={`Machine: ${scannedProduct?.barcode}`}
        >
          <Box pt={5}>
            {error && (
              <>
                <Text variant="body"> {error}</Text>
                <BottomButton mt={5} onClick={() => resetScan()} variant="error">
                  Retourner à la liste des expéditions
                </BottomButton>
              </>
            )}
            {isLoading && (
              <>
                <CircularProgress />
                <Text variant="body">Recherche de livraison associée en cours</Text>{' '}
              </>
            )}
          </Box>
        </PortalComponent>
      )}
    </Box>
  );
};

export default ProductPreparationScan;
