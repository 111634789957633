export const ADDITIONAL_PACKAGE = 'additional_package';
export const BASIC_PACKAGE = 'basic_package';
export const COMMERCIAL_GESTURE = 'commercial_gesture';
export const CONSUMABLE = 'consumable';
export const DISCOUNTED_PACKAGE = 'discounted_package';
export const HERMETIC_UNIT = 'hermetic_unit';
export const LEGACY_QUOTE = 'legacy_quote';
export const REDUCTION_CODE = 'reduction_code';
export const SPARE_PART = 'spare_part';
export const REPAIR_BONUS = 'repair_bonus';

export type QuoteElementType =
  | 'additional_package'
  | 'basic_package'
  | 'commercial_gesture'
  | 'consumable'
  | 'discounted_package'
  | 'hermetic_unit'
  | 'legacy_quote'
  | 'reduction_code'
  | 'spare_part'
  | 'repair_bonus';

export const getLabel: { [key in QuoteElementType]: string } = {
  additional_package: 'Forfait supplémentaire',
  basic_package: 'Forfait',
  commercial_gesture: 'Geste commercial',
  consumable: "Produit d'entretien",
  discounted_package: 'Forfait supplémentaire',
  hermetic_unit: 'Intervention sur unité hermétique',
  legacy_quote: 'Ancien Devis',
  reduction_code: 'Bon de réduction',
  spare_part: 'Pièce Détachée',
  repair_bonus: 'Bonus Réparation',
};
