import React from 'react';

import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

type PaperStripItemProps = {
  labelName: string;
  percentage: string;
  numberOfLabel?: string;
} & MarginProps;

const PaperStripItem: React.FunctionComponent<PaperStripItemProps> = ({
  labelName,
  percentage,
  numberOfLabel,
  ...rest
}: PaperStripItemProps) => {
  return (
    <PaperStrip {...rest} display="flex" justifyContent="space-between">
      <Text variant="body" color="black500">
        {labelName}
      </Text>
      <Box display="flex">
        <Text variant="smallBold" color="green" mr={2}>
          {percentage}
        </Text>
        {numberOfLabel && (
          <Text variant="caption" color="grey700">
            {numberOfLabel}
          </Text>
        )}
      </Box>
    </PaperStrip>
  );
};

export default PaperStripItem;
