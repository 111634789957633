import React from 'react';

import { CircularProgress } from '@material-ui/core';

import Button from 'components/atoms/Button';

import BaseTourSummary from './BaseTourSummary';
import TourStatusReport from './TourStatusReport';

type StartedTourProps = {
  className?: string;
  visitCount: number;
  buttonAction: () => void;
  buttonLabel: 'Prendre une pause' | 'Reprendre ma tournée' | 'Terminer ma journée';
  disabled?: boolean;
  isLoading: boolean;
};

const StartedTour: React.FunctionComponent<StartedTourProps> = ({
  className,
  visitCount,
  buttonAction,
  buttonLabel,
  disabled = false,
  isLoading,
}: StartedTourProps) => {
  return (
    <BaseTourSummary className={className}>
      <TourStatusReport visitCount={visitCount} />
      <Button mt={3} width="100%" onClick={buttonAction} disabled={disabled || isLoading}>
        {isLoading ? <CircularProgress size={20} /> : buttonLabel}
      </Button>
    </BaseTourSummary>
  );
};

export default StartedTour;
