import React from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import dayjs from 'dayjs';
import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import ButtonIcon from 'components/atoms/ButtonIcon';
import Text from 'components/atoms/Text';

import { uppercaseFirstLetter } from 'utils/formatting';

type MonthSelectorProps = {
  selectedDate: string;
  onMonthChange: (index: number) => void;
} & MarginProps;

const ContainerChevron = styled(Box)((props) => ({
  background: 'white',
  borderRadius: '50%',
  width: props.theme.space[4],
  height: props.theme.space[4],
}));

const MonthSelector: React.FunctionComponent<MonthSelectorProps> = ({
  selectedDate,
  onMonthChange,
  ...rest
}) => {
  const getMonthName = () => dayjs(selectedDate).locale('fr').format('MMMM');

  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...rest}>
      <ContainerChevron>
        <ButtonIcon
          handleClick={() => onMonthChange(-1)}
          icon={ChevronLeftIcon}
          display={['block']}
        />
      </ContainerChevron>
      <Text variant="small" mr={3} ml={3} color="black500">
        {uppercaseFirstLetter(getMonthName())}
      </Text>
      <ContainerChevron>
        <ButtonIcon
          handleClick={() => onMonthChange(1)}
          icon={ChevronRightIcon}
          display={['block']}
        />
      </ContainerChevron>
    </Box>
  );
};

export default MonthSelector;
