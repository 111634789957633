//React imports
import React from 'react';

//MUI imports
import { Button, Typography } from '@material-ui/core';
//Style imports
import { makeStyles } from '@material-ui/core/styles';

import { useField, useForm } from 'react-final-form';

//Component imports
import RepairReportAdditionalInformationPicture from 'pages/RepairReport/components/formComponents/RepairReportAdditionalInformationPicture';
import RepairReportTextArea from 'pages/RepairReport/components/formComponents/RepairReportTextArea';
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
import RepairReportAdditionalInformationPictureStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportAdditionalInformationPictureStyle';
import RepairReportButtonStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportButtonStyle';
//helper imports
import { longInterventionReasonsArray } from 'pages/RepairReport/services/stepHelper';
import ReportStepStyle from 'pages/RepairReport/styles/ReportStepStyle';

const useStyles = makeStyles(RepairReportAdditionalInformationPictureStyle);
const useStylesSteps = makeStyles(ReportStepStyle);
const useButtonSteps = makeStyles(RepairReportButtonStyle);

export default function AdditionalInfoStepComponent(props) {
  const classes = useStyles();
  const classesSteps = useStylesSteps();
  const classesButtonSteps = useButtonSteps();

  const form = useForm();
  const formdata = form.getState().values; // accesses directly to the form values
  var shouldBeDisabled = false;
  const interStatusWithoutSP =
    formdata[props.formProductIdReference] &&
    formdata[props.formProductIdReference].intervention_not_done_conclusion_without_sp;
  const interStatusWithSP =
    formdata[props.formProductIdReference] &&
    formdata[props.formProductIdReference].intervention_not_done_conclusion_with_sp;
  const additionalInfos =
    formdata[props.formProductIdReference] && formdata[props.formProductIdReference].infos;
  if (
    formdata[props.formProductIdReference] &&
    formdata[props.formProductIdReference].is_next_operation_long_duration &&
    formdata[props.formProductIdReference].next_operation_explanation === 'other'
  ) {
    if (
      !formdata[props.formProductIdReference].next_operation_explanation_details ||
      formdata[props.formProductIdReference].next_operation_explanation_details.length < 1
    ) {
      shouldBeDisabled = true;
    }
  } else if (
    (interStatusWithoutSP &&
      (interStatusWithoutSP === 'wrong-command' ||
        interStatusWithoutSP === 'intervention-impossible')) ||
    (interStatusWithSP && interStatusWithSP === 'wrong-command')
  ) {
    if (!additionalInfos || additionalInfos.length < 1) {
      shouldBeDisabled = true;
    }
  }

  const {
    input: { onChange: onClickAskLongVisit },
  } = useField(props.formProductIdReference + '.is_next_operation_long_duration');
  const {
    input: { onChange: onClickReasonLongVisit },
  } = useField(props.formProductIdReference + '.next_operation_explanation');
  const onClickButtonLongVisit = (buttonValue) => {
    onClickAskLongVisit(buttonValue);
  };
  const onClickButtonForm = (buttonValue) => {
    onClickReasonLongVisit(buttonValue);
    if (buttonValue !== 'other') {
      props.intermediateValidation();
    }
  };
  return (
    <>
      {formdata[props.formProductIdReference] &&
      !formdata[props.formProductIdReference].is_next_operation_long_duration ? (
        <>
          <Typography className={classesSteps.stepQuestion} variant="h6" component="div">
            Infos complémentaires
          </Typography>
          <RepairReportTextArea
            textAreaLabel={`infos`}
            textPlaceHolder={`Des éléments à ajouter ?`}
            formProductIdReference={props.formProductIdReference}
          />
          <RepairReportAdditionalInformationPicture
            fileName={'information_additionnelle_'}
            fileTag={'attachment'}
            formProductIdReference={props.formProductIdReference}
          />
          {formdata[props.formProductIdReference] &&
            !formdata[props.formProductIdReference].is_appliance_repaired && (
              <Button
                onClick={() => onClickButtonLongVisit(true)}
                id="add-new-spare-part-button"
                className={classes.addNextVisitLongButton}
              >
                <span className={classes.underlinedLabel}>+ Demander un passage long</span>
              </Button>
            )}
          {props.customerFile &&
          props.customerFile.category === 'workshop_repair' &&
          formdata[props.formProductIdReference].is_appliance_repaired !== 'not-processed' ? (
            <RepairReportValidateButton
              buttonLabel={`Continuer`}
              buttonValue="validate"
              validateFunction={props.displayTimeDurationForWorkshop}
              shouldBeDisabled={shouldBeDisabled}
            />
          ) : (
            <>
              {formdata[props.formProductIdReference] &&
                formdata[props.formProductIdReference].is_appliance_repaired ===
                  'not-processed' && (
                  <RepairReportValidateButton
                    buttonLabel={`Continuer`}
                    buttonValue="validate"
                    validateFunction={props.intermediateValidation}
                    shouldBeDisabled={shouldBeDisabled}
                  />
                )}

              {formdata[props.formProductIdReference] &&
                (formdata[props.formProductIdReference].is_appliance_repaired === 'not-repaired' ||
                  formdata[props.formProductIdReference].is_appliance_repaired ===
                    'intervention-impossible' ||
                  !formdata[props.formProductIdReference].is_appliance_repaired ||
                  formdata[props.formProductIdReference].is_appliance_repaired === 'finished') && (
                  <RepairReportValidateButton
                    buttonLabel={`Continuer`}
                    buttonValue="validate"
                    validateFunction={props.intermediateValidation}
                    shouldBeDisabled={shouldBeDisabled}
                  />
                )}
            </>
          )}
        </>
      ) : (
        <>
          {formdata[props.formProductIdReference] &&
          formdata[props.formProductIdReference].next_operation_explanation &&
          formdata[props.formProductIdReference].next_operation_explanation === 'other' ? (
            <>
              <div>
                <Typography className={classesSteps.stepQuestion} variant="h6" component="div">
                  Plus de détails
                </Typography>
                <RepairReportTextArea
                  textAreaLabel={`next_operation_explanation_details`}
                  textPlaceHolder={`Pourquoi avez-vous besoin d'un passage long ?`}
                  formProductIdReference={props.formProductIdReference}
                />
                <RepairReportAdditionalInformationPicture
                  formProductIdReference={props.formProductIdReference}
                  fileName="justification_passage_long_"
                  fileTag="long_intervention_justification"
                />
              </div>
              <RepairReportValidateButton
                buttonLabel={`Continuer`}
                buttonValue="validate"
                validateFunction={props.intermediateValidation}
                shouldBeDisabled={shouldBeDisabled}
              />
            </>
          ) : (
            <div>
              <Typography className={classesSteps.stepQuestion} variant="h6" component="div">
                Pourquoi ?
              </Typography>
              <div className={classes.reasonsContainer}>
                {longInterventionReasonsArray.map((reason, index) => (
                  <Button
                    className={classesButtonSteps.basicButton}
                    variant="contained"
                    onClick={() => onClickButtonForm(reason.id)}
                    key={index}
                  >
                    {reason.name}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
