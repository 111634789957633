import { useEffect, useState } from 'react';

import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-admin';

import dayjs from 'dayjs';

import { CustomerFile } from 'types/customer-files';
import { FaultForecastListItem } from 'types/intervention';
import { Product } from 'types/products';

type FaultForecast = {
  id: number;
  product: Product;
};

interface FaultForecastDetailReturnType {
  prequalification: FaultForecast | undefined;
  customerFile: CustomerFile | undefined;
}
export const useFaultForecastDetail = (
  prequalificationId: string
): FaultForecastDetailReturnType => {
  const [prequalification, setPrequalification] = useState<FaultForecast>();
  const [customerFile, setCustomerFile] = useState<CustomerFile>();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!prequalification) {
      dataProvider
        .getOne<FaultForecast>('fault-forecast-detail', {
          id: prequalificationId,
        })
        .then((response) => {
          setPrequalification(response.data);
        });
    }

    if (prequalification && !customerFile) {
      dataProvider
        .getOne<CustomerFile>('customer-file', {
          id: (prequalification as FaultForecast).product.customer_file as string,
        })
        .then((response) => {
          setCustomerFile(response.data);
        });
    }
  }, [prequalification, customerFile, dataProvider, prequalificationId]);

  return { prequalification, customerFile };
};

export const useFaultForecastList = (
  customHour: number
): {
  prequalifications: FaultForecastListItem[];
  loading: boolean;
} => {
  const { data: prequalificationList, loading } = useQuery({
    type: 'getList',
    resource: 'fault-forecast-inbox',
    payload: {},
  }) as { data: FaultForecastListItem[]; loading: boolean };

  const isPrequalificationPossible = (prequalification: FaultForecastListItem): boolean => {
    // The previous day at 11h00 or whatever hour in the corresponding flag if crPrequal flag is enabled for this repairman
    const limitTimeForPrequalification = dayjs(prequalification.first_visit_date)
      .subtract(1, 'day')
      .hour(customHour || 11)
      .minute(0)
      .second(0)
      .millisecond(0);
    return dayjs() < limitTimeForPrequalification;
  };

  const getPrequalificationList = () => {
    const uniquePrequaliflications: number[] = [];
    if (loading) {
      return [];
    }
    return prequalificationList
      .filter((prequalification) => {
        if (
          isPrequalificationPossible(prequalification) &&
          !uniquePrequaliflications.includes(prequalification.id)
        ) {
          uniquePrequaliflications.push(prequalification.id);
          return true;
        }
      })
      .sort((prequalA, prequalB) => {
        return -dayjs(prequalA.first_visit_date).diff(dayjs(prequalB.first_visit_date));
      });
  };

  return { prequalifications: getPrequalificationList(), loading };
};
