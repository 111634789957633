import React, { useState } from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { filterIrisCodeLists, normalizeString } from 'pages/RepairCycleTasks/RepairCycleUtils';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import CompactRadioField from 'components/molecules/CompactRadioField';
import InputWithIcon from 'components/molecules/InputWithIcon';

import { IrisDefect, IrisSection } from 'types/iris-codes';

type RepairCycleDefectivePartsProps = {
  irisDefects: IrisDefect[];
  irisSections: IrisSection[];
  handleDefectivePartAddition: (defectivePart: DefectivePartFormValue) => void;
  handleClose: () => void;
};

type DefectivePartFormValue = {
  section: string | null;
  defect: string | null;
};

const initialValues: DefectivePartFormValue = {
  section: null,
  defect: null,
};

const StyledInputWithIcon = styled(InputWithIcon)((props) => ({
  outline: 'none',
  border: 'none',
  height: props.theme.sizes[6],
  borderRadius: props.theme.radii[0],
}));

const RepairCycleDefectiveParts: React.FunctionComponent<RepairCycleDefectivePartsProps> = ({
  irisDefects,
  irisSections,
  handleDefectivePartAddition,
  handleClose,
}: RepairCycleDefectivePartsProps) => {
  const [filterDefectValue, setFilterDefectValue] = useState<string>('');
  const [filterSectionValue, setFilterSectionValue] = useState<string>('');

  const [irisDefectsFiltered, setIrisDefectsFiletered] = useState<IrisDefect[]>(irisDefects);
  const [irisSectionsFiltered, setIrisSectionsFiltered] = useState<IrisDefect[]>(irisSections);

  const validationSchema = yup.object().shape({
    section: yup.mixed().required(),
    defect: yup.mixed().required(),
  });

  const setFilter = (values: DefectivePartFormValue, filter: string) => {
    if (values.section) {
      setTimeout(() => {
        setIrisDefectsFiletered(filterIrisCodeLists(irisDefects, normalizeString(filter)));
      }, 500);
      setFilterSectionValue(filter);
    } else {
      setFilterDefectValue(filter);
      setTimeout(() => {
        setIrisSectionsFiltered(filterIrisCodeLists(irisSections, normalizeString(filter)));
      }, 500);
    }
  };

  return (
    <>
      <Box overflow="auto">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleDefectivePartAddition}
        >
          {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
            <Form>
              <Box position="absolute">
                <HeaderWithArrow
                  title={values.section ? 'Défaut' : 'Pièce défectueuse'}
                  action={values.section ? () => setFieldValue('section', null) : handleClose}
                />
                <Box
                  bg="white"
                  borderBottom={'1px solid #DBDFE8'}
                  borderTop={'1px solid #DBDFE8'}
                  height={7}
                >
                  <StyledInputWithIcon
                    placeholder="Rechercher"
                    name={'search'}
                    value={values.section ? filterSectionValue : filterDefectValue}
                    onChange={(event) => {
                      setFilter(values, event.target.value);
                    }}
                    type="string"
                    Icon={SearchIcon}
                  />
                </Box>
              </Box>

              {!values.section && (
                <Box pt={112}>
                  {irisSectionsFiltered?.map((section) => (
                    <CompactRadioField
                      name="section"
                      key={section.code}
                      value={section.id.toString()}
                      label={section.text}
                    />
                  ))}
                </Box>
              )}
              {values.section && (
                <>
                  <Box pt={112} height="calc(100vh - 72px)">
                    {irisDefectsFiltered?.map((defect) => (
                      <CompactRadioField
                        name="defect"
                        key={defect.code}
                        value={defect.id.toString()}
                        label={defect.text}
                      />
                    ))}
                  </Box>
                  <BottomButton disabled={!isValid || !dirty || isSubmitting}>
                    Valider la pièce défectueuse
                  </BottomButton>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default RepairCycleDefectiveParts;
