import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';

import { Timeslot } from 'types/timeslot';

export const dateToHour = (date: Date | string): string => {
  return dayjs(date).format('HH[h]');
};

export const dateToLongHour = (date: Date | string, separator = 'h'): string => {
  return dayjs(date).format(`HH[${separator}]mm`);
};

export const dateToHourOrDate = (date: Date | string): string => {
  dayjs.extend(isToday);
  return dayjs(date).isToday() ? dateToLongHour(date, ':') : dayjs(date).format('DD/MM/YYYY');
};

export const timeslotToHour = (timeslot: Timeslot): string => {
  const beginningTime = dayjs(timeslot.beginning).format('HH:mm');
  const endTime = dayjs(timeslot.end).format('HH:mm');

  return `${beginningTime} - ${endTime}`;
};

export const timeslotToFullDate = (timeslot: Timeslot): string => {
  const beginningDateTime = dayjs(timeslot.beginning).locale('fr').format('dddd D MMMM - HH:mm');
  const endTime = dayjs(timeslot.end).format('HH:mm');

  return `${beginningDateTime} - ${endTime}`;
};

export const countDownInDays = (date: string): number => {
  return dayjs().diff(dayjs(date), 'day');
};

export const compareDate = (current: Date, prev: Date): -1 | 0 | 1 => {
  if (current > prev) return -1;
  if (prev > current) return 1;
  return 0;
};

export const generateTimeslotsList = (
  start: Date | dayjs.Dayjs,
  end: Date | dayjs.Dayjs
): dayjs.Dayjs[] => {
  let currentSlot = dayjs(start);
  const endSlot = dayjs(end);
  if (currentSlot > endSlot) {
    throw new Error('The end should be after the start!');
  }
  const timeslots = [];
  while (currentSlot <= endSlot) {
    timeslots.push(currentSlot);
    currentSlot = currentSlot.add(30, 'minute');
  }
  return timeslots;
};

export const secondsToMinutes = (seconds: number): number => {
  /**
   * Convert a duration in seconds to minutes,
   * rounded to nearest minute
   */
  dayjs.extend(duration);
  const myDuration = dayjs.duration(seconds, 's');
  return Math.round(myDuration.asMinutes());
};

export const dateToDay = (date: Date): string => {
  return dayjs(date).format('DD/MM/YY');
};
