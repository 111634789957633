import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import {
  DisplayProps,
  FlexboxProps,
  JustifyContentProps,
  MarginProps,
  display,
  flexbox,
  justifyContent,
  margin,
} from 'styled-system';

export type PaperStripProps = {
  className?: string;
  disabled?: boolean;
} & MarginProps &
  DisplayProps &
  JustifyContentProps &
  FlexboxProps;

const PaperStrip = styled('div')<PaperStripProps>(
  margin,
  flexbox,
  display,
  justifyContent,
  (props: PaperStripProps) => ({
    width: '100vw',
    padding: themeGet('space.2')(props),
    backgroundColor: 'white',
    borderBottom: `1px solid ${themeGet('colors.grey500_2')(props)}`,
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
  })
);

export default PaperStrip;
