import React, { useState } from 'react';

import DriveEtaIcon from '@material-ui/icons/DriveEta';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';

import PaperStripAction from 'components/PaperStripAction';

import { sendGAEvent } from 'utils/google-analytics';

export interface InterventionNeededProps {
  onSuccess: () => void;
  prequalificationId: number;
}

const InterventionNeeded: React.FunctionComponent<InterventionNeededProps> = ({
  prequalificationId,
  onSuccess,
}: InterventionNeededProps) => {
  const [disabled, setDisabled] = useState(false);
  const { employee } = useEmployee();

  const SkipPrequalification = () => {
    // trigger rerender immediatly ?
    if (disabled === true) {
      return;
    }
    setDisabled(true);
    sendGAEvent('skip-prequal', 'prequal', new Date().toDateString(), `${employee.user?.username}`);
    return dataProvider
      .create(
        'intervention-skipped-report',
        {
          data: {
            intervention: prequalificationId,
            justification: 'IMPOSSIBLE',
          },
        },
        'json'
      )
      .then(() => {
        setDisabled(false);
        onSuccess();
      });
  };

  return (
    <PaperStripAction
      Icon={DriveEtaIcon}
      text="Besoin de Diag. sur place"
      callback={SkipPrequalification}
    />
  );
};

export default InterventionNeeded;
