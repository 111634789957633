import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledTypography = withStyles({
  root: {
    '& span': {
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '140%',
    },
  },
})(Typography);
