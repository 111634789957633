import React from 'react';

import Tag, { TagVariants } from 'components/atoms/Tag/Tag';

export interface CountDownProps {
  value: number;
}

const CountDown: React.FunctionComponent<CountDownProps> = ({ value }: CountDownProps) => {
  const getVariant = (): keyof typeof TagVariants => {
    switch (value) {
      case -1:
        return 'ternary';
      case -2:
      case -3:
      case -4:
        return 'problem';
      default:
        return 'secondary';
    }
  };

  return <Tag variant={getVariant()} text={`J${value}`} />;
};

export default CountDown;
