import React from 'react';

import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import PageLayout from 'components/templates/PageLayout';

import { useProduct } from 'utils/api/api';

import SellingPicturesUpdate from './SellingPicturesUpdate';

type SellingPicturesUpdateContainerProps = {
  productId: number;
  handleClose: () => void;
};

const SellingPicturesUpdateContainer: React.FunctionComponent<
  SellingPicturesUpdateContainerProps
> = ({ productId, handleClose }: SellingPicturesUpdateContainerProps) => {
  const { product } = useProduct(productId);

  return (
    <PageLayout>
      <Box overflow="auto">
        <HeaderWithArrow title="Photos" action={handleClose} />
        {product && <SellingPicturesUpdate product={product} handleClose={handleClose} />}
      </Box>
    </PageLayout>
  );
};

export default SellingPicturesUpdateContainer;
