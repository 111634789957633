// react imports
import React from 'react';

// mui imports
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// style imports
import { makeStyles } from '@material-ui/core/styles';

import RepairReportButtonStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportButtonStyle';

const useStyles = makeStyles(RepairReportButtonStyle);

type RepairReportSubmitButtonProps = {
  buttonLabel: string;
  isLoading: boolean;
  handleSubmit: () => void;
  formUploadProgress?: number;
  disabled: boolean;
};

const RepairReportSubmitButton: React.FunctionComponent<RepairReportSubmitButtonProps> = ({
  buttonLabel,
  handleSubmit,
  isLoading,
  formUploadProgress,
  disabled,
}: RepairReportSubmitButtonProps) => {
  const classes = useStyles();
  const buttonClass = classes.paymentButton;

  const renderButtonContent = () => {
    if (isLoading) {
      return formUploadProgress ? (
        <CircularProgress variant="static" value={formUploadProgress} />
      ) : (
        <span>
          <CircularProgress />
        </span>
      );
    }
    return <span id="validation-button-label">{buttonLabel}</span>;
  };

  return (
    <Button
      id="submit-button"
      className={buttonClass}
      variant="contained"
      onClick={handleSubmit}
      disabled={disabled || isLoading}
    >
      {renderButtonContent()}
    </Button>
  );
};

export default RepairReportSubmitButton;
