import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-admin';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';
import styled from 'styled-components';

import ProductInventory from 'pages/ProductInventory/ProductInventory';
import SelectProductLocation from 'pages/Store/components/SelectProductLocation';

import OpenScanButton from 'components/OpenScanButton';
import PageHeader from 'components/PageHeader';
import WorkshopBottomBar from 'components/WorkshopBottomBar/WorkshopBottomBar';

import { Location } from 'types/location';
import { Product } from 'types/products';

const Container = styled.div(() => ({
  height: '100vh',
}));

const SubContainer = styled.div(() => ({
  height: '100%',
}));

const WorkshopInventory: React.FunctionComponent = () => {
  const [isSelectProductLocationOpen, setIsSelectProductLocationOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<Location>();
  const [productList, setProductList] = useState<Product[]>([] as Product[]);
  const { employee } = useEmployee();
  const closeSelectProductLocation = () => {
    setIsSelectProductLocationOpen(false);
  };

  const { data: locations } = useQuery({
    type: 'getList',
    resource: 'location',
    payload: {
      sort: { field: 'id', order: 'DESC' },
      filter: { workshop: employee.workshop },
    },
  });

  useEffect(() => {
    if (location !== undefined) {
      dataProvider
        .getList('products', {
          filter: {
            location_id: location.id,
          },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data }) => {
          setProductList(data as Product[]);
        });
    }
  }, [location]);

  const getLocationFromId = (id: number | unknown) => {
    return locations.find((el: Location) => el.id === id);
  };
  const handleChangeLocation = (id: number | unknown) => {
    const location = getLocationFromId(id);
    setLocation(location);
    setIsSelectProductLocationOpen(false);
  };

  return (
    <>
      <Container>
        <SubContainer>
          <PageHeader title="Inventaire" />
          <OpenScanButton
            text={'Scanner un emplacement'}
            handleOpen={() => {
              setIsSelectProductLocationOpen(true);
            }}
          />
        </SubContainer>
        <WorkshopBottomBar />
      </Container>
      {isSelectProductLocationOpen && (
        <SelectProductLocation
          locations={locations}
          open={isSelectProductLocationOpen}
          handleClose={closeSelectProductLocation}
          handleChangeLocation={handleChangeLocation}
        />
      )}
      {location && (
        <ProductInventory
          productList={productList}
          open={true}
          onClose={() => setLocation(undefined)}
          location={location}
          redirectUrl={'/workshop/inventory'}
          ConfirmationButtonText={"Terminer l'inventaire"}
        />
      )}
    </>
  );
};

export default WorkshopInventory;
