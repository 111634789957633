import React from 'react';

import { BorderProps, MarginProps, PositionProps, WidthProps } from 'styled-system';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import { TextVariants } from 'components/atoms/Text/Text';

type FloatingTextProps = {
  text: string;
  color?: string;
  background?: string;
  variant?: keyof typeof TextVariants;
} & MarginProps &
  PositionProps &
  BorderProps &
  WidthProps;

const FloatingText: React.FunctionComponent<FloatingTextProps> = ({
  text,
  color = 'white',
  background = 'red',
  variant = 'body',
  position = 'absolute',
  bottom = '64px',
  right,
  width = '100%',
  borderRadius = '8px',
  ...rest
}: FloatingTextProps) => {
  return (
    <Box
      backgroundColor={background}
      borderRadius={borderRadius}
      position={position}
      bottom={bottom}
      width={width}
      right={right}
    >
      <Text p={1} variant={variant} display="flex" color={color} {...rest}>
        {text}
      </Text>
    </Box>
  );
};

export default FloatingText;
