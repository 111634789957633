import React from 'react';

import { CircularProgress } from '@material-ui/core';

import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import PageLayout from 'components/templates/PageLayout';

import { ProductCheckListElement } from 'types/product-check-list-element';
import { Product } from 'types/products';

import { OVERALL_CONDITION, SECTION_PRESENCE } from '../RepairCycleUtils';
import { useProductChecklist } from '../hooks';
import RepairReportForm from './RepairReportForm/RepairReportForm';

type RepairCycleRepairReportProps = {
  product: Product;
  handleClose: () => void;
};

const RepairCycleRepairReport: React.FunctionComponent<RepairCycleRepairReportProps> = ({
  product,
  handleClose,
}: RepairCycleRepairReportProps) => {
  const { productCheckList: sectionPresenceChecks, isLoading: isSectionsPresenceChecksLoading } =
    useProductChecklist(product.product_type, SECTION_PRESENCE);
  const {
    productCheckList: overallConditionChecks,
    isLoading: isOverallConditionChecklistLoading,
  } = useProductChecklist(product.product_type, OVERALL_CONDITION);

  const initialValues = {
    repair_outcome: null,
    section_presence: sectionPresenceChecks.map((check: ProductCheckListElement) => {
      return {
        id: check.id,
        text: check.display_text,
        is_checked: false,
      };
    }),
    overall_condition: overallConditionChecks.map((check: ProductCheckListElement) => {
      return {
        id: check.id,
        text: check.display_text,
        is_checked: false,
      };
    }),
  };

  return (
    <PageLayout>
      {isSectionsPresenceChecksLoading && isOverallConditionChecklistLoading && (
        <>
          <HeaderWithArrow title={`Chargement des vérifications`} action={handleClose} />
          <Box margin="auto">
            <CircularProgress />
          </Box>
        </>
      )}
      {!isSectionsPresenceChecksLoading && !isOverallConditionChecklistLoading && (
        <RepairReportForm
          handleClose={handleClose}
          product={product}
          initialValues={initialValues}
        />
      )}
    </PageLayout>
  );
};

export default RepairCycleRepairReport;
