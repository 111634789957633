import React, { useState } from 'react';

import { getProductUsingPayload } from 'pages/RepairCycleTasks/api';
import ProductDetailField from 'pages/Store/components/ProductDetailField';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ProductScanner from 'components/organisms/ProductScanner';

import { Product } from 'types/products';

import { isItemInList } from 'utils/list';

export type ProductListSelectionPortalProps = {
  productList: Product[];
  selectedList: Product[];
  surplusProductList?: Product[];
  onProductSelection: (product: Product) => void;
};

const ProductListSelectionPortal: React.FunctionComponent<ProductListSelectionPortalProps> = ({
  productList,
  selectedList,
  onProductSelection,
  surplusProductList,
}: ProductListSelectionPortalProps) => {
  const [error, setError] = useState<string>();
  const handleConfirmBarcode = async (code: string) => {
    setError(undefined);
    try {
      const products = await getProductUsingPayload({ barcode: code });
      if (products.length > 0) {
        onProductSelection(products[0]);
      } else {
        setError(`Machine inconnue (${code})`);
      }
    } catch (e: any) {
      setError(e.message);
    }
  };
  return (
    <>
      <ProductScanner
        handleConfirmBarcode={handleConfirmBarcode}
        error={error}
        buttonText="Confirmer"
      />
      {productList.length > 0 && (
        <Box m={2} textAlign={'initial'}>
          <Text variant="body">{productList.length} appareils identifié(s)</Text>
        </Box>
      )}
      <Box textAlign={'initial'}>
        {productList.map((product) => (
          <ProductDetailField
            data-testid={product.id}
            key={product.id}
            product={product as Product}
            checked={isItemInList(product, selectedList)}
            removeable={false}
          />
        ))}
        {surplusProductList?.map((product) => (
          <ProductDetailField
            data-testid={product.id}
            key={product.id}
            product={product as Product}
            removeable={false}
          />
        ))}
      </Box>
    </>
  );
};

export default ProductListSelectionPortal;
