import React from 'react';

import styled from 'styled-components';
import {
  ColorProps,
  FlexboxProps,
  LayoutProps,
  WidthProps,
  color,
  flexbox,
  layout,
  width,
} from 'styled-system';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import { TextVariants } from 'components/atoms/Text/Text';

export type TabProps = {
  title: string;
  cardinality: number;
  active: boolean;
  handleClick: () => void;
  Icon?: React.FunctionComponent;

  variant?: keyof typeof TextVariants;
} & React.HTMLAttributes<HTMLDivElement> &
  ColorProps &
  FlexboxProps &
  LayoutProps &
  WidthProps;

const TabContainer = styled('div')<
  ColorProps & FlexboxProps & LayoutProps & WidthProps & { active: boolean }
>(color, flexbox, layout, width, (props) => ({
  borderBottomStyle: 'solid',
  borderBottomWidth: '2px',
  borderColor: props.active ? props.theme.colors.green : 'transparent',
  alignItems: 'center',
  paddingBlock: props.theme.space[2],
  alignSelf: 'center',
}));

TabContainer.defaultProps = {
  backgroundColor: 'white',
};

const Tab: React.FunctionComponent<TabProps> = ({
  title,
  cardinality,
  handleClick,
  active,
  Icon,
  variant = 'small',
  ...rest
}: TabProps) => {
  return (
    <TabContainer {...rest} active={active} onClick={handleClick}>
      <Box alignItems="center" display={'flex'} justifyContent={Icon ? 'space-evenly' : 'center'}>
        <Text color="black500" textAlign="center" variant={variant}>
          {title}
        </Text>
        {Icon && <Icon />}
      </Box>
      {!!cardinality && (
        <Text
          text-align="center"
          paddingX="8px"
          borderRadius="5px"
          backgroundColor={'turquoise'}
          variant="extraSmall"
          color="white"
        >
          {cardinality}
        </Text>
      )}
    </TabContainer>
  );
};

export default Tab;
