import React from 'react';

import Text from 'components/atoms/Text';
import ButtonBlock from 'components/molecules/ButtonBlock';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { ProductType } from 'types/productTypes';

import RadioField from '../RadioField';

type ProductAdditionProps = {
  productTypes: ProductType[];
  disabled: boolean;
  validateType: () => void;
};
const ProductAddition: React.FunctionComponent<ProductAdditionProps> = ({
  disabled,
  productTypes,
  validateType,
}: ProductAdditionProps) => {
  return (
    <PageLayout>
      <GoBackHeader title="Type d'appareil" />
      <Text variant="bodyBold" ml={3} mt={1}>
        Choisissez le produit
      </Text>
      <Text variant="small" mx={2} mt={1}>
        Information pour le client : le prix du forfait supplémentaire dépend de la catégorie de
        l&apos;appareil
      </Text>
      <ContentContainer>
        {productTypes.map((productType) => (
          <RadioField
            name="productType"
            key={productType.id}
            value={productType.id.toString()}
            label={productType.name}
            mt={1}
          />
        ))}
      </ContentContainer>
      <ButtonBlock disabled={disabled} onClick={validateType}>
        Voir le prix du forfait
      </ButtonBlock>
    </PageLayout>
  );
};

export default ProductAddition;
