// React imports
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ProductDetailField from 'pages/Store/components/ProductDetailField';
// Style imports
import TransferProductAdditionStyle from 'pages/Transfer/styles/TransferProductAdditionStyle';

const useCreationStyles = makeStyles(TransferProductAdditionStyle);

export default function ProductList(props) {
  const { products, removeProduct } = props;
  const creationClasses = useCreationStyles();

  return (
    <div className={creationClasses.ProductListContainer}>
      {products &&
        products.length > 0 &&
        products
          .filter((product) => product !== undefined)
          .map((product, index) => (
            <ProductDetailField
              key={index}
              product={product}
              removeable
              onRemove={() => removeProduct(product)}
              showLocation
            />
          ))}
    </div>
  );
}
