import React from 'react';

import { SECTION_WEAR } from 'pages/RepairCycleTasks/RepairCycleUtils';

import ProductCheckListReportBlock from 'components/RepairCycleBlocks/ProductCheckListReportBlock';

import { ProductCheckListReport } from 'types/product-history';

type RepairCycleCheckListProps = {
  productCheckListReport: ProductCheckListReport;
};
const CheckListBlock: React.FunctionComponent<RepairCycleCheckListProps> = ({
  productCheckListReport,
}: RepairCycleCheckListProps) => {
  switch (productCheckListReport.checklist) {
    case SECTION_WEAR:
      return <ProductCheckListReportBlock productCheckListReport={productCheckListReport} />;
    default:
      // Unsupported checklist type
      return <></>;
  }
};

export default CheckListBlock;
