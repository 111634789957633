import React from 'react';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';

type IndividualPictureDisplayUpdateProps = {
  photo: {
    index: number;
    id: number | undefined;
    url: string | undefined;
    newPicture: File | undefined;
    state: string | undefined;
  };
  updatePicture: (fileList: FileList | null, index: number) => void;
  key: number;
};

const StyledImage = styled(Image)`
  width: 100%;
  border-radius: 0px;
`;

const IndividualPictureDisplayUpdate: React.FunctionComponent<
  IndividualPictureDisplayUpdateProps
> = ({ photo, updatePicture, key }: IndividualPictureDisplayUpdateProps) => {
  const renderImage = (photoUrl: string) => {
    return (
      <Box overflow="auto" maxHeight={415} position="relative" margin="auto" maxWidth={328}>
        <StyledImage src={photoUrl} alt="preview" />
      </Box>
    );
  };
  return (
    <Box key={key} overflow="auto" mb={140} position="relative">
      <Text marginBottom={2} variant="body">
        Photo {photo.index + 1}
      </Text>
      <Box width="100%">
        <Button padding="none" variant="transparent" my={2} width="90%" type="button">
          <label htmlFor="photo-upload">
            <Box px={4} py={10}>
              Modifier la photo
            </Box>
          </label>
        </Button>
      </Box>
      {photo.newPicture && <Image src={URL.createObjectURL(photo.newPicture)} alt="preview" />}
      {photo.url && !photo.newPicture && <>{renderImage(photo.url)} </>}
      {!photo.url && !photo.newPicture && (
        <Box minHeight={328} maxHeight="100%">
          {' '}
        </Box>
      )}
      <Box textAlign="center">
        <input
          id="photo-upload"
          data-testid="nameplate-input"
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
            updatePicture(event.currentTarget.files, photo.index);
          }}
        />
      </Box>
    </Box>
  );
};

export default IndividualPictureDisplayUpdate;
