import React, { useState } from 'react';

import { Close } from '@material-ui/icons';

import animationData from 'assets/scan.json';
import Lottie from 'react-lottie';

import PortalComponent from 'components/Portal/PortalComponent';
import AutocompleteInput from 'components/atoms/AutocompleteInput/AutocompleteInput';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import QrScanner from 'utils/QrScanner';

type PackageScannerProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmCallback: (code: string) => void;
  scannerTitle: string;
  scannerExplanations: string;
  options: Array<{ value: string; label: string }>;
  selectedValue: { label: string; value: string } | undefined;
  loadOptionList: () => void;
  handleManualInput: (scannedCode: { value: number; label: string }) => void;
  error?: string;
};

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const PackageScanner: React.FunctionComponent<PackageScannerProps> = ({
  open,
  onClose,
  handleConfirmCallback,
  scannerTitle,
  scannerExplanations,
  options,
  selectedValue,
  loadOptionList,
  handleManualInput,
  error,
}: PackageScannerProps) => {
  const [manualInput, setManualInput] = useState(false);

  return (
    <PortalComponent open={open} onClose={onClose}>
      <Box overflow={'auto'}>
        <Box textAlign={'end'} m={2}>
          <Close onClick={onClose} />
        </Box>

        {manualInput && (
          <Box margin={1}>
            <Text variant="body" mb={2}>
              Sélection manuelle
            </Text>
            <AutocompleteInput
              options={options}
              onChange={(pckg: any) => handleManualInput(pckg)}
              name={''}
              value={selectedValue as any}
              isClearable={false}
              isCreatableEnabled={false}
              placeholder={'Sélection manuelle'}
            />
          </Box>
        )}
        {!manualInput && (
          <Box>
            <Text variant="body">{scannerTitle}</Text>
            <QrScanner handleConfirmBarcode={handleConfirmCallback} />
            <Text variant="smallBold" color="red">
              {error}
            </Text>

            <Lottie
              options={animationOptions}
              height={100}
              width={100}
              isClickToPauseDisabled={true}
            />
            <Text variant="body">{scannerExplanations}</Text>
            <Button
              variant="fullTransparent"
              onClick={() => {
                setManualInput(true);
                loadOptionList();
              }}
            >
              Identifier manuellement
            </Button>
          </Box>
        )}
      </Box>
    </PortalComponent>
  );
};

export default PackageScanner;
