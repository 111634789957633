import React from 'react';

import dayjs from 'dayjs';

import DeliveryItemField from 'components/DeliveryItemField/DeliveryItemField';
import Box from 'components/atoms/Box';

import { DeliveryItem } from 'types/delivery-items';

import { useDeliveryItems } from 'utils/api/api';

const COLLECTION = 'collection';

export type DeliveryItemListProps = {
  onClick: (deliveryItem: DeliveryItem) => void;
  searchValue: string;
};

const DeliveryItemList: React.FunctionComponent<DeliveryItemListProps> = ({
  onClick,
  searchValue,
}: DeliveryItemListProps) => {
  const { deliveryItems } = useDeliveryItems({
    date_range_before: !searchValue ? dayjs().format('YYYY-MM-DD') : null,
    date_range_after: !searchValue ? dayjs().add(-3, 'day').format('YYYY-MM-DD') : null,
    delivery_type: COLLECTION,
    q: searchValue,
  });

  return (
    <Box mt={3} height="calc(100vh - 172px)" overflow="scroll">
      {deliveryItems?.map((deliveryItem: DeliveryItem, index) =>
        searchValue ? (
          <DeliveryItemField
            data-testid={index}
            key={index}
            deliveryItem={deliveryItem as DeliveryItem}
            onclick={() => onClick(deliveryItem)}
            couldBeChecked
          />
        ) : (
          !deliveryItem?.product?.workshop_file && (
            <DeliveryItemField
              data-testid={index}
              key={index}
              deliveryItem={deliveryItem as DeliveryItem}
              onclick={() => onClick(deliveryItem)}
            />
          )
        )
      )}
    </Box>
  );
};

export default DeliveryItemList;
