import React from 'react';

import { IconProps } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UndoIcon from '@material-ui/icons/Undo';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import FloatingActionButton from 'pages/Store/components/FloatingActionButton';

import { FlatCardList } from 'components/FlatCard';
import PortalComponent from 'components/Portal/PortalComponent';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import MinimalSparePartDetail from 'components/molecules/MinimalSparePartDetail';

import { SparePart } from 'types/spare-part';

import { useSpareParts } from 'utils/api/api';

type SparePartPackageListProps = {
  open: boolean;
  onClose: () => void;
  onCloseCheck: () => void;
  packageId: number | null;
  selectedIds: number[];
  handleOpenScanModal: () => void;
  lostSparePartList: SparePart[];
  surplusSparePartList: SparePart[];
  handleSavePackage: (selectedIds: number[], spareParts: SparePart[]) => void;
};

const SparePartPackageList: React.FunctionComponent<SparePartPackageListProps> = ({
  open,
  onClose,
  onCloseCheck,
  packageId,
  selectedIds,
  handleOpenScanModal,
  lostSparePartList,
  surplusSparePartList,
  handleSavePackage,
}: SparePartPackageListProps) => {
  const { spareParts } = useSpareParts({ package_id: packageId });

  const handleValidate = () => {
    handleSavePackage(selectedIds, spareParts);
  };

  const StyledCancelIcon = styled(CancelIcon)<IconProps>((props: IconProps) => ({
    color: themeGet('colors.red')(props),
    width: themeGet('space.3')(props),
    height: themeGet('space.3')(props),
  }));

  const StyledCheckedIcon = styled(CheckCircleIcon)<IconProps>((props: IconProps) => ({
    color: themeGet('colors.green200')(props),
    width: themeGet('space.3')(props),
    height: themeGet('space.3')(props),
  }));

  const StyledUndoIcon = styled(UndoIcon)<IconProps>((props: IconProps) => ({
    color: themeGet('colors.honey')(props),
    width: themeGet('space.3')(props),
    height: themeGet('space.3')(props),
  }));

  return (
    <PortalComponent open={open} onClose={onClose}>
      <HeaderWithArrow action={onCloseCheck} title="Nouveau colis"></HeaderWithArrow>

      <Box overflow="scroll" mx={2} mt={1} height="calc(100vh - 124px)" pb={10}>
        <FlatCardList>
          {spareParts
            ?.filter((sparePart: SparePart) => selectedIds.includes(sparePart.id))
            .map((selectedSparePart: SparePart) => {
              return (
                <MinimalSparePartDetail
                  key={selectedSparePart.id}
                  icon={<StyledCheckedIcon />}
                  sparePart={selectedSparePart}
                />
              );
            })}
          {surplusSparePartList.map((sparePart: SparePart) => {
            return (
              <MinimalSparePartDetail
                key={sparePart.id}
                icon={<StyledUndoIcon />}
                sparePart={sparePart}
              />
            );
          })}
          {lostSparePartList.map((sparePart: SparePart) => {
            return (
              <MinimalSparePartDetail
                key={sparePart.id}
                icon={<StyledCancelIcon />}
                sparePart={sparePart}
              />
            );
          })}
        </FlatCardList>
      </Box>

      {selectedIds.length > 0 && (
        <BottomButton variant="primary" onClick={handleValidate} minWidth={192}>
          Enregistrer les pièces
        </BottomButton>
      )}

      <FloatingActionButton
        onClick={handleOpenScanModal}
        withoutBottomBar={selectedIds.length > 0}
        color="secondary"
        icon={<CenterFocusStrongIcon />}
      />
    </PortalComponent>
  );
};

export default SparePartPackageList;
