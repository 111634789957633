import React from 'react';

import { useQuery } from 'react-admin';

import { useEmployee } from 'employeeContext';
import { Redirect } from 'react-router-dom';

import {
  CLEANING,
  OPERATOR,
  PERFORMANCE,
  REPAIR,
  REPAIRMAN,
  STORE,
  TRANSFER,
} from 'pages/Home/constants';

import Loader from 'components/Loader';

import { Task } from 'types/tasks';

const HomeScreen: React.FunctionComponent = () => {
  const { employee } = useEmployee();

  const { data: taskList, loading }: { data?: Task[]; loading: boolean } = useQuery({
    type: 'getList',
    resource: 'task-list',
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
      filter: '',
    },
  });

  const getInitiateHomeScreen = () => {
    // taskList is defined here as this method is called after loading
    // we can assert its type confidently

    const associatedTasks = (taskList as Task[])
      .filter((task) => employee.associated_tasks.includes(task.id))
      .map((task) => task.name);
    if (employee.role === OPERATOR) {
      return 'workshop/repair-cycle/';
    } else if (employee.role === REPAIRMAN) {
      return `incentives/${employee.id}`;
    } else if (associatedTasks.length === 1) {
      if (associatedTasks[0] === PERFORMANCE) return `incentives/${employee.id}`;
      else if (associatedTasks[0] === REPAIR) return 'calendar';
      else if (associatedTasks.includes(TRANSFER)) return 'workshop';
      else return associatedTasks[0];
    } else {
      if (associatedTasks.includes(PERFORMANCE)) return `incentives/${employee.id}`;
      else if (associatedTasks.includes(REPAIR)) return 'calendar';
      else if (associatedTasks.includes(TRANSFER)) return 'workshop';
      else if (associatedTasks.includes(CLEANING)) return 'cleaning';
      else if (associatedTasks.includes(STORE)) return 'store';
      else return 'no-context';
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return <Redirect to={`/${getInitiateHomeScreen()}`} />;
  }
};

export default HomeScreen;
