import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';

import { useProduct } from 'utils/api/api';

type AppliancePhotoBlockProps = {
  productId: number;
  updatePicture: (fileList: FileList | null) => void;
  error: string | null;
};

const StyledImage = styled(Image)`
  width: 100%;
  border-radius: 0px;
  object-fit: contain;
`;

const ImageContainer = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const AppliancePhotoBlock: React.FunctionComponent<AppliancePhotoBlockProps> = ({
  productId,
  updatePicture,
  error,
}: AppliancePhotoBlockProps) => {
  const { product, loadingProduct } = useProduct(productId);
  const [isNameplateOpen, setIsNameplateOpen] = useState(false);

  const renderImage = (photoUrl: string) => {
    return (
      <>
        <Box
          overflow="auto"
          onClick={() => setIsNameplateOpen(true)}
          height={200}
          position="relative"
        >
          <StyledImage src={photoUrl} alt="preview" />
        </Box>
        <PortalComponent
          title="Plaque signalétique"
          open={isNameplateOpen}
          onClose={() => setIsNameplateOpen(false)}
        >
          <ImageContainer data-testid="applianceNameplate" src={photoUrl} />
        </PortalComponent>
      </>
    );
  };

  return !loadingProduct ? (
    <Box bg="white">
      {product?.identification_plate && <>{renderImage(product.identification_plate)} </>}
      <Box textAlign="center">
        <input
          id="file-upload"
          data-testid="nameplate-input"
          type="file"
          style={{ display: 'none' }}
          onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
            updatePicture(event.currentTarget.files);
          }}
        />
        {!product || error ? (
          <Text mt={2} variant="smallBold" color="pink700" pl={2}>
            {`Plaque signalétique non mise à jour:  ${error}`}
          </Text>
        ) : (
          <></>
        )}
        <Button variant="transparent" my={2} width="90%" type="button">
          <label htmlFor="file-upload">Modifier la plaque signalétique</label>
        </Button>
      </Box>
    </Box>
  ) : (
    <Box bg="white">
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default AppliancePhotoBlock;
