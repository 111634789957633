// States
import { assign, createMachine } from 'xstate';

export const PAYMENT_INPUT = 'payment_input';
export const PAYMENT_SUCCESS = 'payment_success';
export const PAYMENT_FAILURE = 'payment_failure';
export const PAYMENT_PROOF = 'payment_proof';
export const FINAL = 'final';

// Events

export const PAYMENT = 'payment';
export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const RETRY = 'retry';
export const PROOF = 'proof';

type EventType = typeof PAYMENT | typeof RETRY;

interface BaseEvent {
  type: EventType;
}

interface UpdatePaymentEvent {
  type: typeof PROOF | typeof SUCCESS | typeof FAILURE;
  data: {
    payment_amount: number;
    payment_method: string;
  };
}

type Event = BaseEvent | UpdatePaymentEvent;

interface PaymentContext {
  payment_amount: number | null;
  payment_method: string;
}

const updateContext = (context: PaymentContext, event: Event) => {
  switch (event.type) {
    case PROOF:
    case SUCCESS:
    case FAILURE: {
      return {
        ...context,
        payment_amount: event.data.payment_amount,
        payment_method: event.data.payment_method,
      };
    }
    default:
      return context;
  }
};

export const paymentMachine = createMachine<PaymentContext, Event>(
  {
    id: 'payment-machine',
    initial: PAYMENT_INPUT,
    context: {
      payment_amount: null,
      payment_method: '',
    },
    states: {
      [PAYMENT_INPUT]: {
        on: {
          [SUCCESS]: PAYMENT_SUCCESS,
          [FAILURE]: PAYMENT_FAILURE,
          [PROOF]: PAYMENT_PROOF,
        },
        exit: ['updateContext'],
      },
      [PAYMENT_PROOF]: {
        on: {
          [SUCCESS]: PAYMENT_SUCCESS,
          [FAILURE]: PAYMENT_FAILURE,
        },
      },
      [PAYMENT_FAILURE]: {
        on: {
          [RETRY]: PAYMENT_INPUT,
        },
      },
      [PAYMENT_SUCCESS]: {
        type: FINAL,
      },
    },
  },
  {
    actions: {
      updateContext: assign(updateContext),
    },
  }
);

// Link to Stately : https://stately.ai/viz/cf6dbe1a-1abd-4ae1-bd84-ba691a186cb2
