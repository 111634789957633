import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import dataProvider from 'dataProvider';
import dayjs from 'dayjs';

import { EmployeeStatus } from 'types/employee-status';
import { TourStatus } from 'types/tour-status';

type TourStatusContextType = {
  tourStatus: TourStatus | null;
  setRepairmanTourStatus: (nextTourStatus: TourStatus) => void;
  isLoading: boolean;
};

type TourStatusProviderProps = {
  children: React.ReactNode;
};
const TourStatusContext = React.createContext<TourStatusContextType>({} as TourStatusContextType);
export const TourStatusProvider: React.FunctionComponent<TourStatusProviderProps> = ({
  children,
}: TourStatusProviderProps) => {
  const [tourStatus, setTourStatus] = useState<TourStatus | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // prevent setting state in callback
  // on unmounted component
  const isMounted = useRef(true);
  const getTourStatus = useCallback((date: string) => {
    handleEmployeeStatusResponse(
      dataProvider.getOne('employee-status', { employee_log_date: date })
    );
  }, []);

  useEffect(() => {
    getTourStatus(dayjs().format('YYYY-MM-DD'));
    return () => {
      isMounted.current = false;
    };
  }, [getTourStatus]);

  const handleEmployeeStatusResponse = (apiResponse: Promise<{ data: EmployeeStatus }>) => {
    setIsLoading(true);
    apiResponse
      .then(({ data }: { data: EmployeeStatus }) => {
        if (isMounted.current) {
          setTourStatus(data.tour_status);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setRepairmanTourStatus = (nextTourStatus: TourStatus) => {
    handleEmployeeStatusResponse(
      dataProvider.update('employee-status', {
        employee_log_date: dayjs().format('YYYY-MM-DD'),
        data: { tour_status: nextTourStatus },
      })
    );
  };
  return (
    <TourStatusContext.Provider value={{ tourStatus, setRepairmanTourStatus, isLoading }}>
      {children}
    </TourStatusContext.Provider>
  );
};

export const useTourStatus: () => TourStatusContextType = () => useContext(TourStatusContext);
