import React, { useState } from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import PaperStrip from 'components/atoms/PaperStrip';
import Text, { TextVariants } from 'components/atoms/Text';

type ContentProps = {
  open: boolean;
};

type PaperStripUnfoldsWithArrowProps = {
  content: React.ReactNode | React.ReactNode[];
  title: string;
  contentStyle?: keyof typeof TextVariants;
} & MarginProps;

const ContentContainer = styled.div<ContentProps>`
  overflow: hidden;
  display: ${(props) => (props.open ? 'none' : 'block')};
  margin-top: ${(props) => `${props.theme.space[2]}px`};
`;

const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaperStripUnfoldsWithArrow: React.FunctionComponent<PaperStripUnfoldsWithArrowProps> = ({
  content,
  title,
  contentStyle = 'body',
  ...rest
}: PaperStripUnfoldsWithArrowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderContent = () => {
    return Array.isArray(content) ? (
      content.map((text, index) => (
        <Text variant={contentStyle} key={index}>
          {text}
        </Text>
      ))
    ) : (
      <Text variant={contentStyle}>{content}</Text>
    );
  };

  return (
    <PaperStrip onClick={toggleOpen} {...rest}>
      <ContainerTitle>
        <Text variant="bodyBold">{title}</Text>
        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ContainerTitle>
      <ContentContainer open={isOpen}>{renderContent()}</ContentContainer>
    </PaperStrip>
  );
};

export default PaperStripUnfoldsWithArrow;
