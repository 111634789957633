import React from 'react';

// MUI imports
import RepairReportButton from 'pages/RepairReport/components/formComponents/RepairReportButton';
import { ReasonListType, StepId } from 'pages/RepairReport/services/stepHelper';

type RepairReportExplanationComponentProps = {
  slug: StepId;
  validateFunction: (p?: string | boolean) => void;
  formProductIdReference: string;
  reasonArray: ReasonListType;
};

const RepairReportExplanationComponent: React.FC<RepairReportExplanationComponentProps> = ({
  slug,
  validateFunction,
  formProductIdReference,
  reasonArray,
}) => {
  return (
    <>
      {reasonArray.map((reason) => (
        <RepairReportButton
          key={reason.id}
          stepQuestionId={slug}
          buttonLabel={reason.name}
          buttonValue={reason.id}
          validateFunction={validateFunction}
          formProductIdReference={formProductIdReference}
        />
      ))}
    </>
  );
};

export default RepairReportExplanationComponent;
