import React from 'react';

import InfoIcon from '@material-ui/icons/Info';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Carousel from 'components/Carousel';
import FlatCard from 'components/FlatCard';
import IconText from 'components/IconText';
import SkuDetailCard from 'components/SkuDetailCard';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { SparePart } from 'types/spare-part';

type IconProps = {
  className?: string;
};

const StyledInfoIcon = styled(InfoIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.honey')(props),
  marginRight: '8px',
  verticalAlign: 'sub',
}));

type SkuDetailCarouselProps = {
  locationCodes: string[];
  sku: SparePart;
};

const SkuDetailCarousel: React.FunctionComponent<SkuDetailCarouselProps> = ({
  locationCodes,
  sku: sparePart,
}) => {
  return (
    <Carousel>
      {locationCodes.length > 0 && (
        <Box>
          <FlatCard variant="primary" p={1} flexGrow={1}>
            <IconText
              icon={<StyledInfoIcon fontSize="default" />}
              text="Emplacement(s) disponible(s)"
              color="dark"
              variant="small"
            />
            {locationCodes.map((locationCode) => (
              <Text variant="smallBold" mt={1} ml={4} key={locationCode} textAlign="left">
                {locationCode}
              </Text>
            ))}
          </FlatCard>
        </Box>
      )}
      {(sparePart.location || sparePart.demand) && <SkuDetailCard sku={sparePart} />}
    </Carousel>
  );
};

export default SkuDetailCarousel;
