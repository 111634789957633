import React from 'react';

import { Formik, FormikProps } from 'formik';

import {
  BACK,
  BackEvent,
  VALIDATE_SYMPTOMS_AND_DIAGNOSTIC,
  ValidateSymptomsAndDiagnosticEvent,
} from '../../types';
import { ValidationSchema } from '../../validators';
import SymptomsAndDiagnostic from './SymptomsAndDiagnostic';

type SymptomsAndDiagnosticForm = {
  symptoms: string;
  diagnostic: string;
};

type SymptomsAndDiagnosticProps = {
  send: (event: ValidateSymptomsAndDiagnosticEvent | BackEvent) => void;
  validationSchema: ValidationSchema;
} & SymptomsAndDiagnosticForm;
const SymptomsAndDiagnosticContainer: React.FunctionComponent<SymptomsAndDiagnosticProps> = ({
  symptoms,
  diagnostic,
  validationSchema,
  send,
}) => {
  const back = () =>
    send({
      type: BACK,
      data: {},
    });

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        symptoms: symptoms,
        diagnostic: diagnostic,
      }}
      validationSchema={validationSchema}
      onSubmit={(values: SymptomsAndDiagnosticForm) => {
        send({
          type: VALIDATE_SYMPTOMS_AND_DIAGNOSTIC,
          data: values,
        });
      }}
    >
      {(props: FormikProps<SymptomsAndDiagnosticForm>) => {
        return <SymptomsAndDiagnostic back={back} {...props} />;
      }}
    </Formik>
  );
};

export default SymptomsAndDiagnosticContainer;
