import { createStyles } from '@material-ui/core/styles';

const RepairReportAdditionalInformationPictureStyle = createStyles({
  AdditionalInformationContainer: {
    border: '1px solid #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 8,
    margin: '0px 24px',
    display: 'grid',
    textAlign: 'center',
  },
  AdditionalInformationContent: {
    margin: '64px 36px 58px',
  },
  addNewMediaButton: {
    display: 'flex',
    margin: '42px 24px 0px 24px',
    textDecoration: 'underline',
    textTransform: 'none',
    color: '#8795B3',
    fontSize: 14,
    lineHeight: '135%',
    background: '#FFFFFF',
    border: '1px dashed #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 8,
  },
  addNewMediaButtonContainer: {
    display: 'grid',
  },
  preview: {
    margin: 16,
    width: 148,
    height: 144,
    display: 'inline-flex',
    position: 'relative',
  },
  previewPicture: {
    borderRadius: 10,
  },
  onCloseIcon: {
    position: 'absolute',
    right: 0,
    color: '#fff',
  },
  addNextVisitLongButton: {
    fontSize: '15px',
    lineHeight: '140%',
    textTransform: 'none',
    width: 'fit-content',
    margin: '8px 24px',
    color: '#8795B3',
  },
  underlinedLabel: {
    textDecoration: 'underline',
  },
  reasonsContainer: {
    display: 'grid',
  },
});

export default RepairReportAdditionalInformationPictureStyle;
