import React from 'react';

import Box from 'components/atoms/Box';
import BulletList from 'components/atoms/BulletList';
import Text from 'components/atoms/Text';
import BulletPoint from 'components/molecules/BulletPoint';

const InterventionImpossibleDescription: React.FunctionComponent = () => {
  return (
    <Box>
      <Text variant="body" mt={1} mb={1}>
        Identique à la catégorie dans les CR, pour rappel une intervention est jugée impossible
        lorsque :
      </Text>
      <BulletList>
        <BulletPoint>
          Le client est absent, vous avez tenté plusieurs fois de le joindre et l'avez attendu 15min
        </BulletPoint>
        <BulletPoint>
          L'appareil à réparer est en hauteur, il faut deux personnes pour le descendre, le client
          n'est pas prêt à aider et n'a pas prévenu Murfy lors de sa réservation
        </BulletPoint>
        <BulletPoint>
          Il y a un risque d'endommager le mobilier ou l'environnement lors du démontage, et le
          propriétaire, après avoir été mis au courant du risque, n'accepte pas l'intervention
        </BulletPoint>
        <BulletPoint>L'environnement de travail est impraticable (sale ou dangereux)</BulletPoint>
        <BulletPoint>
          Pour diverses raisons possibles, il y a conflit avec le client. Il faut alors expliquer
          calmement au client que l'on met fin à l'intervention et que l'on va quitter son domicile.
          Il faut veiller à bien récupérer toutes ses affaires et à laisser l'endroit dans le
          meilleur état possible - nettoyage, rangement et remontage de la machine.
        </BulletPoint>
        <BulletPoint>La pièce à poser est introuvable à bord</BulletPoint>
      </BulletList>
      <Text variant="body" mt={1} mb={1}>
        Pour plus de détails, vous pouvez vous référer à la page Notion suivante :
      </Text>
      <a
        href="https://www.notion.so/murfy/Interventions-impossibles-et-litiges-clients-39e78d8984f543098a534db3657bd0a6"
        target="_blank"
        rel="noopener noreferrer"
      >
        Interventions impossibles et litiges clients
      </a>
    </Box>
  );
};

export default InterventionImpossibleDescription;
