import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

const HeaderWithText = styled.div<SpaceProps>`
  ${space}
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  position: sticky;
  width: 100vw;
  font-family: ${(props) => props.theme.fonts[0]};
  font-weight: ${(props) => props.theme.fontWeights[0]};
  color: ${(props) => props.theme.colors.black500};
  font-size: ${(props) => props.theme.fontSizes[3]}px;
`;

HeaderWithText.defaultProps = {
  marginBottom: 2,
  pt: 2,
  pb: 2,
  pr: 2,
  pl: 2,
};

export default HeaderWithText;
