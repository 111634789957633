import React from 'react';

import EditIcon from '@material-ui/icons/Edit';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';
import { useModals } from 'modalsContext';
import styled from 'styled-components';

import { EMPLOYEE_ROLES } from 'pages/Home/constants';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { Workshop } from 'types/workshops';

import { useWorkshops } from 'utils/api/api';

const StyledEditIcon = styled(EditIcon)((props) => ({
  color: props.theme.colors.white,
  height: 16,
  width: 16,
}));

const Profile: React.FunctionComponent = () => {
  const { showModal, closeModal } = useModals();
  const { workshops } = useWorkshops();
  const { employee, storeEmployee } = useEmployee();

  const employeeRole = EMPLOYEE_ROLES.find((role) => role.id === employee.role);
  const workshop = workshops?.find((el: Workshop) => el.id === employee?.workshop);
  const updateEmployee = (workshopId: number) => {
    dataProvider
      .update('employee', {
        id: employee.id,
        data: {
          workshop: workshopId,
        },
      })
      .then(() => {
        const employee = JSON.parse(localStorage.getItem('employee') || '');
        employee.workshop = workshopId;
        storeEmployee(employee);
      });
  };

  const updateEmployeeWorkshop = () => {
    showModal({
      modalToShow: 'FORM_SELECT_MODAL',
      modalProps: {
        list: workshops as any[],
        fieldName: '',
        title: "Modification de l'atelier",
        onValueSelection: (value: string) => {
          updateEmployee(parseInt(value));
          closeModal();
        },
        isChecked: (value: string) => {
          return parseInt(value) === employee.workshop;
        },
      },
    });
  };

  return (
    <Box backgroundColor="#073331">
      <Box px={2} py={3} borderBottom="1px solid #CBF5AB26">
        <Text variant="bodyBold" color="#FFFFFF">
          {employee?.user?.first_name} {employee?.user?.last_name}
        </Text>
        <Text variant="small" color="#FFFFFF">
          {employee?.user?.username}
        </Text>
        {employeeRole && (
          <Text variant="small" color="#FFFFFF">
            {employeeRole.name}
          </Text>
        )}

        <Box display="flex" onClick={() => updateEmployeeWorkshop()}>
          <Text mt={2} variant="small" color="#FFFFFF">
            Atelier: {workshop ? workshop.name : 'Non défini'}
          </Text>
          <Box mt={2} ml={1}>
            <StyledEditIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
