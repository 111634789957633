import React from 'react';

import Box from 'components/atoms/Box';

type SimpleBlockTitleProps = {
  children?: React.ReactNode;
};

const SimpleBlockTitle: React.FunctionComponent = ({
  children,
  ...rest
}: SimpleBlockTitleProps) => (
  <Box
    bg="white"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    borderBottomWidth="1px"
    borderColor="grey500_2"
    borderBottomStyle={'solid'}
    pt={1}
    pl={2}
    pb={1}
    pr={2}
    {...rest}
  >
    {children}
  </Box>
);

export default SimpleBlockTitle;
