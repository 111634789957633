/**
 * This context is used to display modals in our application
 * This is based on this SO answer by Dan Abramov: https://stackoverflow.com/a/35641680
 * It has been adapted to use React Context instead of Redux
 */
import React, { useContext, useState } from 'react';

import ConfirmModal, { ConfirmModalProps } from 'components/organisms/modals/ConfirmModal';
import FormSelectModal, { FormSelectModalProps } from 'components/organisms/modals/FormSelectModal';
import InformationModal, {
  InformationModalProps,
} from 'components/organisms/modals/InformationModal';
import InputModal, { InputModalProps } from 'components/organisms/modals/InputModal';
import MultipleChoiceModal, {
  MultipleChoiceModalProps,
} from 'components/organisms/modals/MultipleChoiceModal';
import SuccessModal, { SuccessModalProps } from 'components/organisms/modals/SuccessModal';
import PageVeil from 'components/templates/PageVeil';

type ModalOptions =
  | { modalToShow: 'CONFIRM_MODAL'; modalProps: ConfirmModalProps }
  | { modalToShow: 'SUCCESS_MODAL'; modalProps: SuccessModalProps }
  | { modalToShow: 'FORM_SELECT_MODAL'; modalProps: FormSelectModalProps }
  | { modalToShow: 'INPUT_MODAL'; modalProps: InputModalProps }
  | { modalToShow: 'INFORMATION_MODAL'; modalProps: InformationModalProps }
  | { modalToShow: 'CHOICE_MODAL'; modalProps: MultipleChoiceModalProps };
type ModalTypes = ModalOptions['modalToShow'];
type ModalProps = ModalOptions['modalProps'];

const MODAL_COMPONENTS = {
  CONFIRM_MODAL: ConfirmModal,
  SUCCESS_MODAL: SuccessModal,
  FORM_SELECT_MODAL: FormSelectModal,
  INPUT_MODAL: InputModal,
  INFORMATION_MODAL: InformationModal,
  CHOICE_MODAL: MultipleChoiceModal,
} as Record<ModalTypes, React.ComponentType<ModalProps>>;

type ModalRootProps = {
  modalOptions: ModalOptions | null;
};

const ModalRoot: React.FunctionComponent<ModalRootProps> = ({ modalOptions }: ModalRootProps) => {
  if (!modalOptions) {
    return null;
  }
  const ModalToShow = MODAL_COMPONENTS[modalOptions.modalToShow];

  return (
    <PageVeil>
      <ModalToShow {...modalOptions.modalProps} />
    </PageVeil>
  );
};

type ModalsContextType = {
  showModal: (modalToShow: ModalOptions) => void;
  closeModal: () => void;
};

const ModalsContext = React.createContext<ModalsContextType>({
  showModal: () => {},
  closeModal: () => {},
});

type ModalsProviderProps = {
  children: React.ReactNode;
};

export const ModalsProvider: React.FunctionComponent<ModalsProviderProps> = ({
  children,
}: ModalsProviderProps) => {
  const [modalOptions, setModalOptions] = useState<ModalOptions | null>(null);

  const showModal = (newModalOptions: ModalOptions) => {
    setModalOptions(newModalOptions);
  };

  const closeModal = () => {
    setModalOptions(null);
  };

  return (
    <ModalsContext.Provider value={{ showModal, closeModal }}>
      {children}
      <ModalRoot modalOptions={modalOptions} />
    </ModalsContext.Provider>
  );
};

export const useModals: () => ModalsContextType = () => useContext(ModalsContext);
