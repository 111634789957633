import React, { useContext } from 'react';

import { useEmployee } from 'employeeContext';

type FeatureFlagsContextType = {
  isBetaTester: boolean;
  isAllowedToAddVisits: boolean;
};
const FeatureFlagsContext = React.createContext<FeatureFlagsContextType>({
  isBetaTester: false,
  isAllowedToAddVisits: false,
});

type FeatureFlagsProviderProps = {
  children: React.ReactNode;
};

export const FeatureFlagsProvider: React.FunctionComponent<FeatureFlagsProviderProps> = ({
  children,
}: FeatureFlagsProviderProps) => {
  const { employee } = useEmployee();

  const isBetaTester = () => {
    if (!process.env.REACT_APP_BETA_TESTERS_IDS) return false;

    const beta_testers_ids = process.env.REACT_APP_BETA_TESTERS_IDS.split(',').map((string_id) =>
      parseInt(string_id)
    );
    return beta_testers_ids.includes(employee.id);
  };

  const isAllowedToAddVisits = () => {
    if (!process.env.REACT_APP_ALLOWED_TO_ADD_VISITS) return false;

    const allowed_to_add_visits = process.env.REACT_APP_ALLOWED_TO_ADD_VISITS.split(',').map(
      (string_id) => parseInt(string_id)
    );
    return allowed_to_add_visits.includes(employee.id);
  };

  return (
    <FeatureFlagsContext.Provider
      value={{ isBetaTester: isBetaTester(), isAllowedToAddVisits: isAllowedToAddVisits() }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags: () => FeatureFlagsContextType = () => useContext(FeatureFlagsContext);
