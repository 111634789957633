import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import {
  MarginProps,
  MinWidthProps,
  WidthProps,
  margin,
  minWidth,
  variant,
  width,
} from 'styled-system';

const ButtonVariants = {
  active: {
    backgroundColor: 'white',
    color: 'dark',
    borderColor: 'green',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  transparent: {
    backgroundColor: 'white',
    color: 'dark',
    borderColor: 'grey500_2',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  borderless: { backgroundColor: 'white', color: 'dark' },
};

type BaseChoiceButtonProps = {
  variant?: keyof typeof ButtonVariants;
  padding?: string;
} & MarginProps &
  WidthProps &
  MinWidthProps;

const BaseChoiceButton = styled('div')<BaseChoiceButtonProps>(
  margin,
  width,
  minWidth,

  (props: BaseChoiceButtonProps) => ({
    border: 0,
    borderRadius: themeGet('radii.2')(props),
    cursor: 'pointer',
    fontFamily: 'GT-Walsheim',
    fontSize: themeGet('fontSizes.1')(props),
    fontWeight: themeGet('fontWeights.medium')(props),
    padding: props.padding ? props.padding : `${themeGet('space.2')(props)}px`,
    display: 'flex',
    justifyContent: 'space-between',
  }),
  variant({
    variants: ButtonVariants,
  })
);

export type ChoiceButtonProps = {
  children: React.ReactNode;
  active?: boolean;
  color?: string;
  activeVariant?: 'active' | 'transparent' | 'borderless';
  inactiveVariant?: 'transparent' | 'borderless';
  displayNonSelectedIcon?: boolean;
  iconSize?: 'small' | 'default' | 'large';
} & BaseChoiceButtonProps &
  React.HTMLAttributes<HTMLDivElement>;

const ChoiceButton: React.FunctionComponent<ChoiceButtonProps> = ({
  children,
  active,
  color = 'pink',
  activeVariant = 'active',
  inactiveVariant = 'transparent',
  displayNonSelectedIcon,
  iconSize = 'small',
  ...rest
}: ChoiceButtonProps) => (
  <BaseChoiceButton variant={active ? activeVariant : inactiveVariant} {...rest}>
    {children}{' '}
    {active ? (
      <CheckCircleIcon style={{ color: color }} fontSize={iconSize} />
    ) : (
      displayNonSelectedIcon && (
        <RadioButtonUncheckedIcon style={{ color: 'black' }} fontSize={iconSize} />
      )
    )}
  </BaseChoiceButton>
);

export default ChoiceButton;
