import React, { useState } from 'react';

import { CenterFocusStrong } from '@material-ui/icons';

import WorkshopFileDetailScreen from 'pages/WorkshopFileDetailScreen/WorkshopFileDetailScreen';

import PageHeader from 'components/PageHeader';
import ProductSelectionPortal from 'components/ProductSelectionPortal/ProductSelectionPortal';
import WorkshopBottomBar from 'components/WorkshopBottomBar/WorkshopBottomBar';
import Box from 'components/atoms/Box';
import FloatingActionButton from 'components/atoms/FloatingActionButton';
import WorkshopFilesList from 'components/molecules/WorkshopFilesList';
import PageLayout from 'components/templates/PageLayout';

import { Product } from 'types/products';

const WorkshopFilesStock: React.FunctionComponent = () => {
  const [isProductSelectionOpen, setIsProductSelectionOpen] = useState<boolean>(false);
  const [selectedWorkshopFileId, setSelectedWorkshopFileId] = useState<number | undefined>();

  const handleCloseProductSelectionPortal = () => {
    setIsProductSelectionOpen(false);
  };
  const handleItemSelection = (product: Product) => {
    setSelectedWorkshopFileId(product.workshop_file);
  };

  return (
    <PageLayout>
      <Box bg="white">
        <PageHeader title="Stock" />
      </Box>
      <WorkshopFilesList handleItemSelection={handleItemSelection} />
      <FloatingActionButton
        page={1}
        variant="primary"
        onClick={() => setIsProductSelectionOpen(true)}
      >
        <CenterFocusStrong data-testid="scan-icon" />
      </FloatingActionButton>
      {isProductSelectionOpen && (
        <ProductSelectionPortal
          buttonText="Confirmer"
          onClose={handleCloseProductSelectionPortal}
          onProductSelection={(product: Product) => handleItemSelection(product)}
          open={isProductSelectionOpen}
        />
      )}
      {selectedWorkshopFileId && (
        <WorkshopFileDetailScreen
          workshopFileId={selectedWorkshopFileId}
          handleClosePortal={() => setSelectedWorkshopFileId(undefined)}
        />
      )}
      <WorkshopBottomBar />
    </PageLayout>
  );
};

export default WorkshopFilesStock;
