import React, { useState } from 'react';

import { useQuery } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import ButtonBlock from 'components/molecules/ButtonBlock';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { VALIDATE_NAMEPLATE, ValidateNameplateEvent } from '../../types';
import ApplianceNamplate from './ApplianceNameplate';

type ApplianceNameplateProps = {
  send: (event: ValidateNameplateEvent) => void;
  productId: string;
};

const ApplianceNameplate: React.FunctionComponent<ApplianceNameplateProps> = ({
  productId,
  send,
}: ApplianceNameplateProps) => {
  const classes = useStyles();
  const [displayValidationForm, setDisplayValidationForm] = useState(false);
  const handleSuccess = () =>
    send({
      type: VALIDATE_NAMEPLATE,
      data: {},
    });

  const { data: product, loading } = useQuery(
    {
      type: 'getOne',
      resource: 'products',
      payload: { id: productId },
    },
    {
      onSuccess: ({ data }) => {
        setDisplayValidationForm(Boolean(data.identification_plate));
      },
    }
  );

  if (loading) {
    return <PageLayout></PageLayout>;
  }

  if (displayValidationForm) {
    return (
      <PageLayout>
        <GoBackHeader title="Identification" />
        <ContentContainer>
          <img className={classes.img} src={product.identification_plate} alt="preview" />
        </ContentContainer>
        <ButtonBlock onClick={handleSuccess}>Valider la plaque </ButtonBlock>
        <ButtonBlock
          onClick={() => {
            setDisplayValidationForm(false);
          }}
        >
          Prendre une autre photo{' '}
        </ButtonBlock>
      </PageLayout>
    );
  }

  return <ApplianceNamplate productId={productId} onSuccess={handleSuccess} />;
};

const useStyles = makeStyles({
  img: {
    maxHeight: '320px',
    width: '100%',
  },
});
export default ApplianceNameplate;
