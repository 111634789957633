// React imports
import React, { useEffect } from 'react';

// Icons
import { Paper, Typography } from '@material-ui/core';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { useFormState } from 'react-final-form';

import RepairReportIntermediateValidationScreenStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportIntermediateValidationScreenStyle';

import { Product } from 'types/products';

import { useProducts } from 'utils/api/api';

type RepairReportIntermediateValidationScreenProps = {
  customerFileId: number;
  setNextStep: (p?: string | boolean) => void;
};

const RepairReportIntermediateValidationScreen: React.FunctionComponent<
  RepairReportIntermediateValidationScreenProps
> = ({ customerFileId, setNextStep }: RepairReportIntermediateValidationScreenProps) => {
  const classes = makeStyles(RepairReportIntermediateValidationScreenStyle)();

  const formValues = useFormState().values;
  const dataKeys = Object.keys(formValues);

  const numberOfProductDone = dataKeys.filter((element) => element.includes('product')).length; //to Differenciate the payment object and the products

  useEffect(() => {
    const timer = setTimeout(() => {
      setNextStep();
    }, 2000);
    return () => clearTimeout(timer);
  });
  const { products, loadingProducts } = useProducts(customerFileId);

  if (loadingProducts) {
    return null;
  }

  const productsCount = (products as Product[]).length;
  const numberOfProductLeft = productsCount - numberOfProductDone;

  return (
    <Paper className={classes.intermediateValidationScreenContainer}>
      <div className={classes.confirmationScreenContent}>
        <CheckCircleIcon className={classes.biggerCheckedIcon} />
        <Typography variant="h1" gutterBottom>
          Produit enregistré
        </Typography>
        {numberOfProductLeft > 0 ? (
          <div className={classes.progressInfoContainer}>
            <Typography variant="body2" gutterBottom>
              <span>
                {numberOfProductDone}/{productsCount}
              </span>
              <AssignmentIcon className={classes.icons} />
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span>Il reste encore {numberOfProductLeft} produits à traiter</span>
            </Typography>
          </div>
        ) : null}
        <div className={classes.progressInfoContainer}>
          <Typography gutterBottom>
            <CreditCardIcon className={classes.icons} />
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span>Pensez à encaisser pour terminer votre passage.</span>
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

export default RepairReportIntermediateValidationScreen;
