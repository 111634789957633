import Select from 'react-select';
import styled from 'styled-components';

const StyledSelect = styled(Select)((props) => ({
  margin: `0 ${props.theme.space[3]}px`,
  fontFamily: props.theme.fonts[0],
  color: props.theme.colors.dark,
  textAlign: 'left',
}));

export default StyledSelect;
