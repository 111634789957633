import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import BottomBar from 'components/BottomBar/BottomBar';
import PageHeader from 'components/PageHeader';
import PageLayout from 'components/templates/PageLayout';

import { useSkuList } from 'utils/api/api';

import RepairmanStockReception from './components/RepairmanStockReception';
import RepairmanStockReturn from './components/RepairmanStockReturn';
import RepairmanStockVisualization from './components/RepairmanStockVisualization';

const StyledTab = styled(Tab)((props) => ({
  fontSize: props.theme.fontSizes[1],
  fontStyle: 'normal',
  fontWeight: props.theme.fontWeights.bold,
  lineHeight: '140%',
  padding: '0 16px',
  flexGrow: 1,
}));

const RepairmanStockAppBar = styled(AppBar)((props) => ({
  '&.MuiAppBar-colorPrimary': {
    background: props.theme.colors.white,
    color: props.theme.colors.dark,
  },
  boxShadow: 'none',
  borderRadius: 'unset',
  width: '100vw',
}));

const RedAlert = styled.div((props) => ({
  background: props.theme.colors.red,
  width: props.theme.space[1],
  height: props.theme.space[1],
  borderRadius: '50%',
  position: 'absolute',
  right: 20,
  top: 12,
}));

const RepairmanStock: React.FunctionComponent = () => {
  const [indexTab, setIndexTab] = React.useState(0);

  const handleChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ): void => {
    setIndexTab(newValue);
  };

  const { skuList: skuToReturnList, loadingSku } = useSkuList({
    enable_employee_filter: 'on',
    status: 'return_to_warehouse',
  });

  return (
    <PageLayout>
      <RepairmanStockAppBar position="sticky">
        <PageHeader title="Pièces détachées" />
        <Tabs value={indexTab} onChange={handleChange}>
          <StyledTab label="RÉCEPTION" />
          <StyledTab label="EN STOCK" />
          <StyledTab label="RETOUR" />
          {skuToReturnList?.length > 10 && <RedAlert />}
        </Tabs>
      </RepairmanStockAppBar>

      <SwipeableViews axis="x" index={indexTab} onChangeIndex={setIndexTab}>
        {indexTab === 0 && <RepairmanStockReception />}
        {indexTab === 1 && <RepairmanStockVisualization />}
        {indexTab === 2 && <RepairmanStockReturn loading={loadingSku} skuList={skuToReturnList} />}
      </SwipeableViews>
      <BottomBar />
    </PageLayout>
  );
};

export default RepairmanStock;
