import React from 'react';

import { transparentize } from 'polished';
import styled from 'styled-components';
import { WidthProps, width } from 'styled-system';

type AdditionalInputProps = {
  valid: boolean;
};

const StyledInput = styled.input.attrs<AdditionalInputProps>(({ valid }) => ({
  valid,
}))<AdditionalInputProps>((props) => ({
  height: '56px',
  borderRadius: '12px',
  boxSizing: 'border-box',
  border: props.valid
    ? `1px solid ${transparentize(0.6, props.theme.colors.grey500_2)}`
    : `2px solid ${props.theme.colors.pink700}`,
  paddingLeft: props.value ? '12px' : '16px',
  paddingTop: props.value ? '16px' : '',
  fontSize: props.theme.fontSizes[1],
  fontFamily: 'inherit',
  outline: 'none',
  width: 'inherit',
  ':focus': {
    border: `2px solid ${props.theme.colors.green}`,
  },
  '::placeholder': {
    fontSize: '16px',
  },
}));

StyledInput.defaultProps = {
  valid: true,
};

export type InputProps = React.ComponentProps<typeof StyledInput> & WidthProps;

const InputContainer = styled.div<InputProps>(
  (props) => ({
    fontFamily: 'GT-Walsheim',
    '> label': {
      visibility: props.value ? 'visible' : 'hidden',
      position: 'relative',
      top: '24px',
      left: '12px',
      fontSize: '14px',
      color: props.theme.colors.grey700,
    },
  }),
  width
);

const Input: React.FunctionComponent<InputProps> = React.forwardRef((props: InputProps, ref) => {
  return (
    <InputContainer {...props}>
      <label>{props.placeholder}</label>
      <StyledInput {...props} ref={ref} />
    </InputContainer>
  );
});

Input.displayName = 'Input';
export default Input;
