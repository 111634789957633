// React imports
import React, { useEffect } from 'react';

// Styles imports
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';

// Component imports
import TransferStep from 'pages/Transfer/components/TransferStep';
import TransferPlayerStyle from 'pages/Transfer/styles/TransferPlayerStyle';

const useStyles = makeStyles(TransferPlayerStyle);
const initialStep = 'product_selection';

export default function TransferPlayer(props) {
  const classes = useStyles();
  const history = useHistory();
  // Should contains our slug, or null if undefined
  const stepSlug = props.stepSlug || null;
  useEffect(() => {
    // If no slug, it should be an init iteration
    if (stepSlug == null) {
      setNextStep(initialStep);
    }
  });

  // Will push the new step slug in the URL
  const setNextStep = (nextStepSlug) => {
    const pathname = history.location.pathname;
    if (pathname.includes('workshop')) {
      history.push(`/workshop/transfer/${nextStepSlug}`);
    } else {
      history.push(`/recondition/transfer/${nextStepSlug}`);
    }
  };

  return (
    <div className={classes.transferContainer}>
      <div>
        <TransferStep
          setNextStep={setNextStep}
          stepSlug={stepSlug}
          errorMessage={props.error}
          isErrorShown={props.isErrorShown}
          isLoading={props.isLoading}
        />
      </div>
    </div>
  );
}
