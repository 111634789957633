import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import StoreStyles from 'pages/Store/StoreStyles';

type ValidateScanningTaskProps = {
  disabled: boolean;
  onClick: () => void;
  buttonText: string;
};

const styleStore = makeStyles(StoreStyles);

const ValidateScanningTask: React.FunctionComponent<ValidateScanningTaskProps> = ({
  disabled,
  onClick,
  buttonText,
}: ValidateScanningTaskProps) => {
  const classes = styleStore();

  return (
    <div className={classes.customToolbar}>
      <Button
        disabled={disabled}
        className={`${classes.buttonToolbar} ${classes.buttonOrange}`}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ValidateScanningTask;
