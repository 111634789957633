import React from 'react';

import { CenterFocusStrong } from '@material-ui/icons';

import styled from 'styled-components';

import ProductDetailField from 'pages/Store/components/ProductDetailField';
import SparePartDetailField from 'pages/Store/components/SparePartDetailField';

import OpenScanButton from 'components/OpenScanButton';
import PortalComponent from 'components/Portal/PortalComponent';
import Button from 'components/atoms/Button';
import { ButtonProps } from 'components/atoms/Button/Button';
import FloatingActionButton from 'components/atoms/FloatingActionButton';

import { Product } from 'types/products';
import { SparePart } from 'types/spare-part';

import { isItemInList } from 'utils/list';

type Item = Product | SparePart;

export type ItemListSelectionPortalProps = {
  itemList: Item[];
  selectedList: Item[];
  surplusItemList?: Item[];
  title?: string;
  buttonText?: string;
  onClose: () => void;
  openItemSelection: () => void;
  onClickOnButton: () => void;
  open: boolean;
  isSaving: boolean;
  redButton?: boolean;
  field: string;
  openScanText: string;
  disableScan?: boolean;
};

export type BottomButtonProps = {
  redButton?: boolean;
} & ButtonProps;

const BottomButton = styled(Button)<BottomButtonProps>({
  width: '100%',
  alignSelf: 'self-end',
});

const SelectionPageContainer = styled.div((props) => ({
  paddingTop: props.theme.space[3],
  textAlign: 'left',
  height: '100%',
  overflow: 'auto',
}));

const ButtonContainer = styled.div((props) => ({
  width: '100%',
  boxSizing: 'border-box',
  padding: `${props.theme.space[2]}px ${props.theme.space[3]}px`,
  backgroundColor: props.theme.colors.white,
}));

const ItemListSelectionPortal: React.FunctionComponent<ItemListSelectionPortalProps> = ({
  itemList,
  selectedList,
  surplusItemList,
  title,
  buttonText,
  onClose,
  openItemSelection,
  onClickOnButton,
  isSaving,
  open,
  redButton,
  field,
  openScanText,
  disableScan,
}: ItemListSelectionPortalProps) => {
  return (
    <PortalComponent open={open} onClose={onClose} title={title}>
      {itemList && itemList.length > 0 && (
        <SelectionPageContainer>
          {itemList.map((item: Item, index) => (
            <>
              {field === 'sku' && (
                <SparePartDetailField
                  data-testid={index}
                  key={index}
                  sparePart={item as SparePart}
                  checked={isItemInList(item, selectedList)}
                />
              )}
              {field === 'product' && (
                <ProductDetailField
                  data-testid={index}
                  key={index}
                  product={item as Product}
                  checked={isItemInList(item, selectedList)}
                  removeable={false}
                />
              )}
            </>
          ))}
          {surplusItemList &&
            surplusItemList.length > 0 &&
            surplusItemList.map((item: Item, index) => (
              <SparePartDetailField
                data-testid={index}
                key={index}
                sparePart={item as SparePart}
                checked
              />
            ))}
        </SelectionPageContainer>
      )}
      {!disableScan && (
        <>
          {itemList && itemList.length > 0 ? (
            <FloatingActionButton page={1} variant="primary" onClick={openItemSelection}>
              <CenterFocusStrong data-testid="scan-icon" />
            </FloatingActionButton>
          ) : (
            <OpenScanButton text={openScanText} handleOpen={openItemSelection} />
          )}{' '}
        </>
      )}

      <ButtonContainer>
        <BottomButton
          variant={redButton ? 'error' : 'primary'}
          onClick={onClickOnButton}
          disabled={isSaving}
          data-testid="bottom-button"
        >
          {buttonText ? buttonText : 'Valider'}
        </BottomButton>
      </ButtonContainer>
    </PortalComponent>
  );
};

export default ItemListSelectionPortal;
