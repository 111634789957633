import React from 'react';

import StateMachineProvider, { StateRenderer } from 'StateMachineProvider';

import DeviceList from 'components/DeviceList';
import PageLayout from 'components/templates/PageLayout';

import { Product } from 'types/products';

import SparePartsThumbnails from '../SparePartThumbnailsList/SparePartsThumbnails';
import RetrieveProductModelSkuModel from './RetrieveProductModelSkuModels';
import { productDisassemblyMachine } from './StateMachine';
import { PRODUCT_SELECTION, PRODUCT_VERIFICATION, SPARE_PARTS } from './StateMachine';
import { BACK, CONFIRMATION, SELECTION } from './StateMachine';

type ProductDisassemblyTaskProps = {
  handleClose: () => void;
  product: Product;
};

const ProductDisassemblyTask: React.FunctionComponent<ProductDisassemblyTaskProps> = ({
  handleClose,
  product,
}: ProductDisassemblyTaskProps) => {
  return (
    <StateMachineProvider machine={productDisassemblyMachine}>
      <StateRenderer state={PRODUCT_VERIFICATION}>
        {(send) => {
          return (
            <PageLayout>
              <RetrieveProductModelSkuModel
                goBack={handleClose}
                product={product}
                onSubmit={(productReference: string, brand: string, category: string) =>
                  send({
                    type: CONFIRMATION,
                    data: {
                      product_reference: productReference,
                      brand: brand,
                      category: category,
                    },
                  })
                }
              />
            </PageLayout>
          );
        }}
      </StateRenderer>
      <StateRenderer state={PRODUCT_SELECTION}>
        {(send, currentState) => {
          return (
            <PageLayout>
              <DeviceList
                productReference={product.model_reference as string}
                onSubmit={(deviceId: string) => {
                  send({
                    type: SELECTION,
                    data: {
                      device_id: deviceId,
                    },
                  });
                }}
                goBack={() => {
                  send({
                    type: BACK,
                  });
                }}
                selectedDeviceId={currentState.context.device_id}
              />
            </PageLayout>
          );
        }}
      </StateRenderer>

      <StateRenderer state={SPARE_PARTS}>
        {(send, currentState) => {
          return (
            <PageLayout>
              <SparePartsThumbnails
                product={product}
                handleClose={handleClose}
                requestParams={{
                  aswoDeviceId: currentState.context.device_id,
                  brand: currentState.context.brand,
                  category: currentState.context.category,
                  reference: currentState.context.product_reference,
                }}
                goBack={() => {
                  send({
                    type: BACK,
                  });
                }}
              />
            </PageLayout>
          );
        }}
      </StateRenderer>
    </StateMachineProvider>
  );
};

export default ProductDisassemblyTask;
