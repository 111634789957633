import React, { useEffect, useState } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import dataProvider from 'dataProvider';
import styled from 'styled-components';

import RepairCycleDefectivePart from 'pages/RepairCycleTasks/RepairCycleDiagTask/RepairCycleDefectiveParts';
import RepairCycleSymptoms from 'pages/RepairCycleTasks/RepairCycleDiagTask/RepairCycleSymptoms';
import { DONE } from 'pages/RepairCycleTasks/RepairCycleUtils';
import { renderIrisCodeFromId } from 'pages/RepairCycleTasks/RepairCycleUtils';

import PaperStripAction from 'components/PaperStripAction';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import TaskItem from 'components/atoms/TaskItem';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/TextArea';
import PageLayout from 'components/templates/PageLayout';

import { IrisCondition, IrisDefect, IrisSection, IrisSymptom } from 'types/iris-codes';

type SymptomFormValue = {
  symptom: string | null;
  condition: string | null;
};

type DefectivePartFormValue = {
  section: string | null;
  defect: string | null;
};

type SelectedSymptom = {
  symptom: string | null;
  condition: string | null;
};

type SelectedDefectivePart = {
  section: string | null;
  defect: string | null;
};

const GreenPlus = styled(AddCircleIcon)((props) => ({
  color: props.theme.colors.green,
}));

type RepairCycleDiagTaskProps = {
  handleClose: () => void;
  productId: number;
  irisConditions: IrisCondition[];
  irisDefects: IrisDefect[];
  irisSections: IrisSection[];
  irisSymptoms: IrisSymptom[];
};

const formatDiagnosticTask = (
  symptomList: SelectedSymptom[],
  defectivePartList: SelectedDefectivePart[],
  comment: string | null,
  productId: number
) => {
  return {
    state: DONE,
    comment: comment,
    product: productId,
    symptoms: symptomList?.map((symptom) => {
      return { iris_condition: symptom.condition, iris_symptom: symptom.symptom };
    }),
    section_defects: defectivePartList?.map((defectivePart) => {
      return { iris_section: defectivePart.section, iris_defect: defectivePart.defect };
    }),
  };
};

const RepairCycleDiagTask: React.FunctionComponent<RepairCycleDiagTaskProps> = ({
  handleClose,
  productId,
  irisConditions,
  irisDefects,
  irisSections,
  irisSymptoms,
}: RepairCycleDiagTaskProps) => {
  const [isSymptomAdditionOpen, setIsSymptomAdditionOpen] = useState<boolean>(false);
  const [isDefectivePartOpen, setIsDefectivePartOpen] = useState<boolean>(false);

  const [symptomsList, setSymptomsList] = useState<SelectedSymptom[]>([]);
  const [defectivePartList, setDefectivePartList] = useState<SelectedDefectivePart[]>([]);
  const [comment, setComment] = useState<string | null>('');

  const [error, setError] = useState<string | null>(null);

  const handleSymptomsAddition = (symptom: SymptomFormValue) => {
    setSymptomsList([...symptomsList, symptom]);
    setIsSymptomAdditionOpen(false);
  };

  const handleSymptomsDeletion = (index: number) => {
    const symptomsListState = [...symptomsList];
    symptomsListState.splice(index, 1);
    setSymptomsList([...symptomsListState]);
  };

  const handleDefectivePartAddition = (defectivePart: DefectivePartFormValue) => {
    setDefectivePartList([...defectivePartList, defectivePart]);
    setIsDefectivePartOpen(false);
  };

  const handleDefectivePartDeletion = (index: number) => {
    const defectiveListState = [...defectivePartList];
    defectiveListState.splice(index, 1);
    setDefectivePartList([...defectiveListState]);
  };

  const renderDefectivePart = (defectivePart: DefectivePartFormValue) => {
    return `${
      defectivePart?.section && renderIrisCodeFromId(irisSections, parseInt(defectivePart.section))
    } ⟶ ${
      defectivePart?.defect && renderIrisCodeFromId(irisDefects, parseInt(defectivePart.defect))
    }`;
  };

  const renderSymptom = (symptom: SymptomFormValue) => {
    return `${symptom.symptom && renderIrisCodeFromId(irisSymptoms, parseInt(symptom.symptom))} ⟶ ${
      symptom.condition && renderIrisCodeFromId(irisConditions, parseInt(symptom.condition))
    }`;
  };

  useEffect(() => {
    setError(null);
  }, [symptomsList, defectivePartList, comment]);

  const handleSubmitDiagnostic = () => {
    const formattedDiagnostic = formatDiagnosticTask(
      symptomsList,
      defectivePartList,
      comment,
      productId
    );
    dataProvider
      .create('diagnostics', {
        data: formattedDiagnostic,
      })
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <PageLayout>
      {!isSymptomAdditionOpen && !isDefectivePartOpen && (
        <>
          <HeaderWithArrow title="Diagnostic" action={handleClose} />
          <Box overflow="auto" height="calc(100% - 128px)">
            <Box mt={4}>
              <PaperStripAction
                text={'SYMPTÔME(S)'}
                Icon={GreenPlus}
                callback={() => setIsSymptomAdditionOpen(true)}
                textMode="task"
              />
              {symptomsList?.map((symptom, index) => (
                <TaskItem
                  key={`${symptom.symptom} - ${symptom.condition}`}
                  text={renderSymptom(symptom)}
                  removeable={true}
                  onRemove={() => handleSymptomsDeletion(index)}
                />
              ))}
            </Box>
            <Box mt={4}>
              <PaperStripAction
                text={'PIÈCE(S) DÉFECTUEUSE(S)'}
                Icon={GreenPlus}
                callback={() => setIsDefectivePartOpen(true)}
                textMode="task"
              />
              {defectivePartList?.map((defectivePart, index) => (
                <TaskItem
                  key={`${defectivePart.section} - ${defectivePart.defect}`}
                  text={renderDefectivePart(defectivePart)}
                  removeable={true}
                  onRemove={() => handleDefectivePartDeletion(index)}
                />
              ))}
            </Box>
            <Box mt={4} ml={2} mr={2}>
              <Text mb={2} variant="subtitle" textAlign="start">
                Commentaire
              </Text>
              <TextArea
                onChange={(event) => setComment((event.target as HTMLTextAreaElement).value)}
                width="100%"
                rows="8"
                data-testid="additional_information"
                value={comment}
              />
            </Box>
            {error && (
              <Box mt={4} ml={1} mr={1} p={1} borderRadius={1} backgroundColor="red">
                <Text variant="body" color="white">
                  Une erreur est survenue: {error}
                </Text>
              </Box>
            )}
            <BottomButton variant="primary" onClick={() => handleSubmitDiagnostic()} minWidth={192}>
              Valider le diagnostic
            </BottomButton>
          </Box>
        </>
      )}
      {isSymptomAdditionOpen && (
        <RepairCycleSymptoms
          handleSymptomsAddition={handleSymptomsAddition}
          irisSymptoms={irisSymptoms}
          irisConditions={irisConditions}
          handleClose={() => setIsSymptomAdditionOpen(false)}
        />
      )}

      {isDefectivePartOpen && (
        <RepairCycleDefectivePart
          handleDefectivePartAddition={handleDefectivePartAddition}
          irisDefects={irisDefects}
          irisSections={irisSections}
          handleClose={() => setIsDefectivePartOpen(false)}
        />
      )}
    </PageLayout>
  );
};

export default RepairCycleDiagTask;
