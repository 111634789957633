import { createStyles } from '@material-ui/core/styles';

const RepairReportnameplateTemplateStyle = createStyles({
  nameplateBackground: {
    backgroundColor: '#ffffff',
    height: '100vh',
  },
  nameplateContainer: {
    border: '1px solid #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 8,
    margin: '0px 24px',
    display: 'grid',
    textAlign: 'center',
  },
  checkProofContainer: {
    border: '1px solid #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 8,
    margin: '24px',
    display: 'grid',
    textAlign: 'center',
  },
  nameplateContent: {
    margin: '64px 36px 0px',
  },
  confirmationScreenContent: {
    margin: '64px 36px 64px',
  },
  cameraIcon: {
    color: '#F7B349',
  },
  addNewMediaButton: {
    display: 'flex',
    margin: '42px 58px',
    textDecoration: 'underline',
    textTransform: 'none',
    color: '#8795B3',
  },
  addNewMediaButtonNameplate: {
    display: 'flex',
    margin: '42px 24px 0px 24px',
    textDecoration: 'underline',
    textTransform: 'none',
    color: '#8795B3',
    fontSize: 14,
    lineHeight: '135%',
    background: '#FFFFFF',
    border: '1px dashed #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 8,
  },
  contentMoreMedias: {
    marginLeft: 20,
  },
  addNewMediaButtonContainer: {
    display: 'grid',
  },
  nameplateContainerCamera: {
    margin: '0px 24px',
    textAlign: 'center',
    paddingTop: 24,
  },
  pictureCarousel: {
    'overflow-x': 'auto',
    whiteSpace: 'nowrap',
  },
  checkedIcon: {
    color: '#81D89F',
  },
  biggerCheckedIcon: {
    color: '#81D89F',
    height: 40,
    width: 40,
  },
  biggerErrorIcon: {
    color: '#F85B5B',
    height: 40,
    width: 40,
  },
  infoNameplate: {
    textTransform: 'none',
    color: '#8795B3',
    textDecorationLine: 'underline',
  },
  retakePictureButton: {
    color: '#F7B349',
  },
  infoPicture: {
    marginLeft: '16px',
    verticalAlign: 'super',
    color: '#2B3B5C',
  },
  guidelines: {
    margin: '28px 58px',
    color: '#2B3B5C',
  },
  videoContainer: {
    borderRadius: 35,
    width: 280,
    height: 326,
    marginRight: 7,
    marginLeft: 7,
  },
  secondaryButtonContent: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '140%',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  stepQuestion: {
    height: 28,
    margin: '24px',
    fontWeight: 'bold',
  },
  validateButton: {
    margin: '24px',
    height: 56,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#2B3B5C',
  },
  infoForPicture: {
    color: '#2B3B5C',
  },
});

export default RepairReportnameplateTemplateStyle;
