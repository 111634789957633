import React from 'react';

import { IconProps } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { themeGet } from '@styled-system/theme-get';
import { useModals } from 'modalsContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BottomBar from 'components/BottomBar/BottomBar';
import OpenScanButton from 'components/OpenScanButton';
import PageHeader from 'components/PageHeader';
import ProductSelectionPortal from 'components/ProductSelectionPortal/ProductSelectionPortal';
import ReconditionBottomBar from 'components/ReconditionBottomBar';
import WorkshopBottomBar from 'components/WorkshopBottomBar/WorkshopBottomBar';
import Box from 'components/atoms/Box';

import { Product } from 'types/products';

const Container = styled.div(() => ({
  height: '100vh',
}));

const StyledIcon = styled(InfoIcon)<IconProps>((props: IconProps) => ({
  color: themeGet('colors.pink700')(props),
}));

const WorkshopRepairCycleScan: React.FunctionComponent = () => {
  const history = useHistory();
  const [isProductSelectionOpen, setIsProductSelectionOpen] = React.useState(false);
  const { showModal, closeModal } = useModals();
  const isWorkshopContext = history.location.pathname.includes('workshop');
  const isReconditionContext = history.location.pathname.includes('recondition');

  const closeDisplayedModal = () => {
    setIsProductSelectionOpen(false);
    closeModal();
  };

  const handleRedirectToProductDashboard = (product: Product) => {
    if (!product.location_workshop_name) {
      showModal({
        modalToShow: 'CONFIRM_MODAL',
        modalProps: {
          title: `Oups, la machine est dans l'emplacement ${product.location_name}`,
          content: `Cet emplacement n'est relié à aucun atelier. Il faut transférer la machine dans son emplacement actuel pour modifier son cycle de réparation`,
          onConfirm: () => {
            history.push('/recondition/transfer/product_selection');
            closeDisplayedModal();
          },
          onCancel: () => {
            history.push(`${history.location.pathname}/${product.id}`);
            closeDisplayedModal();
          },
          cancelLabel: `Continuer`,
          confirmLabel: 'Transférer',
          icon: <StyledIcon />,
        },
      });
    } else {
      setIsProductSelectionOpen(false);
      history.push(`${history.location.pathname}/${product.id}`);
    }
  };

  return (
    <Box>
      <PageHeader title="Cycle de reconditionnement" />
      <Container>
        <Box height="100%">
          <OpenScanButton
            text="Identification de la machine"
            handleOpen={() => setIsProductSelectionOpen(true)}
          />
        </Box>

        {isProductSelectionOpen && (
          <ProductSelectionPortal
            buttonText="Confirmer"
            onClose={() => setIsProductSelectionOpen(false)}
            onProductSelection={(product: Product) => handleRedirectToProductDashboard(product)}
            open={isProductSelectionOpen}
          />
        )}
        {isWorkshopContext && <WorkshopBottomBar />}
        {isReconditionContext && <ReconditionBottomBar />}
        {!isWorkshopContext && !isReconditionContext && <BottomBar />}
      </Container>
    </Box>
  );
};

export default WorkshopRepairCycleScan;
