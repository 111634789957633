import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useField } from 'react-final-form';

import RepairReportButtonStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportButtonStyle';
import { StepId } from 'pages/RepairReport/services/stepHelper';

const useStyles = makeStyles(RepairReportButtonStyle);

type RepairReportButtonProps = {
  stepQuestionId: StepId; // Step id of the current step in the form submission, used as the key of the form step data
  buttonLabel: string; // Button Label displayed
  buttonValue: string | boolean; // Button value used to populate the form data can be a string or a boolean
  validateFunction: (p?: string | boolean) => void; // Function used to pass to the next step
  formProductIdReference: string;
};

const RepairReportButton: React.FunctionComponent<RepairReportButtonProps> = ({
  stepQuestionId,
  buttonLabel,
  buttonValue,
  validateFunction,
  formProductIdReference,
}: RepairReportButtonProps) => {
  const classes = useStyles();
  const {
    input: { onChange: onStepValueChange },
  } = useField(formProductIdReference + '.' + stepQuestionId);

  const onClickButtonForm = (buttonValue: string | boolean) => {
    onStepValueChange(buttonValue); // persist data in form after this step
    validateFunction(buttonValue); // allow next step decision
  };

  return (
    <Button
      className={classes.basicButton}
      variant="contained"
      onClick={() => onClickButtonForm(buttonValue)}
    >
      {buttonLabel}
    </Button>
  );
};

export default RepairReportButton;
