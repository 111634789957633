import React from 'react';

import { useModals } from 'modalsContext';

import { PaymentMethod } from '../form-values';
import { paymentModeArray } from '../utils/utils';

type ReturnType = {
  toggleMethodDialog: (
    choices: paymentModeArray[],
    paymentMethod: PaymentMethod,
    setFieldValue: (name: string, value: number | string) => void,
    customContent: React.ReactNode,
    hiddenChoices?: paymentModeArray[]
  ) => void;
  toggleAmountDialog: (
    amount: number,
    setFieldValue: (name: string, value: number) => void
  ) => void;
};

const CustomModals = (): ReturnType => {
  const { showModal, closeModal } = useModals();

  const toggleMethodDialog = (
    choices: paymentModeArray[],
    paymentMethod: PaymentMethod,
    setFieldValue: (name: string, value: number | string) => void,
    customContent?: React.ReactNode,
    hiddenChoices?: paymentModeArray[]
  ) => {
    showModal({
      modalToShow: 'CHOICE_MODAL',
      modalProps: {
        choices: choices,
        title: "Type d'encaissement",
        onSelect: (fieldValue: string) => {
          setFieldValue('paymentMethod', fieldValue);
          closeModal();
        },
        defaultValue: paymentMethod || '',
        customContent: customContent,
        onCancel: closeModal,
        hiddenChoices: hiddenChoices || [],
      },
    });
  };

  const toggleAmountDialog = (
    amount: number,
    setFieldValue: (name: string, value: number) => void
  ) => {
    showModal({
      modalToShow: 'INPUT_MODAL',
      modalProps: {
        fieldName: 'amount',
        title: 'Montant dû',
        initialValue: amount,
        onCancel: () => {
          closeModal();
        },
        onConfirm: (selectedAmount: number) => {
          setFieldValue('amount', selectedAmount);
          closeModal();
        },
      },
    });
  };

  return { toggleMethodDialog, toggleAmountDialog };
};

export default CustomModals;
