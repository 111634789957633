import React from 'react';

import { tint } from 'polished';
import styled from 'styled-components';
import { MarginProps, WidthProps, margin, variant } from 'styled-system';
import theme from 'theme';

import Box from 'components/atoms/Box';

interface IconProps {
  fontSize: 'default' | 'inherit';
}

export const TagVariants = {
  primary: {
    backgroundColor: 'grey200',
    color: 'grey500',
  },
  secondary: {
    backgroundColor: tint(0.85, theme.colors.lightBlue),
    color: theme.colors.lightBlue,
  },
  ternary: {
    backgroundColor: tint(0.85, theme.colors.purple),
    color: theme.colors.purple,
  },
  info: {
    backgroundColor: tint(0.85, theme.colors.turquoise),
    color: theme.colors.turquoise,
  },
  alert: {
    backgroundColor: tint(0.85, theme.colors.red),
    color: theme.colors.red,
  },
  warning: {
    backgroundColor: tint(0.85, theme.colors.honey),
    color: theme.colors.honey,
  },
  success: {
    backgroundColor: tint(0.85, theme.colors.greenChecked),
    color: theme.colors.greenChecked,
  },
  problem: {
    backgroundColor: tint(0.85, theme.colors.orange),
    color: theme.colors.orange,
  },
};

type ContainerProps = {
  variant?: keyof typeof TagVariants;
} & MarginProps &
  WidthProps;

export type TagProps = {
  text: string;
  Icon?: React.FunctionComponent<IconProps>;
} & ContainerProps &
  React.HTMLAttributes<HTMLDivElement>;

// The height is outside the spacing grid
const Container = styled('div')<ContainerProps>`
  ${margin};
  padding: 2px 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 21px;
  border-radius: ${(props) => props.theme.radii[1] / 2}px;
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  width: fit-content;
  ${variant({
    variants: TagVariants,
  })};
`;

const Tag: React.FunctionComponent<TagProps> = ({ text, Icon, ...rest }: TagProps) => {
  return (
    <Container {...rest}>
      {text}
      {Icon && (
        <Box display="flex" alignItems="center" ml={1}>
          <Icon fontSize="inherit" />
        </Box>
      )}
    </Container>
  );
};

Tag.defaultProps = {
  variant: 'alert',
};
export default Tag;
