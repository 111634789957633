import React from 'react';

/* eslint-disable react/jsx-key */
import { Route } from 'react-router-dom';

import Prequalification from './Prequalification';
import PrequalificationReport from './PrequalificationReport';

export const prequalificationReportRoutes = [
  <Route
    exact
    path="/prequalification/:prequalificationId"
    component={Prequalification}
    noLayout
  />,
  <Route
    exact
    path="/prequal-report/:prequalificationId/:do"
    component={PrequalificationReport}
    noLayout
  />,
];
