import React from 'react';

import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

const StyledBox = styled(Box)((props) => ({
  flex: '1 0 50%;',
  marginTop: props.theme.space[2],
  marginBottom: props.theme.space[2],
}));

type ProductInfoBlockProps = {
  name: string;
  value: string | number;
};

const SimpleBlockField: React.FunctionComponent<ProductInfoBlockProps> = ({
  name,
  value,
}: ProductInfoBlockProps) => (
  <StyledBox flex="1 0 50%;">
    <Text variant="small" color="grey">
      {name}
    </Text>
    <Text variant="smallBold">{value}</Text>
  </StyledBox>
);

export default SimpleBlockField;
