import React, { useState } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { useEmployee } from 'employeeContext';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import { useModals } from 'modalsContext';
import styled from 'styled-components';

import { useDiagnosticSections } from 'pages/RepairCycleTasks/hooks';

import PaperStripAction from 'components/PaperStripAction';
import SparePartSelector from 'components/SparePartSelector';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import ChoiceButton from 'components/atoms/ChoiceButton';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import PageLayout from 'components/templates/PageLayout';

import { IrisRepair, IrisSection } from 'types/iris-codes';
import { Product } from 'types/products';
import { Sku, SparePartWithFittingType } from 'types/sku';
import { FittingType } from 'types/spare-part-fittings';

import { useSpareParts } from 'utils/api/api';

import GenericCodeList from '../GenericIrisCodeList/GenericIrisCodeList';
import { FITTABLE_SPARE_PART_STATUSES, renderIrisCodeFromId } from '../RepairCycleUtils';
import { postRepairSection, postSparePartFitting } from '../api';
import { sparePartFittingChoices } from './Utils';

interface RestProps {
  index: number;
}

type RepairCycleRepairActionProps = {
  handleClose: () => void;
  irisRepairs: IrisRepair[];
  irisSections: IrisSection[];
  product: Product;
};

type SparePartPartFittingFormType = { fitted: boolean; fitting_type: FittingType; spare_part: Sku };
const GreenCheckIcon = styled(AddCircleIcon)((props) => ({
  color: props.theme.colors.green,
}));
const GreyRemoveIcon = styled(RemoveCircleOutlineIcon)((props) => ({
  color: props.theme.colors.grey300,
}));

const RepairCycleRepairAction: React.FunctionComponent<RepairCycleRepairActionProps> = ({
  handleClose,
  irisRepairs,
  irisSections,
  product,
}: RepairCycleRepairActionProps) => {
  const { showModal, closeModal } = useModals();

  const { diagnosticDefectiveSectionIds } = useDiagnosticSections(product.id);
  const { employee } = useEmployee();

  const { spareParts } = useSpareParts({
    workshop_file: product.workshop_file,
    status: FITTABLE_SPARE_PART_STATUSES,
  });

  const [isSectionAdditionOpen, setIsSectionAdditionOpen] = useState<boolean>(false);
  const [isRepairAdditionOpen, setIsRepairAdditionOpen] = useState<boolean>(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(-1);
  const [isSparePartScanOpen, setIsSparePartScanOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitRepairAction = async (values: {
    section_repairs: { iris_section: number; iris_repair: number | null }[];
    spare_part_fitting: { spare_part: Sku; fitted: boolean }[];
  }) => {
    const sectionsRepaired = values.section_repairs.filter((section) => section.iris_repair);
    const sparePartFitted = values.spare_part_fitting.filter((sparePart) => sparePart.fitted);

    setIsLoading(true);

    Promise.all([
      ...sectionsRepaired.map(async (sectionRepaired) => {
        await postRepairSection(product.id, sectionRepaired);
      }),
      ...sparePartFitted.map(async (fittedSparePart) => {
        await postSparePartFitting(product.id, fittedSparePart);
      }),
    ]).then(() => {
      setIsLoading(false);
      handleClose();
    });
  };

  const openRepairActionSelection = (index: number) => {
    setSelectedSectionIndex(index);
    setIsRepairAdditionOpen(true);
  };

  const fitSparePart = (
    setFieldValue: (
      field: string,
      value: SparePartPartFittingFormType[],
      shouldValidate?: boolean | undefined
    ) => void,
    name: string,
    values: SparePartPartFittingFormType[],
    index: number
  ) => {
    showModal({
      modalToShow: 'CHOICE_MODAL',
      modalProps: {
        title: 'Peux-tu nous en dire plus sur le lien entre la pièce et la machine ?',
        defaultValue: values[index].fitting_type,
        onCancel: closeModal,
        onSelect: (value: string) => {
          values[index].fitting_type = value as FittingType;
          setFieldValue(name, values);
        },
        choices: sparePartFittingChoices,
      },
    });
  };

  const SparePartCheckbox = ({
    field,
    form,
    label,
    ...rest
  }: { label: string } & FieldProps & RestProps) => {
    const { name, value: formikValue } = field;
    const { setFieldValue } = form;
    const values = formikValue || [];
    const handleChange = () => {
      values[rest.index].fitted = !values[rest.index].fitted;
      if (!values[rest.index].fitted) {
        setFieldValue(name, values);
      } else {
        fitSparePart(setFieldValue, name, values, rest.index);
      }
    };

    return (
      <Box borderTop={'1px solid #DBDFE8'}>
        <ChoiceButton
          key={values[rest.index].id}
          onClick={handleChange}
          active={values[rest.index].fitted}
          color="#005646"
          activeVariant="borderless"
          inactiveVariant="borderless"
          displayNonSelectedIcon
          iconSize="default"
          {...rest}
        >
          <span>{label}</span>
        </ChoiceButton>
      </Box>
    );
  };

  const initialValues = {
    section_repairs: diagnosticDefectiveSectionIds.map((section: string) => {
      return {
        iris_repair: null,
        iris_section: parseInt(section),
      };
    }),
    spare_part_fitting: !spareParts
      ? []
      : spareParts.map((sparePart: Sku) => {
          return {
            spare_part: sparePart,
            fitted: false,
            fitting_type: null,
          };
        }),
  };

  return (
    <PageLayout>
      <Box overflow="auto" mb={80}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitRepairAction}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              {!isSectionAdditionOpen && !isRepairAdditionOpen && !isSparePartScanOpen && (
                <>
                  <Box position="absolute" top={0}>
                    <HeaderWithArrow title="Réparation" action={handleClose} />
                  </Box>
                  <Box px={2} py={2} mt={80} backgroundColor="white" textAlign="start">
                    <Text variant="small" color="green">
                      Pièce à poser
                    </Text>
                  </Box>
                  <Box overflow="auto" textAlign="start">
                    {values.spare_part_fitting.map((sparePart, index) => {
                      return (
                        <Box key={sparePart.spare_part.id}>
                          <Field
                            component={SparePartCheckbox}
                            name="spare_part_fitting"
                            index={index}
                            value={`${sparePart.spare_part.id}`}
                            label={`${sparePart.spare_part.sku_model.designation}  ${sparePart.spare_part.sku_model.sku_reference}`}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                  <Button
                    onClick={() => setIsSparePartScanOpen(true)}
                    mt={1}
                    variant="fullTransparent"
                    width="fit-content"
                  >
                    + Scanner des pièces posées
                  </Button>
                </>
              )}

              <FieldArray name="section_repairs">
                {({ push, replace }) => (
                  <>
                    {!isSectionAdditionOpen && !isRepairAdditionOpen && !isSparePartScanOpen && (
                      <>
                        <Box
                          pl={2}
                          pt={1}
                          pb={1}
                          mt={4}
                          backgroundColor="white"
                          textAlign="start"
                          borderBottom={'1px solid #DBDFE8'}
                        >
                          <Text variant="small" color="green">
                            Pièce à réparer
                          </Text>
                        </Box>
                        <>
                          {values.section_repairs.map((sectionToRepair, index) => (
                            <>
                              {!sectionToRepair.iris_repair ? (
                                <PaperStripAction
                                  key={index}
                                  text={renderIrisCodeFromId(
                                    irisSections,
                                    sectionToRepair.iris_section
                                  ).toUpperCase()}
                                  Icon={GreenCheckIcon}
                                  callback={() => openRepairActionSelection(index)}
                                  textMode="task"
                                />
                              ) : (
                                <PaperStripAction
                                  key={index}
                                  text={renderIrisCodeFromId(
                                    irisSections,
                                    sectionToRepair.iris_section
                                  ).toUpperCase()}
                                  secondaryText={renderIrisCodeFromId(
                                    irisRepairs,
                                    sectionToRepair?.iris_repair || 0
                                  )}
                                  Icon={GreyRemoveIcon}
                                  callback={() =>
                                    replace(index, {
                                      iris_section: sectionToRepair.iris_section,
                                      iris_repair: null,
                                    })
                                  }
                                  textMode="taskFulfilled"
                                />
                              )}
                            </>
                          ))}
                        </>

                        <Button
                          onClick={() => setIsSectionAdditionOpen(true)}
                          mt={1}
                          variant="fullTransparent"
                          width="fit-content"
                        >
                          + Ajouter une autre pièce réparée
                        </Button>

                        <BottomButton disabled={isLoading} variant="primary" minWidth={192}>
                          {isLoading ? 'Chargement en cours' : 'Confirmer'}
                        </BottomButton>
                      </>
                    )}

                    {isSectionAdditionOpen && (
                      <GenericCodeList
                        handleCodeAddition={(value: { code: string }): void => {
                          push({
                            iris_section: parseInt(value.code),
                            iris_repair: null,
                          });
                          setIsSectionAdditionOpen(false);
                        }}
                        handleClose={() => {
                          setIsSectionAdditionOpen(false);
                          setSelectedSectionIndex(-1);
                        }}
                        irisCodes={irisSections}
                        title="Pièce à réparer"
                        buttonText="Valider la pièce"
                      />
                    )}
                    {isRepairAdditionOpen && (
                      <GenericCodeList
                        handleCodeAddition={(value: { code: string }): void => {
                          replace(selectedSectionIndex, {
                            iris_section: values.section_repairs[selectedSectionIndex].iris_section,
                            iris_repair: parseInt(value.code),
                          });
                          setSelectedSectionIndex(-1);
                          setIsRepairAdditionOpen(false);
                        }}
                        handleClose={() => {
                          setIsRepairAdditionOpen(false);
                          setSelectedSectionIndex(-1);
                        }}
                        irisCodes={irisRepairs}
                        title="Réparation"
                        buttonText="Confirmer"
                      />
                    )}
                  </>
                )}
              </FieldArray>
              <FieldArray name="spare_part_fitting">
                {({ push }) => (
                  <>
                    {isSparePartScanOpen && (
                      <SparePartSelector
                        enableFittingTypeModal
                        filter={{
                          available_spare_part: true,
                          status: FITTABLE_SPARE_PART_STATUSES,
                          workshop: employee.workshop,
                        }}
                        addSpareParts={(spareParts: SparePartWithFittingType[]) => {
                          spareParts.map((sparePart) => {
                            push({
                              spare_part: sparePart,
                              fitted: true,
                              fitting_type: sparePart?.fitting_type,
                            });
                          });
                        }}
                        closeSparePartSelector={() => {
                          setIsSparePartScanOpen(false);
                        }}
                      />
                    )}
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Box>
    </PageLayout>
  );
};

export default RepairCycleRepairAction;
