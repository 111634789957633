import React, { useState } from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { filterIrisCodeLists, normalizeString } from 'pages/RepairCycleTasks/RepairCycleUtils';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import CompactRadioField from 'components/molecules/CompactRadioField';
import InputWithIcon from 'components/molecules/InputWithIcon';

import { IrisCondition, IrisSymptom } from 'types/iris-codes';

type RepairCycleSymptomsProps = {
  irisConditions: IrisCondition[];
  irisSymptoms: IrisSymptom[];
  handleSymptomsAddition: (defect: SymptomFormValue) => void;
  handleClose: () => void;
};

type SymptomFormValue = {
  symptom: string | null;
  condition: string | null;
};

const initialValues: SymptomFormValue = {
  symptom: null,
  condition: null,
};

const StyledInputWithIcon = styled(InputWithIcon)((props) => ({
  outline: 'none',
  border: '1px solid #D7D7D7',
  borderRadius: props.theme.radii[1],
  height: props.theme.sizes[5],
  backgroundColor: '#F2F2F2',
  margin: 'auto',
  width: '90%',
  position: 'fixed',
  right: 16,
}));

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: ${(props) => props.theme.space[2]}px;
  color: ${(props) => props.theme.colors.turquoise};
`;

const RepairCycleSymptoms: React.FunctionComponent<RepairCycleSymptomsProps> = ({
  irisConditions,
  irisSymptoms,
  handleSymptomsAddition,
  handleClose,
}: RepairCycleSymptomsProps) => {
  const [filterSymptomValue, setFilterSymptomValue] = useState<string>('');
  const [filterConditionValue, setFilterConditionValue] = useState<string>('');

  const [irisSymptomsFiltered, setIrisSymptomsFiletered] = useState<IrisSymptom[]>(irisSymptoms);
  const [irisConditionsFiltered, setIrisConditionsFiltered] =
    useState<IrisCondition[]>(irisConditions);

  const setFilter = (values: SymptomFormValue, filter: string) => {
    if (values.symptom) {
      setFilterSymptomValue(filter);
      setTimeout(() => {
        setIrisConditionsFiltered(filterIrisCodeLists(irisConditions, normalizeString(filter)));
      }, 500);
    } else {
      setFilterConditionValue(filter);
      setTimeout(() => {
        setIrisSymptomsFiletered(filterIrisCodeLists(irisSymptoms, normalizeString(filter)));
      }, 500);
    }
  };

  const validationSchema = yup.object().shape({
    symptom: yup.mixed().required(),
    condition: yup.mixed().required(),
  });
  return (
    <>
      <Box overflow="auto">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSymptomsAddition}
        >
          {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
            <Form>
              <Box position="absolute">
                <HeaderWithArrow
                  title={values.symptom ? 'Condition' : 'Symptôme'}
                  action={values.symptom ? () => setFieldValue('symptom', null) : handleClose}
                />
                <Box
                  bg="white"
                  borderBottom={'1px solid #DBDFE8'}
                  borderTop={'1px solid #DBDFE8'}
                  height={7}
                  display="flex"
                  alignItems="center"
                >
                  <StyledInputWithIcon
                    placeholder="Rechercher"
                    name={'search'}
                    value={values.symptom ? filterSymptomValue : filterConditionValue}
                    onChange={(event) => {
                      setFilter(values, event.target.value);
                    }}
                    type="string"
                    Icon={StyledSearchIcon}
                    noStyling
                  />
                </Box>
              </Box>

              {!values.symptom && (
                <Box pt={128}>
                  {irisSymptomsFiltered?.map((symptom) => (
                    <CompactRadioField
                      name="symptom"
                      key={symptom.code}
                      value={symptom.id.toString()}
                      label={symptom.text}
                    />
                  ))}
                </Box>
              )}
              {values.symptom && (
                <>
                  <Box pt={112} height="calc(100vh - 72px)">
                    {irisConditionsFiltered?.map((condition) => (
                      <CompactRadioField
                        name="condition"
                        key={condition.code}
                        value={condition.id.toString()}
                        label={condition.text}
                      />
                    ))}
                  </Box>
                  <BottomButton disabled={!isValid || !dirty || isSubmitting}>
                    Valider le symptôme
                  </BottomButton>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default RepairCycleSymptoms;
