import { STEPS_LIST } from './stepHelper';

export const setUserHistory = (values, key) => {
  const userHistory = [];
  for (const step of STEPS_LIST) {
    let historyEntry = {};
    if (step.id === 'need_spare_part') {
      historyEntry = {
        stepId: step.id,
        stepQuestion: step.stepQuestion,
        touchedElements: values[key] && values[key]['spare_parts_to_order'],
      };
    } else if (step.id === 'payment') {
      historyEntry = {
        stepId: step.id,
        stepQuestion: step.stepQuestion,
        touchedElements: {
          amount: values[key] && values[key]['amount'],
          payment_mode: values[key] && values[key]['payment_mode'],
        },
      };
    } else if (step.id === 'not_paid') {
      historyEntry = {
        stepId: step.id,
        stepQuestion: step.stepQuestion,
        touchedElements: values[key] && values[key]['why_not_payed'],
      };
    } else if (
      (step.id === 'is_spare_part_used_reparation_done' &&
        values[key] &&
        values[key]['is_spare_part_used_reparation_done']) ||
      (step.id === 'is_spare_part_used_reparation_not_done' &&
        values[key] &&
        values[key]['is_spare_part_used_reparation_not_done'])
    ) {
      historyEntry = {
        stepId: step.id,
        stepQuestion: step.stepQuestion,
        touchedElements: [
          values[key] && values[key]['spare_part_used'] === false
            ? 'Non'
            : values[key] && values[key]['spare_part_used'] === true
            ? 'Oui'
            : values[key] && values[key]['spare_part_used'],
          { Stock: values[key] && values[key]['spare_parts_to_be_added'] },
        ],
      };
    } else {
      historyEntry = {
        stepId: step.id,
        stepQuestion: step.stepQuestion,
        touchedElements:
          values[key] && values[key][step.id] === false
            ? 'Non'
            : values[key] && values[key][step.id] === true
            ? 'Oui'
            : values[key] && values[key][step.id],
      };
    }
    if (historyEntry.touchedElements) {
      userHistory.push(historyEntry);
    }
  }
  return userHistory;
};
