import { FormSkuModel } from 'types/form-sku-models';
import { SkuModelReferential } from 'types/sku-model-referential';
import { SkuSupplierReference } from 'types/sku-supplier-references';

import { normalizeString } from '../RepairCycleUtils';

export const getInitialValues = (
  skuModels: SkuModelReferential[]
): { sku_models: FormSkuModel[] } => {
  return {
    sku_models: skuModels.map((skuModel) => {
      const aswoSupplierReference = skuModel.supplier_references.find(
        (skuModelReference: SkuSupplierReference) => skuModelReference.supplier_name == 'ASWO'
      ) || { price: 0, reference: '', supplier: null, supplier_name: '' };
      return {
        id: skuModel.id,
        thumbnail: skuModel.thumbnail,
        quantity: 0,
        designation: skuModel.designation,
        normalizedDesignation: normalizeString(skuModel.designation),
        checks: [
          {
            id: 1,
            text: 'J’ai testé la pièce seule',
            is_checked: false,
          },
          {
            id: 2,
            text: "J’ai testé la pièce sur l'appareil",
            is_checked: false,
          },
        ],

        supplierReference: aswoSupplierReference,
      };
    }),
  };
};

export type CheckValues = {
  quantity: number;
  sku_model_test: { id: number; text: string; is_checked: boolean }[];
};
export type ArrayHelpers = {
  replace: (
    arg0: number,
    arg1: {
      quantity: number;
      id: number;
      designation: string;
      thumbnail: string;
      checks: { id: number; text: string; is_checked: boolean }[];
      normalizedDesignation: string;
      supplierReference: SkuSupplierReference;
    }
  ) => void;
};

export const handleValidateSparePart = (
  checksValues: CheckValues,
  selectedSkuModel: FormSkuModel,
  values: {
    sku_models: FormSkuModel[];
  },
  arrayHelpers: ArrayHelpers
): void => {
  const selectedSkuModelIndex = values['sku_models'].findIndex(
    (skuModel) => skuModel.id === selectedSkuModel.id
  );
  if (selectedSkuModelIndex > -1) {
    arrayHelpers.replace(selectedSkuModelIndex, {
      quantity: checksValues.quantity,
      id: values['sku_models'][selectedSkuModelIndex].id,
      designation: values['sku_models'][selectedSkuModelIndex].designation,
      thumbnail: values['sku_models'][selectedSkuModelIndex].thumbnail,
      checks: checksValues.sku_model_test,
      normalizedDesignation: values['sku_models'][selectedSkuModelIndex].normalizedDesignation,
      supplierReference: values['sku_models'][selectedSkuModelIndex].supplierReference,
    });
  }
};
