import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import styled from 'styled-components';
import invariant from 'tiny-invariant';
import * as yup from 'yup';

import SelectField from 'pages/Calendar/VisitOpportunityDetail/SelectField';

import PortalComponent from 'components/Portal/PortalComponent';
import SkuLocationSelectionPortal from 'components/SkuLocationSelectionPortal';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import { postSparePartRegularisation } from 'utils/api/api';

type SparePartCreatorProps = {
  sparePartBarcode: string;
  handleConfirmSparePart: (barcode: string, sparePartId: number) => void;
  onClose: () => void;
  onSuccess: (unique_id: string) => void;
  onError: () => void;
};

type SparePartCreatorFormProps = {
  unique_id?: string;
  location?: {
    id: number;
    name: string;
  };
  type?: string;
};

const StyledForm = styled(Form)((_props) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SparePartCreator: React.FunctionComponent<SparePartCreatorProps> = ({
  sparePartBarcode,
  onClose,
  onSuccess,
}: SparePartCreatorProps) => {
  const [isLocationSelectionOpen, setIsLocationSelectionOpen] = useState(false);
  // locationId migth be initially undefined
  const initialValues: SparePartCreatorFormProps = {
    unique_id: sparePartBarcode,
    location: undefined,
    type: 'new',
  };

  const validationSchema = yup.object().shape({
    unique_id: yup.string().required(),
    location: yup
      .object({
        name: yup.string().required(),
        id: yup.number().required(),
      })
      .required(),
  });

  const onSubmit = async (formValues: SparePartCreatorFormProps) => {
    // The validationSchema must prevent theses values
    // from being defined
    invariant(formValues.location !== undefined);
    invariant(formValues.unique_id !== undefined);

    await postSparePartRegularisation({
      location: formValues.location.id,
      unique_id: formValues.unique_id,
      type: formValues.type,
    });

    onSuccess(formValues.unique_id);
  };

  return (
    <>
      <PortalComponent title="Régularisation de stock" open={true} onClose={onClose}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, isSubmitting, isValid, setFieldValue }) => (
            <>
              <SkuLocationSelectionPortal
                handleOpenPortal={() => undefined}
                handleClosePortal={() => {
                  setIsLocationSelectionOpen(false);
                }}
                onConfirm={(skuLocation) => {
                  setFieldValue('location', { name: skuLocation.name, id: skuLocation.id });
                  setIsLocationSelectionOpen(false);
                }}
                isOpen={isLocationSelectionOpen}
              />
              <StyledForm>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  flex={1}
                >
                  <Text variant="subtitleBold">Code QR scanné</Text>
                  <Text variant="body">{values.unique_id}</Text>
                  <Box my={6}>
                    <Text variant="subtitleBold">Emplacement</Text>
                    {values.location ? (
                      <Text variant="body">{values.location.name}</Text>
                    ) : (
                      <Text variant="small" color="grey700">
                        Veuillez Scanner un Emplacement
                      </Text>
                    )}
                    <Button
                      variant="fullTransparent"
                      type="button"
                      onClick={() => setIsLocationSelectionOpen(true)}
                    >
                      {values.location ? "Changer l'emplacement" : 'Scanner un emplacement'}{' '}
                    </Button>
                  </Box>
                  <SelectField
                    width="90%"
                    timeslotsList={[
                      { value: 'new', name: 'Neuve' },
                      { value: 'second_hand', name: 'Occasion' },
                    ]}
                    name="type"
                    placeholder="Type de pièce"
                  />
                </Box>
                <Box display="grid" width="90%" p={2}>
                  <Button
                    data-testid="bottom-button"
                    disabled={!isValid || isSubmitting}
                    type="submit"
                  >
                    Enregistrer la pièce
                  </Button>
                </Box>
              </StyledForm>
            </>
          )}
        </Formik>
      </PortalComponent>
    </>
  );
};

export default SparePartCreator;
