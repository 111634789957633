import React, { useState } from 'react';

import { useQuery } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';

import StateMachineProvider, { StateRenderer } from 'StateMachineProvider';
import { useEmployee } from 'employeeContext';
import { RouteComponentProps } from 'react-router-dom';

import Text from 'components/atoms/Text';

import { CustomerFileDetailed } from 'types/customer-files';

import DebitFailure from './PaymentFormStep/DebitFailure';
import DebitSuccess from './PaymentFormStep/DebitSuccess';
import PaymentInputs from './PaymentFormStep/PaymentInputs';
import PaymentProof from './PaymentFormStep/PaymentProof';
import { PAYMENT_FAILURE, PAYMENT_INPUT, PAYMENT_PROOF, PAYMENT_SUCCESS } from './StateMachine';
import { FAILURE, PROOF, RETRY, SUCCESS } from './StateMachine';
import { paymentMachine } from './StateMachine';

type PaymentMatchParams = {
  customerFileId: string;
  visitId: string;
};
type PaymentProps = RouteComponentProps<PaymentMatchParams>;

const Payment: React.FunctionComponent<PaymentProps> = ({
  match: {
    params: { customerFileId, visitId },
  },
}: PaymentProps) => {
  const [hasStripePaymentMethod, setHasStripePaymentMethod] = useState(false);
  const [setupIntentError, setSetupIntentError] = useState(false);
  const { employee } = useEmployee();

  const {
    data: customerFile,
    loading: customerFileLoading,
    error: customerFileError,
  } = useQuery({
    type: 'getOne',
    resource: 'customer-file',
    payload: {
      id: customerFileId,
    },
  }) as { data: CustomerFileDetailed; loading: boolean; error: boolean };

  const { loading: setupIntentLoading } = useQuery(
    {
      type: 'getOne',
      resource: 'customer-file-setup-intent',
      payload: {
        id: customerFileId,
      },
    },
    {
      onSuccess: ({ data }) => {
        setHasStripePaymentMethod(data.has_payment_method);
      },
      onFailure: (error) => {
        if (error.status !== 404) {
          setSetupIntentError(true);
        } else {
          setHasStripePaymentMethod(false);
        }
      },
    }
  ) as { loading: boolean; error: boolean };

  const hasSavedCard =
    hasStripePaymentMethod ||
    customerFile?.has_payplug_cards ||
    customerFile?.has_valid_stored_cards;

  if (customerFileLoading || (setupIntentLoading && !hasSavedCard)) {
    return (
      <div>
        <Text variant="body">Récupération des données du dossier client en cours</Text>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (customerFileError || (setupIntentError && !hasSavedCard)) {
    return (
      <div>
        <Text variant="body">Erreur lors de la récupération des données du dossier client</Text>
        <ErrorIcon />
      </div>
    );
  }

  return (
    <>
      <StateMachineProvider machine={paymentMachine}>
        <StateRenderer state={PAYMENT_INPUT}>
          {(send) => {
            return (
              <>
                <PaymentInputs
                  hasStripePaymentMethod={hasSavedCard}
                  totalPaid={customerFile?.total_paid}
                  amount={customerFile.balance}
                  customerFileId={customerFileId}
                  visitId={visitId}
                  onSubmit={(data: { payment_amount: number; payment_method: string }) =>
                    send({ type: SUCCESS, data: data })
                  }
                  onFailure={(data: { payment_amount: number; payment_method: string }) =>
                    send({ type: FAILURE, data: data })
                  }
                  employee_id={employee.id}
                  goToPaymentProof={(data: { payment_amount: number; payment_method: string }) =>
                    send({ type: PROOF, data: data })
                  }
                />
              </>
            );
          }}
        </StateRenderer>
        <StateRenderer state={PAYMENT_PROOF}>
          {(send, currentState) => {
            return (
              <>
                <PaymentProof
                  customerFileId={customerFileId}
                  visitId={visitId}
                  onSubmit={() => send(SUCCESS)}
                  onFailure={() => send(FAILURE)}
                  employee_id={employee.id}
                  paymentMethod={currentState.context.payment_method}
                  amount={currentState.context.payment_amount * 100}
                />
              </>
            );
          }}
        </StateRenderer>

        <StateRenderer state={PAYMENT_SUCCESS}>
          {(_, currentState) => {
            return (
              <>
                <DebitSuccess
                  amount={currentState.context.payment_amount * 100}
                  customerFileId={customerFileId}
                  visitId={visitId}
                />
              </>
            );
          }}
        </StateRenderer>
        <StateRenderer state={PAYMENT_FAILURE}>
          {(send, currentState) => {
            return (
              <>
                <DebitFailure
                  amount={currentState.context.payment_amount * 100}
                  customerFileId={customerFileId}
                  visitId={visitId}
                  onChangePaymentMethod={() => send(RETRY)}
                />
              </>
            );
          }}
        </StateRenderer>
      </StateMachineProvider>
    </>
  );
};

export default Payment;
