import React from 'react';

import Text from 'components/atoms/Text';

import { IncentiveLabel } from 'types/incentive-label';

import HelpWantedDescription from './HelpWantedDescription';
import InterventionImpossibleDescription from './InterventionImpossibleDescription';
import NotRepairedDescription from './NotRepairedDescription';

export const LabelDescription = (label: IncentiveLabel) => {
  switch (label) {
    case 'finished':
      return <Text variant="body">Le passage a donné lieu à la réparation de l'appareil.</Text>;
    case 'diag':
      return (
        <Text variant="body">
          Le passage a donné lieu à un diagnostic permettant d'aboutir à la réparation de
          l'appareil, a priori en posant une pièce lors d'un second passage.
        </Text>
      );
    case 'quote-refused':
      return (
        <Text variant="body">
          Suite au diagnostic, le client a refusé le devis proposé par Murfy : le passage n'a pas
          abouti à une réparation. Le passage était un "Diag" et devient un "Refus devis"
        </Text>
      );
    case 'wrong-command':
      return (
        <Text variant="body">
          La pièce que vous avez reçue n'est pas en bon état ou n'est pas celle que vous avez
          demandée, suite à une erreur de commande. Ce type de passage ne devrait pas exister si
          vous prenez le temps de vérifier les pièces que vous devez poser pour les jours à venir et
          prévenez votre binôme.
        </Text>
      );
    case 'superfluous-visit':
      return (
        <Text variant="body">
          Le passage est superflu dans tous les cas où le passage n'a pas fait avancer la
          réparation. Lorsqu'il y a eu plus de 2 passages sur un dossier c'est souvent qu'un des
          passages au moins était superflu. Par exemple lorsque deux passages sont des "Diag" sur un
          même dossier, le premier est considéré "Passage superflu" car le diagnostic était
          manifestement incomplet. Lorsqu'une réouverture se fait à moins d'un mois de la
          réparation, les passages précédents sont également qualifiés de "Passage superflu"
        </Text>
      );
    case 'non_compliant':
      return (
        <Text variant="body">
          Lorsque votre CR ne permet pas de faire avancer le dossier car il n'est pas exploitable
          par le binôme (ex : manque d'information ou photo de la plaque signalétique illisible), le
          passage ne vous rapporte aucun point. Ce sont les cas que nous voulons éliminer : ils sont
          pénalisants pour le client, pour l'entreprise et pour le technicien.
        </Text>
      );
    case 'not-repaired':
      return <NotRepairedDescription />;
    case 'intervention-impossible':
      return <InterventionImpossibleDescription />;
    case 'help-wanted':
      return <HelpWantedDescription />;
    default:
      return null;
  }
};

export default LabelDescription;
