import React from 'react';

import { useGetOne } from 'react-admin';

import { RouteComponentProps } from 'react-router-dom';

import CallButton from 'components/CallButton';
import PaperStripText from 'components/PaperStripText';
import GoBackHeader from 'components/molecules/GoBackHeader';
import PageLayout from 'components/templates/PageLayout';

import { CustomerFile } from 'types/customer-files';

type CustomerDetailsMatchParams = {
  customerFileId: string;
};

type CustomerDetailsProps = RouteComponentProps<CustomerDetailsMatchParams>;

const CustomerDetails: React.FunctionComponent<CustomerDetailsProps> = ({
  match: {
    params: { customerFileId },
  },
}: CustomerDetailsProps) => {
  const { data: customerFile, loading } = useGetOne('customer-file', customerFileId) as {
    data: CustomerFile;
    loading: boolean;
  };

  const renderAddress = () => {
    const address = [
      `${customerFile.street_number} ${customerFile.street}`,
      `${customerFile.city}, ${customerFile.postal_code}`,
    ];
    return <PaperStripText mt={3} title="Adresse" content={address} />;
  };

  return (
    <PageLayout>
      {!loading ? (
        <>
          <GoBackHeader title="Infos client" />
          {renderAddress()}
          {customerFile.extra_information ? (
            <PaperStripText title="Complément d'adresse" content={customerFile.extra_information} />
          ) : null}
          <CallButton
            mt={3}
            text={`Appeler le client ${customerFile.phone}`}
            phone={customerFile.phone}
          />
          <PaperStripText mt={3} title="Référence du dossier" content={customerFile.file_number} />
        </>
      ) : null}
    </PageLayout>
  );
};

export default CustomerDetails;
