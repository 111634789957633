//React imports
import React from 'react';

// MUI imports
import { CircularProgress, Typography } from '@material-ui/core';
//Styles imports
import { makeStyles } from '@material-ui/core/styles';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import InstalledPartSelection from 'pages/RepairReport/components/formComponents/InstalledPartSelection';
//Component imports
import RepairReportButton from 'pages/RepairReport/components/formComponents/RepairReportButton';
import RepairReportEndScreen from 'pages/RepairReport/components/formComponents/RepairReportEndScreen';
import RepairReportExplanationComponent from 'pages/RepairReport/components/formComponents/RepairReportExplanationComponent';
import RepairReportIntermediateValidationScreen from 'pages/RepairReport/components/formComponents/RepairReportIntermediateValidationScreen';
import RepairReportNameplate from 'pages/RepairReport/components/formComponents/RepairReportNameplate';
import RepairReportNotPaid from 'pages/RepairReport/components/formComponents/RepairReportNotPaid';
import RepairReportSparePartCommand from 'pages/RepairReport/components/formComponents/RepairReportSparePartCommand';
import RepairReportTextArea from 'pages/RepairReport/components/formComponents/RepairReportTextArea';
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
// steps imports
import AdditionalInfoStepComponent from 'pages/RepairReport/components/stepComponents/AdditionalInfoStepComponent';
import ApplianceRepairedStep from 'pages/RepairReport/components/stepComponents/ApplianceRepairedStepComponent';
import PaymentProofStepComponent from 'pages/RepairReport/components/stepComponents/PaymentProofStepComponent';
import PaymentStepComponent from 'pages/RepairReport/components/stepComponents/PaymentStepComponent';
import {
  interventionImpossibleReasonArray,
  notRepairedReasonArray,
} from 'pages/RepairReport/services/stepHelper';
import ReportStepStyle from 'pages/RepairReport/styles/ReportStepStyle';

import { CustomerFileDetailed } from 'types/customer-files';

import TimeEvaluation from 'utils/TimeEvaluation';

//Service imports
import { Step, StepId } from '../services/stepHelper';

const useStyles = makeStyles(ReportStepStyle);

type RepairReportStepProps = {
  currentStep: Step;
  customerFile: CustomerFileDetailed;
  dataMissing: string[];
  displayTimeDurationForWorkshop: () => void;
  errorMessage: string;
  formProductIdReference: string;
  formUploadProgress?: number;
  getNextStep: (p?: string | boolean) => void;
  goBackToScanScreen: (url: string) => void;
  handleSubmit: () => void;
  intermediateValidation: () => void;
  isDataMissing: boolean;
  isErrorShown: boolean;
  isLoading: boolean;
  messageForMissingData: string;
  stepSlug: StepId;
  productId: string;
};

const RepairReportStep: React.FunctionComponent<RepairReportStepProps> = ({
  currentStep,
  customerFile,
  dataMissing,
  displayTimeDurationForWorkshop,
  errorMessage,
  formProductIdReference,
  formUploadProgress,
  getNextStep,
  goBackToScanScreen,
  handleSubmit,
  intermediateValidation,
  isDataMissing,
  isErrorShown,
  isLoading,
  messageForMissingData,
  stepSlug,
  productId,
}: RepairReportStepProps) => {
  const classes = useStyles();
  const isReco = customerFile?.category === 'workshop_repair';

  let slugComponent: React.ReactNode = null;
  switch (stepSlug) {
    case 'appliance_nameplate':
      slugComponent = (
        <RepairReportNameplate
          validateFunction={getNextStep}
          formProductIdReference={formProductIdReference}
        />
      );
      break;
    case 'is_appliance_repaired':
      slugComponent = (
        <div className={classes.elementContainer}>
          <ApplianceRepairedStep
            slug={stepSlug}
            validateFunction={getNextStep}
            customerFile={customerFile}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;
    case 'is_spare_part_used_reparation_not_done':
    case 'is_spare_part_used_reparation_done':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Oui`}
            buttonValue={true}
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Non`}
            buttonValue={false}
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;

    case 'which_spare_parts_reparation_done':
    case 'which_spare_parts_reparation_not_done':
      slugComponent = (
        <div className={classes.elementContainer}>
          <div className={classes.sparePartsContainer}>
            <InstalledPartSelection
              customerFileId={customerFile.id}
              formProductIdReference={formProductIdReference}
              getNextStep={getNextStep}
              productId={productId}
            />
          </div>
        </div>
      );
      break;

    case 'intervention_not_done_conclusion_with_sp':
    case 'intervention_not_done_conclusion_without_sp':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Problème de commande`}
            buttonValue="wrong-command"
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Besoin d'une pièce`}
            buttonValue="need-spare-part"
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Aide technique`}
            buttonValue="help-wanted"
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
          {isReco && (
            <RepairReportButton
              stepQuestionId={currentStep.id}
              buttonLabel={`Abandon`}
              buttonValue="abandon"
              validateFunction={getNextStep}
              formProductIdReference={formProductIdReference}
            />
          )}
        </div>
      );
      break;

    case 'appliance_test':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Fonctionne`}
            buttonValue={true}
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
          <RepairReportButton
            stepQuestionId={currentStep.id}
            buttonLabel={`Ne fonctionne pas`}
            buttonValue={false}
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;

    case 'additional_information':
      slugComponent = (
        <div className={classes.elementContainer}>
          <AdditionalInfoStepComponent
            customerFile={customerFile}
            validateFunction={getNextStep}
            intermediateValidation={intermediateValidation}
            step={stepSlug}
            errorMessage={errorMessage}
            isErrorShown={isErrorShown}
            isLoading={isLoading}
            formUploadProgress={formUploadProgress}
            isDataMissing={isDataMissing}
            messageForMissingData={messageForMissingData}
            dataMissing={dataMissing}
            displayTimeDurationForWorkshop={displayTimeDurationForWorkshop}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;
    case 'time-evaluation':
      slugComponent = (
        <div className={classes.elementContainer}>
          <TimeEvaluation
            customerFile={customerFile}
            validateFunction={getNextStep}
            intermediateValidation={intermediateValidation}
            step={stepSlug}
            errorMessage={errorMessage}
            isErrorShown={isErrorShown}
            isLoading={isLoading}
            formUploadProgress={formUploadProgress}
            isDataMissing={isDataMissing}
            messageForMissingData={messageForMissingData}
            dataMissing={dataMissing}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;

    case 'symptoms_diagnosis_not_reparable':
    case 'symptoms_diagnosis_not_repaired':
    case 'symptoms_diagnosis_repaired':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportTextArea
            textAreaLabel={`symptoms`}
            textPlaceHolder={`Quel constat ?`}
            formProductIdReference={formProductIdReference}
          />
          <Typography className={classes.stepQuestion} variant="h6" component="div">
            {`Test & diag`}
          </Typography>
          <RepairReportTextArea
            textAreaLabel={`diagnosis`}
            textPlaceHolder={`Gestes et tests effectués sur l’appareil`}
            formProductIdReference={formProductIdReference}
          />
          <RepairReportValidateButton
            buttonLabel={`Continuer`}
            validateFunction={getNextStep}
            step={stepSlug}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;

    case 'payment':
      slugComponent = (
        <div className={classes.elementContainer}>
          <PaymentStepComponent
            icon={<EuroSymbolIcon className={classes.euroIcon} />}
            handleSubmit={handleSubmit}
            step={stepSlug}
            errorMessage={errorMessage}
            isErrorShown={isErrorShown}
            isLoading={isLoading}
            formUploadProgress={formUploadProgress}
            isDataMissing={isDataMissing}
            messageForMissingData={messageForMissingData}
            dataMissing={dataMissing}
            validateFunction={getNextStep}
          />
        </div>
      );
      break;

    case 'not_paid':
      slugComponent = (
        <div>
          <RepairReportNotPaid />
        </div>
      );
      break;

    case 'need_spare_part':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportSparePartCommand
            formProductIdReference={formProductIdReference}
            validateFunction={getNextStep}
          />
        </div>
      );
      break;

    case 'payment_proof':
      slugComponent = (
        <PaymentProofStepComponent
          stepQuestionId={currentStep.id}
          validateFunction={getNextStep}
          step={stepSlug}
          errorMessage={errorMessage}
          isErrorShown={isErrorShown}
          isLoading={isLoading}
          formUploadProgress={formUploadProgress}
          isDataMissing={isDataMissing}
          messageForMissingData={messageForMissingData}
        />
      );
      break;

    case 'report_done':
      slugComponent = (
        <div className={classes.elementContainer}>
          {isReco ? (
            <RepairReportEndScreen task="workshop_repair" setNextStep={goBackToScanScreen} />
          ) : (
            <RepairReportEndScreen setNextStep={getNextStep} />
          )}
        </div>
      );
      break;

    case 'intermediate_validation_screen':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportIntermediateValidationScreen
            setNextStep={getNextStep}
            customerFileId={customerFile.id}
          />
        </div>
      );
      break;

    case 'not_repaired_reason':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportExplanationComponent
            reasonArray={notRepairedReasonArray}
            slug={stepSlug}
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;

    case 'intervention_impossible_reason':
      slugComponent = (
        <div className={classes.elementContainer}>
          <RepairReportExplanationComponent
            reasonArray={interventionImpossibleReasonArray}
            slug={stepSlug}
            validateFunction={getNextStep}
            formProductIdReference={formProductIdReference}
          />
        </div>
      );
      break;

    default:
      slugComponent = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      );
      break;
  }
  return (
    <div>
      {currentStep &&
        currentStep.id !== 'appliance_nameplate' &&
        currentStep.id !== 'payment' &&
        currentStep.id !== 'payment_proof' &&
        currentStep.id !== 'product_list' &&
        currentStep.id !== 'additional_information' &&
        currentStep.id !== 'intermediate_validation_screen' && (
          <Typography className={classes.stepQuestion} variant="h6" component="div">
            {currentStep.stepQuestion}
          </Typography>
        )}
      <div>{slugComponent}</div>
    </div>
  );
};

export default RepairReportStep;
