import React from 'react';

import { Field, FieldProps, FormikHelpers, FormikValues } from 'formik';

import { ProductModel } from '../../../types/product-models';
import { getProductModels } from '../../../utils/api/api';
import AutocompleteInput from '../AutocompleteInput';

type OptionType = { label: string; value: string };
type OptionsType = Array<OptionType>;

type ProductReferenceAutocompleteProps = {
  fieldName: string;
  brandField: 'name' | 'id';
};

const ProductReferenceAutocomplete: React.FunctionComponent<ProductReferenceAutocompleteProps> = ({
  fieldName,
  brandField,
}) => {
  const [referenceList, setReferenceList] = React.useState<OptionsType>([]);
  const [productModelList, setProductModelList] = React.useState<ProductModel[]>([]);
  const [filterText, setFilterText] = React.useState<string>('');

  React.useEffect(() => {
    const fetchData = async () => {
      const productModelList: ProductModel[] = await getProductModels({
        q: filterText.length > 2 && filterText.toUpperCase().replace(/\s/g, ''),
      });
      setProductModelList(productModelList);
      setReferenceList(
        productModelList.map((model: ProductModel) => ({
          label: model.reference,
          value: model.id.toString(),
        }))
      );
    };
    fetchData();
  }, [filterText]);

  const getBrand = (modelId: string) => {
    return productModelList.find((item) => item.id === parseInt(modelId))?.brand
      ? productModelList.find((item) => item.id === parseInt(modelId))?.brand[brandField]
      : '';
  };

  const getProductType = (modelId: string) => {
    return productModelList.find((item) => item.id === parseInt(modelId))?.product_type?.id;
  };

  const getReference = (value: string) => {
    return productModelList.find((item) => item.id === parseInt(value))?.reference || value;
  };

  const isItemInReferenceList = (value: string) =>
    value && referenceList.find((item) => item.value === value);

  const changeFormFields = (form: FormikHelpers<FormikValues>, event: OptionType) => {
    form.setFieldValue(fieldName, getReference(event?.value));
    if (isItemInReferenceList(event?.value)) {
      form.setFieldValue('productBrand', getBrand(event?.value));
      form.setFieldValue('productType', getProductType(event?.value));
    }
  };

  return (
    <Field key="reference" name={fieldName}>
      {({ field: { name }, form }: FieldProps) => {
        return (
          <AutocompleteInput
            name={name}
            value={
              referenceList.find((item) => item.value === form.values[fieldName]) ||
              ({ label: form.values[fieldName], value: form.values[fieldName] } as OptionType)
            }
            isClearable
            options={referenceList}
            onChange={(event) => changeFormFields(form, event)}
            placeholder="Référence"
            onInputChange={(value) => setFilterText(value.length > 2 ? value.substring(0, 3) : '')}
            formatCreateLabel={(inputValue) =>
              filterText.length < 3 ? 'Entrez 3 caractères min.' : `Créer ${inputValue}`
            }
            noOptionsMessage={() => 'Commencez à écrire'}
          />
        );
      }}
    </Field>
  );
};

export default ProductReferenceAutocomplete;
