// React imports
import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-admin';

import { LinearProgress } from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';

import { useField } from 'react-final-form';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';

// Component imports
import RepairReportStep from 'pages/RepairReport/components/ReportStep';
import { formatData } from 'pages/RepairReport/services/formatData';
// Service imports
import { INITIAL_STEP, STEPS_LIST, Step, StepId } from 'pages/RepairReport/services/stepHelper';
import ReportStepPlayerStyle from 'pages/RepairReport/styles/ReportStepsPlayerStyle';

import { CustomerFileDetailed } from 'types/customer-files';

import ReportStepsHeader from './ReportStepsHeader';

const useStyles = makeStyles(ReportStepPlayerStyle);

const MISSING_DATA_MESSAGE =
  "Vous devez recommencer la saisie du compte-rendu, des informations essentielles n'ont pas été enregistrées :";

type PaymentForm = {
  amount: number;
  not_payed: boolean;
  payment_mode: string;
};

type ReportsStepsPlayerProps = {
  customerFileId: number;
  error: string;
  formUploadProgress?: number;
  isErrorShown: boolean;
  isLoading: boolean;
  isPosted: boolean;
  postForm: (data: Record<string, unknown>) => void;
  resetIsPosted: () => void;
  stepSlug: StepId;
  visitId: string;
  productId: string;
};

const ReportStepsPlayer: React.FunctionComponent<ReportsStepsPlayerProps> = ({
  customerFileId,
  error,
  formUploadProgress,
  isErrorShown,
  isLoading,
  isPosted,
  postForm,
  resetIsPosted,
  stepSlug,
  visitId,
  productId,
}: ReportsStepsPlayerProps) => {
  const { data: customerFile, loading: customerFileIsLoading } = useQuery({
    type: 'getOne',
    resource: 'customer-file',
    payload: {
      id: customerFileId,
      include: ['sku_demands'],
    },
  }) as { data: CustomerFileDetailed; loading: boolean };

  const classes = useStyles();

  const [isDataMissing, setIsDataMissing] = useState(false);
  const [dataMissing, setDataMissing] = useState<string[]>([]);
  const currentProduct = customerFile?.products.find(
    (product) => product.id === parseInt(productId)
  );
  const [isGetNextForReco, setIsGetNextForReco] = useState(false);

  const formProductIdReference = `product_${currentProduct?.id}`;
  const currentStep = STEPS_LIST.find((element) => element.id === stepSlug) as Step;
  const history = useHistory();

  const formValues = useFormState().values;
  const countValues = Object.keys(formValues).length;

  const displayTimeDurationForWorkshop = () => {
    setIsGetNextForReco(true);
  };

  useEffect(
    () => {
      // If no slug, it should be an init iteration
      if (
        !countValues &&
        stepSlug !== 'product_list' &&
        stepSlug !== 'appliance_nameplate' &&
        stepSlug !== 'is_appliance_repaired' &&
        stepSlug !== 'payment' &&
        stepSlug !== 'new_product'
      ) {
        setNextStep(INITIAL_STEP);
      }
      if (
        stepSlug === 'payment' ||
        stepSlug === 'additional_information' ||
        stepSlug === 'payment_proof' ||
        stepSlug === 'time-evaluation' ||
        stepSlug === 'dashboard'
      ) {
        if (isPosted) {
          goToNextStep(true);
        }
      }
      if (
        stepSlug === 'additional_information' &&
        customerFile &&
        customerFile.category === 'workshop_repair' &&
        isGetNextForReco
      ) {
        goToNextStep('workshop');
      }

      // if form can't be posted
    }, // eslint-disable-next-line
    [stepSlug, isPosted, isGetNextForReco]
  );

  // Will push the new step slug in the URL
  const setNextStep = (nextStepSlug: StepId = INITIAL_STEP) => {
    history.push(
      `/compte-rendu/${customerFileId}/${nextStepSlug}?visit=${visitId}&productId=${productId}`
    );
  };

  // resetting progress when in payment step
  const {
    input: { onChange: resetPaymentStep },
  } = useField(formProductIdReference + '.not_payed');
  const {
    input: { onChange: softResetStepReasonExplanation },
  } = useField(formProductIdReference + '.next_operation_explanation');
  const {
    input: { onChange: softResetStepIsNextInterventionLong },
  } = useField(formProductIdReference + '.is_next_operation_long_duration');

  // The browser will handle the goBack method
  const setPreviousStep = () => {
    if (stepSlug === 'payment' && formValues['payment'] && formValues['payment'].not_payed) {
      // Special case during the payment steps reset
      resetPaymentStep('');
    } else if (
      stepSlug === 'additional_information' &&
      formValues[formProductIdReference].next_operation_explanation &&
      formValues[formProductIdReference].next_operation_explanation === 'other'
    ) {
      softResetStepReasonExplanation('');
    } else if (
      stepSlug === 'additional_information' &&
      formValues[formProductIdReference].is_next_operation_long_duration
    ) {
      softResetStepIsNextInterventionLong('');
      softResetStepReasonExplanation('');
    } else {
      history.goBack();
      setIsDataMissing(false);
      setIsGetNextForReco(false);
    }
  };

  const goBackToScanScreen = (screenUrl: string) => {
    resetIsPosted();
    history.push(`/${screenUrl}`);
  };

  const handleSubmit = () => {
    const formattedInputs = formatData(formValues, visitId, customerFileId);
    postForm(formattedInputs);
  };

  const {
    input: { onChange: onChangeProductTypeSetter },
  } = useField(formProductIdReference + '.product_type');

  const intermediateValidation = () => {
    onChangeProductTypeSetter(currentProduct?.product_type);
    const productFormValues = formValues[formProductIdReference];
    const itemsMissing = [];
    const visit_outcome =
      productFormValues.intervention_not_done_conclusion_without_sp ||
      productFormValues.intervention_not_done_conclusion_with_sp ||
      productFormValues.is_appliance_repaired ||
      productFormValues.is_appliance_repaired;
    if (visit_outcome) {
      goToNextStep();
    } else {
      if (!visit_outcome) {
        itemsMissing.push('Issue intervention');
      }
      setDataMissing(itemsMissing);
      setIsDataMissing(true);
    }
  };

  const selectNextStep = (
    currentStepValue: string | boolean | PaymentForm,
    potentialValue?: string | boolean
  ): StepId | null => {
    switch (stepSlug) {
      case 'dashboard':
        // The report has just ben posted and we want a redirection to the report done screen
        // We should do a proper redirection after the API call has succeeded
        if (potentialValue === true) {
          return 'report_done';
        }
        return null;

      case 'appliance_nameplate':
        return 'is_appliance_repaired';

      case 'is_appliance_repaired':
        if (currentStepValue === 'not-repaired') {
          return 'symptoms_diagnosis_not_reparable';
        } else if (currentStepValue === 'finished') {
          return 'symptoms_diagnosis_repaired';
        } else if (
          currentStepValue === 'not-processed' ||
          currentStepValue === 'intervention-impossible'
        ) {
          return 'additional_information';
        } else {
          return 'symptoms_diagnosis_not_repaired';
        }

      case 'symptoms_diagnosis_repaired':
        return 'is_spare_part_used_reparation_done';

      case 'symptoms_diagnosis_not_repaired':
        return 'is_spare_part_used_reparation_not_done';

      case 'is_spare_part_used_reparation_done':
        if (currentStepValue) {
          return 'which_spare_parts_reparation_done';
        } else {
          return 'appliance_test';
        }

      case 'which_spare_parts_reparation_done':
        return 'appliance_test';

      case 'is_spare_part_used_reparation_not_done':
        if (currentStepValue) {
          return 'which_spare_parts_reparation_not_done';
        } else {
          return 'intervention_not_done_conclusion_without_sp';
        }

      case 'which_spare_parts_reparation_not_done':
        return 'intervention_not_done_conclusion_with_sp';

      case 'intervention_not_done_conclusion_with_sp':
      case 'intervention_not_done_conclusion_without_sp':
        if (currentStepValue === 'wrong-command') {
          return 'additional_information';
        } else {
          return 'need_spare_part';
        }

      case 'symptoms_diagnosis_not_reparable':
      case 'appliance_test':
      case 'need_spare_part':
        return 'additional_information';

      case 'additional_information':
        if (currentStepValue === 'workshop') {
          return 'time-evaluation';
        } else {
          return 'intermediate_validation_screen';
        }

      case 'time-evaluation':
        if (currentStepValue) return 'dashboard';
        break;

      case 'payment':
        return 'dashboard';

      case 'payment_proof':
        if (currentStepValue) {
          return 'dashboard';
        } else {
          return null;
        }
      case 'intermediate_validation_screen':
        return 'dashboard';

      case 'not_repaired_reason':
        return 'symptoms_diagnosis_not_reparable';

      case 'intervention_impossible_reason':
        return 'additional_information';

      default:
        // Useful snippet to make sure no stepSlug has been forgotten
        // It should never execute
        // ((_: never): void => {})(stepSlug);
        return null;
    }
    return null;
  };

  // This is our player : It know where we are and where we go
  const goToNextStep = (potentialValue?: string | boolean) => {
    const repairReportData = formValues;
    const currentStepValue = potentialValue ?? (repairReportData[stepSlug] as string | PaymentForm);

    const nextStepSlug = selectNextStep(currentStepValue, potentialValue);

    if (nextStepSlug === null) {
      // Let's wait useEffect to set the default route
      return LinearProgress;
    }

    setNextStep(nextStepSlug);
  };

  if (customerFileIsLoading) {
    return <div className={classes.reportContainer}></div>;
  }

  return (
    <div className={classes.reportContainer}>
      {currentStep.id !== 'intermediate_validation_screen' && (
        <ReportStepsHeader
          customerFile={customerFile}
          stepSlug={stepSlug}
          setPreviousStep={setPreviousStep}
          currentStep={currentStep}
        />
      )}
      {isLoading && !isErrorShown && (
        <SnackbarContent
          id="progress-snackbar"
          className={classes.snackbarSubmission}
          message={
            formUploadProgress
              ? `Envoi du CR en cours: ${formUploadProgress}%`
              : `Envoi du CR en cours, veuillez patienter`
          }
        />
      )}
      <div>
        {!customerFileIsLoading && (
          <RepairReportStep
            customerFile={customerFile}
            currentStep={currentStep}
            getNextStep={goToNextStep}
            handleSubmit={handleSubmit}
            errorMessage={error}
            isErrorShown={isErrorShown}
            isLoading={isLoading}
            formUploadProgress={formUploadProgress}
            messageForMissingData={MISSING_DATA_MESSAGE}
            isDataMissing={isDataMissing}
            dataMissing={dataMissing}
            displayTimeDurationForWorkshop={displayTimeDurationForWorkshop}
            goBackToScanScreen={goBackToScanScreen}
            intermediateValidation={intermediateValidation}
            formProductIdReference={formProductIdReference}
            stepSlug={stepSlug}
            productId={productId}
          />
        )}
      </div>
    </div>
  );
};

export default ReportStepsPlayer;
