// React imports
import React, { useEffect, useState } from 'react';

// Icons
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
// Component imports
// Style imports
import { makeStyles } from '@material-ui/core/styles';

import { useModals } from 'modalsContext';
import { useField, useForm } from 'react-final-form';

import { DONE } from 'pages/RepairCycleTasks/RepairCycleUtils';
import RepairReportCheckboxStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportCheckboxStyle';

import SparePartSelector from 'components/SparePartSelector';

import { Sku, SparePartWithFittingType } from 'types/sku';
import { SparePartFitting } from 'types/spare-part-fittings';

import { getSparePartsList } from 'utils/api/api';

const useStyles = makeStyles(RepairReportCheckboxStyle);

type InstalledPartSelectionProps = {
  customerFileId: number;
  formProductIdReference: string;
  getNextStep: () => void;
  productId: string;
};

const InstalledPartSelection: React.FunctionComponent<InstalledPartSelectionProps> = ({
  customerFileId,
  formProductIdReference,
  getNextStep,
  productId,
}: InstalledPartSelectionProps) => {
  const classes = useStyles();
  const { showModal, closeModal } = useModals();
  const form = useForm();
  const formdata = form.getState().values;

  const [isSparePartSelectionOpen, setIsSparePartSelectionOpen] = useState(true);

  const {
    input: { onChange: onSparePartAddition },
  } = useField(formProductIdReference + '.spare_part_used'); // ref-designation-id / string[] / ???
  const {
    input: { onChange: onTotalSkuAddition },
  } = useField(formProductIdReference + '.total_spare_part_in_file'); // spare parts / Sku[]
  const {
    input: { onChange: onEmbeddedInstallation },
  } = useField(formProductIdReference + '.spare_parts_to_be_added'); // ref-designation-id / string[] / ???
  const {
    input: { onChange: onSkuAddition },
  } = useField(formProductIdReference + '.sku_list_id_used'); // spare part ids / number[]

  const {
    input: { onChange: onSparePartFitting },
  } = useField('spare_part_fitted');

  const getDescriptionSparePart = (sparePart: Sku) =>
    `${sparePart.sku_model.sku_reference} - ${sparePart.sku_model.designation}-sku-id:${sparePart.id}`;

  const addSpareParts = (spareParts: SparePartWithFittingType[]) => {
    let sparePartUsed: string[] = [];
    let skuListIdUsed: number[] = [];
    let sparePartsToBeAdded: string[] = [];
    for (const part of spareParts) {
      skuListIdUsed = [...skuListIdUsed, part.id];
      if (isFromFile(part)) {
        sparePartUsed = [...sparePartUsed, getDescriptionSparePart(part)];
      } else {
        sparePartsToBeAdded = [...sparePartsToBeAdded, getDescriptionSparePart(part)];
      }
    }
    onEmbeddedInstallation(sparePartsToBeAdded);
    onSkuAddition(skuListIdUsed);
    onSparePartAddition(sparePartUsed);

    const existingSparePartFittings = formdata?.spare_part_fitted
      ? [
          ...formdata?.spare_part_fitted?.filter(
            (fittedSparePart: SparePartFitting) => fittedSparePart.product !== parseInt(productId)
          ),
        ]
      : [];

    onSparePartFitting([
      ...existingSparePartFittings,
      ...spareParts.map((sparePart) => {
        return {
          fitted: true,
          spare_part: sparePart,
          product: productId,
          state: DONE,
          fitting_type: sparePart.fitting_type,
        };
      }),
    ]);

    getNextStep();
  };

  const isFromFile = (sparePart: SparePartWithFittingType) => {
    return (
      formdata[formProductIdReference] &&
      formdata[formProductIdReference].total_spare_part_in_file.find(
        (item: Sku) => item.id === sparePart.id
      )
    );
  };

  useEffect(() => {
    const fetchSpareParts = async () => {
      try {
        const spareParts = await getSparePartsList({
          repair_report_customer_file_id: customerFileId,
        });
        onTotalSkuAddition(spareParts);
      } catch (e) {
        console.error("Can't fetch spare part list" + e);
      }
    };
    fetchSpareParts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFileId]);

  const toggleReturnButtonModal = () => {
    showModal({
      modalToShow: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Quitter la sélection ?',
        content: 'les pièces sélectionnées seront perdues',
        onCancel: closeModal,
        onConfirm: () => {
          closeModal();
          onSparePartAddition([]);
          onSkuAddition([]);
          let existingSparePartFittings: SparePartFitting[] = [];
          if (formdata?.spare_part_fitted) {
            existingSparePartFittings = [
              ...formdata?.spare_part_fitted?.filter(
                (fittedSparePart: SparePartFitting) =>
                  fittedSparePart.product !== parseInt(productId)
              ),
            ];
          }

          onSparePartFitting([...existingSparePartFittings]);
          setIsSparePartSelectionOpen(false);
        },
      },
    });
  };

  return (
    <FormControl component="fieldset" className={classes.formContainer}>
      <FormGroup className={classes.formContainer}>
        {isSparePartSelectionOpen && (
          <SparePartSelector
            closeOnSuccess={false}
            enableFittingTypeModal
            filter={{
              enable_employee_filter: 'on',
              status: 'repairman_stock',
            }}
            addSpareParts={(spareParts: SparePartWithFittingType[]) => {
              addSpareParts(spareParts);
            }}
            closeSparePartSelector={() => toggleReturnButtonModal()}
          />
        )}
      </FormGroup>
    </FormControl>
  );
};

export default InstalledPartSelection;
