import React, { useEffect } from 'react';

import { ListToolbar, useListController } from 'react-admin';

import IncentiveSystemFilter from 'pages/IncentiveSystem/Components/IncentiveSystemFilter';
import { listRepairReportLabel } from 'pages/IncentiveSystem/Services/LabelList';

import IncentiveSystemDetails from './IncentiveSystemDetails';

const IncentiveSystemDetailsComponentList = (props) => {
  const controllerProps = useListController(props);

  const { data, ids, setFilters, filterValues } = controllerProps;
  useEffect(() => {
    setFilters({});
    return () => {
      setFilters({});
    };
  }, []);
  return (
    <div>
      <ListToolbar
        filters={
          <IncentiveSystemFilter
            setFilters={setFilters}
            repairmanId={props.repairmanId}
            filterValues={filterValues}
            listRepairReportLabel={listRepairReportLabel}
          />
        }
        {...controllerProps}
        actions={props.actions}
        permanentFilter={props.filter}
      />
      <IncentiveSystemDetails ids={ids} data={data} {...props} />
    </div>
  );
};

export const IncentiveSystemDetailsList = (props) => (
  <IncentiveSystemDetailsComponentList
    {...props}
    repairmanId={props.repairmanId}
    basePath=""
    filter={{ repairman_id: props.repairmanId }}
    resource="incentives"
    perPage={300}
  />
);
