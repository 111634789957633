import React from 'react';

import HomeIcon from '@material-ui/icons/Home';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ReorderIcon from '@material-ui/icons/Reorder';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import Box from 'components/atoms/Box';

import NavButton from '../BottomBar/NavButton';

const BottomBar: React.FunctionComponent = () => {
  return (
    <Box
      bg="white"
      py={1}
      display="flex"
      justifyContent="space-around"
      borderColor="grey500_2"
      borderTopWidth="1px"
      borderTopStyle="solid"
      position="absolute"
      bottom={0}
      width="100%"
    >
      <NavButton to="/workshop/transfer/" icon={<ImportExportIcon />}>
        Transfert
      </NavButton>
      <NavButton to="/workshop/inventory" icon={<YoutubeSearchedForIcon />}>
        Inventaire
      </NavButton>
      <NavButton to="/workshop/product-reception/" icon={<HomeIcon />}>
        Réception
      </NavButton>
      <NavButton to="/workshop/stock/" icon={<ReorderIcon />}>
        Stock
      </NavButton>
      <NavButton to="/workshop/expeditions/" icon={<LocalShippingIcon />}>
        Expéditions
      </NavButton>
    </Box>
  );
};

export default BottomBar;
