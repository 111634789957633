// React imports
import React, { useEffect, useState } from 'react';

import { useMutation } from 'react-admin';

// Component imports
import { Button, SwipeableDrawer, Typography } from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// Style imports
import { makeStyles } from '@material-ui/core/styles';
// Material-ui imports
import ImportExportIcon from '@material-ui/icons/ImportExport';

import animationData from 'assets/check.json';
import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';
import Quagga from 'quagga';
import { useForm } from 'react-final-form';
// import lottie animations
import Lottie from 'react-lottie';

import SelectLocation from 'pages/Store/components/SelectLocation';
import TransferProductAdditionStyle from 'pages/Transfer/styles/TransferProductAdditionStyle';

const useCreationStyles = makeStyles(TransferProductAdditionStyle);

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function TransferNewLocation(props) {
  const creationClasses = useCreationStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [error, setError] = useState('');
  const form = useForm();
  const [locations, setLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [isError, setIsError] = useState(false);
  const { employee } = useEmployee();
  var formdata = form.getState().values; // accesses directly to the form values

  const stopCamera = () => {
    if (Quagga.CameraAccess.getActiveTrack()) {
      Quagga.offDetected();
      Quagga.offProcessed();
      Quagga.stop();
    }
  };

  useEffect(() => {
    // on opening start the barcode scanner once
    if (formdata['new_location']) {
      // stop scanner instance if there is one currently running
      stopCamera();
    }
  });

  const [getLocationList] = useMutation(
    {
      type: 'getList',
      resource: 'location',
      payload: {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'id', order: 'DESC' },
        filter: {
          workshop: employee.workshop,
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        setLocations(data);
      },
    }
  );

  useEffect(() => {
    getLocationList();
  }, [formdata, getLocationList]);

  const getIdsFromList = (list) => {
    let idsList = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i]) {
        idsList.push(list[i].id);
      }
    }
    return idsList;
  };

  const handleSubmitLocationChange = () => {
    if (formdata['addProduct']) {
      dataProvider
        .updateMany('products', {
          ids: getIdsFromList(formdata['addProduct']),
          data: { location: formdata['new_location'] },
        })
        .then(() => {
          props.setNextStep('transfer-successful');
          setErrorMessage();
          setIsError(false);
          form.reset();
        })
        .catch((e) => {
          setErrorMessage(e);
          setIsError(true);
        });
    }
  };

  const handleResetLocation = () => {
    form.mutators.addLocation({ location: '' });
  };

  const handleChangeLocation = (selectedLocationId) => {
    form.mutators.addLocation({ location: selectedLocationId });
  };

  const handleConfirmBarcode = (barcode) => {
    const path = processPath(barcode);
    const location = getLocationFromPath(path);
    if (location) {
      form.mutators.addLocation({ location: location.id });
    } else {
      setError(`Emplacement non trouvé pour le code barres ${barcode}`);
    }
  };

  const processPath = (barcode) => {
    let path = barcode;
    path = path.split('/').join('.');
    path = path.split('-').join('_');
    return path;
  };

  const getLocationFromPath = (stringPath) => {
    return locations.find((el) => el.path === stringPath);
  };

  const toggleDrawer = () => {
    // stop scanner instance if there is one currently running
    stopCamera();
    setError('');
    setDrawerOpen(!drawerOpen);
  };

  return formdata['addProduct'] && formdata['addProduct'].length > 0 ? (
    <>
      <div className={creationClasses.drawerButton}>
        <Button onClick={toggleDrawer}>
          <span className={creationClasses.drawerButtonText}>DEPLACER VERS </span>
          <ImportExportIcon className={creationClasses.drawerIcon} />
        </Button>
      </div>
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <div className={creationClasses.drawerContainer}>
          <div className={creationClasses.drawerButtonTop}>
            <Button onClick={toggleDrawer}>
              <span className={creationClasses.drawerButtonText}>DEPLACER VERS </span>
              <ImportExportIcon className={creationClasses.drawerIcon} />
            </Button>
          </div>
          {formdata['new_location'] ? (
            <div className={creationClasses.confirmationContainer}>
              <Lottie options={animationOptions} height={130} width={130} />
              <div className={creationClasses.newLocationName}>
                {locations.find((el) => el.id === formdata['new_location']).name}
              </div>
              <Button onClick={handleResetLocation}>
                <Typography variant="h5">Modifier l'emplacement</Typography>
              </Button>
              <Button className={creationClasses.submitButton} onClick={handleSubmitLocationChange}>
                {isError ? 'Réessayer' : 'Confirmer'}
              </Button>
              {isError && (
                <SnackbarContent
                  className={creationClasses.snackbarContent}
                  message={
                    <span className={creationClasses.snackBarText}>
                      {`Une erreur est survenue, votre transfert n'a pas été sauvegardé. ${errorMessage}`}
                    </span>
                  }
                />
              )}
            </div>
          ) : (
            <SelectLocation
              locations={locations}
              handleConfirmBarcode={handleConfirmBarcode}
              error={error}
              handleChangeLocation={handleChangeLocation}
            />
          )}
        </div>
      </SwipeableDrawer>
    </>
  ) : null;
}
