import React, { useEffect } from 'react';

import SimpleProductDetail from '../../../../components/organisms/SimpleProductDetail';
import { Product } from '../../../../types/products';
import { useProductModel } from '../../../../utils/api/api';

const ProductDetailForDashboard = ({
  product,
  active,
}: {
  product: Product;
  active: boolean;
}): JSX.Element | null => {
  const productModel = useProductModel(product.product_model);

  useEffect(() => {
    let isMounted = true;

    if (productModel && isMounted) {
      if (productModel.product_type) {
        product.product_type = productModel.product_type.name;
      }
      if (productModel.brand) {
        product.brand = productModel.brand.name;
      }
    }

    return () => {
      isMounted = false;
    };
  }, [product, productModel]);

  if (!active) return null;

  return <SimpleProductDetail product={product} />;
};

export default ProductDetailForDashboard;
