/* eslint-disable @typescript-eslint/ban-types */
export interface PrequalReportStateSchema {
  states: {
    applianceNameplate: {};
    symptomsAndDiagnostic: {};
    additionalInformation: {};
    sparePartsNeededChoice: {};
    isRepairableChoice: {};
    sumbitting: {};
    success: {};
    sparePartsNeed: {};
    notRepairableReason: {};
  };
}

export type PrequalReportState = keyof PrequalReportStateSchema['states'];
// Events definition
export const IS_REPAIRABLE = 'IS_REPAIRABLE';
export const IS_NOT_REPAIRABLE = 'IS_NOT_REPAIRABLE';
export const SPARE_PARTS_ARE_NEEDED = 'SPARE_PARTS_ARE_NEEDED';
export const SPARE_PARTS_ARE_NOT_NEEDED = 'SPARE_PARTS_ARE_NOT_NEEDED';
export const VALIDATE_SYMPTOMS_AND_DIAGNOSTIC = 'VALIDATE_SYMPTOMS_AND_DIAGNOSTIC';
export const VALIDATE_NAMEPLATE = 'VALIDATE_NAMEPLATE';
export const VALIDATE_SPARE_PART_NEED = 'VALIDATE_SPARE_PART_NEED';
export const NOT_REPAIRABLE_REASON = 'NOT_REPAIRABLE_REASON';
export const BACK = 'BACK';
export const SUBMIT = 'SUBMIT';

export type IsReparaibleEvent = {
  type: typeof IS_REPAIRABLE | typeof IS_NOT_REPAIRABLE;
  data: {
    is_repairable: boolean;
  };
};
export type NotRepairableReasonEvent = {
  type: typeof NOT_REPAIRABLE_REASON;
  data: {
    irreparable_reason: string;
  };
};

export type SparePartsEvent = {
  type: typeof SPARE_PARTS_ARE_NEEDED | typeof SPARE_PARTS_ARE_NOT_NEEDED;
  data: {
    spare_parts_needed: boolean;
  };
};
export type ValidateSymptomsAndDiagnosticEvent = {
  type: typeof VALIDATE_SYMPTOMS_AND_DIAGNOSTIC;
  data: {
    symptoms: string;
    diagnostic: string;
  };
};

export type ValidateNameplateEvent = {
  type: typeof VALIDATE_NAMEPLATE;
  data: {};
};

export type ValidateSparPartsNeedeEvent = {
  type: typeof VALIDATE_SPARE_PART_NEED;
  data: {};
};

export type BackEvent = {
  type: typeof BACK;
  data: {};
};

export type SubmitEvent = {
  type: typeof SUBMIT;
  data: {
    additional_information: string;
  };
};
export type PrequalReportEvent =
  | IsReparaibleEvent
  | SparePartsEvent
  | ValidateSymptomsAndDiagnosticEvent
  | ValidateNameplateEvent
  | BackEvent
  | SubmitEvent
  | ValidateSparPartsNeedeEvent
  | NotRepairableReasonEvent;

export interface PrequalReportContext {
  symptoms: string;
  intervention: string;
  diagnostic: string;
  need_skus: boolean | null;
  is_repairable: boolean | null;
  additional_information: string;
  irreparable_reason: string | null;
}
