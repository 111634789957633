import dayjs from 'dayjs';

import { IrisCode, IrisCondition, IrisDefect, IrisSection, IrisSymptom } from 'types/iris-codes';

export const ASWO = 'ASWO';
export const ASWO_SUPPLIER_ID = '3';
export const CLOSED = 'closed';

export const REPAIRMAN = 'repairman';

// History element types
export const CHECKLIST = 'product_check_list_report';
export const CLEANING = 'cleaning_task';
export const COLLECTION_ITEM = 'delivery_item';
export const COMMENT = 'comment';
export const DIAG = 'diagnostic';
export const DISASSEMBLY = 'disassembly';
export const REPAIR_REPORT = 'repair_report';
export const REPAIR_ACTION = 'repair_action';
export const SECTION_REPAIR = 'section_repair';
export const SKU_DEMAND = 'sku_demand';
export const SPARE_PART_FITTING = 'spare_part_fitting';
export const SPARE_PART_REGISTRATION_REPORT = 'spare_part_registration_report';
export const PHOTO = 'photos';
export const PRODUCT_CHECK_LIST_REPORT = 'product_check_list_report';
export const SCRAP = 'scrap';
export const REFURBISHING_DECISION = 'refurbishing_decision';

export const SCRAP_PRODUCT = 'SCRAP_PRODUCT';
export const ACCEPT_COLLECTION = 'ACCEPT_COLLECTION';
export const REFUSE_COLLECTION = 'REFUSE_COLLECTION';

// Checklist types
export const SECTION_WEAR = 'section_wear';
export const SECTION_PRESENCE = 'section_presence';
export const OVERALL_CONDITION = 'overall_condition';
export const SHIPPING_CHECKS = 'shipping';
export const OVERALL_APPAREANCE = 'overall_appearance';
export const SPARE_PART_CLEANLINESS = 'spare_part_cleanliness';
export const WIRES_AND_PIPES = 'wires_and_pipes';
export const STARTING_UP = 'starting_up';
export const SPARE_PART_REGISTRATION_CHECKLIST = 'spare_part_registration';

// Task status
export const DONE = 'done';

// Repair report outcomes
export const OPERATIONAL = 'operational';
export const VALO = 'valo'; // Not supported in backend yet
export const IRREPARABLE = 'irreparable';
export const REPAIR_OUTCOMES = [
  {
    id: 1,
    name: 'Machine fonctionnelle',
    value: OPERATIONAL,
  },
  {
    id: 2,
    name: 'Valorisation',
    value: IRREPARABLE,
  },
];

export const PACKED = 'packed';
export const SENT = 'sent';

// Typeform form ids
export const REPAIR_REPORT_QUALITY_PICTURE_FORM_ID = 'Xvw2KGWR';
export const WEAR_CHECK_PICTURE_FORM_ID = 'Rd2BE6wr';

// spare part status

export const SP_PACKED = 'packed';
export const RECEIVED_TECH = 'received_tech';
export const RETURN_TO_WAREHOUSE = 'return_to_warehouse';
export const LOST = 'lost';
export const RECEIVED_WRONG_TECH = 'received_wrong_tech';

export const FITTABLE_SPARE_PART_STATUSES = [
  SP_PACKED,
  RECEIVED_TECH,
  RECEIVED_WRONG_TECH,
  RETURN_TO_WAREHOUSE,
  LOST,
];

export const filterIrisCodeLists = (
  irisCodeList: IrisCondition[] | IrisDefect[] | IrisSection[] | IrisSymptom[],
  normalizedFilterValue: string
): IrisCode[] => {
  return irisCodeList.filter((iris) => iris.normalizedText.includes(normalizedFilterValue));
};

export const normalizeIrisCodes = (irisCodeList: IrisCode[]): IrisCode[] => {
  return irisCodeList?.map(function (row: IrisCode) {
    return {
      id: row.id,
      code: row.code,
      text: row.text,
      normalizedText: normalizeString(row.text),
    };
  });
};

export const normalizeString = (string: string): string => {
  return string
    .toLowerCase()
    .normalize('NFKD')
    .replace(/[\u0300-\u036F]/g, '');
};

export const parseEventDate = (isoString: Date): string => {
  return dayjs(isoString).locale('fr').format('DD-MM-YYYY HH:mm');
};

export const renderIrisCodeFromId = (irisCodes: IrisCode[], id: number): string => {
  return `${irisCodes?.find((irisCode) => irisCode.id === id)?.text}`;
};
