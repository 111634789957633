import React from 'react';

import dataProvider from 'dataProvider';
import dayjs from 'dayjs';

import ItemDetailField from 'components/atoms/ItemDetailField';

import { DeliveryItem } from 'types/delivery-items';

type DeliveryItemFieldProps = {
  deliveryItem: DeliveryItem;
  onclick?: () => void;
  couldBeChecked?: boolean;
};

const DeliveryItemField: React.FunctionComponent<DeliveryItemFieldProps> = ({
  deliveryItem,
  onclick,
  couldBeChecked,
}: DeliveryItemFieldProps) => {
  const [isNewlyCreated, setIsNewlyCreated] = React.useState<boolean>(false);

  function isWorkshopFileNewlyCreated(workshopFileId: number | undefined) {
    if (workshopFileId) {
      dataProvider
        .getOne('workshop-files', {
          id: workshopFileId,
        })
        .then(({ data }) => {
          setIsNewlyCreated(
            dayjs(data?.created_at).diff(dayjs(), 'day') < 3 ||
              dayjs(data?.updated_at).diff(dayjs(), 'day') < 3
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
  React.useEffect(() => {
    isWorkshopFileNewlyCreated(deliveryItem.product?.workshop_file);
  }, [deliveryItem]);

  return (
    deliveryItem && (
      <ItemDetailField
        topText={`${deliveryItem.collection_code || deliveryItem.id}`}
        bottomText={deliveryItem.product_type + ' ' + (deliveryItem.product_brand || '')}
        onclick={onclick}
        removeable={false}
        checked={couldBeChecked && isNewlyCreated}
      />
    )
  );
};

export default DeliveryItemField;
