import styled from 'styled-components';
import { LayoutProps, PaddingProps, layout, padding } from 'styled-system';

type ContentContainerProps = PaddingProps & LayoutProps;
const ContentContainer = styled('div')<ContentContainerProps>(
  layout,
  {
    flexGrow: 1,
  },
  padding
);

ContentContainer.defaultProps = {
  paddingY: 3,
  paddingX: 2,
};

export default ContentContainer;
