import React, { useState } from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { filterIrisCodeLists, normalizeString } from 'pages/RepairCycleTasks/RepairCycleUtils';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import CompactRadioField from 'components/molecules/CompactRadioField';
import InputWithIcon from 'components/molecules/InputWithIcon';

import { IrisCode } from 'types/iris-codes';

type GenericCodeListProps = {
  irisCodes: IrisCode[];
  handleCodeAddition: (irisCode: IrisCodeFormValue) => void;
  handleClose: () => void;
  title: string;
  buttonText: string;
};

type IrisCodeFormValue = {
  code: string;
};

const initialValues: IrisCodeFormValue = {
  code: '',
};

const StyledInputWithIcon = styled(InputWithIcon)((props) => ({
  outline: 'none',
  border: '1px solid #D7D7D7',
  borderRadius: props.theme.radii[1],
  height: props.theme.sizes[5],
  backgroundColor: '#F2F2F2',
  margin: 'auto',
  width: '90%',
  position: 'fixed',
  right: 16,
}));

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: ${(props) => props.theme.space[2]}px;
  color: ${(props) => props.theme.colors.turquoise};
`;

const GenericCodeList: React.FunctionComponent<GenericCodeListProps> = ({
  irisCodes,
  handleCodeAddition,
  handleClose,
  title,
  buttonText,
}: GenericCodeListProps) => {
  const [filterSectionValue, setFilterSectionValue] = useState<string>('');

  const [irisCodeFiltered, setIrisCodeFiltered] = useState<IrisCode[]>(irisCodes);

  const validationSchema = yup.object().shape({
    code: yup.mixed().required(),
  });

  const setFilter = (filter: string) => {
    setFilterSectionValue(filter);
    setTimeout(() => {
      setIrisCodeFiltered(filterIrisCodeLists(irisCodes, normalizeString(filter)));
    }, 500);
  };

  return (
    <>
      <Box overflow="auto">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleCodeAddition}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form>
              <Box position="absolute">
                <HeaderWithArrow title={title} action={handleClose} />
                <Box
                  bg="white"
                  borderBottom={'1px solid #DBDFE8'}
                  borderTop={'1px solid #DBDFE8'}
                  height={7}
                  display="flex"
                  alignItems="center"
                >
                  <StyledInputWithIcon
                    placeholder="Rechercher"
                    name={'search'}
                    value={filterSectionValue}
                    onChange={(event) => {
                      setFilter(event.target.value);
                    }}
                    type="string"
                    Icon={StyledSearchIcon}
                    noStyling
                  />
                </Box>
              </Box>

              <Box pt={112}>
                {irisCodeFiltered?.map((code) => (
                  <CompactRadioField
                    name="code"
                    key={code.code}
                    value={code.id.toString()}
                    label={code.text}
                  />
                ))}
              </Box>

              <BottomButton disabled={!isValid || !dirty || isSubmitting}>
                {buttonText}
              </BottomButton>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default GenericCodeList;
