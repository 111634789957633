import React from 'react';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import {
  HeightProps,
  MarginProps,
  MinWidthProps,
  WidthProps,
  height,
  margin,
  minWidth,
  variant,
  width,
} from 'styled-system';

const ButtonVariants = {
  primary: {
    backgroundColor: 'pink',
    color: 'green',
  },
  secondary: {
    backgroundColor: 'yellow',
    color: 'green',
  },
  disabled: {
    backgroundColor: 'grey300',
    color: 'grey700',
  },
  transparent: {
    backgroundColor: 'transparent',
    color: 'green',
    borderColor: 'green',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  fullTransparent: {
    backgroundColor: 'transparent',
    color: 'green',
  },
  error: {
    backgroundColor: 'red',
    color: 'white',
  },
  redTransparent: {
    backgroundColor: 'transparent',
    color: 'red',
    textDecorationLine: 'underline',
  },
  paperStrip: {
    background: 'white',
    width: '100%',
    borderRadius: 0,
    borderBottom: '1px solid #DBDFE8',
  },
};

type BaseButtonProps = {
  variant?: keyof typeof ButtonVariants;
  padding?: string;
} & MarginProps &
  WidthProps &
  MinWidthProps &
  HeightProps;

const BaseButton = styled('button')<BaseButtonProps>(
  margin,
  width,
  minWidth,
  height,

  (props: BaseButtonProps) => ({
    border: 0,
    borderRadius: 35,
    cursor: props.variant === 'disabled' ? 'not-allowed' : 'pointer',
    fontFamily: 'GT-Walsheim',
    fontSize: themeGet('fontSizes.1')(props),
    fontWeight: themeGet('fontWeights.medium')(props),
    height: props.height ? props.height : themeGet('space.5')(props),
    padding: props.padding ? props.padding : `10px ${themeGet('space.2')(props)}px`,
  }),
  variant({
    variants: ButtonVariants,
  })
);

export type ButtonProps = {
  disabled?: boolean;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
} & BaseButtonProps &
  React.HTMLAttributes<HTMLButtonElement>;

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  disabled,
  variant,
  type = 'submit',
  ...rest
}: ButtonProps) => (
  <BaseButton variant={disabled ? 'disabled' : variant} disabled={disabled} {...rest} type={type}>
    {children}
  </BaseButton>
);

Button.defaultProps = {
  variant: 'primary',
  disabled: false,
};

export default Button;
