// React imports
import React from 'react';

import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import ReconditionBottomBar from 'components/ReconditionBottomBar';
import WorkshopBottomBar from 'components/WorkshopBottomBar/WorkshopBottomBar';

// Component imports
import TransferPlayer from './components/TransferPlayer';

export default function Transfer(props) {
  const stepSlug = props.match.params.stepSlug || null;
  const baseURL = props.location;
  const history = useHistory();

  return (
    <div>
      <Form
        //it is essential for the Form
        onSubmit={() => true}
        initialValues={{ addProduct: [] }}
        // describe mutators to add and remove products from from data
        mutators={{
          removeProduct: (args, state, utils) => {
            if (args[0].id) {
              utils.changeValue(state, 'addProduct', (value) =>
                value.filter(function (element) {
                  return element.id !== args[0].id;
                })
              );
            } else if (args[0].barcode) {
              utils.changeValue(state, 'addProduct', (value) =>
                value.filter(function (element) {
                  return element !== args[0].barcode;
                })
              );
            }
          },
          addProductInForm: (args, state, utils) => {
            utils.changeValue(state, 'addProduct', (value) =>
              value.filter(function (element) {
                return element !== args[0].product.barcode;
              })
            );
            utils.changeValue(state, 'addProduct', (value) => [...value, args[0].product]);
          },
          addLocation: (args, state, utils) => {
            utils.changeValue(state, 'new_location', () => args[0].location);
          },
        }}
      >
        {(props) => (
          <form>
            <TransferPlayer formProps={props} stepSlug={stepSlug} baseUrl={baseURL} />
          </form>
        )}
      </Form>
      {history?.location?.pathname?.includes('workshop') ? (
        <WorkshopBottomBar />
      ) : (
        <ReconditionBottomBar />
      )}
    </div>
  );
}
