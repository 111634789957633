import React, { useState } from 'react';

import { useQuery } from 'react-admin';

import { CircularProgress } from '@material-ui/core';
// Material-ui icons imports
import ImportExportIcon from '@material-ui/icons/ImportExport';

import animationData from 'assets/check.json';
import dataProvider from 'dataProvider';
// import lottie animations
import Lottie from 'react-lottie';
import styled from 'styled-components';

import DrawerButton from 'pages/Store/components/DrawerButton';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import AutocompleteInput from 'components/molecules/AutocompleteInput';

import { SkuLocation } from 'types/sku-location';

import QrScanner from 'utils/QrScanner';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const DrawerButtonIcon = styled(ImportExportIcon)((props) => ({
  marginLeft: props.theme.space[2],
  color: props.theme.colors.honey,
  transform: 'rotate(90deg)',
}));

const TextButton = styled.div((props) => ({
  textDecoration: 'underline',
  color: props.theme.colors.grey900,
  fontSize: props.theme.fontSizes[2],
}));

const BottomButton = styled(Button)((props) => ({
  marginBottom: props.theme.space[3],
  width: '100%',
  alignSelf: 'self-end',
}));

const ConfirmationContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  height: '100%',
  padding: `0 ${props.theme.space[3]}px`,
  boxSizing: 'border-box',
}));

const ScanContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  marginTop: props.theme.space[7],
  paddingBottom: 400,
  overflow: 'auto',
}));

export type SkuLocationSelectionPortalProps = {
  onConfirm: (location: SkuLocation) => void;
  isDrawer?: boolean;
  isOpen: boolean;
  handleOpenPortal: () => void;
  handleClosePortal: () => void;
  title?: string;
  filter?: Record<string, unknown>;
  errorMessage?: string | undefined;
};

const SkuLocationSelectionPortal: React.FunctionComponent<SkuLocationSelectionPortalProps> = ({
  onConfirm,
  isDrawer,
  title,
  isOpen,
  handleOpenPortal,
  handleClosePortal,
  filter = {},
  errorMessage,
}: SkuLocationSelectionPortalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [location, setLocation] = useState<SkuLocation>();
  const [locationList, setLocationList] = useState<ReadonlyArray<SkuLocation>>([]);
  const [error, setError] = useState('');

  useQuery(
    {
      type: 'getList',
      resource: 'sku-location',
      payload: {
        sort: { field: 'id', order: 'DESC' },
        filter: filter,
      },
    },
    {
      onSuccess: ({ data }) => {
        setLocationList(
          data.map((location: SkuLocation) => ({
            ...location,
            value: location.id,
            label: location.code,
          }))
        );
      },
    }
  );

  const handleConfirmBarcode = (text: string) => {
    setIsLoading(true);
    dataProvider.getOne('sku-location', { code: text }).then((response) => {
      if (response.data && response.data.length > 0) {
        if (
          Object.keys(filter).length > 0 &&
          !locationList
            .map((location) => {
              return location.code;
            })
            .includes(response.data[0].code)
        ) {
          setError(`Emplacement introuvable ${filter.workshop && 'dans votre atelier'}`);
        } else {
          setLocation(response.data[0]);
        }
        setIsLoading(false);
      } else {
        setError('Erreur emplacement : ' + text);
        setIsLoading(false);
      }
    });
  };

  const confirmLocation = () => {
    setIsSubmitting(true);
    const LocationToRightType = location as SkuLocation;
    onConfirm(LocationToRightType);
  };

  const handleCloseDrawer = () => {
    handleClosePortal();
    setIsManual(false);
    setIsLoading(false);
    setIsSubmitting(false);
    setError('');
  };

  const handleOpenDrawer = () => {
    handleOpenPortal();
  };

  const openManualInput = () => {
    setIsManual(true);
  };

  const handleSelect = (location: SkuLocation) => {
    setLocation(location);
  };

  const resetLocation = () => {
    setLocation(undefined);
  };

  return (
    <>
      {isDrawer && (
        <DrawerButton onClick={handleOpenDrawer} bottom>
          Déplacer vers
          <DrawerButtonIcon />
        </DrawerButton>
      )}
      <PortalComponent title={title} open={isOpen} onClose={handleCloseDrawer}>
        {isDrawer && (
          <DrawerButton onClick={handleCloseDrawer} top>
            Déplacer vers
            <DrawerButtonIcon />
          </DrawerButton>
        )}
        {location ? (
          <ConfirmationContainer>
            <Box mt={200}>
              <Lottie options={animationOptions} height={130} width={130} />
            </Box>
            <Text variant="title1" mt={3} mb={3}>
              {location.code}
            </Text>
            <TextButton onClick={resetLocation}>Modifier la destination</TextButton>
            {errorMessage && (
              <Text variant="caption" color="red" mt={1} mb={1}>
                {errorMessage}
              </Text>
            )}

            <BottomButton
              variant="primary"
              onClick={confirmLocation}
              minWidth={192}
              disabled={isSubmitting}
            >
              Confirmer
            </BottomButton>
          </ConfirmationContainer>
        ) : (
          <>
            <ScanContainer>
              <QrScanner handleConfirmBarcode={handleConfirmBarcode} />
              <Text variant="body" color="red">
                {error}
              </Text>
              {isLoading && (
                <Box height={50}>
                  <CircularProgress />
                </Box>
              )}
              {isManual ? (
                <AutocompleteInput
                  options={locationList}
                  placeholder="Emplacement"
                  onChange={(location: SkuLocation) => handleSelect(location)}
                />
              ) : (
                <TextButton onClick={openManualInput}>Identifier manuellement</TextButton>
              )}
            </ScanContainer>
          </>
        )}
      </PortalComponent>
    </>
  );
};

export default SkuLocationSelectionPortal;
