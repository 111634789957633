// imports
import React from 'react';

// styles
import styled from 'styled-components';

import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

// components
import Backdrop from './Backdrop';
import Portal from './Portal';

const Content = styled.div(
  (props) => `
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: ${props.theme.colors.white};
  border-radius: ${props.theme.radii[1]};
  text-align: center;
`
);

export type PortalComponentProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  parent?: React.ReactNode;
  title?: string;
};

const PortalComponent: React.FunctionComponent<PortalComponentProps> = ({
  open,
  onClose,
  children,
  parent,
  title,
}: PortalComponentProps) => {
  // set up active state
  const [active, setActive] = React.useState(false);
  // Make a reference to the backdrop
  const backdrop = React.useRef<HTMLDivElement>(null);

  // on mount
  React.useEffect(() => {
    // get dom element from backdrop
    const { current } = backdrop;
    // when transition ends set active state to match open prop
    const transitionEnd = () => setActive(open);

    current?.addEventListener('transitionend', transitionEnd);

    // if open props is true add inert to #root
    // and set active state to true
    if (open) {
      window.setTimeout(() => {
        (document.activeElement as HTMLElement).blur();
        setActive(open);
        // document.querySelector('#root')?.setAttribute('inert', 'true');
      }, 10);
    }

    // on unmount remove listeners
    return () => {
      current?.removeEventListener('transitionend', transitionEnd);
    };
  }, [open]);

  return (
    <>
      {(open || active) && (
        <Portal parent={parent}>
          <Backdrop ref={backdrop} className={active && open ? 'active' : ''}>
            <Content className="modal-content">
              {title && <HeaderWithArrow title={title} action={onClose} />}
              {children}
            </Content>
          </Backdrop>
        </Portal>
      )}
    </>
  );
};

export default PortalComponent;
