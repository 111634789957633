import React, { useState } from 'react';

import List from '@material-ui/core/List';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import dayjs from 'dayjs';

import StoreStyles from 'pages/Store/StoreStyles';
import StorePackingFilter from 'pages/Store/components/StorePackingFilter';

import LoaderPlaceHolder from 'components/LoaderPlaceHolder';
import SparePartPackaging from 'components/SparePartPackaging';

import { SparePart } from 'types/spare-part';

import { usePackages, useWorkshops } from 'utils/api/api';
import { patchManySpareParts, postPackage } from 'utils/api/api';

import { useSparePartToPack } from '../api';
import PackageItem from '../components/PackageItem';

const styleList = makeStyles(StoreStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SENT = 'sent';
const LOST = 'lost';
const PACKED = 'packed';
const PICKING = 'picking';
const DEFAULT_PACKAGE_CODE = 'CODE_COLIS';

export default function StorePacking(): React.FunctionComponent {
  const classes = styleList();
  const [filterEmployee, setFilterEmployee] = useState<string>();
  const [employeeWorkshopIdentifier, setEmployeeWorkshopIdentifier] = useState();
  const [recipientName, setRecipientName] = useState<string>();
  const [selectedPackageSpareParts, setSelectedPackageSpareParts] = useState<SparePart[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageCode, setPackageCode] = useState(null);
  const [isPackageButtonDisabled, setIsPackageButtonDisabled] = useState(false);

  const { workshops } = useWorkshops();
  const { packages } = usePackages({ sent_at: dayjs().format('YYYY-MM-DD') });
  const { loading, sparePartsByEmployee, sparePartsByWorkshop } =
    useSparePartToPack(filterEmployee);

  const setFilter = (event) => {
    if (event.target) {
      console.log(event.target.value);
      setFilterEmployee(event.target.value);
    } else {
      setFilterEmployee();
    }
  };

  const onPackageClick = (
    recipientName: string,
    employeeId: number,
    spareParts: SparePart[]
  ): void => {
    setEmployeeWorkshopIdentifier(employeeId || recipientName); // FIX to allow resupplying packaging (29/03/2022)
    // could be an employee Id OR an employee name OR a workshop name

    setIsModalOpen(true);
    setRecipientName(recipientName);
    setSelectedPackageSpareParts(spareParts);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    setPackageCode();
    setEmployeeWorkshopIdentifier();
  };

  const doesPackageExist = (employeeId: number): boolean => {
    return packages && packages.map((pack) => pack.employee).includes(employeeId);
  };

  const handleUpdateSkuAfterValidation = async (
    id: number,
    lostSparePartIds: number[],
    sparePartIds: number[]
  ): void => {
    try {
      await patchManySpareParts(sparePartIds, { status: PACKED, package: id });
      await patchManySpareParts(lostSparePartIds, { status: LOST, loss_reason: PICKING });
    } catch (e) {
      console.error(("Erreur lors de l'update des pièces", e.message));
    }
  };

  const handleValidateTask = async (
    employeeId: number,
    sparePartIds: number[],
    recipientName: string,
    lostSparePartIds: number[]
  ): void => {
    setIsPackageButtonDisabled(true);
    const workshop = workshops?.find((workshop) => workshop.name === recipientName);

    try {
      const data = await postPackage(
        workshop
          ? {
              status: SENT,
              workshop: workshop.id,
              sent_at: dayjs().format('YYYY-MM-DDTHH:mm'),
            }
          : {
              code: DEFAULT_PACKAGE_CODE,
              status: SENT,
              employee: employeeId,
              sent_at: dayjs().format('YYYY-MM-DDTHH:mm'),
              employee_name: recipientName.toUpperCase(),
            }
      );
      handleUpdateSkuAfterValidation(data.id, lostSparePartIds, sparePartIds);
      setPackageCode(data.code);
    } catch (e) {
      console.error(e);
    }
    setIsPackageButtonDisabled(false);
  };

  return (
    <div className={classes.StoreReceptionContainer}>
      <div className={classes.StoreReceptionScrollableView}>
        <List className={classes.list}>
          <StorePackingFilter onType={setFilter} />
          {!loading &&
            sparePartsByWorkshop &&
            Object.keys(sparePartsByWorkshop)
              .sort((a, b) => a.localeCompare(b))
              .map((workshopName, key) => (
                <PackageItem
                  recipientName={workshopName}
                  key={key}
                  itemCount={sparePartsByWorkshop[workshopName].length}
                  onClick={() =>
                    onPackageClick(
                      workshopName,
                      sparePartsByWorkshop[workshopName][0].demand.recipient_id,
                      sparePartsByWorkshop[workshopName]
                    )
                  }
                  packageAlreadyExists={doesPackageExist(
                    sparePartsByWorkshop[workshopName][0].demand.recipient_id
                  )}
                />
              ))}
          {!loading &&
            sparePartsByEmployee &&
            Object.keys(sparePartsByEmployee)
              .sort((a, b) => a.localeCompare(b))
              .map((employeeName, key) => (
                <PackageItem
                  recipientName={employeeName}
                  key={key}
                  itemCount={sparePartsByEmployee[employeeName].length}
                  onClick={() =>
                    onPackageClick(
                      employeeName,
                      sparePartsByEmployee[employeeName][0].demand.recipient_id,
                      sparePartsByEmployee[employeeName]
                    )
                  }
                  packageAlreadyExists={doesPackageExist(
                    sparePartsByEmployee[employeeName][0].demand.recipient_id
                  )}
                />
              ))}
        </List>
        {loading && <LoaderPlaceHolder size="small" />}
        {employeeWorkshopIdentifier && isModalOpen && (
          <SparePartPackaging
            selectedId={employeeWorkshopIdentifier}
            TransitionComponent={Transition}
            open={isModalOpen}
            onClose={handleCloseModal}
            recipientName={recipientName}
            mode="packing"
            spareParts={selectedPackageSpareParts.sort((a: SparePart, b: SparePart) =>
              `${a.location.code} ${a.id}` > `${b.location.code} ${b.id}` ? 1 : -1
            )}
            handleCreate={handleValidateTask}
            packageCode={packageCode}
            isPackageButtonDisabled={isPackageButtonDisabled}
            generatedPackageCode={packageCode}
          />
        )}
      </div>
    </div>
  );
}
