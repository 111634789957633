// based on https://dev.to/link2twenty/react-using-portals-to-make-a-modal-2kdf
// Takes the relatively simple createPortal and add a layer
// of complexity and contain it within a component.
// imports
import React from 'react';

import ReactDOM from 'react-dom';

export type PortalProps = {
  parent?: React.ReactNode;
  children: React.ReactChild;
  className?: string;
};

// get parent and className props as well as the children
const Portal: React.FunctionComponent<PortalProps> = ({ children, parent }: PortalProps) => {
  // Create div to contain everything
  const el = React.useMemo(() => document.createElement('div'), []);
  // On mount function
  React.useEffect(() => {
    // work out target in the DOM based on parent prop
    const target = parent && (parent as HTMLElement).appendChild ? parent : document.body;
    // Default classes
    const classList = ['portal-container'];
    classList.forEach((item) => el.classList.add(item));
    // Append element to dom
    (target as HTMLElement).appendChild(el);
    // On unmount function
    return () => {
      // Remove element from dom
      (target as HTMLElement).removeChild(el);
    };
  }, [el, parent]);
  // return the createPortal function
  return ReactDOM.createPortal(children, el);
};

export default Portal;
