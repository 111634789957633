// React imports
import React from 'react';

import { Typography } from '@material-ui/core';

import logo from 'assets/MurfyLogo.png';

export default function NoContextScreen() {
  return (
    <div style={{ textAlign: 'center', margin: 48 }}>
      <div style={{ marginBottom: '10%' }}>
        <img src={logo} alt="Logo Murfy" width="100%" />
      </div>
      <Typography variant="h6">Vous n'avez pas de tâche associée à votre profil</Typography>
    </div>
  );
}
