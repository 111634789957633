import React, { useState } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import dayjs from 'dayjs';
import SwipeableViews from 'react-swipeable-views';
import { SlideRenderProps, virtualize } from 'react-swipeable-views-utils';

import Box from 'components/atoms/Box';
import ButtonIcon from 'components/atoms/ButtonIcon';
import ContentContainer from 'components/templates/ContentContainer';

import DateSelector from './DateSelector';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

type InfiniteDateSelectorProps = {
  selectedDate: string;
  selectedWeekIndex: number;
  onWeekChange: (index: number) => void;
  onDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InfiniteDateSelector: React.FunctionComponent<InfiniteDateSelectorProps> = ({
  selectedDate,
  selectedWeekIndex,
  onWeekChange,
  onDateChange,
}: InfiniteDateSelectorProps) => {
  const slideRenderer = ({ key }: SlideRenderProps) => (
    <DateSelector key={key} onSelect={onDateChange} selectedDate={selectedDate} />
  );
  return (
    <Box display="flex">
      <ButtonIcon
        mr={1}
        handleClick={() => onWeekChange(selectedWeekIndex - 1)}
        icon={ChevronLeftIcon}
        display={['none', 'block']}
      />
      <ContentContainer paddingX={0} paddingY={0}>
        <VirtualizeSwipeableViews
          index={selectedWeekIndex}
          slideRenderer={slideRenderer}
          onChangeIndex={onWeekChange}
          overscanSlideAfter={1}
          overscanSlideBefore={1}
        />
      </ContentContainer>
      <ButtonIcon
        data-testid="nextWeek"
        ml={1}
        handleClick={() => onWeekChange(selectedWeekIndex + 1)}
        icon={ChevronRightIcon}
        display={['none', 'block']}
      />
    </Box>
  );
};

type DateSelectorHandler = {
  selectedDate: string;
  selectedWeekIndex: number;
  handleDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeekChange: (index: number) => void;
};
export const useDateSelector = (
  initialDate: string,
  initialWeekIndex: number
): DateSelectorHandler => {
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(initialWeekIndex);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const onWeekChange = (index: number) => {
    if (index === 0) {
      setSelectedDate(dayjs().format('YYYY-MM-DD'));
    } else {
      setSelectedDate(dayjs().add(index, 'week').day(1).format('YYYY-MM-DD'));
    }
    setSelectedWeekIndex(index);
  };

  return {
    selectedDate: selectedDate,
    selectedWeekIndex: selectedWeekIndex,
    handleDateChange: handleDateChange,
    onWeekChange: onWeekChange,
  };
};

export default InfiniteDateSelector;
