import React from 'react';

import PaperStripUnfoldsWithArrow from 'components/PaperStripUnfoldsWithArrow';
import Text from 'components/atoms/Text';

import { Product } from 'types/products';

import { formatProductTitle } from 'utils/products';

type ProductGeneralInfoPanelProps = {
  product: Product;
};

const ProductGeneralInfoPanel: React.FunctionComponent<ProductGeneralInfoPanelProps> = ({
  product,
}: ProductGeneralInfoPanelProps) => {
  const getProductDescription = () => {
    return (
      <>
        {product.model_reference && (
          <>
            <Text textAlign="left" variant="small" color="grey500">
              Modèle
            </Text>
            <Text textAlign="left" variant="small">
              {product?.model_reference}
            </Text>
          </>
        )}
        {product.model_reference && (
          <Text textAlign="left" variant="extraSmall" color="grey900">
            {product?.subcategory1} {product?.subcategory2} - {product?.brand}
          </Text>
        )}
      </>
    );
  };

  return (
    <PaperStripUnfoldsWithArrow
      title={formatProductTitle(product)}
      content={getProductDescription()}
      key={product.id}
    />
  );
};

export default ProductGeneralInfoPanel;
