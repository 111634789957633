import React from 'react';

import PaperStripUnfoldsWithArrow from 'components/PaperStripUnfoldsWithArrow';
import BulletList from 'components/atoms/BulletList';
import Text from 'components/atoms/Text';
import BulletPoint from 'components/molecules/BulletPoint';
import ApplianceNameplate from 'components/organisms/ApplianceNameplate';

import { Product } from 'types/products';

import { formatProductTitle } from 'utils/products';

type ProductDetailPanelProps = {
  product: Product;
};

const ProductDetailPanel: React.FunctionComponent<ProductDetailPanelProps> = ({
  product,
}: ProductDetailPanelProps) => {
  const getProductDescription = () => {
    return (
      <BulletList pl={3} mt={16}>
        <BulletPoint>
          {product.symptom !== undefined && product.symptom !== 'Autre'
            ? product.symptom
            : 'Pas de symptôme décrit'}
        </BulletPoint>
        {product.model_reference && (
          <BulletPoint>Modèle ou Référence: {product.model_reference}</BulletPoint>
        )}
        {product.fault && <BulletPoint>{product.fault}</BulletPoint>}
        {product.fault_details && <Text variant="small">{product.fault_details}</Text>}
        {product.identification_plate && (
          <ApplianceNameplate applianceNameplateImageUrl={product.identification_plate} />
        )}
      </BulletList>
    );
  };

  return (
    <PaperStripUnfoldsWithArrow
      title={formatProductTitle(product)}
      content={getProductDescription()}
      key={product.id}
    />
  );
};

export default ProductDetailPanel;
