import { createStyles } from '@material-ui/core/styles';

const RepairConfirmationModalStyle = createStyles({
  closeButton: {
    color: '#8795B3',
    position: 'absolute',
    right: 16,
    top: 16,
    zIndex: 3,
  },
  arrowBackButton: {
    color: '#8795B3',
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 3,
  },
  modalHeader: {
    width: '100vw',
    textAlign: 'center',
    paddingTop: '16vh',
    paddingBottom: '8vh',
  },
  validateButton: {
    background: '#FCD660',
    margin: '20px 24px',
    height: 42,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#2B3B5C',
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 48px)',
  },
  skuField: {
    width: 'calc(100vw - 32px)',
    height: 52,
    backgroundColor: 'white',
    borderRadius: 10,
    marginLeft: 16,
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
  },
  skuFieldDesignation: {
    paddingLeft: 16,
    color: '#2B3B5C',
  },
  skuFieldReference: {
    color: '#8795B3',
    paddingLeft: 16,
  },
  modal: {
    backgroundColor: '#F1F0F5',
    minHeight: '100vh',
  },
  cancelIcon: {
    right: 32,
    position: 'absolute',
    color: '#F85B5B',
  },
});

export default RepairConfirmationModalStyle;
