import React, { useState } from 'react';

import { useModals } from 'modalsContext';

import { sparePartFittingChoices } from 'pages/RepairCycleTasks/RepairCycleRepairAction/Utils';

import SkuListSelectionPortal from 'components/SkuListSelectionPortal';
import SparePartSelectionPortal from 'components/SparePartSelectionPortal';

import { Sku, SparePartWithFittingType } from 'types/sku';
import { FittingType } from 'types/spare-part-fittings';

type SparePartSelectorProps = {
  enableFittingTypeModal?: boolean;
  addSpareParts: (spareParts: Sku[]) => void;
  closeSparePartSelector: () => void;
  filter: Record<string, unknown>;
  closeOnSuccess?: boolean;
};

const SparePartSelector: React.FunctionComponent<SparePartSelectorProps> = ({
  addSpareParts,
  closeSparePartSelector,
  filter,
  enableFittingTypeModal,
  closeOnSuccess = true,
}: SparePartSelectorProps) => {
  const { showModal, closeModal } = useModals();

  const [isSparePartSelectionOpen, setIsSparePartSelectionOpen] = useState<boolean>(true);
  const [selectedSparePartList, setSelectedSparePartList] = useState<Sku[]>([]);
  const [sparePart, setSparePart] = useState<SparePartWithFittingType>();
  const [isListSelectionOpen, setIsListSelectionOpen] = useState(true);

  const validatePart = () => {
    addSpareParts(selectedSparePartList);
    closeOnSuccess && closeSparePartSelector();
  };

  const pushSparePartWithFittingType = (sparePart: SparePartWithFittingType) => {
    showModal({
      modalToShow: 'CHOICE_MODAL',
      modalProps: {
        title: 'Peux-tu nous en dire plus sur le lien entre la pièce et la machine ?',
        onCancel: closeModal,
        onSelect: (value: string) => {
          sparePart.fitting_type = value as FittingType;
          setSelectedSparePartList([...selectedSparePartList, sparePart]);
        },
        choices: sparePartFittingChoices,
      },
    });
  };

  const addSparePart = (value: Sku) => {
    if (enableFittingTypeModal) {
      const sparePartWithFittingType = Object.assign(value, { fitting_type: undefined });
      pushSparePartWithFittingType(sparePartWithFittingType);
    } else {
      setSelectedSparePartList([...selectedSparePartList, value]);
    }
    setIsSparePartSelectionOpen(false);
    setIsListSelectionOpen(true);
  };

  return (
    <>
      {isSparePartSelectionOpen && (
        <SparePartSelectionPortal
          onConfirm={(value: Sku) => addSparePart(value)}
          onClose={() => {
            setIsSparePartSelectionOpen(false);
            setIsListSelectionOpen(true);
          }}
          open={isSparePartSelectionOpen}
          selectedIds={selectedSparePartList.map((x: Sku) => x.id)}
          setSku={(sparePart: Sku | undefined) => setSparePart(sparePart)}
          sku={sparePart}
          filter={filter}
        />
      )}
      {isListSelectionOpen && (
        <SkuListSelectionPortal
          skuList={selectedSparePartList}
          selectedList={selectedSparePartList}
          open={isListSelectionOpen}
          onClose={() => closeSparePartSelector()}
          title="Scannez les pièces posées"
          buttonText="Continuer"
          onClickOnButton={() => validatePart()}
          openSkuSelection={() => {
            setIsSparePartSelectionOpen(true);
            setIsListSelectionOpen(false);
          }}
          isSaving={false}
        />
      )}
    </>
  );
};

export default SparePartSelector;
