import { createStyles } from '@material-ui/core/styles';

const RepairReportSparePartStockAddition = createStyles({
  addNewSparePartButton: {
    fontSize: '15px',
    lineHeight: '140%',
    textTransform: 'none',
    width: 'fit-content',
    margin: '8px 24px',
    color: '#8795B3',
  },
  newSparePartContainer: {
    display: 'flex',
    marginBottom: 16,
  },
  pieceNumber: {
    margin: '0px 24px 16px 24px',
    fontSize: 14,
  },
  underlinedLabel: {
    textDecoration: 'underline',
  },
  onCloseIcon: {
    position: 'absolute',
    right: 24,
  },
  skuReference: {
    color: '#8795B3',
  },
  skuDesignation: {
    color: '#2B3B5C',
  },
});
export default RepairReportSparePartStockAddition;
