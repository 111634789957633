import React from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Field, FieldRenderProps } from 'react-final-form';

import RepairReportTextAreaStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportTextAreaStyle';

const useStyles = makeStyles(RepairReportTextAreaStyle);

type RepairReportTextAreaProps = {
  textPlaceHolder?: string;
  formProductIdReference: string;
  textAreaLabel: string;
};

const RepairReportTextArea: React.FunctionComponent<RepairReportTextAreaProps> = ({
  textAreaLabel,
  textPlaceHolder,
  formProductIdReference,
}: RepairReportTextAreaProps) => {
  const classes = useStyles();
  return (
    <Field
      name={formProductIdReference + '.' + textAreaLabel}
      formProductIdReference={formProductIdReference}
    >
      {(props: FieldRenderProps<string, HTMLInputElement>) => (
        <div className={classes.textAreaContainer}>
          <TextField
            multiline
            rows={7}
            variant="outlined"
            className={classes.basicTextArea}
            placeholder={textPlaceHolder}
            name={textAreaLabel}
            value={props.input.value}
            onChange={props.input.onChange}
          />
        </div>
      )}
    </Field>
  );
};

export default RepairReportTextArea;
