import React, { useState } from 'react';

import SimpleScanner from 'components/molecules/SimpleScanner';

import { Package } from 'types/package';

import { getPackages } from 'utils/api/api';

type PackageScannerProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmBarcode: (scannedCode: string) => void;
  handleManualInput: (scannedCode: { value: number; label: string }) => void;
  packageFilter?: Record<string, unknown>;
  error?: string;
};

const PackageScanner: React.FunctionComponent<PackageScannerProps> = ({
  open,
  onClose,
  handleConfirmBarcode,
  handleManualInput,
  packageFilter,
  error,
}: PackageScannerProps) => {
  const [choicesList, setChoicesList] = useState<Package[]>([]);

  const handleScan = (code: string) => {
    handleConfirmBarcode(code);
  };

  const loadOptionList = async () => {
    const pckgs = await getPackages(packageFilter);
    setChoicesList(pckgs);
  };

  return (
    <SimpleScanner
      open={open}
      onClose={onClose}
      handleConfirmCallback={handleScan}
      handleManualInput={handleManualInput}
      error={error}
      scannerTitle="Nouveau Colis"
      scannerExplanations="Le QR code du colis se situe à l'intérieur du colis sur l'un des côtés."
      options={
        choicesList.map((choice) => {
          return { value: choice.id, label: choice.code };
        }) as unknown as Array<{ value: string; label: string }>
      }
      selectedValue={undefined}
      loadOptionList={loadOptionList}
    />
  );
};

export default PackageScanner;
