import { createStyles } from '@material-ui/core/styles';

const IncentiveSystemStyles = createStyles({
  IncentiveSystemAppBar: {
    background: '#fff',
    color: '#2B3B5C',
    boxShadow: 'none',
    borderRadius: 'unset',
    width: '100vw',
    top: 0,
  },
  history: {
    marginLeft: 16,
  },
  IncentiveSystemTitle: {
    color: '#F7B349',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
  },
  IncentiveSystemContainerActve: {
    flexGrow: 1,
  },
  containerResume: {
    padding: '0 16px 16px',
  },
  IncentiveSystemListItem: {
    color: '#2B3B5C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineSeight: '140%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  IncentiveSystemPoints: {
    color: '#2B3B5C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineSeight: '140%',
  },
  IncentiveSystemPointsResume: {
    color: '#2B3B5C',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 15,
    lineSeight: '140%',
  },
  incentiveSystemListContainer: {
    marginTop: 16,
    borderRadius: 10,
    boxShadow: 'none',
    padding: 24,
  },
  circlePoints: {
    width: 110,
    height: 110,
    textAlign: 'center',
    border: '3px solid #F7B349',
    borderRadius: 70,
    '& span': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '140%',
      letterSpacing: 0.5,
      color: '#2B3B5C',
    },
  },
  totalPoints: {
    paddingTop: 24,
    '& span': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 26,
      lineHeight: '135%',
      textAlign: 'center',
      color: '#2B3B5C',
    },
  },
  totalPointsContainer: {
    marginTop: 16,
    borderRadius: 10,
    padding: '24px 16px',
    display: 'flex',
    boxShadow: 'none',
    justifyContent: 'center',
  },
  totalInterventions: {
    marginLeft: 24,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    color: '#2B3B5C',
  },
  itemsList: {
    fontSize: 15,
  },
  detailTitle: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '140%',
    borderTop: '1px solid #DBDFE8',
    borderBottom: '1px solid #DBDFE8',
    background: '#FFF',
    padding: '4px 16px',
    marginTop: 17,
  },
  IncentiveSystemListItemDetails: {
    display: 'flex',
    color: '#2B3B5C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineSeight: '140%',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'space-between',
    borderBottom: '1px solid #DBDFE8',
  },
  lastnameStyle: {
    width: 120,
    color: '#2B3B5C',
    textDecoration: 'none',
  },
  labelStyle: {
    '& p': {
      color: '#8795B3',
    },
  },
  updateTitle: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '140%',
    borderTop: '1px solid #DBDFE8',
    borderBottom: '1px solid #DBDFE8',
    background: '#FFF',
    padding: '4px 16px',
    marginTop: 17,
  },
  filterHeader: {
    backgroundColor: '#fff',
  },
  header: {
    position: 'relative',
    padding: '0 16px',
    borderRadius: 'unset',
    color: '#2B3B5C',
  },
  filterButton: {
    background: '#F4F3F8',
    borderRadius: 10,
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
  },
  back: {
    color: '#2B3B5C',
  },
  containerUpdate: {
    overflow: 'auto',
    background: '#F1F0F5',
  },
  IncentiveSystemListItemUpdate: {
    display: 'flex',
    color: '#2B3B5C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    padding: '11px 16px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DBDFE8',
    overflowX: 'auto',
  },
  arrowStyle: {
    width: 16,
    color: '#2B3B5C',
  },
  fieldUpdate: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '140%',
    color: '#2B3B5C',
    width: 140,
    textDecoration: 'none',
  },
  fieldPrevLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '140%',
    width: 140,
    textDecoration: 'none',
    color: '#8795B3',
    '& p': {
      color: '#8795B3',
    },
    '& span': {
      color: '#8795B3',
    },
    '& a': {
      color: '#8795B3',
    },
  },
  incentiveSlotContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    background: 'none',
    boxShadow: 'none',
  },
  incentiveSlotDate: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    color: '#2B3B5C',
  },
  incentiveArrow: {
    color: '#2B3B5C',
    padding: 0,
  },
  percentageFinished: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '140%',
    color: '#2B3B5C',
    paddingLeft: 24,
    display: 'flex',
  },
  arrowUp: {
    color: '#F85B5B',
  },
  arrowDown: {
    color: '#81D89F',
  },
  containerKPI: {
    margin: 'auto',
  },
  labelNameRecap: {
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '140%',
  },
  goBackRepairmListStyles: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    padding: '12px 0',
    color: '#2B3B5C',
    marginLeft: 16,
    '& span': {
      justifyContent: 'left',
    },
  },
  wrapperClaimList: {
    marginTop: 22,
  },
});

export default IncentiveSystemStyles;
