import React, { useState } from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { FieldArray, Form, Formik } from 'formik';
import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import InputWithIcon from 'components/molecules/InputWithIcon';
import SkuModelInfos from 'components/organisms/SkuModelInfos/SkuModelInfos';
import PageLayout from 'components/templates/PageLayout';

import { FormSkuModel } from 'types/form-sku-models';
import { Product } from 'types/products';

import Summary from '../ProductDisassemblyTask/Summary';
import { ArrayHelpers, CheckValues } from '../ProductDisassemblyTask/utils';
import { normalizeString } from '../RepairCycleUtils';
import SparePartDetailsTest from '../SparePartDetailsTest/SparePartDetailsTest';

type SparePartThumbnailsListProps = {
  initialValues: {
    sku_models: FormSkuModel[];
  };
  goBack: () => void;
  handleClose: () => void;
  product: Product;
  title: string;
  error: string | undefined;
  openPrintQrCodeModal?: (skuCode: string) => void;
  handleSubmit: (values: { sku_models: FormSkuModel[] }) => void;
  handleValidateSparePart: (
    checksValues: CheckValues,
    selectedSkuModel: FormSkuModel,
    values: {
      sku_models: FormSkuModel[];
    },
    arrayHelpers: ArrayHelpers
  ) => void;
  allChecksNeeded?: boolean;
  showQuantitySelector?: boolean;
};
const StyledForm = styled(Form)({
  overflow: 'auto',
});

const StyledInputWithIcon = styled(InputWithIcon)((props) => ({
  outline: 'none',
  border: '1px solid #D7D7D7',
  borderRadius: props.theme.radii[1],
  height: props.theme.sizes[5],
  backgroundColor: '#FFFFFF',
  margin: 'auto',
  width: '90%',
  position: 'fixed',
  right: 16,
}));

const StyledSearchIcon = styled(SearchIcon)((props) => ({
  position: 'absolute',
  right: props.theme.space[2],
  color: props.theme.colors.turquoise,
}));

const SparePartThumbnailsList: React.FunctionComponent<SparePartThumbnailsListProps> = ({
  initialValues,
  goBack,
  title,
  error,
  handleSubmit,
  handleValidateSparePart,
  allChecksNeeded,
  openPrintQrCodeModal,
  showQuantitySelector = false,
}: SparePartThumbnailsListProps) => {
  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedSkuModel, setSelectedSkuModel] = useState<FormSkuModel>();

  const handleSubmitForm = async (values: { sku_models: FormSkuModel[] }) => {
    handleSubmit(values);
  };

  return (
    <PageLayout>
      <HeaderWithArrow
        title={title}
        action={selectedSkuModel ? () => setSelectedSkuModel(undefined) : () => goBack()}
      />
      <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
        {({ values, isSubmitting }) => (
          <StyledForm>
            <Box height={7} display="flex" alignItems="center">
              <StyledInputWithIcon
                data-testid="search"
                placeholder="Nom de la pièce"
                name={'search'}
                value={filterValue}
                onChange={(event) => {
                  setFilterValue(event.target.value);
                }}
                type="string"
                Icon={StyledSearchIcon}
                noStyling
              />
            </Box>
            <Box
              overflow="auto"
              pb={98}
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              <FieldArray
                name="sku_models"
                render={(arrayHelpers) => (
                  <>
                    {values['sku_models']
                      .filter((skuModel) =>
                        (
                          skuModel.normalizedDesignation + skuModel.supplierReference.reference
                        ).includes(normalizeString(filterValue))
                      )
                      .map((skuModel) => {
                        return (
                          <Box backgroundColor="white" key={skuModel.id} mt={1}>
                            <SkuModelInfos
                              skuModel={skuModel}
                              key={skuModel.id}
                              handleOnClick={() => {
                                if (skuModel.skuCode) {
                                  openPrintQrCodeModal && openPrintQrCodeModal(skuModel.skuCode);
                                } else {
                                  setSelectedSkuModel(
                                    values['sku_models'].find(
                                      (skuModelValue) => skuModel.id === skuModelValue.id
                                    )
                                  );
                                }
                              }}
                            />
                          </Box>
                        );
                      })}
                    {selectedSkuModel && (
                      <SparePartDetailsTest
                        allChecksNeeded={allChecksNeeded}
                        showQuantitySelector={showQuantitySelector}
                        skuModel={selectedSkuModel}
                        handleClose={() => setSelectedSkuModel(undefined)}
                        handleSubmit={(checksValues: {
                          quantity: number;
                          sku_model_test: { id: number; text: string; is_checked: boolean }[];
                        }) =>
                          handleValidateSparePart(
                            checksValues,
                            selectedSkuModel,
                            values,
                            arrayHelpers
                          )
                        }
                      />
                    )}
                  </>
                )}
              />
              <Box
                width="100%"
                backgroundColor="#FFFFFF"
                bottom={0}
                px={2}
                py={1}
                position="absolute"
              >
                {error && (
                  <Box px={1} pb={1}>
                    <Text variant="small" color="red">
                      {error}
                    </Text>
                  </Box>
                )}
                <Summary skuModels={values.sku_models} />
                <Button disabled={isSubmitting || !!error} width="100%">
                  {isSubmitting ? 'Sauvegarde en cours' : 'Confirmer'}
                </Button>
              </Box>
            </Box>
          </StyledForm>
        )}
      </Formik>
    </PageLayout>
  );
};

export default SparePartThumbnailsList;
