export const b64toFile = (b64Data: string, contentType: string, sliceSize = 512): File => {
  // like explained here : https://stackoverflow.com/a/20151856
  // create a byte array from the base 64 encoded data
  // create a blob from this array
  // add missing properties before creating the file from the created blob

  const byteCharacters = atob(b64Data.split(',')[1]);
  const byteArrays = [];

  // write the bytes of the string to a typed array
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // create new blob from byte arrays
  const blob = new Blob(byteArrays, { type: contentType });

  // create file from blob by passing the two missing arguments
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const file: any = blob;
  file.lastModifiedDate = new Date();
  file.name = 'payment-signature';
  return file;
};
