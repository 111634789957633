import { adminReducer } from 'react-admin';

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// import custom reducers
import repairReportPlayer from 'pages/RepairReport/components/module';

export default function createReducer(history) {
  return combineReducers({
    repairReportPlayer,
    admin: adminReducer,
    router: connectRouter(history),
  });
}
