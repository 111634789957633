import React from 'react';

import { useHistory } from 'react-router-dom';

import { Visit } from 'types/visits';

import VisitCardDone from './VisitCardDone';
import VisitCardToDo from './VisitCardToDo';

export type VisitCardProps = {
  visit: Visit;
};

const VisitCard: React.FunctionComponent<VisitCardProps> = ({ visit }: VisitCardProps) => {
  const history = useHistory();
  const goToReport = () => {
    history.push(`/compte-rendu/${visit.customer_file}/dashboard?visit=${visit.id}`);
  };

  return visit.is_over ? (
    <VisitCardDone visit={visit} handleClick={goToReport} />
  ) : (
    <VisitCardToDo visit={visit} handleClick={goToReport} />
  );
};

export default VisitCard;
