// React imports
import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Payment from 'pages/Payment/Payment';
import ProductAddition from 'pages/Product/ProductAddition';
import ProductModelIdentification from 'pages/ProductModelIdentification';
import { postSparePartFitting } from 'pages/RepairCycleTasks/api';
// Component imports
import ReportStepsPlayer from 'pages/RepairReport/components';

import { SparePartFitting } from 'types/spare-part-fittings';

import useQuerySearch from 'utils/useQuerySearch';

import ApplyBonus from '../ApplyBonus';
import ProductFunctionnalStatusScreen from './components/ProductFunctionnalStatusScreen';
import { postFormRequest } from './components/module';
import Dashboard from './components/stepComponents/Dashboard';
import { formatData } from './services/formatData';
import { StepId } from './services/stepHelper';
//service
import useUnload from './services/useUnload';

type RepairReportMatchParams = {
  customerFileId: string;
  stepSlug?: StepId;
};

type RepairReportProps = RouteComponentProps<RepairReportMatchParams>;

type RepairReportPlayerState = {
  error: string | null;
  isErrorShown: boolean;
  isLoading: boolean;
  uploadProgress: number;
  isPosted: boolean;
};

const RepairReport: React.FunctionComponent<RepairReportProps> = ({
  match: {
    params: { customerFileId: customerFileIdString, stepSlug },
  },
}: RepairReportProps) => {
  const dispatch = useDispatch();

  // React-Router doesn't cast route params in int
  const customerFileId = parseInt(customerFileIdString);
  const { errorMessage, isErrorShown, isLoading, formUploadProgress, isPosted } = useSelector(
    ({ repairReportPlayer }: { repairReportPlayer: RepairReportPlayerState }) => {
      return {
        errorMessage: repairReportPlayer.error,
        isErrorShown: repairReportPlayer.isErrorShown,
        isLoading: repairReportPlayer.isLoading,
        formUploadProgress: repairReportPlayer.uploadProgress,
        isPosted: repairReportPlayer.isPosted,
      };
    }
  );
  const query = useQuerySearch();
  const visitIdFromQS = query.get('visit');
  const productIdFromQS = query.get('productId');
  const visitId = (visitIdFromQS || localStorage.getItem('visitId')) as string;
  const productId = (productIdFromQS || localStorage.getItem('productId')) as string;

  localStorage.setItem('visitId', visitId);
  localStorage.setItem('productId', productId);

  const [productIdentificationByPassed, setProductIdentificationByPassed] = React.useState<
    number[]
  >([]);

  const [productsWithBonusChecked, setProductsWithBonusChecked] = React.useState<number[]>([]);

  const onByPassProductIdentification = (productId: number) =>
    setProductIdentificationByPassed((array) => [...array, productId]);
  const onProductIdentification = (productId: number) =>
    setProductIdentificationByPassed((array) => array.filter((item) => item !== productId));
  const handleBonusVerified = (productId: number) => {
    setProductsWithBonusChecked((array) => [...array, productId]);
  };

  const onSubmit = (formValues: Record<string, unknown>) => {
    try {
      (formValues?.spare_part_fitted as SparePartFitting[])?.map(
        (sparePartFitted: SparePartFitting) =>
          sparePartFitted.product && postSparePartFitting(sparePartFitted.product, sparePartFitted)
      );
    } catch (e) {
      console.error("Can't submit spare part fitting; " + e);
    }
    delete formValues['spare_part_fittings'];
    const formattedInputs = formatData(formValues, visitId, customerFileId);

    dispatch(postFormRequest(formattedInputs));
  };

  useUnload((e: BeforeUnloadEvent) => {
    // This hook displays an alert when a user tries to reload the page
    // This is necessary to prevent data loss on reloading
    e.preventDefault();
    e.returnValue = '';
  });

  if (stepSlug === undefined) {
    return <Redirect to={`/compte-rendu/${customerFileId}/dashboard?visit=${visitIdFromQS}`} />;
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit }) => (
          /*
          This is a temporary fix to keep the ticket data in the state when redirected to the payment screen.
          */
          <Switch>
            <Route path="/compte-rendu/:customerFileId/payment/:visitId" component={Payment} />
            <Route
              path="/compte-rendu/:customerFileId/:productId/bonus-reparation/machine-fonctionnelle/:visitId"
              render={(props) => (
                <ProductFunctionnalStatusScreen
                  {...props}
                  handleNotWorkingChoice={handleBonusVerified}
                />
              )}
            />
            <Route
              path="/compte-rendu/:customerFileId/:productId/bonus-reparation/appliquer/:visitId"
              render={(props) => <ApplyBonus {...props} onApplyBonus={handleBonusVerified} />}
            />
            <Route
              path="/compte-rendu/:customerFileId/product-addition/:visitId"
              component={ProductAddition}
            />
            <Route
              path="/compte-rendu/:customerFileId/product-identification/:productId/:visitId"
              render={(props) => (
                <ProductModelIdentification
                  {...props}
                  onByPassProductIdentification={onByPassProductIdentification}
                  onProductIdentification={onProductIdentification}
                />
              )}
            />
            <Route
              render={() => (
                <form>
                  {stepSlug === 'dashboard' && !isPosted ? (
                    <Dashboard
                      handleSubmit={handleSubmit}
                      customerFileId={customerFileId}
                      isLoading={isLoading}
                      isErrorShown={isErrorShown}
                      visitId={visitId}
                      productId={productId}
                      errorMessage={errorMessage || ''}
                      formUploadProgress={formUploadProgress}
                      productIdentificationByPassed={productIdentificationByPassed}
                      productsWithBonusChecked={productsWithBonusChecked}
                    />
                  ) : (
                    <ReportStepsPlayer
                      customerFileId={customerFileId}
                      visitId={visitId}
                      stepSlug={stepSlug as StepId}
                      productId={productId || ''}
                    />
                  )}
                </form>
              )}
            />
          </Switch>
        )}
      </Form>
    </div>
  );
};

export default RepairReport;
