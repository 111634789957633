import React from 'react';

import ArrowForward from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import FlatCard from 'components/FlatCard';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import VisitTag from 'components/molecules/VisitTag';

import { Visit, VisitLabelProps } from 'types/visits';

import VisitCardContent from './VisitCardContent';
import VisitLabel from './VisitLabel';

interface VisitCardDoneProps {
  visit: Visit;
  handleClick: () => void;
}
const VisitCardDone: React.FunctionComponent<VisitCardDoneProps> = ({
  visit,
  handleClick,
}: VisitCardDoneProps) => {
  const renderLabels = () => {
    let labels: VisitLabelProps[] = [];
    // labels might be incorrectly fomatted
    try {
      labels = JSON.parse(visit.label);
      return labels.map((label_object) => (
        <VisitLabel mr={1} key={label_object.id} label={label_object.label} />
      ));
    } catch {
      return null;
    }
  };
  return (
    <FlatCard data-testid={`visit-${visit.id}`} variant="ternary" onClick={handleClick}>
      <Box display="flex" justifyContent="space-between">
        <VisitCardContent visit={visit} />
        <CheckCircleIcon style={{ color: '#005646' /* new theme green */ }} />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box fontSize={0} display="flex" alignItems="center">
          <VisitTag visitType={visit.visit_type} isLong={visit.time_needed} />
          <ArrowForward fontSize="inherit" />
          {renderLabels()}
        </Box>
        <Text variant="extraSmall" color="grey900">
          {visit.order}
        </Text>
      </Box>
    </FlatCard>
  );
};

export default VisitCardDone;
