import React, { useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

import StockListFilterModal from './StockListFilterModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
  },
  input: {
    flex: 1,
    color: '#8795B3',
    fontSize: 15,
    lineHeight: '140%',
    paddingLeft: 16,
  },
  iconButton: {
    padding: 10,
    color: '#8795B3',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filterButton: {
    background: '#F4F3F8',
    borderRadius: 10,
    color: '#8795B3',
    margin: '0 20px 0 15px',
  },
});

export default function StockListFilter(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filteredLabelList, setFilteredLabelList] = React.useState([]);
  const labelList = [
    { id: 'stock', name: 'Stock embarqué' },
    { id: 'planned', name: 'Planifié' },
  ];

  useEffect(() => {
    return () => {
      // similar to componentWillUnmount in functional components
      props.setFilters({});
    };
  }, []);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSetFilters = () => {
    props.setFilters({ label_name: filteredLabelList });
  };

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Recherche"
        onInput={(e) => props.setFilters({ q: e.target.value })}
      />
      <IconButton className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
      <IconButton className={classes.filterButton} onClick={() => handleOpenModal()}>
        <FilterListIcon />
      </IconButton>
      <StockListFilterModal
        open={open}
        handleCloseModal={handleCloseModal}
        handleSetFilters={handleSetFilters}
        TransitionComponent={Transition}
        labelList={labelList}
        filteredLabelList={filteredLabelList}
        setFilteredLabelList={setFilteredLabelList}
      />
    </Paper>
  );
}
