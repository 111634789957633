import React from 'react';

import Box from 'components/atoms/Box';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import ButtonBlock from 'components/molecules/ButtonBlock';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { formatPrice } from 'utils/formatting';

import failure from '../svg/failure.svg';

type DebitFailureProps = {
  amount: number;
  customerFileId: string;
  visitId: string;
  onChangePaymentMethod: () => void;
};

const DebitFailure: React.FunctionComponent<DebitFailureProps> = ({
  customerFileId,
  visitId,
  amount,
  onChangePaymentMethod,
}: DebitFailureProps) => {
  return (
    <PageLayout>
      <GoBackHeader title="Paiement" />
      <ContentContainer>
        <Box display="flex" justifyContent="center">
          <Image src={failure} alt="preview" width={128} height={128} />
        </Box>
        <Text variant="title1" color={'green'} textAlign="center" mb={1}>
          Oups, le paiement a échoué...
        </Text>
        <Text variant="small" textAlign="center" mb={1}>
          Il semble qu’une erreur soit intervenue lors du paiement mais pas de panique, vous avez
          toujours la possibilité de payer directement par carte bancaire auprès de notre
          technicien.
        </Text>
        <Text variant="bodyBold" textAlign="center">
          Montant dû : {formatPrice(amount)}
        </Text>
      </ContentContainer>
      <Link
        to={`/compte-rendu/${customerFileId}/payment?visit=${visitId}`}
        mb={2}
        color={'grey900'}
      >
        Je n&apos;ai pas pu encaisser
      </Link>
      <ButtonBlock variant="primary" onClick={onChangePaymentMethod}>
        Changer le mode de paiement
      </ButtonBlock>
    </PageLayout>
  );
};

export default DebitFailure;
