import React, { useState } from 'react';

import ConfirmationPortal from 'components/ConfirmationPortal';
import PackageScanner from 'components/Scanner/PackageScanner';

import { Package } from 'types/package';

import { getPackageFromCode } from 'utils/api/api';

type PackageSelectionProps = {
  selectedIds: number[];
  handleValidatePackage: (selectedPackage: Package) => void;
  handleClose: () => void;
  packageFilter?: Record<string, unknown>;
};

const PackageSelection: React.FunctionComponent<PackageSelectionProps> = ({
  handleValidatePackage,
  handleClose,
  packageFilter,
}: PackageSelectionProps) => {
  const [isPackageSelectionOpen, setIsPackageSelectionOpen] = useState<boolean>(true);
  const [selectedPackage, setSelectedPackage] = useState<Package>();
  const [error, setError] = useState<string>();

  const handleConfirmBarcode = async (scannedCode: string) => {
    try {
      const scannedPackage = await getPackageFromCode(scannedCode);

      // checking if the scanned package is a workshop one,
      // in case it is a repairman package, displays an error
      if (packageFilter && 'workshop' in packageFilter && !scannedPackage?.workshop) {
        setError('Ce colis est destiné à la réparation à domicile');
      } else if (packageFilter && !('workshop' in packageFilter) && scannedPackage.workshop) {
        setError('Ce colis est destiné à la réparation en atelier');
      } else {
        setSelectedPackage(scannedPackage);
      }
    } catch (e) {
      setError('Colis inconnu');
    }
  };

  return (
    <>
      {!selectedPackage && isPackageSelectionOpen && (
        <PackageScanner
          onClose={() => handleClose()}
          open={isPackageSelectionOpen}
          handleConfirmBarcode={handleConfirmBarcode}
          handleManualInput={(code: { value: number; label: string }) => {
            return handleConfirmBarcode(code.label);
          }}
          packageFilter={packageFilter}
          error={error}
        />
      )}
      {selectedPackage && (
        <ConfirmationPortal
          onConfirm={() => handleValidatePackage(selectedPackage)}
          onClose={() => {
            setSelectedPackage(undefined);
            setIsPackageSelectionOpen(true);
          }}
          icon={
            <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 0v23.33h23.33V0H0zm16.67 16.67h-10v-10h10v10zM0 56.67V80h23.33V56.67H0zm16.67 16.66h-10v-10h10v10zM56.67 0v23.33H80V0H56.67zm16.66 16.67h-10v-10h10v10z"
                fill="#2B3B5C"
              />
              <path d="M73.33 30v20H56.67v6.67H80V30h-6.67z" fill="#F7B349" />
              <path
                d="M56.67 63.33V80h6.66V70h10v10H80V63.33H56.67zM30 0v6.67h13.33v16.66H50V0H30zM43.33 30v13.33H30v20h13.33V80H50V56.67H36.67V50H50V36.67h6.67v6.66h6.66V30h-20z"
                fill="#2B3B5C"
              />
              <path d="M36.67 70H30v10h6.67V70z" fill="#F7B349" />
              <path d="M23.33 43.33h-10V50h10v-6.67z" fill="#2B3B5C" />
              <path d="M30 13.33V30H0v20h6.67V36.67h30V13.33H30z" fill="#F7B349" />
            </svg>
          }
          buttonText={'Scanner les pièces'}
          backText="Identification de colis"
          body={''}
          open={!!selectedPackage}
          title={`Colis - ${selectedPackage.code}`}
        />
      )}
    </>
  );
};
export default PackageSelection;
