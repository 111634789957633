import React, { ReactNode } from 'react';

import animationData from 'assets/check.json';
// import lottie animations
import Lottie from 'react-lottie';
import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ConfirmationContainer = styled.div((props) => ({
  width: '100%',
  height: '100%',
  padding: `0 ${props.theme.space[3]}px`,
  backgroundColor: props.theme.colors.white,
  boxSizing: 'border-box',
}));

const GreyContainer = styled.div((props) => ({
  background: props.theme.colors.grey200,
  borderRadius: props.theme.radii[1],
  marginBottom: props.theme.space[3],
  padding: props.theme.space[3],
  width: '100%',
  boxSizing: 'border-box',
}));

const BottomButton = styled(Button)((props) => ({
  marginBottom: props.theme.space[2],
  width: '90%',
}));

const ButtonContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
}));

export type ConfirmationPortalProps = {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  title: string;
  body: string;
  buttonText: string;
  icon: ReactNode;
  backText?: string;
};

const ConfirmationPortal: React.FunctionComponent<ConfirmationPortalProps> = ({
  onConfirm,
  onClose,
  open,
  title,
  body,
  buttonText,
  icon,
  backText,
}: ConfirmationPortalProps) => {
  return (
    <PortalComponent open={open} onClose={onClose} title={backText}>
      <ConfirmationContainer>
        <Box pt={80}>
          <Lottie options={animationOptions} height={130} width={130} />
          <Text variant="title2" mt={3}>
            {title}
          </Text>
        </Box>
        <Box mt={8}>
          <GreyContainer>
            {icon}
            <Text variant="body" mt={1}>
              {body}
            </Text>
          </GreyContainer>
        </Box>
      </ConfirmationContainer>
      <ButtonContainer>
        <BottomButton variant={'transparent'} onClick={onConfirm} data-testid="bottom-button">
          {buttonText}
        </BottomButton>
      </ButtonContainer>
    </PortalComponent>
  );
};

export default ConfirmationPortal;
