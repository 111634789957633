type ResourceMap = {
  [key: string]: string | undefined;
};

const RESOURCE_TO_API: ResourceMap = {
  account: 'common/account',
  'acquisition-source': 'murfy-erp/acquisition-source',
  'aswo-barcode': 'sku/aswo-barcode',
  'aswo-devices': 'sku/aswo-devices',
  claim: 'murfy-erp/claim',
  'collection-files': 'murfy-erp/collection-files',
  comment: 'common/comments',
  comments: 'common/comments',
  'customer-file': 'murfy-erp/customer-file',
  'customer-file-history': 'murfy-erp/visit-history',
  'customer-survey': 'murfy-erp/customer-survey',
  deliveries: 'shipping/deliveries',
  'delivery-items': 'shipping/delivery-items',
  'delivery-items-check-list-reports': 'shipping/delivery-item-check-list-reports',
  diagnostics: 'product/diagnostics',
  disassemblies: 'product/disassemblies',
  'disassembly-sku-models': 'product/disassembly-sku-models',
  employee: 'common/employee',
  'employee-incentives': 'common/employee-incentives',
  'employee-statistics-period': 'operations-data-provider/employee-statistics-period',
  'employee-status': 'common/employee-status',
  'fault-forecast': 'murfy-erp/fault-forecast',
  'fault-forecast-detail': 'murfy-erp/fault-forecast/detail',
  'fault-forecast-inbox': 'murfy-erp/fault-forecast/inbox',
  'fault-forecast-report': 'repairman-report/fault-forecast-report',
  incentives: 'murfy-erp/incentives',
  'incentive-statistics': 'murfy-erp/incentive-statistics',
  'incentives-points': 'murfy-erp/incentives-points',
  'incentives-exemplarity': 'murfy-erp/incentives-exemplarity',
  'installation-types': 'product/installation-types',
  'intervention-skipped-report': 'repairman-report/intervention-skipped-report',
  'iris-defects': 'iris-code/defects',
  'iris-sections': 'iris-code/sections',
  'iris-symptoms': 'iris-code/symptoms',
  'iris-conditions': 'iris-code/conditions',
  'iris-repairs': 'iris-code/repairs',
  location: 'murfy-erp/location',
  'my-car': 'murfy-erp/sku-location/my-car',
  opportunity: 'route-management/opportunity',
  package: 'murfy-erp/package',
  pictures: 'ecom/pictures',
  'prev-incentives': 'murfy-erp/incentives',
  products: 'murfy-erp/products',
  'product-models': 'sku/product-models',
  'product-brands': 'product/brands',
  'product-categories': 'product/product-categories',
  'product-check-list-reports': 'product/product-check-list-reports',
  'product-check-list-elements': 'product/check-list',
  'product-suppliers': 'product/suppliers',
  'product-types': 'product/product-types',
  quotes: 'payment/quotebilling',
  'repair-bonus-amount': 'payment/paymentbilling/repair-bonus-amount',
  'repair-package-prices': 'payment/murfy-repair-package-price',
  'repair-reports': 'product/repair-reports',
  'repair-sections': 'product/section-repairs',
  'seven-opteam-repairman-in-deployed-zone': 'route-management/zone/repairman-in-deployed-zone',
  sku: 'murfy-erp/sku',
  'sku-demand-workshop': 'murfy-erp/sku-demand/workshop',
  'sku-demands': 'murfy-erp/sku-demand',
  'sku-inventory-task': 'murfy-erp/sku-inventory-tasks',
  'sku-location': 'murfy-erp/sku-location',
  'sku-model': 'murfy-erp/sku-model',
  'sku-models': 'sku/sku-models',
  'sku-need': 'sku/sku-need',
  'sku-receive': 'murfy-erp/sku-receive',
  'sku-suppliers': 'sku/suppliers',
  'sku-transfer': 'murfy-erp/sku-transfer',
  'spare-parts': 'murfy-erp/spare-parts',
  'spare-parts-count': 'murfy-erp/spare-parts/count',
  'spare-part-check-list-elements': 'spare-part/check-list',
  'spare-part-defects': 'spare-part/defects',
  'spare-part-fitting': 'product/spare-part-fittings/v2',
  'spare-part-packing': 'murfy-erp/spare-parts/packing',
  'spare-part-registration-report': 'spare-part/spare-part-registration-report',
  'task-list': 'common/task',
  visit: 'murfy-erp/visit',
  'seven-opteam-route': 'route-management/seven-opteam-route',
  'visit-payment': 'payment/visit-payment',
  'visit-payment-process': 'payment/visit-payment/process',
  workshops: 'common/workshops',
  'workshop-files': 'murfy-erp/workshop-files',
  'workshop-task': 'murfy-erp/workshop-tasks',
};

export default RESOURCE_TO_API;
