import React from 'react';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

export type ContentModalProps = {
  content: string | string[];
};

const ContentModal: React.FunctionComponent<ContentModalProps> = ({
  content,
}: ContentModalProps) => {
  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map((text) => (
        <Text variant="small" key={text}>
          {text || <br />}
        </Text>
      ));
    }
    return <Text variant="small">{content}</Text>;
  };
  return <Box mb={4}>{renderContent()}</Box>;
};

export default ContentModal;
