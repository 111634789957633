import React, { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import ClaimComponent from 'pages/IncentiveSystem/Claims/Claims';
import { LabelIncentiveSystemFieldWithOldLabel } from 'pages/IncentiveSystem/Components/LabelIncentiveSystemField';
import { StyledTypography } from 'pages/IncentiveSystem/ComponentsFields/StyledTypography';
import {
  groupBy,
  incentiveListSliceCreatedAt,
  renderDate,
} from 'pages/IncentiveSystem/Services/utils';
import ClaimConfirmationScreen from 'pages/IncentiveSystem/Team/Components/ClaimConfirmationScreen';
import IncentiveSystemStyles from 'pages/IncentiveSystem/styles/incentiveSystemStyles';

import { compareDate } from 'utils/dates';

const styleList = makeStyles(IncentiveSystemStyles);

export default function IncentiveSystemUpdate(props) {
  const classes = styleList();

  const handdleDisplayButtonClaim = () =>
    !(props.location && props.location.state && props.location.state.repairman_id);

  const incentiveValues = props.data && Object.values(props.data);
  const dataExcludeRequal =
    incentiveValues &&
    incentiveValues.length > 0 &&
    incentiveValues.filter((incentive) => incentive.point_type !== 'label_requalification');
  const dataExcludeCRUseless =
    dataExcludeRequal &&
    dataExcludeRequal.length > 0 &&
    dataExcludeRequal.filter((incentive) => incentive.label !== 'CR-useless');
  const dataIncentiveListSliceCreatedAt =
    dataExcludeCRUseless &&
    dataExcludeCRUseless.length > 0 &&
    incentiveListSliceCreatedAt(dataExcludeCRUseless);
  const dataFilter = [];
  props.ids &&
    props.ids.map((id) => {
      dataIncentiveListSliceCreatedAt &&
        dataIncentiveListSliceCreatedAt.length > 0 &&
        dataIncentiveListSliceCreatedAt.map((incentive) => {
          if (id === incentive.id) {
            dataFilter.push(incentive);
          }
          return dataFilter;
        });
      return dataFilter;
    });
  const incentiveUpdate =
    dataFilter.length > 0 && dataFilter.filter((data) => data.previous_incentive !== null);
  const dataIncentiveGroupByCreated =
    incentiveUpdate && incentiveUpdate.length > 0 && groupBy(incentiveUpdate, 'created_at');
  const listDate = dataFilter && Object.keys(dataIncentiveGroupByCreated);
  const [itemRequalificationToMadol, setRequalificationToMadol] = useState('');

  const handleClose = () => {
    setRequalificationToMadol('');
  };
  const [isSubmit, setIsSubmit] = useState(false);

  const getPoint = (current, prev) => (current - prev >= 0 ? `+${current - prev}` : current - prev);
  return (
    <div className={classes.containerUpdate}>
      {isSubmit ? (
        <ClaimConfirmationScreen setIsSubmit={setIsSubmit} />
      ) : (
        listDate &&
        listDate.length > 0 &&
        listDate.sort(compareDate).map((dateVisit, index) => (
          <div key={index}>
            <ListSubheader className={classes.updateTitle} key={index}>
              {' '}
              {renderDate(dateVisit)}{' '}
            </ListSubheader>
            {dataIncentiveGroupByCreated[dateVisit].map(
              (incentive, indexId) =>
                incentive.previous_incentive && (
                  <div>
                    <ListItem
                      key={indexId}
                      className={classes.IncentiveSystemListItemUpdate}
                      onClick={() => setRequalificationToMadol(incentive)}
                    >
                      <ListItemIcon className={classes.fieldPrevLabel} key={`prev${indexId}`}>
                        <StyledTypography>
                          {LabelIncentiveSystemFieldWithOldLabel(
                            incentive.previous_incentive.prev_label
                          )}
                        </StyledTypography>
                      </ListItemIcon>
                      <ListItemIcon className={classes.arrowStyle} key={`arrow${indexId}`}>
                        <ArrowRightAltIcon />
                      </ListItemIcon>
                      <ListItemIcon className={classes.fieldUpdate} key={`label${indexId}`}>
                        <StyledTypography>
                          {LabelIncentiveSystemFieldWithOldLabel(incentive.label)}
                        </StyledTypography>
                      </ListItemIcon>
                      <ListItemIcon className={classes.fieldUpdate} key={`lastname${indexId}`}>
                        <StyledTypography>{incentive.lastname}</StyledTypography>
                      </ListItemIcon>
                      <ListItemIcon className={classes.fieldUpdate} key={`points${indexId}`}>
                        {getPoint(incentive.points, incentive.previous_incentive.prev_point)}
                      </ListItemIcon>
                      <Divider />
                    </ListItem>
                    <ClaimComponent
                      openModalClaim={itemRequalificationToMadol}
                      handleClose={handleClose}
                      key={indexId}
                      incentiveData={itemRequalificationToMadol}
                      isScreenUpdateIncentive={handdleDisplayButtonClaim()}
                      setIsSubmit={setIsSubmit}
                    />
                  </div>
                )
            )}
          </div>
        ))
      )}
    </div>
  );
}
