import React, { useEffect, useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import { CenterFocusStrong } from '@material-ui/icons';

import dataProvider from 'dataProvider';
import Quagga from 'quagga';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import CreateSkuModel from 'components/CreateSkuModel/CreateSkuModel';
import PortalComponent from 'components/Portal/PortalComponent';
import Button from 'components/atoms/Button';
import ChoiceButton from 'components/atoms/ChoiceButton';
import Divider from 'components/atoms/Divider';
import FloatingActionButton from 'components/atoms/FloatingActionButton';
import Text from 'components/atoms/Text';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';

import { SkuModel } from 'types/sku-model';
import { Supplier } from 'types/supplier';

import Scanner from 'utils/Scanner';
import { useSkuSuppliers } from 'utils/api/api';

import ConfirmationContainer from './ConfirmationContainer';
import { customStyles } from './CustomStyles';

type OptionType = { label: string; value: string };
type OptionsType = Array<OptionType>;

export type CreateNewSkuProps = {
  initialSupplier?: Supplier;
  handleChangeSupplier?: (supplier: Supplier) => void;
  initialSkuType?: string;
  handleSelectSecondHand?: () => void;
  filterText: string;
  onClose: () => void;
  handleCreateSku: (skuModel: SkuModel) => void;
  open: boolean;
  handleOpenSkuScanner?: () => void;
  referenceList?: OptionsType;
};

const StyledTextField = styled(TextField)((props) => ({
  background: props.theme.colors.white,
  width: 312,
  height: props.theme.sizes[7],
  borderRadius: props.theme.sizes[1],
}));

const BottomButton = styled(Button)((props) => ({
  bottom: props.theme.space[2],
  position: 'absolute',
  width: '90%',
}));

const Container = styled.div((props) => ({
  background: props.theme.colors.grey200,
  height: '100%',
  alignItems: 'center',
  paddingTop: props.theme.space[2],
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));
const ButtonsContainer = styled.div(() => ({
  width: '90%',
  height: '100%',
}));

export const CreateNewSku: React.FunctionComponent<CreateNewSkuProps> = ({
  initialSupplier,
  initialSkuType,
  handleChangeSupplier,
  filterText,
  onClose,
  handleCreateSku,
  open,
  handleOpenSkuScanner,
  referenceList,
  handleSelectSecondHand,
}: CreateNewSkuProps) => {
  const [supplierReference, setSupplierReference] = useState<string>(filterText);
  const [supplier, setSupplier] = useState<Supplier>();
  const [skuType, setSkuType] = useState<string>();
  const [isRefInvalid, setIsRefInvalid] = useState<boolean>(false);
  const [skuModel, setSkuModel] = useState<SkuModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreateNewModelOpen, setIsCreateNewModelOpen] = useState<boolean>(false);
  const [isEditingReference, setIsEditingReference] = useState<boolean>(false);
  const [designation, setDesignation] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [isManualSelect, setManualSelect] = useState(false);

  useEffect(() => {
    if (filterText) {
      handleUpdateSupplierReference(filterText);
    }
    if (initialSupplier) {
      setSupplier(initialSupplier);
    }
    if (supplierReference) {
      handleValidate();
    }
    if (initialSkuType) {
      setSkuType(initialSkuType);
    }
    // eslint-disable-next-line
  }, [filterText, initialSupplier, supplierReference, initialSkuType]);

  const { skuSuppliers } = useSkuSuppliers();

  const handleUpdateSupplierReference = (supplierReference: string) => {
    setSupplierReference(supplierReference);
  };

  const handleCloseModal = () => {
    setSupplier(undefined);
    setSkuType(undefined);
    setSupplierReference('');
    onClose();
  };

  const stopCamera = () => {
    if (Quagga.CameraAccess.getActiveTrack()) {
      Quagga.offDetected();
      Quagga.offProcessed();
      Quagga.stop();
    }
  };

  const handleGoBack = () => {
    if (supplier !== undefined) {
      setSupplier(undefined);
      setSupplierReference('');
      setIsRefInvalid(false);
    } else {
      setSupplierReference('');
      setSkuModel(undefined);
      onClose();
    }
  };

  const isAswoSupplier = (): boolean => supplier?.name.toUpperCase() === 'ASWO';

  const handleValidate = () => {
    if (isAswoSupplier()) {
      if (supplierReference?.length >= 4) {
        handleValidateSupplierReference();
      }
    } else {
      if (designation) {
        setDesignation('');
      }
      handleGetSkuModel();
    }
  };

  const handleValidateSupplierReference = () => {
    setIsLoading(true);
    dataProvider
      .getOne('murfy-erp/aswo-piece', { id: supplierReference })
      .then((response) => {
        let newName = response.data.Artikelbezeichnung;
        if (newName.length > 64) {
          newName = newName.slice(0, 64 - newName.length);
        }
        setDesignation(newName);
        setIsRefInvalid(false);
        handleGetSkuModel();
        setIsLoading(false);
      })
      .catch(() => {
        setIsRefInvalid(true);
        setIsLoading(false);
      });
  };

  const handleGetSkuModel = () => {
    dataProvider
      .getOne('sku-model', {
        supplier: supplier?.name,
        supplier_reference: supplierReference,
        sku_type: skuType,
      })
      .then((response) => {
        if (response.data.length > 0) {
          if (referenceList && referenceList.some((x) => x.value == supplierReference)) {
            onSkuModelValidation(response.data[0]);
          } else {
            handleAfterSkuModelSelection(response.data[0]);
          }
        } else {
          setIsCreateNewModelOpen(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const handleAfterSkuModelSelection = (skuModel: SkuModel) => {
    setIsCreateNewModelOpen(false);
    setSkuModel(skuModel);
    setIsRefInvalid(false);
    setIsLoading(false);
  };

  const onSkuModelValidation = (skuModel: SkuModel) => {
    handleCloseModal();
    handleCreateSku(skuModel);
    setSkuModel(undefined);
  };

  const handleConfirmBarcode = (data: string) => {
    if (data) {
      getAswoModelFromBarcode(data);
    }
  };

  const getAswoModelFromBarcode = (barcode: string) => {
    dataProvider
      .getOne('aswo-barcode', {
        barcode: barcode,
      })
      .then(({ data }) => {
        // Trim spaces added by ASWO at the end of the reference
        setSupplierReference(data?.reference.trim());
        setIsModalOpen(false);
        setError('');
        stopCamera();
      })
      .catch(() => {
        setError(`Erreur code barres: ${barcode}`);
      });
  };

  const selectSecondHand = () => {
    // second hand spare parts are integrated with aswo references
    handleChangeSupplier && handleChangeSupplier(skuSuppliers.filter((x) => x.name === 'ASWO')[0]);
    handleSelectSecondHand && handleSelectSecondHand();
  };

  return (
    <PortalComponent open={open} onClose={handleGoBack} title={'Rechercher un modèle'}>
      {skuModel ? (
        <ConfirmationContainer
          skuModel={skuModel}
          setSkuModel={setSkuModel}
          onSkuModelValidation={onSkuModelValidation}
        />
      ) : (
        <Container>
          {supplier === undefined ? (
            <ButtonsContainer>
              <Text variant="subtitle" p={1}>
                Sélectionner la provenance
              </Text>
              {skuSuppliers && (
                <MultiChoicesButton<string>
                  choices={skuSuppliers.map((x) => {
                    return { value: x.name, name: x.name };
                  })}
                  action={(value: string) => {
                    handleChangeSupplier &&
                      handleChangeSupplier(skuSuppliers.filter((x) => x.name === value)[0]);
                    setSupplier(skuSuppliers.filter((x) => x.name === value)[0]);
                    setManualSelect(false);
                    setError('');
                  }}
                  selectedValue={supplier}
                />
              )}
              {handleSelectSecondHand && (
                <>
                  <Divider mt={2} mx={5} />
                  <Box display="flex" flexDirection="column" mt={2}>
                    <ChoiceButton
                      mx={2}
                      my={1}
                      key="second_hand"
                      onClick={() => selectSecondHand()}
                      style={{ background: '#ddeeff' }}
                    >
                      OCCASION
                    </ChoiceButton>
                  </Box>
                </>
              )}
            </ButtonsContainer>
          ) : (
            <>
              {skuType === 'second_hand' && (
                <Text variant="subtitle" p={4}>
                  Pièce d&apos;occasion
                </Text>
              )}
              {!isEditingReference && (
                <Box mb={4}>
                  <StyledTextField
                    variant="outlined"
                    margin="none"
                    label="Fournisseur"
                    name="Fournisseur"
                    value={supplier.name}
                    disabled
                  />
                </Box>
              )}
              <Box mb={4} width={312}>
                {referenceList && isAswoSupplier() && !isManualSelect && skuType === 'new' ? (
                  <>
                    <Scanner
                      handleConfirmBarcode={handleConfirmBarcode}
                      isModalOpen={isModalOpen}
                    />
                    {error && (
                      <Text variant="extraSmall" color="red" mb={1}>
                        {error}
                      </Text>
                    )}
                    <Text variant="small" onClick={() => setManualSelect(true)} color="grey900">
                      Identifier manuellement
                    </Text>
                  </>
                ) : referenceList ? (
                  <>
                    <CreatableSelect
                      styles={customStyles}
                      isClearable
                      options={referenceList}
                      onChange={(value) => setSupplierReference(value?.value || '')}
                      placeholder={`Référence ${supplier.name}`}
                      onFocus={() => setIsEditingReference(true)}
                      onBlur={() => setIsEditingReference(false)}
                    />
                    {isAswoSupplier() && (
                      <Text
                        variant="small"
                        onClick={() => setManualSelect(false)}
                        color="grey900"
                        mt={8}
                      >
                        Identifier par code barres
                      </Text>
                    )}
                  </>
                ) : (
                  <StyledTextField
                    variant="outlined"
                    margin="none"
                    label={`Référence ${supplier.name}`}
                    value={supplierReference}
                    onChange={(event) => setSupplierReference(event.target.value)}
                    name="Ref"
                  />
                )}
                {isRefInvalid && (
                  <Text variant="small" color="error">
                    Référence ASWO incorrecte
                  </Text>
                )}
              </Box>
              {!isManualSelect && isAswoSupplier()
                ? null
                : !isEditingReference && (
                    <BottomButton
                      variant={'transparent'}
                      onClick={handleValidate}
                      data-testid="bottom-button"
                      disabled={isLoading}
                    >
                      Confirmer
                    </BottomButton>
                  )}
            </>
          )}

          {handleOpenSkuScanner && !isEditingReference && !isAswoSupplier() && (
            <FloatingActionButton page={1} variant="primary" onClick={handleOpenSkuScanner}>
              <CenterFocusStrong data-testid="scan-icon" />
            </FloatingActionButton>
          )}
        </Container>
      )}
      {isCreateNewModelOpen && (
        <CreateSkuModel
          skuSuppliers={skuSuppliers}
          open={isCreateNewModelOpen}
          handleSkuModelCreation={handleAfterSkuModelSelection}
          onClose={() => setIsCreateNewModelOpen(false)}
          initialSupplier={supplier as Supplier}
          initialDesignation={designation}
          initialSupplierReference={supplierReference}
          skuType={skuType}
        />
      )}
    </PortalComponent>
  );
};

export default CreateNewSku;
