import React, { ImgHTMLAttributes, useState } from 'react';

import styled from 'styled-components';

interface Props extends ImgHTMLAttributes<unknown> {
  src: string;
  height: number;
  width: number | string;
}

const StyledImg = styled.img((props) => ({
  width: props.width,
  height: props.height,
  objectFit: 'contain',
}));

const MAX_RETRIES = 5;
const RETRY_BACKOFF_TIME = 5000;

const ImageWithRetry: React.FunctionComponent<Props> = ({ src, height, width }: Props) => {
  const [source, setSource] = useState<string>(src);
  const [retries, setRetries] = useState<number>(0);
  const onError = () => {
    if (retries < MAX_RETRIES) {
      setTimeout(function () {
        setSource(src + '?v=' + retries);
        setRetries(retries + 1);
      }, RETRY_BACKOFF_TIME);
    }
  };

  return <StyledImg height={height} width={width} src={source} onError={() => onError()} />;
};

export default ImageWithRetry;
