import React from 'react';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PrintIcon from '@material-ui/icons/Print';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';

import ListBadge from 'components/ListBadge';
import LoaderPlaceHolder from 'components/LoaderPlaceHolder';
import SecondHandIcon from 'components/atoms/SecondHandIcon';

import { SkuLocation } from 'types/sku-location';
import { SkuModel } from 'types/sku-model';

import StoreStyles from '../StoreStyles';

type SparePart = {
  id: number;
  sku_model: SkuModel;
  is_second_hand: boolean;
  location: SkuLocation;
};
type SparePartReceptionFieldProps = {
  record: SparePart;
  isLoading: boolean;
  classes: Record<string, string>;
  lostSkuList: number[];
  surplusSkuList: number[];
  selectedIds: number[];
  printSku: (SparePart) => void;
  lossClick: (SparePart) => void;
};

export const SparePartReceptionField: React.FunctionComponent<SparePartReceptionFieldProps> = ({
  record,
  isLoading,
  classes,
  lostSkuList,
  surplusSkuList,
  selectedIds,
  printSku,
  lossClick,
}: SparePartFieldProps) => {
  const isSkuLost = () => {
    return lostSkuList.includes(record.id);
  };
  const isSkuSurplus = () => {
    return surplusSkuList.includes(record.id);
  };

  const isSelected = () => {
    return selectedIds.includes(record.id);
  };

  const handlePrintClick = () => {
    printSku(record);
  };

  return isLoading ? (
    <LoaderPlaceHolder size="big" />
  ) : (
    <div className={classes.skuFieldContainer}>
      {isSkuSurplus() || isSelected() ? (
        <ListBadge variant={isSkuSurplus() ? 'orange' : 'green'}>
          <CheckCircleIcon />
        </ListBadge>
      ) : null}
      <div className={classes.SkuFieldSubContainer}>
        <span className={classes.skuFieldRef}>
          <Box display="inline-flex">
            {record.sku_model?.sku_reference}
            {record.is_second_hand && (
              <Box ml={1}>
                <SecondHandIcon />
              </Box>
            )}
          </Box>
        </span>
        <span className={classes.skuFieldDesignation}>
          {record.sku_model && record.sku_model.designation}
        </span>
        <div className={classes.skuFieldLocation}>
          <span>{record.location && record.location.code}</span>
          <LocationOnIcon className={classes.skuFieldIcon} />
          {isSkuLost() && <span className={classes.skuFieldLostText}>Perdu</span>}
        </div>
        {!isSkuLost() && (
          <>
            <ReportOutlinedIcon className={classes.lossIcon} onClick={() => lossClick(record)} />
            <PrintIcon className={classes.printIcon} onClick={handlePrintClick} />
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(StoreStyles)(SparePartReceptionField);
