import React from 'react';

import { SvgIconProps } from '@material-ui/core/SvgIcon';

import Box from 'components/atoms/Box';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

import { countDownInDays, dateToHourOrDate } from 'utils/dates';

import CountDown from './CountDown';

export interface InboxItemProps {
  date: string;
  title: string;
  Icon?: React.FunctionComponent<SvgIconProps>;
  body: string[];
  created_at: string;
}

const InboxItem: React.FunctionComponent<InboxItemProps> = ({
  date,
  title,
  body,
  Icon,
  created_at,
}: InboxItemProps) => {
  return (
    <PaperStrip display="flex" flexDirection="column">
      <Box mb={1} display="flex" justifyContent="space-between">
        <Text variant="bodyBold"> {title} </Text>
        <Text variant="small" color="turquoise">
          {Icon && <Icon fontSize="inherit" />}
        </Text>
      </Box>
      {body.map((text) => (
        <Text key={text} color="grey900" variant="body">
          {text}
        </Text>
      ))}
      <Box mt={2} display="flex" justifyContent="space-between">
        {date && <CountDown value={countDownInDays(date)} />}
        <Text variant="extraSmallBold">{dateToHourOrDate(created_at)}</Text>
      </Box>
    </PaperStrip>
  );
};

export default InboxItem;
