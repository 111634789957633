import { toastr } from 'react-redux-toastr';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';

import convertIntoFormData from 'pages/RepairReport/services/formData';
import { createAuthenticatedUploader } from 'pages/RepairReport/services/networking';

export const POST_FORM_REQUEST = 'Form/POST_FORM_REQUEST';
export const POST_FORM_SUCCESS = 'Form/POST_FORM_SUCCESS';
export const POST_FORM_ERROR = 'Form/POST_FORM_ERROR';

export const SET_IS_LOADING = 'Form/SET_IS_LOADING';
export const RESET_IS_POSTED = 'Form/RESET_IS_POSTED';
export const SET_UPLOAD_PROGRESS = 'Form/SET_UPLOAD_PROGRESS';

// *********************************
// Actions
// *********************************
export function postFormRequest(data) {
  return {
    type: POST_FORM_REQUEST,
    payload: data,
  };
}

export function postFormSuccess() {
  return {
    type: POST_FORM_SUCCESS,
  };
}

export function postFormError(error) {
  return {
    type: POST_FORM_ERROR,
    payload: error,
  };
}

export function setIsLoading() {
  return {
    type: SET_IS_LOADING,
  };
}

export function resetIsPosted() {
  return {
    type: RESET_IS_POSTED,
  };
}

export function* watchOnProgress(channel) {
  while (true) {
    const data = yield take(channel);
    yield put({ type: SET_UPLOAD_PROGRESS, payload: data });
  }
}

export function* postForm(data) {
  const formData = convertIntoFormData(data.payload, ['ticket_attachments']);
  yield put(setIsLoading());
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/tool/ticket-payload/`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };

  const toastrConfig = {
    error: {
      title: 'Erreur',
      message: "Votre message n'a pas été envoyé",
      useApiResponse: true,
    },
  };

  const token = localStorage.getItem('token');
  const [uploadPromise, channel] = createAuthenticatedUploader(config, token);
  yield fork(watchOnProgress, channel);
  try {
    yield call(() => uploadPromise);
    yield put(postFormSuccess());
  } catch (error) {
    yield put(postFormError(error));
    yield call(toastr.error, toastrConfig.error.title);
  }
}

export function* postFormSaga() {
  yield takeEvery(POST_FORM_REQUEST, postForm);
}
/**
 * Following the duck pattern, the module.js file should export a reducer as a default function
 */

const initialState = {
  error: null,
  isErrorShown: false,
  isPosted: false,
  isLoading: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_FORM_REQUEST:
      return {
        ...state,
        error: null,
      };
    case POST_FORM_SUCCESS:
      return {
        ...state,
        isPosted: true,
        isLoading: false,
      };
    case POST_FORM_ERROR:
      return {
        ...state,
        isErrorShown: true,
        isLoading: false,
        error: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_IS_POSTED:
      return {
        ...state,
        isPosted: false,
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
