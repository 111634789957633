import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Transfer from 'pages/Transfer/Transfer';
import WorkshopFilesStock from 'pages/WorkshopFilesStock';
import WorkshopInventory from 'pages/WorkshopInventory/WorkshopInventory';
import WorkshopProductReception from 'pages/WorkshopProductReception/WorkshopProductReception';
import WorkshopRepairCycleDashboard from 'pages/WorkshopRepairCycle/WorkshopRepairCycleDashboard';
import WorkshopRepairCycleScan from 'pages/WorkshopRepairCycle/WorkshopRepairCycleScan';
import WorkshopShipping from 'pages/WorkshopShipping/WorkshopShipping';

const WorkshopRouter: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/workshop/transfer/:stepSlug?" component={Transfer} />
      <Route path="/workshop/inventory/" component={WorkshopInventory} />
      <Route path="/workshop/product-reception/" component={WorkshopProductReception} />
      <Route path="/workshop/stock/" component={WorkshopFilesStock} />
      <Route exact path="/workshop/repair-cycle/" component={WorkshopRepairCycleScan} />
      <Route path="/workshop/repair-cycle/:productId" component={WorkshopRepairCycleDashboard} />
      <Route path="/workshop/expeditions/" component={WorkshopShipping} />
      <Redirect strict from="/workshop*" to="/workshop/transfer" />
    </Switch>
  );
};

export default WorkshopRouter;
