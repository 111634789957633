import React, { useEffect, useRef } from 'react';

import SignaturePad from 'signature_pad';
import styled from 'styled-components';

const StyledCanvas = styled.canvas((props) => ({
  backgroundColor: 'white',
  width: '100%',
  height: 230,
  border: `2px solid ${props.theme.colors.green200}`,
  borderRadius: 8,
  boxSizing: 'border-box',
}));

type SignatureProps = {
  // signature_pad's props
  velocityFilterWeight?: number;
  minWidth?: number;
  maxWidth?: number;
  minDistance?: number;
  dotSize?: number;
  penColor?: string;
  throttle?: number;
  onBegin?: () => void;
  onChange: (b64Data: string | undefined) => void;
  // props specific to the React wrapper
  width: number;
  height?: number;
  bg: string;
  value: string;
};

let signaturePad: SignaturePad;

const SignatureCanvas: React.FunctionComponent<SignatureProps> = ({
  onChange,
  value,
  ...props
}: SignatureProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const handleSave = () => {
    onChange(signaturePad.toDataURL('image/png'));
  };

  useEffect(() => {
    signaturePad = new SignaturePad(canvasRef.current as HTMLCanvasElement);
    Object.assign(signaturePad, { ...props, onEnd: handleSave });
  }, []);

  useEffect(() => {
    // save the canvas each time the user withdraw his finger
    if (signaturePad && value) {
      signaturePad.fromDataURL(value, { width: props.width, height: props.height });
    }
  }, [signaturePad, value]);

  return <StyledCanvas id="canvas" ref={canvasRef} {...props} />;
};

export default SignatureCanvas;
