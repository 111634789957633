import React from 'react';

import { CheckCircle } from '@material-ui/icons';

import styled from 'styled-components';

import { ASWO } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { SparePartRegistrationHistory } from 'types/product-history';
import { SkuModelReferential } from 'types/sku-model-referential';
import { SkuSupplierReference } from 'types/sku-supplier-references';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type SparePartRegistrationBlockProps = {
  sparePartRegistration: SparePartRegistrationHistory;
};

const TurquoiseCheck = styled(CheckCircle)((props) => ({
  color: props.theme.colors.turquoise,
  height: 24,
  width: 24,
}));

const SparePartRegistrationBlock: React.FunctionComponent<SparePartRegistrationBlockProps> = ({
  sparePartRegistration,
}: SparePartRegistrationBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const nbParts = sparePartRegistration.data.spare_part_registrations.length;
  const skuModels =
    sparePartRegistration.data.spare_part_registrations
      .map((sparePartRegistration) => sparePartRegistration.sku_model)
      .flat() || [];
  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={sparePartRegistration.data.created_at}
          assignee={sparePartRegistration.data.assignee}
          title={'Enregistrement'}
          tagVariant={'ternary'}
        />
      </Box>
      {isOpen && (
        <>
          {nbParts > 0 && (
            <SimpleBlockContent noSeparator>
              {skuModels.map((skuModel: SkuModelReferential) => (
                <Box width="100%" mt={1} key={skuModel.id}>
                  <Box justifyContent="space-between" width="100%" display="flex">
                    <Box>
                      <Text variant="smallBold">{skuModel.designation}</Text>
                    </Box>
                    <TurquoiseCheck />
                  </Box>
                  <Text variant="small" color="grey500">
                    {skuModel.supplier_references.find(
                      (skuModelReference: SkuSupplierReference) =>
                        skuModelReference.supplier_name == ASWO
                    )?.reference || 'Pas de référence'}
                  </Text>
                </Box>
              ))}
              <Box
                pt={2}
                width="100%"
                pb={1}
                margin="auto"
                display="flex"
                justifyContent="space-between"
              >
                <Text color="turquoise" variant="body">
                  {nbParts} pièce{nbParts > 1 && 's'} ajoutées au stock
                </Text>
              </Box>
            </SimpleBlockContent>
          )}
          {nbParts < 1 && (
            <SimpleBlockContent noSeparator>
              <Text variant="body">Aucune pièce récupérée</Text>
            </SimpleBlockContent>
          )}
        </>
      )}
    </Box>
  );
};
export default SparePartRegistrationBlock;
