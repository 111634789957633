/* React-admin V3+ example template
* https://gist.github.com/thclark/722ea2bae42db05c38a5c583ba976c52

*/
import { fetchUtils } from 'react-admin';

import { RESOURCE_TO_API } from 'Constants';
import { stringify } from 'query-string';

import { Employee } from 'types/employees';
import { ResolvedType } from 'types/utils';

import { cloudinaryUpload } from 'utils/cloudinaryUpload';

const CLOUDINARY_UPLOAD_FIELDS = ['url'];
const UNPAGINATED_RESOURCES = [
  'acquisition-source',
  'aswo-barcode',
  'aswo-devices',
  'collection-files',
  'comments',
  'deliveries',
  'delivery-items',
  'disassembly-sku-models',
  'employee-incentives',
  'employee-statistics-period',
  'fault-forecast-inbox',
  'incentive-statistics',
  'incentives',
  'incentives-points',
  'incentives-exemplarity',
  'installation-types',
  'iris-conditions',
  'iris-defects',
  'iris-repairs',
  'iris-sections',
  'iris-symptoms',
  'location',
  'package',
  'product-brands',
  'repair-bonus-amount',
  'repair-package-prices',
  'product-categories',
  'product-check-list-elements',
  'product-models',
  'product-suppliers',
  'product-types',
  'products',
  'sku',
  'sku-demands',
  'sku-location',
  'sku-models',
  'sku-suppliers',
  'sku-transfer',
  'spare-parts',
  'spare-part-check-list-elements',
  'spare-parts-count',
  'task-list',
  'visit',
  'seven-opteam-route',
  'workshop-files',
  'workshops',
];

type FetchReturnTypePromise = ReturnType<typeof fetchUtils.fetchJson>;
type FetchReturnType = ResolvedType<FetchReturnTypePromise>;
type DataType = 'json' | 'formData';

// The fetchUtils.Option type expect a
// stringify body when using json
interface fetchJsonOptions extends fetchUtils.Options {
  body?: any; //eslint-disable-line
}

export const fetchJson = (
  url: string,
  options: fetchJsonOptions,
  type: DataType = 'json'
): FetchReturnTypePromise => {
  const fullUrl = `${process.env.REACT_APP_BACKOFFICE_ENDPOINT}/${url}`;
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  // Serialize body
  if (options.body) {
    if (type === 'formData') {
      const formData = new FormData();
      Object.entries(options.body).forEach((value) =>
        formData.append(...(value as [string, string | Blob]))
      );
      options.body = formData;
    } else options.body = JSON.stringify(options.body);
  }
  const token = localStorage.getItem('token');
  (options.headers as Headers).set('Authorization', `Token ${token}`);

  // options.headers.set('X-Custom-Header', 'foobar')
  return fetchUtils.fetchJson(fullUrl, options);
};

const getListFromResponse = (response: FetchReturnType, resource: string) => {
  const { headers, json } = response;
  if (Number.isInteger(json)) {
    return { data: json, total: 1 };
  } else if ('count' in json) {
    return { data: json.results, total: json.count };
  } else if (headers.has('content-range')) {
    const contentRange = headers.get('content-range') as string;
    const totalString = contentRange.split('/').pop() as string;
    return {
      data: json,
      total: parseInt(totalString, 10),
    };
  } else if (UNPAGINATED_RESOURCES.includes(resource)) {
    return { data: json, total: json.count };
  } else if ('detail' in json && json.detail === 'Invalid page.') {
    return { data: [], total: 0 };
  } else {
    throw new Error(
      'The total number of results is unknown. The DRF data provider ' +
        'expects responses for lists of resources to contain this ' +
        "information to build the pagination. If you're not using the " +
        'default PageNumberPagination class, please include this ' +
        'information using the Content-Range header OR a "count" key ' +
        'inside the response.'
    );
  }
};

/**
 * Map resource name to API endpoint.
 * By defaut, the resource name is used as the endpoint if not specified otherwise.
 */
const mapResource = (resource: string): string => {
  return RESOURCE_TO_API[resource] ?? resource;
};

type Sort = { field: string; order: 'DESC' | 'ASC' };
type APIParams = {
  pagination?: {
    page: number;
    perPage: number;
  };
  sort?: Sort;
  barcode?: string;
  id?: number | string;
  ids?: number[];
  supplier?: string;
  supplier_reference?: string;
  sku_reference?: string;
  sku_type?: string;
  data?: any; // eslint-disable-line
  filter?: any; // eslint-disable-line
  code?: string;
  unique_id?: string;
  target?: string;
  employee_log_date?: string;
  employee_id?: number;
  date?: string;
  user_modification?: boolean;
  include?: string[];
  employee?: Employee;
  product?: number;
  q?: string;
  last_quote_from_file_id?: number;
  postal_code?: string;
  product_type?: string;
  brand?: string;
};

export type APIResponse = {
  data: any; // eslint-disable-line
  count?: number;
  status?: number;
};

/**
 * Maps react-admin queries to the default format of Django REST Framework
 * Allows requests without pagination for getList
 */

const CLOUDINARY_CONCERNED_RESOURCES = ['pictures'];

export const getOrdering = ({
  field = 'id',
  order = 'DESC',
}: {
  field: string;
  order: string;
}): string => {
  const prefix = order === 'ASC' ? '' : '-';
  return `${prefix}${field}`;
};

const getList = (resource: string, params: APIParams): Promise<APIResponse> => {
  const options = {};
  const { filter } = params;
  const query = {
    ordering: getOrdering(params.sort || ({} as Sort)),
    ...filter,
  };
  if (params.pagination) {
    query.page = params.pagination.page;
    query.page_size = params.pagination.perPage;
  }
  const url = `${mapResource(resource)}/?${stringify(query)}`;
  return fetchJson(url, options).then((response) => getListFromResponse(response, resource));
};

const getOne = (resource: string, params: APIParams): Promise<APIResponse> => {
  let url = '';
  if (resource === 'customer-file-setup-intent') {
    url = `murfy-erp/customer-file/${params.id}/setup-intent/`;
  } else if (resource === 'seven-opteam-route') {
    url = `${mapResource(resource)}/${params.employee_id}/${params.date}/`;
  } else if (params.barcode) {
    url = `${mapResource(resource)}/?barcode=${params.barcode}`;
  } else if (params.supplier && params.supplier_reference) {
    url = `${mapResource(resource)}/?supplier=${params.supplier}&supplier_reference=${
      params.supplier_reference
    }&sku_type=${params.sku_type}`;
  } else if (params.id) {
    url = `${mapResource(resource)}/${params.id}/`;
  } else if (params.code) {
    url = `${mapResource(resource)}/?code=${params.code}`;
  } else if (params.postal_code) {
    url = `${mapResource(resource)}/?postal_code=${params.postal_code}`;
    if (params.product_type) {
      url += `&product_type=${params.product_type}`;
    }
  } else if (params.unique_id) {
    url = `${mapResource(resource)}/?unique_id=${params.unique_id}`;
  } else if (params.product_type || params.brand) {
    url = `${mapResource(resource)}/?product_type=${params.product_type}&brand=${params.brand}`;
  } else if (params.employee_log_date) {
    url = `${mapResource(resource)}/${params.employee_log_date}/`;
  } else if (params.last_quote_from_file_id) {
    url = `${mapResource(resource)}/${params.last_quote_from_file_id}/latest/`;
  } else {
    url = `${mapResource(resource)}/`;
  }

  if (params.include) {
    url = url + `?include=${params.include.join()}`;
  }
  return fetchJson(url, {}).then((response) => {
    return { data: response.json };
  });
};

const getMany = (resource: string, params: APIParams): Promise<APIResponse> => {
  if (!params.ids) {
    throw Error('ids field must be defined in params to call getMany');
  }
  const options = { method: 'GET' };
  return Promise.all(
    params.ids.map((id) => fetchJson(`${mapResource(resource)}/${id}/`, options))
  ).then((responses) => ({
    data: responses.map((response) => response.json),
  }));
};

const getManyReference = (resource: string, params: APIParams): Promise<APIResponse> => {
  if (!params.target) {
    throw Error('target field must be defined in params to call getManyReference');
  }
  const { page, perPage } = params.pagination ?? {};
  const { field, order } = params.sort ?? {};
  const { filter, target, id } = params;
  const query = {
    page,
    page_size: perPage,
    ordering: `${order === 'ASC' ? '' : '-'}${field}`,
    ...filter,
    [target]: id,
  };
  const url = `${mapResource(resource)}/?${stringify(query)}`;
  const options = {};
  return fetchJson(url, options).then((response) => getListFromResponse(response, resource));
};

const create = (
  resource: string,
  params: APIParams,
  type: DataType = 'json'
): Promise<APIResponse> => {
  const url = `${mapResource(resource)}/`;

  if (CLOUDINARY_CONCERNED_RESOURCES.includes(resource)) {
    // For products update only, upload images to Cloudinary and store the URL in the API
    return cloudinaryManager(params, 'POST', url, type);
  } else {
    const options = {
      method: 'POST',
      body: params.data,
    };
    return fetchJson(url, options, type).then((response) => {
      return { data: response.json, status: response.status };
    });
  }
};

const post = (resource: string, params: APIParams, type: DataType = 'json'): Promise<APIResponse> =>
  create(resource, params, type);

const update = (
  resource: string,
  params: APIParams,
  type: DataType = 'json'
): Promise<APIResponse> => {
  let url = '';
  if (params.employee_log_date) {
    url = `${mapResource(resource)}/${params.employee_log_date}/`;
  } else if (params.user_modification) {
    url = `${mapResource(resource)}/`;
  } else {
    url = `${mapResource(resource)}/${params.id}/`;
  }

  if (CLOUDINARY_CONCERNED_RESOURCES.includes(resource)) {
    // For products update only, upload images to Cloudinary and store the URL in the API
    return cloudinaryManager(params, 'PATCH', url, type);
  } else {
    const options = {
      method: 'PATCH',
      body: params.data,
    };
    return fetchJson(url, options, type).then((response) => {
      return { data: response.json, status: response.status };
    });
  }
};

const put = (
  resource: string,
  params: APIParams,
  type: DataType = 'json'
): Promise<APIResponse> => {
  const url = `${mapResource(resource)}/`;

  const options = {
    method: 'PUT',
    body: params.data,
  };
  return fetchJson(url, options, type).then((response) => {
    return { data: response.json, status: response.status };
  });
};

const updateMany = (resource: string, params: APIParams): Promise<APIResponse> => {
  if (!params.ids) {
    throw Error('ids field must be defined in params to call updateMany');
  }
  const ids = params.ids;

  const getUrl = (id: number): string => {
    if (resource === 'product-loss') {
      return `murfy-erp/products/${id}/loss/`;
    }
    return `${mapResource(resource)}/${id}/`;
  };

  return Promise.all(
    ids.map((id) =>
      fetchJson(getUrl(id), {
        method: resource === 'product-loss' ? 'POST' : 'PATCH',
        body: Array.isArray(params.data) ? params.data[ids.indexOf(id)] : params.data,
      })
    )
  ).then((responses) => ({
    data: responses.map((response) => response.json),
  }));
};

const _delete = (resource: string, params: APIParams): Promise<APIResponse> => {
  const url = `${mapResource(resource)}/${params.id}/`;
  const options = {
    method: 'DELETE',
  };
  return fetchJson(url, options).then((response) => {
    return { data: response.json };
  });
};

const deleteMany = (resource: string, params: APIParams): Promise<APIResponse> => {
  // TODO can we check whether the viewsets need this customisation?
  //  Perhaps we can make a single query like the example in
  //  https://github.com/marmelab/react-admin/blob/v3.0.0-beta.0/docs/DataProviders.md

  if (!params.ids) {
    throw Error('ids field must be defined in params to call deleteMany');
  }

  return Promise.all(
    params.ids.map((id) => fetchJson(`${mapResource(resource)}/${id}`, { method: 'DELETE' }))
  ).then((responses) => ({
    data: responses.map((response) => response.json),
  }));
};

export default {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  post,
  update,
  updateMany,
  delete: _delete,
  deleteMany,
  put,
};

function cloudinaryManager(
  params: APIParams,
  apiMethod: string,
  url: string,
  type: DataType | undefined
): Promise<APIResponse> {
  const uploadPromises: Promise<{ key: string; url: string }>[] = [];
  CLOUDINARY_UPLOAD_FIELDS.forEach(async (key) => {
    if (params.data[key] !== undefined) {
      uploadPromises.push(cloudinaryUpload(key, params.data[key]));
    }
  });
  return Promise.all(uploadPromises)
    .then((uploadedPictures) =>
      uploadedPictures.reduce((acc, current) => ({ ...acc, [current.key]: current.url }), {})
    )
    .then((uploadedPictures) => {
      const options = {
        method: apiMethod,
        body: {
          ...params.data,
          ...uploadedPictures,
        },
      };
      return fetchJson(url, options, type).then((response) => {
        return { data: response.json };
      });
    });
}
