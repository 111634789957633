import React, { useContext, useEffect, useState } from 'react';

import Loader from 'components/Loader';

import { Employee } from 'types/employees';

type EmployeeContextType = {
  employee: Employee;
  storeEmployee: (e: Employee) => void;
  clearEmployee: () => void;
  permissions: string[];
};

const EmployeeContext = React.createContext<EmployeeContextType>({} as EmployeeContextType);

type EmployeeProviderProps = {
  children: React.ReactNode;
};

export const EmployeeProvider: React.FunctionComponent<EmployeeProviderProps> = ({
  children,
}: EmployeeProviderProps) => {
  const [employee, setEmployee] = useState<Employee>({} as Employee);
  const [permissions, setPermissions] = useState<string[]>([]);
  const isEmployeeEmpty = Object.keys(employee).length === 0;

  useEffect(() => {
    const employeeData = localStorage.getItem('employee');
    const permissions = localStorage.getItem('groups');
    if (isEmployeeEmpty && employeeData !== null) {
      setEmployee(JSON.parse(employeeData));
    }
    if (permissions) {
      setPermissions(JSON.parse(permissions));
    }
  }, [employee, isEmployeeEmpty]);

  const storeEmployee = (employeeIn: Employee) => {
    localStorage.setItem('employee', JSON.stringify(employeeIn));
    setEmployee(employeeIn);
  };

  const clearEmployee = () => {
    localStorage.removeItem('employee');
    setEmployee({} as Employee);
  };

  const checkStorageHealth = () => {
    if (
      isEmployeeEmpty &&
      localStorage.getItem('employee') === null &&
      localStorage.getItem('token') !== null
    ) {
      // This is an anomaly, the employee is not set while we are logged in the application
      // We must clear the token to force logout on next interaction
      localStorage.removeItem('token');
    }
    if (!isEmployeeEmpty && localStorage.getItem('employee') === null) {
      // This is another anomaly, the employee is set but not in localStorage
      // We should add it again
      storeEmployee(employee);
    }
  };

  const renderApp = (): React.ReactNode => {
    if (isEmployeeEmpty && localStorage.getItem('employee') !== null) {
      // We do not render the app if we are refreshing, we show a loader instead
      return <Loader />;
    }
    return children;
  };

  checkStorageHealth();

  return (
    <EmployeeContext.Provider value={{ employee, storeEmployee, clearEmployee, permissions }}>
      {renderApp()}
    </EmployeeContext.Provider>
  );
};

export const useEmployee: () => EmployeeContextType = () => useContext(EmployeeContext);
