import React from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ClaimTextAreaStyle = {
  textAreaContainer: {
    display: 'flex',
  },
  basicTextArea: {
    backgroundColor: '#FFFFFF',
    margin: '0px 16px',
    boxSizing: 'border-box',
    borderRadius: 8,
    justifyContent: 'flex-start',
    width: '100%',
    color: '#2B3B5C',
  },
};
const useStyles = makeStyles(ClaimTextAreaStyle);

export default function ClaimTextArea({
  textAreaLabel,
  textPlaceHolder,
  claimRequest,
  setClaimRequest,
}) {
  const classes = useStyles();
  return (
    <div className={classes.textAreaContainer}>
      <TextField
        multiline
        rows={7}
        variant="outlined"
        className={classes.basicTextArea}
        placeholder={textPlaceHolder}
        name={textAreaLabel}
        value={claimRequest}
        onChange={(e) => setClaimRequest(e.target.value)}
      />
    </div>
  );
}
