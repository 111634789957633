import React from 'react';

import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';
import PageLayout from 'components/templates/PageLayout';

type NotRepairableReasonProps = {
  submit: (not_reparable_reason: string) => void;
  back: () => void;
  selectedValue: string | null;
};

export const NotRepairableReason: React.FunctionComponent<NotRepairableReasonProps> = ({
  submit,
  back,
  selectedValue,
}: NotRepairableReasonProps) => {
  return (
    <PageLayout>
      <HeaderWithArrow action={back} title="Non Réparable" />

      <Text m={3} variant="subtitle">
        La raison de non réparablité
      </Text>
      <MultiChoicesButton<string>
        choices={[
          {
            name: 'LL - Elément du bloc laveur HS, décuvage nécessaire',
            value: 'WMBOO',
          },
          {
            name: 'SL - Pompe à chaleur HS, démontage intégral nécessaire',
            value: 'LDHPOO',
          },
          { name: 'Froid - Fuite de gaz', value: 'CGLR600' },
          {
            name: 'Froid - Panne liée à un autre gaz que le R600',
            value: 'CGLO',
          },
        ]}
        action={submit}
        selectedValue={selectedValue}
      />
    </PageLayout>
  );
};

export default NotRepairableReason;
