import React from 'react';

import { MarginProps } from 'styled-system';

import PaperStripWithIcon from '../PaperStripWithIcon';

type PaperStripLinkBaseProps = {
  Icon: React.FunctionComponent;
  SecondaryIcon?: React.FunctionComponent;
  callback: () => void;
} & MarginProps;

type PaperStripLinkWithTitleProps = PaperStripLinkBaseProps & {
  textMode?: 'title';
  text: string;
};

type PaperStripLinkWithContentProps = PaperStripLinkBaseProps & {
  textMode?: 'content';
  text: React.ReactNode;
};

type PaperStripLinkWithActionProps = PaperStripLinkBaseProps & {
  textMode?: 'action';
  text: React.ReactNode;
};

type PaperStripWithIconWithTaskProps = PaperStripLinkBaseProps & {
  textMode?: 'task';
  text: React.ReactNode;
};

type PaperStripLinkWithTaskFulfilledProps = PaperStripLinkBaseProps & {
  textMode?: 'taskFulfilled';
  text: React.ReactNode;
  secondaryText: React.ReactNode;
};

type PaperStripLinkProps =
  | PaperStripLinkWithTitleProps
  | PaperStripLinkWithContentProps
  | PaperStripWithIconWithTaskProps
  | PaperStripLinkWithActionProps
  | PaperStripLinkWithTaskFulfilledProps;
const PaperStripLink: React.FunctionComponent<PaperStripLinkProps> = ({
  callback,
  ...rest
}: PaperStripLinkProps) => {
  return (
    <div onClick={() => callback()}>
      <PaperStripWithIcon {...rest} />
    </div>
  );
};

export default PaperStripLink;
