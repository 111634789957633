import React, { useState } from 'react';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';
import ModalContainer from 'components/organisms/modals/ModalContainer';

interface Choice<T> {
  name: string;
  value: T;
}

export type MultipleChoiceModalProps = {
  title: string;
  onSelect: (value: string) => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  choices: Choice<string>[];
  defaultValue?: string;
  customContent?: React.ReactNode;
  hiddenChoices?: Choice<string>[];
};

const MultipleChoiceModal: React.FunctionComponent<MultipleChoiceModalProps> = ({
  title,
  onSelect,
  onCancel,
  confirmLabel = 'Confirmer',
  cancelLabel = 'Annuler',
  choices,
  defaultValue = choices[0].value,
  customContent,
  hiddenChoices = [],
}: MultipleChoiceModalProps) => {
  const [currentValue, setCurrentValue] = useState<string>(defaultValue);
  const [areHiddenChoicesVisible, setAreHiddenChoicesVisible] = useState(false);
  return (
    <ModalContainer>
      <Text variant="bodyBold" mb={3}>
        {title}
      </Text>
      <MultiChoicesButton<string>
        choices={areHiddenChoicesVisible ? [...choices, ...hiddenChoices] : choices}
        action={setCurrentValue}
        selectedValue={currentValue}
      />

      {hiddenChoices.length > 0 && !areHiddenChoicesVisible && (
        <Box textAlign="initial">
          {customContent}
          <Button onClick={() => setAreHiddenChoicesVisible(true)} variant="fullTransparent">
            <Text variant="buttonSmall">Voir les options supplémentaires</Text>
          </Button>
        </Box>
      )}

      <Box textAlign="center" mt={1}>
        <Button variant="fullTransparent" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onSelect(currentValue);
            onCancel();
          }}
          padding="8px 48px"
        >
          {confirmLabel}
        </Button>
      </Box>
    </ModalContainer>
  );
};

export default MultipleChoiceModal;
