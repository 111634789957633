import React, { useEffect, useState } from 'react';

import { Datagrid, DatagridBody, List, useRefresh } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';

import styled from 'styled-components';

import SparePartDetailField from 'pages/Store/components/SparePartDetailField';

const StyledSkugrid = withStyles({
  table: {
    background: '#F1F0F5',
  },
})(Datagrid);

const StyledList = styled(List)`
  background: #f1f0f5;
  text-align: initial;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 108px;
  overflow: scroll;
`;

const SkuGridRow = (props) => (
  <SparePartDetailField
    checked={props.alreadySelectedIds && props.alreadySelectedIds.includes(props.record.id)}
    sparePart={props.record}
    {...props}
  />
);
const SkuGridBody = (props) => <DatagridBody {...props} row={<SkuGridRow {...props} />} />;
const SkuGrid = (props) => <StyledSkugrid {...props} body={<SkuGridBody {...props} />} />;

export default function SkuList(props) {
  const refresh = useRefresh();
  const { handleSkuSelection, filter, filters } = props;
  const processRowClick = (sku) => {
    handleSkuSelection(sku);
  };
  const [isListLoading, setIsListLoading] = useState(false);

  useEffect(() => {
    if (!props.isModalOpen) {
      refresh();
    }
  }, [props.isModalOpen, refresh]);

  const ListController = ({ loading }) => {
    if (isListLoading !== loading) {
      setIsListLoading(loading);
    }

    return true;
  };
  return (
    <>
      <StyledList
        basePath="/store"
        resource="sku"
        exporter={false}
        filter={filter}
        filters={filters}
        bulkActionButtons={false}
        perPage={100}
        aside={<ListController />}
        actions={false}
      >
        <SkuGrid rowClick={processRowClick} {...props} />
      </StyledList>
    </>
  );
}
