import React from 'react';

import { Box } from '@material-ui/core';

import { Form } from 'formik';

import BottomButton from 'components/atoms/BottomButton';
import Button from 'components/atoms/Button';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';

import { CustomerFile } from 'types/customer-files';

import { usePackagePrices } from 'utils/api/api';

type ProductAdditionProps = {
  customerFile: CustomerFile;
  productType: string;
  isSubmitting: boolean;
  goBack: () => void;
};
const ProductAdditionConfirmation: React.FunctionComponent<ProductAdditionProps> = ({
  customerFile,
  productType,
  isSubmitting,
  goBack,
}: ProductAdditionProps) => {
  const priceObject = usePackagePrices(customerFile.postal_code as string, productType);
  const additionalPackagePrice =
    priceObject && priceObject['murfy_repair_additional_package_price'];

  return (
    <Form>
      <HeaderWithArrow title={"Type d'appareil"} action={() => goBack()} />
      <Box display={'flex'} p={2} justifyContent={'space-between'}>
        <Text variant="bodyBold">Forfait {customerFile.products.length + 1}ème appareil</Text>
      </Box>
      <Box p={2}>
        <Text variant="smallBold">{productType}</Text>
        <Box display={'flex'} justifyContent={'space-between'} mt={1}>
          <Text variant="small">Forfait </Text>
          {additionalPackagePrice && (
            <Text variant="small" ml={3}>
              {additionalPackagePrice / 100}€
            </Text>
          )}
        </Box>
      </Box>
      <Box position="absolute" bottom={80} left="5%" width="90%">
        <Button width="100%" onClick={() => goBack()} variant="transparent" disabled={isSubmitting}>
          Annuler
        </Button>
      </Box>
      <BottomButton type="submit" variant="primary" disabled={isSubmitting}>
        Ajouter un produit
      </BottomButton>
    </Form>
  );
};

export default ProductAdditionConfirmation;
