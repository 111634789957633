import React, { useState } from 'react';

import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

type ContentProps = {
  open: boolean;
};

const TextContent = styled(Text)<ContentProps>`
  width: 100%;
  white-space: ${(props) => (props.open ? '' : 'nowrap')};
  overflow: hidden;
  text-overflow: ${(props) => (props.open ? '' : 'ellipsis')};
`;

export type PaperStripUnfoldsProps = {
  content: string;
  title: string;
} & MarginProps;

const PaperStripUnfolds: React.FunctionComponent<PaperStripUnfoldsProps> = ({
  content,
  title,
  ...rest
}: PaperStripUnfoldsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <PaperStrip onClick={toggleOpen} {...rest}>
      <Text variant="bodyBold">{title}</Text>

      <TextContent variant="body" open={isOpen}>
        {content}
      </TextContent>
    </PaperStrip>
  );
};

export default PaperStripUnfolds;
