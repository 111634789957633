import React from 'react';

import { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';

type OptionType = { label: string; value: string };
type OptionsType = Array<OptionType>;

type AutocompleteInputProps = {
  name: string;
  value: OptionType;
  isClearable: boolean;
  options: OptionsType;
  onChange: (value: OptionType) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder: string;
  onInputChange?: (value: string) => void;
  noOptionsMessage?: () => string;
  formatCreateLabel?: (value: string) => string;
  isCreatableEnabled?: boolean;
};

const customStyles: StylesConfig<OptionType, false> = {
  menu: () => ({
    color: '#073331',
    backgroundColor: '#F6F6F6',
    textAlign: 'left',
  }),
  control: () => ({
    height: 56,
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center',
    textAlign: 'left',
  }),
  option: () => ({
    padding: 16,
    marginBottom: 8,
    backgroundColor: 'white',
    borderRadius: 8,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: () => ({ paddingLeft: 16, width: '100%' }),
  placeholder: () => ({ position: 'absolute', fontSize: 16, color: 'grey', paddingTop: 4 }),
};

const AutocompleteInput: React.FunctionComponent<AutocompleteInputProps> = ({
  name,
  value,
  isClearable,
  options,
  onChange,
  placeholder,
  onFocus,
  onBlur,
  onInputChange,
  noOptionsMessage = () => 'Sélection inconnue',
  formatCreateLabel = (value: string) => `Créer ${value}`,
  isCreatableEnabled = true,
}: AutocompleteInputProps) => {
  return (
    <CreatableSelect
      formatCreateLabel={formatCreateLabel}
      isValidNewOption={() => isCreatableEnabled}
      styles={customStyles}
      name={name}
      value={value}
      isClearable={isClearable}
      options={options}
      onChange={(event) => onChange(event as OptionType)}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      onInputChange={(value) => onInputChange && onInputChange(value)}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default AutocompleteInput;
