import React from 'react';

import { FormikProps } from 'formik';

import { REPAIR_OUTCOMES } from 'pages/RepairCycleTasks/RepairCycleUtils';

import MultiChoicesButton from 'components/molecules/MultiChoicesButton';

type FormattedChecklistElement = {
  id: number;
  text: string;
  is_checked: boolean;
};
type RepairReportFormValue = {
  repair_outcome: string | null;
  section_presence: FormattedChecklistElement[];
  overall_condition: FormattedChecklistElement[];
};

type RepairOutcomeStepProps = {
  props: FormikProps<RepairReportFormValue>;
};

const RepairOutcomeStep: React.FunctionComponent<RepairOutcomeStepProps> = ({
  props,
}: RepairOutcomeStepProps) => {
  return (
    <MultiChoicesButton<string>
      choices={REPAIR_OUTCOMES}
      action={(value) => props.setFieldValue('repair_outcome', value)}
      selectedValue={props.values.repair_outcome}
    />
  );
};

export default RepairOutcomeStep;
