import React from 'react';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { CommentHistory } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type CommentBlockProps = {
  comment: CommentHistory;
};

const CommentBlock: React.FunctionComponent<CommentBlockProps> = ({
  comment,
}: CommentBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={comment.data.created_at}
          assignee={comment.data.author}
          title={`Commentaire`}
          tagVariant={'primary'}
        />
      </Box>
      {isOpen && (
        <SimpleBlockContent noSeparator>
          <Box
            minHeight={5}
            mt={1}
            mb={1}
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="small" color="dark">
              {comment.data.text}
            </Text>
          </Box>
        </SimpleBlockContent>
      )}
    </Box>
  );
};
export default CommentBlock;
