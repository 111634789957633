import React from 'react';

/* eslint-disable react/jsx-key */
import { Route, Switch } from 'react-router-dom';

import GoBackHeader from 'components/molecules/GoBackHeader';
import PageLayout from 'components/templates/PageLayout';

import Calendar from './Calendar';

const EmptyPage: React.FunctionComponent = () => {
  return (
    <PageLayout>
      <GoBackHeader title="Retourner sur le calendrier" />
    </PageLayout>
  );
};

const CalendarSwitch: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path="/calendar" component={Calendar} noLayout />

      <Route path="/calendar/*" component={EmptyPage} noLayout />
    </Switch>
  );
};

export default CalendarSwitch;
