import React from 'react';

import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import { Field, FieldProps } from 'formik';

import Box from 'components/atoms/Box';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

type SparePartsFormElementProps = {
  index: number;
  name: string;
  remove: () => void;
};

const SparePartsFormElement: React.FunctionComponent<SparePartsFormElementProps> = ({
  index,
  name,
  remove,
}: SparePartsFormElementProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Text variant="small" justifyContent="space-between" display="flex" mt={2} key={index}>
        Pièce {index + 1} <RemoveCircleOutlineOutlinedIcon onClick={remove} />
      </Text>
      <Field key={`ref${index}`} name={`${name}.[${index}].reference`}>
        {({ field: { name, onChange, value } }: FieldProps) => {
          return (
            <Input
              placeholder="Référence de la pièce"
              width="100%"
              name={name}
              value={value}
              onChange={onChange}
            />
          );
        }}
      </Field>
      <Field name={`${name}.${index}.designation`} key={`name${index}`}>
        {({ field: { name, onChange, value } }: FieldProps) => {
          return (
            <Input
              placeholder="Nom de la piéce"
              width="100%"
              name={name}
              onChange={onChange}
              value={value}
            />
          );
        }}
      </Field>
    </Box>
  );
};

export default SparePartsFormElement;
