import React from 'react';

import {
  BACK,
  BackEvent,
  VALIDATE_SPARE_PART_NEED,
  ValidateSparPartsNeedeEvent,
} from '../../types';
import SparePartsNeed from './SparePartsNeed';

type SparePartNeedContainerProps = {
  send: (event: ValidateSparPartsNeedeEvent | BackEvent) => void;
  intervention: string;
};

const SparePartNeedContainer: React.FunctionComponent<SparePartNeedContainerProps> = ({
  send,
  intervention,
}: SparePartNeedContainerProps) => {
  const handleSuccess = () =>
    send({
      type: VALIDATE_SPARE_PART_NEED,
      data: {},
    });
  const back = () => {
    send({
      type: BACK,
      data: {},
    });
  };

  return <SparePartsNeed intervention={intervention} onSuccess={handleSuccess} back={back} />;
};

export default SparePartNeedContainer;
