//React imports
import React from 'react';

// MUI imports
import { Typography } from '@material-ui/core';
//Styles imports
import { makeStyles } from '@material-ui/core/styles';

import { useForm } from 'react-final-form';

import RepairReportRadio from 'pages/RepairReport/components/formComponents/RepairReportRadio';
import RepairReportTextArea from 'pages/RepairReport/components/formComponents/RepairReportTextArea';
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
//Service imports
import Condition from 'pages/RepairReport/components/services/ConditionalDisplayRFF';
import { notPayedReasonsArray } from 'pages/RepairReport/services/stepHelper';
import ReportStepStyle from 'pages/RepairReport/styles/ReportStepStyle';

const useStyles = makeStyles(ReportStepStyle);

export default function PaymentStepComponent(props) {
  const form = useForm();
  var formdata = form.getState().values; // accesses directly to the form values

  const classes = useStyles();
  let disabled = false;
  let whyNotPayed = formdata['payment'] && formdata['payment'].why_not_payed;
  let explanation = formdata['payment'] && formdata['payment'].cant_pay_explanation;

  if (
    !whyNotPayed ||
    (whyNotPayed === 'cant_pay' && (!explanation || (explanation && explanation.length < 1)))
  ) {
    disabled = true;
  }

  return (
    <>
      <Typography className={classes.stepQuestion} variant="h6" component="div">
        Pourquoi ?
      </Typography>
      <RepairReportRadio
        radioLabel={'payment.why_not_payed'}
        selectionableValues={notPayedReasonsArray}
      />
      <Condition when="payment.why_not_payed" is="cant_pay">
        <RepairReportTextArea
          textAreaLabel={`cant_pay_explanation`}
          formProductIdReference={'payment'}
          textPlaceHolder={`Explication`}
        />
      </Condition>
      <RepairReportValidateButton
        buttonLabel={`Continuer`}
        validateFunction={props.validateFunction}
        buttonValue="validate"
        step={props.step}
        shouldBeDisabled={disabled}
      />
    </>
  );
}
