import React from 'react';

import { Form, Formik, FormikProps } from 'formik';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
// New imports
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/TextArea';
import ImageUpload from 'components/molecules/ImageUpload';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';

type DefaultDecalarationFormValue = {
  [key: string]: string | { value: string; images: File[] };
};

type DefaultDecalarationFormProps = {
  handleClose: () => void;
  initialValues: DefaultDecalarationFormValue;
  activeStep: number;
  steps: {
    text: string;
    type: string;
    name: string;
    choices?: { value: string; name: string }[];
  }[];
  error: string | undefined;
  handleValidateStep: (values: any) => void;
  isLoading: boolean;
  handleBack: () => void;
  headerText?: string;
};

function _renderStepContent(
  step: number,
  formProps: FormikProps<DefaultDecalarationFormValue>,
  steps: {
    type: string;
    name: string;
    text: string;
    choices?: { value: string; name: string }[];
  }[]
) {
  const actualStep = steps[step];
  let selectedValue = null;
  (Object.keys(formProps.values) as (keyof DefaultDecalarationFormValue)[]).forEach((key) => {
    if (key === actualStep.name && formProps.values[actualStep.name]) {
      selectedValue = formProps.values[actualStep.name];
    }
  });
  switch (actualStep.type) {
    case 'choice':
      return (
        actualStep.choices && (
          <MultiChoicesButton<string>
            choices={actualStep.choices}
            action={(value) => formProps.setFieldValue(actualStep.name, value)}
            selectedValue={selectedValue as string}
          />
        )
      );
    case 'text':
      return (
        <Box mt={4} ml={2} mr={2}>
          <TextArea
            width="100%"
            rows="12"
            name={actualStep.name}
            value={(formProps.values[actualStep.name] as { value: string }).value}
            onChange={(event) =>
              formProps.setFieldValue(actualStep.name, {
                ...formProps.values[actualStep.name],
                value: (event.target as HTMLTextAreaElement).value,
              })
            }
          />
          <ImageUpload
            images={(formProps.values[actualStep.name] as { images: File[] }).images}
            onChange={(files) =>
              formProps.setFieldValue(actualStep.name, {
                ...formProps.values[actualStep.name],
                images: files,
              })
            }
          />
        </Box>
      );
  }
}

const DefaultDecalarationForm: React.FunctionComponent<DefaultDecalarationFormProps> = ({
  handleClose,
  handleValidateStep,
  initialValues,
  activeStep,
  steps,
  isLoading,
  handleBack,
  error,
  headerText = '',
}: DefaultDecalarationFormProps) => {
  function _handleSubmit(values: DefaultDecalarationFormValue) {
    handleValidateStep(values);
  }

  const isLastStep = () => activeStep === steps.length - 1;

  return (
    <>
      <HeaderWithArrow
        title={`Défaut ${headerText}`}
        action={activeStep !== 0 ? handleBack : handleClose}
      />
      <Box overflow="auto" mb={64}>
        <Formik initialValues={initialValues} onSubmit={_handleSubmit} enableReinitialize>
          {({ ...props }: FormikProps<DefaultDecalarationFormValue>) => {
            return (
              <Form>
                <Text pt={2} pb={2} variant="body" margin="auto">
                  {steps[activeStep].text}
                </Text>

                {_renderStepContent(activeStep, props, steps)}
                {isLastStep() && (
                  <Text mt={2} mx="auto" variant="body" color="red">
                    {"Merci de renvoyer la pièce au magasin avec l'étiquette pièce défectueuse"}
                  </Text>
                )}
                {error && (
                  <Text variant="extraSmallBold" color="red">
                    {error}
                  </Text>
                )}
                <BottomButton disabled={!props.isValid || isLoading}>Confirmer</BottomButton>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default DefaultDecalarationForm;
