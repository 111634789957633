import React from 'react';

import WarningIcon from '@material-ui/icons/Warning';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { Defect } from 'types/defects';

type DefectsFieldProps = {
  defects: Defect[];
};

class DefectObject {
  static get BAD_REFERENCE() {
    return 'bad_reference';
  }
  static get INCOMPLETE() {
    return 'incomplete';
  }
  static get BROKEN_DURING_INTERVENTION() {
    return 'broken_during_intervention';
  }
  static get INCORRECTLY_PROGRAMMED() {
    return 'incorrectly_programmed';
  }
  static get MALFUNCTIONNING() {
    return 'malfunctioning';
  }
  static get RECEIVED_BROKEN() {
    return 'received_broken';
  }
  static get DIRTY() {
    return 'dirty';
  }
  static get BAD_QUANTITY() {
    return 'bad_quantity';
  }
}

const translateDefect = (defect: string) => {
  switch (defect) {
    case DefectObject.BAD_REFERENCE:
      return 'Mauvaise référence';
    case DefectObject.INCOMPLETE:
      return 'Incomplète';
    case DefectObject.BROKEN_DURING_INTERVENTION:
      return 'Cassée en intervention';
    case DefectObject.INCORRECTLY_PROGRAMMED:
      return 'Mal programmée';
    case DefectObject.MALFUNCTIONNING:
      return 'Ne fonctionne pas';
    case DefectObject.RECEIVED_BROKEN:
      return 'Pièce reçue cassée';
    case DefectObject.DIRTY:
      return 'Sale';
    case DefectObject.BAD_QUANTITY:
      return 'Mauvaise quantité';
    default:
      return '';
  }
};

const StyledWarningIcon = styled(WarningIcon)((props) => ({
  color: themeGet('colors.red')(props),
  height: props.theme.sizes[3],
  width: props.theme.sizes[3],
}));
const DefectsField: React.FunctionComponent<DefectsFieldProps> = ({
  defects,
}: DefectsFieldProps) => {
  return (
    <Box
      textAlign="left"
      height="fit-content"
      borderRadius={2}
      mb={1}
      color="#F85B5B"
      backgroundColor="rgba(248, 91, 91, 0.15)"
    >
      <Box ml={2} alignItems="center" pt={1} display="flex" mt={1} mb={1}>
        <StyledWarningIcon />
        <Text ml={1} variant="smallBold" color="red">
          Pièce défectueuse
        </Text>
      </Box>
      <Box ml={3} pb={1}>
        {defects.map((defect: Defect, index: number) => (
          <Text key={index} variant="small">
            &bull; {translateDefect(defect.defect)}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default DefectsField;
