import React, { useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';

import dataProvider from 'dataProvider';
import dayjs from 'dayjs';

import { StyledTypography } from 'pages/IncentiveSystem/ComponentsFields/StyledTypography';
import { groupBy } from 'pages/IncentiveSystem/Services/utils';
import ClaimDetails from 'pages/IncentiveSystem/Team/Components/ClaimDetails';
import IncentiveSystemStyles from 'pages/IncentiveSystem/styles/incentiveSystemStyles';

import ClaimConfirmationScreen from './ClaimConfirmationScreen';

const styleList = makeStyles(IncentiveSystemStyles);

export default function ClaimList() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [claimListData, setClaimListData] = useState([]);
  const classes = styleList();

  useEffect(() => {
    if (!isSubmit) {
      dataProvider
        .getList('claim', {
          pagination: { page: 1, perPage: 300 },
          sort: { field: 'id', order: 'DESC' },
          filter: '',
        })
        .then(({ data }) => {
          setClaimListData(data);
        });
    }
  }, [isSubmit]);
  const claimListGroupByTech =
    claimListData && claimListData.length > 0 && groupBy(claimListData, 'lastname_technician');
  const technicianList = claimListGroupByTech && Object.keys(claimListGroupByTech);
  const renderDate = (dateValue) => dayjs(dateValue).locale('fr').format('D/MM');
  const [itemClaim, setItemClaim] = useState('');

  const handleCloseModalClaimDetails = () => {
    setItemClaim('');
  };

  return (
    <>
      {isSubmit ? (
        <div className={classes.wrapperClaimList}>
          {' '}
          <ClaimConfirmationScreen setIsSubmit={setIsSubmit} isManager />{' '}
        </div>
      ) : (
        <>
          {technicianList &&
            technicianList.length > 0 &&
            technicianList.map((LastnameTech, index) => (
              <div key={index}>
                <ListSubheader className={classes.detailTitle} key={index}>
                  {LastnameTech}
                </ListSubheader>
                {claimListGroupByTech[LastnameTech].map((claim, index) => (
                  <div key={index}>
                    <ListItem
                      key={index}
                      className={classes.IncentiveSystemListItemDetails}
                      onClick={() => setItemClaim(claim)}
                    >
                      <ListItemText className={classes.labelStyle}>
                        <StyledTypography>{claim.lastname_customer}</StyledTypography>
                      </ListItemText>
                      <ListItemSecondaryAction className={classes.IncentiveSystemPoints}>
                        {renderDate(claim.created_at)}
                      </ListItemSecondaryAction>
                      <Divider />
                    </ListItem>
                  </div>
                ))}
              </div>
            ))}
          <ClaimDetails
            openModalClaimDetails={!!itemClaim}
            handleCloseModalClaimDetails={handleCloseModalClaimDetails}
            setIsSubmit={setIsSubmit}
            claimDetails={itemClaim}
          />
        </>
      )}
    </>
  );
}
