import React, { useState } from 'react';

import Image from '@material-ui/icons/Image';

import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import Tag from 'components/atoms/Tag';

export interface ApplianceNameplateProps {
  applianceNameplateImageUrl: string;
}

const ImageContainer = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ApplianceNameplate: React.FunctionComponent<ApplianceNameplateProps> = ({
  applianceNameplateImageUrl,
}: ApplianceNameplateProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Tag onClick={() => setIsOpen(true)} Icon={Image} variant="info" text="Plaque signalétique" />
      <PortalComponent title="Plaque signalétique" open={isOpen} onClose={() => setIsOpen(false)}>
        <ImageContainer data-testid="applianceNameplate" src={applianceNameplateImageUrl} />
      </PortalComponent>
    </>
  );
};

export default ApplianceNameplate;
