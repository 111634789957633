import React, { useState } from 'react';

// Libs
import { useQuery } from 'react-admin';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
// material-ui imports
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import ClaimsList from 'pages/IncentiveSystem/Team/Components/ClaimList';
// Style
import IncentiveSystemStyles from 'pages/IncentiveSystem/styles/incentiveSystemStyles';
import RepairmanCalendarTab from 'pages/Team/components/RepairmanCalendarTab';
import RepairmanPerformanceTab from 'pages/Team/components/RepairmanPerformanceTab';

// Components
import BottomBar from 'components/BottomBar/BottomBar';
import PageHeader from 'components/PageHeader';
import PageLayout from 'components/templates/PageLayout';

// Types and utils
import { EmployeeWithPoints } from 'types/employees';

const StyledTab = withStyles({
  root: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '140%',
    padding: '0 16px',
    width: '33%',
  },
})(Tab);
const StyledTabs = withStyles({
  root: {
    background: '#FFFFFF',
  },
  flexContainer: {
    justifyContent: 'space-evenly',
  },
  indicator: {
    backgroundColor: '#FCD660',
    height: 4,
  },
})(Tabs);
const useStyles = makeStyles(IncentiveSystemStyles);
const Team: React.FunctionComponent = () => {
  const { state } = useLocation() as { state?: { indexTab: number } };
  const initialTab = state?.indexTab || 0;
  const [indexTab, setIndexTab] = useState(initialTab);
  const classes = useStyles();
  const { data: repairmanListWithPoints, loading } = useQuery({
    type: 'getList',
    resource: 'employee-incentives',
    payload: {
      pagination: { page: 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: '',
    },
  }) as { data: EmployeeWithPoints[]; loading: boolean };

  const handleChange = (_event: React.ChangeEvent<unknown>, newIndexTab: number) => {
    setIndexTab(newIndexTab);
  };

  const handleChangeIndex = (index: number) => {
    setIndexTab(index);
  };

  return (
    <PageLayout>
      <PageHeader title="Team" />
      <StyledTabs value={indexTab} onChange={handleChange}>
        <StyledTab label="AGENDA" />
        <StyledTab label="PERF." />
        <StyledTab label="RÉCLAMATIONS" />
      </StyledTabs>
      <div className={classes.IncentiveSystemContainerActve}>
        {!loading && (
          <SwipeableViews axis="x" index={indexTab} onChangeIndex={handleChangeIndex}>
            <RepairmanCalendarTab repairmanList={repairmanListWithPoints} />
            <RepairmanPerformanceTab repairmanListWithPoints={repairmanListWithPoints} />
            <ClaimsList />
          </SwipeableViews>
        )}
      </div>

      <BottomBar />
    </PageLayout>
  );
};

export default Team;
