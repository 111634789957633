// React imports
import React, { useState } from 'react';

// Component imports
import { Button, Typography } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
// Style imports
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
// Material-ui icons imports
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import dataProvider from 'dataProvider';

import RepairmanScanModal from 'pages/RepairmanStock/components/RepairmanScanModal';
import FloatingActionButton from 'pages/Store/components/FloatingActionButton';

import CreateNewSku from 'components/CreateNewSku/CreateNewSku';

import TransferSkuAdditionStyle from '../styles/TransferSkuAdditionStyle';
import SkuAddedList from './SkuList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(TransferSkuAdditionStyle);

export default function RepairReportSkuAddition(props) {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // States related to RepairManScan Modal
  const [error, setError] = useState('');
  const [isScanLoading, setIsScanLoading] = useState(false);

  // State related to CreateNewSku Modal
  const [isCreateNewSkuModalOpen, setIsCreateNewSkuModalOpen] = useState(false);

  const isSkuAlreadyAdded = (skuId) => {
    if (props.skus && props.skus.length > 0) {
      for (var i = 0; i < props.skus.length; i++) {
        if (skuId === props.skus[i].id) {
          return true;
        }
      }
    }
    return false;
  };

  const handleAddSku = (sku) => {
    if (isSkuAlreadyAdded(sku.id)) {
      setIsModalOpen(false);
    } else {
      props.addSkuInForm(sku);
      handleClose();
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setError('');
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const removeSku = (sku) => {
    props.removeSku(sku.id);
  };

  const handleCloseCreateNewSkuModal = () => {
    setIsCreateNewSkuModalOpen(false);
  };

  const handleOpenCreateNewSku = () => {
    setIsModalOpen(false);
    setIsCreateNewSkuModalOpen(true);
  };

  const handleFetchSku = (data, mode) => {
    setIsScanLoading(true);
    dataProvider
      .getOne('sku', mode === 'barcode' ? { unique_id: data } : { id: data })
      .then((response) => {
        const sku = mode === 'barcode' ? response.data[0] : response.data;
        if (sku) {
          if (!isSkuAlreadyAdded(sku.id)) {
            handleAddSku(sku);
          } else {
            setError('Erreur sku : ' + data);
          }
        } else {
          setError('Erreur sku : ' + data);
        }
        setIsScanLoading(false);
      })
      .catch((e) => {
        setError('Erreur sku : ' + data);
        setIsScanLoading(false);
        console.error(e);
      });
  };

  const handleScanSku = (data) => {
    handleFetchSku(data, 'barcode');
  };

  const handleSelectSku = (data) => {
    handleFetchSku(data, 'manualSelect');
  };

  const handleCreateSku = (model) => {
    dataProvider
      .create('sku', {
        data: {
          sku_model: model.id,
          status: 'received_tech',
          tech_location: 'on',
        },
      })
      .then((response) => {
        const sku = response.data;
        sku.sku_model = model;
        handleAddSku(sku);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className={classes.paddingContainer}>
      <Typography variant="h1" style={{ paddingLeft: 0 }}>
        Transfert
      </Typography>
      <Typography variant="h6">{`${props.nbSkus} pièce(s) sélectionnée(s)`}</Typography>
      {props.nbSkus === 0 ? (
        <div className={classes.dashedSquare}>
          <div className={classes.clickableDiv}>
            <CenterFocusStrongIcon
              id="add-new-sku-button-0"
              className={classes.scanNewSkuButton}
              onClick={handleOpen}
            />
            <div>Transférer une pièce</div>
          </div>
        </div>
      ) : (
        <div>
          <SkuAddedList skus={props.skus} removeSku={removeSku} />
          <div className={classes.fabAddedList}>
            <FloatingActionButton onClick={handleOpen} color="secondary" icon={<AddIcon />} />
          </div>
          <Button className={classes.validateButton} onClick={props.openLocationModal}>
            Confirmer
          </Button>
        </div>
      )}

      <RepairmanScanModal
        mode="skuTransfer"
        error={error}
        bodyText="Prenez l'ensemble de l'étiquette, veillez à la netteté."
        headerText="Scannez une pièce"
        manualHeaderText="Sélectionnez une pièce"
        TransitionComponent={Transition}
        handleConfirmBarcode={handleScanSku}
        onClose={handleClose}
        open={isModalOpen}
        isScanLoading={isScanLoading}
        addManualSku={handleSelectSku}
        excludeList={props.skus.map((el) => el.id)}
        addManualSkuCreation={handleOpenCreateNewSku}
      />
      <CreateNewSku
        TransitionComponent={Transition}
        open={isCreateNewSkuModalOpen}
        onClose={handleCloseCreateNewSkuModal}
        handleCreateSku={handleCreateSku}
      />
    </div>
  );
}
