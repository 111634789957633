// React imports
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
// Material ui imports
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { SparePart } from 'types/spare-part';

// Style imports
import TransferSkuAdditionStyle from '../styles/TransferSkuAdditionStyle';

const useCreationStyles = makeStyles(TransferSkuAdditionStyle);

type SkuListProps = {
  skus: SparePart[];
  removeSku: (sku: SparePart) => void;
};

const SkuList: React.FunctionComponent<SkuListProps> = ({ skus, removeSku }: SkuListProps) => {
  const creationClasses = useCreationStyles();

  return (
    <div className={creationClasses.SkuListContainer}>
      {skus &&
        skus.length > 0 &&
        skus.map((sku, index) => (
          <div key={index} className={creationClasses.SkuItem}>
            <div className={creationClasses.skuItemText}>
              {`${sku.sku_model.sku_reference} - ${sku.sku_model.designation}`}
            </div>
            <RemoveCircleOutlineIcon
              id={`remove-icon-${index}`}
              className={creationClasses.removeIcon}
              onClick={() => removeSku(sku)}
            />
          </div>
        ))}
    </div>
  );
};

export default SkuList;
