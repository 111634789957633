import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

import styled from 'styled-components';

import { IRREPARABLE, OPERATIONAL, REPAIR_OUTCOMES } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { ProductCheckListReportItem } from 'types/product-check-list-report-item';
import { ProductCheckListReport, RepairReportHistory } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type RepairReportBlockProps = {
  repairReport: RepairReportHistory;
};

const GreenCheck = styled(CheckCircleTwoToneIcon)((props) => ({
  color: props.theme.colors.green,
  height: 48,
  width: 48,
}));

const RedCross = styled(CancelTwoToneIcon)((props) => ({
  color: props.theme.colors.red,
  height: 48,
  width: 48,
}));

const TinyRedCross = styled(CancelIcon)((props) => ({
  color: props.theme.colors.red,
  height: 16,
  width: 16,
}));

const OrangeWarning = styled(WarningTwoToneIcon)((props) => ({
  color: props.theme.colors.orange,
  height: 48,
  width: 48,
}));

const RepairReportBlock: React.FunctionComponent<RepairReportBlockProps> = ({
  repairReport,
}: RepairReportBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const outcome = REPAIR_OUTCOMES.find(
    (outcome: { id: number; name: string; value: string }) =>
      outcome.value === repairReport.data.repair_outcome?.repair_outcome
  );

  const problematicItems = repairReport.data.checklists
    ?.map((checklist: ProductCheckListReport) => {
      return [...checklist.items.filter((item: ProductCheckListReportItem) => !item.is_checked)];
    })
    .flat(1);

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={repairReport.data.created_at}
          assignee={repairReport.data.assignee}
          title={`Fin de réparation`}
          tagVariant={'success'}
        />
      </Box>
      {isOpen && (
        <SimpleBlockContent noSeparator>
          <Box
            height={6}
            mt={2}
            mb={outcome ? 2 : 0}
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text variant="smallBold" color="dark">
                {outcome?.name || 'Problème fin de réparation'}
              </Text>
              {outcome?.value === OPERATIONAL && (
                <Text variant="caption" color="dark">
                  Appareil vérifié
                </Text>
              )}
            </Box>

            {outcome?.value === IRREPARABLE && <RedCross />}
            {outcome?.value === OPERATIONAL && <GreenCheck />}
            {!outcome && <OrangeWarning />}
          </Box>

          {!outcome && (
            <Box width="100%" mb={1} mr={2}>
              {problematicItems?.map((item: ProductCheckListReportItem | undefined, index) => {
                return (
                  <Box key={`${index}`} display="flex" justifyContent="space-between" mt={2}>
                    <Text key={`${index}`} variant="small" color="dark">
                      {item?.text}
                    </Text>
                    <TinyRedCross />
                  </Box>
                );
              })}
            </Box>
          )}
        </SimpleBlockContent>
      )}
    </Box>
  );
};
export default RepairReportBlock;
