import {
  listRepairReportLabel,
  listRepairReportLabelWithOldLabel,
} from 'pages/IncentiveSystem/Services/LabelList';

export const LabelIncentiveSystemField = (label) => {
  return (
    listRepairReportLabel.find((repairLabel) => label === repairLabel.id) &&
    listRepairReportLabel.find((repairLabel) => label === repairLabel.id).name
  );
};

export const LabelIncentiveSystemFieldWithOldLabel = (label) => {
  return (
    listRepairReportLabelWithOldLabel.find((repairLabel) => label === repairLabel.id) &&
    listRepairReportLabelWithOldLabel.find((repairLabel) => label === repairLabel.id).name
  );
};

export const LabelExemplarityIncentiveFunctionField = (incentive, incentivePoints) => {
  return (
    incentivePoints.find(
      (labelToBeDisplayed) => incentive.label === labelToBeDisplayed.label_key
    ) &&
    incentivePoints.find((incentivePoint) => incentive.label === incentivePoint.label_key)
      .label_name
  );
};
