import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import PageHeader from 'components/PageHeader';

import StoreInventory from './components/StoreInventory';
import StorePacking from './components/StorePacking';
import StoreReception from './components/StoreReception';
import StoreTransfer from './components/StoreTransfer';

const StyledTab = styled(Tab)((props) => ({
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '140%',
  padding: `0 ${props.theme.space[1]}`,
}));
const StyledTabs = styled(Tabs)((props) => ({
  flexContainer: {
    justifyContent: 'space-between',
  },
  indicator: {
    backgroundColor: props.theme.colors.green,
    height: 4,
  },
}));

const StoreContainer = styled.div((props) => ({
  height: '100vh',
  backgroundColor: props.theme.colors.grey200,
}));
const StoreAppBar = styled(AppBar)((props) => ({
  background: props.theme.colors.white,
  color: props.theme.colors.dark,
  boxShadow: 'none',
  borderRadius: 'unset',
  width: '100vw',
}));

const SubContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.grey200,
  position: 'relative',
  width: '100vw',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const TabIndex = [
  {
    id: 0,
    url: '/store/reception',
  },
  {
    id: 1,
    url: '/store/picking',
  },
  {
    id: 2,
    url: '/store/transfer',
  },
  {
    id: 3,
    url: '/store/inventory',
  },
];

const Store: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const getUrlFromTabIndex = (id: number) => {
    return TabIndex.filter((item) => item.id === id)[0]?.url;
  };
  const getTabIndexFromUrl = (url: string) => {
    return TabIndex.filter((item) => item.url === url)[0]?.id;
  };

  const handleChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ): void => {
    history.push(getUrlFromTabIndex(newValue));
  };

  const handleChangeIndex = (newValue: number): void => {
    history.push(getUrlFromTabIndex(newValue));
  };

  return (
    <StoreContainer>
      <StoreAppBar position="relative">
        <PageHeader title="Magasin pièce" />

        <StyledTabs value={getTabIndexFromUrl(location.pathname)} onChange={handleChange}>
          <StyledTab label="RÉCEPTION" />
          <StyledTab label="COLISAGE" />
          <StyledTab label="TRANSFERT" />
          <StyledTab label="INVENTAIRE" />
        </StyledTabs>
      </StoreAppBar>
      <SubContainer>
        <SwipeableViews
          value={getTabIndexFromUrl(location.pathname)}
          onChangeIndex={handleChangeIndex}
        >
          <Switch>
            <Route path="/store/reception" component={StoreReception} />
            <Route path="/store/picking" component={StorePacking} />
            <Route path="/store/transfer" component={StoreTransfer} />
            <Route path="/store/inventory" component={StoreInventory} />
            <Redirect from="/store*" to={TabIndex[0].url} />
          </Switch>
        </SwipeableViews>
      </SubContainer>
    </StoreContainer>
  );
};

export default Store;
