import { transparentize } from 'polished';
import styled from 'styled-components';

const PageVeil = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${transparentize(0.85, 'black')};
  /**
   * Unfortunately, this is necessary to have such a high z-index value.
   * It ensures the modal is above every other component.
   * This should be changed once we have correctly refactored the other components.
   */
  z-index: 100000;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PageVeil;
