import React from 'react';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';
import { FieldArray, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import Button from 'components/atoms/Button';
import ButtonBlock from 'components/molecules/ButtonBlock';
import ContentContainer from 'components/templates/ContentContainer';

import { sendGAEvent } from 'utils/google-analytics';

import SkuFormElement from './SparePartsFormElement';

const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
type FormValues = {
  SparePartNeed: [{ reference: string; designation: string }];
};
type SparePartsNeedProps = {
  onSuccess: () => void;
  intervention: string;
};
const validationSchema = yup.object().shape({
  SparePartNeed: yup
    .array()
    .of(
      yup.object().shape({
        designation: yup.string().required('Requis'),
        reference: yup.string().required('Requis'),
      })
    )
    .required('Il manque les infos de la pièce')
    .min(1, 'Minimum une piece'),
});
const SparePartsForm: React.FunctionComponent<SparePartsNeedProps> = ({
  onSuccess,
  intervention,
}: SparePartsNeedProps) => {
  const { employee } = useEmployee();
  const createSkuNeed = (values: FormValues) => {
    return dataProvider
      .create('sku-need', {
        data: values.SparePartNeed.map((sku) => ({
          ...sku,
          intervention,
        })),
      })
      .then(() => {
        onSuccess();
      });
  };
  const initialValues: FormValues = {
    SparePartNeed: [{ designation: '', reference: '' }],
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createSkuNeed}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, isValid }) => (
        <StyledForm>
          <FieldArray name="SparePartNeed">
            {({ remove, push, name }) => (
              <ContentContainer>
                {values.SparePartNeed.length > 0 &&
                  values.SparePartNeed.map((sku, index) => (
                    <SkuFormElement
                      name={name}
                      index={index}
                      remove={() => remove(index)}
                      key={index}
                    />
                  ))}
                <Button
                  onClick={() => push({ sparePartName: '', sparePartRef: '' })}
                  type="button"
                  variant="transparent"
                  width="100%"
                  mt={2}
                >
                  + Demander une nouvelle pièce
                </Button>
              </ContentContainer>
            )}
          </FieldArray>
          <ButtonBlock
            onClick={() =>
              sendGAEvent(
                'prequal-spare-parts-need',
                'prequal',
                new Date().toDateString(),
                `${employee.user?.username}`
              )
            }
            disabled={!isValid}
          >
            Soumettre
          </ButtonBlock>
        </StyledForm>
      )}
    </Formik>
  );
};

export default SparePartsForm;
