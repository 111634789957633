import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import dayjs from 'dayjs';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BottomBar from 'components/BottomBar/BottomBar';
import IconText from 'components/IconText';
import Header from 'components/atoms/Header';
import InfiniteDateSelector, { useDateSelector } from 'components/organisms/InfiniteDateSelector';
import RepairmanVisitList from 'components/organisms/RepairmanVisitList';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { useRepairman } from 'utils/api/api';

const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
`;

type RepairmanCalendarMatchParams = {
  repairmanId: string;
};

type RepairmanCalendarProps = RouteComponentProps<RepairmanCalendarMatchParams>;

const RepairmanCalendar: React.FunctionComponent<RepairmanCalendarProps> = ({
  match: {
    params: { repairmanId },
  },
}: RepairmanCalendarProps) => {
  const { selectedDate, selectedWeekIndex, handleDateChange, onWeekChange } = useDateSelector(
    dayjs().format('YYYY-MM-DD'),
    0
  );
  const history = useHistory();

  const { repairman } = useRepairman(Number(repairmanId));

  const goBackToTeam = () => {
    history.push('/team');
  };

  return (
    <PageLayout>
      <Header>
        <IconText
          variant="subtitle"
          icon={<ArrowBackIcon style={{ marginRight: '8px' }} />}
          text={repairman?.first_name}
          mb={1}
          onClick={goBackToTeam}
        />
        <InfiniteDateSelector
          selectedDate={selectedDate}
          selectedWeekIndex={selectedWeekIndex}
          onWeekChange={onWeekChange}
          onDateChange={handleDateChange}
        />
      </Header>
      <Container>
        <RepairmanVisitList selectedDate={selectedDate} repairmanId={parseInt(repairmanId)} />
      </Container>
      <BottomBar />
    </PageLayout>
  );
};

export default RepairmanCalendar;
