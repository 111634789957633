import React, { useState } from 'react';

import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircle from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ExtensionIcon from '@material-ui/icons/Extension';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FlareIcon from '@material-ui/icons/Flare';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import WarningIcon from '@material-ui/icons/Warning';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import RepairCycleHistory from 'pages/RepairCycleTasks/RepairCycleHistory/RepairCycleHistory';
import RepairCycleTaskScreen from 'pages/RepairCycleTasks/RepairCycleTaskScreen';
import {
  CLEANING,
  COMMENT,
  DIAG,
  DISASSEMBLY,
  IRREPARABLE,
  OPERATIONAL,
  PHOTO,
  REFURBISHING_DECISION,
  REPAIR_ACTION,
  REPAIR_REPORT,
  SCRAP,
  SCRAP_PRODUCT,
  SECTION_WEAR,
  SKU_DEMAND,
  SPARE_PART_REGISTRATION_REPORT,
} from 'pages/RepairCycleTasks/RepairCycleUtils';
import { useIrisCodes } from 'pages/RepairCycleTasks/hooks';
import { useProductHistory } from 'pages/RepairCycleTasks/hooks';

import Loader from 'components/Loader';
import ProductInfoBlock from 'components/RepairCycleBlocks/ProductInfoBlock';
import SimpleBlockContent from 'components/RepairCycleBlocks/SimpleBlocks/SimpleBlockContent';
import SpeedDial from 'components/SpeedDial/SpeedDial';
import SpeedDialAction from 'components/SpeedDial/SpeedDialAction';
import SpeedDialIcon from 'components/SpeedDial/SpeedDialIcon';
import { TypeformDefaultSideTab } from 'components/TypeformComponents';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import GoBackHeader from 'components/molecules/GoBackHeader';

import { useProduct } from 'utils/api/api';

const Container = styled.div(() => ({
  height: '100vh',
}));

const StyledSpeedDial = styled(SpeedDial)((props) => ({
  position: 'fixed',
  bottom: props.theme.space[9],
  right: props.theme.space[2],
}));

type RepairCycleMatchParams = {
  productId: string;
};

type WorkshopRepairCycleDashboardProps = RouteComponentProps<RepairCycleMatchParams>;

const WorkshopRepairCycleDashboard: React.FunctionComponent<WorkshopRepairCycleDashboardProps> = (
  props: WorkshopRepairCycleDashboardProps
) => {
  const history = useHistory();
  const productId = Number(props.match.params.productId);
  const { product, loadingProduct } = useProduct(productId);
  const { irisSymptoms, irisSections, irisRepairs, irisDefects, irisConditions } = useIrisCodes();
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [isTaskScreenOpen, setIsTaskScreenOpen] = useState<boolean>(false);
  const productHistoryData = useProductHistory(productId, !isTaskScreenOpen);
  const [sideBarVisible, setSideBarVisible] = useState(true);

  const { defaultForm } = useFlags();
  const hasDiagnostic = () => {
    if (!productHistoryData) return false;
    return productHistoryData.some((element) => element.type === DIAG);
  };

  const getRepairReports = () => {
    return (
      productHistoryData && productHistoryData.filter((element) => element.type === REPAIR_REPORT)
    );
  };

  const isRepaired = () => {
    const repairReports = getRepairReports();
    return repairReports && repairReports[0]?.data?.repair_outcome?.repair_outcome === OPERATIONAL;
  };

  const isIrreparable = () => {
    const repairReports = getRepairReports();
    return (
      (repairReports && repairReports[0]?.data?.repair_outcome?.repair_outcome === IRREPARABLE) ||
      (productHistoryData &&
        productHistoryData.some(
          (element) =>
            element.type === REFURBISHING_DECISION && element.data.decision === SCRAP_PRODUCT
        ))
    );
  };

  const isDisassembled = () => {
    if (!productHistoryData) return false;
    return productHistoryData.some((element) => element.type === DISASSEMBLY);
  };

  const isInSourcingLocation = () => {
    return product?.location_usage === 'sourcing';
  };

  const isInSparePartRecyclingLocation = () => {
    return product?.location_usage === 'spare_part_recycling';
  };

  const isInWorkshop = () => {
    return !!product.location_workshop_name;
  };

  const openSpecificTask = (task: string) => {
    setSelectedTask(task);
    setIsTaskScreenOpen(true);
    setIsSpeedDialOpen(false);
  };

  const handleCloseTaskScreen = () => {
    setSelectedTask(null);
    setIsTaskScreenOpen(false);
  };

  const actions = [
    {
      icon: <SaveAltIcon />,
      tooltipTitle: `Enregistrement pièces`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(SPARE_PART_REGISTRATION_REPORT),
      isDisplayed: () => isDisassembled(),
    },
    {
      icon: <AllInclusiveIcon />,
      tooltipTitle: `Valorisation`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(DISASSEMBLY),
      isDisplayed: () =>
        isInSparePartRecyclingLocation() ||
        isInSourcingLocation() ||
        isDisassembled() ||
        isIrreparable(),
    },
    {
      icon: <PhotoCameraIcon />,
      tooltipTitle: `Photos`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(PHOTO),
      isDisplayed: () => !isDisassembled() && !isIrreparable(),
    },
    {
      icon: <FlareIcon />,
      tooltipTitle: `Nettoyage`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(CLEANING),
      // Allow cleaning task for products created before the repair cycle
      isDisplayed: () =>
        (product && product.created_at < '2021-09-01') || (isRepaired() && !isDisassembled()),
    },
    {
      icon: <CheckCircle />,
      tooltipTitle: 'Fin de réparation',
      tooltipOpen: true,
      onClick: () => openSpecificTask(REPAIR_REPORT),
      isDisplayed: () => hasDiagnostic() && !isDisassembled(),
    },
    {
      icon: <ExtensionIcon />,
      tooltipTitle: 'Réparation',
      tooltipOpen: true,
      onClick: () => openSpecificTask(REPAIR_ACTION),
      isDisplayed: () => hasDiagnostic() && !isDisassembled(),
    },
    {
      icon: <FindInPageIcon />,
      tooltipTitle: 'Besoin de pièce(s)',
      tooltipOpen: true,
      onClick: () => openSpecificTask(SKU_DEMAND),
      isDisplayed: () => hasDiagnostic() && !isDisassembled(),
    },
    {
      icon: <WarningIcon />,
      tooltipTitle: 'Symptômes & diag.',
      tooltipOpen: true,
      onClick: () => openSpecificTask(DIAG),
      isDisplayed: () => !isDisassembled(),
    },
    {
      icon: <PlaylistAddCheckIcon />,
      tooltipTitle: `Contrôle des pièces`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(SECTION_WEAR),
      isDisplayed: () => !isDisassembled(),
    },
    {
      icon: <ChatIcon />,
      tooltipTitle: `Commentaire`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(COMMENT),
      isDisplayed: () => !isDisassembled(),
    },
    {
      icon: <DeleteIcon />,
      tooltipTitle: `Déclarer un rebut`,
      tooltipOpen: true,
      onClick: () => openSpecificTask(SCRAP),
      isDisplayed: () => !isRepaired(),
    },
  ];

  if (loadingProduct && !product) {
    return <Loader />;
  }

  const speedDialProps = {
    ariaLabel: 'repairCycleTask',
    icon: <SpeedDialIcon />,
    onClose: () => {
      setIsSpeedDialOpen(false);
      setSideBarVisible(true);
    },
    onOpen: () => {
      setIsSpeedDialOpen(true);
      setSideBarVisible(false);
    },
    open: isSpeedDialOpen,
  };

  const shouldDefaultFormBeOpen = () => {
    return defaultForm || (!isTaskScreenOpen && !selectedTask && sideBarVisible && isRepaired());
  };

  const previousUrl = history.location.pathname.split('/').slice(0, -2);

  const backLink = previousUrl.reduce(
    (previousValue, currentValue) => previousValue + '/' + currentValue
  );

  return (
    <Container>
      <Box position="absolute" zIndex={1000}>
        <GoBackHeader title={`${product.product_type} - ${product.barcode} `} backLink={backLink} />
      </Box>
      <Box
        pt={7}
        pb={2}
        overflow="auto"
        width="100%"
        height="100%"
        opacity={isSpeedDialOpen ? 0.2 : 1}
      >
        {!isInWorkshop() && (
          <SimpleBlockContent>
            <Text color="red" variant="extraSmall" textAlign="center">
              Cette machine n&apos;est pas située en atelier, la modification du cycle de réparation
              est désactivée
            </Text>
          </SimpleBlockContent>
        )}

        {product?.id && (
          <RepairCycleHistory
            productHistoryData={productHistoryData}
            irisSections={irisSections}
            irisRepairs={irisRepairs}
          />
        )}
        <ProductInfoBlock product={product} />
      </Box>

      {isTaskScreenOpen && selectedTask && (
        <RepairCycleTaskScreen
          open={isTaskScreenOpen}
          selectedTask={selectedTask}
          handleClose={handleCloseTaskScreen}
          product={product}
          irisConditions={irisConditions}
          irisDefects={irisDefects}
          irisRepairs={irisRepairs}
          irisSections={irisSections}
          irisSymptoms={irisSymptoms}
        />
      )}
      {shouldDefaultFormBeOpen() && <TypeformDefaultSideTab barcode={product.barcode} />}
      {isInWorkshop() && (
        <StyledSpeedDial {...speedDialProps}>
          {actions
            .filter((action) => action.isDisplayed())
            .map((action) => (
              <SpeedDialAction key={action.tooltipTitle} {...action} />
            ))}
        </StyledSpeedDial>
      )}
    </Container>
  );
};

export default WorkshopRepairCycleDashboard;
