import { createStyles } from '@material-ui/core/styles';

const RepairReportTemplateStyle = createStyles({
  notPaidBackground: {
    backgroundColor: '#ffffff',
  },
  notPaidContainer: {
    border: '1px solid #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 8,
    margin: '0px 24px',
    display: 'grid',
    textAlign: 'center',
  },
  notPaidContent: {
    margin: '64px 36px 58px',
  },
  addNewMediaButton: {
    display: 'flex',
    textDecoration: 'underline',
    textTransform: 'none',
    color: '#8795B3',
    border: 'solid 1 #fff',
  },
  addNewMediaButtonContainer: {
    display: 'grid',
  },
  preview: {
    margin: 16,
  },
});

export default RepairReportTemplateStyle;
