import React from 'react';

import { AlignItemsProps, MarginProps } from 'styled-system';

import Text from 'components/atoms/Text';
import { TextVariants } from 'components/atoms/Text/Text';

type IconTextProps = {
  text: string;
  icon: React.ReactNode;
  color?: string;
  variant?: keyof typeof TextVariants;
  onClick?: () => void;
} & MarginProps &
  AlignItemsProps;

const IconText: React.FunctionComponent<IconTextProps> = ({
  text,
  icon,
  onClick,
  color = 'dark',
  variant = 'small',
  alignItems = 'center',
  ...rest
}: IconTextProps) => {
  return (
    <Text
      variant={variant}
      display="flex"
      alignItems={alignItems}
      color={color}
      {...rest}
      onClick={onClick}
    >
      {icon}
      {text}
    </Text>
  );
};

export default IconText;
