import React from 'react';

import BuildIcon from '@material-ui/icons/Build';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Text from 'components/atoms/Text';

type RemainingVisitCount = {
  visitCount: number;
};
const TourStatusReport: React.FunctionComponent<RemainingVisitCount> = ({
  visitCount,
}: RemainingVisitCount) => {
  const renderStatusReport = () =>
    visitCount > 0 ? (
      <Text variant="small" display="flex" alignItems="center">
        <BuildIcon fontSize="small" style={{ marginRight: 16, color: '#8795B3' }} />
        {`${visitCount} ${visitCount > 1 ? 'interventions restantes' : 'intervention restante'}`}
      </Text>
    ) : (
      <>
        <Text variant="bodyBold" mb={2} display="flex">
          <CheckCircleIcon style={{ margin: 'auto', color: '#005646', fontSize: '42px' }} />
        </Text>
        <Text variant="small" mb={1} textAlign="center">
          Tous vos comptes-rendus sont envoyés
        </Text>
      </>
    );

  return (
    <>
      <Text variant="bodyBold" mb={1}>
        Tournée en cours
      </Text>
      {renderStatusReport()}
    </>
  );
};

export default TourStatusReport;
