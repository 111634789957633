import React, { useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as yup from 'yup';

import {
  IRREPARABLE,
  OPERATIONAL,
  OVERALL_CONDITION,
  SECTION_PRESENCE,
  VALO,
} from 'pages/RepairCycleTasks/RepairCycleUtils';
import { postCheckLists, postRepairReport } from 'pages/RepairCycleTasks/api';

import { TypeformQualityPictureWidget } from 'components/TypeformComponents';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

import { Product } from 'types/products';

import RepairOutcomeStep from '../RepairOutcomeStep/RepairOutcomeStep';
import RepairProductChecklist from '../RepairProductChecklist/RepairProductChecklist';

const QUALITY_PICTURES = 'quality_pictures';
const REPAIR_OUTCOME = 'repair_outcome';

type RepairReportFormProps = {
  product: Product;
  handleClose: () => void;
  initialValues: RepairReportFormValue;
};

type FormattedChecklistElement = {
  id: number;
  text: string;
  is_checked: boolean;
};

type RepairReportFormValue = {
  repair_outcome: string | null;
  section_presence: FormattedChecklistElement[];
  overall_condition: FormattedChecklistElement[];
};

const steps = [REPAIR_OUTCOME, QUALITY_PICTURES, SECTION_PRESENCE, OVERALL_CONDITION];
type DataType = {
  section_presence: FormattedChecklistElement[];
  overall_condition: FormattedChecklistElement[];
};
function _renderStepContent(
  step: number,
  formProps: FormikProps<RepairReportFormValue>,
  _handleSubmit: (values: RepairReportFormValue) => void,
  product: Product,
  pictureProofStepActivated: boolean
) {
  // checking if the current step has data to display
  // if not, going straight to the next step
  const actualStep = steps[step];
  (Object.keys(formProps.values) as (keyof DataType)[]).forEach((key) => {
    if (
      key === actualStep &&
      formProps.values[actualStep] &&
      formProps.values[actualStep]?.length < 1
    ) {
      _handleSubmit(formProps.values);
    }
  });

  switch (step) {
    case 0:
      return <RepairOutcomeStep props={formProps} />;

    case 1:
      if (!pictureProofStepActivated) {
        return _handleSubmit(formProps.values);
      }
      return (
        <TypeformQualityPictureWidget
          onClose={() => {
            _handleSubmit(formProps.values);
          }}
          barcode={product.barcode}
        />
      );

    case 2:
      return (
        <RepairProductChecklist
          values={formProps.values}
          checklistType={SECTION_PRESENCE}
          title={'Présence des éléments'}
          subtitle={'Je vérifie que les éléments suivant sont présents'}
        />
      );
    case 3:
      return (
        <RepairProductChecklist
          values={formProps.values}
          checklistType={OVERALL_CONDITION}
          title={'État général'}
          subtitle={'Je vérifie encore une fois'}
        />
      );
    default:
      return <div>Not Found</div>;
  }
}

const RepairReportForm: React.FunctionComponent<RepairReportFormProps> = ({
  product,
  handleClose,
  initialValues,
}: RepairReportFormProps) => {
  const { pictureProof } = useFlags();

  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const validationSchema = yup.object().shape({
    repair_outcome: yup.mixed().required(),
  });

  async function _submitForm(values: RepairReportFormValue) {
    const outcome =
      values.repair_outcome && [VALO, IRREPARABLE].includes(values.repair_outcome)
        ? IRREPARABLE
        : OPERATIONAL;

    if (values.repair_outcome === OPERATIONAL) {
      if (values?.section_presence && values?.section_presence.length > 0) {
        await postCheckLists(product.id, SECTION_PRESENCE, values.section_presence);
      }
      if (values?.overall_condition && values?.overall_condition.length > 0) {
        await postCheckLists(product.id, OVERALL_CONDITION, values.overall_condition);
      }
    }

    if (
      ![...(values?.overall_condition || []), ...(values?.section_presence || [])].some(
        (checks) => !checks.is_checked
      ) ||
      values.repair_outcome !== OPERATIONAL
    ) {
      await postRepairReport(product.id, outcome);
    }
    handleClose();
  }

  function _handleSubmit(values: RepairReportFormValue) {
    if (isLastStep || values.repair_outcome === IRREPARABLE) {
      _submitForm(values);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const isQualityPicturesStep = () => {
    return steps[activeStep] === QUALITY_PICTURES;
  };

  return (
    <>
      <HeaderWithArrow
        title="Fin de réparation"
        action={activeStep !== 0 ? _handleBack : handleClose}
      />
      <Box overflow="auto" mb={isQualityPicturesStep() ? 0 : 64}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={_handleSubmit}
          enableReinitialize
        >
          {({ ...props }: FormikProps<RepairReportFormValue>) => {
            return (
              <Form>
                {_renderStepContent(activeStep, props, _handleSubmit, product, pictureProof)}
                {!isQualityPicturesStep() && (
                  <BottomButton disabled={!props.isValid}>Confirmer</BottomButton>
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default RepairReportForm;
