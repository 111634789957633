import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducer';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, dataProvider, history, authProvider) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */

  const composeEnhancers =
    // process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  // applying saga middleware
  const store = createStore(createReducer(history), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run; // used to load dynamic saga https://github.com/redux-saga/redux-saga/releases/tag/v0.7.0
  store.asyncReducers = {}; // async reducers registry
  store.asyncSagas = {}; // async sagas registry

  sagaMiddleware.run(rootSaga, dataProvider, authProvider);
  return store;
}
