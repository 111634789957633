import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import dayjs from 'dayjs';

import { LabelIncentiveSystemField } from 'pages/IncentiveSystem/Components/LabelIncentiveSystemField';

import ClaimButton from './ClaimButton';
import ClaimForm from './ClaimForm';
import ClaimNotificationError from './ClaimNotificationError';

const useStyles = makeStyles({
  header: {
    borderRadius: 0,
    paddingLeft: '20px',
  },
  headWrapper: {
    backgroundColor: '#fff',
    '& header': {
      position: 'unset',
    },
  },
  elementHeader: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
  },
  back: {
    color: '#2B3B5C',
  },
  paper: {
    height: '-webkit-fill-available',
    width: '100%',
    left: '0',
    bottom: '0',
    overflow: 'auto',
    background: '#F1F0F5',
    '&:focus': {
      outline: 'none',
    },
  },
  button: {
    width: '100%',
    height: 52,
    padding: 24,
    borderRadius: 10,
    border: '1px solid #FFFFFF',
    margin: '20px 0',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    '& svg': {
      color: '#F7B349',
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  linkHistory: {
    textDecoration: 'none',
  },
  wrapper: {
    margin: '32px 20px 0',
  },
  container: {
    marginTop: 24,
    borderRadius: 10,
    boxShadow: 'none',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  titleItem: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    color: '#2B3B5C',
  },
  item: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '135%',
    marginTop: 8,
    color: '#2B3B5C',
    '& span': {
      fontWeight: 'normal',
    },
  },
  historyText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '135%',
    color: '#2B3B5C',
  },
  divider: {
    borderTop: '1px solid #DBDFE8',
    margin: '20px 0px',
  },
  classButton: {
    background: '#F85B5B',
    color: '#FFFFFF',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#F85B5B',
      },
    },
  },
  classContainerButton: {
    position: 'absolute',
  },
});

type IncentiveData = {
  customer_file_id: string;
  lastname: string;
  date_visit_beginning: string;
  date_visit_end: string;
  manager: string;
  label: string;
};

type ClaimComponentProps = {
  incentiveData: IncentiveData;
  handleClose: () => void;
  setIsSubmit: () => void;
  isScreenUpdateIncentive: boolean;
  openModalClaim: boolean;
};

const ClaimComponent: React.FunctionComponent<ClaimComponentProps> = ({
  incentiveData,
  handleClose,
  setIsSubmit,
  openModalClaim,
  isScreenUpdateIncentive,
}: ClaimComponentProps) => {
  const classes = useStyles();
  const [openModalClaimForm, setOpenModalClaimForm] = useState(false);
  const [status, setStatus] = useState('');
  const handleOpenClaimForm = () => {
    if (incentiveData.manager) {
      setOpenModalClaimForm(true);
    } else {
      setStatus('error');
    }
  };

  const handleCloseClaimForm = () => {
    setOpenModalClaimForm(false);
  };

  const renderDateAndHourVisit = (beginning: string, end: string) => {
    const dateVisit = dayjs(beginning).locale('fr').format('D/MM/YY');
    const hourVisitBeginning = dayjs(beginning).locale('fr').format('HH');
    const hourVisitEnd = dayjs(end).locale('fr').format('HH');
    return `${dateVisit} - ${hourVisitBeginning}h à ${hourVisitEnd}h`;
  };

  const body = (
    <div className={classes.paper}>
      <div className={classes.headWrapper}>
        <AppBar className={classes.header}>
          <div className={classes.elementHeader}>
            <IconButton edge="start" onClick={handleClose} className={classes.back}>
              <ArrowBackIcon />
            </IconButton>
            <span>{incentiveData.lastname}</span>
          </div>
        </AppBar>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <span className={classes.titleItem}>Date du passage</span>
          <span className={classes.item}>
            {renderDateAndHourVisit(
              incentiveData.date_visit_beginning,
              incentiveData.date_visit_end
            )}
          </span>
        </div>
        <hr className={classes.divider} />

        <div className={classes.container}>
          <span className={classes.titleItem}>Labélisation</span>
          <span className={classes.item}>{LabelIncentiveSystemField(incentiveData.label)}</span>
        </div>

        <div>
          <a
            href={`/customer-file/${incentiveData.customer_file_id}/history`}
            className={classes.linkHistory}
            rel="noopener noreferrer"
          >
            <IconButton type="button" className={classes.button}>
              <Typography className={classes.historyText}>Voir l’historique</Typography>
              <ArrowForwardIcon />
            </IconButton>
          </a>
        </div>
      </div>
      {isScreenUpdateIncentive && (
        <ClaimButton
          handleSubmit={handleOpenClaimForm}
          handleOpenClaimForm={handleOpenClaimForm}
          buttonLabel="FAIRE UNE RECLAMATION"
          classButton={classes.classButton}
          classContainerButton={classes.classContainerButton}
        />
      )}
      {status ? <ClaimNotificationError status={status} setStatus={setStatus} /> : null}
    </div>
  );
  return (
    <div>
      <Modal open={openModalClaim} onClose={handleClose}>
        {body}
      </Modal>
      <ClaimForm
        openModalClaimForm={openModalClaimForm}
        handleCloseClaimForm={handleCloseClaimForm}
        handleCloseClaimModal={handleClose}
        incentiveData={incentiveData}
        setIsSubmit={setIsSubmit}
      />
    </div>
  );
};

export default ClaimComponent;
