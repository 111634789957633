import React from 'react';

import CarouselLib from 'react-material-ui-carousel';

import ContentContainer from 'components/templates/ContentContainer';

type CarouselProps = {
  children: React.ReactNode;
};

const Carousel: React.FunctionComponent<CarouselProps> = ({ children }) => {
  return (
    <ContentContainer overflow="scroll" minHeight="200px">
      <CarouselLib
        indicatorIconButtonProps={{
          style: {
            padding: '1px',
            color: '#DBDFE8',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: 'orange',
          },
        }}
        indicatorContainerProps={{
          style: {
            marginTop: '16px',
          },
        }}
        autoPlay={false}
        animation={'slide'}
        indicators={true}
        timeout={200}
        navButtonsAlwaysInvisible={true}
        swipe={true}
        reverseEdgeAnimationDirection={true}
      >
        {children}
      </CarouselLib>
    </ContentContainer>
  );
};

export default Carousel;
