// React imports
import React, { useState } from 'react';

// Icons
import { Button, Paper, Typography } from '@material-ui/core';
// Styles imports
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FilterIcon from '@material-ui/icons/Filter';

import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';

// Component imports
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
import RepairReportNameplateStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportNameplateStyle';

import { compressImage } from 'utils/images';

const useStyles = makeStyles(RepairReportNameplateStyle);
const StyledButton = withStyles({
  root: {
    backgroundColor: '#FCD660',
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: '#FCD660',
      },
    },
  },
})(Button);

export default function RepairReportNameplate({ validateFunction, formProductIdReference }) {
  const classes = useStyles();
  const [isPictureChosen, setIsPictureChosen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const {
    input: { onChange: onNameplatePictureAddition },
  } = useField(`${formProductIdReference}.nameplate_appliance`);
  const productReference = formProductIdReference && formProductIdReference.split('_');
  let productFileNameIdentifier = productReference && productReference[1];
  if (productReference && productReference[1] === 'new') {
    productFileNameIdentifier = productReference[1] + productReference[2];
  }

  const onDrop = (uploadedPicture) => {
    const newMedia = [];
    uploadedPicture &&
      uploadedPicture.map((image) =>
        newMedia.push({
          tag: 'nameplate',
          file: image,
        })
      );
    setPictures([...pictures, ...newMedia]);
    setIsPictureChosen(true);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const continueSteps = () => {
    pictures.forEach((image, index) =>
      compressImage(
        image.file,
        'plaque_signaletique_' + productFileNameIdentifier + '_' + index + '.png'
      )
        .then((compressesImage) => {
          image.file = compressesImage;
        })
        .catch((error) => {
          console.error(error.message);
        })
    );
    onNameplatePictureAddition(pictures);
    validateFunction();
  };

  const resetStep = () => {
    setPictures([]);
    setIsPictureChosen(false);
  };

  return (
    <div>
      {isPictureChosen ? (
        <div className={classes.nameplateBackground}>
          <div className={classes.nameplateContainerCamera}>
            {pictures && pictures.length > 1 ? (
              <div className={classes.pictureCarousel}>
                {pictures &&
                  pictures.map((picture, index) => (
                    <img
                      className={classes.videoContainer}
                      src={URL.createObjectURL(picture.file)}
                      alt="preview"
                      key={index}
                    />
                  ))}
              </div>
            ) : (
              pictures && (
                <img
                  className={classes.videoContainer}
                  src={URL.createObjectURL(pictures[0].file)}
                  alt="preview"
                />
              )
            )}
            <Button id="re-add-media" className={classes.retakePictureButton} onClick={resetStep}>
              <p className={classes.secondaryButtonContent}> Recommencer </p>
            </Button>

            <div>
              <CheckCircleIcon className={classes.checkedIcon} />
              <span className={classes.infoPicture}>
                {`${pictures && pictures.length} `}
                {pictures && pictures.length > 1 ? 'photos prises' : 'photo prise'}
              </span>
            </div>
          </div>
          <div className={classes.addNewMediaButtonContainer}>
            <Button id="add-new-media" className={classes.addNewMediaButtonNameplate}>
              <FilterIcon />
              <div className={classes.contentMoreMedias} {...getRootProps()}>
                <input {...getInputProps()} />
                {<p>Ajouter des photos</p>}
              </div>
            </Button>
            <RepairReportValidateButton
              buttonLabel={`continuer`}
              validateFunction={() => continueSteps()}
            />
          </div>
        </div>
      ) : (
        <div>
          <Typography className={classes.stepQuestion} variant="h6" component="div">
            Identification
          </Typography>
          <Paper className={classes.nameplateContainer}>
            <div className={classes.nameplateContent}>
              <CameraAltRoundedIcon className={classes.cameraIcon} />
              <Typography variant="h6" gutterBottom>
                Ajoutez une photo de la plaque signalétique de l’appareil.
              </Typography>
              <Typography variant="caption" gutterBottom className={classes.infoForPicture}>
                Prenez l’ensemble de l’étiquette, veillez à la netteté.
              </Typography>
            </div>
            <StyledButton className={classes.validateButton} {...getRootProps()}>
              <input {...getInputProps()} data-testid="nameplate-input" />
              Ajouter une photo
            </StyledButton>
          </Paper>
        </div>
      )}
    </div>
  );
}
