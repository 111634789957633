import React from 'react';

import { MarginProps } from 'styled-system';

import ProductDetailField from 'pages/Store/components/ProductDetailField';

import Box from 'components/atoms/Box';

import { Location } from 'types/location';
import { ShippingDelivery, ShippingDeliveryItem } from 'types/shipping-deliveries';

import { useProduct } from 'utils/api/api';

import { SHIPPING } from '../utils';
import ProductPreparationChecklist from './ProductPreparationChecks/ProductPreparationChecks';

type ShippingPreparationProps = {
  isChecklistOpen: boolean;
  setIsChecklistOpen: (bool: boolean) => void;
  setSelectedProductId: (id: number | null) => void;
  selectedProductId: number | null;
  productId: number;
  locations: Location[];
  delivery: ShippingDelivery;
  deliveryItem: ShippingDeliveryItem;
} & MarginProps;

const ShippingPreparation: React.FunctionComponent<ShippingPreparationProps> = ({
  isChecklistOpen,
  setIsChecklistOpen,
  setSelectedProductId,
  selectedProductId,
  productId,
  locations,
  delivery,
  deliveryItem,
}: ShippingPreparationProps) => {
  const { product } = useProduct(productId);
  const location = locations?.find((location: Location) => location.id === product?.location);
  return (
    <Box mt={2}>
      {product && location?.usage === SHIPPING && (
        <ProductDetailField
          product={product}
          removeable={false}
          onclick={() => {
            setIsChecklistOpen(true);
            setSelectedProductId(productId);
          }}
          location={location}
          associatedDelivery={delivery}
          checked={deliveryItem.is_checked !== null && !!deliveryItem.is_checked}
          errored={deliveryItem.is_checked !== null && !deliveryItem.is_checked}
        />
      )}

      {productId === selectedProductId && isChecklistOpen && (
        <ProductPreparationChecklist
          isOpen={isChecklistOpen}
          handleClose={() => {
            setIsChecklistOpen(false);
            setSelectedProductId(null);
          }}
          product={product}
          deliveryItem={deliveryItem}
        />
      )}
    </Box>
  );
};
export default ShippingPreparation;
