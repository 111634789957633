import dataProvider from 'dataProvider';

import { CustomerFileHistory } from 'types/customer-file-history';

export const getCustomerFileHistory = async (
  customerFileId: string
): Promise<CustomerFileHistory[]> => {
  const { data } = await dataProvider.getOne('customer-file-history', {
    id: customerFileId,
    include: ['visit_payment'],
  });
  return data;
};
