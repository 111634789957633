import React, { useState } from 'react';

import { useQuery } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';

import dataProvider from 'dataProvider';
import { useModals } from 'modalsContext';
import styled from 'styled-components';

import StoreStyles from 'pages/Store/StoreStyles';

import CreateNewSku from 'components/CreateNewSku/CreateNewSku';
import OpenScanButton from 'components/OpenScanButton';
import ReceptionConfirmation from 'components/ReceptionConfirmation';
import SkuListSelectionPortal from 'components/SkuListSelectionPortal';
import SkuLocationSelectionPortal from 'components/SkuLocationSelectionPortal';
import SparePartSelectionPortal from 'components/SparePartSelectionPortal';

import { SkuLocation } from 'types/sku-location';
import { SkuModel } from 'types/sku-model';
import { SparePart } from 'types/spare-part';
import { Supplier } from 'types/supplier';

import { generateQrCode } from 'utils/QrCodeUtils';
import { isItemInList } from 'utils/list';

import PrintContent from './PrintContent';

type OptionType = { label: string; value: string };
type OptionsType = Array<OptionType>;

const styleList = makeStyles(StoreStyles);

const StyledCachedIcon = styled(CachedIcon)((props) => ({
  color: props.theme.colors.grey900,
}));

export const StoreReception: React.FunctionComponent = () => {
  const classes = styleList();
  const { showModal, closeModal } = useModals();

  // INITIALIZE PORTALS TO CLOSED STATE
  const [isSkuLocationSelectionOpen, setIsSkuLocationSelectionOpen] = useState<boolean>(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState<boolean>(false);
  const [isCreateSkuModalOpen, setIsCreateSkuModalOpen] = useState<boolean>(false);
  const [isListSelectionOpen, setIsListSelectionOpen] = useState<boolean>(false);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // INITIALIZE LAST SELECTED SKU DETAILS
  const [skuReference, setSkuReference] = useState<string>();
  const [designation, setDesignation] = useState<string>();
  const [sku, setSku] = useState<SparePart>();
  const [qrCode, setQrCode] = React.useState<string>();

  // INITIALIZE LIST OF SELECTED SKUS
  const [selectedSkus, setSelectedSkus] = useState<SparePart[]>([] as SparePart[]);

  // STORE USER CREATION CHOICES
  const [identificationChoice, setIdentificationChoice] = React.useState<string>('manual');
  const [supplier, setSupplier] = React.useState<Supplier>();
  const [skuType, setSkuType] = React.useState<string>('new');

  const [referenceList, setReferenceList] = React.useState<OptionsType>([]);
  const [receptionConfirmationOpen, setReceptionConfirmationOpen] = useState<boolean>(false);

  useQuery(
    {
      type: 'getList',
      resource: 'sku',
      payload: {
        sort: { field: 'id', order: 'DESC' },
        filter: { status: ['ordered', 'ordered_rotating_stock'] },
      },
    },
    {
      onSuccess: ({ data }) => {
        setReferenceList(
          data.map((x: SparePart) => ({
            label: x.sku_model?.supplier_reference,
            value: x.sku_model?.supplier_reference,
          }))
        );
      },
    }
  );

  const handleCloseResumeModal = () => {
    setIsCreateSkuModalOpen(true);
    setIsResumeModalOpen(false);
  };

  /**
   * sets states to format QR code top print and diaply printing page
   * @param sku last selected physical spare part
   */
  const handleOpenResumeModal = (sku: SparePart) => {
    setSkuReference(sku.sku_model.sku_reference);
    setDesignation(sku.sku_model.designation);
    setSku(sku);
    updateQrCode(sku.sku_model.sku_reference, sku.id);
    if (skuType === 'new') {
      setReceptionConfirmationOpen(true);
    } else {
      setIsResumeModalOpen(true);
    }
  };

  const addSkuInListFromScanner = (sku: SparePart) => {
    handleNewSkuSelected(sku);
    setIsScannerOpen(false);
    setIsListSelectionOpen(true);
  };

  /**
   * format QR code from model and unique id from database
   * @param ref reference of the skuModel associated to the sku
   * @param id  id of the selected physical spare part
   */
  const updateQrCode = (ref: string, id: number) => {
    generateQrCode(ref + '/' + id, setQrCode);
  };

  const handleOpenSkuSelection = () => {
    setIsListSelectionOpen(false);
    if (identificationChoice === 'manual') {
      setIsCreateSkuModalOpen(true);
    } else {
      setIsScannerOpen(true);
    }
  };

  const handleOpenLocationPortal = () => {
    setIsListSelectionOpen(false);
    setIsSkuLocationSelectionOpen(true);
  };

  const handleCloseLocationPortal = () => {
    setIsSkuLocationSelectionOpen(false);
    if (selectedSkus.length > 0) {
      setIsListSelectionOpen(true);
    }
  };

  const handleCloseCreateSkuModal = () => {
    setIsCreateSkuModalOpen(false);
    if (selectedSkus.length > 0) {
      setIsListSelectionOpen(true);
    }
  };

  const handleNewSkuSelected = (sku: SparePart) => {
    if (!isItemInList(sku, selectedSkus)) {
      setSelectedSkus([...selectedSkus, sku]);
    }
  };

  const goToListSelection = () => {
    sku && handleNewSkuSelected(sku);
    setIsResumeModalOpen(false);
    setIsListSelectionOpen(true);
  };

  const handleCloseScanner = () => {
    setIsScannerOpen(false);
  };

  const handleOpenSkuScanner = () => {
    setIdentificationChoice('scan');
    setIsScannerOpen(true);
  };

  const goBackToSkuCreation = () => {
    setIdentificationChoice('manual');
    setIsScannerOpen(false);
    setIsCreateSkuModalOpen(true);
  };

  /**
   * Create a physical spare part from a given model
   * @param model selected or created skuModel
   */
  const handleCreateSku = (model: SkuModel) => {
    dataProvider
      .create('sku', {
        data: {
          sku_model: model.id,
        },
      })
      .then(({ data }) => {
        handleOpenResumeModal(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  /**
   * Selects a physical spare part from a list to ensure that the
   * spare part has not been already selected
   * @param skuList list of physical spare parts
   */
  const getSkuFromOrderedList = (skuList: SparePart[]) => {
    for (let i = 0; i < skuList.length; i++) {
      if (!isItemInList(skuList[i], selectedSkus)) {
        return skuList[i];
      }
    }
    return null;
  };

  /** if a physical spare part exists with the ordered status get it
   * else call handleCreateSku to create one */
  const getOrCreateOrderedPart = (model: SkuModel) => {
    dataProvider
      .getList('sku', {
        sort: { field: 'id', order: 'DESC' },
        filter: { sku_model_id: model.id, status: 'ordered' },
      })
      .then(({ data }) => {
        if (data.length > 0 && getSkuFromOrderedList(data)) {
          const sku = getSkuFromOrderedList(data);
          handleOpenResumeModal(sku as SparePart);
        } else {
          handleCreateSku(model);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  /**
   * update a physical spare part location based on id state
   */
  const handleUpdateSparePartLocation = (skuLocation: SkuLocation) => {
    setIsSaving(true);
    dataProvider
      .create('sku-receive', {
        data: {
          skus: selectedSkus.map((x) => x.id),
          location: skuLocation.id,
        },
      })
      .then((response) => {
        if (response.data) {
          setSelectedSkus([]);
          handleCloseLocationPortal();
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setIsSaving(false));
  };

  const toggleReturnButtonModal = () => {
    showModal({
      modalToShow: 'CONFIRM_MODAL',
      modalProps: {
        title: 'Quitter la réception ?',
        content: 'les pièces sélectionnées seront perdues',
        onCancel: closeModal,
        onConfirm: () => {
          closeModal();
          setSelectedSkus([]);
          setIsListSelectionOpen(false);
        },
      },
    });
  };

  return (
    <div className={classes.StoreReceptionContainer}>
      <div className={classes.StoreReceptionScrollableView}>
        <OpenScanButton text={'Identifier une pièce'} handleOpen={handleOpenSkuSelection} />
        {isSkuLocationSelectionOpen && (
          <SkuLocationSelectionPortal
            isOpen={isSkuLocationSelectionOpen}
            handleClosePortal={handleCloseLocationPortal}
            handleOpenPortal={handleOpenLocationPortal}
            title={'Sélectionner un emplacement'}
            onConfirm={(location: SkuLocation) => handleUpdateSparePartLocation(location)}
          />
        )}
        {isCreateSkuModalOpen && (
          <CreateNewSku
            initialSupplier={supplier}
            handleChangeSupplier={(supplier: Supplier) => setSupplier(supplier)}
            open={isCreateSkuModalOpen}
            onClose={handleCloseCreateSkuModal}
            handleCreateSku={getOrCreateOrderedPart}
            filterText=""
            handleOpenSkuScanner={handleOpenSkuScanner}
            referenceList={referenceList}
            initialSkuType={skuType}
            handleSelectSecondHand={() => setSkuType('second_hand')}
          />
        )}
        {sku && receptionConfirmationOpen && (
          <ReceptionConfirmation
            sku={sku}
            setSku={setSku}
            handleClose={() => setReceptionConfirmationOpen(false)}
            onConfirm={() => {
              setIsResumeModalOpen(true);
              setReceptionConfirmationOpen(false);
            }}
            receptionConfirmationOpen={receptionConfirmationOpen}
            locationToDisplay="turning"
          />
        )}
        <PrintContent
          open={isResumeModalOpen}
          onClose={handleCloseResumeModal}
          firstText={skuReference}
          secondText={designation}
          codeSubtitle={skuReference + '/' + sku?.id}
          qrCode={qrCode}
          handleNextStep={goToListSelection}
          headingText={sku?.is_second_hand ? 'OCCASION' : undefined}
          LeftIcon={sku?.is_second_hand ? StyledCachedIcon : undefined}
          RightIcon={sku?.is_second_hand ? StyledCachedIcon : undefined}
          barCode={undefined}
        />
        {selectedSkus.length > 0 && (
          <SkuListSelectionPortal
            skuList={selectedSkus as SparePart[]}
            selectedList={selectedSkus as SparePart[]}
            open={isListSelectionOpen}
            onClose={toggleReturnButtonModal}
            isSaving={isSaving}
            title="Réception"
            buttonText="Réception terminé"
            onClickOnButton={handleOpenLocationPortal}
            openSkuSelection={handleOpenSkuSelection}
          />
        )}
        {isScannerOpen && (
          <SparePartSelectionPortal
            onConfirm={(sku: SparePart) => addSkuInListFromScanner(sku)}
            onClose={handleCloseScanner}
            open={isScannerOpen}
            handleClickOnManualSelection={goBackToSkuCreation}
            locationToDisplay="turning"
            sku={sku}
            setSku={setSku}
          />
        )}
      </div>
    </div>
  );
};

export default StoreReception;
