import React from 'react';

import SevenOpteamRepairmanVisitList from './SevenOpteamRepairmanVisitList';

interface RepairmanVisitListProps {
  repairmanId: number;
  selectedDate: string;
}

const RepairmanVisitList: React.FunctionComponent<RepairmanVisitListProps> = ({
  repairmanId,
  selectedDate,
}: RepairmanVisitListProps) => {
  return <SevenOpteamRepairmanVisitList repairmanId={repairmanId} selectedDate={selectedDate} />;
};

export default RepairmanVisitList;
