import React from 'react';

/* eslint-disable react/jsx-key */
import { Route } from 'react-router-dom';

import IncentiveSystem from 'pages/IncentiveSystem/IncentiveSystem';

import RepairmanCalendar from './RepairmanCalendar';
import Team from './Team';

export const teamRoutes = [
  <Route path="/team" component={Team} noLayout exact />,
  <Route path="/team/incentives/:repairmanId" component={IncentiveSystem} noLayout />,
  <Route path="/team/calendar/:repairmanId" component={RepairmanCalendar} noLayout />,
];
