import React, { useEffect, useState } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';

import dataProvider from 'dataProvider';
import dayjs from 'dayjs';
import { useEmployee } from 'employeeContext';
import { Field, FieldProps, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  getProductCategories,
  getProductInstallations,
  getProductTypes,
  upsertProduct,
} from 'pages/ProductReception/utils/api';
import { SOURCING_CODE } from 'pages/ProductReception/utils/helpers';
import SelectProductLocation from 'pages/Store/components/SelectProductLocation';

import IconText from 'components/IconText/IconText';
import AutocompleteInput from 'components/atoms/AutocompleteInput';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import ButtonIcon from 'components/atoms/ButtonIcon/ButtonIcon';
import FloatingText from 'components/atoms/FloatingText/FloatingText';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Select from 'components/molecules/Select';

import { AswoDevice } from 'types/aswo-devices';
import { DeliveryItem } from 'types/delivery-items';
import { Location } from 'types/location';
import { ProductCategory } from 'types/product-categories';
import { ProductInstallation } from 'types/product-installations';
import { ProductType } from 'types/product-types';
import { Product } from 'types/products';

import { useEmployeeAssociatedLocations, useProductBrands } from 'utils/api/api';

type ProductReceptionInfoProps = {
  handleProductReception: (product: Product) => void;
  selectedAswoDevice: AswoDevice | undefined;
  selectedReference: string | null;
  selectedProductBrand: string | null;
  selectedProductType: number | null;
  customSupplier: string | null;
  buyingPrice: number | null;
  applianceNameplate: File[] | null;
  defaultProductId: number | null;
  goBack: () => void;
  deliveryItem: DeliveryItem | null;
  errorMessage: string | null;
};

type OptionType = { label: string; value: string };

const StyledForm = styled(Form)((props) => ({
  background: props.theme.colors.grey200,
  height: '100vh',
  flex: 'auto',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  width: '90%',
  overflow: 'auto',
}));

const StyledButtonIcon = styled(ButtonIcon)({
  position: 'absolute',
  right: '7%',
});

const ProductReceptionInfo: React.FunctionComponent<ProductReceptionInfoProps> = ({
  handleProductReception,
  selectedAswoDevice,
  selectedReference,
  selectedProductType,
  selectedProductBrand,
  customSupplier,
  buyingPrice,
  applianceNameplate,
  defaultProductId,
  goBack,
  deliveryItem,
  errorMessage,
}) => {
  const [productTypeList, setProductTypeList] = useState<ProductType[]>([]);
  const [productCategoryList, setProductCategoryList] = useState<ProductCategory[]>([]);
  const [productInstallationTypeList, setProductInstallationTypeList] = useState<
    ProductInstallation[]
  >([]);
  const [isLocationSelectionOpen, setIsLocationSelectionOpen] = useState<boolean>(false);
  const [productLocation, setProductLocation] = useState<Location | undefined>();
  const [error, setError] = useState<string | null>(errorMessage);
  const { employee } = useEmployee();
  const { locations } = useEmployeeAssociatedLocations(employee.workshop || undefined);

  const { productBrands } = useProductBrands();

  type FormValues = {
    productModel: string | null;
    productType: number | null;
    productCategory: number | null | undefined;
    productBrand: OptionType | null;
    productInstallation: string | number | undefined | null;
  };
  const validationSchema = yup.object().shape({
    productModel: yup.string().required('Modèle requis'),
    productType: yup.number().nullable().required('Type requis'),
    productBrand: yup.mixed().required('Marque requise'),
  });

  const getProductType = () => {
    return (
      selectedAswoDevice?.product_type?.id ||
      productTypeList.find((type) => deliveryItem?.product_type === type.name)?.id ||
      0
    );
  };

  const findIdInList = (typeList: ProductCategory[] | ProductInstallation[], value: string) => {
    return typeList?.find((category) => category.name === value)?.id;
  };
  const getCategory = () => {
    if (productCategoryList) {
      if (deliveryItem?.product?.subcategory1) {
        return findIdInList(productCategoryList, deliveryItem?.product?.subcategory1);
      }
      if (deliveryItem?.product?.subcategory2) {
        return findIdInList(productInstallationTypeList, deliveryItem?.product?.subcategory2);
      }
    }
    return selectedAswoDevice?.product_category?.id || null;
  };
  const getInstallation = () => {
    if (productInstallationTypeList) {
      if (deliveryItem?.product?.subcategory2) {
        return findIdInList(productInstallationTypeList, deliveryItem?.product?.subcategory2);
      }
      if (deliveryItem?.product?.subcategory1) {
        return findIdInList(productCategoryList, deliveryItem?.product?.subcategory1);
      }
    }
    return selectedAswoDevice?.installation_type || '';
  };

  const getBrand = () => {
    const defaultBrand =
      selectedProductBrand ||
      selectedAswoDevice?.brand ||
      deliveryItem?.product?.brand ||
      deliveryItem?.product_brand ||
      undefined;
    return defaultBrand ? { value: defaultBrand, label: defaultBrand } : null;
  };
  const initialValues: FormValues = {
    productModel: selectedAswoDevice?.device_id || selectedReference || '',
    productType: selectedProductType || getProductType(),
    productCategory: getCategory(),
    productInstallation: getInstallation(),
    productBrand: getBrand() || null,
  };

  const formatSelectFieldCompliantList = (
    list: Array<ProductType | ProductCategory | ProductInstallation>
  ) => {
    return list.map(function (row: ProductType | ProductCategory | ProductInstallation) {
      return { value: row.id, name: row.name, id: row.id };
    });
  };
  const fetchProductTypeInfos = async (value: number) => {
    const productCategories = await getProductCategories(value);
    const productInstallations = await getProductInstallations(value);
    const formattedProductCategories = formatSelectFieldCompliantList(productCategories);
    const formattedProductInstallations = formatSelectFieldCompliantList(productInstallations);
    setProductCategoryList(formattedProductCategories);
    setProductInstallationTypeList(formattedProductInstallations);
  };

  const updateProductType = (value: number | null, name: string, form: any) => {
    form.setFieldValue(name, value);
    form.setFieldValue('productCategory', null);
    form.setFieldValue('productInstallation', null);
    if (value) {
      fetchProductTypeInfos(value);
    }
  };

  const getLocationFromId = (id: number | unknown): any => {
    return locations.find((el: any) => el.id === id);
  };
  const handleChangeLocation = (id: number | unknown) => {
    const location = getLocationFromId(id);
    setProductLocation(location);
    setIsLocationSelectionOpen(false);
  };

  const buildProductPayload = (values: FormValues) => {
    const category = productCategoryList.filter(
      (category) => category.id === values.productCategory
    )[0];
    const installation = productInstallationTypeList.filter(
      (installation) => installation.id === values.productInstallation
    )[0];

    const buyingPricePretax = (buyingPrice && Math.round(buyingPrice * 100)) || null;

    // formData request does not allowed null values in body
    if (buyingPricePretax === null) {
      return {
        product_type:
          productTypeList.filter((type) => type.id === values.productType)[0].name || '',
        subcategory1: category ? category.name : '',
        installation_type_id: installation ? installation.id : '',
        model_reference: values.productModel,
        brand: values?.productBrand?.value,
        supplier: customSupplier || '',
        nameplate: applianceNameplate && applianceNameplate[0],
        location: productLocation?.id,
        delivery_date: dayjs().format('YYYY-MM-DD'),
        insert_model: true,
      };
    }
    return {
      product_type: productTypeList.filter((type) => type.id === values.productType)[0].name || '',
      subcategory1: category ? category.name : '',
      installation_type_id: installation ? installation.id : '',
      model_reference: values.productModel,
      brand: values?.productBrand?.value,
      buying_price_pretax: buyingPricePretax,
      supplier: customSupplier || '',
      nameplate: applianceNameplate && applianceNameplate[0],
      location: productLocation?.id,
      delivery_date: dayjs().format('YYYY-MM-DD'),
      insert_model: true,
    };
  };

  const handleDeliveryItemUpdate = async (product: Product) => {
    if (deliveryItem) {
      dataProvider
        .update('delivery-items', {
          id: deliveryItem.id,
          data: { product: product.id },
        })
        .catch((e) => setError(e.message));
    }
  };

  const handleProductCreation = async (values: FormValues, { setSubmitting }: any) => {
    setSubmitting(true);
    setError(null);
    const productPayload = buildProductPayload(values);
    await upsertProduct(defaultProductId, productPayload)
      .then(async (response) => {
        await handleProductReception(response);
        if (deliveryItem && !defaultProductId) {
          await handleDeliveryItemUpdate(response);
        }
      })
      .catch((e) => {
        setError(e.message);
      })

      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    const fetchProductTypes = async () => {
      const productTypes = await getProductTypes();
      const formattedProductTypes = formatSelectFieldCompliantList(productTypes);
      setProductTypeList(formattedProductTypes);
    };
    fetchProductTypes();
    if (selectedAswoDevice?.product_type?.id) {
      fetchProductTypeInfos(selectedAswoDevice.product_type.id);
    }
  }, [selectedAswoDevice]);

  useEffect(() => {
    if (locations) {
      const sourcingLocation = locations.find((el: any) => el.code === SOURCING_CODE) as any;
      setProductLocation(sourcingLocation);
    }
  }, [locations]);

  useEffect(() => {
    if (selectedProductType) {
      fetchProductTypeInfos(selectedProductType);
    }
  }, []);

  useEffect(() => {
    if (deliveryItem?.product_type) {
      const productTypeId = productTypeList.find(
        (type) => deliveryItem?.product_type === type.name
      )?.id;
      if (productTypeId) {
        fetchProductTypeInfos(productTypeId);
      }
    }
  }, [productTypeList]);

  return (
    <div>
      {isLocationSelectionOpen && (
        <SelectProductLocation
          locations={locations as any[] | Location[]}
          open={isLocationSelectionOpen}
          handleClose={() => {
            setIsLocationSelectionOpen(false);
          }}
          handleChangeLocation={handleChangeLocation}
        />
      )}
      <HeaderWithArrow title={selectedReference || 'Identification'} action={goBack} />
      <Formik
        onSubmit={handleProductCreation}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, isSubmitting, isValid }) => (
          <StyledForm>
            <Box flex="0.9">
              <Box mb={2} textAlign="left" width="100%">
                <Field name="productModel">
                  {({ field: { name, onChange, value } }: FieldProps) => {
                    return (
                      <Input
                        width="100%"
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeholder="Modèle"
                        data-testid="product-reference"
                        autoComplete="off"
                      />
                    );
                  }}
                </Field>
                <Text variant="small" color="red">
                  {errors?.productModel}
                </Text>
              </Box>
              <Box textAlign="left" width="100%">
                <Field name="productBrand">
                  {({ field: { name, value }, form }: FieldProps) => {
                    return (
                      <AutocompleteInput
                        name={name}
                        value={value}
                        isClearable
                        isCreatableEnabled={false}
                        options={
                          productBrands
                            ? (productBrands.map((brand) => {
                                return { label: brand.name, value: brand.name };
                              }) as OptionType[])
                            : ([] as OptionType[])
                        }
                        onChange={(value: OptionType) => {
                          form.setFieldValue('productBrand', value);
                        }}
                        placeholder="Marque"
                      />
                    );
                  }}
                </Field>
                <Text variant="small" color="red">
                  {errors?.productBrand}
                </Text>
              </Box>
              <Box textAlign="left" width="100%">
                <Field name="productType">
                  {({ field: { name, value }, form }: FieldProps) => {
                    return (
                      <Select
                        items={productTypeList as any[]}
                        width="100%"
                        innerWidth="90%"
                        maxHeight={280}
                        value={value}
                        name={name}
                        // Necessary because our Select component does not behave like a standard input
                        onChange={(value) => updateProductType(value, name, form)}
                        placeholder="Type"
                      />
                    );
                  }}
                </Field>
                <Text variant="small" color="red">
                  {errors?.productType}
                </Text>
              </Box>
              {productCategoryList.length > 0 && (
                <Box textAlign="left" width="100%">
                  <Field name="productCategory">
                    {({ field: { name, value }, form }: FieldProps) => {
                      return (
                        <Box display="flex">
                          <Select
                            items={productCategoryList as any[]}
                            width="100%"
                            innerWidth="90%"
                            maxHeight={280}
                            value={value}
                            name={name}
                            // Necessary because our Select component does not behave like a standard input
                            onChange={(value) => form.setFieldValue(name, value)}
                            placeholder="Catégorie"
                          />
                          {value && (
                            <StyledButtonIcon
                              icon={ClearIcon}
                              mt={4}
                              handleClick={() => form.setFieldValue(name, null)}
                            />
                          )}
                        </Box>
                      );
                    }}
                  </Field>
                  <Text variant="small" color="red">
                    {errors?.productCategory}
                  </Text>
                </Box>
              )}
              {productInstallationTypeList.length > 0 && (
                <Box mb={2} textAlign="left" width="100%">
                  <Field name="productInstallation">
                    {({ field: { name, value }, form }: FieldProps) => {
                      return (
                        <Box display="flex">
                          <Select
                            items={productInstallationTypeList as any[]}
                            width="100%"
                            innerWidth="90%"
                            maxHeight={280}
                            value={value}
                            name={name}
                            // Necessary because our Select component does not behave like a standard input
                            onChange={(value) => form.setFieldValue(name, value)}
                            placeholder="Installation"
                          />
                          {value && (
                            <StyledButtonIcon
                              icon={ClearIcon}
                              mt={4}
                              handleClick={() => form.setFieldValue(name, null)}
                            />
                          )}
                        </Box>
                      );
                    }}
                  </Field>
                  <Text variant="small" color="red">
                    {errors?.productCategory}
                  </Text>
                </Box>
              )}
              {productLocation && (
                <>
                  <Box mb={1} textAlign="left" width="90%">
                    <Text variant="body">Emplacement machine: {productLocation.name}</Text>
                  </Box>
                  <Box textAlign="left" width="90%">
                    <IconText
                      text="Modifier l'emplacement"
                      icon={<EditIcon />}
                      color="grey700"
                      variant="small"
                      onClick={() => setIsLocationSelectionOpen(true)}
                    />
                  </Box>
                </>
              )}
              {error && (
                <FloatingText
                  width="90%"
                  variant="small"
                  text={`Une erreur est survenue:  ${error}`}
                />
              )}
            </Box>
            <Box>
              {productLocation ? (
                <Button
                  width="100%"
                  data-testid="bottom-button"
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  {isSubmitting
                    ? 'Sauvegarde en cours'
                    : defaultProductId
                    ? 'Modifier le produit'
                    : 'Créer le produit'}
                </Button>
              ) : (
                <Button width="100%" onClick={() => setIsLocationSelectionOpen(true)} type="button">
                  Confirmer
                </Button>
              )}
            </Box>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default ProductReceptionInfo;
