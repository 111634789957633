import React, { useEffect, useState } from 'react';

import dataProvider from 'dataProvider';

import ProductDetailScreen from 'pages/ProductReception/components/ProductDetailScreen';
import ProductSupplierInfo from 'pages/ProductReception/components/ProductSupplierInfo';

import DeviceSelection from 'components/DeviceSelection';
import PortalComponent from 'components/Portal/PortalComponent';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import PageLayout from 'components/templates/PageLayout';

import { AswoDevice } from 'types/aswo-devices';
import { DeliveryItem } from 'types/delivery-items';
import { Product } from 'types/products';

import ProductReceptionInfo from './components/ProductReceptionInfo';
import ProductReceptionNameplate from './components/ProductReceptionNameplate';
import { B2C, isB2C } from './utils/helpers';

type FormValues = {
  nameplate: File[] | null;
  model: string | null;
  productType: number | null;
  productBrand: string | null;
};

type SupplierFormValues = {
  price: number | null;
  supplier: string | null;
};

type ProductReceptionProps = {
  open: boolean;
  handleClose: () => void;
  productSupplier: string | null;
  deliveryItem: DeliveryItem | null;
};
const ProductReception: React.FunctionComponent<ProductReceptionProps> = ({
  open,
  handleClose,
  productSupplier,
  deliveryItem,
}: ProductReceptionProps) => {
  const [isProductDataFilled, setIsProductDataFilled] = useState<boolean>(false);
  const [potentialDevices, setpotentialDevices] = useState<AswoDevice[]>([]);
  const [selectedAswoDevice, setSelectedAswoDevice] = useState<AswoDevice | undefined>();
  const [selectedReference, setSelectedReference] = useState<string | null>(
    deliveryItem?.product?.model_reference || null
  );
  const [selectedProductBrand, setSelectedProductBrand] = useState<string | null>(
    deliveryItem?.product?.brand || null
  );
  const [selectedProductType, setSelectedProductType] = useState<number | null>(null);
  const [productId, setProductId] = useState<number | null>(null);
  const [customSupplier, setCustomSupplier] = useState<string | null>(null);
  const [isSupplierFilled, setIsSupplierFilled] = useState<boolean>();
  const [buyingPrice, setBuyingPrice] = useState<number | null>(null);
  const [applianceNameplate, setApplianceNameplate] = useState<File[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProductionReceptionPending, setisProductionReceptionPending] = useState<boolean>(false);
  const [deviceNotFound, setDeviceNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (productSupplier) {
      setCustomSupplier(productSupplier);
      setIsSupplierFilled(isB2C(productSupplier));
    }

    const associatedProduct = deliveryItem?.product;
    if (associatedProduct) {
      if (associatedProduct.workshop_file && associatedProduct.barcode) {
        setIsProductDataFilled(true);
      } else if (associatedProduct.customer_file && !associatedProduct.workshop_file) {
        setCustomSupplier(B2C);
      }
      setProductId(associatedProduct.id);
    }
  }, [productSupplier, deliveryItem?.product]);

  const resetIdentificationValues = () => {
    setIsProductDataFilled(false);
    setApplianceNameplate(null);
    setSelectedAswoDevice(undefined);
    setpotentialDevices([]);
    setIsSupplierFilled(false);
    setBuyingPrice(null);
    setCustomSupplier(null);
    setDeviceNotFound(false);
    setBuyingPrice(null);
  };
  const resetInitialState = () => {
    setProductId(null);
    resetIdentificationValues();
  };

  const handleClosePortal = () => {
    resetInitialState();
    handleClose();
  };

  const handleGetAswoMachines = (values: FormValues) => {
    setSelectedReference(values?.model);
    setSelectedProductType(values?.productType);
    setSelectedProductBrand(values?.productBrand);
    setApplianceNameplate(values?.nameplate);

    // temporarly deactivating the devicesearch api call 'till the requests number problem is resolved
    // dataProvider
    //   .getList('aswo-devices', {
    //     filter: { search: values.model },
    //   })
    //   .then(({ data }) => {
    //     if (data?.length > 0) {
    //       setpotentialDevices(data);
    //     }
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   })
    //   .finally(() => {
    //     setSelectedReference(values?.model);
    //     setApplianceNameplate(values?.nameplate);
    //   });
  };

  const handleValidateAswoDeviceSelection = (aswoDeviceId: string | null) => {
    if (aswoDeviceId) {
      const aswoDevice = potentialDevices.find((device) => device.aswo_device_id === aswoDeviceId);
      setSelectedAswoDevice(aswoDevice);
    } else {
      setDeviceNotFound(true);
    }
  };

  const handleValidateProductSupplier = (values: SupplierFormValues) => {
    setCustomSupplier(values.supplier);
    setBuyingPrice(values.price);
    setIsSupplierFilled(true);
  };

  const handleRedoIdentification = () => {
    setErrorMessage(null);
    resetIdentificationValues();
  };

  const handleProductReception = async (product: Product) => {
    setErrorMessage(null);
    setisProductionReceptionPending(true);
    await dataProvider
      .create(`murfy-erp/products/${product.id}/receipt`, {
        data: { location: product.location },
      })
      .then(() => {
        setisProductionReceptionPending(false);
        setIsProductDataFilled(true);
        setProductId(product.id);
      })
      .catch((e) => {
        setErrorMessage(e);
      });
  };

  const goBackToIdentificationScreen = () => {
    if (potentialDevices.length > 0) {
      setSelectedAswoDevice(undefined);
      setDeviceNotFound(false);
    } else {
      setSelectedAswoDevice(undefined);
      setApplianceNameplate(null);
    }
  };
  return (
    <PortalComponent open={open} onClose={handleClosePortal}>
      {!isProductDataFilled && (
        <>
          {!applianceNameplate ? (
            <>
              {isSupplierFilled || isB2C(productSupplier) ? (
                <ProductReceptionNameplate
                  selectedReference={
                    selectedReference || deliveryItem?.product?.model_reference || null
                  }
                  handleSubmit={handleGetAswoMachines}
                  goBack={() =>
                    isSupplierFilled ? setIsSupplierFilled(false) : handleClosePortal()
                  }
                />
              ) : (
                <ProductSupplierInfo
                  handleSubmit={handleValidateProductSupplier}
                  goBack={() => (productId ? setIsProductDataFilled(true) : handleClosePortal())}
                />
              )}
            </>
          ) : (
            <>
              {potentialDevices.length > 0 && !selectedAswoDevice && !deviceNotFound ? (
                <PageLayout>
                  <HeaderWithArrow
                    title="Identification"
                    action={() => setApplianceNameplate(null)}
                  />
                  <DeviceSelection
                    handleSubmit={handleValidateAswoDeviceSelection}
                    potentialDevices={potentialDevices}
                    submitNoSufficientDeviceFound={() => setDeviceNotFound(true)}
                  />
                </PageLayout>
              ) : (
                <ProductReceptionInfo
                  selectedAswoDevice={selectedAswoDevice}
                  selectedReference={selectedReference}
                  selectedProductType={selectedProductType}
                  selectedProductBrand={selectedProductBrand}
                  handleProductReception={handleProductReception}
                  buyingPrice={buyingPrice}
                  customSupplier={customSupplier}
                  applianceNameplate={applianceNameplate}
                  defaultProductId={productId}
                  goBack={goBackToIdentificationScreen}
                  deliveryItem={deliveryItem}
                  errorMessage={errorMessage}
                />
              )}
            </>
          )}
        </>
      )}

      {isProductDataFilled && productId && (
        <ProductDetailScreen
          productId={productId}
          handleRedoIdentification={handleRedoIdentification}
          handleClosePortal={handleClosePortal}
          errorMessage={errorMessage}
          isProductionReceptionPending={isProductionReceptionPending}
          goBack={() => setIsProductDataFilled(false)}
        />
      )}
    </PortalComponent>
  );
};

export default ProductReception;
