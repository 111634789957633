import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import styled from 'styled-components';

import { SECTION_WEAR } from 'pages/RepairCycleTasks/RepairCycleUtils';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { ProductCheckListReport } from 'types/product-history';

import HistoryEventHeader from './SimpleBlocks/HistoryEventHeader';
import SimpleBlockContent from './SimpleBlocks/SimpleBlockContent';

type ProductCheckListReportBlockProps = {
  productCheckListReport: ProductCheckListReport;
};

const RedCheck = styled(CancelIcon)((props) => ({
  color: props.theme.colors.red,
  height: 18,
  width: 18,
}));

const GreenCheck = styled(CheckBoxIcon)((props) => ({
  color: props.theme.colors.turquoise,
  height: 18,
  width: 18,
}));

const getTitle = (checklist: string): string => {
  switch (checklist) {
    case SECTION_WEAR:
      return 'Contrôle des pièces';
    default:
      return checklist;
  }
};

const ProductCheckListReportBlock: React.FunctionComponent<ProductCheckListReportBlockProps> = ({
  productCheckListReport,
}: ProductCheckListReportBlockProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const uncheckedItems = productCheckListReport.items?.filter((item) => !item.is_checked);

  return (
    <Box>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <HistoryEventHeader
          date={productCheckListReport.created_at}
          assignee={productCheckListReport.assignee}
          title={getTitle(productCheckListReport.checklist)}
          tagVariant={'warning'}
        />
      </Box>
      {isOpen && (
        <SimpleBlockContent noSeparator>
          <Box mt={2} width="100%" alignItems="center">
            <>
              {uncheckedItems?.length > 0 ? (
                productCheckListReport.items.map((check) => {
                  return (
                    !check.is_checked && (
                      <Box width="100%" display="flex" mb={2} justifyContent="space-between">
                        <Text variant="smallBold" color="dark">
                          {check.text}
                        </Text>
                        <RedCheck />
                      </Box>
                    )
                  );
                })
              ) : (
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Text variant="smallBold" mb={2} color="dark">
                    {`Aucune pièce usée`}
                  </Text>
                  <GreenCheck />
                </Box>
              )}
            </>
          </Box>
        </SimpleBlockContent>
      )}
    </Box>
  );
};
export default ProductCheckListReportBlock;
