import React, { useEffect, useState } from 'react';

import dataProvider from 'dataProvider';

import OpenScanButton from 'components/OpenScanButton';
import SkuInventory from 'components/SkuInventory/SkuInventory';
import SkuLocationSelectionPortal from 'components/SkuLocationSelectionPortal';

import { SkuLocation } from 'types/sku-location';
import { SparePart } from 'types/spare-part';

const StoreInventory: React.FunctionComponent = () => {
  const [isInventoryOpen, setIsInventoryOpen] = useState<boolean>(false);
  const [isSkuLocationSelectionOpen, setIsSkuLocationSelectionOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<SkuLocation>();
  const [skuList, setSkuList] = useState<SparePart[]>([] as SparePart[]);

  useEffect(() => {
    if (location !== undefined) {
      dataProvider
        .getList('sku', {
          filter: {
            location_id: location.id,
          },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data }) => {
          setSkuList(data as SparePart[]);
        });
    }
  }, [location]);

  const handleCloseInventory = () => {
    setIsInventoryOpen(false);
    setLocation(undefined);
  };
  const handleOpenInventory = (location: SkuLocation) => {
    setIsSkuLocationSelectionOpen(false);
    setLocation(location);
    setIsInventoryOpen(true);
  };
  const handleOpenLocationPortal = () => {
    setIsSkuLocationSelectionOpen(true);
  };
  const handleCloseLocationPortal = () => {
    setIsSkuLocationSelectionOpen(false);
    setLocation(undefined);
  };
  return (
    <>
      <OpenScanButton text={'Scanner un emplacement'} handleOpen={handleOpenLocationPortal} />
      {isSkuLocationSelectionOpen && (
        <SkuLocationSelectionPortal
          isOpen={isSkuLocationSelectionOpen}
          handleClosePortal={handleCloseLocationPortal}
          handleOpenPortal={handleOpenLocationPortal}
          title={'Sélectionner un emplacement'}
          onConfirm={(location: SkuLocation) => handleOpenInventory(location)}
        />
      )}
      {location && (
        <SkuInventory
          open={isInventoryOpen}
          onClose={handleCloseInventory}
          skuList={skuList}
          location={location}
          surplusStatus="instock"
          lossReason="store_inventory"
          redirectUrl="/store/inventory"
          ConfirmationButtonText="Terminer"
          isCreationAllowed
          SkuCreationStatus="instock"
        />
      )}
    </>
  );
};

export default StoreInventory;
