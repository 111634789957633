import React from 'react';

import InfoIcon from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { themeGet } from '@styled-system/theme-get';
import dataProvider from 'dataProvider';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';

import CustomModal from 'pages/Payment/CustomModals';

import SignatureInput from 'components/SignatureInput';
import Box from 'components/atoms/Box';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import TextWithBackground from 'components/atoms/TextWithBackground';
import ButtonBlock from 'components/molecules/ButtonBlock';
import FormErrorMessage from 'components/molecules/FormErrorMessage';
import GoBackHeader from 'components/molecules/GoBackHeader';
import ContentContainer from 'components/templates/ContentContainer';
import PageLayout from 'components/templates/PageLayout';

import { b64toFile } from 'utils/data-formatting';
import { formatPrice } from 'utils/formatting';
import useQuerySearch from 'utils/useQuerySearch';

import BillDetails from '../BillDetails';
import { PaymentMethod, VisitPayment } from '../form-values';
import {
  CASH,
  CHECK,
  CREDIT_CARD,
  CREDIT_CARD_DEFERRED,
  getMethodName,
  getPaymentModeArray,
  physicalPaymentModeArray,
} from '../utils/utils';
import { validationSchemaPaymentInput } from '../validationSchema';

type PaymentProps = {
  hasStripePaymentMethod: boolean;
  totalPaid: number;
  amount: number;
  customerFileId: string;
  visitId: string;
  employee_id: number;
  onSubmit: (data: { payment_amount: number; payment_method: string }) => void;
  onFailure: (data: { payment_amount: number; payment_method: string }) => void;
  goToPaymentProof: (data: { payment_amount: number; payment_method: string }) => void;
};

const StyledForm = styled(Form)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'center',
});
const StyledContentContainer = styled(ContentContainer)({
  padding: 0,
  width: '90%',
  paddingBottom: 120,
});

const StyledInfoIcon = styled(InfoIcon)((props) => ({
  color: themeGet('colors.grey700')(props),
  height: props.theme.sizes[4],
  width: props.theme.sizes[4],
}));

const PaymentInputs: React.FunctionComponent<PaymentProps> = ({
  customerFileId,
  visitId,
  hasStripePaymentMethod,
  totalPaid,
  amount,
  onSubmit,
  onFailure,
  employee_id,
  goToPaymentProof,
}: PaymentProps) => {
  const { toggleAmountDialog, toggleMethodDialog } = CustomModal();
  const getTextButton = (method: string): string => {
    if ([CASH, CHECK].includes(method)) return 'Continuer';
    if (method === CREDIT_CARD_DEFERRED) return 'Déclencher le débit';

    return 'Paiement effectué';
  };
  const query = useQuerySearch();

  const embeded = query.get('embeded');

  type FormValues = {
    amount: number;
    paymentMethod: PaymentMethod;
    signature: string;
  };

  const initialValues: FormValues = {
    amount: amount / 100,
    paymentMethod: hasStripePaymentMethod ? CREDIT_CARD_DEFERRED : CREDIT_CARD,
    signature: '',
  };

  const createPayment = async (values: FormValues, formikBag: FormikHelpers<FormValues>) => {
    const formValues: VisitPayment = {
      amount: values.amount * 100,
      payment_method: values.paymentMethod,
      visit: parseInt(visitId),
      customer_file: parseInt(customerFileId),
      employee: employee_id,
    };

    if ([CASH, CHECK].includes(values.paymentMethod as string)) {
      return goToPaymentProof({
        payment_amount: values.amount,
        payment_method: values.paymentMethod as string,
      });
    }

    if (values.signature) {
      formValues.signature = b64toFile(values.signature, 'image/png');
    }
    const PaymentProcessingMethod =
      values.paymentMethod === CREDIT_CARD_DEFERRED ? 'visit-payment-process' : 'visit-payment';

    try {
      await dataProvider.create(
        PaymentProcessingMethod,
        {
          data: formValues,
        },
        'formData'
      );
      onSubmit({
        payment_amount: values.amount,
        payment_method: values.paymentMethod as string,
      });
    } catch (errors) {
      if (values.paymentMethod === CREDIT_CARD_DEFERRED) {
        formikBag.setFieldValue('paymentMethod', CREDIT_CARD);
      }
      onFailure({
        payment_amount: values.amount,
        payment_method: values.paymentMethod as string,
      });
    }
  };

  const getTextExplanation = () => {
    return (
      <Box mt={1} textAlign="initial">
        <Box display="flex">
          <StyledInfoIcon />
          <Box ml={1}>
            <Text variant="buttonSmall">
              <b>Privilégier les paiements par empreinte CB ou TPE.</b>
            </Text>
            <Text variant="buttonSmall">
              Si le client insiste pour payer autrement, les options supplémentaires sont
              disponibles ci-dessous.
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <PageLayout>
      <GoBackHeader
        title="Paiement"
        backLink={`/compte-rendu/${customerFileId}/dashboard?visit=${visitId}`}
      />
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={createPayment}
        validationSchema={validationSchemaPaymentInput}
      >
        {({ values, setFieldValue, isSubmitting, isValid }) => {
          return (
            <StyledForm>
              <StyledContentContainer>
                <BillDetails totalPaid={totalPaid} customerFileId={customerFileId} />
                <Box
                  id="Box-amount"
                  bg="None"
                  display="flex"
                  py={24}
                  pl={'4px'}
                  pr={'8px'}
                  width="100%"
                  borderTop="1px solid #DBDFE8"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => toggleAmountDialog(values.amount, setFieldValue)}
                >
                  <Text variant="bodyBold">Montant dû</Text>
                  <div style={{ display: 'flex', color: '#8795B3' }}>
                    <Text variant="bodyBold" mr={2}>
                      {formatPrice(values.amount * 100)}
                    </Text>
                    <MoreVertIcon />
                  </div>
                </Box>
                <Box
                  bg="white"
                  borderRadius="8px"
                  display="flex"
                  py={16}
                  pl={16}
                  pr={'8px'}
                  width="100%"
                  justifyContent="space-between"
                  mt={2}
                  onClick={() =>
                    toggleMethodDialog(
                      getPaymentModeArray(hasStripePaymentMethod) || [],
                      values.paymentMethod,
                      setFieldValue,
                      getTextExplanation(),
                      physicalPaymentModeArray
                    )
                  }
                >
                  <Text variant="body">Paiement</Text>
                  <Box display="inline-flex" color="#8795B3">
                    <TextWithBackground borderRadius="10px" mr={2}>
                      {getMethodName(values.paymentMethod)}
                    </TextWithBackground>
                    <MoreVertIcon />
                  </Box>
                </Box>
                {values.paymentMethod === CREDIT_CARD_DEFERRED && (
                  <>
                    <Text variant="small" pt={2} pb={1}>
                      Signature du client
                    </Text>
                    <SignatureInput />
                  </>
                )}
                <ErrorMessage name="paymentMethod" component={FormErrorMessage} />
              </StyledContentContainer>
              {embeded !== 'true' && (
                <Link
                  to={`/compte-rendu/${customerFileId}/payment?visit=${visitId}`}
                  mb={2}
                  color={'grey900'}
                >
                  Je n&apos;ai pas pu encaisser
                </Link>
              )}
              <ButtonBlock variant="primary" disabled={!isValid || isSubmitting}>
                {getTextButton(values.paymentMethod as string)}
              </ButtonBlock>
            </StyledForm>
          );
        }}
      </Formik>
    </PageLayout>
  );
};

export default PaymentInputs;
