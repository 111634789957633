import React from 'react';

import { Sidetab } from '@typeform/embed-react';
import { useEmployee } from 'employeeContext';

import { useWorkshops } from 'utils/api/api';

const DEFAULT_FORM_ID = 'iYiAQ3W2';

type TypeformDefaultSideTabProps = {
  barcode: string;
  formId?: string;
};

const TypeformDefaultSideTab: React.FunctionComponent<TypeformDefaultSideTabProps> = ({
  barcode,
  formId = DEFAULT_FORM_ID,
}: TypeformDefaultSideTabProps) => {
  const { employee } = useEmployee();
  const { workshops } = useWorkshops();

  return (
    <Sidetab
      id={formId}
      buttonText="Défaut"
      hidden={{
        product_barcode: barcode,
        tech_name: `${employee.user.first_name} ${employee.user.last_name}`,
        tech_workshop:
          (workshops && workshops.find((workshop) => workshop.id === employee.workshop)?.name) ||
          '',
      }}
      buttonColor="#F7BDBD"
    />
  );
};

export default TypeformDefaultSideTab;
