import React, { useRef, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useBarcode } from 'react-barcodes';
import { useReactToPrint } from 'react-to-print';

import PortalComponent from 'components/Portal/PortalComponent';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';

import StoreStyles from '../StoreStyles';

const useStyles = makeStyles(StoreStyles);

const RenderBarcode = ({ barcode }: { barcode: string }) => {
  const { inputRef } = useBarcode({ value: barcode });
  return <img ref={inputRef}></img>;
};

type PrintContentProps = {
  handleNextStep: () => void;
  onClose: () => void;
  barCode?: string;
  firstText?: string;
  secondText?: string;
  codeSubtitle?: string;
  LeftIcon?: React.FunctionComponent;
  RightIcon?: React.FunctionComponent;
  open: boolean;
  headingText?: string;
  qrCode?: string;
};

const PrintContent: React.FunctionComponent<PrintContentProps> = ({
  handleNextStep,
  onClose,
  barCode,
  firstText,
  secondText,
  codeSubtitle,
  LeftIcon,
  RightIcon,
  open,
  headingText,
  qrCode,
}: PrintContentProps) => {
  const classes = useStyles();
  const componentRef = useRef(null);
  const [validationStep, setValidationStep] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleAfterPrint(),
  });

  const handleAfterPrint = () => {
    setValidationStep(true);
  };

  const handleValidateStep = () => {
    setValidationStep(false);
    handleNextStep();
  };
  const handleForceClose = () => {
    setValidationStep(false);
    onClose();
  };

  const handlePrintButtonClick = () => {
    setValidationStep(false);
    handlePrint && handlePrint();
  };

  return (
    <PortalComponent open={open} onClose={handleForceClose} title="imprimer le code">
      {!validationStep && (
        <>
          <Box textAlign="center" paddingTop="47px">
            <Typography variant="h6">{firstText}</Typography>
            <Typography variant="h5">{secondText}</Typography>
          </Box>{' '}
          <div
            className={barCode ? classes.barcodeStyle : classes.receptionResumeContainer}
            ref={componentRef}
          >
            {barCode ? (
              <>
                <RenderBarcode barcode={barCode} />
                <Text variant="extraSmall">{codeSubtitle}</Text>
              </>
            ) : (
              <div>
                {headingText && (
                  <Box
                    bgcolor="black"
                    color="white"
                    borderRadius={32}
                    width={100}
                    margin="auto"
                    padding={1}
                  >
                    {headingText}
                  </Box>
                )}
                <Box display="inline-flex">
                  {LeftIcon && (
                    <Box borderRadius="50%" margin="auto">
                      <LeftIcon />
                    </Box>
                  )}
                  <img alt="QrCode" className={classes.qrImageContainer} src={qrCode} />
                  {RightIcon && (
                    <Box borderRadius="50%" margin="auto">
                      <RightIcon />
                    </Box>
                  )}
                </Box>
                <Box position="relative">{codeSubtitle}</Box>
              </div>
            )}
          </div>
          <Box justifyContent="space-between" height={64}>
            <Button variant="secondary" onClick={handlePrintButtonClick} width="95%">
              Imprimer
            </Button>
          </Box>
        </>
      )}
      {validationStep && (
        <div className={classes.receptionResumeContainerConfirmation} ref={componentRef}>
          <Box display="grid" gridRowGap={16}>
            <Text variant="title2">Confirmez l&apos;impression</Text>
            <Button onClick={handleValidateStep} variant="secondary">
              CONFIRMER
            </Button>
            <Button onClick={() => setValidationStep(false)} variant="secondary">
              RÉ-IMPRIMER L&apos;ETIQUETTE
            </Button>
          </Box>
        </div>
      )}
    </PortalComponent>
  );
};

export default PrintContent;
