import React from 'react';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';

import { IncentiveLabel } from 'types/incentive-label';

import getLabelRepairDescription from './LabelDescription';

type IncentiveDescriptionLabelModalProps = {
  isOpenDescription: boolean;
  setIsOpenDescription: (value: boolean) => void;
  selectedValue: { label: IncentiveLabel; title: string };
};

const IncentiveDescriptionLabelModal: React.FunctionComponent<
  IncentiveDescriptionLabelModalProps
> = ({
  isOpenDescription,
  setIsOpenDescription,
  selectedValue,
}: IncentiveDescriptionLabelModalProps) => {
  return (
    <PortalComponent
      title={selectedValue.title}
      open={isOpenDescription}
      onClose={() => setIsOpenDescription(false)}
    >
      <Box display="flex" m={2} justifyContent="left">
        {getLabelRepairDescription(selectedValue.label)}
      </Box>
    </PortalComponent>
  );
};

export default IncentiveDescriptionLabelModal;
