import React, { useState } from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';

import PortalComponent from 'components/Portal/PortalComponent';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

import { patchAccount } from 'utils/api/api';

type PasswordChangeProps = {
  onClose: () => void;
  open: boolean;
  onSuccessCallback: () => void;
};

type FormValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const PasswordChange: React.FunctionComponent<PasswordChangeProps> = ({
  open,
  onClose,
  onSuccessCallback,
}: PasswordChangeProps) => {
  const [error, setError] = useState<string>();

  const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const handleChangePassword = async (values: FormValues) => {
    try {
      await patchAccount({
        user: { new_password: values.newPassword, confirm_password: values.oldPassword },
      });
      onSuccessCallback();
      onClose();
    } catch (error) {
      setError(
        `Un problème est survenu: vous assurer que votre nouveau mot de passe respecte les règles et que votre ancien mot de passe est correct `
      );
    }
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("L'ancien mot de passe est requis"),
    newPassword: yup
      .string()
      .required('Un mot de passe est requis')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
        'Le nouveau mot de passe doit contenir: au moins 1 majuscule, au moins 1 chiffre, au moins 8 caractères.'
      ),
    newPasswordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Les mots de passe  ne correspondent pas')
      .required('La confirmation est obligatoire'),
  });

  return (
    <PortalComponent open={open} onClose={onClose} title="Modification de mot de passe">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleChangePassword}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Box px={1} pb={1}>
              <Text variant="small">
                Le nouveau mot de passe doit contenir: {<br />} - Au moins 1 majuscule {<br />} - Au
                moins 1 chiffre {<br />}- Au moins 8 caractères
              </Text>
            </Box>
            <Field name="oldPassword">
              {({ field: { name, onChange, value } }: FieldProps) => {
                return (
                  <Input
                    width="90%"
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Ancien mot de passe"
                    type="password"
                  />
                );
              }}
            </Field>
            <Text variant="small" color="red">
              {errors?.oldPassword}
            </Text>
            <Field name="newPassword">
              {({ field: { name, onChange, value } }: FieldProps) => {
                return (
                  <Input
                    width="90%"
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Nouveau mot de passe"
                    type="password"
                  />
                );
              }}
            </Field>
            <Text variant="small" color="red">
              {errors?.newPassword}
            </Text>
            <Field name="newPasswordConfirmation">
              {({ field: { name, onChange, value } }: FieldProps) => {
                return (
                  <Input
                    width="90%"
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Confirmation"
                    type="password"
                  />
                );
              }}
            </Field>
            <Text variant="small" color="red">
              {errors?.newPasswordConfirmation}
            </Text>
            {error && (
              <Box px={1} pb={1}>
                <Text variant="small" color="red">
                  {error}
                </Text>
              </Box>
            )}
            <BottomButton disabled={isSubmitting} type="submit">
              Confirmer
            </BottomButton>
          </Form>
        )}
      </Formik>
    </PortalComponent>
  );
};

export default PasswordChange;
