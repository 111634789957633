import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import QrReader from 'react-qr-reader';

// Style imports
import ScannerStyle from './styles/ScannerStyle';

const Scanner = (props) => {
  const handleScan = (data) => {
    if (data) {
      const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      !iOS && window && window.navigator && window.navigator.vibrate([50, 50, 50]);
      props.handleConfirmBarcode(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div className={props.classes.ReaderContainer}>
      <QrReader
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
        scanDelay={300}
      />
    </div>
  );
};

export default withStyles(ScannerStyle)(Scanner);
