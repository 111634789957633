/**
 * Function used to return an object sorted alphabetically on its keys
 * @param {obj} obj - The object to sort
 */

export function sortObjByKey<T extends Record<string, unknown>>(obj: T): T {
  return Object.keys(obj)
    .sort()
    .reduce((result: T, key: keyof T): T => {
      result[key] = obj[key];
      return result;
    }, {} as T);
}
