import React, { useState } from 'react';

import SparePartScanner from 'components/Scanner/SparePartScanner';

import { Package } from 'types/package';
import { SparePart } from 'types/spare-part';

import SparePartPackageList from '../../../SparePartPackageList';

type SparePartSelectionProps = {
  selectedPackage: Package;
  selectedIds: number[];
  sparePartList: SparePart[];
  lostSparePartList: SparePart[];
  surplusSparePartList: SparePart[];
  addSparePart: (id: number) => void;
  handleDoubleClickCheck: () => void;
  onClose: () => void;
  handleSavePackage: (selectedIds: number[], spareParts: SparePart[]) => void;
  getSparePartSurplus: (uniqueId: string) => void;
};

const SparePartSelection: React.FunctionComponent<SparePartSelectionProps> = ({
  sparePartList,
  selectedPackage,
  selectedIds,
  lostSparePartList,
  surplusSparePartList,
  addSparePart,
  handleDoubleClickCheck,
  onClose,
  handleSavePackage,
  getSparePartSurplus,
}: SparePartSelectionProps) => {
  const [isSparePartSelectionModalOpen, setIsSparePartSelectionModalOpen] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(selectedIds.length === 0);

  const handleBarcodeScan = (code: string) => {
    const result = sparePartList.find((el) => el.unique_id === code);
    if (result && !selectedIds.includes(result.id)) {
      addSparePart(result.id);
    } else if (!result) {
      getSparePartSurplus(code);
    }

    setIsModalOpen(false);
  };

  const handleManualInput = (sparePartId: number) => {
    setIsSparePartSelectionModalOpen(true);
    setIsModalOpen(false);
    addSparePart(sparePartId);
  };

  return (
    <>
      <SparePartPackageList
        open={isSparePartSelectionModalOpen}
        onClose={onClose}
        packageId={selectedPackage?.id || null}
        selectedIds={selectedIds}
        handleOpenScanModal={() => setIsModalOpen(true)}
        lostSparePartList={lostSparePartList}
        surplusSparePartList={surplusSparePartList}
        onCloseCheck={handleDoubleClickCheck}
        handleSavePackage={handleSavePackage}
      />
      {isModalOpen && (
        <SparePartScanner
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleConfirmBarcode={handleBarcodeScan}
          packageId={selectedPackage?.id}
          handleManualInput={handleManualInput}
          excludedIdsList={[
            ...selectedIds,
            ...lostSparePartList.map((sparePart: SparePart) => sparePart.id),
          ]}
        />
      )}
    </>
  );
};
export default SparePartSelection;
