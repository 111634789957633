import React from 'react';

const SecondHandIcon = () => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.5" cy="10.0003" r="9.5" fill="#4A55FE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 6.64734C7.65029 6.64734 6.14706 8.15057 6.14706 10.0003H7.82353L5.58824 12.2356L3.35294 10.0003H5.02941C5.02941 7.53028 7.03 5.52969 9.5 5.52969C10.3774 5.52969 11.1932 5.78675 11.8806 6.22263L11.0647 7.03851C10.6009 6.78704 10.0644 6.64734 9.5 6.64734ZM11.1765 10.0003L13.4118 7.76498L15.6471 10.0003H13.9706C13.9706 12.4703 11.97 14.4709 9.5 14.4709C8.62265 14.4709 7.80676 14.2138 7.11941 13.7779L7.93529 12.962C8.39912 13.2135 8.93559 13.3532 9.5 13.3532C11.3497 13.3532 12.8529 11.85 12.8529 10.0003H11.1765Z"
      fill="white"
    />
  </svg>
);

export default SecondHandIcon;
