import { Product } from 'types/products';

const getProductType = (product: Product): string => {
  if (
    product.product_type &&
    ['Réfrigérateur', 'Congélateur'].includes(product.product_type) &&
    product.subcategory1
  )
    return product.subcategory1;
  return product.product_type;
};

const getProductBrand = (product: Product): string => {
  return (product.brand && ` • ${product.brand}`) || '';
};

export const formatProductTitle = (product: Product): string => {
  return getProductType(product) + getProductBrand(product);
};

export const PRODUCT_FUNCTIONAL_STATUS_WORKING = 'working';
export const PRODUCT_FUNCTIONAL_STATUS_NOT_WORKING = 'not_working';
export const PRODUCT_FUNCTIONAL_STATUS = [
  PRODUCT_FUNCTIONAL_STATUS_WORKING,
  PRODUCT_FUNCTIONAL_STATUS_NOT_WORKING,
];

export type ProductFunctionnalStatusScreenFormValue = {
  isRepaired: string;
};
