import React, { useState } from 'react';

import dataProvider from 'dataProvider';
import { Form, Formik, FormikProps } from 'formik';

import RepairProductChecklist from 'pages/RepairCycleTasks/RepairCycleRepairReport/RepairProductChecklist/RepairProductChecklist';
import {
  OVERALL_APPAREANCE,
  SPARE_PART_CLEANLINESS,
  STARTING_UP,
  WIRES_AND_PIPES,
} from 'pages/RepairCycleTasks/RepairCycleUtils';
import { postCheckLists } from 'pages/RepairCycleTasks/api';

import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';

import { Product } from 'types/products';

type RepairReportFormProps = {
  product: Product;
  handleClose: () => void;
  initialValues: RepairReportFormValue;
};

type FormattedChecklistElement = {
  id: number;
  text: string;
  is_checked: boolean;
};

type RepairReportFormValue = {
  overall_appearance: FormattedChecklistElement[];
  spare_part_cleanliness: FormattedChecklistElement[];
  wires_and_pipes: FormattedChecklistElement[];
  starting_up: FormattedChecklistElement[];
};

const steps = [OVERALL_APPAREANCE, SPARE_PART_CLEANLINESS, WIRES_AND_PIPES, STARTING_UP];
function _renderStepContent(
  step: number,
  formProps: FormikProps<RepairReportFormValue>,
  _handleSubmit: (values: RepairReportFormValue) => void
) {
  // checking if the current step has data to display
  // if not, going straight to the next step
  const actualStep = steps[step];
  (Object.keys(formProps.values) as (keyof RepairReportFormValue)[]).forEach((key) => {
    if (
      key === actualStep &&
      formProps.values[actualStep] &&
      formProps.values[actualStep]?.length < 1
    ) {
      _handleSubmit(formProps.values);
    }
  });

  switch (step) {
    case 0:
      return (
        <RepairProductChecklist
          values={formProps.values}
          checklistType={OVERALL_APPAREANCE}
          title={'Aspect général'}
        />
      );
    case 1:
      return (
        <RepairProductChecklist
          values={formProps.values}
          checklistType={SPARE_PART_CLEANLINESS}
          title={'Propreté des pièces'}
        />
      );
    case 2:
      return (
        <RepairProductChecklist
          values={formProps.values}
          checklistType={WIRES_AND_PIPES}
          title={'Câbles et tuyaux'}
        />
      );
    case 3:
      return (
        <RepairProductChecklist
          values={formProps.values}
          checklistType={STARTING_UP}
          title={'Démarrage'}
        />
      );
    default:
      return <div>Not Found</div>;
  }
}

const RepairReportForm: React.FunctionComponent<RepairReportFormProps> = ({
  product,
  handleClose,
  initialValues,
}: RepairReportFormProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  async function _submitForm(values: RepairReportFormValue) {
    if (values?.overall_appearance?.length > 0) {
      await postCheckLists(product.id, OVERALL_APPAREANCE, values.overall_appearance);
    }
    if (values?.spare_part_cleanliness?.length > 0) {
      await postCheckLists(product.id, SPARE_PART_CLEANLINESS, values.spare_part_cleanliness);
    }
    if (values?.wires_and_pipes?.length > 0) {
      await postCheckLists(product.id, WIRES_AND_PIPES, values.wires_and_pipes);
    }
    if (values?.starting_up?.length > 0) {
      await postCheckLists(product.id, STARTING_UP, values.starting_up);
    }

    dataProvider
      .create('workshop-task', {
        data: {
          state: 'done',
          workshop_file: product.workshop_file,
          type: 'cleaning',
        },
      })
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function _handleSubmit(values: RepairReportFormValue) {
    if (isLastStep) {
      _submitForm(values);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      <HeaderWithArrow title="Nettoyage" action={activeStep !== 0 ? _handleBack : handleClose} />
      <Box overflow="auto" mb={64}>
        <Formik initialValues={initialValues} onSubmit={_handleSubmit} enableReinitialize>
          {({ ...props }: FormikProps<RepairReportFormValue>) => {
            return (
              <Form>
                {_renderStepContent(activeStep, props, _handleSubmit)}
                <BottomButton disabled={!props.isValid}>Confirmer</BottomButton>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default RepairReportForm;
