import dataProvider, { APIResponse } from '../../dataProvider';
import { Product } from '../../types/products';
import { Quote } from '../../types/quotes';
import { getProductModel } from './api';

export const applyBonusToQuote = (quote: Quote, product?: Product): Promise<APIResponse> => {
  const params = product ? { data: { product_id: product.id } } : {};
  return dataProvider.create(`payment/quotebilling/${quote.uuid}/add_repair_bonus`, params);
};

export const removeBonusFromQuote = (quote: Quote, product: Product): Promise<APIResponse> => {
  const params = { data: { product_id: product.id } };
  return dataProvider.post(`payment/quotebilling/${quote.uuid}/delete_repair_bonus`, params);
};

export async function RepairBonusQuery(product: Product): Promise<{ amount: number }> {
  const { data } = await dataProvider.getOne('repair-bonus-amount', {
    brand: product.brand,
    product_type: encodeURIComponent(product.product_type),
  });
  return data;
}

export const getRepairBonusEligibility = async (product: Product): Promise<boolean> => {
  if (product.product_model) {
    const productModel = await getProductModel(product.product_model as number);
    if (productModel.product_type) {
      product.product_type = productModel.product_type.name;
    }
    if (productModel.brand) {
      product.brand = productModel.brand.name;
    }
  }
  const data = await RepairBonusQuery(product);
  if (data) return data.amount !== 0;
  return false;
};
