// React imports
import React from 'react';

import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import HomeRepairDashboard from 'pages/RepairReport/components/stepComponents/HomeRepairDashboard';

import { useCustomerFile } from 'utils/api/hooks';

type DashboardProps = {
  customerFileId: number;
  isErrorShown: boolean;
  errorMessage: string;
  handleSubmit: () => void;
  isLoading: boolean;
  formUploadProgress?: number;
  visitId: string;
  productId: string;
  productIdentificationByPassed: number[];
  productsWithBonusChecked: number[];
};

const Dashboard: React.FunctionComponent<DashboardProps> = ({
  customerFileId,
  visitId,
  handleSubmit,
  errorMessage,
  isErrorShown,
  isLoading,
  productIdentificationByPassed,
  productsWithBonusChecked,
  productId,
}: DashboardProps) => {
  const {
    data: customerFile,
    isLoading: isCustomerFileLoading,
    error,
  } = useCustomerFile(customerFileId, { include: 'sku_demands' });

  if (isCustomerFileLoading) {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Récupération des données du dossier client en cours
        </Typography>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" gutterBottom>
        Une erreur est survenue lors de la récupération des données du dossier client
      </Typography>
    );
  }
  return (
    <>
      {customerFile && (
        <HomeRepairDashboard
          handleSubmit={handleSubmit}
          customerFile={customerFile}
          isLoading={isCustomerFileLoading || isLoading}
          isErrorShown={isErrorShown}
          visitId={visitId}
          productId={productId}
          errorMessage={errorMessage || ''}
          productIdentificationByPassed={productIdentificationByPassed}
          productsWithBonusChecked={productsWithBonusChecked}
        />
      )}
    </>
  );
};

export default Dashboard;
