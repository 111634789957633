import { adminSaga } from 'react-admin';

import { all } from 'redux-saga/effects';

// customs sagas
import { postFormSaga } from 'pages/RepairReport/components/module';

export default function* rootSaga(dataProvider) {
  yield all([postFormSaga(), adminSaga(dataProvider)]);
}
