import React from 'react';

import { MarginProps } from 'styled-system';

import StatBlock from 'components/StatBlock';
import Box from 'components/atoms/Box';

type StatBlockContainerProps = {
  percentageOfFinished: string;
  numberOfPointsPerDay: string;
  numberOfVisitPerDay: string;
  numberOfPointsPerVisit: string;
} & MarginProps;

const StatBlockContainer: React.FunctionComponent<StatBlockContainerProps> = ({
  percentageOfFinished,
  numberOfPointsPerDay,
  numberOfVisitPerDay,
  numberOfPointsPerVisit,
  ...rest
}: StatBlockContainerProps) => {
  return (
    <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" {...rest} pr={1} pl={1}>
      <StatBlock title={numberOfPointsPerDay} subTitle="points/j ce mois-ci" m={1} />
      <StatBlock title={percentageOfFinished} subTitle="terminé réparé" m={1} />
      <StatBlock title={numberOfPointsPerVisit} subTitle="points/passage" m={1} />
      <StatBlock title={numberOfVisitPerDay} subTitle="passage/jours" m={1} />
    </Box>
  );
};

export default StatBlockContainer;
