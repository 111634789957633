import React, { useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

export default function ClaimNotificationError({ status, setStatus }) {
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    setOpen(true);
  }, [status]);

  function handleClose() {
    setOpen(false);
    setStatus('');
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        status={status}
        contentprops={{
          'aria-describedby': 'message-id',
        }}
        // prettier-ignore
        message={(
                    <span id='message-id'>
                        Vous n'avez pas de manager enregistré, la réclamation ne peut être envoyée.
                    </span>
                )}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
