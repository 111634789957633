import React from 'react';

import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import Text from 'components/atoms/Text';
import MultiChoicesButton from 'components/molecules/MultiChoicesButton';
import PageLayout from 'components/templates/PageLayout';

type SparePartsNeededChoiceProps = {
  submit: (data: boolean) => void;
  back: () => void;
  selectedValue: boolean | null;
};

export const SparePartsNeededChoice: React.FunctionComponent<SparePartsNeededChoiceProps> = ({
  submit,
  back,
  selectedValue,
}: SparePartsNeededChoiceProps) => {
  return (
    <PageLayout>
      <HeaderWithArrow title="Pièces détachées" action={back} />
      <Text m={3} variant="subtitle">
        Besoin de pièces détachées ?
      </Text>
      <MultiChoicesButton<boolean>
        choices={[
          { name: 'Oui', value: true },
          { name: 'Non', value: false },
        ]}
        action={submit}
        selectedValue={selectedValue}
      />
    </PageLayout>
  );
};

export default SparePartsNeededChoice;
