import React from 'react';

import styled from 'styled-components';
import { MarginProps } from 'styled-system';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { useRadioFieldContext } from '../RadioFieldList/RadioFieldList';

export type RadioFieldItemProps = {
  name: string;
  value: string;
  label: string;
} & MarginProps;

const StyledLabel = styled.label`
  color: #2b3b5c;
  font-size: 18px;
  display: flex;
  width: 100%;
  font-weight: 400;
  line-height: 135%;
  margin-left: 16px;
`;

const StyledField = styled.input`
  width: 10%;
  height: 16px;
  margin-top: 4px;
`;

const RadioFieldItem: React.FunctionComponent<RadioFieldItemProps> = ({
  label,
  value,
  name,
  ...rest
}: RadioFieldItemProps) => {
  const { activeItem, handleRadioClick } = useRadioFieldContext();

  return (
    <Box bg="white" borderRadius="10px" display="flex" padding={1} width="100%" {...rest}>
      <StyledLabel>
        <StyledField
          type="radio"
          checked={value === activeItem}
          value={value}
          name={name}
          onClick={() => handleRadioClick(value)}
        />
        <Text variant="body">{label}</Text>
      </StyledLabel>
    </Box>
  );
};

export default RadioFieldItem;
