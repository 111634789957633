import React from 'react';

import { useStateMachine } from './StateMachineProvider';

interface StateRendererProps {
  state: string;
  children: (send: any, currentState: any) => React.ReactNode;
}

const StateRenderer: React.FunctionComponent<StateRendererProps> = ({ state, children }) => {
  const { state: currentState, send } = useStateMachine();
  return currentState.matches(state) ? <>{children(send, currentState)}</> : <></>;
};

export default StateRenderer;
