import dataProvider from 'dataProvider';

import { ShippingDelivery } from 'types/shipping-deliveries';

export const getDeliveries = async (
  filter: Record<string, unknown>
): Promise<ShippingDelivery[]> => {
  const { data } = await dataProvider.getList('deliveries', {
    filter: filter,
    sort: { field: 'id', order: 'ASC' },
  });
  return data;
};
