import React from 'react';

import Phone from '@material-ui/icons/Phone';

import { MarginProps } from 'styled-system';

import PaperStripLink from 'components/PaperStripLink';

type CallButtonProps = {
  phone: string;
  text: string;
} & MarginProps;

const CallButton: React.FunctionComponent<CallButtonProps> = ({
  phone,
  text,
  ...rest
}: CallButtonProps) => {
  return (
    <PaperStripLink {...rest} text={text} link={`tel:${phone}`} Icon={Phone} linkMode="href" />
  );
};

export default CallButton;
