// React imports
import React, { useState } from 'react';

// Component imports
import { Typography } from '@material-ui/core';
// Style imports
import { makeStyles } from '@material-ui/core/styles';
// Material-ui icons imports
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

import Quagga from 'quagga';
import { useForm } from 'react-final-form';

import ProductList from 'pages/Transfer/components/ProductList';
import TransferProductAdditionStyle from 'pages/Transfer/styles/TransferProductAdditionStyle';

import ProductSelectionPortal from 'components/ProductSelectionPortal/ProductSelectionPortal';

const useCreationStyles = makeStyles(TransferProductAdditionStyle);

export default function RepairReportProductAddition() {
  const creationClasses = useCreationStyles();

  const [nbProducts, setNbProducts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm();
  var formdata = form.getState().values; // accesses directly to the form values

  const stopCamera = () => {
    if (Quagga.CameraAccess.getActiveTrack()) {
      Quagga.offDetected();
      Quagga.offProcessed();
      Quagga.stop();
    }
  };

  const isBarcodeAlreadyAdded = (barcode) => {
    if (formdata.addProduct && formdata.addProduct.length > 0) {
      for (var i = 0; i < formdata.addProduct.length; i++) {
        if (barcode === formdata.addProduct[i].barcode) {
          return true;
        }
      }
    }
    return false;
  };

  const handleConfirmBarcode = (data) => {
    const productBarcode = data.barcode;
    if (isBarcodeAlreadyAdded(productBarcode)) {
      form.mutators.removeProduct({ barcode: productBarcode });
    } else {
      form.mutators.addProductInForm({ product: data });
      setNbProducts(nbProducts + 1);
    }
    stopCamera();
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const removeProduct = (product) => {
    form.mutators.removeProduct({ id: product.id });
    setNbProducts(nbProducts - 1);
  };
  return (
    <div>
      <Typography
        variant="h6"
        className={creationClasses.marginLeft}
      >{`${nbProducts} appareil(s) sélectionné(s)`}</Typography>
      {nbProducts === 0 ? (
        <div className={creationClasses.paddingContainer}>
          <div className={creationClasses.dashedSquare}>
            <div className={creationClasses.clickableDiv}>
              <CenterFocusStrongIcon
                id="add-new-product-button-0"
                className={creationClasses.scanNewProductButton}
                onClick={handleOpen}
              />
              <div>Scanner un appareil</div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <ProductList products={formdata.addProduct} removeProduct={removeProduct} />
          <div className={creationClasses.SmallClickableDiv}>
            <CenterFocusStrongIcon
              id="add-new-product-button-1"
              className={creationClasses.scanNewProductButton}
              onClick={handleOpen}
            />
          </div>
        </div>
      )}
      {isModalOpen && (
        <ProductSelectionPortal
          buttonText="Confirmer"
          onClose={handleClose}
          onProductSelection={handleConfirmBarcode}
          open={isModalOpen}
        />
      )}
    </div>
  );
}
