import React from 'react';

import { useEmployee } from 'employeeContext';
import { useTourStatus } from 'tourStatusContext';

import Loader from 'components/Loader';
import VisitList from 'components/VisitList';
import Box from 'components/atoms/Box';

import { useSevenOpteamVisitList } from 'utils/api/hooks';

import TourSummary from './components/TourSummary';

interface MurfyCalendarProps {
  selectedDate: string;
}
export const SevenOpteamCalendar: React.FunctionComponent<MurfyCalendarProps> = ({
  selectedDate,
}: MurfyCalendarProps) => {
  const { tourStatus, setRepairmanTourStatus, isLoading } = useTourStatus();
  const { employee } = useEmployee();
  const { visitList, loadingVisits } = useSevenOpteamVisitList(employee.id, selectedDate);
  return loadingVisits ? (
    <Box mb={4}>
      <Loader />
    </Box>
  ) : (
    <>
      {tourStatus !== null && (
        <Box mb={2}>
          <TourSummary
            visitCount={visitList.length}
            firstVisit={visitList?.[0]}
            date={selectedDate}
            setRepairmanTourStatus={setRepairmanTourStatus}
            tourStatus={tourStatus}
            remainingVisitCount={visitList.filter((visit) => !visit.is_over).length}
            areAllReportsComplete={visitList.every((visit) => visit.is_report_complete)}
            isLoading={isLoading}
          />
        </Box>
      )}
      <VisitList visitList={visitList} />
    </>
  );
};

export default SevenOpteamCalendar;
