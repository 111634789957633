import React from 'react';

import Box from 'components/atoms/Box';

export interface RoundTabProps {
  handleClick: () => void;
  children: React.ReactNode;
}

const RoundTab: React.FunctionComponent<RoundTabProps> = ({
  handleClick,
  children,
}: RoundTabProps) => {
  return (
    <Box
      onClick={handleClick}
      borderRadius="50%"
      justifyContent="center"
      display="flex"
      alignItems="center"
      backgroundColor="#F2F2F2"
      width={48}
      height={48}
      border="1px solid #D7D7D7"
    >
      {children}
    </Box>
  );
};

export default RoundTab;
