import React, { useEffect, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import styled from 'styled-components';

import TransferProductAdditionStyle from 'pages/Transfer/styles/TransferProductAdditionStyle';

import Text from 'components/atoms/Text';
import AutocompleteInput from 'components/molecules/AutocompleteInput';

import { Location } from 'types/location';

import QRScanner from 'utils/QrScanner';

const useCreationStyles = makeStyles(TransferProductAdditionStyle);

const StyledAutocompleteInput = styled(AutocompleteInput)((props) => ({
  height: props.theme.sizes[4],
  width: '100%',
  margin: 0,
  fontSize: props.theme.fontSizes[4],
}));

type SelectLocationProps = {
  error: string;
  handleConfirmBarcode: (text: string) => void;
  locations: Location[];
  handleChangeLocation: (locationId: number) => void;
};

const SelectLocation: React.FunctionComponent<SelectLocationProps> = ({
  error,
  handleConfirmBarcode,
  locations,
  handleChangeLocation,
}: SelectLocationProps) => {
  const creationClasses = useCreationStyles();
  const [locationList, setLocationList] = useState<ReadonlyArray<Location>>([]);
  const [shouldDisplayScanner, setShouldDisplayScanner] = useState<boolean>(true);

  useEffect(() => {
    setLocationList(
      locations?.map((location: Location) => ({
        ...location,
        value: location.id,
        label: location.name,
      }))
    );
  }, [locations]);

  return (
    <div>
      {shouldDisplayScanner && (
        <>
          <QRScanner handleConfirmBarcode={handleConfirmBarcode} />
          {error && <div className={creationClasses.error}>{error}</div>}
          <Text variant="small">ou</Text>
        </>
      )}
      <div className={creationClasses.paddingDiv}>
        <FormControl fullWidth variant={'filled'}>
          <StyledAutocompleteInput
            options={locationList}
            placeholder="Nouvel emplacement"
            onChange={(location: Location) => handleChangeLocation(location.id)}
            onMenuOpen={() => setShouldDisplayScanner(false)}
            onMenuClose={() => setShouldDisplayScanner(true)}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default SelectLocation;
