import { createStyles } from '@material-ui/core/styles';

const RepairReportCheckboxStyle = createStyles({
  formContainer: {
    display: 'block',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    backgroundColor: '#FFFFFF',
    border: '1px solid #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 10,
    flex: 'none',
    alignSelf: 'flex-start',
    margin: '0px 24px 16px 24px',
    height: '56px',
    color: '#2B3B5C',
  },
  soloCheckboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    backgroundColor: '#FFFFFF',
    border: '1px solid #DBDFE8',
    boxSizing: 'border-box',
    borderRadius: 10,
    flex: 'none',
    alignSelf: 'flex-start',
    margin: '24px',
    height: '56px',
    color: '#2B3B5C',
  },
  checkedIcon: {
    color: '#4A55FE',
  },
  errorText: {
    margin: '0px 24px',
  },
});

export default RepairReportCheckboxStyle;
