import React from 'react';

import { MarginProps } from 'styled-system';

import ProductDetailField from 'pages/Store/components/ProductDetailField';

import Box from 'components/atoms/Box';

import { Location } from 'types/location';
import { Product } from 'types/products';
import { ShippingDelivery } from 'types/shipping-deliveries';

import { SHIPPING } from '../utils';

type ShippingProductProps = {
  product: Product;
  locations: Location[];
  delivery: ShippingDelivery;
  onClick: () => void;
} & MarginProps;

const ShippingProduct: React.FunctionComponent<ShippingProductProps> = ({
  product,
  locations,
  delivery,
  onClick = () => {},
}: ShippingProductProps) => {
  const location = locations?.find((location: Location) => location.id === product?.location);
  return (
    <Box mt={2}>
      {product && location?.usage !== SHIPPING && (
        <ProductDetailField
          product={product}
          removeable={false}
          onclick={onClick}
          location={location}
          associatedDelivery={delivery}
        />
      )}
    </Box>
  );
};
export default ShippingProduct;
