import imageCompression from 'browser-image-compression';

export async function compressImage(
  imageToCompress: File,
  compressedImageName: string,
  options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }
): Promise<File> {
  const compressedFileOrBlob = await imageCompression(imageToCompress, options);
  return new File([compressedFileOrBlob], compressedImageName, {
    type: 'image/png',
  });
}
