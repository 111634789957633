import React from 'react';

import { CircularProgress } from '@material-ui/core';

import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

import { Package } from 'types/package';

import { usePackages } from 'utils/api/api';

import PackageField from '../PackageField';

type PackageListProps = {
  filter?: Record<string, unknown>;
};

const PackageList: React.FunctionComponent<PackageListProps> = ({ filter }: PackageListProps) => {
  const { packages, loading, error } = usePackages(filter);

  return (
    <>
      {loading && (
        <Box mt={4} mx="auto">
          <Text textAlign="center" variant="subtitle">
            Récupération des colis
          </Text>
          <Box mx="auto" width="fit-content" py={2}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {error && (
        <Text textAlign="center" variant="body" color="red">
          Une erreur est survenue lors du chargement des colis
        </Text>
      )}
      {!loading && !error && (
        <>
          {packages.length > 0 && (
            <>
              <Box mt={1} overflow="scroll">
                {packages?.map((pack: Package) => (
                  <Box key={pack.id} m={2}>
                    <PackageField sparePartPackage={pack} />
                  </Box>
                ))}
              </Box>
              <Box width="80%" display="flex" justifyContent="space-between"></Box>
            </>
          )}
          {packages.length < 1 && (
            <Text textAlign="center" variant="body">
              Aucun colis
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default PackageList;
