import axios from 'axios';

export const cloudinaryUpload = async (
  key: string,
  file: File
): Promise<{ key: string; url: string }> => {
  // Deleted photos need an empty URL and not null

  if (file === null) {
    return { key, url: '' };
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_NAME as string);
  const { data } = await axios.post(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return { key, url: data.secure_url };
};
