import React from 'react';

import { BackEvent, SparePartsEvent } from '../../types';
import { BACK, SPARE_PARTS_ARE_NEEDED, SPARE_PARTS_ARE_NOT_NEEDED } from '../../types';
import SparePartsNeededChoice from './SparePartsNeededChoice';

interface SparePartsNeededChoiceContainerProps {
  send: (event: SparePartsEvent | BackEvent) => void;
  selectedValue: boolean | null;
}

const SparePartsNeededChoiceContainer: React.FunctionComponent<
  SparePartsNeededChoiceContainerProps
> = ({ send, selectedValue }: SparePartsNeededChoiceContainerProps) => {
  return (
    <SparePartsNeededChoice
      back={() => {
        send({
          type: BACK,
          data: {},
        });
      }}
      submit={(spare_parts_needed: boolean) => {
        spare_parts_needed
          ? send({
              type: SPARE_PARTS_ARE_NEEDED,
              data: {
                spare_parts_needed,
              },
            })
          : send({
              type: SPARE_PARTS_ARE_NOT_NEEDED,
              data: {
                spare_parts_needed,
              },
            });
      }}
      selectedValue={selectedValue}
    />
  );
};

export default SparePartsNeededChoiceContainer;
