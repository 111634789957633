import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ColorProps, MarginProps, color, margin } from 'styled-system';

const Link = styled(RouterLink)<MarginProps | ColorProps>(margin, color, () => ({
  backgroundColor: 'transparent',
  textAlign: 'center',
  display: 'inline-block',
}));

export default Link;
