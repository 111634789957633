// React imports
import React, { useState } from 'react';

// Icons
import { Button } from '@material-ui/core';
// Styles imports
import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';

import RepairReportTextArea from 'pages/RepairReport/components/formComponents/RepairReportTextArea';
// Component imports
import RepairReportValidateButton from 'pages/RepairReport/components/formComponents/RepairReportValidateButton';
import RepairReportNotPaidStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportNotPaidStyle';

const useStyles = makeStyles(RepairReportNotPaidStyle);

const RepairReportNotPaid: React.FunctionComponent = () => {
  const classes = useStyles();
  const [pictures, setPictures] = useState<Array<{ preview: string; raw: File }>>([]);
  const {
    input: { onChange: onNameplatePictureAddition },
  } = useField('payment.not_paid');

  const onDrop = (uploadedPictures: File[]) => {
    setPictures([
      ...pictures,
      { preview: URL.createObjectURL(uploadedPictures[0]), raw: uploadedPictures[0] },
    ]);
    onNameplatePictureAddition(uploadedPictures);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const continueSteps = () => {
    onNameplatePictureAddition(pictures);
  };

  return (
    <div>
      {
        <div className={classes.addNewMediaButtonContainer}>
          <RepairReportTextArea formProductIdReference={'payment'} textAreaLabel={`not_paid`} />
          <div>
            {pictures &&
              pictures.map((picture, index) => (
                <img
                  src={picture.preview}
                  className={classes.preview}
                  key={index}
                  alt="dummy"
                  width="148"
                  height="144"
                />
              ))}
          </div>
          <Button id="add-new-media" className={classes.addNewMediaButton}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {<p> Ajouter des photos</p>}
            </div>
          </Button>
          <RepairReportValidateButton
            buttonLabel={`Terminer`}
            buttonType={'paymentButton'}
            formProductIdReference={'payment'}
            validateFunction={() => continueSteps()}
            step="not_paid"
          />
        </div>
      }
    </div>
  );
};

export default RepairReportNotPaid;
