import { useQuery } from 'react-query';

import { DiagnosticDetails } from 'types/diagnostic-details';

import { get } from 'utils/api/http';

export const useDiagnosticDetails = (
  diagnosticId: number
): {
  diagnosticDetails: DiagnosticDetails;
  isLoading: boolean;
  isError: boolean;
} => {
  const emptyDiagnosticDetails = {
    diagnostic_id: 0,
    symptoms: [],
    defects: [],
    comment: { task: { comment: 'Détail de diagnostic vide' } },
  };
  const diagnosticDetailsQuery = `product_diagnostic?select=diagnostic_id:intervention_ptr_id,symptoms:product_symptom(iris_code_irissymptom(text),iris_code_iriscondition(text)),defects:product_sectiondefect(iris_code_irissection(text),iris_code_irisdefect(text)),comment:murfy_erp_intervention(task:murfy_erp_task(comment))&intervention_ptr_id=eq.${diagnosticId}`;
  const url = `/operations-data-provider/cobble-flow-read-api-proxy/${diagnosticDetailsQuery}`;
  const {
    data: diagnosticDetails,
    isLoading,
    isError,
  } = useQuery<[DiagnosticDetails], unknown, DiagnosticDetails>([url], () => get(url), {
    select: (responsePayload) =>
      responsePayload.length > 0 ? responsePayload[0] : emptyDiagnosticDetails,
  });
  return { diagnosticDetails: diagnosticDetails || emptyDiagnosticDetails, isLoading, isError };
};
