import { createStyles } from '@material-ui/core/styles';

const TransferPlayerStyle = createStyles({
  transferContainer: {
    backgroundColor: '#F1F0F5',
    height: '100vh',
    paddingTop: 24,
    boxSizing: 'border-box',
  },
});

export default TransferPlayerStyle;
