import React, { useEffect, useState } from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import * as yup from 'yup';
import { setLocale } from 'yup';

import { getProductTypes } from 'pages/ProductReception/utils/api';

import AutocompleteInput from 'components/atoms/AutocompleteInput';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import ProductReferenceAutocomplete from 'components/atoms/ProductReferenceAutocomplete';
import Text from 'components/atoms/Text';
import Select from 'components/molecules/Select';

import { ProductBrand } from 'types/product-brands';
import { ProductModel } from 'types/product-models';
import { ProductType } from 'types/productTypes';
import { Product } from 'types/products';

import { useProductBrands } from 'utils/api/api';

import { formatSelectFieldCompliantList } from './utils';

type FormValues = {
  reference: string;
  productBrand: string;
  productType: string;
  identificationPlate?: File | null;
};
type OptionType = { label: string; value: string };
type OptionsType = Array<OptionType>;
type ProductIdentificationProps = {
  handleSubmit: (
    reference: string,
    productType: string,
    productBrand: string,
    identificationPlate?: File | null
  ) => void;
  product: Product;
  error: string | undefined;
  validationLabel?: string;
  waitingLabel?: string;
  defaultProductModel?: ProductModel;
  identificationPlateUpdateEnabled?: boolean;
  enableBypass?: boolean;
  bypassCallback?: (identificationPlate?: File | null) => void;
  hideClientData?: boolean;
};

const StyledForm = styled(Form)((props) => ({
  background: props.theme.colors.grey200,
  textAlign: 'center',
  minHeight: '100%',
}));
const StyledImage = styled(Image)`
  width: 100%;
  border-radius: 0px;
`;

const ProductIdentification: React.FunctionComponent<ProductIdentificationProps> = ({
  handleSubmit,
  product,
  error,
  validationLabel = 'Valider',
  waitingLabel = 'Le chargement peut prendre quelques minutes',
  defaultProductModel,
  identificationPlateUpdateEnabled = false,
  enableBypass = false,
  bypassCallback,
  hideClientData = false,
}) => {
  setLocale({
    string: {
      min: 'La référence doit avoir au moins 3 caractères',
    },
  });

  const [productTypeList, setProductTypeList] = useState<ProductType[]>([]);
  const [productBrandList, setProductBrandList] = useState<ProductBrand[]>([]);

  const validationSchema = yup.object().shape({
    reference: yup.string().min(3).required('Référence requise'),
    identificationPlate:
      identificationPlateUpdateEnabled && !product?.identification_plate
        ? yup.mixed().required('Plaque signalétique requise')
        : yup.mixed().notRequired(),
  });

  const initialValues: FormValues = {
    reference:
      defaultProductModel?.reference || (!hideClientData && product?.model_reference) || '',
    productBrand:
      defaultProductModel?.brand?.id.toString() ||
      (!hideClientData &&
        productBrandList
          ?.find((item) => item.name === (product?.brand as string))
          ?.id.toString()) ||
      '',
    productType:
      defaultProductModel?.product_type?.id.toString() ||
      (!hideClientData &&
        productTypeList
          ?.find((item) => item.name === (product?.product_type as string))
          ?.id.toString()) ||
      '',
    identificationPlate: null,
  };

  const { productBrands } = useProductBrands();

  useEffect(() => {
    const FetchProductInfo = async () => {
      const productTypes = await getProductTypes();
      const formattedProductTypes = formatSelectFieldCompliantList(productTypes);
      setProductTypeList(formattedProductTypes);
      if (productBrands) {
        const formattedProductBrands = formatSelectFieldCompliantList(productBrands);
        setProductBrandList(formattedProductBrands);
      }
    };

    FetchProductInfo();
  }, [productBrands]);

  const handleValidateProductReference = async (values: FormValues) => {
    await handleSubmit(
      values.reference,
      values.productBrand,
      values.productType,
      values.identificationPlate
    );
  };

  const renderImage = (photoUrl: string) => {
    return (
      <>
        <Box overflow="auto" height={200} position="relative">
          <TransformWrapper>
            <TransformComponent>
              <StyledImage src={photoUrl} alt="preview" />
            </TransformComponent>
          </TransformWrapper>
        </Box>
      </>
    );
  };

  return (
    <>
      <Formik
        onSubmit={handleValidateProductReference}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors, isSubmitting, isValid }) => (
          <StyledForm>
            <Box mb={2} textAlign="left" mx={2} mt={2}>
              {identificationPlateUpdateEnabled && (
                <>
                  <Field>
                    {({ form }: FieldProps) => {
                      return (
                        <>
                          {(values.identificationPlate || product?.identification_plate) &&
                            renderImage(
                              values.identificationPlate
                                ? URL.createObjectURL(values.identificationPlate)
                                : product?.identification_plate
                            )}
                          <input
                            id="file-upload"
                            data-testid="nameplate-input"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                              form.setFieldValue(
                                'identificationPlate',
                                event?.currentTarget?.files && event.currentTarget.files[0]
                              );
                            }}
                          />
                          <Button variant="transparent" my={2} width="100%" type="button">
                            <label htmlFor="file-upload">Modifier la plaque signalétique</label>
                          </Button>
                        </>
                      );
                    }}
                  </Field>
                  <Text variant="small" color="red">
                    {errors?.identificationPlate}
                  </Text>
                </>
              )}
              <Text variant="body" p={1} data-testid="product-reference">
                Référence
              </Text>
              <ProductReferenceAutocomplete fieldName="reference" brandField="id" />
              <Box textAlign="left" width="100%" mt={2}>
                <Text variant="body" p={1}>
                  Marque
                </Text>
                <Field name="productBrand">
                  {({ field: { name, value }, form }: FieldProps) => {
                    return (
                      <AutocompleteInput
                        isClearable
                        isCreatableEnabled={false}
                        name={name}
                        value={
                          {
                            label:
                              productBrandList.find(
                                (item) => item.id.toString() === value?.toString()
                              )?.name || '',
                            value:
                              productBrandList.find(
                                (item) => item.id.toString() === value?.toString()
                              )?.id || '',
                          } as OptionType
                        }
                        options={
                          productBrandList
                            ? productBrandList.map((brand) => {
                                return { label: brand.name, value: brand.id.toString() };
                              })
                            : ([] as OptionsType)
                        }
                        onChange={(event: OptionType) => {
                          form.setFieldValue('productBrand', event?.value);
                        }}
                        placeholder="Marque"
                      />
                    );
                  }}
                </Field>
                <Text variant="small" color="red">
                  {errors?.productBrand}
                </Text>
              </Box>
              <Box textAlign="left" width="100%" mt={2}>
                <Text variant="body" p={1}>
                  Catégorie
                </Text>
                <Field name="productType">
                  {({ field: { name, value }, form }: FieldProps) => {
                    return (
                      <Select
                        items={productTypeList as any[]}
                        width="100%"
                        innerWidth="90%"
                        maxHeight={280}
                        value={value?.toString()}
                        name={name}
                        onChange={(selected: string) => {
                          form.setFieldValue('productType', selected.toString());
                        }}
                      />
                    );
                  }}
                </Field>
                <Text variant="small" color="red">
                  {errors?.productType}
                </Text>
              </Box>
              <Text variant="small" color="red">
                {errors?.reference}
              </Text>
            </Box>
            {enableBypass && (
              <Box mb={1}>
                <Button
                  variant="fullTransparent"
                  onClick={() => bypassCallback && bypassCallback(values.identificationPlate)}
                >
                  Impossible d&apos;identifier le produit
                </Button>
              </Box>
            )}
            <Box
              position={!identificationPlateUpdateEnabled ? 'absolute' : null}
              bottom={!identificationPlateUpdateEnabled ? 16 : null}
              width="100%"
            >
              <Box>
                <Text variant="small" color="red">
                  {error}
                </Text>
              </Box>
              {isSubmitting && (
                <Text variant="extraSmall" color="grey500">
                  {waitingLabel}
                </Text>
              )}
              <Button
                width="90%"
                disabled={isSubmitting || !isValid}
                data-testid="bottom-button"
                type="submit"
              >
                {isSubmitting ? 'Chargement en cours' : validationLabel}
              </Button>
            </Box>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default ProductIdentification;
