import React from 'react';

import ReactAdminApp from 'ReactAdminApp';
import ServiceWorkerController from 'ServiceWorkerController';
import { FeatureFlagsProvider } from 'featureFlagsContext';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ModalsProvider } from 'modalsContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider as SystemThemeProvider } from 'styled-components';

import './App.css';
import { useEmployee } from './employeeContext';
import systemTheme from './theme';
import { TourStatusProvider } from './tourStatusContext';

const App = (props) => {
  const { storeEmployee, clearEmployee } = useEmployee();
  const authProvider = props.authProviderFactory(storeEmployee, clearEmployee);
  const queryClient = new QueryClient();

  return (
    <SystemThemeProvider theme={systemTheme}>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagsProvider>
          <ModalsProvider>
            <TourStatusProvider>
              <ServiceWorkerController />
              <ReactAdminApp
                authProvider={authProvider}
                dataProvider={props.dataProvider}
                store={props.store}
                history={props.history}
              />
            </TourStatusProvider>
          </ModalsProvider>
        </FeatureFlagsProvider>
      </QueryClientProvider>
    </SystemThemeProvider>
  );
};

const AppWithFeatureFlag: React.FC = (props) => {
  const clientSideID = process.env.REACT_APP_CLIENT_SIDE_ID || '';
  const { employee } = useEmployee();
  const user = employee.id
    ? {
        key: employee.id,
        name: employee.user.first_name,
        email: employee.user.email,
      }
    : undefined;
  const Provider = withLDProvider({ clientSideID: clientSideID, user: user })(App);
  return <Provider {...props} />;
};
export default AppWithFeatureFlag;
