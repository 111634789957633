import React from 'react';

import NavigationIcon from '@material-ui/icons/Navigation';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useModals } from 'modalsContext';

import Box from 'components/atoms/Box';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

import { CustomerFile } from 'types/customer-files';

import { buildGoogleMapsURL } from 'utils/google-maps';
import { useLocalStorage } from 'utils/useLocalStorage';
import { buildWazeURL } from 'utils/waze';

type AddressPanelProps = {
  customerFile: CustomerFile;
};
type PreferredApp = 'map' | 'waze';

const AddressPanel: React.FunctionComponent<AddressPanelProps> = ({
  customerFile,
}: AddressPanelProps) => {
  const { showModal, closeModal } = useModals();
  const { preferredGps } = useFlags();
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const openAppNavigation = (preferredApplication: PreferredApp | null) => {
    if (preferredApplication === 'waze') return openInNewTab(buildWazeURL(customerFile));
    return openInNewTab(buildGoogleMapsURL(customerFile));
  };
  const [preferredApp, setPreferredApp] = useLocalStorage<PreferredApp | null>(
    'preferred_app',
    null
  );
  const handleSelect = (preferredApplication: PreferredApp) => {
    setPreferredApp(preferredApplication);
    openAppNavigation(preferredApplication);
  };
  interface Choice {
    name: 'MAPS' | 'WAZE';
    value: PreferredApp;
  }
  const choices: Choice[] = [
    { name: 'MAPS', value: 'map' },
    { name: 'WAZE', value: 'waze' },
  ];
  const AddressIcon = () => (
    <Box
      alignItems="center"
      bg="blue"
      borderRadius="50%"
      color="white"
      display="flex"
      fontSize={16}
      justifyContent="center"
      size={4}
    >
      <NavigationIcon color="inherit" fontSize="inherit" />
    </Box>
  );
  const showChoiceModal = () => {
    showModal({
      modalToShow: 'CHOICE_MODAL',
      modalProps: {
        title: 'Choisissez votre app ?',
        onCancel: closeModal,
        onSelect: handleSelect,
        choices: choices,
      },
    });
  };
  const isPreferenceSet = (): boolean => preferredApp !== null;
  const buildUrl = () => {
    if (!isPreferenceSet()) return showChoiceModal();
    openAppNavigation(preferredApp);
  };

  const renderCustomerAddress = () => {
    return (
      <Box ml={2}>
        <Text variant="small">{`${customerFile.street_number} ${customerFile.street}`}</Text>
        <Text variant="small">{`${customerFile.city}, ${customerFile.postal_code}`}</Text>
      </Box>
    );
  };

  return (
    <PaperStrip
      onClick={() => (preferredGps ? buildUrl() : openInNewTab(buildGoogleMapsURL(customerFile)))}
      display="flex"
    >
      {AddressIcon()}
      {renderCustomerAddress()}
    </PaperStrip>
  );
};

export default AddressPanel;
