import React, { useState } from 'react';

import { useEmployee } from 'employeeContext';

import PageHeader from 'components/PageHeader';
import ReconditionBottomBar from 'components/ReconditionBottomBar';
import Box from 'components/atoms/Box';
import Tab from 'components/molecules/Tab';
import PageLayout from 'components/templates/PageLayout';

import UpdateWorkshopInterface from './components/UpdateWorkshopInterface';
import WorkshopPackages from './components/WorkshopPackages';
import WorkshopSparePartStockList from './components/WorkshopSparePartList/WorkshopSparePartStockList';

const ReconditionSpareParts: React.FunctionComponent = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(1);
  const { employee } = useEmployee();

  return (
    <PageLayout>
      <PageHeader title="Pièces détachées" />
      {employee.workshop && (
        <>
          <Box backgroundColor="white" display="flex" justifyContent="space-between">
            <Box width="100%" textAlign="center">
              <Tab
                title="RECEPTION"
                active={activeTabIndex === 0}
                cardinality={0}
                handleClick={() => setActiveTabIndex(0)}
                width="100%"
                variant={'smallBold'}
              />
            </Box>
            <Box width="100%" textAlign="center">
              <Tab
                title="STOCK"
                active={activeTabIndex === 1}
                cardinality={0}
                handleClick={() => setActiveTabIndex(1)}
                width="100%"
                variant={'smallBold'}
              />
            </Box>
            <Box width="100%" textAlign="center">
              <Tab
                title="RETOUR"
                active={activeTabIndex === 2}
                cardinality={0}
                handleClick={() => setActiveTabIndex(2)}
                width="100%"
                variant={'smallBold'}
              />
            </Box>
          </Box>
          {activeTabIndex === 0 && <WorkshopPackages />}
          {activeTabIndex === 1 && <WorkshopSparePartStockList />}
        </>
      )}
      {!employee.workshop && <UpdateWorkshopInterface />}
      <ReconditionBottomBar />{' '}
    </PageLayout>
  );
};

export default ReconditionSpareParts;
