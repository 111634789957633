import React, { useState } from 'react';

import CachedIcon from '@material-ui/icons/Cached';

import PrintContent from 'pages/Store/components/PrintContent';

import PageLayout from 'components/templates/PageLayout';

import { CheckListElement } from 'types/check-list-elements';
import { FormSkuModel } from 'types/form-sku-models';
import { Product } from 'types/products';
import { SkuSupplierReference } from 'types/sku-supplier-references';

import { generateQrCode } from 'utils/QrCodeUtils';

import {
  ASWO,
  DONE,
  SPARE_PART_REGISTRATION_CHECKLIST,
  normalizeString,
} from '../RepairCycleUtils';
import SparePartThumbnailsList from '../SparePartThumbnailsList/SparePartsThumbnailsList';
import {
  postSparePartRegistrationReport,
  useDisassemblySkuModels,
  useSparePartCheckList,
} from '../api';

type SparePartRegistrationTaskProps = {
  handleClose: () => void;
  product: Product;
};

const SparePartRegistrationTask: React.FunctionComponent<SparePartRegistrationTaskProps> = ({
  product,
  handleClose,
}: SparePartRegistrationTaskProps) => {
  const { data: disassemblyEvents } = useDisassemblySkuModels(product.id);
  const skuChecks = useSparePartCheckList(SPARE_PART_REGISTRATION_CHECKLIST);
  const [error, setError] = useState<string>();
  const [code, setCode] = useState<string>();
  const [qrCode, setQrCode] = useState<string>();

  const initialValues = {
    sku_models:
      disassemblyEvents &&
      disassemblyEvents.map((disassemblyEvent) => {
        const aswoSupplierReference = disassemblyEvent.sku_model.supplier_references.find(
          (skuModelReference: SkuSupplierReference) => skuModelReference.supplier_name == ASWO
        ) || { price: 0, reference: '', supplier: null, supplier_name: '' };
        return {
          id: disassemblyEvent.id,
          thumbnail: disassemblyEvent.sku_model.thumbnail,
          quantity: 0,
          designation: disassemblyEvent.sku_model.designation,
          normalizedDesignation: normalizeString(disassemblyEvent.sku_model.designation),
          checks:
            skuChecks &&
            skuChecks.map((skuCheck: CheckListElement) => {
              return {
                id: skuCheck.id,
                text: skuCheck.text,
                is_checked: false,
              };
            }),
          supplierReference: aswoSupplierReference,
          skuCode: '',
        };
      }),
  };

  const handleSubmit = async () => {
    handleClose();
  };

  const openPrintQrCodeModal = (skuCode: string) => {
    setCode(skuCode);
    generateQrCode(skuCode, setQrCode);
  };

  const handleValidateSparePart = async (
    checksValues: {
      quantity: number;
      sku_model_test: { id: number; text: string; is_checked: boolean }[];
    },
    selectedSkuModel: FormSkuModel,
    values: {
      sku_models: FormSkuModel[];
    },
    arrayHelpers: {
      replace: (arg0: number, arg1: FormSkuModel) => void;
    }
  ) => {
    setError(undefined);
    const selectedSkuModelIndex = values['sku_models'].findIndex(
      (skuModel) => skuModel.id === selectedSkuModel.id
    );
    try {
      const createdSkuId = await postSparePartRegistrationReport({
        disassembly_sku_model: values['sku_models'][selectedSkuModelIndex].id,
        state: DONE,
        checklist: SPARE_PART_REGISTRATION_CHECKLIST,
        items: values['sku_models'][selectedSkuModelIndex].checks,
      });
      if (
        selectedSkuModelIndex > -1 &&
        values['sku_models'][selectedSkuModelIndex].quantity === 0
      ) {
        const selectedSkuModel = values['sku_models'][selectedSkuModelIndex];
        const skuCode = `${values['sku_models'][selectedSkuModelIndex].supplierReference.supplier_name}_${values['sku_models'][selectedSkuModelIndex].supplierReference.reference}/${createdSkuId}`;
        arrayHelpers.replace(selectedSkuModelIndex, {
          quantity: 1,
          id: selectedSkuModel.id,
          designation: selectedSkuModel.designation,
          thumbnail: selectedSkuModel.thumbnail,
          checks: checksValues.sku_model_test,
          normalizedDesignation: selectedSkuModel.normalizedDesignation,
          supplierReference: selectedSkuModel.supplierReference,
          skuCode: skuCode,
        });
        openPrintQrCodeModal(skuCode);
      }
    } catch (e) {
      setError(`Une erreur est survenue lors de sauvegarde de la tâche: \n ${error}`);
    }
  };

  return (
    <PageLayout>
      {disassemblyEvents && (
        <SparePartThumbnailsList
          allChecksNeeded
          product={product}
          goBack={handleClose}
          handleClose={handleClose}
          initialValues={initialValues}
          title="Enregistrement"
          handleSubmit={handleSubmit}
          error={undefined}
          handleValidateSparePart={handleValidateSparePart}
          openPrintQrCodeModal={openPrintQrCodeModal}
        />
      )}
      <PrintContent
        open={!!code}
        onClose={() => {
          setCode(undefined);
        }}
        codeSubtitle={code}
        handleNextStep={() => setCode(undefined)}
        qrCode={qrCode}
        headingText="OCCASION"
        LeftIcon={CachedIcon}
        RightIcon={CachedIcon}
      />
    </PageLayout>
  );
};

export default SparePartRegistrationTask;
