import styled from 'styled-components';
import {
  BorderProps,
  ColorProps,
  DisplayProps,
  FlexboxProps,
  SpaceProps,
  TypographyProps,
  border,
  color,
  display,
  flexbox,
  space,
  typography,
  variant,
} from 'styled-system';

export const TextVariants = {
  title1: {
    fontSize: 5,
    fontWeight: 'bold',
  },
  title2: {
    fontSize: 5,
    fontWeight: 'medium',
  },
  subtitle: {
    fontSize: 4,
    fontWeight: 'medium',
  },
  subtitleBold: {
    fontSize: 4,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 3,
    fontWeight: 'regular',
  },
  bodyBold: {
    fontSize: 3,
    fontWeight: 'bold',
  },
  small: {
    fontSize: 2,
    fontWeight: 'regular',
  },
  smallBold: {
    fontSize: 2,
    fontWeight: 'bold',
  },
  extraSmall: {
    fontSize: 1,
    fontWeight: 'regular',
  },
  extraSmallBold: {
    fontSize: 1,
    fontWeight: 'bold',
  },
  button: {
    fontSize: 1,
    fontWeight: 'medium',
  },
  buttonSmall: {
    fontSize: 0,
    fontWeight: 'medium',
  },
  caption: {
    fontSize: 0,
    fontWeight: 'regular',
  },
};

export type TextProps = {
  variant: keyof typeof TextVariants;
} & SpaceProps &
  ColorProps &
  DisplayProps &
  TypographyProps &
  BorderProps &
  FlexboxProps;

const Text = styled('div')<TextProps>(
  space,
  typography,
  border,
  color,
  display,
  flexbox,
  {
    fontFamily: 'GT-Walsheim',
  },
  variant({
    variants: TextVariants,
  })
);

Text.defaultProps = {
  color: 'dark',
};
export default Text;
