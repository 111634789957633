import * as React from 'react';

import { Field, FieldProps, Formik } from 'formik';

import PortalComponent from 'components/Portal/PortalComponent';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import QuantitySelector from 'components/molecules/QuantitySelector';
import SkuModelInfos from 'components/organisms/SkuModelInfos/SkuModelInfos';

import { FormSkuModel } from 'types/form-sku-models';

import RepairProductChecklist from '../RepairCycleRepairReport/RepairProductChecklist/RepairProductChecklist';
import {
  disassemblyValidationSchema,
  sparePartRegisteringValidationSchema,
} from './SparePartDetailsTestUtils';

type SparePartDetailsTestProps = {
  skuModel: FormSkuModel;
  allChecksNeeded?: boolean;
  handleSubmit: (values: {
    quantity: number;
    sku_model_test: { id: number; text: string; is_checked: boolean }[];
  }) => void;
  handleClose: () => void;
  showQuantitySelector: boolean;
};

const SparePartDetailsTest: React.FunctionComponent<SparePartDetailsTestProps> = ({
  skuModel,
  handleSubmit,
  handleClose,
  allChecksNeeded = false,
  showQuantitySelector,
}: SparePartDetailsTestProps) => {
  const handleSubmitForm = (values: {
    quantity: number;
    sku_model_test: { id: number; text: string; is_checked: boolean }[];
  }) => {
    handleSubmit(values);
    handleClose();
  };

  const initialValues = {
    quantity: skuModel.quantity ? skuModel.quantity : 1,
    sku_model_test: skuModel.checks,
  };

  return (
    <PortalComponent open={true} onClose={handleClose} title="À enregistrer">
      <Box overflow="auto" pb={9}>
        <Formik
          onSubmit={handleSubmitForm}
          initialValues={initialValues}
          validationSchema={
            allChecksNeeded ? sparePartRegisteringValidationSchema : disassemblyValidationSchema
          }
          validateOnMount
          enableReinitialize
        >
          {({ values, isValid }) => (
            <Box>
              <SkuModelInfos detailedView skuModel={skuModel} handleOnClick={() => {}} />
              {showQuantitySelector && (
                <Field data-testid="quantity" name={'quantity'}>
                  {({ field: { name, value }, form }: FieldProps) => {
                    return (
                      <QuantitySelector
                        value={value}
                        handleCustomInput={(value: number) => {
                          form.setFieldValue(name, value);
                        }}
                      />
                    );
                  }}
                </Field>
              )}

              <RepairProductChecklist values={values} checklistType={'sku_model_test'} />

              <BottomButton disabled={!isValid} onClick={() => handleSubmitForm(values)}>
                Pièce(s) fonctionnelle(s) récupérée(s)
              </BottomButton>
            </Box>
          )}
        </Formik>
      </Box>
    </PortalComponent>
  );
};

export default SparePartDetailsTest;
