import { createStyles } from '@material-ui/core/styles';

const ReportStepPlayerStyle = createStyles({
  headerContent: {
    display: 'flex',
    padding: '0px 24px',
    height: 54,
  },
  reportContainer: {
    backgroundColor: '#F1F0F5',
    height: '100vh',
  },
  header: {
    position: 'relative',
  },
  playerHeader: {
    backgroundColor: '#ffffff',
  },
  stepName: {
    color: '#2B3B5C',
    fontWeight: 'bold',
    fontSize: 15,
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
  },
  arrowBack: {
    color: '#8795B3',
  },
  snackbarSubmission: {
    textAlign: 'center',
    display: 'block',
  },
});

export default ReportStepPlayerStyle;
