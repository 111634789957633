import * as React from 'react';

import { Field, FieldProps } from 'formik';

import ReactSignatureCanvas from 'utils/react-signature-pad';

const SignatureInput: React.FunctionComponent = () => {
  return (
    <Field name="signature">
      {({ field: { name }, form }: FieldProps) => {
        return (
          <ReactSignatureCanvas
            bg="white"
            width={window.innerWidth - 48}
            height={230}
            value={form.values.signature}
            onChange={(value) => form.setFieldValue(name, value)}
          />
        );
      }}
    </Field>
  );
};

export default SignatureInput;
