import React from 'react';

import animationData from 'assets/check.json';
import Lottie from 'react-lottie';

import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ModalContainer from 'components/organisms/modals/ModalContainer';
import ContentModal from 'components/organisms/modals/ModalContent';

export type SuccessModalProps = {
  title: string;
  content: string | string[];
  onConfirm: () => void;
  confirmLabel?: string;
  fullScreen?: boolean;
  animation?: boolean;
};

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const SuccessModal: React.FunctionComponent<SuccessModalProps> = ({
  content,
  title,
  onConfirm,
  confirmLabel = 'Confirmer',
  fullScreen = false,
  animation = false,
}: SuccessModalProps) => {
  return (
    <ModalContainer fullScreen={fullScreen}>
      <Box margin="auto" marginTop={fullScreen ? '50%' : 0}>
        <Text variant="bodyBold" mb={3}>
          {title}
        </Text>
        {animation && <Lottie options={animationOptions} height={130} width={130} />}
        <ContentModal content={content} />
        {fullScreen ? (
          <BottomButton variant="primary" onClick={onConfirm} minWidth={192}>
            {confirmLabel}
          </BottomButton>
        ) : (
          <Button variant="primary" onClick={onConfirm} minWidth={192}>
            {confirmLabel}
          </Button>
        )}
      </Box>
    </ModalContainer>
  );
};

export default SuccessModal;
