import QRCode from 'qrcode';

export function generateQrCode(toQrCode, callback) {
  const opts = {
    type: 'image/png',
    color: {
      dark: '#000',
      light: '#fff',
    },
    version: 3,
  };
  QRCode.toDataURL(toQrCode, opts)
    .then((url) => {
      callback(url);
    })
    .catch((err) => {
      console.error(err);
    });
}
