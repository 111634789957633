import React from 'react';

import Loader from 'components/Loader';
import VisitList from 'components/VisitList';
import DailyInterventionsCounter from 'components/molecules/DailyInterventionsCounter';

import { useSevenOpteamVisitList } from 'utils/api/hooks';

interface SevenOpteamRepairmanCalendarProps {
  repairmanId: number;
  selectedDate: string;
}

const SevenOpteamRepairmanVisitList: React.FunctionComponent<SevenOpteamRepairmanCalendarProps> = ({
  repairmanId,
  selectedDate,
}: SevenOpteamRepairmanCalendarProps) => {
  const { visitList, loadingVisits } = useSevenOpteamVisitList(repairmanId, selectedDate);
  return !loadingVisits ? (
    <>
      <DailyInterventionsCounter date={selectedDate} visitCount={visitList.length} />
      <VisitList visitList={visitList} />
    </>
  ) : (
    <Loader />
  );
};

export default SevenOpteamRepairmanVisitList;
