import React from 'react';

import TextWithBackground from 'components/atoms/TextWithBackground';

const StatusLabelStyles: any = {
  grey: {
    color: '#858585',
    backgroundColor: 'rgba(133, 133, 133, 0.15)',
  },
  purple: {
    color: '#CB77FE',
    backgroundColor: 'rgba(203, 119, 254, 0.15)',
  },
  dark_blue: {
    color: '#4A55FE',
    backgroundColor: 'rgba(74, 85, 254, 0.15)',
  },
  orange: {
    color: '#FEA877',
    backgroundColor: 'rgba(254, 168, 119, 0.15)',
  },
  yellow: {
    color: '#F0BB00',
    backgroundColor: 'rgba(240, 187, 0, 0.15)',
  },
  green: {
    color: '#81D89F',
    backgroundColor: 'rgba(129, 216, 159, 0.15)',
  },
  red: {
    color: '#F85B5B',
    backgroundColor: 'rgba(248, 91, 91, 0.15)',
  },
  dark: {
    color: '#FFFFFF',
    background: '#2B3B5C',
  },
  brown: {
    color: '#D69F4E',
    backgroundColor: 'rgba(214, 159, 78, 0.15)',
  },
};

const colorFromStatus = (status: string) => {
  switch (status) {
    case 'installed':
    case 'closed':
      return 'grey';

    case 'ordered':
    case 'waiting_for_sku':
      return 'purple';

    case 'received_warehouse':
    case 'instock':
    case 'reserved':
    case 'emitted':
      return 'dark_blue';

    case 'waiting_for_packing':
      return 'orange';

    case 'being_prepared':
    case 'packed':
      return 'yellow';

    case 'received_tech':
    case 'affected':
      return 'green';

    case 'lost':
    case 'not_found':
    case 'deployment_adjustment':
    case 'lossè_declared':
      return 'dark';

    case 'received_wrong_tech':
    case 'canceled':
      return 'red';

    case 'return_to_warehouse':
      return 'brown';

    default:
      return 'grey';
  }
};

const parseStatus = (status: string) => {
  switch (status) {
    case 'waiting_for_packing':
      return 'En attente de colisage';
    case 'being_prepared':
      return 'En préparation';
    case 'packed':
      return 'Colisé';
    case 'ordered':
      return 'Commandé';
    case 'received_warehouse':
      return 'Reçu Atelier';
    case 'instock':
      return 'En Stock';
    case 'received_tech':
      return 'Reçu Technicien';
    case 'received_wrong_tech':
      return 'Reçu Mauvais Technicien';
    case 'installed':
      return 'Posé';
    case 'lost':
      return 'Perdu';
    case 'reserved':
      return 'Réservé';
    case 'return_to_warehouse':
      return 'A retourner à l atelier';
    case 'not_found':
      return 'Pièce non trouvée';
    case 'deployment_adjustment':
      return 'Ajustement déploiement';
    case 'emitted':
      return 'Émise';
    case 'waiting_for_sku':
      return 'En attente de pièces';
    case 'loss_declared':
      return 'Perte déclarée';
    case 'affected':
      return 'Affecté';
    case 'closed':
      return 'Fermé';
    case 'canceled':
      return 'Annulé';
    default:
      return '';
  }
};

type FeatureFlagsProviderProps = {
  status: string;
};

const StatusField: React.FunctionComponent<FeatureFlagsProviderProps> = ({
  status,
}: FeatureFlagsProviderProps) => {
  return (
    <TextWithBackground
      color={StatusLabelStyles[colorFromStatus(status)].color}
      background={StatusLabelStyles[colorFromStatus(status)].backgroundColor}
      fontSize={14}
    >
      {parseStatus(status)}
    </TextWithBackground>
  );
};

export default StatusField;
