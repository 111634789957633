import styled from 'styled-components';
import { LayoutProps, layout } from 'styled-system';

const VerticalConnector = styled.div<LayoutProps>(layout, {
  borderLeft: '1px solid #ABABAB',
  width: 1,
  margin: 'auto',
});

export default VerticalConnector;
