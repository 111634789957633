import React from 'react';

import { MarginProps } from 'styled-system';

import Tag from 'components/atoms/Tag';

import { VisitType } from 'types/visits';

type VisitTagProps = {
  visitType: VisitType;
  isLong: boolean;
} & MarginProps;

const VisitTag: React.FunctionComponent<VisitTagProps> = ({
  visitType,
  isLong,
  ...rest
}: VisitTagProps) => {
  const renderTag = () => {
    switch (visitType) {
      case 'previ':
        return <Tag {...rest} text="Prévi" variant="success" />;
      case 'reopening':
        return <Tag {...rest} text="Réouverture" />;
      case 'sp_installation':
        return <Tag {...rest} variant="warning" text="Pose pièce" />;
      case 'first_visit':
        return <Tag {...rest} variant="success" text="Premier passage" />;
      case 'reopening_sp_installation':
        return <Tag variant="warning" {...rest} text="Réouverture et pose pièce" />;
      default:
        return null;
    }
  };

  return (
    <>
      {isLong ? <Tag {...rest} text="Long" /> : null}
      {renderTag()}
    </>
  );
};

export default VisitTag;
