import React from 'react';

import { OffCanvasContentProps } from './OffCanvas';

const OffCanvasBody: React.FunctionComponent<OffCanvasContentProps> = ({
  width = 250,
  transitionDuration = 250,
  isMenuOpened = false,
  position = 'left',
  effect = 'push',
  children,
}: OffCanvasContentProps) => {
  // closed state style
  const translateX = position === 'left' ? 0 : 0;
  const closedStyle = {
    transitionDuration: transitionDuration + 'ms',
    transform: 'translate(' + translateX + 'px, 0px)',
  };

  // open state style
  let translateOpenX = position === 'left' ? width : -1 * width;
  translateOpenX = effect === 'parallax' ? translateOpenX / 2 : translateOpenX;
  translateOpenX = effect === 'overlay' ? 0 : translateOpenX;

  const openStyle = {
    transform: 'translate(' + translateOpenX + 'px, 0px)',
  };

  // create current state styles
  let currStyle = Object.assign({}, closedStyle);
  if (isMenuOpened) {
    currStyle = Object.assign({}, currStyle, openStyle);
  }

  return <div style={{ ...currStyle }}>{children}</div>;
};

export default OffCanvasBody;
