import React, { useEffect, useState } from 'react';

import { useRefresh } from 'react-admin';

import { CircularProgress, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import animationData from 'assets/check.json';
import dataProvider from 'dataProvider';
// import lottie animations
import Lottie from 'react-lottie';
import styled from 'styled-components';

import SparePartDefectsDeclaration from 'pages/SparePartDefectsDeclaration';
import SkuList from 'pages/Store/components/SkuList';
import StoreListFilter from 'pages/Store/components/StoreReceptionFilter';

import CreateNewSku from 'components/CreateNewSku/CreateNewSku';
import DefectsField from 'components/DefectsField/DefectsField';
import PortalComponent from 'components/Portal/PortalComponent';
import SkuDetailCard from 'components/SkuDetailCard';
import SkuDetailCarousel from 'components/SkuDetailCarousel';
import StatusField from 'components/StatusField/StatusField';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import FloatingActionButton from 'components/atoms/FloatingActionButton';
import SecondHandIcon from 'components/atoms/SecondHandIcon';
import Text from 'components/atoms/Text';

import { SkuLocation } from 'types/sku-location';
import { SkuModel } from 'types/sku-model';
// Types
import { SparePart } from 'types/spare-part';

import QrScanner from 'utils/QrScanner';
import { getSparePartIdFromBarcode } from 'utils/spareParts';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const TextButton = styled.div((props) => ({
  textDecoration: 'underline',
  color: props.theme.colors.grey900,
  fontSize: props.theme.fontSizes[2],
  marginTop: props.theme.space[2],
  marginBottom: props.theme.space[2],
}));

const StyledTextField = styled(TextField)((props) => ({
  background: props.theme.colors.white,
  width: 312,
  height: props.theme.sizes[7],
  borderRadius: props.theme.sizes[1],
  label: {
    color: props.theme.colors.grey900,
    fontSize: props.theme.fontSizes[2],
  },
}));

const BottomButton = styled(Button)((props) => ({
  marginBottom: props.theme.space[3],
  width: '100%',
  alignSelf: 'self-end',
}));

const ConfirmationContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  height: 'calc(100% - 57px)',
  padding: `0 ${props.theme.space[3]}px`,
  boxSizing: 'border-box',
  overflow: 'auto',
}));

const StyledImg = styled.img({
  maxHeight: 130,
  maxWidth: 130,
  height: 'auto',
  width: 'auto',
});

export type SparePartSelectionPortalProps = {
  onConfirm: (sku: SparePart) => void;
  onClose: () => void;
  open: boolean;
  filter?: Record<string, unknown>;
  isCreationAllowed?: boolean;
  location?: SkuLocation;
  skuCreationStatus?: string;
  selectedIds?: number[];
  handleClickOnManualSelection?: () => void;
  locationToDisplay?: string;
  sku: SparePart | undefined;
  setSku: (sku: SparePart | undefined) => void;
  getFromUniqueId?: boolean;
};

const SparePartSelectionPortal: React.FunctionComponent<SparePartSelectionPortalProps> = ({
  onConfirm,
  onClose,
  open,
  filter,
  isCreationAllowed,
  location,
  skuCreationStatus,
  selectedIds,
  getFromUniqueId,
  handleClickOnManualSelection,
  locationToDisplay,
  sku,
  setSku,
}: SparePartSelectionPortalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [isCreationOpen, setIsCreationOpen] = useState(false);
  const [error, setError] = useState('');
  const [filterText, setFilterText] = useState('');
  const [locationCodes, setLocationCodes] = useState<string[]>([] as string[]);
  const [uniqueId, setUniqueId] = useState('');
  const [manualStep, setManualStep] = useState(getFromUniqueId ? 1 : 2);
  const refresh = useRefresh();

  const [isSparePartDefectOpen, setIsSparePartDefectOpen] = useState<boolean>(false);

  /**
   * tries to get spare part in database based on encoded text
   * if there is no spare part in db with this unique id, tries to get a part from
   * the encoded id (second part of unique id) it will return a spare part by its
   * id only if the spare part has no unique id associated to it
   * else it returns an error
   * @param text text encoded in qr code
   */
  const handleConfirmBarcode = (text: string) => {
    setIsLoading(true);
    dataProvider.getOne('sku', { unique_id: text }).then((response) => {
      if (response.data && response.data.length > 0) {
        setSku(response.data[0]);
        setIsLoading(false);
      } else {
        const sparePartId = getSparePartIdFromBarcode(text);
        if (sparePartId) {
          dataProvider
            .getOne('sku', { id: sparePartId })
            .then((response) => {
              if (response.data.unique_id !== null) {
                setError('Oops, veuillez sélectionner cette pièce manuellement');
              } else {
                setSku(response.data);
              }
            })
            .catch(() => {
              setError('Erreur sku : ' + text);
            })
            .finally(() => setIsLoading(false));
        } else {
          setError('Erreur sku : ' + text);
          setIsLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (!isSparePartDefectOpen && sku) {
      dataProvider
        .getOne('sku', { id: sku.id })
        .then((response) => {
          if (response.data.unique_id !== null) {
            setError('Oops, veuillez sélectionner cette pièce manuellement');
          } else {
            setSku(response.data);
          }
        })
        .catch((e) => {
          setError('Erreur au scan de la pièce:' + e);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isSparePartDefectOpen]);

  useEffect(() => {
    if (locationToDisplay && sku?.sku_model) {
      dataProvider
        .getList('sku-location', { filter: { sku: sku?.sku, location_type: locationToDisplay } })
        .then(({ data }) => setLocationCodes(data.map((location: SkuLocation) => location?.code)));
    }
  }, [locationToDisplay, sku]);

  const handleCreateSku = (model: SkuModel) => {
    dataProvider
      .create('sku', {
        data: {
          sku_model: model.id,
          status: skuCreationStatus,
          location_id: location?.id,
        },
      })
      .then(({ data }) => {
        refresh();
        confirmSku(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const confirmSku = (selectedSku: SparePart | undefined) => {
    const skuToRightType = selectedSku ? (selectedSku as SparePart) : (sku as SparePart);
    onConfirm(skuToRightType);
    handleClose();
  };

  const handleClose = () => {
    setSku(undefined);
    setIsLoading(false);
    setError('');
    onClose();
    setIsManual(false);
    setLocationCodes([]);
  };

  const openManualInput = () => {
    setIsManual(true);
  };

  const openSkuCreation = () => {
    setIsCreationOpen(true);
  };

  const handleCloseCreateNewSkuModal = () => {
    setIsCreationOpen(false);
  };

  const onTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target ? event.target.value : '');
  };

  const getSkuFromUniqueId = (uniqueId: string) => {
    setIsLoading(true);
    dataProvider.getOne('sku', { unique_id: uniqueId }).then((response) => {
      if (response.data && response.data.length > 0) {
        setSku(response.data[0]);
        setIsLoading(false);
      } else {
        setError('Pièce non trouvée');
      }
    });
  };

  return (
    <PortalComponent open={open} onClose={handleClose} title="Identifier une pièce">
      {sku ? (
        <ConfirmationContainer>
          <Box mt={2}>
            {sku.thumbnail ? (
              <StyledImg src={sku.thumbnail} />
            ) : (
              <Lottie options={animationOptions} height={130} width={130} />
            )}
          </Box>
          <div>
            <Text variant="title1" mt={2} display="inline-flex">
              {sku.sku_model?.sku_reference}
              {sku.is_second_hand && (
                <Box ml={1}>
                  <SecondHandIcon />
                </Box>
              )}
            </Text>
            <Text color="grey900" mt={1} variant="caption">
              {sku.unique_id}
            </Text>
            <Text variant="body" mt={1}>
              {sku.sku_model?.designation}
            </Text>
          </div>
          <div>
            <StatusField status={sku.status} />
          </div>
          <div>
            {locationCodes.length > 0 ? (
              <SkuDetailCarousel sku={sku} locationCodes={locationCodes} />
            ) : (
              <>{(sku.location || sku.demand) && <SkuDetailCard sku={sku} />}</>
            )}
          </div>
          {sku.is_defective && (
            <Box mt={1}>
              <DefectsField defects={sku.defects} />
            </Box>
          )}

          <Button onClick={() => setIsSparePartDefectOpen(true)} variant="redTransparent">
            Déclarer un défaut sur la pièce
          </Button>
          {isSparePartDefectOpen && (
            <SparePartDefectsDeclaration
              defaultSparePart={sku}
              handleClose={() => setIsSparePartDefectOpen(false)}
            />
          )}
          <TextButton
            onClick={() => {
              setSku(undefined);
              setLocationCodes([]);
            }}
          >
            Modifier la sélection
          </TextButton>
          <BottomButton variant="primary" onClick={() => confirmSku(sku)} minWidth={192}>
            Confirmer
          </BottomButton>
        </ConfirmationContainer>
      ) : (
        <>
          {isManual ? (
            manualStep === 1 ? (
              <>
                <Box px={4} pt={8} height="100%" display="grid">
                  <div>
                    <Text variant="bodyBold" mb={2} display="flex">
                      Indiquez l&apos;id de la pièce
                    </Text>
                    <StyledTextField
                      variant="outlined"
                      margin="none"
                      label="Ex: ASWO_H2345/123"
                      name="uniqueId"
                      value={uniqueId}
                      onChange={(event) => setUniqueId(event.target.value)}
                    />
                    <Text variant="body" color="red" my={2}>
                      {error}
                    </Text>
                    <TextButton onClick={() => setManualStep(2)}>
                      Ajouter manuellement/ ID illisible
                    </TextButton>
                  </div>
                  <BottomButton
                    variant="primary"
                    onClick={() => getSkuFromUniqueId(uniqueId)}
                    minWidth={192}
                  >
                    Confirmer
                  </BottomButton>
                </Box>
              </>
            ) : (
              <>
                <SkuList
                  filters={<StoreListFilter onTextInput={onTextInput} />}
                  filter={filter}
                  handleSkuSelection={(selectedSku: SparePart) => setSku(selectedSku)}
                  isModalOpen={isManual}
                  mt={2}
                  showLocation
                  alreadySelectedIds={selectedIds}
                />
                {isCreationAllowed && (
                  <FloatingActionButton page={1} variant="primary" onClick={openSkuCreation}>
                    <AddIcon data-testid="add-icon" />
                  </FloatingActionButton>
                )}
                {isCreationOpen && (
                  <CreateNewSku
                    open={isCreationOpen}
                    onClose={handleCloseCreateNewSkuModal}
                    handleCreateSku={handleCreateSku}
                    filterText={filterText}
                  />
                )}
              </>
            )
          ) : (
            <Box>
              <QrScanner handleConfirmBarcode={handleConfirmBarcode} />
              <Text variant="body" color="red">
                {error}
              </Text>
              <Box height={50} mt={50}>
                {isLoading && <CircularProgress />}
              </Box>
              <TextButton onClick={handleClickOnManualSelection || openManualInput}>
                Identifier manuellement
              </TextButton>
            </Box>
          )}
        </>
      )}
    </PortalComponent>
  );
};

export default SparePartSelectionPortal;
