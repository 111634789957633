import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';

import EmployeeStatistics from 'pages/EmployeeStatistics';
import { formatRawIncentives } from 'pages/IncentiveSystem/Services/utils';

import { Repairman } from 'types/employees';
import { Incentive } from 'types/incentives';

import { IncentiveSystemDetailsList } from './Components/IncentiveSystemDetailsList';
import { IncentiveSystemUpdateList } from './Components/IncentiveSystemUpdateList';
import IncentiveSystemStyles from './styles/incentiveSystemStyles';

const useStyles = makeStyles(IncentiveSystemStyles);

type IncentiveSystemContentProps = {
  repairman: Repairman;
  indexTab: number;
  onSwipe: (index: number) => void;
  rawIncentives: Incentive[];
};
const IncentiveSystemContent: React.FunctionComponent<IncentiveSystemContentProps> = ({
  repairman,
  indexTab,
  onSwipe,
  rawIncentives,
}: IncentiveSystemContentProps) => {
  const classes = useStyles();

  const incentives = formatRawIncentives(rawIncentives);
  return (
    <div className={classes.IncentiveSystemContainerActve}>
      <SwipeableViews axis="x" index={indexTab} onChangeIndex={onSwipe}>
        {indexTab === 0 && <EmployeeStatistics repairman={repairman} />}

        {indexTab === 1 && <IncentiveSystemDetailsList repairmanId={repairman.id} />}
        {indexTab === 2 && (
          <IncentiveSystemUpdateList
            dataIncentiveListSliceCreatedAt={incentives.withDateFormatted}
            repairmanId={repairman.id}
          />
        )}
      </SwipeableViews>
    </div>
  );
};

export default IncentiveSystemContent;
