import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const Image = styled.img((props) => ({
  display: 'inline-block',
  width: props.width ? props.width : 328,
  height: props.height ? props.height : 328,
  borderRadius: themeGet('radii.5')(props),
}));

export default Image;
