import React, { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';

import ClaimComponent from 'pages/IncentiveSystem/Claims/Claims';
import { LabelIncentiveSystemField } from 'pages/IncentiveSystem/Components/LabelIncentiveSystemField';
import { StyledTypography } from 'pages/IncentiveSystem/ComponentsFields/StyledTypography';
import {
  getLastIncentive,
  groupBy,
  groupByProduct,
  incentiveListSliceCreatedAt,
  renderDate,
} from 'pages/IncentiveSystem/Services/utils';
import IncentiveSystemStyles from 'pages/IncentiveSystem/styles/incentiveSystemStyles';

import { compareDate } from 'utils/dates';

const styleList = makeStyles(IncentiveSystemStyles);

export default function IncentiveSystemDetails(props) {
  const classes = styleList();

  const incentiveValues = props.data && Object.values(props.data);
  const dataExcludeRequal =
    incentiveValues &&
    incentiveValues.length > 0 &&
    incentiveValues.filter((incentive) => incentive.point_type !== 'label_requalification');
  const dataExcludeCRUseless =
    dataExcludeRequal &&
    dataExcludeRequal.length > 0 &&
    dataExcludeRequal.filter((incentive) => incentive.label !== 'CR-useless');
  const dataGroupByVisit =
    dataExcludeCRUseless &&
    dataExcludeCRUseless.length > 0 &&
    groupBy(dataExcludeCRUseless, 'visit');
  const dataGroupByProduct = dataGroupByVisit && groupByProduct(dataGroupByVisit);
  const incentiveWithLastUpdate = dataGroupByProduct && getLastIncentive(dataGroupByProduct);

  const dataFilter = [];
  props.ids &&
    props.ids.map((id) => {
      incentiveWithLastUpdate &&
        incentiveWithLastUpdate.map((incentive) => {
          if (id === incentive.id) {
            dataFilter.push(incentive);
          }
          return dataFilter;
        });
      return dataFilter;
    });
  const dataIncentiveListSliceDate =
    dataFilter && dataFilter.length > 0 && incentiveListSliceCreatedAt(Object.values(dataFilter));
  const dataGroupByDate =
    dataIncentiveListSliceDate && groupBy(dataIncentiveListSliceDate, 'created_at');
  const listDate = dataGroupByDate && Object.keys(dataGroupByDate);
  const [itemToOpenMadol, setItemToOpenMadol] = useState('');

  const handleClose = () => {
    setItemToOpenMadol('');
  };

  return (
    <>
      {listDate &&
        listDate.length > 0 &&
        listDate.sort(compareDate).map((createdAt, index) => (
          <div key={index}>
            <ListSubheader className={classes.detailTitle} key={index}>
              {renderDate(createdAt)}
            </ListSubheader>
            {dataGroupByDate[createdAt].map((incentive, indexId) => (
              <>
                <ListItem
                  key={indexId}
                  className={classes.IncentiveSystemListItemDetails}
                  onClick={() => setItemToOpenMadol(incentive)}
                >
                  <ListItemIcon className={classes.lastnameStyle} key={indexId}>
                    <StyledTypography>{incentive.lastname}</StyledTypography>
                  </ListItemIcon>
                  <ListItemText className={classes.labelStyle}>
                    <StyledTypography>
                      {LabelIncentiveSystemField(incentive.label)}
                    </StyledTypography>
                  </ListItemText>
                  <ListItemSecondaryAction className={classes.IncentiveSystemPoints}>
                    {incentive.points}
                  </ListItemSecondaryAction>
                  <Divider />
                </ListItem>
                <ClaimComponent
                  openModalClaim={!!itemToOpenMadol}
                  handleClose={handleClose}
                  incentiveData={itemToOpenMadol}
                />
              </>
            ))}
          </div>
        ))}
    </>
  );
}
