import React, { useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import PortalComponent from 'components/Portal/PortalComponent';
import Box from 'components/atoms/Box';

import { SparePart } from 'types/spare-part';

import SparePartTransferStep from './components/SparePartTransferStep';

type SparePartTransferProps = { open: boolean; onClose: () => void };
const SparePartTransfer: React.FunctionComponent<SparePartTransferProps> = ({
  open,
  onClose,
}: SparePartTransferProps) => {
  const [spareParts, setSpareParts] = useState<SparePart[]>([]);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const removeSku = (sparePartId: number) => {
    setSpareParts((value) =>
      value.filter(function (element) {
        return element.id !== sparePartId;
      })
    );
  };
  const addSkuInForm = (sparePart: SparePart) => {
    setSpareParts((value) => [...value, sparePart]);
  };

  const openLocationModal = () => {
    setIsLocationModalOpen(true);
  };

  const onCloseLocationModal = () => {
    setIsLocationModalOpen(false);
  };

  const resetSpareParts = () => {
    setSpareParts([]);
  };
  return (
    <PortalComponent open={open} onClose={onClose}>
      <Box backgroundColor="#F1F0F5">
        <Box color="#8795B3" position="absolute" right={16} top={16}>
          <CloseIcon onClick={onClose} />
        </Box>
        <Box backgroundColor="#F1F0F5" paddingTop={24}>
          <SparePartTransferStep
            addSkuInForm={addSkuInForm}
            removeSku={removeSku}
            skus={spareParts}
            nbSkus={spareParts.length}
            isLocationModalOpen={isLocationModalOpen}
            openLocationModal={openLocationModal}
            onCloseLocationModal={onCloseLocationModal}
            resetSkus={resetSpareParts}
            setNextStep={() => {}}
            addLocation={() => {}}
          />
        </Box>
      </Box>
    </PortalComponent>
  );
};

export default SparePartTransfer;
