import { useEffect, useRef } from 'react';

/**
 * This custom hook triggers a callback when reloading a page
 * @param callback The callback to be triggered, takes the event as argument
 */
const useUnload = (fn: (e: BeforeUnloadEvent) => void): void => {
  const cb = useRef(fn);

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [cb]);
};

export default useUnload;
