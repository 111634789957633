import React from 'react';

import { Box } from '@material-ui/core';

import dataProvider from 'dataProvider';
import { useEmployee } from 'employeeContext';
import { useModals } from 'modalsContext';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import PageLayout from 'components/templates/PageLayout';

import { useWorkshops } from 'utils/api/api';

const UpdateWorkshopInterface: React.FunctionComponent = () => {
  const { employee, storeEmployee } = useEmployee();
  const { showModal, closeModal } = useModals();

  const { workshops } = useWorkshops();
  const updateEmployee = (workshopId: number) => {
    dataProvider
      .update('employee', {
        id: employee.id,
        data: {
          workshop: workshopId,
        },
      })
      .then(() => {
        const employee = JSON.parse(localStorage.getItem('employee') || '');
        employee.workshop = workshopId;
        storeEmployee(employee);
      });
  };

  const updateEmployeeWorkshop = () => {
    showModal({
      modalToShow: 'FORM_SELECT_MODAL',
      modalProps: {
        list: workshops as any[],
        fieldName: '',
        title: "Modification de l'atelier",
        onValueSelection: (value: string) => {
          updateEmployee(parseInt(value));
          closeModal();
        },
        isChecked: (value: string) => {
          return parseInt(value) === employee.workshop;
        },
      },
    });
  };
  return (
    <PageLayout>
      <Box textAlign={'center'} mx={1} margin={'auto'}>
        <Text variant="subtitle">
          {"Vous n'avez pas d'atelier associé à votre profil, vous pouvez le paramétrer ci-après:"}
        </Text>
        <Button mt={2} variant="primary" onClick={updateEmployeeWorkshop}>
          {'Modifier mon atelier'}
        </Button>
      </Box>
    </PageLayout>
  );
};

export default UpdateWorkshopInterface;
