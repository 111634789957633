// React imports
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Quagga from 'quagga';

// Style imports
import ScannerStyle from './styles/ScannerStyle';

const useStyles = makeStyles(ScannerStyle);

export default function Scanner(props) {
  let barcode = null;
  let counter = 0;

  const [scannerIsRunning, setScannerIsRunning] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    // on opening start the barcode scanner once
    if (!scannerIsRunning && document.querySelector('#scanner-container')) {
      startScanner();
    } else if (document.querySelector('#scanner-container') === null) {
      setScannerIsRunning();
    }
  }, [scannerIsRunning, document]);

  const startScanner = () => {
    // initialize barcode reader with configuration
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.getElementById('scanner-container'),
          constraints: {
            width: 480,
            height: props.height || 960,
            facingMode: 'environment',
          },
        },
        locator: {
          patchSize: props.location ? 'x-large' : 'medium',
          halfSample: props.location ? false : true,
        },
        locate: true,
        multiple: false,
        decoder: {
          readers: ['code_128_reader'],
        },
      },
      function (err) {
        if (err) {
          console.error(err);
          return;
        }
        setScannerIsRunning(true);
        Quagga.start();
      }
    );
    // method called each time a barcode is detected
    Quagga.onDetected(function (result) {
      if (result.codeResult && result.codeResult.code) {
        handleBarcodeDetection(result.codeResult.code);
      }
    });
  };

  const handleBarcodeDetection = (newBarcode) => {
    if (newBarcode !== barcode) {
      barcode = newBarcode;
    } else {
      if (counter < 2) {
        counter += 1;
      } else {
        counter = 0;
        Quagga.offDetected();
        Quagga.offProcessed();
        Quagga.stop();
        const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        !iOS && window && window.navigator && window.navigator.vibrate([100, 100, 100]);
        props.handleConfirmBarcode(barcode);
      }
    }
  };

  return (
    <div className={classes.ReaderContainer}>
      <div id="scanner-container" className={classes.BarcodeReader}></div>
    </div>
  );
}
