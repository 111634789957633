import React from 'react';

import Box from 'components/atoms/Box';
import BulletList from 'components/atoms/BulletList';
import Text from 'components/atoms/Text';
import BulletPoint from 'components/molecules/BulletPoint';
import ApplianceNameplate from 'components/organisms/ApplianceNameplate';

import { Product } from 'types/products';

import { formatProductTitle } from 'utils/products';

type SimpleProductDetailProps = {
  product: Product;
};

const SimpleProductDetail: React.FunctionComponent<SimpleProductDetailProps> = ({
  product,
}: SimpleProductDetailProps) => {
  return (
    <Box
      backgroundColor="white"
      borderTopStyle="solid"
      borderTopWidth="1px"
      borderColor={'grey300'}
      py={1}
    >
      <BulletList px={3} mt={1}>
        <Box mb={1}>{formatProductTitle(product)}</Box>
        <BulletPoint>
          {product.symptom && product.symptom !== 'Autre' && product.symptom !== ''
            ? product.symptom
            : 'Pas de symptôme décrit'}
        </BulletPoint>
        {product.model_reference && (
          <BulletPoint>Modèle ou Référence: {product.model_reference}</BulletPoint>
        )}
        {product.fault && <BulletPoint>{product.fault}</BulletPoint>}
        {product.fault_details && (
          <BulletPoint>
            <Text variant="small">{product.fault_details}</Text>
          </BulletPoint>
        )}
        {product.identification_plate && (
          <ApplianceNameplate applianceNameplateImageUrl={product.identification_plate} />
        )}
      </BulletList>
    </Box>
  );
};

export default SimpleProductDetail;
