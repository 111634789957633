import { createStyles } from '@material-ui/core/styles';

import theme from 'theme';

const RepairReportProductAddition = createStyles({
  addNewProductButton: {
    fontSize: '15px',
    lineHeight: '140%',
    textTransform: 'none',
    margin: '8px 24px',
    color: '#8795B3',
    textDecoration: 'underline',
  },
  validateButton: {
    background: '#FCD660',
    margin: '12px 24px',
    height: 56,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#2B3B5C',
  },
  newProductContainer: {
    display: 'flex',
  },
  dashedSquare: {
    border: '1px dashed #8795B3',
    borderRadius: 10,
    height: 188,
    marginTop: 156,
  },
  clickableDiv: {
    width: '100%',
    textAlign: 'center',
    marginTop: 50,
  },
  SmallClickableDiv: {
    position: 'absolute',
    right: 16,
    bottom: 68,
  },
  scanNewProductButton: {
    width: 72,
    height: 72,
    padding: 22,
    boxSizing: 'border-box',
    background: '#FCD660',
    borderRadius: '50%',
  },
  ProductListContainer: {
    width: '100%',
    marginTop: 46,
    height: 'calc(100vh - 188px)',
    overflow: 'scroll',
  },
  ProductItem: {
    backgroundColor: 'white',
    borderRadius: 10,
    margin: '8px 0px',
    padding: '20px 16px',
  },
  closeButton: {
    color: '#8795B3',
    position: 'absolute',
    right: 16,
    top: 16,
  },
  error: {
    color: 'red',
    padding: '0 24px',
  },
  removeIcon: {
    color: '#DADADA',
    float: 'right',
  },
  drawerContainer: {
    height: 'calc(100vh - 100px)',
    padding: '0 0 24px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  drawerButton: {
    height: 56,
    padding: 8,
    textAlign: 'center',
    boxSizing: 'border-box',
    width: '100%',
    background: 'white',
    borderRadius: '12px 12px 0px 0px',
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
  },
  drawerButtonText: {
    fontSize: 14,
    color: '#2B3B5C',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  drawerIcon: {
    color: '#FCD660',
  },
  paddingContainer: {
    padding: '0px 24px',
    maxHeight: '100%',
  },
  drawerButtonTop: {
    paddingTop: 8,
    textAlign: 'center',
    background: 'white',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  confirmationContainer: {
    marginTop: '45%',
    display: 'grid',
    'grid-gap': 36,
    textAlign: 'center',
  },
  newLocationName: {
    width: '100%',
    color: '#2B3B5C',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  submitButton: {
    background: '#FCD660',
    height: 56,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid #DBDFE8',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    color: '#2B3B5C',
    position: 'absolute',
    bottom: 24,
    width: 'calc(100% - 48px)',
    margin: '0 24px',
  },
  paddingDiv: {
    padding: '24px',
  },
  snackbarContent: {
    backgroundColor: '#f44336',
    margin: 24,
  },
  snackBarText: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#fff',
  },
  marginLeft: {
    marginLeft: theme.space[2],
  },
});

export default RepairReportProductAddition;
