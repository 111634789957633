import React from 'react';

import { styled } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { MarginProps } from 'styled-system';
import theme from 'theme';

import Box from 'components/atoms/Box';
import PaperStrip from 'components/atoms/PaperStrip';
import Text from 'components/atoms/Text';

type PaperStripWithIconBaseProps = {
  Icon: React.FunctionComponent;
  SecondaryIcon?: React.FunctionComponent;
  secondaryText?: React.ReactNode;
  color?: string;
  disabled?: boolean;
  importantIcon?: boolean;
} & MarginProps;

type PaperStripWithIconWithTitleProps = PaperStripWithIconBaseProps & {
  textMode?: 'title';
  text: string;
};

type PaperStripWithIconWithContentProps = PaperStripWithIconBaseProps & {
  textMode?: 'content';
  text: React.ReactNode;
};

type PaperStripWithIconWithActionProps = PaperStripWithIconBaseProps & {
  textMode?: 'action';
  text: React.ReactNode;
};

type PaperStripWithIconWithTaskProps = PaperStripWithIconBaseProps & {
  textMode?: 'task';
  text: React.ReactNode;
};

type PaperStripWithIconWithTaskFulfilledProps = PaperStripWithIconBaseProps & {
  textMode?: 'taskFulfilled';
  text: React.ReactNode;
};

type PaperStripWithIconProps =
  | PaperStripWithIconWithTitleProps
  | PaperStripWithIconWithContentProps
  | PaperStripWithIconWithActionProps
  | PaperStripWithIconWithTaskProps
  | PaperStripWithIconWithTaskFulfilledProps;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(() => ({
  color: theme.colors.turquoise,
  marginLeft: theme.space[1],
  verticalAlign: 'bottom',
  transform: 'rotate(180deg)',
}));

const PaperStripWithIcon: React.FunctionComponent<PaperStripWithIconProps> = ({
  text,
  secondaryText,
  Icon,
  SecondaryIcon,
  textMode,
  color = '#000000',
  disabled,
  importantIcon,
  ...rest
}: PaperStripWithIconProps) => {
  return (
    <PaperStrip {...rest} disabled={disabled}>
      {textMode === 'title' && (
        <Text
          variant="bodyBold"
          display="flex"
          color={disabled ? 'grey500_2' : color}
          justifyContent="space-between"
        >
          <Box>
            {text}
            {importantIcon && <StyledInfoOutlinedIcon />}
          </Box>
          <Icon />
        </Text>
      )}
      {textMode === 'content' && (
        <Box display="flex" alignItems="center">
          <Icon />
          {text}
        </Box>
      )}
      {textMode === 'task' && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text variant="extraSmall" color="grey500">
            {text}
          </Text>
          <Icon />
        </Box>
      )}
      {textMode === 'taskFulfilled' && (
        <>
          <Box
            textAlign="start"
            display="flex"
            mb={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="extraSmall" color="grey500">
              {text}
            </Text>
          </Box>
          <Box textAlign="start" display="flex" alignItems="center" justifyContent="space-between">
            <Text variant="smallBold">{secondaryText}</Text>
            <Icon />
          </Box>
        </>
      )}

      {textMode === 'action' && (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Icon />
            <Text ml={16} variant="bodyBold" display="flex" justifyContent="space-between">
              {text}
            </Text>
          </Box>
          {SecondaryIcon ? <SecondaryIcon /> : null}
        </Box>
      )}
    </PaperStrip>
  );
};

PaperStripWithIcon.defaultProps = {
  textMode: 'title',
};
export default PaperStripWithIcon;
