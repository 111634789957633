import React from 'react';

import { themeGet } from '@styled-system/theme-get';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { MarginProps, WidthProps, margin, width } from 'styled-system';

export type StyledTextAreaProps = {
  valid?: boolean;
  value: string;
  placeholder: string;
};

const StyledTextArea = styled('textarea')<StyledTextAreaProps>((props) => ({
  borderRadius: themeGet('radii.3')(props),
  boxSizing: 'border-box',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: props.valid
    ? `${transparentize(0.6, props.theme.colors.grey500_2)}`
    : themeGet('colors.pink700')(props),
  paddingLeft: themeGet('space.3')(props),
  paddingTop: themeGet('space.3')(props),
  width: 'inherit',
  outline: 'none',
  ':focus': {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: themeGet('colors.green')(props),
  },
}));

StyledTextArea.defaultProps = {
  valid: true,
};

export type TextAreaContainerProps = React.ComponentProps<typeof StyledTextArea> &
  WidthProps &
  MarginProps &
  React.HTMLAttributes<HTMLInputElement>;

const TextAreaContainer = styled('div')<TextAreaContainerProps>(
  (props: TextAreaContainerProps) => ({
    fontFamily: 'GT-Walsheim',
    '> label': {
      visibility: props.value ? 'visible' : 'hidden',
      position: 'relative',
      top: themeGet('space.3')(props),
      left: themeGet('radii.3')(props),
      fontSize: themeGet('fontSizes.1')(props),
      boxSizing: 'border-box',
      color: themeGet('colors.grey700')(props),
    },
  }),
  width,
  margin
);

const TextArea: React.FunctionComponent<TextAreaContainerProps> = ({
  width,
  value,
  placeholder,
  ...rest
}: TextAreaContainerProps) => {
  return (
    <TextAreaContainer value={value} width={width}>
      <label>{placeholder}</label>
      <StyledTextArea value={value} placeholder={placeholder} {...rest} />
    </TextAreaContainer>
  );
};

export default TextArea;
