import React from 'react';

import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import PageLayout from 'components/templates/PageLayout';

import SparePartsForm from './SparePartsForm';

type SparePartsNeedProps = {
  onSuccess: () => void;
  back: () => void;
  intervention: string;
};

const SparePartsNeed: React.FunctionComponent<SparePartsNeedProps> = ({
  onSuccess,
  back,
  intervention,
}: SparePartsNeedProps) => {
  return (
    <PageLayout>
      <HeaderWithArrow title="Besoin de pièce(s) détachée(s) ?" action={back} />
      <SparePartsForm intervention={intervention} onSuccess={onSuccess} />
    </PageLayout>
  );
};

export default SparePartsNeed;
