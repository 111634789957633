import React from 'react';

import { Widget } from '@typeform/embed-react';
import { useEmployee } from 'employeeContext';

import { REPAIR_REPORT_QUALITY_PICTURE_FORM_ID } from 'pages/RepairCycleTasks/RepairCycleUtils';

import { useWorkshops } from 'utils/api/api';

type TypeformQualityPictureWidgetProps = {
  barcode: string;
  formId?: string;
  onClose: () => void;
};

const TypeformQualityPictureWidget: React.FunctionComponent<TypeformQualityPictureWidgetProps> = ({
  barcode,
  formId = REPAIR_REPORT_QUALITY_PICTURE_FORM_ID,
  onClose,
}: TypeformQualityPictureWidgetProps) => {
  const { employee } = useEmployee();
  const { workshops } = useWorkshops();

  return (
    <Widget
      id={formId}
      onClose={onClose}
      onSubmit={onClose}
      style={{ width: '100%', height: 'calc(100vh - 58px)' }}
      hideFooter
      hideHeaders
      hidden={{
        product_barcode: barcode,
        tech_name: `${employee.user.first_name} ${employee.user.last_name}`,
        tech_workshop:
          (workshops && workshops.find((workshop) => workshop.id === employee.workshop)?.name) ||
          '',
      }}
    />
  );
};

export default TypeformQualityPictureWidget;
