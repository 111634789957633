import React, { useRef, useState } from 'react';

import styled from 'styled-components';
import { WidthProps } from 'styled-system';

import Box from 'components/atoms/Box';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

type ItemValue = string | number;
type Item<T extends ItemValue> = { name: string; value: T };

type SelectItemProps<T extends ItemValue> = {
  item: Item<T>;
  onSelect: (selected: Item<T>) => void;
  selected: boolean;
};
const StyledBox = styled(Box)({
  zIndex: 1000,
});
const SelectItem = <T extends ItemValue>({
  item,
  onSelect,
  selected,
}: SelectItemProps<T>): JSX.Element => {
  return (
    <StyledBox
      onClick={() => onSelect(item)}
      /** This ensures the onClick event is called before the onBlur event.
       * It's a bit hacky but that works.
       * See: https://stackoverflow.com/a/57630197
       */
      onMouseDown={(e) => e.preventDefault()}
      height={7}
      p={2}
      bg={selected ? 'green' : 'white'}
    >
      <Text variant="body" color={selected ? 'white' : 'dark'}>
        {item.name}
      </Text>
    </StyledBox>
  );
};
type SelectProps<T extends ItemValue> = {
  items: Item<T>[];
  value: T | null;
  onChange: (selected: T) => void;
  placeholder?: string;
  name: string;
  valid?: boolean;
  innerWidth?: string;
  maxHeight?: number;
} & WidthProps;

const Select = <T extends ItemValue>({
  items,
  value,
  onChange,
  width,
  innerWidth,
  maxHeight = 168,
  placeholder,
  name,
  valid = true,
}: SelectProps<T>): JSX.Element => {
  const textInput = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    textInput.current?.focus();
    setIsOpen(true);
  };

  const handleClose = () => {
    textInput.current?.blur();
    setIsOpen(false);
  };

  const handleSelect = (item: Item<T>) => {
    onChange(item.value);
    handleClose();
  };

  const handleBlur = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.stopPropagation();
    handleClose();
  };

  const selectedItem = items.find((item) => item.value === value);

  return (
    <Box width={width}>
      <Input
        value={selectedItem?.name || ''}
        ref={textInput}
        onClick={onOpen}
        placeholder={placeholder}
        width={width}
        onBlur={handleBlur}
        name={name}
        valid={valid}
        readOnly
      />
      <StyledBox
        bg="white"
        borderRadius="16px"
        mt={2}
        maxHeight={maxHeight}
        overflow="auto"
        width={innerWidth || width}
        position="absolute"
      >
        {isOpen &&
          items.map((item, index) => (
            <SelectItem
              key={`${item.name}${index}`}
              item={item}
              onSelect={handleSelect}
              selected={item === selectedItem}
            />
          ))}
      </StyledBox>
    </Box>
  );
};

export default Select;
