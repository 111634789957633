import React from 'react';

import { Typography } from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';

import RepairReportSnackbarStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportSnackbarStyle';

const useStyles = makeStyles(RepairReportSnackbarStyle);

const displayMessage = (
  errorMessage,
  messageForMissingData,
  isDataMissing,
  dataMissing,
  snackBarTitle,
  errorMessageStyle
) => (
  <div>
    <Typography variant="h6" className={snackBarTitle}>
      Une erreur est survenue
    </Typography>
    <Typography variant="body2" className={errorMessageStyle}>
      {isDataMissing ? messageForMissingData : errorMessage && errorMessage.toString()}
    </Typography>
    {isDataMissing &&
      dataMissing &&
      dataMissing.map((data) => (
        <Typography variant="body2" className={errorMessageStyle} key={data}>
          {data}
        </Typography>
      ))}
  </div>
);

export default function RepairReportSnackbar({
  errorMessage,
  messageForMissingData = '',
  isDataMissing = false,
  dataMissing = [],
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SnackbarContent
        className={classes.snackbarContent}
        message={displayMessage(
          errorMessage,
          messageForMissingData,
          isDataMissing,
          dataMissing,
          classes.snackBarTitle,
          classes.errorMessageStyle
        )}
      />
    </div>
  );
}
