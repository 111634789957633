import React from 'react';

import Text from 'components/atoms/Text';

import { Visit } from 'types/visits';

import { timeslotToHour } from 'utils/dates';
import { formatName, uppercaseFirstLetter } from 'utils/formatting';

interface VisitCardContentProps {
  visit: Visit;
}
const VisitCardContent: React.FunctionComponent<VisitCardContentProps> = ({
  visit,
}: VisitCardContentProps) => {
  return (
    <div>
      <Text color="black500" variant="smallBold">
        {formatName(visit.firstname, visit.lastname)}
      </Text>

      <Text color="black500" variant="extraSmall">
        {visit.is_over
          ? `${uppercaseFirstLetter(visit.city)} - ${timeslotToHour(visit.timeslot)}`
          : timeslotToHour(visit.timeslot)}
      </Text>
    </div>
  );
};

export default VisitCardContent;
