import React, { useEffect, useState } from 'react';

import { useRefresh } from 'react-admin';

import dataProvider from 'dataProvider';

import SparePartReceptionManagement from 'components/organisms/SparePartReceptionManagement';

import { SparePart } from 'types/spare-part';

import { generateQrCode } from 'utils/QrCodeUtils';
import { getSparePartsList } from 'utils/api/api';
import { generateUniqueId } from 'utils/skuUniqueIdGenerator';

// STATUSES
const LOST = 'lost';

type SparePartReceptionProps = {
  selectedId: number;
  baseSkuList: SparePart[];
  onClose: () => void;
  mode: 'packing' | 'transfer';
  open: boolean;
  employeeName: string;
  sparePartFilter: Record<string, unknown>;
  handleCreate: (selectedId: any, selectedIds: any, _: any, lostSkuList: any) => void; // oof
  isPackageButtonDisabled: boolean;
  generatedPackageCode: string;
};

const SparePartReception: React.FunctionComponent<SparePartReceptionProps> = ({
  selectedId,
  baseSkuList,
  onClose,
  mode,
  open,
  employeeName,
  sparePartFilter,
  handleCreate,
  isPackageButtonDisabled,
  generatedPackageCode,
}) => {
  const refresh = useRefresh();
  const [isLossModalOpen, setIsLossModalOpen] = useState(false);
  const [sku, setSku] = useState<SparePart>();
  const [skuNumber, setSkuNumber] = useState<number>(0);
  const [isScanModalOpen, setIsScanModalOpen] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedUniqueIds, setSelectedUniqueIds] = useState<string[]>([]);
  const [lostSkuList, setLostSkuList] = useState<number[]>([]);
  const [error, setError] = useState<string>('');
  const [isPrintModalOpened, setIsPrintModalOpened] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>();
  const [packageCode, setPackageCode] = useState<string>();
  const [skuList, setSkuList] = useState<SparePart[]>([]);
  const [surplusSkuList, setSurplusSkuList] = useState<SparePart[]>([]);
  // don't wait for list to load if it is passed as props
  const [isListLoading, setIsListLoading] = useState<boolean>(!baseSkuList);
  const [isCurrentModalOpen, setIsCurrentModalOpen] = useState<boolean>(false);

  // Related to PackageCreation
  const [disabledPackageButton, setDisabledPackageButton] = useState(isPackageButtonDisabled);

  const handleOpenScanModal = () => {
    setIsScanModalOpen(true);
  };

  const resetLists = () => {
    setSelectedIds([]);
    setSelectedUniqueIds([]);
    setLostSkuList([]);
    setSurplusSkuList([]);
  };

  const handleCloseModal = () => {
    resetLists();
    onClose();
    setIsCurrentModalOpen(false);
  };
  const handleCloseSparePartLossModal = () => {
    setIsLossModalOpen(false);
    refresh();
  };

  const handleCloseScanModal = () => {
    setIsScanModalOpen(false);
    setError('');
  };

  const handleClosePackageDetails = () => {
    setIsPrintModalOpened(false);
  };

  // Used to sort Sku List depending on mode
  useEffect(() => {
    const fetchSparePartList = async () => {
      const skusList: SparePart[] = await getSparePartsList(sparePartFilter);
      setSkuList(skusList);
    };

    if (selectedId && open && !isCurrentModalOpen && !baseSkuList) {
      setIsCurrentModalOpen(true);
      fetchSparePartList();
    }
    if (baseSkuList) {
      setSkuNumber(baseSkuList.length);
      setSkuList(
        baseSkuList.sort((skuA: SparePart, skuB: SparePart) => {
          return skuA.location.code > skuB.location.code ? 1 : -1;
        })
      );
    }
  }, [selectedId, mode, open, isCurrentModalOpen, baseSkuList]);

  // Handle icon button click on sku
  const handleRowClick = (record: SparePart) => {
    setSku(record);
    setIsLossModalOpen(true);
  };

  const handleSkuSurplus = (sparePartUniqueId: string) => {
    dataProvider
      .getOne('sku', { unique_id: sparePartUniqueId })
      .then((response) => {
        if (response.data?.length >= 1 && !selectedIds.includes(response.data[0].id)) {
          dataProvider
            .update('sku', {
              id: response.data[0].id,
              data: { transfer: [selectedId] },
            })
            .then(() => {
              if (!surplusSkuList.includes(response.data[0].id)) {
                setSurplusSkuList((oldArray) => [...oldArray, response.data[0].id]);

                setSelectedUniqueIds((oldArray) => [...oldArray, sparePartUniqueId]);
                const filteredArray = skuList.filter((el) => el.id !== response.data[0].id);
                setSkuList(filteredArray);
                setIsScanModalOpen(false);
                setSelectedIds((oldArray) => [...oldArray, response.data[0].id]);
                refresh();
              }
            });
        } else {
          setError('Erreur sku : ' + sparePartUniqueId);
          return Promise.reject("Sku doesn't exists");
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleConfirmBarcode = (sparePartBarcode: string) => {
    const sparePartId = sparePartBarcode.substring(0, sparePartBarcode.lastIndexOf('/'));
    const sparePart = skuList.find(
      (el) => el.sku_model.sku_reference === sparePartId && el.status !== LOST
    );
    if (sparePart && !selectedUniqueIds.includes(sparePartBarcode)) {
      setError('');
      setSelectedUniqueIds((oldArray) => [...oldArray, sparePartBarcode]);
      const filteredArray = skuList.filter((el) => el.id !== sparePart.id);
      setSkuList(filteredArray);
      setIsScanModalOpen(false);
      setSelectedIds((oldArray) => [...oldArray, sparePart.id]);
    } else if (!sparePart) {
      handleSkuSurplus(sparePartBarcode);
    } else {
      setError('Erreur SKU : ' + sparePartBarcode);
    }
  };

  // Add a sku to the lost sku list and adapt the skuNumber state
  const handleSparePartLoss = () => {
    if (sku) {
      setLostSkuList([...lostSkuList, sku.id]);
      setSkuNumber(
        skuList.map((x) => x.id).filter((el) => ![...lostSkuList, sku.id].includes(el)).length
      );
    }
  };

  // It open the print screen modal
  const handlePrintScreen = (code: string) => {
    // unclear
    setPackageCode(code);
    setIsPrintModalOpened(true);
    generateQrCode(code, setQrCode);
  };

  // Used to generate an unique id for sku and to trigger
  // the print screen display
  const printSku = (sparePart: SparePart) => {
    generateUniqueId(sparePart).then(handlePrintScreen);
  };

  useEffect(() => {
    if (generatedPackageCode) handlePrintScreen(generatedPackageCode);
  }, [generatedPackageCode]);

  // Used when the complete task is finished
  // It tests if we have to manage a transfer or a package
  async function handleValidateTask() {
    setDisabledPackageButton(true);
    await handleCreate(selectedId, selectedIds, employeeName, lostSkuList);
    resetLists();
    refresh();
  }

  return (
    <SparePartReceptionManagement
      open={open}
      onClose={onClose}
      handleCloseModal={handleCloseModal}
      selectedIds={selectedIds}
      lostSkuList={lostSkuList}
      setSkuNumber={setSkuNumber}
      skuNumber={skuNumber}
      employeeName={employeeName}
      selectedId={selectedId}
      baseSkuList={baseSkuList}
      printSku={printSku}
      handleRowClick={handleRowClick}
      surplusSkuList={surplusSkuList}
      isListLoading={isListLoading}
      sparePartFilter={sparePartFilter}
      disabledPackageButton={disabledPackageButton}
      handleValidateTask={handleValidateTask}
      mode={mode}
      isLossModalOpen={isLossModalOpen}
      setIsListLoading={setIsListLoading}
      sku={sku}
      handleCloseSparePartLossModal={handleCloseSparePartLossModal}
      handleConfirmBarcode={handleConfirmBarcode}
      handleCloseScanModal={handleCloseScanModal}
      handleSparePartLoss={handleSparePartLoss}
      isScanModalOpen={isScanModalOpen}
      isPrintModalOpened={isPrintModalOpened}
      handleClosePackageDetails={handleClosePackageDetails}
      qrCode={qrCode}
      packageCode={packageCode}
      handleOpenScanModal={handleOpenScanModal}
      error={error}
    />
  );
};

export default SparePartReception;
