import React from 'react';

import { IconProps } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import { FlatCardList } from 'components/FlatCard';
import PortalComponent from 'components/Portal/PortalComponent';
import BottomButton from 'components/atoms/BottomButton';
import Box from 'components/atoms/Box';
import HeaderWithArrow from 'components/atoms/HeaderWithArrow';
import MinimalSparePartDetail from 'components/molecules/MinimalSparePartDetail';

import { SparePart } from 'types/spare-part';

type LostSparePartDeclarationListProps = {
  potentiallyLostSpareParts: SparePart[];
  open: boolean;
  onClose: () => void;
  setLostSpareParts: (spareParts: SparePart[]) => void;
};

const LostSparePartDeclarationList: React.FunctionComponent<LostSparePartDeclarationListProps> = ({
  setLostSpareParts,
  potentiallyLostSpareParts,
  open,
  onClose,
}: LostSparePartDeclarationListProps) => {
  const StyledCancelIcon = styled(CancelIcon)<IconProps>((props: IconProps) => ({
    color: themeGet('colors.red')(props),
    width: themeGet('space.3')(props),
    height: themeGet('space.3')(props),
  }));
  return (
    <PortalComponent open={open} onClose={onClose}>
      <HeaderWithArrow title={`Pièces perdues`} action={onClose} />
      <Box mb={8} mx={2} overflow="scroll">
        <FlatCardList>
          {potentiallyLostSpareParts.map((sparePart: SparePart) => (
            <MinimalSparePartDetail
              key={sparePart.id}
              sparePart={sparePart}
              icon={<StyledCancelIcon />}
            />
          ))}
        </FlatCardList>
      </Box>
      <BottomButton onClick={() => setLostSpareParts(potentiallyLostSpareParts)} variant="error">
        Déclarer une perte
      </BottomButton>
    </PortalComponent>
  );
};

export default LostSparePartDeclarationList;
