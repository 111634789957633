import React from 'react';

import FlatCard from 'components/FlatCard';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import VisitTag from 'components/molecules/VisitTag';

import { Visit } from 'types/visits';

import { getVisitAddress } from 'utils/visits';

import VisitCardContent from './VisitCardContent';

interface VisitCardDoneProps {
  visit: Visit;
  handleClick: () => void;
}
const VisitCardDone: React.FunctionComponent<VisitCardDoneProps> = ({
  visit,
  handleClick,
}: VisitCardDoneProps) => {
  return (
    <FlatCard data-testid={`visit-${visit.id}`} p={1} onClick={handleClick}>
      <Box display="flex" justifyContent="space-between">
        <Box mb={1}>
          <VisitCardContent visit={visit} />
        </Box>
        <VisitTag visitType={visit.visit_type} isLong={visit.time_needed} ml={1} />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Text color="black500" variant="extraSmall">
          {getVisitAddress(visit)}
        </Text>
        <Text variant="extraSmall" color="grey900">
          {visit.order}
        </Text>
      </Box>
    </FlatCard>
  );
};

export default VisitCardDone;
