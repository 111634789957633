import { setUserHistory } from 'pages/RepairReport/services/setUserHistory';

const ASWO_SUPPLIER_NAME = 'ASWO';

export function formatData(values, visitId, customerFileId) {
  const is_payment_done = values['payment'] && values['payment'].payment_mode !== undefined;
  const why_payment_not_done = values['payment'] && values['payment'].why_not_payed;
  const payment_not_done_explanation = values['payment'] && values['payment'].cant_pay_explanation;
  const payment_amount =
    values['payment'] &&
    values['payment'].amount &&
    parseInt(parseFloat(values['payment'].amount) * 100);
  const payment_method = values['payment'] && values['payment'].payment_mode;
  const payment_proof = (values['payment'] && values['payment'].payment_proof) || [];

  let productsData = [];
  let attachmentsTags = [];
  let attachments = [];
  let history = [];

  Object.keys(values).forEach(function (key) {
    const userHistory = setUserHistory(values, key);
    history = [...history, userHistory];
    if (key !== 'payment' && key !== 'spare_part_fitted') {
      const keyString = key.split('_');
      const productIdValidated = keyString[1] === 'new' ? null : keyString[1];
      const spare_part_used =
        values[key].is_spare_part_used_reparation_done ||
        values[key].is_spare_part_used_reparation_not_done;
      const own_stock_spare_part_used =
        values[key].spare_parts_to_be_added && values[key].spare_parts_to_be_added.length > 0;
      const spareAdded = values[key].spare_added && values[key].spare_added.length > 0;
      const listSkuModelDesignation = values[key].list_sku_model_designation || [];
      const sparePartsToBeAdded = values[key].spare_parts_to_be_added || [];
      const visit_outcome =
        values[key].intervention_not_done_conclusion_without_sp ||
        values[key].intervention_not_done_conclusion_with_sp ||
        values[key].is_appliance_repaired ||
        values[key].is_appliance_repaired;

      const additional_info_pictures = values[key].additional_info_pictures || [];
      const nameplate_appliance = values[key].nameplate_appliance || [];
      const hermetic_unit_picture = values[key].hermetic_unit_order_picture || [];
      const justification_long_intervention =
        values[key].justification_long_intervention_pictures || [];

      attachments = [
        ...attachments,
        ...additional_info_pictures,
        ...nameplate_appliance,
        ...hermetic_unit_picture,
        ...justification_long_intervention,
      ];

      const is_next_operation_long_duration =
        (values[key].next_operation_explanation &&
          values[key].next_operation_explanation.length > 0) ||
        false;
      const hermetic_unit_order =
        values[key].hermetic_unit_order &&
        values[key].hermetic_unit_order.length > 0 &&
        values[key].hermetic_unit_order;
      const skuListIdUsed = values[key].sku_list_id_used || [];
      const skuListStockAdded = values[key].spare_added || [];

      const spare_parts_orders =
        values[key].spare_parts_to_order &&
        values[key].spare_parts_to_order.map((item) => ({
          ...item,
          supplier: ASWO_SUPPLIER_NAME,
        }));

      const product = [
        {
          product_id: productIdValidated,
          product_type: values[key].product_type,
          visit_outcome: visit_outcome,
          spare_parts_orders: spare_parts_orders,
          spare_parts_installs: [...listSkuModelDesignation, ...sparePartsToBeAdded],
          spare_part_used: spare_part_used || false,
          which_spare_part_used: values[key].spare_part_used,
          own_stock_spare_part_used: own_stock_spare_part_used || spareAdded || false,
          symptoms: values[key].symptoms,
          diagnosis: values[key].diagnosis,
          is_next_operation_long_duration: is_next_operation_long_duration,
          next_operation_explanation: values[key].next_operation_explanation,
          next_operation_explanation_details: values[key].next_operation_explanation_details,
          additional_information: values[key].infos || '',
          hermetic_unit_order: hermetic_unit_order || [],
          task_duration: values[key].task_duration || '',
          sku_list_id: [...skuListIdUsed, ...skuListStockAdded] || [],
          not_repaired_reason: values[key].not_repaired_reason || '',
          intervention_impossible_reason: values[key].intervention_impossible_reason || '',
        },
      ];
      productsData = [...productsData, ...product];
    }
  });

  attachments = [...attachments, ...payment_proof];
  attachments &&
    attachments.map((attachment) =>
      attachmentsTags.push({ tag: attachment.tag, file: attachment.file.name })
    );
  const payment = {
    payment_method: payment_method,
    is_payment_done: is_payment_done,
    amount: payment_amount,
    why_payment_not_done: why_payment_not_done,
    explanation: payment_not_done_explanation,
  };

  const spare_part_fitted = values['spare_part_fitted'];
  const output = {
    customer_file: customerFileId,
    visit: parseInt(visitId),
    products: JSON.stringify(productsData),
    payment: JSON.stringify(payment),
    ticket_attachments: attachments,
    ticket_description: 'Compte rendu App Tech',
    user_history: JSON.stringify(history) || [],
    attachmentsTags: JSON.stringify(attachmentsTags),
    spare_part_fitted: JSON.stringify(spare_part_fitted),
  };
  return output;
}
