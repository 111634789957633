import { createStyles } from '@material-ui/core/styles';

const ScannerStyle = createStyles({
  BarcodeReader: {
    textAlign: 'center',
    '& .drawingBuffer': {
      display: 'none',
    },
    '& video': {
      borderRadius: 35,
      width: '100%',
      maxWidth: 400,
    },
  },
  ReaderContainer: {
    marginTop: 24,
    marginBottom: 24,
    borderRadius: 35,
    padding: '0 24px',
    '& section': {
      borderRadius: 16,
      maxWidth: 400,
      margin: 'auto',
      '& section > div': {
        boxShadow: 'rgba(255, 175, 0, 0.5) 0px 0px 0px 5px inset !important',
      },
    },
  },
});

export default ScannerStyle;
