import React, { useState } from 'react';

import animationData from 'assets/check.json';
import dataProvider from 'dataProvider';
import Quagga from 'quagga';
// import lottie animations
import Lottie from 'react-lottie';
import styled from 'styled-components';

import PortalComponent from 'components/Portal/PortalComponent';
import BottomButton from 'components/atoms/BottomButton/BottomButton';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ProductScanner from 'components/organisms/ProductScanner';

import { Product } from 'types/products';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ConfirmationContainer = styled.div((props) => ({
  display: 'inline-grid',
  width: '100%',
  height: 'calc(100% - 57px)',
  padding: `0 ${props.theme.space[3]}px`,
  boxSizing: 'border-box',
}));

const TextButton = styled.div((props) => ({
  textDecoration: 'underline',
  color: props.theme.colors.grey900,
  fontSize: props.theme.fontSizes[2],
}));

export type ProductSelectionPortalProps = {
  buttonText?: string;
  onClose: () => void;
  onProductSelection: (product: Product) => void;
  open: boolean;
};

const ProductSelectionPortal: React.FunctionComponent<ProductSelectionPortalProps> = ({
  buttonText,
  onClose,
  onProductSelection,
  open,
}: ProductSelectionPortalProps) => {
  const [error, setError] = useState<string>();
  const [product, setProduct] = useState<Product>();

  const stopCamera = () => {
    if (Quagga.CameraAccess.getActiveTrack()) {
      Quagga.offDetected();
      Quagga.offProcessed();
      Quagga.stop();
    }
  };

  const handleConfirmBarcode = (data: string) => {
    if (data) {
      dataProvider
        .getOne('products', { barcode: data })
        .then((response) => {
          if (response.data.length > 0) {
            // update formdata
            setProduct(response.data[0]);

            // stop scanner instance if there is one currently running
            stopCamera();
            setError('');
          } else {
            setError(`Le produit n'a pas été trouvé: ${data}`);
            setProduct(undefined);
          }
        })
        .catch((e) => {
          setError(e);
        });
    }
  };

  const confirmProduct = (product: Product) => {
    onProductSelection(product);
    onClose();
  };

  const productFound = (product: Product) => (
    <ConfirmationContainer>
      <Box mt={150}>
        <Lottie options={animationOptions} height={130} width={130} />
      </Box>
      <div>
        <Text variant="title1" mt={3}>
          {product.barcode}
        </Text>
        <Text variant="body" mt={1}>
          {product.product_type} {product.brand}
        </Text>
      </div>
      <TextButton onClick={() => setProduct(undefined)}>Modifier la sélection</TextButton>
      <BottomButton variant="primary" onClick={() => confirmProduct(product)}>
        Confirmer
      </BottomButton>
    </ConfirmationContainer>
  );

  return (
    <PortalComponent
      open={open}
      onClose={onClose}
      title="Scannez une machine"
      key="product-selection"
    >
      {product ? (
        productFound(product)
      ) : (
        <ProductScanner
          handleConfirmBarcode={handleConfirmBarcode}
          error={error}
          buttonText={buttonText || 'Confirmer'}
        />
      )}
    </PortalComponent>
  );
};

export default ProductSelectionPortal;
