import React from 'react';

import { RESOURCE_TO_API } from 'Constants';
import { UseQueryResult, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { CustomerFileDetailed } from 'types/customer-files';
import { SevenOpteamRoute } from 'types/seven-opteam-route';
import { Visit } from 'types/visits';

import { compareSevenOpteamActivity } from 'utils/visits';

import { get } from './http';

type QueryParams = Record<string, string>;

export const useCustomerFile = (
  customerFileId: number,
  queryParams: QueryParams
): UseQueryResult<CustomerFileDetailed, Error> => {
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `/${RESOURCE_TO_API['customer-file']}/${customerFileId}/?${queryString}`;
  return useQuery(['customer-file-get-one', customerFileId, queryParams], () => get(url));
};

export const useSevenOpteamVisitList = (
  repairmanId: number,
  visitDate: string
): { visitList: Visit[]; loadingVisits: boolean } => {
  const url = `/${RESOURCE_TO_API['seven-opteam-route']}/${repairmanId}/${visitDate}/`;
  const { data: visitList, isLoading: loadingVisits } = useQuery<
    SevenOpteamRoute,
    unknown,
    Visit[]
  >([url], () => get(url), {
    select: (data) => data?.stops.sort(compareSevenOpteamActivity),
  });

  return {
    visitList: visitList || [],
    loadingVisits,
  };
};

export const useSearchParams = (): URLSearchParams => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
