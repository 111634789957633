// react imports
import React, { useState } from 'react';

// mui imports
import { Button } from '@material-ui/core';
// style imports
import { makeStyles } from '@material-ui/core/styles';

import { useField, useForm } from 'react-final-form';

import RepairReportButtonStyle from 'pages/RepairReport/components/formComponents/styles/RepairReportButtonStyle';
import { StepId } from 'pages/RepairReport/services/stepHelper';

import { SparePart } from 'types/spare-part';

import { containsOnlyUndefined } from 'utils/arrays';

// component imports
import RepairReportConfirmModal from './RepairReportConfirmModal';

const useStyles = makeStyles(RepairReportButtonStyle);

type RepairReportValidateButtonProps = {
  buttonLabel: string;
  buttonType?: string;
  formProductIdReference: string;
  shouldBeDisabled?: boolean;
  step: StepId;
  validateFunction: () => void;
};
const RepairReportValidateButton: React.FunctionComponent<RepairReportValidateButtonProps> = ({
  buttonLabel,
  validateFunction,
  buttonType,
  step,
  shouldBeDisabled = false,
  formProductIdReference,
}: RepairReportValidateButtonProps) => {
  const form = useForm();
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);
  const {
    input: { onChange: addSkuModelDesignation },
  } = useField(formProductIdReference + '.list_sku_model_designation');
  const [remainingSkus, setRemainingSkus] = useState<SparePart[]>([]);
  const formdata = form.getState().values; // accesses directly to the form values
  const classes = useStyles();
  let buttonClass = classes.validateButton;
  let disabled = shouldBeDisabled || false;
  const sparePartQuote =
    (formdata[formProductIdReference] && formdata[formProductIdReference].spare_part_used) || null;
  const sparePartQuoteIds =
    (formdata[formProductIdReference] && formdata[formProductIdReference].sku_list_id_used) || null;
  const totalSparePartQuote =
    (formdata[formProductIdReference] &&
      formdata[formProductIdReference].total_spare_part_in_file) ||
    null;
  const sparePartStock =
    formdata[formProductIdReference] &&
    ((formdata[formProductIdReference].spare_parts_to_be_added &&
      formdata[formProductIdReference].spare_parts_to_be_added.concat(
        formdata[formProductIdReference].spare_added
      )) ||
      (formdata[formProductIdReference].spare_added &&
        formdata[formProductIdReference].spare_added) ||
      null);
  const symptoms =
    (formdata[formProductIdReference] && formdata[formProductIdReference].symptoms) || null;
  const diagnosis =
    (formdata[formProductIdReference] && formdata[formProductIdReference].diagnosis) || null;
  const timeEvaluation =
    (formdata[formProductIdReference] && formdata[formProductIdReference].task_duration) || null;

  const handleAddSkuModelDesignation = () => {
    const getSparePartDescription = (sparePart: SparePart) =>
      `${sparePart.sku_model.sku_reference} - ${sparePart.sku_model.designation}-sku-id:${sparePart.id}`;

    const sparePartQuoteIds =
      (formdata[formProductIdReference] && formdata[formProductIdReference].spare_added) || [];

    const totalSkuStock =
      (formdata[formProductIdReference] && formdata[formProductIdReference].total_sku_stock) || [];

    const listSparePartDescription = totalSkuStock
      .filter((sparePart: SparePart) => sparePartQuoteIds.includes(sparePart.id))
      .map((sparePart: SparePart) => getSparePartDescription(sparePart));

    if (listSparePartDescription.length > 0) addSkuModelDesignation(listSparePartDescription);

    if (listSparePartDescription.length > 0) addSkuModelDesignation(listSparePartDescription);
  };

  const handleTestBeforeValidation = () => {
    handleAddSkuModelDesignation();
    if (
      step === 'which_spare_parts_reparation_not_done' || // if no spare and stock parts are added
      step === 'which_spare_parts_reparation_done'
    ) {
      if (
        sparePartQuote &&
        totalSparePartQuote &&
        sparePartQuote.length !== totalSparePartQuote.length
      ) {
        const remainingSkusArray: Array<SparePart> = [];
        totalSparePartQuote.forEach((element: SparePart) => {
          if (sparePartQuoteIds.indexOf(element.id) === -1) {
            remainingSkusArray.push(element);
          }
        });
        setRemainingSkus(remainingSkusArray);
        setIsConfirmationModalOpened(true);
      } else {
        validateFunction();
      }
    } else {
      validateFunction();
    }
  };

  // can't allow to go further if some vital fields are empty
  if (
    step === 'which_spare_parts_reparation_not_done' || // if no spare and stock parts are added
    step === 'which_spare_parts_reparation_done'
  ) {
    if (
      (!sparePartQuote || sparePartQuote.length < 1) &&
      (!sparePartStock || (sparePartStock && containsOnlyUndefined(sparePartStock)))
    ) {
      disabled = true;
    }
  } else if (
    step === 'symptoms_diagnosis_not_reparable' || // if no symptom or no test are done
    step === 'symptoms_diagnosis_repaired' ||
    step === 'symptoms_diagnosis_not_repaired'
  ) {
    if (!symptoms || !diagnosis) {
      disabled = true;
    }
  } else if (buttonType === 'paymentButton') {
    buttonClass = classes.paymentButton;
  } else if (step === 'time-evaluation' && !timeEvaluation) {
    disabled = true;
  }

  return (
    <>
      <Button
        id="validate-step-button"
        data-testid="validate-step-button"
        className={buttonClass}
        variant="contained"
        onClick={() => handleTestBeforeValidation()}
        disabled={disabled}
      >
        <span id="validation-button-label">{buttonLabel}</span>
      </Button>
      <RepairReportConfirmModal
        open={isConfirmationModalOpened}
        onClose={() => setIsConfirmationModalOpened(false)}
        remainingSkus={remainingSkus}
        onValidate={
          /* We don't want to pass the event as the application logic
           * relies on the first argument of this function sometimes being undefined...
           * This is not robust and should be changed!
           */
          () => validateFunction()
        }
      />
    </>
  );
};

export default RepairReportValidateButton;
