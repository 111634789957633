import React from 'react';

import FlatCard from 'components/FlatCard';

type BaseTourSummaryProps = {
  className?: string;
  children: React.ReactNode;
};

const BaseTourSummary: React.FunctionComponent<BaseTourSummaryProps> = ({
  className,
  children,
}: BaseTourSummaryProps) => {
  return <FlatCard className={className}>{children}</FlatCard>;
};

export default BaseTourSummary;
