import React from 'react';

import FlatCard from 'components/FlatCard';
import Text from 'components/atoms/Text';

type RepairmanIncentiveCardProps = {
  name: string;
  points: number;
  onClick: () => void;
};

const RepairmanIncentiveCard: React.FunctionComponent<RepairmanIncentiveCardProps> = ({
  name,
  points,
  onClick,
}: RepairmanIncentiveCardProps) => {
  return (
    <FlatCard display="flex" justifyContent="space-between" onClick={onClick}>
      <Text variant="bodyBold">{name}</Text>
      <Text variant="body" color="honey">
        {points}
      </Text>
    </FlatCard>
  );
};

export default RepairmanIncentiveCard;
